import React, { ChangeEvent, useCallback, useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
  FormControl,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  Checkbox,
  Paper,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import clsx from "clsx";
import Label from "Component/Label";
import { AttachmentImageFile } from "Component/AttachmentImageFile";
import { callWebApi } from "Common/Utility/Api";
import { AccountSetting, MyAccountInfo, DefaultNotification, DefaultSendMailTime } from "Models/AccountSetting";
import { toBlob, removeImagePrefix } from "Common/Utility/Blob";
import { useExecuteEx } from "Hooks/useFetch";
import { useAlertAdd } from "Common/Component/AlertList";
import VirtualaizedTable, { InputState, ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { NotificationMailSendType } from "Pages/Contact/Contact";
import { DateUtility } from "Common/Utility/DateUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import { AppActionTypes, AppProvider } from "App";
import { DigitsWithHyphenField } from "Component/DigitsField";
import { RaLMAccount } from "Models/RaLMAccount";
import { validateResponse } from "Common/Utility/HttpUtility";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  box: {
    width: 708,
    height: 550,
  },
  select: {
    paddingRight: theme.spacing(1),
    overflowY: "auto",
    overflowX: "hidden",
  },
  selected: {
    backgroundColor: "#CCE8FF",
  },
  setting: {
    marginLeft: theme.spacing(1),
    width: 500,
    height: "100%",
  },
  inline: {
    display: "inline-block",
    verticalAlign: "top",
  },
  element: {
    overflowY: "scroll",
    overflowX: "hidden",
  },
  inEdit: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    margin: theme.spacing(1),
  },
  toDesktopTop: {
    marginTop: theme.spacing(1) * 1,
  },
  colon: {
    width: 15,
    marginTop: theme.spacing(1),
    display: "inline-block",
    verticalAlign: "top",
  },
  sendMailTimesTable: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    height: 200,
    margin: theme.spacing(1),
  },
}));

interface TabProps<T> {
  data: T;
  setData: React.Dispatch<React.SetStateAction<AccountSetting>>;
  edited: () => void;
  inputState: InputState;
  setInputState: React.Dispatch<React.SetStateAction<InputState>>;
}

const NotificationTab = (props: TabProps<DefaultNotification>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const validSendMailTime = useMemo(
    () => props.data.category === NotificationMailSendType.regular,
    [props.data.category]
  );

  const [time, setTime] = useState({ hour: "00", minutes: "00" });

  const inputManager = useInputManager(setTime);

  const setInputState = useMemo(() => props.setInputState, [props.setInputState]);

  const edited = useMemo(() => props.edited, [props.edited]);

  const setData = useMemo(() => props.setData, [props.setData]);

  const handleOnUnmounted = useCallback(
    (inputState: InputState) => {
      if (inputState === InputState.Adding) {
        setData((value) => {
          value.notification.sendMailTimes.pop();
          return { ...value };
        });
      }
    },
    [setData]
  );

  const handleOnChangeToDesktop = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setData((value) => {
      value.notification.toDesktop = checked;
      return { ...value };
    });

    edited();
  };

  const handleOnChangeSelected = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => {
    setData((value) => {
      value.notification.category = event.target.value as number;
      return { ...value };
    });

    edited();
  };

  const handleOnClickAdd = useCallback(() => {
    setData((value) => {
      value.notification.sendMailTimes = [...value.notification.sendMailTimes, { sendAt: "" }];
      return { ...value };
    });
    setTime({ hour: "00", minutes: "00" });
  }, [setData]);

  const handleOnClickSave = useCallback(
    (data: DefaultSendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setData((value) => {
        value.notification.sendMailTimes[rowIndex].sendAt = `${time.hour}:${time.minutes}:00`;
        return { ...value };
      });

      edited();
    },
    [setData, edited, time]
  );

  const handleOnClickEdit = useCallback(
    (data: DefaultSendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setTime({ hour: data.sendAt.substr(0, 2), minutes: data.sendAt.substr(3, 2) });
    },
    []
  );

  const handleOnClickCancel = useCallback(() => {
    if (props.inputState === InputState.Adding) {
      setData((value) => {
        value.notification.sendMailTimes.pop();
        return { ...value };
      });
    }
  }, [props.inputState, setData]);

  const handleOnClickDelete = useCallback(
    async (data: DefaultSendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setData((value) => {
        value.notification.sendMailTimes.splice(rowIndex, 1);
        return { ...value };
      });

      edited();
    },
    [setData, edited]
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: validSendMailTime ? EditType.EditButton : undefined,
      },
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: validSendMailTime ? EditType.DeleteButton : undefined,
      },
      {
        width: 100,
        label: "通知時間",
        dataKey: "sendAt",
        headerAlign: "center",
        bodyAlign: "center",
        fit: true,
        editType: EditType.AllowEdit,
        component: (data: DefaultSendMailTime, rowIndex: number) => {
          return (
            <Box className={clsx(genericClasses.width100percent, genericClasses.textAlignCenter)}>
              {DateUtility.hhmm(data.sendAt)}
            </Box>
          );
        },
        componentWhenEditing: (rowIndex: number) => {
          return (
            <>
              <FormControl>
                <Select onChange={inputManager.handleOnChangeSelect("hour")} value={time.hour}>
                  {[...Array(24)].map((_, i) => {
                    const h = ("00" + String(i)).slice(-2);

                    return (
                      <MenuItem key={i} value={h}>
                        {h}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box className={clsx(classes.inline, classes.colon)}>：</Box>
              <FormControl>
                <Select onChange={inputManager.handleOnChangeSelect("minutes")} value={time.minutes}>
                  {["00", "30"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          );
        },
      },
    ],
    [
      classes.colon,
      classes.inline,
      genericClasses.width100percent,
      genericClasses.textAlignCenter,
      validSendMailTime,
      time,
      inputManager,
    ]
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FormControlLabel
          className={clsx(genericClasses.width100percent, classes.toDesktopTop)}
          control={<Checkbox color="primary" checked={props.data.toDesktop} onChange={handleOnChangeToDesktop} />}
          label="デスクトップ通知"
        />
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel id="select-customer">メール通知方法</InputLabel>
          <Select
            className={genericClasses.width200}
            id="select-category"
            value={props.data.category}
            onChange={handleOnChangeSelected}
          >
            <MenuItem key="0" value={0}>
              通知を受けない
            </MenuItem>
            <MenuItem key="1" value={1}>
              受信毎に知らせる
            </MenuItem>
            <MenuItem key="2" value={2}>
              定時に知らせる
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Paper className={clsx(classes.inline, classes.sendMailTimesTable)}>
          <VirtualaizedTable
            values={props.data.sendMailTimes}
            rowHeight={48}
            columns={columns}
            onClickAddDirectInput={validSendMailTime ? handleOnClickAdd : undefined}
            onClickSave={handleOnClickSave}
            onClickEdit={handleOnClickEdit}
            onClickCancel={handleOnClickCancel}
            onClickDelete={handleOnClickDelete}
            setEditing={setInputState}
            onUnmounted={handleOnUnmounted}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const AccountTab = (props: TabProps<MyAccountInfo>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const [imageFile, setImageFile] = useState<Blob | undefined>(undefined);

  const handleOnChange = (key: keyof MyAccountInfo) => (event: ChangeEvent<HTMLAreaElement | any>) => {
    const newValue = event.target.value;
    props.setData((value) => {
      value.accountInfo[key] = newValue;
      return { ...value };
    });

    props.edited();
  };

  const handleOnChangeImage = (blob: Blob | undefined) => {
    const reader = new FileReader();
    reader.onload = () => {
      props.setData((value) => {
        value.accountInfo.approvalStamp = removeImagePrefix(reader.result as string);
        return { ...value };
      });
    };

    setImageFile(blob);

    if (blob) {
      reader.readAsDataURL(blob);
    } else {
      props.setData((value) => {
        value.accountInfo.approvalStamp = null;
        return { ...value };
      });
    }

    props.edited();
  };

  useEffect(() => {
    const blob = toBlob(props.data.approvalStamp);

    if (blob) {
      setImageFile(blob);
    }
  }, [props.data.approvalStamp]);

  return (
    <>
      <Label className={classes.inEdit} caption="所属テナント" text={ralmAccount?.goodSeriesInfo?.tenantName ?? ""} />
      <Label
        className={classes.inEdit}
        caption="プラン名"
        text={ralmAccount?.goodSeriesInfo?.plans?.map((value) => value.text) ?? ""}
      />
      <Label className={classes.inEdit} caption="ロール" text={props.data.roleName} />
      <TextField
        className={classes.inEdit}
        label="ユーザー名"
        value={props.data.userName}
        onChange={handleOnChange("userName")}
      />
      <DigitsWithHyphenField
        className={classes.inEdit}
        label="TEL"
        value={props.data.tel ?? ""}
        onChange={handleOnChange("tel")}
      />
      <TextField
        className={classes.inEdit}
        label="メールアドレス"
        value={props.data.email ?? ""}
        onChange={handleOnChange("email")}
      />
      <Box className={genericClasses.margin}>
        <Typography variant="caption" display="block">
          電子印
        </Typography>
        <AttachmentImageFile
          controlId={"accountSetting"}
          image={imageFile}
          onChangeImage={handleOnChangeImage}
          style={{ height: "100px" }}
        />
      </Box>
    </>
  );
};

interface SettingProps {
  open: boolean;
  onClose: (result: boolean) => void;
}

export default (props: SettingProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const appDispatch = AppProvider.useDispatch();

  const [edited, confirm] = useWhetherEdited(props.open);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [accountSetting, setAccountSetting] = useState({} as AccountSetting);

  const [inputState, setInputState] = useState<InputState>(InputState.None);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { accountSetting: AccountSetting; onClose: (result: boolean) => void }
      ) => {
        var response = await callWebApi().put<RaLMAccount>("/accountSettings", object.accountSetting);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "アカウント情報の保存に成功しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(true);

        appDispatch({ type: AppActionTypes.SET_RALMACCOUNT, value: response.data });
      },
      [alertAdd, appDispatch]
    )
  );

  const handleOnClickSaveInAction = () => {
    if (!accountSetting.accountInfo.userName) {
      alertAdd({ type: "info", message: "ユーザー名は必須項目です。" });
      return;
    }

    executePut({ accountSetting: accountSetting, onClose: props.onClose });
  };

  const handleOnSelected = useCallback(
    (index: number) => () => {
      setSelectedIndex(index);
    },
    []
  );

  const listText = useMemo(() => {
    const state = {
      setData: setAccountSetting,
      edited: edited,
      inputState: inputState,
      setInputState: setInputState,
    };

    return [
      {
        text: "アカウント",
        element: <AccountTab data={accountSetting.accountInfo} {...state} />,
      },
      {
        text: "通知",
        element: <NotificationTab data={accountSetting.notification} {...state} />,
      },
    ];
  }, [edited, accountSetting, inputState, setAccountSetting]);

  const list = useMemo(() => {
    return (
      <List>
        {
          <>
            {listText.map((value, index) => (
              <ListItem
                key={value.text + index}
                className={index === selectedIndex ? classes.selected : ""}
                button
                onClick={handleOnSelected(index)}
              >
                {value.text}
              </ListItem>
            ))}
          </>
        }
      </List>
    );
  }, [classes.selected, listText, selectedIndex, handleOnSelected]);

  useEffect(() => {
    if (props.open) {
      setSelectedIndex(0);

      setAccountSetting((value) => {
        if (ralmAccount != null) {
          value.accountInfo = {
            roleName: ralmAccount.roleName ?? "",
            userName: ralmAccount.userName ?? "",
            approvalStamp: ralmAccount.approvalStamp ?? null,
            tel: ralmAccount.tel ?? "",
            email: ralmAccount.email ?? "",
            updatedAt: ralmAccount.updatedAt,
          };

          value.notification = ralmAccount.defaultNotification
            ? { ...ralmAccount.defaultNotification, sendMailTimes: [...ralmAccount.defaultNotification.sendMailTimes] }
            : ({
                category: 0,
                toDesktop: false,
                sendMailTimes: [] as DefaultSendMailTime[],
              } as DefaultNotification);
        }

        return { ...value };
      });
    }
  }, [ralmAccount, props.open]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(false))} open={props.open} fullWidth={false} maxWidth="md">
      <DialogTitle>アカウント設定</DialogTitle>
      <DialogContent>
        <Box className={classes.box}>
          <>
            <Box
              className={clsx(genericClasses.width200, genericClasses.height100percent, classes.select, classes.inline)}
            >
              {list}
            </Box>
            <Box className={clsx(classes.setting, classes.inline, classes.element)}>
              {listText[selectedIndex].element}
            </Box>
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose(false))} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickSaveInAction}
          color="primary"
          disabled={!props.open || inputState !== InputState.None || inProcess}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
