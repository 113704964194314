import { PublicClientApplication, Configuration } from "@azure/msal-browser";

export const configuration: Configuration = {
  auth: {
    clientId: "9ec27bea-8023-434c-ae60-7eb0eaec5602",
    authority: "https://login.microsoftonline.com/koeigoodoutlook.onmicrosoft.com",
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msal = new PublicClientApplication(configuration);
