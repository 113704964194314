import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useFetch, useExecuteEx } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { useContentHeight } from "Hooks/useResize";
import { EditRoleType, BasicRoleAuth, Auth } from "Models/Role";
import { menuInfos } from "Component/Menu";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { DateUtility } from "Common/Utility/DateUtility";
import { ComboItem } from "Common/Utility/GenericInterface";
import clsx from "clsx";
import { getEditAuthInfos } from "Common/Utility/Role";
import { validateResponse } from "Common/Utility/HttpUtility";
import TextField from "Component/TextField";
import { RestrictionModel } from "Models/Restriction";
import Label from "Component/Label";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { editButton } from "Common/Utility/AppUtility";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  editPaper: {
    width: "100%",
    height: 500,
  },
}));

type RemoveProps<T, K extends keyof T> = { [P in Exclude<keyof T, K>]: T[P] };

interface DialogProps {
  open: boolean;
  data: RestrictionModel;
  onClose: (restriction: RestrictionModel | null) => void;
}

const EditDialog = (props: DialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState<RestrictionModel>({} as RestrictionModel);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [edited, confirm] = useWhetherEdited(props.open, true);

  const menuAuthData = useMemo(() => {
    const auth = [] as BasicRoleAuth[];

    if (data.menuReferenceAuth == null || data.menuEditAuth == null) {
      return auth;
    }

    menuInfos.forEach((i) => {
      const info = i;

      if (i.administratorsOnly) {
        return;
      }

      auth.push({
        name: info.menu,
        bitIndex: info.bit,
        type: "menuEditAuth",
        isReference: Boolean(data.menuReferenceAuth & info.bit),
        isEdit: Boolean(data.menuEditAuth & info.bit),
        referenceOnly: i.referenceOnly,
      } as BasicRoleAuth);
    });

    return auth;
  }, [data.menuReferenceAuth, data.menuEditAuth]);

  const createEditAuth = useCallback((type: EditRoleType, target: number) => {
    const auth = [] as BasicRoleAuth[];

    if (target == null) {
      return auth;
    }

    Object.values(getEditAuthInfos(type)).forEach((info) => {
      auth.push({
        name: info.text,
        bitIndex: info.bit,
        type: type,
        isEdit: Boolean(target & info.bit),
      } as BasicRoleAuth);
    });

    return auth;
  }, []);

  const estimateAuthData = useMemo(
    () => createEditAuth("estimateEditAuth", data.estimateEditAuth),
    [createEditAuth, data]
  );

  const voucherAuthData = useMemo(
    () => createEditAuth("voucherEditAuth", data.voucherEditAuth),
    [createEditAuth, data]
  );

  const companyAuthData = useMemo(
    () => createEditAuth("companyEditAuth", data.companyEditAuth),
    [createEditAuth, data]
  );

  const systemAuthData = useMemo(() => createEditAuth("systemAuth", data.systemAuth), [createEditAuth, data]);

  const handleOnClose = useCallback(() => confirm(() => props.onClose(null)), [props, confirm]);

  const handleOnClickCancelInAction = useCallback(() => confirm(() => props.onClose(null)), [props, confirm]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { data: RestrictionModel; onClose: (restriction: RestrictionModel | null) => void }
      ) => {
        var response = await callWebApi().put<RestrictionModel>("/restrictions", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "機能制限を更新しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(() => {
    executePut({ data: data, onClose: props.onClose });
  }, [props.onClose, data, executePut]);

  const handleOnChangeAuthCategory = useCallback((event) => {
    setSelectedIndex(event.target.value);
  }, []);

  const changeAuth = useCallback(
    (key: keyof Auth, bit: number, beforeUpdate: (value: RestrictionModel) => void = () => {}) => {
      setData((value) => {
        beforeUpdate(value);

        return {
          ...value,
          [key]: value[key] ^ bit,
        };
      });
    },
    []
  );

  const handleOnChangeReferenceAuth = useCallback(
    (data: BasicRoleAuth) => {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (data.type === "menuEditAuth") {
          changeAuth("menuReferenceAuth", data.bitIndex);
        }

        edited();
      };
    },
    [edited, changeAuth]
  );

  const handleOnChangeEditAuth = useCallback(
    (data: BasicRoleAuth) => {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (data.type === "menuEditAuth") {
          changeAuth("menuEditAuth", data.bitIndex, (value) => {
            if (checked) {
              value.menuReferenceAuth |= data.bitIndex;
            }
          });
        } else {
          changeAuth(data.type, data.bitIndex);
        }

        edited();
      };
    },
    [edited, changeAuth]
  );

  useEffect(() => {
    if (props.open) {
      setData(props.data);
      setSelectedIndex(0);
    }
  }, [props.open, props.data]);

  const basicColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 150,
        label: "表示権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
              <Checkbox
                checked={data.isReference}
                color="primary"
                onChange={handleOnChangeReferenceAuth(data)}
                disabled={data.isEdit}
              />
            </Box>
          );
        },
      },
      {
        width: 150,
        label: "編集権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              {!(data.referenceOnly ?? false) && (
                <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
                  <Checkbox checked={data.isEdit} color="primary" onChange={handleOnChangeEditAuth(data)} />
                </Box>
              )}
            </>
          );
        },
      },
    ],
    [
      genericClasses.width100percent,
      genericClasses.textAlignCenter,
      handleOnChangeReferenceAuth,
      handleOnChangeEditAuth,
    ]
  );

  const authColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 150,
        label: "権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              {
                <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
                  <Checkbox checked={data.isEdit} color="primary" onChange={handleOnChangeEditAuth(data)} />
                </Box>
              }
            </>
          );
        },
      },
    ],
    [genericClasses.width100percent, genericClasses.textAlignCenter, handleOnChangeEditAuth]
  );

  const select = useMemo(() => {
    return [
      { text: "メニュー", value: { data: menuAuthData, columns: basicColumns } },
      { text: "見積管理", value: { data: estimateAuthData, columns: authColumns } },
      { text: "伝票管理", value: { data: voucherAuthData, columns: authColumns } },
      { text: "企業管理", value: { data: companyAuthData, columns: authColumns } },
      { text: "システム", value: { data: systemAuthData, columns: authColumns } },
    ] as ComboItem[];
  }, [menuAuthData, basicColumns, authColumns, estimateAuthData, voucherAuthData, companyAuthData, systemAuthData]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>機能制限の編集</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Label className={classes.inEdit} caption="テナント名" text={data.tenantName} underLine />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inEdit}>
              <InputLabel id="input-auth_category">分類</InputLabel>
              <Select id="select-auth_category" value={selectedIndex} onChange={handleOnChangeAuthCategory}>
                {select.map((value, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {value.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.editPaper}>
              {selectedIndex !== -1 && (
                <VirtualizedTable
                  values={select[selectedIndex].value.data}
                  rowHeight={48}
                  columns={select[selectedIndex].value.columns}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickSaveInAction}
          color="primary"
          disabled={!props.open || inProcess}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Restriction = () => {
  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState<RestrictionModel[]>([]);

  const [name, setName] = useState("");

  const [search, setSearch] = useState({
    name: "",
  });

  const [open, setOpen] = useState(false);

  const [editData, setEditData] = useState<RestrictionModel>({} as RestrictionModel);

  const fetchResultSearch = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<RestrictionModel[]>("/restrictions", {
          cancelToken: signal.token,
          params: { name: search.name },
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (response.data == null) {
          return;
        }

        setData(response.data);
      },
      [search, alertAdd]
    ),
    false
  );

  const handleOnClickSearch = useCallback(() => {
    setSearch({ name: name });
  }, [name]);

  const handleOnClickClear = useCallback(() => {
    setName("");
  }, []);

  const hanldeOnChangeName = useCallback((event) => setName(event.target.value), []);

  const handleOnClickEdit = useCallback(
    (restriction: RestrictionModel, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...restriction });
      setOpen(true);
    },
    []
  );

  const handleOnClose = useCallback((restriction: RestrictionModel | null) => {
    setOpen(false);

    if (restriction != null && restriction.id != null) {
      setData((value) => {
        const index = value.findIndex((i) => i.id === restriction.id);

        if (index === -1) {
          return value;
        }

        value[index] = restriction;
        return [...value];
      });
    }
  }, []);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.Restriction
  );

  const columns: ColumnData[] = useMemo(() => {
    const columnData: ColumnData[] = [
      editButton,
      {
        width: 250,
        label: "名前",
        dataKey: "tenantName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 200,
        label: "作成日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm"),
        visible: getVisible(1),
      },
      {
        width: 300,
        label: "作成者",
        dataKey: "createdBy",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "編集日",
        dataKey: "updatedAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm"),
        visible: getVisible(3),
      },
      {
        width: 300,
        label: "編集者",
        dataKey: "updatedBy",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      },
    ];

    return columnData;
  }, [getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.margin}
                label="テナント名"
                value={name}
                onChange={hanldeOnChangeName}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
              <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultSearch) ?? (
            <>
              <Paper className={classes.paper}>
                <VirtualizedTable
                  values={data}
                  tableHeight={height}
                  rowHeight={48}
                  columns={columns}
                  onClickEdit={handleOnClickEdit}
                  onCloseContextMenu={handleOnCloseContextMenu}
                  onChangeHeaderVisible={handleOnChangeHeaderVisible}
                  headerContext
                />
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
      <EditDialog open={open} data={editData} onClose={handleOnClose} />
    </>
  );
};

export default React.memo(Restriction);
