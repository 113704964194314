import { Button, Grid, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Box } from "@material-ui/core";
import { CancelTokenSource } from "axios";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { DateUtility } from "Common/Utility/DateUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { useContentHeight } from "Hooks/useResize";
import { Arrive, ShipmentDetail } from "Models/Shipment";
import React, { useRef, useState, useCallback, useMemo } from "react";
import VirtualaizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { useAlertAdd } from "Common/Component/AlertList";
import { useOpens } from "Hooks/useOpens";
import TextField from "Component/TextField";
import { ModelMaster } from "Models/ModelMaster";
import { SelectDialog, useNarrowDown, validateNarrowDownKey } from "Component/SelectDialog";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import DigitsField from "Component/DigitsField";
import { useMessageBox } from "Hooks/useMessageBox";
import { createBusinessNumber, removeArrayState } from "Common/Utility/AppUtility";
import { InspectionMode, modelColumns, ShipmentStatus } from "Common/Utility/Constants";
import InspectionResultDialog from "./InspectionResultDialog";

const useStyles = makeStyles((theme) => ({
  loadCustomers: {
    height: (props: any) => props.height,
  },
  paper: {
    height: (props: any) => props.height,
    padding: 8,
  },
  box: {
    width: "100%",
    height: (props: any) => {
      return props.height - 68 - 52;
    },
  },
}));

interface Input {
  numberOfReturn: number | null;

  numberOfFailures: number | null;
}

interface InputNumberOfReturnProps {
  onClose: (input?: Input) => void;

  arrive: Arrive;
}

const InputNumberOfReturn = (props: InputNumberOfReturnProps) => {
  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(true);

  const [input, setInput] = useState<Input>({
    numberOfReturn: null,
    numberOfFailures: null,
  });

  const inputManager = useInputManager(setInput, edited);

  const handleOnClickSaveInAction = () => {
    if (input.numberOfReturn == null || input.numberOfFailures == null) {
      return;
    }

    if (input.numberOfReturn + input.numberOfFailures === 0) {
      alertAdd({ type: "info", message: "返品数、故障数を合わせて1以上に設定してください。" });
      return;
    }

    if (
      props.arrive.numberOf <
      props.arrive.numberOfReturn + props.arrive.numberOfFailures + input.numberOfReturn + input.numberOfFailures
    ) {
      alertAdd({ type: "info", message: "返品数 + 故障数が貸出数を超えています。" });
      return;
    }

    props.onClose(input);
  };

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={true} fullWidth={false} maxWidth="md">
      <DialogTitle>返品数、故障数の設定</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <DigitsField
              className={genericClasses.width100percent}
              label="返品数"
              value={input.numberOfReturn}
              onChange={inputManager.handleOnChangeNumber("numberOfReturn")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <DigitsField
              className={genericClasses.width100percent}
              label="故障数"
              value={input.numberOfFailures}
              onChange={inputManager.handleOnChangeNumber("numberOfFailures")}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickSaveInAction}
          color="primary"
          disabled={input.numberOfReturn == null || input.numberOfFailures == null}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OpenIndex = {
  SelectShipment: 0,
  SelectModelMaster: 1,
  Max: 2,
};
type OpenIndex = typeof OpenIndex[keyof typeof OpenIndex];

interface Props {
  onClose: () => void;
}

export const ArriveInspection = React.memo((props: Props) => {
  const [height] = useContentHeight(56);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const messageBox = useMessageBox();

  const [input, setInput] = useState<{ value: string | undefined }>({ value: undefined });

  const inputManager = useInputManager(setInput);

  const [models, setModels] = useState<ModelMaster[]>([]);

  const [assetNumber, setAssetNumber] = useState<
    | {
        assetNumber?: string;
        isInspection: boolean;
      }
    | undefined
  >(undefined);

  const [rows, setRows] = useState<Arrive[]>([]);

  const rowIds = useRef<string[]>([]);

  const [arrvies, setArrives] = useState<Arrive[]>([]);

  const [arrive, setArrive] = useState<Arrive | undefined>(undefined);

  const [checkSheet, setCheckSheet] = useState<Arrive | undefined>(undefined);

  const [status, open, close] = useOpens(OpenIndex.Max);

  const [modelMasterId, setModelMasterId] = useState<{ id?: string } | undefined>(undefined);

  const handleOnClickInspection = () => {
    setAssetNumber({ assetNumber: input.value, isInspection: true });
  };

  const handleOnClickFailures = () => {
    setAssetNumber({ assetNumber: input.value, isInspection: false });
  };

  const handleOnClickDelete = useCallback(
    (data: Arrive, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      rowIds.current = rowIds.current.filter((i) => i !== data.lendingPeriodId);
      removeArrayState(setRows, rowIndex);
    },
    []
  );

  const handleOnClose = useCallback(
    (input?: Input) => {
      if (input && arrive != null) {
        rowIds.current.push(arrive.lendingPeriodId);
        setRows((value) => {
          arrive.inputReturn = input.numberOfReturn ?? 0;
          arrive.inputFailures = input.numberOfFailures ?? 0;
          return [...value, arrive];
        });
      }

      setArrive(undefined);
    },
    [arrive]
  );

  const handleOnCloseInspectionResultDialog = (data?: ShipmentDetail | Arrive) => {
    if (data) {
      const arrive = data as Arrive;
      rowIds.current.push(arrive.lendingPeriodId);
      setRows((value) => {
        return [...value, arrive];
      });
    }

    setCheckSheet(undefined);
    close();
  };

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          arrives: Arrive[];
        }
      ) => {
        var response = await callWebApi().post<Arrive[]>("/shipments/arrives", object.arrives);

        if (response.data == null) {
          return;
        }

        if (response.data.length === 0) {
          alertAdd({ type: "success", message: "帰庫検品確定が完了しました。" });

          if (unmounted.value) {
            return;
          }

          setRows([]);
          rowIds.current = [];
        } else {
          alertAdd({
            type: "info",
            message:
              "検品数が不正の為、処理をキャンセルしました。一覧の返品数を最新の状態に更新しました。検品の確認を行ってください。",
          });

          if (unmounted.value) {
            return;
          }

          rowIds.current = response.data.map((i) => i.lendingPeriodId);
          setRows(response.data);
        }
      },
      [alertAdd]
    )
  );

  const handleOnClick = () => {
    if (rows.length === 0) {
      alertAdd({ type: "info", message: "検品されていません。" });
      return;
    }

    executePost({ arrives: rows });
  };

  const handleOnNarrowDown = useCallback(
    (text: string) => {
      const split = validateNarrowDownKey(text);
      if (split.length === 0) {
        return arrvies;
      }

      return arrvies.filter((i) => {
        const shipDate = DateUtility.format(i.shipDate);
        const period = `${DateUtility.format(i.from)} - ${DateUtility.format(i.to)}`;
        const businessNumber = createBusinessNumber(i.createdAt, i.sequence);

        return split.some((find) => {
          return (
            shipDate.indexOf(find) >= 0 ||
            period.indexOf(find) >= 0 ||
            businessNumber.indexOf(find) >= 0 ||
            i.companyName.indexOf(find) >= 0 ||
            i.model.indexOf(find) >= 0 ||
            i.numberOf.toString().indexOf(find) >= 0 ||
            i.numberOfReturn.toString().indexOf(find) >= 0
          );
        });
      });
    },
    [arrvies]
  );

  const onceArrive = useCallback((arrive: Arrive, isInspection?: boolean) => {
    if (arrive.checkSheetName == null) {
      if (isInspection != null) {
        arrive.inputReturn = isInspection ? 1 : 0;
        arrive.inputFailures = isInspection ? 0 : 1;
        rowIds.current.push(arrive.lendingPeriodId);
        setRows((value) => {
          return [...value, arrive];
        });
      } else {
        setArrive(arrive);
      }
    } else {
      setCheckSheet(arrive);
    }
  }, []);

  const afterFetch = useCallback(
    (data: Arrive[], isInspection?: boolean) => {
      if (data == null) {
        return;
      }

      const arrives = data.filter((i) => !rowIds.current.some((id) => id === i.lendingPeriodId));

      if (arrives.length === 0) {
        alertAdd({ type: "info", message: "対象は検品済み又は貸出されていません。" });
      } else if (arrives.length === 1) {
        onceArrive(arrives[0], isInspection);
      } else {
        setArrives(arrives);
        open(OpenIndex.SelectShipment);
      }
    },
    [onceArrive, alertAdd, open]
  );

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Arrive[]>(`/shipments/modelmaster/${modelMasterId?.id}`, {
          cancelToken: signal.token,
        });

        afterFetch(response.data);
      },
      [afterFetch, modelMasterId]
    ),
    false
  );

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (assetNumber?.assetNumber == null) {
          return;
        }
        const response = await callWebApi().get<Arrive[]>("/shipments/assetNumber", {
          cancelToken: signal.token,
          params: { assetNumber: assetNumber.assetNumber },
        });

        afterFetch(response.data, assetNumber.isInspection);
      },
      [afterFetch, assetNumber]
    ),
    false
  );

  const loadingButton = useLoadingElement(
    classes.loadCustomers,
    LoadingMode.Simple,
    useSimpleFetch("/modelMaster", setModels)
  );

  const columns: ColumnData[] = useMemo(() => {
    let columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.DeleteButton,
      },
      {
        width: 150,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
      },
      {
        width: 200,
        label: "貸出期間",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) =>
          `${DateUtility.format(rowData.from)} - ${rowData.to ? DateUtility.format(rowData.to) : ""}`,
      },
      {
        width: 150,
        label: "受注番号",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) => createBusinessNumber(rowData.createdAt, rowData.sequence),
      },
      {
        width: 150,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 100,
        label: "貸出",
        dataKey: "numberOf",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 100,
        label: "返品",
        dataKey: "numberOfReturn",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 100,
        label: "故障",
        dataKey: "numberOfFailures",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 110,
        label: "検品(返品)",
        dataKey: "inputReturn",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 110,
        label: "検品(故障)",
        dataKey: "inputFailures",
        headerAlign: "center",
        bodyAlign: "right",
      },
    ];

    return columns;
  }, []);

  const arriveColumns: ColumnData[] = useMemo(() => {
    let columns: ColumnData[] = [
      {
        width: 150,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
      },
      {
        width: 300,
        label: "貸出期間",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) =>
          `${DateUtility.format(rowData.from)} - ${rowData.to ? DateUtility.format(rowData.to) : ""}`,
      },
      {
        width: 150,
        label: "受注番号",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) => createBusinessNumber(rowData.createdAt, rowData.sequence),
      },
      {
        width: 150,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 200,
        label: "型式",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "model",
      },
      {
        width: 100,
        label: "貸出",
        dataKey: "numberOf",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 100,
        label: "返品",
        dataKey: "numberOfReturn",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 100,
        label: "故障",
        dataKey: "numberOfFailures",
        headerAlign: "center",
        bodyAlign: "right",
      },
    ];

    return columns;
  }, []);

  const handleOnClickCancelInAction = async () => {
    if (rows.length > 0) {
      if (!(await messageBox.confirm("終了確認", "未確定の検品があります。\n確定せずに帰庫検品を終了しますか？"))) {
        return;
      }
    }
    props.onClose();
  };

  return (
    <>
      <Dialog onClose={handleOnClickCancelInAction} open={true} fullWidth={true} maxWidth="xl">
        <DialogTitle>帰庫検品</DialogTitle>
        <DialogContent>
          <>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <TextField
                  label="資産番号"
                  value={input.value}
                  onChange={inputManager.handleOnChange("value")}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      handleOnClickInspection();
                    }
                  }}
                />
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="primary"
                  onClick={handleOnClickInspection}
                >
                  検品
                </Button>
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="secondary"
                  onClick={handleOnClickFailures}
                >
                  故障
                </Button>
                {loadingButton ?? (
                  <Button
                    className={genericClasses.margin}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAssetNumber(undefined);
                      open(OpenIndex.SelectModelMaster);
                    }}
                  >
                    型式
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <VirtualaizedTable
                  values={rows}
                  setValues={setRows}
                  rowHeight={48}
                  columns={columns}
                  onClickDelete={handleOnClickDelete}
                />
              </Box>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
            閉じる
          </Button>
          <Button className={genericClasses.margin} color="primary" onClick={handleOnClick} disabled={inProcess}>
            帰庫検品確定
          </Button>
        </DialogActions>
      </Dialog>
      <SelectDialog
        title="型式"
        open={status(OpenIndex.SelectModelMaster)}
        columns={modelColumns}
        data={models}
        onClose={(result: ModelMaster | null) => {
          if (result) {
            setModelMasterId({ id: result.id });
          }
          close(OpenIndex.SelectModelMaster);
        }}
        onClickNarrowDown={useNarrowDown(models, "model", "name")}
      />
      <SelectDialog
        title="出荷"
        open={status(OpenIndex.SelectShipment)}
        columns={arriveColumns}
        data={arrvies}
        onClose={(result: Arrive | null) => {
          if (result) {
            onceArrive(result, assetNumber?.isInspection);
          }
          close(OpenIndex.SelectShipment);
        }}
        onClickNarrowDown={handleOnNarrowDown}
        maxWidth="xl"
      />
      {arrive != null && <InputNumberOfReturn onClose={handleOnClose} arrive={arrive} />}
      {checkSheet != null && (
        <InspectionResultDialog
          mode={InspectionMode.Arrive}
          data={checkSheet}
          onClose={handleOnCloseInspectionResultDialog}
          status={ShipmentStatus.Created}
          isInspection={assetNumber?.isInspection}
        />
      )}
    </>
  );
});
