import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { ModelMaster } from "Models/ModelMaster";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { CallOnClose, InputPending, onCloseWithSave, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { CancelTokenSource } from "axios";
import { ComboItem } from "Common/Utility/GenericInterface";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { Design } from "Common/Utility/Constants";
import { CheckSheet } from "Models/CheckSheet";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  supplier: {
    width: "100%",
    borderBottom: "solid 1px #949494",
  },
  fetchSupplier: {
    width: "100%",
    height: Design.componentUnitHeight,
  },
  fetchCheckSheet: {
    width: "100%",
    height: Design.componentUnitHeight,
  },
}));

interface EditModelProps extends InputPending {
  open: boolean;
  data: ModelMaster;
}

export const EditModelDialog = React.memo((props: EditModelProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [supplier, setSupplier] = useState<ComboItem[]>([]);

  const [data, setData] = useState(props.data);

  const [indexData, setIndexData] = useState<{ id: string | null; name: string; viewIndexRule: string }[]>([]);

  const [checkSheet, setCheckSheet] = useState<CheckSheet[]>([]);

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [edited, confirm] = useWhetherEdited(props, isAssetMenuEditValid);

  const inputManager = useInputManager(setData, edited);

  const fetchSupplier = useLoadingElement(
    classes.fetchSupplier,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const responseSupplier = await callWebApi().get<ComboItem[]>("/companies/supplier", {
          cancelToken: signal.token,
        });
        responseSupplier.data.unshift({ value: null, text: "未選択" });
        setSupplier(responseSupplier.data);
      }, [])
    )
  );

  const fetchIndexRule = useLoadingElement(
    classes.fetchSupplier,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        let responseIndexData = await callWebApi().get<{ id: string | null; name: string; viewIndexRule: string }[]>(
          "/indexrules",
          { cancelToken: signal.token }
        );
        responseIndexData.data.unshift({ id: null, name: "未選択", viewIndexRule: "" });
        setIndexData(responseIndexData.data);
      }, [])
    )
  );

  const fetchCheckSheet = useLoadingElement(
    classes.fetchCheckSheet,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        let response = await callWebApi().get<CheckSheet[]>("/checksheets", { cancelToken: signal.token });
        response.data.unshift({ name: "未選択", items: [] });
        setCheckSheet(response.data);
      }, [])
    )
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: ModelMaster; onClose: CallOnClose }) => {
        var response = await callWebApi().put<ModelMaster>("/modelmaster", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (object.data.id === undefined) {
          alertAdd({ type: "success", message: "新規型式を追加しました。" });
        } else {
          alertAdd({ type: "success", message: "型式情報を変更しました。" });
        }

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "型式",
    <EditModelDialog open={true} data={data} onClose={props.onClose} />,
    props.onClose,
    props.onHold
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    if (!data.model) {
      alertAdd({ type: "info", message: "型式を入力してください。" });
      return;
    }
    if (!data.name) {
      alertAdd({ type: "info", message: "名称を入力してください。" });
      return;
    }
    if (!data.cost) {
      data.cost = 0;
    }

    executePut({ data: data, onClose: props.onClose });
  }, [alertAdd, props, data, executePut]);

  const handleOnClickNarrowDown = useNarrowDown(supplier, "text");

  const handleOnClickIndexRuleNarrowDown = useNarrowDown(indexData, "name", "viewIndexRule");

  const handleOnClickCheckSheetNarrowDown = useNarrowDown(checkSheet, "name");

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "企業名",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const indexRuleColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 150,
        label: "採番ルール",
        dataKey: "viewIndexRule",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const checkSheetColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={false} maxWidth="sm">
      <DialogTitle>型式情報</DialogTitle>
      <DialogContent>
        <DatePickersUtilsProvider>
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="型式"
                value={data.model}
                onChange={inputManager.handleOnChange("model")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="名称"
                value={data.name}
                onChange={inputManager.handleOnChange("name")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={12}>
              {fetchSupplier ?? (
                <LabelWithSelect
                  className={classes.supplier}
                  caption="仕入先"
                  text={data.supplierName}
                  data={supplier}
                  columns={columns}
                  onClickNarrowDown={handleOnClickNarrowDown}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, supplierId: result.value, supplierName: result.text };
                  })}
                  maxWidth="sm"
                  disabled={!isAssetMenuEditValid}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <DigitsField
                className={classes.textField}
                label="原価"
                value={data.cost}
                onChange={inputManager.handleOnChangeNumber("cost")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={12}>
              {fetchIndexRule ?? (
                <LabelWithSelect
                  className={classes.supplier}
                  caption="採番ルール"
                  text={data.indexRuleName == null || data.indexRuleName === "" ? "未選択" : data.indexRuleName}
                  data={indexData}
                  columns={indexRuleColumns}
                  onClickNarrowDown={handleOnClickIndexRuleNarrowDown}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, indexRuleId: result.id, indexRuleName: result.name };
                  })}
                  maxWidth="sm"
                  disabled={!isAssetMenuEditValid}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {fetchCheckSheet ?? (
                <LabelWithSelect
                  className={classes.supplier}
                  caption="チェックシート"
                  text={data.checkSheetName == null || data.checkSheetName === "" ? "未選択" : data.checkSheetName}
                  data={checkSheet}
                  columns={checkSheetColumns}
                  onClickNarrowDown={handleOnClickCheckSheetNarrowDown}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, checkSheetId: result.id, checkSheetName: result.name };
                  })}
                  maxWidth="sm"
                  disabled={!isAssetMenuEditValid}
                />
              )}
            </Grid>
          </Grid>
        </DatePickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        {isAssetMenuEditValid ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
