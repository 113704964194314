import React, { useMemo, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { callWebApi } from "Common/Utility/Api";
import { OriginalVoucherSummary } from "Models/OriginalVoucher";
import OriginalVoucherList from "Pages/Voucher/OriginalVoucherList";
import { Props } from "./BusinessDetail";
import { CancelTokenSource } from "axios";
import { useFetch } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { useGenericStyles } from "Common/Utility/Styles";
import { useContentHeight } from "Hooks/useResize";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  loading: {
    height: (props: any) => props.height,
  },
  padding: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const OriginalVoucherInBusiness = (props: Props) => {
  const [height] = useContentHeight(
    Design.topButtonHeight + Design.componentUnitHeight + Design.margin + 64 + Design.margin
  );

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const [summaries, setSummaries] = useState<OriginalVoucherSummary[]>([]);

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<OriginalVoucherSummary[]>("/originalvoucher", {
          cancelToken: signal.token,
          params: {
            customerId: businessInfo.customerId,
            businessId: businessInfo.id,
          },
        });

        if (response.data == null) {
          setSummaries([]);
        } else {
          setSummaries(response.data);
        }
      },
      [businessInfo.customerId, businessInfo.id]
    )
  );

  const handleOnClickReload = useCallback(() => fetchResultLoad.reload(), [fetchResultLoad]);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Voucher);

  return (
    <>
      <Paper>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography className={genericClasses.margin}>元伝票情報</Typography>
              <IconButton
                className={genericClasses.margin}
                color="primary"
                aria-label="udpate"
                onClick={handleOnClickReload}
              >
                <Autorenew />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.padding}>
            {useLoadingElement(classes.loading, LoadingMode.Simple, fetchResultLoad) ?? (
              <OriginalVoucherList
                summaries={summaries}
                setSummaries={setSummaries}
                customerId={businessInfo.customerId}
                customerName={businessInfo.customerName}
                businessId={businessInfo.id}
                businessSequence={businessInfo.sequence}
                businessCreatedAt={businessInfo.createdAt}
                height={height}
                editAuth={isValidEdit}
              />
            )}
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default React.memo(OriginalVoucherInBusiness);
