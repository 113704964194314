import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@material-ui/core";
import { callWebApi } from "Common/Utility/Api";
import { DateUtility } from "Common/Utility/DateUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useGenericStyles } from "Common/Utility/Styles";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import {
  CallOnClose,
  InputPending,
  onCloseWithExclusion,
  onCloseWithSave,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import { AssetMaster, StockHistory } from "Models/Asset";
import { AssetCategory } from "Common/Utility/Constants";
import { validateResponse } from "Common/Utility/HttpUtility";
import DigitsField, { DigitsWithHyphenField } from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import VirtualizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { Delete } from "@material-ui/icons";
import { ModelMaster } from "Models/ModelMaster";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { AssetGroup } from "Models/AssetGroup";
import { useHoldInput } from "Hooks/useHoldInput";

const useStyles = makeStyles((theme) => ({
  selectCustomer: {
    width: "100%",
    borderBottom: "solid 1px #949494",
  },
  fetch: {
    width: "100%",
    height: 48,
  },
  stockHistoryPaper: {
    width: "100%",
    height: 300,
  },
}));

const initialStockHistory: StockHistory = {
  id: null,
  assetMasterId: null,
  numberOf: 1,
  memo: "",
  creatorName: "",
  createdAt: null,
};

interface EditStockHistoryProps {
  open: boolean;

  stockHistory: StockHistory;

  isEdit: boolean;

  onClose: (stockHistory?: StockHistory) => void;
}

const EditStockHistory = React.memo((props: EditStockHistoryProps) => {
  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props.open, props.isEdit);

  const [stockHistory, setStockHistory] = useState<StockHistory>({ ...initialStockHistory });

  const inputManager = useInputManager(setStockHistory, edited);

  useEffect(() => {
    if (props.open) {
      setStockHistory(props.stockHistory);
    }
  }, [props.open, props.stockHistory]);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose(undefined))} open={props.open} fullWidth={true} maxWidth="sm">
        <DialogTitle>資産数編集</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={12}>
              <DigitsWithHyphenField
                className={genericClasses.width100percent}
                label="増減数"
                value={stockHistory.numberOf}
                onChange={inputManager.handleOnChange("numberOf")}
                disabled={!props.isEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="理由"
                value={stockHistory.memo}
                onChange={inputManager.handleOnChange("memo")}
                multiline
                rows={5}
                disabled={!props.isEdit}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            {props.isEdit ? "キャンセル" : "閉じる"}
          </Button>
          {props.isEdit && (
            <Button
              className={genericClasses.margin}
              onClick={() => {
                if (stockHistory.numberOf == null) {
                  alertAdd({ type: "info", message: "増減数は必須項目です。" });
                  return;
                }

                const numberOf = Number(stockHistory.numberOf);
                if (isNaN(numberOf) || numberOf === 0) {
                  alertAdd({ type: "info", message: "増減数には0以外の数値を設定してください。" });
                  return;
                }

                stockHistory.numberOf = numberOf;
                props.onClose(stockHistory);
              }}
              color="primary"
            >
              保存
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
});

interface EditAssetMasterProps extends InputPending {
  open: boolean;
  asset: AssetMaster;
}

export const EditAssetMasterDialog = React.memo((props: EditAssetMasterProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [asset, setAsset] = useState(props.asset);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [edited, confirm] = useWhetherEdited(props, isValidEdit);

  const inputManager = useInputManager(setAsset, edited);

  const [stockHistories, setStockHistories] = useState<StockHistory[]>(props.asset.stockHistories);

  const [stockHistory, setStockHistory] = useState<StockHistory>({ ...initialStockHistory });

  const [open, setOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(true);

  const [model, setModel] = useState<ModelMaster[]>([]);

  const [assetGroup, setAssetGroup] = useState<AssetGroup[]>([]);

  const handleOnNarrowDownAssetGroup = useNarrowDown(assetGroup, "name");

  const handleOnNarrowDownModelMaster = useNarrowDown(model, "name", "model", "indexRuleName");

  const fetchModelmaster = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      let response = await callWebApi().get<ModelMaster[]>("/modelmaster", { cancelToken: signal.token });
      setModel(response.data);
    }, [])
  );

  const fetchAssetGroup = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      let responseAssetGroup = await callWebApi().get<AssetGroup[]>("/assetgroup", { cancelToken: signal.token });
      responseAssetGroup.data.unshift({ id: null, name: "未選択" } as AssetGroup);
      setAssetGroup(responseAssetGroup.data);
    }, [])
  );

  const [executeDelete, inDeleteProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { asset: AssetMaster; onClose: CallOnClose }) => {
        await callWebApi().delete<AssetMaster>(`/assetmaster/${object.asset.id}`);

        alertAdd({ type: "success", message: "資産を削除しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithExclusion(object.asset.id));
      },
      [alertAdd]
    )
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          asset: AssetMaster;
          stockHistories: StockHistory[];
          onClose: CallOnClose;
        }
      ) => {
        object.asset.stockHistories = object.stockHistories;
        const response = await callWebApi().put<AssetMaster>("/assetmaster", object.asset);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (object.asset.id === undefined) {
          alertAdd({ type: "success", message: "新規資産を追加しました。" });
        } else {
          alertAdd({ type: "success", message: "資産情報を変更しました。" });
        }

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickDeleteInAction = async () => {
    if (await message.confirm("削除確認", "資産情報を削除します、よろしいですか？")) {
      executeDelete({ asset: asset, onClose: props.onClose });
    }
  };

  const handleOnClickHoldInAction = useHoldInput(
    "資産",
    <EditAssetMasterDialog open={true} asset={asset} onClose={props.onClose} />,
    props.onClose,
    props.onHold,
    () => {
      asset.stockHistories = stockHistories;
    }
  );

  const handleOnClickSaveInAction = useCallback(() => {
    if (!asset.modelMasterId) {
      alertAdd({ type: "info", message: "型式は必須項目です。" });
      return;
    }

    executePut({
      asset: asset,
      stockHistories: stockHistories,
      onClose: props.onClose,
    });
  }, [alertAdd, props, asset, executePut, stockHistories]);

  const handleOnClickAdd = useCallback(() => {
    setStockHistory({ ...initialStockHistory });
    setIsEdit(true);
    setOpen(true);
  }, []);

  const handleOnClickDelete = useCallback(
    (stockHistory: StockHistory, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return () => {
        setStockHistories((value) => {
          value.splice(rowIndex, 1);
          return [...value];
        });
      };
    },
    []
  );

  const handleOnClickReference = useCallback(
    (stockHistory: StockHistory, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setStockHistory(stockHistory);
      setIsEdit(false);
      setOpen(true);
    },
    []
  );

  const handleOnClose = useCallback((stockHistory?: StockHistory) => {
    if (stockHistory) {
      setStockHistories((value) => {
        return [...value, stockHistory];
      });
    }

    setOpen(false);
  }, []);

  const assetGroupColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "グループ名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const stockHistoryColumns: ColumnData[] = useMemo(() => {
    let columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
      },
    ];

    if (isValidEdit) {
      columns.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        rendererInCell: (data: StockHistory, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return data.id == null ? (
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={handleOnClickDelete(data, columnData, rowIndex, columnIndex)}
            >
              <Delete />
            </IconButton>
          ) : (
            <></>
          );
        },
      });
    }

    columns.push(
      {
        width: 150,
        label: "日付",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data),
      },
      {
        width: 100,
        label: "増減数",
        dataKey: "numberOf",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 150,
        label: "理由",
        dataKey: "memo",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      }
    );
    return columns;
  }, [isValidEdit, handleOnClickDelete]);

  const modelColumns: ColumnData[] = [
    {
      width: 250,
      label: "型式",
      dataKey: "model",
      headerAlign: "center",
      bodyAlign: "left",
    },
    {
      width: 300,
      label: "名称",
      dataKey: "name",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
    },
    {
      width: 250,
      label: "採番ルール",
      dataKey: "indexRuleName",
      headerAlign: "center",
      bodyAlign: "left",
    },
  ];

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="md">
        <DialogTitle>資産情報</DialogTitle>
        <DialogContent>
          <DatePickersUtilsProvider>
            <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
              <Grid item xs={3}>
                <FormControl className={genericClasses.width100percent}>
                  <InputLabel id="select-customer">資産分類</InputLabel>
                  <Select
                    id="select-category"
                    onChange={inputManager.handleOnChangeSelect("category")}
                    value={asset.category}
                    disabled={!isValidEdit}
                  >
                    {AssetCategory.map((value, index) => {
                      return (
                        <MenuItem key={index} value={value.value}>
                          {value.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                {useLoadingElement(classes.fetch, LoadingMode.Simple, fetchAssetGroup) ?? (
                  <LabelWithSelect
                    className={classes.selectCustomer}
                    caption="資産グループ"
                    text={asset.assetGroupName}
                    data={assetGroup}
                    columns={assetGroupColumns}
                    onClickNarrowDown={handleOnNarrowDownAssetGroup}
                    onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                      return { ...value, assetGroupId: result.id, assetGroupName: result.name };
                    })}
                    maxWidth="sm"
                    disabled={!isValidEdit}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className={genericClasses.width100percent}
                  label="資産番号"
                  value={asset.assetNumber}
                  onChange={(event) => {
                    setAsset((value) => {
                      return { ...value, numberOfAsset: undefined };
                    });
                    inputManager.handleOnChange("assetNumber")(event);
                  }}
                  disabled={
                    !isValidEdit ||
                    (props.asset.id != null && (props.asset.id == null || props.asset.assetNumber == null)) ||
                    (props.asset.id == null && asset.indexRuleId !== null && asset.indexRuleId !== "")
                  }
                />
              </Grid>
              <Grid item xs={9}>
                {useLoadingElement(classes.fetch, LoadingMode.Simple, fetchModelmaster) ?? (
                  <LabelWithSelect
                    className={classes.selectCustomer}
                    caption="型式"
                    text={asset.model}
                    data={model}
                    columns={modelColumns}
                    onClickNarrowDown={handleOnNarrowDownModelMaster}
                    onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                      return {
                        ...value,
                        modelMasterId: result.id,
                        model: result.model,
                        name: result.name,
                        indexRuleId: result.indexRuleId,
                      };
                    })}
                    maxWidth="md"
                    disabled={!isValidEdit}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <DigitsField
                  className={genericClasses.width100percent}
                  label="原価"
                  value={asset.cost ?? ""}
                  onChange={inputManager.handleOnChangeNumber("cost")}
                  disabled={!isValidEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.stockHistoryPaper}>
                  <VirtualizedTable
                    values={stockHistories}
                    setValues={setStockHistories}
                    rowHeight={48}
                    columns={stockHistoryColumns}
                    onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                    onClickReference={handleOnClickReference}
                  />
                </Paper>
              </Grid>
            </Grid>
          </DatePickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          {isValidEdit && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess || inDeleteProcess}
            >
              保留
            </Button>
          )}
          {isValidEdit && asset.id && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickDeleteInAction}
              color="secondary"
              disabled={!props.open || inProcess || inDeleteProcess}
            >
              削除
            </Button>
          )}
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            {isValidEdit ? "キャンセル" : "閉じる"}
          </Button>
          {isValidEdit && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess || inDeleteProcess}
            >
              保存
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <EditStockHistory open={open} stockHistory={stockHistory} isEdit={isEdit} onClose={handleOnClose} />
    </>
  );
});
