import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useInputManager } from "Common/Utility/HandleUtility";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import DigitsField from "Component/DigitsField";
import { RepairHistoryParts } from "Models/RepairHistory";
import { RepairParts } from "Models/RepairParts";
import { useCallFetch } from "Hooks/useCallFetch";

const useStyles = makeStyles((theme) => ({
  quantity: {
    width: 100,
  },
}));

interface Props {
  data: RepairHistoryParts & { index?: number };

  onClose: (data: (RepairHistoryParts & { index?: number }) | undefined) => void;
}

export const EditRepairHistoryPartsDialog = React.memo((props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const [data, setData] = useState<RepairHistoryParts & { index?: number }>(props.data);

  const [edited, confirm] = useWhetherEdited(true, true);

  const inputManager = useInputManager(setData, edited);

  const [repairParts, fetchRepairParts] = useCallFetch<RepairParts>("", "/repairParts");

  const narrowDownRepairParts = useNarrowDown(repairParts, "maker", "productNumber", "name", "amount");

  const columnsRepairParts: ColumnData[] = useMemo(
    () => [
      {
        width: 200,
        label: "メーカー",
        dataKey: "maker",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 200,
        label: "品番",
        dataKey: "productNumber",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 300,
        label: "品名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 120,
        label: "価格",
        dataKey: "amount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowData: RepairParts) => (rowData.amount == null ? "" : data.toLocaleString() + " 円"),
      },
    ],
    []
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    props.onClose(data);
  }, [props, data]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(undefined))} open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle>修理部品情報</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            {fetchRepairParts ?? (
              <LabelWithSelect
                className={genericClasses.width100percent}
                caption="修理部品"
                text={data.repairPartsId == null ? "" : `${data.maker} - ${data.productNumber} - ${data.name}`}
                data={repairParts}
                columns={columnsRepairParts}
                onClickNarrowDown={narrowDownRepairParts}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  const { id, updatedAt, ...other } = result;
                  return { ...value, repairPartsId: id, ...other };
                })}
                maxWidth="md"
                underLine
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <DigitsField
              className={classes.quantity}
              label="使用数"
              value={data.quantity}
              onChange={inputManager.handleOnChangeNumber("quantity")}
              maxLength={9}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={genericClasses.margin}
          onClick={() => confirm(() => props.onClose(undefined))}
          color="primary"
        >
          キャンセル
        </Button>
        <Button className={genericClasses.margin} onClick={handleOnClickSaveInAction} color="primary">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
});
