import { AppActionTypes, AppProvider } from "App";
import { OnClose } from "Common/Utility/AppUtility";
import React, { useCallback, useEffect } from "react";
import { useUnmount } from "./useUnmount";

export const useOnCloseEditDialog = <T, U>(
  category: string,
  setEditData: React.Dispatch<React.SetStateAction<T | undefined>>,
  setListData: React.Dispatch<React.SetStateAction<U[]>>,
  compareKey: keyof U,
  key?: string
) => {
  const unmonted = useUnmount();

  const appDispatch = AppProvider.useDispatch();

  const savedPendingData = AppProvider.useGlobalState("savedPendingData");

  const handleOnClose = useCallback(
    (args?: { onClose: OnClose; key?: string }) => {
      try {
        if (args == null) {
          return;
        }

        appDispatch({
          type: AppActionTypes.SAVED_PENDING_DATA,
          value: { category: category, key: args.key, value: args.onClose },
        });
      } finally {
        if (unmonted.current) {
          return;
        }

        setEditData(undefined);
      }
    },
    [unmonted, appDispatch, setEditData, category]
  );

  useEffect(() => {
    if (savedPendingData && savedPendingData.category === category) {
      if (key == null || savedPendingData.key === key) {
        savedPendingData.value(setListData, compareKey);
        appDispatch({ type: AppActionTypes.SAVED_PENDING_DATA, value: undefined });
      }
    }
  }, [savedPendingData, handleOnClose, appDispatch, setListData, compareKey, key, category]);

  return handleOnClose;
};
