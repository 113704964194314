import { Align, DrawUtility, Point, Rect, Size, VerticalAlign } from "Common/Utility/Drawing";
import React, { useCallback, useEffect, useRef, useState } from "react";

export interface ColumnInfo<T> {
  text: string;
  width: number;
  headerHorizontalAlign: Align;
  headerVerticalAlign: VerticalAlign;
  bodyHorizonAlign: Align;
  bodyVerticalAlign: VerticalAlign;
  toString?: (data: any) => string | null | undefined;
  dataKey?: keyof T;
  draw?: (context: CanvasRenderingContext2D, rect: Rect, data: T) => void;
}

export interface CanvasEvent<T, U> {
  mouseEvent: MouseEvent;

  canvasInfo: CanvasInfo;

  data: T;

  callbacks: U;

  drawArea?: () => Size;
}

export class CanvasHandler<T, U> {
  onClick(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    return undefined;
  }

  onDoubleClick(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    return undefined;
  }

  onMouseDown(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    return undefined;
  }

  private calcOffset(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>) {
    const area = event.drawArea?.();
    if (area) {
      event.canvasInfo.offset = {
        x: event.canvasInfo.offset.x - event.canvasInfo.mouseMovementDistance.x,
        y: event.canvasInfo.offset.y - event.canvasInfo.mouseMovementDistance.y,
      };

      const width = canvas.width - area.width;
      if (width > 0) {
        if (event.canvasInfo.offset.x < 0) {
          event.canvasInfo.offset.x = 0;
        } else if (event.canvasInfo.offset.x > width) {
          event.canvasInfo.offset.x = width;
        }
      } else {
        if (event.canvasInfo.offset.x > 0) {
          event.canvasInfo.offset.x = 0;
        } else if (event.canvasInfo.offset.x < width) {
          event.canvasInfo.offset.x = width;
        }
      }

      const height = canvas.height - area.height;
      if (height > 0) {
        if (event.canvasInfo.offset.y < 0) {
          event.canvasInfo.offset.y = 0;
        } else if (event.canvasInfo.offset.y > height) {
          event.canvasInfo.offset.y = height;
        }
      } else {
        if (event.canvasInfo.offset.y > 0) {
          event.canvasInfo.offset.y = 0;
        } else if (event.canvasInfo.offset.y < height) {
          event.canvasInfo.offset.y = height;
        }
      }
      event.canvasInfo.refresh();
    }
  }

  onMouseUp(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    this.calcOffset(canvas, event);
    return undefined;
  }

  onMouseOut(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    this.calcOffset(canvas, event);
    return undefined;
  }

  onMouseMove(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    return undefined;
  }

  onMouseWheel(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): CanvasHandler<T, U> | undefined {
    return undefined;
  }

  onPaint(canvas: HTMLCanvasElement, canvasInfo: CanvasInfo, data: T, context: CanvasRenderingContext2D) {}
}

export class CanvasComponent<T, U> {
  rect: Rect;

  transmission: boolean = false;

  constructor(rect: Rect) {
    this.rect = rect;
  }

  onPaint(canvas: HTMLCanvasElement, canvasInfo: CanvasInfo, data: T, context: CanvasRenderingContext2D) {}

  onClick(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onDoubleClick(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onMouseDown(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onMouseUp(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onMouseOut(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onMouseMove(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}

  onMouseWheel(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>): void {}
}

interface Props<T, U> {
  className: string;

  handler: CanvasHandler<T, U>;

  data: T;

  callbacks: U;

  components: CanvasComponent<T, U>[];

  drawArea?: () => Size;
}

export interface CanvasInfo {
  refresh: () => void;

  mouseDowned: boolean;

  mouseDownPosition: Point | null;

  mouseMovementDistance: Point;

  offset: Point;
}

export const Canvas = <T, U>(props: Props<T, U>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [handler, setHandler] = useState<CanvasHandler<T, U>>(props.handler);

  const [refresh, setRefresh] = useState<boolean>(true);

  const canvasInfo = useRef<CanvasInfo>({
    refresh: () =>
      setRefresh((value) => {
        return !value;
      }),
    mouseDowned: false,
    mouseDownPosition: { x: 0, y: 0 } as Point,
    mouseMovementDistance: { x: 0, y: 0 } as Point,
    offset: { x: 0, y: 0 } as Point,
  } as CanvasInfo);

  const hittest = useCallback(
    function* (point: Point) {
      for (let i = 0; i < props.components.length; ++i) {
        const index = props.components.length - i - 1;
        let c = props.components[index];
        if (
          DrawUtility.inRect(
            {
              x: c.rect.x + canvasInfo.current.offset.x,
              y: c.rect.y + canvasInfo.current.offset.y,
              w: c.rect.w,
              h: c.rect.h,
            },
            point
          )
        ) {
          yield c;
        }
      }
    },
    [props.components]
  );

  const calcDistance = useCallback((position1: Point, position2: Point): Point => {
    let x = position1.x - position2.x;
    let y = position1.y - position2.y;
    return { x: (x < 0 ? -1 : 1) * Math.abs(x), y: (y < 0 ? -1 : 1) * Math.abs(y) };
  }, []);

  const onEvent = useCallback(
    (
      event: MouseEvent,
      eventName:
        | "onClick"
        | "onDoubleClick"
        | "onMouseDown"
        | "onMouseUp"
        | "onMouseOut"
        | "onMouseMove"
        | "onMouseWheel"
    ) => {
      if (canvasRef.current) {
        const canvasEvent: CanvasEvent<T, U> = {
          mouseEvent: event,
          canvasInfo: canvasInfo.current,
          data: props.data,
          callbacks: props.callbacks,
          drawArea: props.drawArea,
        };

        for (const value of hittest(DrawUtility.mousePosition(canvasRef.current, event))) {
          value[eventName]?.(canvasRef.current, canvasEvent);
          if (!value.transmission) {
            return;
          }
        }

        var nextHandler = handler[eventName]?.(canvasRef.current, canvasEvent);
        if (nextHandler) {
          setHandler(nextHandler);
        }
      }
    },
    [hittest, handler, props]
  );

  const onClick = useCallback(
    (event) => {
      onEvent(event, "onClick");
    },
    [onEvent]
  );

  const onDoubleClick = useCallback(
    (event) => {
      onEvent(event, "onDoubleClick");
    },
    [onEvent]
  );

  const onMouseDown = useCallback(
    (event) => {
      if (canvasRef.current) {
        canvasInfo.current.mouseDownPosition = DrawUtility.mousePosition(canvasRef.current, event);
        canvasInfo.current.mouseDowned = true;
        canvasInfo.current.mouseMovementDistance = { x: 0, y: 0 };
        onEvent(event, "onMouseDown");
      }
    },
    [onEvent]
  );

  const onMouseUp = useCallback(
    (event) => {
      if (canvasRef.current) {
        canvasInfo.current.mouseDownPosition = null;
        canvasInfo.current.mouseDowned = false;
        onEvent(event, "onMouseUp");
        canvasInfo.current.mouseMovementDistance = { x: 0, y: 0 };
      }
    },
    [onEvent]
  );

  const onMouseOut = useCallback(
    (event) => {
      if (canvasRef.current) {
        canvasInfo.current.mouseDownPosition = null;
        canvasInfo.current.mouseDowned = false;
        onEvent(event, "onMouseOut");
        canvasInfo.current.mouseMovementDistance = { x: 0, y: 0 };
      }
    },
    [onEvent]
  );

  const onMouseWheel = useCallback(
    (event) => {
      if (canvasRef.current) {
        onEvent(event, "onMouseWheel");
      }
    },
    [onEvent]
  );

  const onMouseMove = useCallback(
    (event) => {
      if (canvasRef.current) {
        if (canvasInfo.current.mouseDowned && canvasInfo.current.mouseDownPosition) {
          const position = DrawUtility.mousePosition(canvasRef.current, event);
          canvasInfo.current.mouseMovementDistance = calcDistance(canvasInfo.current.mouseDownPosition, position);
        }
        onEvent(event, "onMouseMove");
      }
    },
    [onEvent, calcDistance]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.height = canvas.clientHeight;
      canvas.width = canvas.clientWidth;
    }
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas != null) {
      canvas.addEventListener("click", onClick, false);
      canvas.addEventListener("dblclick", onDoubleClick, false);
      canvas.addEventListener("mousedown", onMouseDown, false);
      canvas.addEventListener("mouseup", onMouseUp, false);
      canvas.addEventListener("mousemove", onMouseMove, false);
      canvas.addEventListener("mouseout", onMouseOut, false);
      canvas.addEventListener("mousewheel", onMouseWheel, { passive: false });
    }

    return () => {
      if (canvas != null) {
        canvas.removeEventListener("click", onClick);
        canvas.removeEventListener("dblclick", onDoubleClick);
        canvas.removeEventListener("mousedown", onMouseDown);
        canvas.removeEventListener("mouseup", onMouseUp);
        canvas.removeEventListener("mousemove", onMouseMove);
        canvas.removeEventListener("mouseout", onMouseOut);
        canvas.removeEventListener("mousewheel", onMouseWheel);
      }
    };
  }, [onClick, onDoubleClick, onMouseDown, onMouseUp, onMouseMove, onMouseOut, onMouseWheel]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (context && canvas) {
      const preCanvas = document.createElement("canvas");
      preCanvas.width = canvas.width;
      if (canvas.height > 0) {
        preCanvas.height = canvas.height;
        const preContext = preCanvas.getContext("2d");
        if (preContext) {
          handler.onPaint?.(canvas, canvasInfo.current, props.data, preContext);
          props.components.forEach((i) => i.onPaint?.(canvas, canvasInfo.current, props.data, preContext));
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(preCanvas, 0, 0);
        }
      }
    }
  }, [handler.onPaint, props.components, props.data, refresh]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");
      if (canvas) {
        canvas.setAttribute("width", entries[0].contentRect.width.toString());
        canvas.setAttribute("height", entries[0].contentRect.height.toString());
        if (context) {
          const preCanvas = document.createElement("canvas");
          preCanvas.width = canvas.width;
          if (canvas.height > 0) {
            preCanvas.height = canvas.height;
            const preContext = preCanvas.getContext("2d");
            if (preContext) {
              handler.onPaint?.(canvas, canvasInfo.current, props.data, preContext);
              props.components.forEach((i) => i.onPaint?.(canvas, canvasInfo.current, props.data, preContext));
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(preCanvas, 0, 0);
            }
          }
        }
      }
    });

    canvasRef.current && resizeObserver.observe(canvasRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [handler.onPaint, props.components, props.data]);

  return <canvas ref={canvasRef} className={props.className} />;
};
