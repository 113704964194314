import { makeStyles } from "@material-ui/core";
import React from "react";
import { InputManagerResult } from "Common/Utility/HandleUtility";
import { EstimateDetail } from "Models/Estimate";
import TextField from "Component/TextField";
import { InputMoney } from "./InputMoney";

const useStyles = makeStyles((theme) => ({
  name: {
    width: 300,
    margin: theme.spacing(1),
  },
  note: {
    width: 300,
    margin: theme.spacing(1),
  },
}));

interface Props {
  editable: boolean;
  detail: EstimateDetail;
  setDetail: React.Dispatch<React.SetStateAction<EstimateDetail>>;
  inputManager: InputManagerResult<EstimateDetail>;
  cost?: boolean;
  taxExemption?: boolean;
}

export const SimpleInput = React.memo((props: Props) => {
  const classes = useStyles();

  const { detail, setDetail, editable, inputManager, cost, taxExemption } = { ...props };

  return (
    <>
      <TextField
        label="品名"
        className={classes.name}
        type="text"
        value={detail.name}
        onChange={inputManager.handleOnChange("name")}
        disabled={!editable}
      />
      <TextField
        label="摘要"
        className={classes.note}
        type="text"
        value={detail.summary}
        onChange={inputManager.handleOnChange("summary")}
        disabled={!editable}
      />
      <InputMoney
        editable={editable}
        detail={detail}
        setDetail={setDetail}
        inputManager={inputManager}
        cost={cost}
        taxExemption={taxExemption}
      />
    </>
  );
});
