import React, { useMemo, useState } from "react";
import { Button, Checkbox, Grid, makeStyles, Paper } from "@material-ui/core";
import { ReportConfig } from "Models/ReportConfig";
import { useGenericStyles } from "Common/Utility/Styles";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { callPrint, Print } from "Common/Component/Print";
import Condition from "Component/Condition";
import { Checked, simpleColumnData } from "../Report";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useContentHeight } from "Hooks/useResize";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useSimpleFetch } from "Common/Utility/Api";
import { ComboItem } from "Common/Utility/GenericInterface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { VoucherStatusComboItems } from "Common/Utility/Constants";
import InvoiceSummaryDesign from "./InvoiceSummaryDesign";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { CheckBox, IndeterminateCheckBox } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  labelWithSelect: {
    width: 300,
    margin: theme.spacing(1),
  },
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
}));

export interface InvoiceSummarySummary {
  customerId: string;

  companyName: string;

  companyName1: string;

  companyName2: string;

  closingDate: DateTime;

  tax: number;

  amount: number;

  details: InvoiceSummaryDetail[];
}

export interface InvoiceSummaryDetail extends Checked {
  voucherNumber: string;

  voucherDate: DateTime;

  companyName: string;

  name: string;

  closingDate: DateTime;

  depositDate: DateTime;

  status: number;

  tax: number;

  amount: number;
}

interface Condition {
  companyId: string;

  companyName: string;

  billingDate: DateTime | null;
}

const defaultCondition: Condition = {
  companyId: "",
  companyName: "",
  billingDate: null,
};

const Report = () => {
  const [height, ref] = useContentHeight(46);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(defaultCondition);

  const [search, setSearch] = useState<Condition>(defaultCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<InvoiceSummarySummary[]>([]);

  const details = useMemo(() => data.reduce((ret, i) => ret.concat(i.details), [] as InvoiceSummaryDetail[]), [data]);

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  const [signImage, setSignImage] = useState<string>("");

  const fetchdata = useSimpleFetch(
    "/invoicesummary",
    setData,
    false,
    DateUtility.InvalidToNull(search, "billingDate"),
    [search]
  );

  const fetchCompany = useSimpleFetch("/companies/customer", setCustomers);

  const fetchSignImage = useSimpleFetch("/reports/kdwsign", (data: { signImage: string }) => {
    setSignImage(data.signImage);
  });

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    fetchdata,
    fetchCompany,
    fetchSignImage
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ReportInvoiceSummary
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
          const numberOfChecked = data.reduce(
            (sum, i) => sum + i.details.reduce((sum, j) => sum + (j.checked ? 1 : 0), 0),
            0
          );

          const numberOfRow = data.reduce((sum, i) => sum + i.details.length, 0);

          return (
            <Checkbox
              checked={numberOfChecked > 0}
              onChange={() => {
                setData((value) => {
                  const checked = numberOfChecked === 0;
                  value.forEach((i) => i.details.forEach((j) => (j.checked = checked)));
                  return [...value];
                });
              }}
              color="primary"
              checkedIcon={numberOfChecked === numberOfRow ? <CheckBox /> : <IndeterminateCheckBox />}
            />
          );
        },
        rendererInCell: (data: InvoiceSummaryDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <Checkbox
              checked={data.checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setData((value) => {
                  data.checked = checked;
                  return [...value];
                });
              }}
              color="primary"
            />
          );
        },
      },
      {
        width: 150,
        label: "伝票番号",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "voucherNumber",
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "伝票日付",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "voucherDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(1),
      },
      {
        width: 300,
        label: "得意先名",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "companyName",
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "工事名",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "name",
        fit: true,
        visible: getVisible(3),
      },
      {
        width: 150,
        label: "請求締日",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "closingDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "入金予定日",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "depositDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(5),
      },
      {
        width: 100,
        label: "状態",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "status",
        convert: (status: number) => {
          const result = VoucherStatusComboItems.find((i) => i.value === status);
          return result ? result.text : "";
        },
        visible: getVisible(6),
      },
    ],
    [getVisible, data]
  );

  return (
    <>
      <Grid item xs={12}>
        <Condition observer={ref}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="得意先"
                text={condition.companyName}
                data={customers}
                columns={[simpleColumnData]}
                onClickNarrowDown={useNarrowDown(customers, "text")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, companyId: result.value, companyName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <DatePickersUtilsProvider>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM"
                  views={["year", "month"]}
                  margin="normal"
                  label="請求年月"
                  autoOk={true}
                  invalidLabel=""
                  invalidDateMessage=""
                  value={condition.billingDate}
                  onChange={inputManager.handleOnChangeDate("billingDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </DatePickersUtilsProvider>
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(defaultCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="flex-start">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => callPrint("printout")}>
              PDF出力
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualizedTable
              values={details}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      <Print id="printout" title="帳票印刷">
        <InvoiceSummaryDesign signImage={signImage} summary={data} />
      </Print>
    </>
  );
};

export const invoiceSummaryConfig: ReportConfig = {
  text: "請求書(総括表)",
  element: <Report />,
};
