import React, { useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useFetch, useExecute } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useContentHeight } from "Hooks/useResize";
import { handleOnChange, handleOnChangeCheck } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import {
  deleteButton,
  editButton,
  referenceButton,
  useIsValidCompanyEditAuthority,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import DigitField from "Component/DigitsField";
import { connect, IAction } from "Common/Utility/connect";
import { CompanyEditAuth } from "Common/Utility/Role";
import { MenuIndex } from "Component/Menu";
import TextField from "Component/TextField";
import { EditDialog } from "./EditDialog";
import {
  Company,
  CompanyContactUser,
  CompanyContactUserWithinCompany,
  CompanyCreditLimit,
  CompanyHeadOfficeBank,
  CompanyItemHandled,
  CompanyManagementReviewComment,
} from "Models/Company";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { useColumnControl } from "Hooks/useColumnControl";
import { VisibleColumn } from "Common/Utility/Constants";

export enum ActionTypes {}

const useStyles = makeStyles((theme) => ({
  page: {
    width: "100%",
    height: (props: any) => props.pageHeight - 56,
  },
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

const initCompany = () => {
  return {
    id: null,
    relationToMe: 0,
    headOfficeId: null,
    headOfficeBanks: [
      { correspondentBank: "", bankBranch: "" },
      { correspondentBank: "", bankBranch: "" },
    ] as CompanyHeadOfficeBank[],
    manualId: "",
    parentCompanyId: null,
    contactUsers: [] as CompanyContactUser[],
    contactUsersWithinCompany: [] as CompanyContactUserWithinCompany[],
    customerClosingDay: 1,
    customerDepositCycle: 0,
    customerDepositDay: 1,
    settlementMonth: 1,
    surveyDate: null,
    conclusionOfBasicTranssactionAgreement: false,
    conclusionOfTradeContract: false,
    managementReviewComments: [] as CompanyManagementReviewComment[],
    companyCreditLimits: [] as CompanyCreditLimit[],
    resurveyPeriod: 3,
    dateConfirmationOfCreditLimit: null,
    invoiceTerms: 0,
    supplierClosingDay: 0,
    supplierDepositCycle: 1,
    supplierDepositDay: 0,
    supplierPaymentMethod: "現金払い",
    billPayment: 1,
    itemHandleds: [] as CompanyItemHandled[],
  } as Company;
};

export const CompanyPanel = React.memo(() => {
  const [pageHeight] = useContentHeight();

  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ pageHeight: pageHeight, height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [data, setData] = useState([] as Company[]);

  const [editData, setEditData] = useState<Company | undefined>(undefined);

  type Condition = { manualId: string; companyName: string; needConfirmCreditLimit: boolean };

  const [condition, setCondition] = useState({
    manualId: "",
    companyName: "",
    needConfirmCreditLimit: false,
  } as Condition);

  const [search, setSearch] = useState({ manualId: "", companyName: "", needConfirmCreditLimit: false });

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Company[]>("/companies", {
          cancelToken: signal.token,
          params: {
            manualId: search.manualId,
            companyName: search.companyName,
            needConfirmCreditLimit: search.needConfirmCreditLimit,
          },
        });

        if (response.data == null) {
          return;
        }

        setData(response.data);
      },
      [search]
    ),
    false
  );

  const handleOnClickAdd = useCallback(() => {
    setEditData(initCompany);
  }, []);

  const handleOnClickEdit = useCallback(
    async (data: Company, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData(data);
    },
    []
  );

  const handleOnClickReference = useCallback(
    async (data: Company, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData(data);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { summary: Company; rowIndex: number }) => {
        await callWebApi().delete<boolean>(`/companies/${object.summary.id}`);

        alertAdd({ type: "success", message: "企業情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (summary: Company, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "企業情報を削除します、よろしいですか？"))) {
        return;
      }

      executeDelete({ summary: summary, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog("Company", setEditData, setData, "id");

  const toStringSelectMonth = (value: number | null) => {
    switch (value) {
      case null:
        return "";
      case 0:
        return "当月";
      case 1:
        return "翌月";
      case 2:
        return "翌々月";
      default:
        return `${value}ヶ月`;
    }
  };

  const toStringSelectDay = (value: number | null) => {
    switch (value) {
      case null:
        return "";
      case 0:
        return "末日";
      default:
        return `${value}日`;
    }
  };

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Company);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Company);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 200,
        label: "企業ID",
        dataKey: "manualId",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(0),
      },
      {
        width: 450,
        label: "企業名（親）",
        dataKey: "parentCompanyName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 100,
        label: "企業名",
        dataKey: "basicCompanyCompanyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 150,
        label: "請求締日",
        dataKey: "customerClosingDay",
        headerAlign: "center",
        bodyAlign: "center",
        convert: toStringSelectDay,
        visible: getVisible(3),
      },
      {
        width: 150,
        label: "入金サイクル",
        dataKey: "customerDepositCycle",
        headerAlign: "center",
        bodyAlign: "center",
        convert: toStringSelectMonth,
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "入金日",
        dataKey: "customerDepositDay",
        headerAlign: "center",
        bodyAlign: "center",
        convert: toStringSelectDay,
        visible: getVisible(5),
      }
    );

    return ret;
  }, [isValidEdit, getVisible]);

  const loadingFetchElement = useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultLoad);

  const isEditCreditLimit = useIsValidCompanyEditAuthority(CompanyEditAuth.creditLimit);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <DigitField
                className={genericClasses.margin}
                label="企業ID"
                value={condition.manualId}
                onChange={handleOnChange<Condition>("manualId", setCondition)}
              />
              <TextField
                className={genericClasses.margin}
                label="企業名"
                value={condition.companyName}
                onChange={handleOnChange<Condition>("companyName", setCondition)}
              />
              {isEditCreditLimit && (
                <FormControlLabel
                  className={genericClasses.margin}
                  control={
                    <Checkbox
                      checked={condition.needConfirmCreditLimit}
                      onChange={handleOnChangeCheck<Condition>("needConfirmCreditLimit", setCondition)}
                      color="primary"
                    />
                  }
                  label="要与信額確認"
                />
              )}
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ manualId: "", companyName: "", needConfirmCreditLimit: false })}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingFetchElement ?? (
            <Paper className={classes.paper}>
              <VirtualizedTable
                values={data}
                setValues={setData}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onClickReference={handleOnClickReference}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {editData != null && <EditDialog open={true} data={editData} onClose={handleOnClose} shouldInitialize />}
    </>
  );
});

interface CompanyType {}

const initialState: CompanyType = {};

function reducer(state: CompanyType, action: IAction): CompanyType {
  switch (action.type) {
    default:
      return state;
  }
}

export const CompanyProvider = connect<CompanyType>(reducer, initialState);
