import React, { useCallback, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useAlertAdd } from "Common/Component/AlertList";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { CallOnClose, deleteButton } from "Common/Utility/AppUtility";
import { Design, simpleColumnData } from "Common/Utility/Constants";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";
import TextField from "Component/TextField";
import { WorkTaskTemplateTask } from "Models/WorkTaskTemplate";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 500,
  },
  dialogPaper2: {
    height: 300,
  },
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  text: {
    width: 200,
    marginRight: Design.margin,
  },
}));

interface EditTemplateTaskDialogProps {
  data: WorkTaskTemplateTask;

  editAuth: boolean;

  userMaster: ComboItem[];

  edited: () => void;

  onClose: CallOnClose;
}

export const EditTemplateTaskDialog = (props: EditTemplateTaskDialogProps) => {
  const genericClasses = useGenericStyles();

  const classes = useStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState<WorkTaskTemplateTask>({ ...props.data, members: [...props.data.members] });

  const inputManager = useInputManager(setData, props.edited);

  const [members, setMembers] = useState<ComboItem[]>([...props.data.members]);

  const [open, setOpen] = useState(false);

  const filteredUserMaster = useMemo(
    () => props.userMaster.filter((i) => members.find((j) => j.value === i.value) === undefined),
    [props.userMaster, members]
  );

  const handleOnClickAdd = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setMembers((values) => {
        values.splice(rowIndex, 1);
        return [...values];
      });
      props.edited();
    },
    [props]
  );

  const handleOnClickSaveInAction = () => {
    if (!data.name) {
      alertAdd({ type: "info", message: "タスク名は必須項目です。" });
      return;
    }

    props.onClose({
      onClose: <T,>(setValue: React.Dispatch<React.SetStateAction<T[]>>, key: keyof T) => {
        setValue((values) => {
          const index = values.findIndex((value) => value === (props.data as any));

          const tmp = { ...data, members: members } as any;
          if (index === -1) {
            return [...values, tmp];
          } else {
            values[index] = tmp;
            return [...values];
          }
        });
      },
    });
  };

  const handleOnClose = (user: ComboItem | null) => {
    if (user) {
      setMembers((values) => {
        return [...values, user];
      });

      props.edited();
    }

    setOpen(false);
  };

  const handleOnClickNarrowDown = useNarrowDown(filteredUserMaster, "text");

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];

    if (props.editAuth) {
      ret.push(deleteButton);
    }

    ret.push({
      width: 300,
      label: "ユーザー名",
      dataKey: "text",
      headerAlign: "center",
      bodyAlign: "left",
    });

    return ret;
  }, [props.editAuth]);

  return (
    <>
      <Dialog onClose={() => props.onClose()} open={true} maxWidth="xs" fullWidth={true}>
        <DialogTitle>タスク</DialogTitle>
        <DialogContent>
          <TextField
            label="タスク名"
            className={clsx(genericClasses.width100percent, genericClasses.marginBottom)}
            onChange={inputManager.handleOnChange("name")}
            value={data.name}
            disabled={!props.editAuth}
          />
          <Paper className={classes.dialogPaper2}>
            <VirtualizedTable
              columns={columns}
              values={members}
              setValues={setMembers}
              rowHeight={48}
              onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
              onClickDelete={handleOnClickDelete}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          {props.editAuth ? (
            <>
              <Button className={genericClasses.margin} color="primary" onClick={() => props.onClose()}>
                キャンセル
              </Button>
              <Button className={genericClasses.margin} color="primary" onClick={handleOnClickSaveInAction}>
                保存
              </Button>
            </>
          ) : (
            <Button className={genericClasses.margin} color="primary" onClick={() => props.onClose()}>
              閉じる
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SelectDialog
        columns={simpleColumnData("名前", "text")}
        data={filteredUserMaster}
        onClose={handleOnClose}
        open={open}
        title="作業者"
        onClickNarrowDown={handleOnClickNarrowDown}
      />
    </>
  );
};
