import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles, Paper } from "@material-ui/core";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Arrive, Shipment, ShipmentDetail } from "Models/Shipment";
import React, { useMemo, useCallback, useState } from "react";
import { InspectionMode, ShipmentStatus } from "Common/Utility/Constants";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import InspectionResultDialog from "./InspectionResultDialog";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { modifyLendingPeriod } from "Common/Utility/DateUtility";
import { checkLendingPeriod } from "./Shipment";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 600,
  },
  loading: {
    height: 600,
  },
}));

const checked = (shipmentDetail: ShipmentDetail) => {
  if (shipmentDetail.checkSheetName == null || shipmentDetail.inspectionResult == null) {
    return shipmentDetail.checked;
  } else {
    return shipmentDetail.inspectionResult.find((i) => !i.checked) == null;
  }
};

interface Props {
  shipment: Shipment;

  onClose: (shipment?: Shipment) => void;
}

const Inspection = (props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(true);

  const [details, setDetails] = useState<ShipmentDetail[]>([]);

  const [assetNumber, setAssetNumber] = useState<string | undefined>(undefined);

  const [shipment, setShipment] = useState<Shipment>(props.shipment);

  const [allowInspection, setAllowInspection] = useState<boolean>(true);

  const handleOnClickInspection = () => {
    if (assetNumber == null || assetNumber === "") {
      return;
    }

    const detail = details.find((i) => i.assetNumber === assetNumber);
    if (detail == null) {
      alertAdd({ type: "info", message: `資産番号 ${assetNumber} は出荷対象外です。` });
      setAssetNumber(undefined);
    } else {
      if (detail.checkSheetName == null) {
        setDetails((value) => {
          detail.checked = true;
          return [...value];
        });
      } else {
        setDetail(detail);
      }
    }
  };

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { shipment: Shipment; onClose: (shipment?: Shipment) => void }) => {
        var response = await callWebApi().put<Shipment>("/shipments/inspection", object.shipment);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "検品が終了しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const [detail, setDetail] = useState<ShipmentDetail | undefined>(undefined);

  const handelOnClick = useCallback(
    (data: ShipmentDetail) => {
      return () => {
        edited();

        if (data.checkSheetName == null) {
          setDetails((value) => {
            data.checked = !data.checked;
            return [...value];
          });
        } else {
          setDetail(data);
        }
      };
    },
    [edited]
  );

  const columns: ColumnData[] = useMemo(() => {
    return [
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 200,
        label: "資産番号",
        dataKey: "assetNumber",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 120,
        label: "販売数",
        dataKey: "numberOfSales",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 120,
        label: "貸出数",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowDate: ShipmentDetail) => {
          const lendingPerios = rowDate.lendingPeriods.find((i) => i.shipmentId === shipment.id);
          if (lendingPerios == null) {
            return "0";
          } else {
            return lendingPerios.numberOf.toString();
          }
        },
      },
      {
        width: 140,
        label: "検品状況",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: ShipmentDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          const flag = checked(data);

          return (
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Button variant="contained" color={flag ? "primary" : "secondary"} onClick={handelOnClick(data)}>
                  {flag ? "検品済み" : "未検品"}
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  }, [shipment.id, handelOnClick]);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (shipment.id == null) {
            return;
          }

          const response = await callWebApi().get<Shipment>(`/shipments/${shipment.id}`, {
            cancelToken: signal.token,
          });

          if (response.data == null) {
            return;
          }

          setShipment(response.data);

          modifyLendingPeriod(response.data.details, shipment.id);

          if (!checkLendingPeriod(shipment.id, response.data.details)) {
            alertAdd({
              type: "warning",
              message: "出荷対象に未帰庫の資産が含まれている為、出荷確定できません。出荷情報を確認してください。",
            });

            setAllowInspection(false);
          }

          setDetails(response.data.details);
        },
        [shipment.id, alertAdd]
      )
    )
  );

  const handleOnClose = (data?: ShipmentDetail | Arrive) => {
    if (data != null) {
      setDetails((value) => [...value]);
    }

    setDetail(undefined);
  };

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={true} fullWidth={true} maxWidth="md">
        <DialogTitle>検品</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <TextField
                  label="資産番号"
                  value={assetNumber}
                  onChange={(event) => setAssetNumber(event.target.value)}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      handleOnClickInspection();
                    }
                  }}
                />
                <Button
                  className={genericClasses.margin}
                  onClick={handleOnClickInspection}
                  variant="contained"
                  color="primary"
                >
                  検品
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingElement ?? (
                <Paper className={classes.paper}>
                  <VirtualaizedTable values={details} setValues={setDetails} rowHeight={48} columns={columns} />
                </Paper>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
          {props.shipment.status === ShipmentStatus.Created && (
            <Button
              className={genericClasses.margin}
              onClick={() => {
                props.shipment.details = details;
                executePut({ shipment: props.shipment, onClose: props.onClose });
              }}
              color="primary"
              disabled={inProcess || details.some((i) => !checked(i)) || !allowInspection}
            >
              出荷確定
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {detail != null && (
        <InspectionResultDialog
          mode={InspectionMode.Shipment}
          data={detail}
          onClose={handleOnClose}
          status={props.shipment.status}
        />
      )}
    </>
  );
};

export default React.memo(Inspection);
