import React, { useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@material-ui/core";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { EstimateSummary, EstimateDetail, isEditableEstimate, isApprovedEstimate } from "Models/Estimate";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import Label from "Component/Label";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DateUtility } from "Common/Utility/DateUtility";
import { ModelMaster } from "Models/ModelMaster";
import { EstimateDetailDialog } from "./EstimateDetailDialog";
import { useInputManager } from "Common/Utility/HandleUtility";
import {
  EstimateDetailType,
  EstimateDetailTypeComboItems,
  RentalPayOff,
  RentalPayOffComboItems,
  toComboText,
} from "Common/Utility/Constants";
import { Group } from "Models/Group";
import { AssetMaster } from "Models/Asset";
import {
  CallOnClose,
  deleteButton,
  editButton,
  formatEstimateNumber,
  InputPending,
  onCloseWithSave,
  referenceButton,
  useIsValidEstimateEditAuthority,
} from "Common/Utility/AppUtility";
import { AppActionTypes, AppProvider } from "App";
import { calcTax } from "Models/TaxMaster";
import { EstimateEditAuth } from "Common/Utility/Role";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { BusinessInfo } from "Models/BusinessInfo";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import MultilineTextField from "Component/MultilineTextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { LineupSummary } from "Models/Lineup";
import { useHoldInput } from "Hooks/useHoldInput";
import { ComboItemCustomerEx } from "Models/Voucher";

const customerColumns: ColumnData[] = [
  {
    width: 300,
    label: "得意先名",
    dataKey: "text",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

const constructionsColumns: ColumnData[] = [
  {
    width: 150,
    label: "工事番号",
    dataKey: "constructionNumber",
    headerAlign: "center",
    bodyAlign: "center",
  },
  {
    width: 300,
    label: "工事名",
    dataKey: "name",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 380,
    margin: theme.spacing(1),
  },
  estimateId: {
    width: 150,
    margin: theme.spacing(1),
  },
  userName: {
    width: 200,
    margin: theme.spacing(1),
  },
  date: {
    width: 120,
    margin: theme.spacing(1),
  },
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
  selectCompany: {
    width: 400,
    margin: theme.spacing(1),
  },
  selectGroup: {
    width: 500,
    margin: theme.spacing(1),
  },
  price: {
    width: 150,
    margin: theme.spacing(2),
  },
  loading: {
    height: 544,
  },
  note: {
    width: "100%",
    margin: theme.spacing(1),
  },
}));

export const estimateInitialize = {
  type: EstimateDetailType.Rental,
  name: "",
  summary: "",
  count: null,
  unit: "",
  basicPrice: null,
  unitPrice: null,
  dailyUnitPrice: null,
  monthlyUnitPrice: null,
  price: null,
  cost: null,
  rentalPayOff: RentalPayOff.Daily,
  rentalDateFrom: null,
  rentalDateTo: null,
  numberOfDaily: 1,
  taxExemption: false,
};

interface Notice {
  requestApproval: boolean;
  noticeApproved: boolean;
}

interface EstimateDialogProps extends InputPending {
  open: boolean;
  summary: EstimateSummary;
  details?: EstimateDetail[];
  editAuth: boolean;
  notice?: Notice;
}

interface Price {
  totalBasic: number; // 基本料小計
  totalPrice: number; // 金額小計
  totalCost: number; // 原価合計
  totalDiscount: number; // 値引き合計
  totalAmount: number; // 税抜き合計
  tax: number; // 消費税
  totalAmountWithTax: number; // 税込み合計
}

const initialPrice: Price = {
  totalBasic: 0,
  totalPrice: 0,
  totalCost: 0,
  totalDiscount: 0,
  totalAmount: 0,
  tax: 0,
  totalAmountWithTax: 0,
} as const;

export const EstimateDialog = React.memo((props: EstimateDialogProps) => {
  // utility
  const genericClasses = useGenericStyles();
  const classes = useStyles();
  const alertAdd = useAlertAdd();
  const message = useMessageBox();
  const appDispatch = AppProvider.useDispatch();
  const history = useHistory();

  // master
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  const taxMaster = AppProvider.useGlobalState("taxMaster");
  const [constructions, setConstructions] = useState<Group[]>([]);
  const [models, setModels] = useState<ModelMaster[]>([]);
  const [assets, setAssets] = useState<AssetMaster[]>([]);
  const [lineupSummaries, setLineupSummaries] = useState<LineupSummary[]>([]);

  // data
  const [summary, setSummary] = useState({ ...props.summary });
  const [details, setDetails] = useState<EstimateDetail[]>(props.details == null ? [] : [...props.details]);
  const [notice, setNotice] = useState<Notice>(props.notice ?? { requestApproval: false, noticeApproved: false });

  // 制御
  const [editingRowIndex, setEditingRowIndex] = useState({ index: -1, isAdd: false });
  const [editingDetail, setEditingDetail] = useState<EstimateDetail | undefined>(undefined);
  const [editableEstimate, setEditableEstimate] = useState<boolean>(
    (props.editAuth && isEditableEstimate(props.summary)) || props.onHold != null
  );
  const [edited, confirm] = useWhetherEdited(props);
  const inputManagerEstimate = useInputManager(setSummary, edited);

  const [customers, setCustomers] = useState<ComboItemCustomerEx[]>([]);

  const handleOnClickHoldInAction = useHoldInput(
    "見積",
    <EstimateDialog
      open={true}
      summary={summary}
      details={details}
      onClose={props.onClose}
      editAuth={props.editAuth}
      notice={notice}
    />,
    props.onClose,
    props.onHold
  );

  const price = useMemo(() => {
    const estimateDate = summary.estimateDate ?? DateUtility.nowDate();
    const price = { ...initialPrice };
    let tax = 0;
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      switch (detail.type) {
        case EstimateDetailType.Rental:
          price.totalBasic += detail.basicPrice ?? 0;
          price.totalPrice += detail.price ?? 0;
          if (!detail.taxExemption) {
            tax += detail.basicPrice ?? 0;
            tax += detail.price ?? 0;
          }
          break;
        case EstimateDetailType.Sale:
        case EstimateDetailType.Fare:
        case EstimateDetailType.Other:
          price.totalPrice += detail.price ?? 0;
          if (!detail.taxExemption) {
            tax += detail.price ?? 0;
          }
          break;
        case EstimateDetailType.Discount:
          price.totalDiscount += detail.price ?? 0;
          break;
      }
      price.totalCost += detail.cost ?? 0;
    }
    price.totalAmount = price.totalBasic + price.totalPrice - price.totalDiscount;
    price.tax = calcTax(tax, estimateDate, taxMaster);
    price.totalAmountWithTax = price.totalAmount + price.tax;
    return price;
  }, [details, summary.estimateDate, taxMaster]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: EstimateSummary;
          details: EstimateDetail[];
          notice: { requestApproval: boolean; noticeApproved: boolean };
          onClose: CallOnClose;
        }
      ) => {
        const response = await callWebApi().put<EstimateSummary>("/estimates", {
          EstimateSummary: object.summary,
          EstimateDetails: object.details,
          ...object.notice,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "見積を保存しました。" });

        if (object.notice.requestApproval) {
          alertAdd({ type: "success", message: "承認依頼を通知しました。" });
        }
        if (object.notice.noticeApproved) {
          alertAdd({ type: "success", message: "担当者に承認を通知しました。" });
        }

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickCreateNewVersionEstimate = async () => {
    if (!(await message.confirm("見積作成確認", "新しい版数の見積を作成しますか？"))) {
      return;
    }

    setSummary((summary) => {
      return {
        ...summary,
        version: null,
        staffUserId: null,
        staffUserName: null,
        approvalUserId: null,
        approvalUserName: null,
        approvalDate: null,
      };
    });

    setEditableEstimate(true);
    alertAdd({ type: "info", message: "新しい版数の見積を作成しました" });
    edited();
  };

  const handleOnClickAssignStaff = async () => {
    if (!(await message.confirm("担当設定確認", "見積の担当者に設定しますか？"))) {
      return;
    }

    const notice = await message.confirm("見積の承認依頼確認", "見積の承認を依頼しますか？\n※保存後に通知します。");
    setNotice((value) => {
      return { ...value, requestApproval: notice };
    });

    setSummary((value) => {
      return {
        ...value,
        staffUserId: ralmAccount?.userGuid ?? null,
        staffUserName: ralmAccount?.userName ?? null,
        staffAssignDate: DateUtility.now(),
      };
    });

    edited();
  };

  const handleOnClickApprovalEstimate = async () => {
    if (!(await message.confirm("承認確認", "見積を承認しますか？"))) {
      return;
    }

    const notice = await message.confirm("承認の通知確認", "見積の担当者に承認を通知しますか？\n※保存後に通知します。");
    setNotice((value) => {
      return { ...value, noticeApproved: notice };
    });

    setSummary((summary) => {
      return {
        ...summary,
        approvalUserId: ralmAccount?.userGuid ?? null,
        approvalUserName: ralmAccount?.userName ?? null,
        approvalDate: DateUtility.nowDate(),
      };
    });

    edited();
  };

  const [executePostOrdersReceived, receptionInProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          estimateId: string;
          customerId: string;
          groupId: string | null;
          history: History<History.UnknownFacade>;
        }
      ) => {
        const response = await callWebApi().post<BusinessInfo>(`/business/estimate/${object.estimateId}`, {
          customerId: object.customerId,
          groupId: object.groupId,
          name: "",
        });

        if (unmounted.value || response.data == null) {
          return;
        }

        appDispatch({ type: AppActionTypes.SET_TRANSITION, value: response.data.id });
        object.history.push(getMenuInfo(MenuIndex.Business).path);
      },
      [appDispatch]
    )
  );

  const handleOnClickOrdersReceived = async () => {
    if (!(await message.confirm("受注確認", "受注を作成しますか？"))) {
      return;
    }

    if (summary.id && summary.customerId) {
      executePostOrdersReceived({
        estimateId: summary.id,
        customerId: summary.customerId,
        groupId: summary.constructionId,
        history: history,
      });
    }
  };

  const handleOnClickSaveInAction = useCallback(async () => {
    if (!summary.estimateDate) {
      alertAdd({ type: "info", message: "見積作成日は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(summary.estimateDate)) {
      alertAdd({ type: "info", message: "見積作成日が不正です。" });
      return;
    }

    if (summary.expirationDate != null && !DateUtility.isValid(summary.expirationDate)) {
      alertAdd({ type: "info", message: "見積有効期限が不正です。" });
      return;
    }

    if (!summary.customerId) {
      alertAdd({ type: "info", message: "得意先は必須項目です。" });
      return;
    }

    executePut({ summary: summary, details: details, notice: notice, onClose: props.onClose });
  }, [summary, executePut, details, notice, props.onClose, alertAdd]);

  const handleOnClickAddDetail = useCallback(() => {
    setEditingRowIndex({ index: -1, isAdd: true });
    setEditingDetail({ ...estimateInitialize });
  }, []);

  const handleOnClickEditDetail = useCallback(
    (detail: EstimateDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditingRowIndex({ index: rowIndex, isAdd: false });
      setEditingDetail(detail);
    },
    []
  );

  const handleOnClickReferenceDetail = useCallback(
    (detail: EstimateDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditingRowIndex({ index: rowIndex, isAdd: false });
      setEditingDetail(detail);
    },
    []
  );

  const handleOnClickDeleteDetail = useCallback(
    (detail: EstimateDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((details) => {
        details.splice(rowIndex, 1);
        return [...details];
      });

      edited();
    },
    [edited]
  );

  const handleOnCloseDetailDialog = useCallback(
    (editedDetail: EstimateDetail | null) => {
      setEditingRowIndex({ index: -1, isAdd: false });

      if (editedDetail !== null) {
        const isRental = editedDetail.type === EstimateDetailType.Rental;
        const detail: EstimateDetail = {
          ...editedDetail,
          rentalPayOff: isRental ? editedDetail.rentalPayOff : null,
        };

        if (editingRowIndex.isAdd) {
          setDetails((details) => [...details, detail]);
        } else {
          const index = editingRowIndex.index;
          setDetails((details) => {
            details[index] = detail;
            return [...details];
          });
        }

        edited();
      }

      setEditingDetail(undefined);
    },
    [edited, editingRowIndex.index, editingRowIndex.isAdd]
  );

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<AssetMaster[]>("/assetmaster", {
          cancelToken: signal.token,
        });
        setAssets(response.data);
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ModelMaster[]>("/modelmaster", {
          cancelToken: signal.token,
        });
        setModels(response.data);
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Group[]>("/group", {
          cancelToken: signal.token,
        });

        setConstructions(response.data);
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<LineupSummary[]>("/lineups/search", {
          cancelToken: signal.token,
        });
        setLineupSummaries(response.data);
      }, [])
    ),
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (props.details != null) {
            return;
          }
          if (!props.summary.id) {
            return;
          }

          const response = await callWebApi().get<EstimateDetail[]>("/estimates/details", {
            cancelToken: signal.token,
            params: { estimateId: props.summary.id },
          });
          setDetails(response.data);
        },
        [props.summary, props.details]
      )
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItemCustomerEx[]>("/companies/customer", {
          cancelToken: signal.token,
        });
        setCustomers(response.data);
      }, [])
    )
  );

  const handleOnClickNarrowDownCustomer = useNarrowDown(customers, "text");
  const handleOnClickNarrowDownConstruction = useNarrowDown(constructions, "constructionNumber", "name");

  const columns: ColumnData[] = useMemo(() => {
    let ret = [] as ColumnData[];
    if (editableEstimate) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    return ret.concat([
      {
        width: 130,
        label: "種別",
        dataKey: "type",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => toComboText(EstimateDetailTypeComboItems, data),
      },
      {
        width: 300,
        label: "品名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 300,
        label: "摘要",
        dataKey: "summary",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 70,
        label: "数量",
        dataKey: "count",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? data.toLocaleString() : ""),
      },
      {
        width: 70,
        label: "単位",
        dataKey: "unit",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 130,
        label: "基本料",
        dataKey: "basicPrice",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? `${data.toLocaleString()}円` : ""),
      },
      {
        width: 130,
        label: "精算区分",
        dataKey: "rentalPayOff",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: number | null) => (data !== null ? toComboText(RentalPayOffComboItems, data) : ""),
      },
      {
        width: 150,
        label: "日極日数",
        dataKey: "numberOfDaily",
        headerAlign: "center",
        bodyAlign: "right",
      },
      {
        width: 130,
        label: "金額",
        dataKey: "price",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? `${data.toLocaleString()}円` : ""),
      },
      {
        width: 110,
        label: "原価",
        dataKey: "cost",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? `${data.toLocaleString()}円` : ""),
      },
      {
        width: 110,
        label: "非課税",
        dataKey: "taxExemption",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: EstimateDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <Box className={genericClasses.width100percent}>
              <Checkbox color="primary" checked={data.taxExemption} disabled={!editableEstimate} />
            </Box>
          );
        },
      },
    ]);
  }, [editableEstimate, genericClasses.width100percent]);

  const canApproval = useIsValidEstimateEditAuthority(EstimateEditAuth.approval);
  const isAssigned = summary.staffUserId;
  const isSavedEstimate = summary.id !== null && summary.version !== null;
  const isUnapproved = !isApprovedEstimate(summary);
  const isOrderable = isSavedEstimate && isApprovedEstimate(props.summary) && props.onHold == null;

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="xl">
      <DialogTitle>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>見積情報</Grid>
          <Grid item>
            {isSavedEstimate && (
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickCreateNewVersionEstimate}
                color="primary"
                disabled={!props.editAuth}
              >
                新しい版数の見積を作成
              </Button>
            )}
            {editableEstimate && isUnapproved && (
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickAssignStaff}
                color="primary"
                disabled={!props.editAuth}
              >
                担当印
              </Button>
            )}
            {isAssigned && canApproval && editableEstimate && isUnapproved && (
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickApprovalEstimate}
                color="primary"
                disabled={!props.editAuth}
              >
                承認印
              </Button>
            )}
            {isOrderable && (
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickOrdersReceived}
                color="primary"
                disabled={!props.editAuth || receptionInProcess}
              >
                受注
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loadingElement ?? (
          <>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-end">
                <Label
                  className={classes.estimateId}
                  caption="見積番号"
                  text={formatEstimateNumber(summary.issueYear, summary.sequence, summary.version)}
                  underLine={true}
                  disabled={!props.editAuth}
                />
                {editableEstimate ? (
                  <DatePickersUtilsProvider>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      label="見積作成日"
                      autoOk
                      value={summary.estimateDate}
                      onChange={inputManagerEstimate.handleOnChangeDate("estimateDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </DatePickersUtilsProvider>
                ) : (
                  <Label
                    className={classes.date}
                    caption="見積作成日"
                    text={DateUtility.format(summary.estimateDate)}
                    disabled={!props.editAuth}
                    underLine
                  />
                )}
                {editableEstimate ? (
                  <DatePickersUtilsProvider>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      label="見積有効期限"
                      autoOk
                      value={summary.expirationDate}
                      onChange={inputManagerEstimate.handleOnChangeDate("expirationDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </DatePickersUtilsProvider>
                ) : (
                  <Label
                    className={classes.date}
                    caption="見積有効期限"
                    text={DateUtility.format(summary.expirationDate)}
                    disabled={!props.editAuth}
                    underLine
                  />
                )}
                {summary.staffUserId && (
                  <Label
                    className={classes.userName}
                    caption="担当者"
                    text={summary.staffUserName ?? ""}
                    underLine={true}
                    disabled={!props.editAuth}
                  />
                )}
                {summary.approvalUserId && (
                  <>
                    <Label
                      className={classes.userName}
                      caption="承認者"
                      text={summary.approvalUserName ?? ""}
                      disabled={!props.editAuth}
                      underLine
                    />
                    <Label
                      className={classes.date}
                      caption="見積承認日"
                      text={DateUtility.format(summary.approvalDate)}
                      disabled={!props.editAuth}
                      underLine
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-end">
                <LabelWithSelect
                  className={classes.selectCompany}
                  caption="得意先"
                  text={summary.customerName ?? ""}
                  data={customers}
                  columns={customerColumns}
                  onClickNarrowDown={handleOnClickNarrowDownCustomer}
                  onSelected={inputManagerEstimate.handleOnChangeLabelWithSelect((value, result) => {
                    return {
                      ...value,
                      customerId: result.value,
                      customerName: result.text,
                      customerClosingDay: result.customerClosingDay,
                      constructionId: null,
                      constructionName: null,
                    };
                  })}
                  maxWidth="sm"
                  disabled={!editableEstimate}
                  underLine
                />
                <LabelWithSelect
                  className={classes.selectGroup}
                  caption="工事情報"
                  text={summary.constructionName ?? ""}
                  data={constructions}
                  columns={constructionsColumns}
                  onClickNarrowDown={handleOnClickNarrowDownConstruction}
                  onSelected={inputManagerEstimate.handleOnChangeLabelWithSelect((value, result) => {
                    return {
                      ...value,
                      constructionId: result.id,
                      constructionName: result.name,
                    };
                  })}
                  maxWidth="sm"
                  disabled={!editableEstimate}
                  underLine
                />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <VirtualaizedTable
                    values={details}
                    rowHeight={48}
                    columns={columns}
                    onClickAdd={editableEstimate ? handleOnClickAddDetail : undefined}
                    onClickEdit={handleOnClickEditDetail}
                    onClickDelete={handleOnClickDeleteDetail}
                    onClickReference={handleOnClickReferenceDetail}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} container direction="row" justify="flex-start" alignItems="center">
                <MultilineTextField
                  id="note"
                  className={classes.note}
                  label="備考"
                  variant="outlined"
                  maxLine={2}
                  value={summary.note}
                  onChange={inputManagerEstimate.handleOnChange("note")}
                  disabled={!editableEstimate}
                />
              </Grid>
              <Grid item xs={6} container direction="column" justify="center" alignItems="flex-end">
                <Grid item container direction="row" justify="flex-end" alignItems="center">
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="基本料小計"
                    text={`${price.totalBasic.toLocaleString()} 円`}
                    underLine
                  />
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="金額小計"
                    text={`${price.totalPrice.toLocaleString()} 円`}
                    underLine
                  />
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="値引き合計"
                    text={`${price.totalDiscount.toLocaleString()} 円`}
                    underLine
                  />
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="原価合計"
                    text={`${price.totalCost.toLocaleString()} 円`}
                    underLine
                  />
                </Grid>
                <Grid item container direction="row" justify="flex-end" alignItems="center">
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="金額合計（税別）"
                    text={`${price.totalAmount.toLocaleString()} 円`}
                    underLine
                  />
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="消費税"
                    text={`${price.tax.toLocaleString()} 円`}
                    underLine
                  />
                  <Label
                    className={classes.price}
                    textClassName={genericClasses.textAlignRight}
                    caption="金額合計（税込）"
                    text={`${price.totalAmountWithTax.toLocaleString()} 円`}
                    underLine
                  />
                </Grid>
              </Grid>
            </Grid>
            {editingDetail != null && (
              <EstimateDetailDialog
                onClose={handleOnCloseDetailDialog}
                assets={assets}
                models={models}
                lineupSummaries={lineupSummaries}
                detail={editingDetail}
                editable={editableEstimate}
                closingDay={summary.customerClosingDay}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!editableEstimate ? (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});
