import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useFetch, useExecute } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { UserMaster } from "Models/UserMaster";
import { useContentHeight } from "Hooks/useResize";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { MenuIndex } from "Component/Menu";
import TextField from "Component/TextField";
import { EditDialog } from "./EditDialog";
import { UserManagerProvider } from "../User";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { DateUtility } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  loading: {
    height: (props: any) => props.height,
    width: "100%",
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

export const UserManager = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const roles = UserManagerProvider.useGlobalState("roles");

  const [data, setData] = useState<UserMaster[]>([]);

  const [name, setName] = useState("");

  const [tel, setTel] = useState("");

  const [email, setEmail] = useState("");

  const [search, setSearch] = useState({
    name: "",
    tel: "",
    email: "",
  });

  const [open, setOpen] = useState(false);

  const [editData, setEditData] = useState({} as UserMaster);

  const searchLoadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<UserMaster[]>("/users", {
            cancelToken: signal.token,
            params: {
              name: search.name,
              tel: search.tel,
              email: search.email,
            },
          });

          if (response.data == null) {
            return;
          }

          setData(response.data);
        },
        [search]
      ),
      false
    )
  );

  const handleOnClickSearch = useCallback(() => {
    setSearch({ name: name, tel: tel, email: email });
  }, [name, tel, email]);

  const handleOnClickClear = useCallback(() => {
    setName("");
    setTel("");
    setEmail("");
  }, []);

  const handleOnChangeName = useCallback((event) => setName(event.target.value), []);

  const handleOnChangeTel = useCallback((event) => setTel(event.target.value), []);

  const handleOnChangeEmail = useCallback((event) => setEmail(event.target.value), []);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      id: null,
      roleId: "",
      password: "",
    } as UserMaster);
    setOpen(true);
  }, []);

  const handleOnClickEdit = useCallback(
    (userMaster: UserMaster, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...userMaster });
      setOpen(true);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        const response = await callWebApi().delete("/users", { params: { id: object.id } });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "ユーザー情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (userMaster: UserMaster, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "ユーザー情報を削除します、よろしいですか？")) {
        executeDelete({ id: userMaster.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose = useCallback((user: UserMaster | null) => {
    setOpen(false);

    if (user != null && user.id != null) {
      setData((value) => {
        const index = value.findIndex((i) => i.id === user.id);

        if (index === -1) {
          return value;
        }

        value[index] = user;
        return [...value];
      });
    }
  }, []);

  const convertToRoleName = useCallback(
    (value: string) => {
      return roles?.find((i) => i.value === value)?.text ?? "";
    },
    [roles]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.User);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.User);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 250,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 300,
        label: "電話番号",
        dataKey: "tel",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 600,
        label: "メールアドレス",
        dataKey: "email",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      },
      {
        width: 300,
        label: "ロール名",
        dataKey: "roleId",
        headerAlign: "center",
        bodyAlign: "left",
        convert: convertToRoleName,
        visible: getVisible(3),
      },
      {
        width: 200,
        label: "登録日時",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm:ss"),
        visible: getVisible(4),
      }
    );

    return ret;
  }, [isValidEdit, convertToRoleName, getVisible]);

  return (
    <>
      {useLoadingElement(classes.loading, LoadingMode.Circular) ?? (
        <>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Condition observer={conditionRef}>
                <>
                  <TextField
                    className={genericClasses.margin}
                    label="名前"
                    value={name}
                    onChange={handleOnChangeName}
                  />
                  <TextField
                    className={genericClasses.margin}
                    label="電話番号"
                    value={tel}
                    onChange={handleOnChangeTel}
                  />
                  <TextField
                    className={genericClasses.margin}
                    label="メールアドレス"
                    value={email}
                    onChange={handleOnChangeEmail}
                  />
                  <Button
                    className={genericClasses.margin}
                    variant="contained"
                    color="primary"
                    onClick={handleOnClickSearch}
                  >
                    検索
                  </Button>
                  <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
                    クリア
                  </Button>
                </>
              </Condition>
            </Grid>
            <Grid item xs={12}>
              {searchLoadingElement ?? (
                <Paper className={classes.paper}>
                  <VirtualizedTable
                    values={data}
                    setValues={setData}
                    tableHeight={height}
                    rowHeight={48}
                    columns={columns}
                    onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                    onClickEdit={handleOnClickEdit}
                    onClickReference={handleOnClickEdit}
                    onClickDelete={handleOnClickDelete}
                    onCloseContextMenu={handleOnCloseContextMenu}
                    onChangeHeaderVisible={handleOnChangeHeaderVisible}
                    headerContext
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
          <EditDialog open={open} data={editData} onClose={handleOnClose} />
        </>
      )}
    </>
  );
};
