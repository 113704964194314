import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import PlaylistAddCheckOutlined from "@material-ui/icons/PlaylistAddCheckOutlined";
import ScheduleOutlined from "@material-ui/icons/ScheduleOutlined";
import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import ReceiptOutlined from "@material-ui/icons/ReceiptOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Apps from "@material-ui/icons/Apps";
import Contacts from "@material-ui/icons/Contacts";
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";
import BusinessCenterOutlined from "@material-ui/icons/BusinessCenterOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssignmentLate from "@material-ui/icons/AssignmentLateOutlined";
import AssignmentOutlined from "@material-ui/icons/AssignmentOutlined";
import People from "@material-ui/icons/People";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import Schedule from "Pages/Schedule/Schedule";
import Business2, { BusinessProvider } from "Pages/Business/Business";
import Voucher, { VoucherProvider } from "Pages/Voucher/Voucher";
import Deposit, { DepositProvider } from "Pages/Deposit/Deposit";
import Asset, { AssetProvider } from "Pages/Asset/Asset";
import Report from "Pages/Report/Report";
import ListItemEx from "Common/Component/ListItemEx";
import SelectService from "Pages/SelectService/SelectService";
import Contact from "Pages/Contact/Contact";
import { Estimate, EstimateProvider } from "Pages/Estimate/Estimate";
import { CancelTokenSource } from "axios";
import { AppActionTypes, AppProvider } from "App";
import { callWebApi } from "Common/Utility/Api";
import { useFetch } from "Hooks/useFetch";
import { ComboItem } from "Common/Utility/GenericInterface";
import { TaxMaster } from "Models/TaxMaster";
import { ServiceByVendor } from "Models/ServiceByVendor";
import { LoadingMode, useLoadingElement } from "./Loading";
import { RaLMAccount } from "Models/RaLMAccount";
import Group from "Pages/Group/Group";
import {
  AddCommentOutlined,
  BuildOutlined,
  FormatListBulletedOutlined,
  LocalShippingOutlined,
  ModeCommentOutlined,
  LibraryBooks,
} from "@material-ui/icons";
import LostOrder from "Pages/LostOrder/LostOrder";
import { CompanyPanel, CompanyProvider } from "Pages/Company/Company";
import User, { UserManagerProvider } from "Pages/User/User";
import Role from "Pages/Role/Role";
import Restriction from "Pages/Restriction/Restriction";
import { belongCategory, BelongIndex, Design } from "Common/Utility/Constants";
import Shipment from "Pages/Shipment/Shipment";
import Notification from "Pages/Notification/Notification";
import { Lineup } from "Pages/Lineup/Lineup";
import NotificationManagement from "Pages/NotificationManagement/NotificationManagement";
import { useContentHeight } from "Hooks/useResize";
import PublicDocuments from "Pages/PublicDocuments/PublicDocuments";
import Statistics from "Pages/Statistics.tsx/Statistics";

const useStyles = makeStyles((theme) => ({
  loading: {
    height: (props: any) => props.height,
  },
}));

export const MenuIndex = {
  SelectService: 0,
  ContactTo: 1,
  ContactFrom: 2,
  Estimate: 3,
  Group: 4,
  Business: 5,
  LostOrder: 6,
  Schedule: 7,
  Voucher: 8,
  Deposit: 9,
  Asset: 10,
  Company: 11,
  OutputReport: 12,
  Role: 13,
  User: 14,
  Restriction: 15,
  Shipment: 16,
  Lineup: 17,
  Notification: 18,
  NotificationManagement: 19,
  PublicDocuments: 20,
  Statistics: 21,
} as const;
export type MenuIndex = typeof MenuIndex[keyof typeof MenuIndex];

interface MenuInfo {
  path: string;

  menu: string;

  element: JSX.Element;

  bit: number;

  route: JSX.Element;

  administratorsOnly?: boolean;

  referenceOnly?: boolean;
}

export function getMenuInfo(menuIndex: MenuIndex): MenuInfo {
  return menuInfos.find((i) => i.bit === 1 << menuIndex)!;
}

export const menuInfos: MenuInfo[] = [
  {
    path: "/Notification",
    menu: "PF情報",
    element: <ModeCommentOutlined />,
    bit: 1 << MenuIndex.Notification,
    route: (
      <Route key="Notification" exact path="/Notification">
        <Notification />
      </Route>
    ),
    referenceOnly: true,
  },
  {
    path: "/SelectService",
    menu: "サービス選択",
    element: <Apps />,
    bit: 1 << MenuIndex.SelectService,
    route: (
      <Route key="SelectService" exact path="/SelectService">
        <SelectService />
      </Route>
    ),
    referenceOnly: true,
  },
  {
    path: "/ContactTo",
    menu: "問い合わせ管理",
    element: <PlaylistAddCheckOutlined />,
    bit: 1 << MenuIndex.ContactTo,
    route: (
      <Route key="ContactTo" exact path="/ContactTo">
        <Contact type="to" />
      </Route>
    ),
  },
  {
    path: "/ContactFrom",
    menu: "営業管理",
    element: <Contacts />,
    bit: 1 << MenuIndex.ContactFrom,
    route: (
      <Route key="ContactFrom" exact path="/ContactFrom">
        <Contact type="from" />
      </Route>
    ),
  },
  {
    path: "/Estimate",
    menu: "見積管理",
    element: <AssignmentOutlined />,
    bit: 1 << MenuIndex.Estimate,
    route: (
      <Route key="Estimate" exact path="/Estimate">
        <EstimateProvider.Provider>
          <Estimate />
        </EstimateProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/Group",
    menu: "工事管理",
    element: <BuildOutlined />,
    bit: 1 << MenuIndex.Group,
    route: (
      <Route key="Group" exact path="/Group">
        <Group />
      </Route>
    ),
  },
  {
    path: "/Business",
    menu: "受注管理",
    element: <AssignmentTurnedIn />,
    bit: 1 << MenuIndex.Business,
    route: (
      <Route key="Business" exact path="/Business">
        <BusinessProvider.Provider>
          <Business2 />
        </BusinessProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/LostOrder",
    menu: "失注管理",
    element: <AssignmentLate />,
    bit: 1 << MenuIndex.LostOrder,
    route: (
      <Route key="LostOrder" exact path="/LostOrder">
        <LostOrder />
      </Route>
    ),
  },
  {
    path: "/Shipment",
    menu: "出荷管理",
    element: <LocalShippingOutlined />,
    bit: 1 << MenuIndex.Shipment,
    route: (
      <Route key="Shipment" exact path="/Shipment">
        <Shipment />
      </Route>
    ),
  },
  {
    path: "/Schedule",
    menu: "日程管理",
    element: <ScheduleOutlined />,
    bit: 1 << MenuIndex.Schedule,
    route: (
      <Route key="Schedule" exact path="/Schedule">
        <Schedule />
      </Route>
    ),
  },
  {
    path: "/Voucher",
    menu: "伝票管理",
    element: <ReceiptOutlined />,
    bit: 1 << MenuIndex.Voucher,
    route: (
      <Route key="Voucher" exact path="/Voucher">
        <VoucherProvider.Provider>
          <Voucher />
        </VoucherProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/Deposit",
    menu: "入金管理",
    element: <PaymentOutlined />,
    bit: 1 << MenuIndex.Deposit,
    route: (
      <Route key="Deposit" exact path="/Deposit">
        <DepositProvider.Provider>
          <Deposit />
        </DepositProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/Asset",
    menu: "資産管理",
    element: <BusinessCenterOutlined />,
    bit: 1 << MenuIndex.Asset,
    route: (
      <Route key="Asset" exact path="/Asset">
        <AssetProvider.Provider>
          <Asset />
        </AssetProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/Lineup",
    menu: "商品管理",
    element: <FormatListBulletedOutlined />,
    bit: 1 << MenuIndex.Lineup,
    route: (
      <Route key="Lineup" exact path="/Lineup">
        <Lineup />
      </Route>
    ),
  },
  {
    path: "/Company",
    menu: "企業管理",
    element: <BusinessIcon />,
    bit: 1 << MenuIndex.Company,
    route: (
      <Route key="Company" exact path="/Company">
        <CompanyProvider.Provider>
          <CompanyPanel />
        </CompanyProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/Statistics",
    menu: "統計情報",
    element: <BarChartIcon />,
    bit: 1 << MenuIndex.Statistics,
    route: (
      <Route key="Statistics" exact path="/Statistics">
        <Statistics />
      </Route>
    ),
    referenceOnly: true,
  },
  {
    path: "/PublicDocuments",
    menu: "公開資料",
    element: <LibraryBooks />,
    bit: 1 << MenuIndex.PublicDocuments,
    route: (
      <Route key="PublicDocuments" exact path="/PublicDocuments">
        <PublicDocuments />
      </Route>
    ),
  },
  {
    path: "/OutputReport",
    menu: "帳票出力",
    element: <ListAltOutlined />,
    bit: 1 << MenuIndex.OutputReport,
    route: (
      <Route key="OutputReport" exact path="/OutputReport">
        <Report />
      </Route>
    ),
    referenceOnly: true,
  },
  {
    path: "/Role",
    menu: "ロール管理",
    element: <DescriptionOutlinedIcon />,
    bit: 1 << MenuIndex.Role,
    route: (
      <Route key="Role" exact path="/Role">
        <Role />
      </Route>
    ),
  },
  {
    path: "/User",
    menu: "ユーザー管理",
    element: <People />,
    bit: 1 << MenuIndex.User,
    route: (
      <Route key="User" exact path="/User">
        <UserManagerProvider.Provider>
          <User />
        </UserManagerProvider.Provider>
      </Route>
    ),
  },
  {
    path: "/NotificationManagement",
    menu: "お知らせ管理",
    element: <AddCommentOutlined />,
    bit: 1 << MenuIndex.NotificationManagement,
    route: (
      <Route key="NotificationManagement" exact path="/NotificationManagement">
        <NotificationManagement />
      </Route>
    ),
    administratorsOnly: true,
  },
  {
    path: "/Restriction",
    menu: "機能制限",
    element: <NotInterestedIcon />,
    bit: 1 << MenuIndex.Restriction,
    route: (
      <Route key="Restriction" exact path="/Restriction">
        <Restriction />
      </Route>
    ),
    administratorsOnly: true,
  },
];

function getMenuInfos(ralmAccount: RaLMAccount | null): MenuInfo[] {
  if (ralmAccount) {
    return menuInfos.filter((value) => {
      if (value.administratorsOnly) {
        return ralmAccount.goodSeriesInfo.category === belongCategory[BelongIndex.Operating].category;
      } else {
        return (value.bit & ralmAccount.menuReferenceAuth) > 0;
      }
    });
  } else {
    return [];
  }
}

interface Props {
  onClick?: (value: any) => void;
  setHeaderTitle: React.Dispatch<React.SetStateAction<string>>;
}

export default function Menu(props: Props) {
  const dispatch = AppProvider.useDispatch();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const location = useLocation();

  const history = useHistory();

  const filteredMenuInfos = useMemo(() => getMenuInfos(ralmAccount), [ralmAccount]);

  const handleClick = useCallback(
    (value: string) => {
      props.onClick?.(value);

      dispatch({ type: AppActionTypes.SAVED_PENDING_DATA, value: undefined });
    },
    [props, dispatch]
  );

  useEffect(() => {
    if (location.pathname === "/") {
      props.setHeaderTitle("");
      if (filteredMenuInfos.length > 0) {
        history.push(filteredMenuInfos[0].path);
      }
    } else {
      const path = "/" + location.pathname.split("/")[1];
      props.setHeaderTitle(filteredMenuInfos.find((value) => value.path === path)?.menu ?? "");
    }
  }, [props, location, history, filteredMenuInfos]);

  return (
    <List>
      {filteredMenuInfos.map((value, index) => (
        <ListItemEx key={index.toString()} to={value.path} value={value.menu} onClick={handleClick}>
          <ListItemIcon>{value.element}</ListItemIcon>
          <ListItemText primary={value.menu} />
        </ListItemEx>
      ))}
    </List>
  );
}

export function MenuRoute() {
  const [height] = useContentHeight(-Design.margin);

  const classes = useStyles({ height: height });

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const filteredMenuInfos = useMemo(() => getMenuInfos(ralmAccount), [ralmAccount]);

  const dispatch = AppProvider.useDispatch();

  const fetchResultCategory = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/deposit/categories", { cancelToken: signal.token });
        dispatch({ type: AppActionTypes.SET_DEPOSIT_CATEGORY_MASTER, value: response.data });
      },
      [dispatch]
    )
  );

  const fetchResultTaxMaster = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<TaxMaster[]>("/taxmaster", { cancelToken: signal.token });
        dispatch({ type: AppActionTypes.SET_TAX_MASTER, value: response.data });
      },
      [dispatch]
    )
  );

  const fetchResultRelation = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (ralmAccount == null) {
          throw new Error("Authorization failed.");
        }

        const response = await callWebApi().get<ServiceByVendor[]>("/relation/service", {
          cancelToken: signal.token,
          params: { tenantId: ralmAccount?.tenantId },
        });
        dispatch({ type: AppActionTypes.SET_SERVICE, value: response.data });
      },
      [dispatch, ralmAccount]
    )
  );

  const fetchResultLicensePlan = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (ralmAccount == null) {
          throw new Error("Authorization failed.");
        }

        const response = await callWebApi().get<ServiceByVendor[]>("/relation/plans/license", {
          cancelToken: signal.token,
          params: { tenantId: ralmAccount?.tenantId },
        });
        dispatch({ type: AppActionTypes.SET_LICENSE_PLAN, value: response.data });
      },
      [dispatch, ralmAccount]
    )
  );

  const fetchResultAgency = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/agency", {
          cancelToken: signal.token,
        });
        dispatch({ type: AppActionTypes.SET_AGENCY, value: response.data });
      },
      [dispatch]
    )
  );

  return (
    <>
      {useLoadingElement(
        classes.loading,
        LoadingMode.Circular,
        fetchResultCategory,
        fetchResultTaxMaster,
        fetchResultRelation,
        fetchResultLicensePlan,
        fetchResultAgency
      ) ?? <Switch>{filteredMenuInfos.map((value) => value.route)}</Switch>}
    </>
  );
}
