import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContentHeight } from "Hooks/useResize";
import { BusinessInfo } from "Models/BusinessInfo";
import { useExecute } from "Hooks/useFetch";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import Condition from "Component/Condition";
import { Button, Grid, Paper } from "@material-ui/core";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import AddDialog from "./AddDialog";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { AppActionTypes, AppProvider } from "App";
import { useMessageBox } from "Hooks/useMessageBox";
import { useColumnControl } from "Hooks/useColumnControl";
import { VisibleColumn } from "Common/Utility/Constants";
import Period from "Component/Period";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
  list: {
    width: "100%",
    height: 200,
  },
}));

interface Props {
  summaries: BusinessInfo[];
  setSummaries: React.Dispatch<React.SetStateAction<BusinessInfo[]>>;
  setDetail: React.Dispatch<React.SetStateAction<BusinessInfo | null>>;
}

interface SearchCondition {
  businessId?: string;
  customerId?: string;
  customerName?: string;
  from?: DateTime;
  to?: DateTime;
}

function initialCondition(): SearchCondition {
  return {
    from: DateUtility.addDate(DateUtility.now(), 0, -1, 0),
    to: DateUtility.format(DateUtility.now(), "yyyy/MM/dd"),
  };
}

const BusinessSummary = (props: Props) => {
  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const transition = AppProvider.useGlobalState("transition");

  const appDispatch = AppProvider.useDispatch();

  const message = useMessageBox();

  const [condition, setCondition] = useState<SearchCondition>(initialCondition());

  const [search, setSearch] = useState<SearchCondition>(initialCondition());

  const [open, setOpen] = useState<boolean>(false);

  const inputManager = useInputManager(setCondition);

  const handleOnClickAdd = useCallback(() => setOpen(true), []);

  const setSummaries = useMemo(() => props.setSummaries, [props.setSummaries]);

  const setDetail = useMemo(() => props.setDetail, [props.setDetail]);

  useEffect(() => {
    if (transition != null) {
      setSearch({ businessId: transition });
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: null });
    }
  }, [transition, appDispatch]);

  const handleOnClickEdit = useCallback(
    (data: BusinessInfo, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetail(data);
    },
    [setDetail]
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { businessId: string; rowIndex: number }) => {
        await callWebApi().delete<boolean>("/business", {
          params: { id: object.businessId },
        });

        alertAdd({ type: "success", message: "受注情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setSummaries((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd, setSummaries]
    )
  );

  const handleOnClickDelete = useCallback(
    async (data: BusinessInfo, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "受注情報を削除します。よろしいですか？")) {
        executeDelete({ businessId: data.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClickReference = useCallback(
    (data: BusinessInfo, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetail(data);
    },
    [setDetail]
  );

  const handleOnClose = useCallback(
    (result?: BusinessInfo) => {
      if (result) {
        setSummaries((value) => [...value, result]);
      }
      setOpen(false);
    },
    [setSummaries]
  );

  const fetchResultLoad = useSimpleFetch(
    "/business",
    setSummaries,
    true,
    DateUtility.InvalidToNull(search, "from", "to"),
    [search, setSummaries]
  );

  const loadingFetchElement = useLoadingElement(classes.searchLoading, LoadingMode.Circular, fetchResultLoad);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Business);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Business);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 200,
        label: "受注番号",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: BusinessInfo) =>
          "DR-" + DateUtility.format(rowData.createdAt, "yyyy") + "-" + rowData.sequence.toString().padStart(5, "0"),
        visible: getVisible(0),
      },
      {
        width: 400,
        label: "工事名",
        dataKey: "constructionName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 350,
        label: "お客様名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 350,
        label: "作成日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy年 MM月dd日 HH時 mm分"),
        visible: getVisible(3),
      }
    );
    return ret;
  }, [isValidEdit, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.marginRight}
                label="お客様番号"
                value={condition.customerId}
                onChange={inputManager.handleOnChange("customerId")}
              />
              <TextField
                className={genericClasses.marginRight}
                label="お客様名"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <Period
                label="作成日"
                fromValue={condition.from}
                toValue={condition.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
              />
              <Button
                className={genericClasses.marginRight}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.marginRight}
                variant="outlined"
                onClick={() => setCondition(initialCondition())}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingFetchElement ?? (
            <Paper className={classes.paper}>
              <VirtualizedTable
                values={props.summaries}
                setValues={setSummaries}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onClickReference={handleOnClickReference}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <AddDialog open={open} onClose={handleOnClose} />
    </>
  );
};

export default React.memo(BusinessSummary);
