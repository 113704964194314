import React, { useCallback, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Drawer,
  Grid,
  Menu as UIMenu,
  MenuItem,
  CssBaseline,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Menu, { MenuRoute } from "./Menu";
import { useLocation } from "react-router-dom";
import { callWebApi } from "Common/Utility/Api";
import { AppActionTypes, AppProvider } from "App";
import { RaLMAccount } from "Models/RaLMAccount";
import { LoadingMode, useLoadingElement } from "./Loading";
import { useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { useHubConnection } from "Hooks/useHubConnection";
import { useAlertAdd } from "Common/Component/AlertList";
import { notification } from "Common/Utility/Notification";
import { MenuIndex, getMenuInfo } from "Component/Menu";
import AccountSetting from "Component/AccountSetting";
import { msal } from "Common/Utility/AuthUtility";
import { useContentHeight, useResizeObserver } from "Hooks/useResize";
import { Design } from "Common/Utility/Constants";
import { AccountCircle } from "@material-ui/icons";

export const NotificationCalledFuncs = {
  ContactReceiveMessage: "ContactReceivedMessage",
} as const;
type NotificationCalledFuncs = typeof NotificationCalledFuncs[keyof typeof NotificationCalledFuncs];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    top: "auto",
    backgroundColor: "#007451",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2), // appBarの3本線の右のマージン
  },
  hide: {
    display: "none", // appBarのメニューが開いているとき消える
  },
  drawer: {
    width: drawerWidth, // drawerの幅
    flexShrink: 0, //
  },
  drawerPaper: {
    width: drawerWidth, // drawerの中身の幅 100%だと画面幅までいくので固定値を設定
    top: "auto", // ヘッダ部分を表示
  },
  drawerHeader: {
    display: "flex", // < の位置
    alignItems: "center", // 〇の形が変わる
    padding: theme.spacing(0, 1), // < の右からの距離
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end", // < の位置を右からに
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: Design.headerHeight,
    height: "calc(100vh - 64px)",
  },
  loading: {
    height: (props: any) => props.height,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  box: {
    width: "100%",
  },
  button: {
    textAlign: "end",
    marginRight: 16,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: "white",
  },
  iconText: {
    "&:hover": {
      cursor: "pointer",
    },
    color: "white",
    marginTop: 2,
  },
}));

function Contents() {
  const [height] = useContentHeight();

  const classes = useStyles({ height: height });

  const theme = useTheme();

  const location = useLocation();

  const alertAdd = useAlertAdd();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const holdDialogs = AppProvider.useGlobalState("holdDialogs");

  const notificationCalledFuncs = AppProvider.useGlobalState("notificationCalledFuncs");

  const dispatch = AppProvider.useDispatch();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);

  const handleDrawerClose = useCallback(() => setOpen(false), []);

  const [headerTitle, setHeaderTitle] = useState("");

  const [started, setStarted] = useState<{} | null>(null);

  const watchingRoomIds = useRef([] as string[]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [settingOpen, setSettingOpen] = useState(false);

  const tenantId = ralmAccount?.tenantId;

  const userGuid = ralmAccount?.userGuid;

  const handleOnStarted = useCallback(
    async (hubConnection: signalR.HubConnection) => {
      await hubConnection.send("AddConnection", tenantId, userGuid);
      setStarted({});
    },
    [tenantId, userGuid]
  );

  const notificationHub = useHubConnection("/notificationhub", handleOnStarted);

  const notificationToDesktop = useCallback(
    async (roomId: string, senderTenantId: string, failed?: (error: any) => void) => {
      return await notificationHub.send("NotificationToDesktopOfAnyUser", failed, roomId, senderTenantId);
    },
    [notificationHub]
  );

  useEffect(() => {
    notificationHub.onreconnected(async () => {
      await notificationHub.send(
        "AddConnection",
        () => {
          alertAdd({
            type: "warning",
            message: "一部サーバーとの接続に失敗しました。再接続を行うまで一部機能が制限されます。",
          });
        },
        tenantId,
        userGuid
      );
    });
  }, [alertAdd, tenantId, userGuid, notificationHub]);

  useEffect(() => {
    dispatch({
      type: AppActionTypes.SET_NOTIFICATION_HUB,
      value: {
        ...notificationHub,
        notificationToDesktop: notificationToDesktop,
      },
    });
  }, [dispatch, notificationToDesktop, notificationHub]);

  useEffect(() => {
    if (started == null) {
      return;
    }

    notificationHub.on(
      NotificationCalledFuncs.ContactReceiveMessage,
      async (roomId: string, toDesktop: boolean, senderTenantId: string, isSentFromContactTo: boolean) => {
        if (NotificationCalledFuncs.ContactReceiveMessage in notificationCalledFuncs) {
          notificationCalledFuncs[NotificationCalledFuncs.ContactReceiveMessage].forEach((func) =>
            func(roomId, toDesktop, senderTenantId, isSentFromContactTo)
          );
        }

        if (
          (location.pathname !== getMenuInfo(MenuIndex.ContactTo).path && isSentFromContactTo) ||
          (location.pathname !== getMenuInfo(MenuIndex.ContactFrom).path && !isSentFromContactTo)
        ) {
          if (toDesktop) {
            if (watchingRoomIds.current.find((value) => value === roomId) === undefined) {
              notificationToDesktop(roomId, senderTenantId);
            }
          } else {
            await notificationHub.send("ClearIssueToDesktopProcess");
          }
        }
      }
    );

    const exclusion = [NotificationCalledFuncs.ContactReceiveMessage];
    Object.values(NotificationCalledFuncs).forEach((methodName) => {
      if (exclusion.find((value) => value === methodName) === undefined && methodName in notificationCalledFuncs) {
        notificationHub.on(methodName, (...data: any[]) => {
          notificationCalledFuncs[methodName].forEach((func) => func(...data));
        });
      }
    });
  }, [location, notificationCalledFuncs, notificationToDesktop, started, notificationHub]);

  useEffect(() => {
    if (started == null) {
      return;
    }

    notificationHub.on("notificationToDesktop", (title: string, message: string) => {
      notification(title, message);
    });

    notificationHub.on("removeWatchingRoomId", (id: string) => {
      const index = watchingRoomIds.current.findIndex((value) => value === id);

      if (index !== -1) {
        watchingRoomIds.current.splice(index, 1);
        dispatch({ type: AppActionTypes.SET_WATCHING_ROOM_IDS, value: watchingRoomIds.current });
      }
    });

    notificationHub.on("ReceiveWatchingRoomIds", async (connectionId: string) => {
      await notificationHub.send("UpdateWatchingRoomIds", undefined, connectionId, watchingRoomIds.current);
    });

    notificationHub.on("UpdateWatchingRoomIds", (ids: string[]) => {
      dispatch({ type: AppActionTypes.SET_WATCHING_ROOM_IDS, value: ids });
      watchingRoomIds.current = ids;
    });

    notificationHub.send("FetchWatchingRoomIds");
  }, [dispatch, started, notificationHub]);

  const handleOnClickSetting = useCallback(() => {
    setSettingOpen(true);
    setAnchorEl(null);
  }, []);

  const handleOnCloseSetting = (result: boolean) => {
    setSettingOpen(false);

    if (result) {
      setStarted(null);
    }
  };

  const fetchAccountResult = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<RaLMAccount>("/account", {
          cancelToken: signal.token,
        });

        if (!response.data) {
          return;
        }

        dispatch({
          type: AppActionTypes.SET_ACCOUNT,
          value: response.data,
        });
      },
      [dispatch]
    ),
    false
  );

  const appDispatch = AppProvider.useDispatch();

  const [rect, resizeRef] = useResizeObserver();

  useEffect(() => {
    appDispatch({ type: AppActionTypes.SET_HEIGHT, value: rect.height });
  }, [appDispatch, rect]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid item xs={6}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {headerTitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <IconButton
                className={classes.icon}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
              >
                <AccountCircle></AccountCircle>
              </IconButton>
              <Typography
                className={classes.iconText}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
              >
                {ralmAccount?.userName}
              </Typography>
              <UIMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleOnClickSetting}>アカウント設定</MenuItem>
                <Divider className={classes.divider} />
                <MenuItem onClick={() => msal.logoutRedirect()}>サインアウト</MenuItem>
              </UIMenu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <Menu setHeaderTitle={setHeaderTitle} />
      </Drawer>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <Grid ref={resizeRef} container direction="row" justify="flex-start" alignItems="flex-start">
              {holdDialogs.length > 0 && (
                <>
                  {holdDialogs.map((value) => {
                    return <div key={value.key}>{value.component}</div>;
                  })}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {useLoadingElement(classes.loading, LoadingMode.Circular, fetchAccountResult) ?? <MenuRoute />}
          </Grid>
        </Grid>
      </div>
      <AccountSetting open={settingOpen} onClose={handleOnCloseSetting} />
    </div>
  );
}

export default React.memo(Contents);
