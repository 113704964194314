import React, { useCallback, useState } from "react";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { CancelTokenSource } from "axios";
import { useFetch, useExecute } from "Hooks/useFetch";

export const useColumnControl = (
  id: number
): [
  (index: number) => boolean,
  (filteredColumns: ColumnData[]) => void,
  (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, filteredColumns: ColumnData[], index: number) => void
] => {
  const [visible, setVisible] = useState<number | null>(null);

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<number | null>(`/columncontrols/${id}`, { cancelToken: signal.token });
        setVisible(response.data);
      },
      [id]
    )
  );

  const execute = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { id: number; visible: number }) => {
      await callWebApi().put("/columncontrols", object);
    }, [])
  );

  const handleOnCloseContextMenu = useCallback(
    (filteredColumns: ColumnData[]) => {
      let flag = 0;
      filteredColumns.forEach((value, index) => {
        const bit = value.visible ? 1 : 0;
        flag += bit << index;
      });

      const max = 2 ** filteredColumns.length - 1;
      if (flag === max) {
        // 全てONの場合はレコード削除用に-1を設定する。
        execute({ id: id, visible: -1 });
      } else {
        execute({ id: id, visible: flag });
      }
    },
    [execute, id]
  );

  const handleOnChangeHeaderVisible = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, filteredColumns: ColumnData[], index: number) => {
      setVisible((value) => {
        const max = 2 ** filteredColumns.length - 1;
        if (value == null) {
          value = max;
        }
        if (checked) {
          return value | (1 << index);
        } else {
          const result = value & ~(1 << index);
          // 全て非表示は禁止とする。
          if (result === 0) {
            return value;
          } else {
            return result;
          }
        }
      });
    },
    []
  );

  const getVisible = useCallback(
    (index: number) => {
      if (visible == null) {
        return true;
      } else {
        return (visible & (1 << index)) > 0;
      }
    },
    [visible]
  );

  return [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible];
};
