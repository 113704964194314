import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import { InspectionResult } from "Models/Shipment";
import React, { useState } from "react";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { InspectionMode } from "Common/Utility/Constants";

interface Props {
  mode: InspectionMode;

  inspectionResult: InspectionResult & { index: number };

  onClose: (inspectionResult?: InspectionResult & { index: number }) => void;
}

const EditDialog = (props: Props) => {
  const genericClasses = useGenericStyles();

  const [edited, confirm] = useWhetherEdited(true);

  const [inspectionResult, setInspectionResult] = useState<InspectionResult & { index: number }>(
    props.inspectionResult
  );

  const inputManager = useInputManager(setInspectionResult, edited);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={true} fullWidth={true} maxWidth="sm">
        <DialogTitle>特記事項</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="出荷検品 - 特記事項"
                value={inspectionResult.shipmentRemark}
                onChange={inputManager.handleOnChange("shipmentRemark")}
                multiline
                rows={10}
                InputProps={{ readOnly: props.mode !== InspectionMode.Shipment }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="帰庫検品 - 特記事項"
                value={inspectionResult.arriveRemark}
                onChange={inputManager.handleOnChange("arriveRemark")}
                multiline
                rows={10}
                InputProps={{ readOnly: props.mode !== InspectionMode.Arrive }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
          <Button className={genericClasses.margin} onClick={() => props.onClose(inspectionResult)} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(EditDialog);
