import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiPickersUtilsProviderProps } from "@material-ui/pickers/MuiPickersUtilsProvider";
import format from "date-fns/format";

class DateFnsUtilsJa extends DateFnsUtils {
  getCalendarHeaderText(date: number | Date) {
    return format(date, "yyyy年 MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date: number | Date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}

export default (props: Omit<MuiPickersUtilsProviderProps, "utils" | "locale">) => {
  return <MuiPickersUtilsProvider {...props} utils={DateFnsUtilsJa} locale={jaLocale} />;
};
