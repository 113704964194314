import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Paper } from "@material-ui/core";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { OriginalVoucherSummary } from "Models/OriginalVoucher";
import OriginalVoucherDialog from "./OriginalVoucherDialog";
import VoucherIssueDialog from "./VoucherIssueDialog";
import { useExecute } from "Hooks/useFetch";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { deleteButton, editButton, referenceButton } from "Common/Utility/AppUtility";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface OriginalVoucherListProps {
  summaries: OriginalVoucherSummary[];
  setSummaries: React.Dispatch<React.SetStateAction<OriginalVoucherSummary[]>>;
  customerId?: string;
  customerName?: string;
  businessId?: string;
  businessSequence?: number;
  businessCreatedAt?: DateTime;
  height: number;
  editAuth: boolean;
}

const OriginalVoucherList = (props: OriginalVoucherListProps) => {
  const classes = useStyles({ height: props.height, minHeight: props.customerId != null ? 0 : 300 });

  const alertAdd = useAlertAdd();
  const message = useMessageBox();

  const [editSummary, setEditSummary] = useState<OriginalVoucherSummary | undefined>(undefined);
  const [issueOriginalVoucherIdList, setIssueOriginalVoucherIdList] = useState<string[]>();

  const [bulkIssueCheckList, setBulkIssueCheckList] = useState<boolean[]>([]);

  useEffect(() => {
    const checkList = new Array<boolean>(props.summaries.length).fill(true);
    setBulkIssueCheckList(checkList);
  }, [props.summaries]);

  const handleOnClickAdd = useCallback(() => {
    setEditSummary({
      customerId: props.customerId ?? null,
      customerName: props.customerName ?? null,
      businessId: props.businessId ?? null,
      businessSequence: props.businessSequence ?? null,
      businessCreatedAt: props.businessCreatedAt ?? null,
      startDate: null,
      endDate: null,
    } as OriginalVoucherSummary);
  }, [props.customerId, props.customerName, props.businessId, props.businessSequence, props.businessCreatedAt]);

  const handleOnClickEdit = useCallback(
    async (summary: OriginalVoucherSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditSummary({ ...summary });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: OriginalVoucherSummary;
          setSummaries: React.Dispatch<React.SetStateAction<OriginalVoucherSummary[]>>;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete("/originalvoucher", { params: { id: object.summary.id } });

        alertAdd({ type: "success", message: "伝票を削除しました。" });

        if (unmounted.value) {
          return;
        }

        object.setSummaries((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (summary: OriginalVoucherSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "元伝票情報を削除します、よろしいですか？"))) {
        return;
      }

      executeDelete({
        summary: summary,
        setSummaries: props.setSummaries,
        rowIndex: rowIndex,
      });
    },
    [props, message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog(
    "OriginalVoucher",
    setEditSummary,
    props.setSummaries,
    "id",
    props.businessId
  );

  const handleOnClickIssue = useCallback(
    (summary: OriginalVoucherSummary) => () => {
      setIssueOriginalVoucherIdList([summary.id!]);
    },
    []
  );

  const handleOnCloseIssueVoucherDialog = useCallback(() => {
    setIssueOriginalVoucherIdList(undefined);
  }, []);

  const handleOnClickIssueAll = useCallback(() => {
    const issueIdList = props.summaries.filter((_, index) => bulkIssueCheckList[index]).map((summary) => summary.id!);
    setIssueOriginalVoucherIdList(issueIdList);
  }, [bulkIssueCheckList, props.summaries]);

  const handleCheckRow = useCallback(
    (rowIndex: number) => () => {
      setBulkIssueCheckList((bulkIssueCheckList) => {
        const newBulkIssueCheckList = [...bulkIssueCheckList];
        newBulkIssueCheckList[rowIndex] = !newBulkIssueCheckList[rowIndex];
        return newBulkIssueCheckList;
      });
    },
    []
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.OriginalVoucher
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (props.editAuth) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 200,
        label: "得意先名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 120,
        label: "請求開始日",
        dataKey: "startDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (date: DateTime) => DateUtility.format(date, "yyyy/MM/dd"),
        visible: getVisible(1),
      },
      {
        width: 120,
        label: "請求終了日",
        dataKey: "endDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (date?: DateTime) => (date ? DateUtility.format(date, "yyyy/MM/dd") : ""),
        visible: getVisible(2),
      }
    );

    if (props.editAuth) {
      ret.push(
        {
          width: 120,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          component: (summary: OriginalVoucherSummary) => {
            return (
              <Button variant="contained" color="primary" onClick={handleOnClickIssue(summary)}>
                発行
              </Button>
            );
          },
        },
        {
          width: 140,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
            return (
              <Button
                variant="contained"
                color="primary"
                disabled={!bulkIssueCheckList.some((checked) => checked === true)}
                onClick={handleOnClickIssueAll}
              >
                一括発行
              </Button>
            );
          },
          component: (summary: OriginalVoucherSummary, rowIndex: number) => {
            const checked = rowIndex < bulkIssueCheckList.length && bulkIssueCheckList[rowIndex];
            return <Checkbox checked={checked} onChange={handleCheckRow(rowIndex)} />;
          },
        }
      );
    }

    return ret;
  }, [props.editAuth, handleOnClickIssue, bulkIssueCheckList, handleOnClickIssueAll, handleCheckRow, getVisible]);

  return (
    <>
      <Paper className={classes.paper}>
        <VirtualaizedTable
          values={props.summaries}
          setValues={props.setSummaries}
          tableHeight={props.height}
          rowHeight={48}
          columns={columns}
          onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
          onClickEdit={handleOnClickEdit}
          onClickReference={handleOnClickEdit}
          onClickDelete={handleOnClickDelete}
          onCloseContextMenu={handleOnCloseContextMenu}
          onChangeHeaderVisible={handleOnChangeHeaderVisible}
          headerContext
        />
      </Paper>
      {editSummary != null && (
        <OriginalVoucherDialog
          open={true}
          onClose={handleOnClose}
          summary={editSummary}
          details={[]}
          editAuth={props.editAuth}
          shouldInitialize
        />
      )}
      <VoucherIssueDialog
        originalVoucherIdList={issueOriginalVoucherIdList!}
        open={issueOriginalVoucherIdList !== undefined}
        onClose={handleOnCloseIssueVoucherDialog}
      />
    </>
  );
};

export default React.memo(OriginalVoucherList);
