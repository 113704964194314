import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useExecuteEx } from "Hooks/useFetch";
import { AxiosResponse } from "axios";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { UserMaster } from "Models/UserMaster";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { MenuIndex } from "Component/Menu";
import { DigitsWithHyphenField } from "Component/DigitsField";
import TextField from "Component/TextField";
import { UserManagerProvider } from "../User";

const useStyles = makeStyles((theme) => ({
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
}));

type DialogProps = {
  open: boolean;
  data: UserMaster;
  onClose: (user: UserMaster | null) => void;
};

export const EditDialog = (props: DialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const roles = UserManagerProvider.useGlobalState("roles");

  const [data, setData] = useState({} as UserMaster);

  const [confirmPassword, setConfirmPassword] = useState("");

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.User);

  const [edited, confirm] = useWhetherEdited(props.open, isValidEdit);

  const handleOnClose = useCallback(() => {
    confirm(() => props.onClose(null));
  }, [props, confirm]);

  const handleOnClickCancelInAction = useCallback(() => {
    confirm(() => props.onClose(null));
  }, [props, confirm]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { data: UserMaster; onClose: (user: UserMaster | null) => void }
      ) => {
        let response: AxiosResponse<UserMaster>;

        if (object.data.id == null) {
          response = await callWebApi().post<UserMaster>("/users", object.data);
        } else {
          response = await callWebApi().put<UserMaster>("/users", object.data);
        }

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "ユーザー情報を保存しました。" });

        if (unmounted.value || !response.data) {
          return;
        }

        response.data.password = "";
        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(() => {
    if (!data.userId) {
      alertAdd({ type: "info", message: "ユーザーIDは必須項目です。" });
      return;
    }

    /* Coreと連携しているRaLMの場合必要無いためコメントアウト
    if (!data.password) {
      alertAdd({ type: "info", message: "パスワードは必須項目です。" });
      return;
    } else if (data.password !== "" && data.password.length < 8) {
      alertAdd({ type: "info", message: "パスワードは８桁以上です。" });
      return;
    } else if (data.password !== confirmPassword) {
      alertAdd({ type: "info", message: "パスワードが一致しません。" });
      return;
    }
    */

    if (!data.name) {
      alertAdd({ type: "info", message: "名前は必須項目です。" });
      return;
    }

    if (!data.roleId) {
      alertAdd({ type: "info", message: "ロールは必須項目です。" });
      return;
    }

    executePut({ data: data, onClose: props.onClose });
  }, [props.onClose, alertAdd, data, executePut]);

  const handleOnChangeUserId = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, userId: target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangePassword = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, password: target === "" ? null : target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangeConfirmPassword = useCallback(
    (event) => {
      setConfirmPassword(event.target.value);
      edited();
    },
    [edited]
  );

  const handleOnChangeName = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, name: target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangeTel = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, tel: target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangeEmail = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, email: target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangeRoleId = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, roleId: target };
      });
      edited();
    },
    [edited]
  );

  useEffect(() => {
    if (props.open) {
      setData(props.data);
      setConfirmPassword("");
    }
  }, [props.open, props.data]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={false} maxWidth="xs">
      <DialogTitle>ユーザー情報</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-end">
          <TextField
            className={classes.inEdit}
            label="ユーザーID"
            value={data.userId}
            onChange={handleOnChangeUserId}
            disabled={!isValidEdit}
          />
          {false && (
            <>
              <TextField
                className={classes.inEdit}
                label="パスワード(8桁以上)"
                value={data.password}
                onChange={handleOnChangePassword}
                type="password"
                disabled={!isValidEdit}
              />
              <TextField
                className={classes.inEdit}
                label="確認パスワード(8桁以上)"
                value={confirmPassword}
                onChange={handleOnChangeConfirmPassword}
                type="password"
                disabled={!isValidEdit}
              />
            </>
          )}
          <TextField
            className={classes.inEdit}
            label="名前"
            value={data.name}
            onChange={handleOnChangeName}
            disabled={!isValidEdit}
          />
          <DigitsWithHyphenField
            className={classes.inEdit}
            label="電話番号"
            value={data.tel}
            onChange={handleOnChangeTel}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEdit}
            label="メールアドレス"
            value={data.email}
            onChange={handleOnChangeEmail}
            disabled={!isValidEdit}
          />
          <FormControl className={classes.inEdit}>
            <InputLabel id="input-label-role">ロール</InputLabel>
            <Select
              labelId="input-label-role"
              id="select-role"
              onChange={handleOnChangeRoleId}
              value={data.roleId}
              disabled={!isValidEdit}
            >
              {roles?.map((v) => (
                <MenuItem key={v.value} value={v.value}>
                  {v.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isValidEdit ? (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              閉じる
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
