import { DateTime } from "Common/Utility/DateUtility";

export type TaxMaster = {
  // 消費税率
  tax: number;

  // 開始日
  startDate: DateTime;
};

function tax(date: DateTime, taxMaster: TaxMaster[]) {
  for (let i = 0; i < taxMaster.length; ++i) {
    if (new Date(taxMaster[i].startDate) <= new Date(date)) {
      return taxMaster[i].tax;
    }
  }

  return 0;
}

export function calcAddTax(value: number, date: DateTime | null, taxMaster: TaxMaster[]) {
  return Math.floor(value * (1 + tax(date == null ? new Date().toLocaleDateString() : date, taxMaster) / 100));
}

export function calcTax(value: number, date: DateTime | null, taxMaster: TaxMaster[]) {
  if (value <= 0) {
    return 0;
  }
  return Math.floor(value * (tax(date == null ? new Date().toLocaleDateString() : date, taxMaster) / 100));
}
