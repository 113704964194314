import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { connect, IAction } from "Common/Utility/connect";
import { ModelPanel } from "./ModelPanel/ModelPanel";
import AssetGroupPanel from "./AssetGroupPanel";
import { AssetPanel } from "./AssetPanel/AssetPanel";
import { IndexRulePanel } from "./IndexRulePanel/IndexRulePanel";
import { RepairHistoryPanel } from "./RepairHistoryPanel/RepairHistoryPanel";
import { RepairPartsPanel } from "./RepairPartsPanel/RepairPartsPanel";
import { CheckSheetPanel } from "../Asset/CheckSheet/CheckSheetPanel";

export enum ActionTypes {}

const useStyles = makeStyles((theme) => ({
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

export default React.memo(() => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <AssetPanel />;
      case 1:
        return <RepairHistoryPanel />;
      case 2:
        return <RepairPartsPanel />;
      case 3:
        return <AssetGroupPanel />;
      case 4:
        return <ModelPanel />;
      case 5:
        return <IndexRulePanel />;
      case 6:
        return <CheckSheetPanel />;
    }
  }, [tabIndex]);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <>
      <Paper className={classes.tabArea} square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTabs}
          aria-label="tabs"
        >
          <Tab label="資産" />
          <Tab label="修理履歴" />
          <Tab label="修理部品" />
          <Tab label="資産グループ" />
          <Tab label="型式" />
          <Tab label="採番ルール" />
          <Tab label="チェックシート" />
        </Tabs>
      </Paper>
      {panel}
    </>
  );
});

const initialState: AssetType = {};

interface AssetType {}

function reducer(state: AssetType, action: IAction): AssetType {
  switch (action.type) {
    default:
      return state;
  }
}

export const AssetProvider = connect<AssetType>(reducer, initialState);
