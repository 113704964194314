import React, { useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Checkbox } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useExecute, useFetch } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { useContentHeight } from "Hooks/useResize";
import { useInputManager } from "Common/Utility/HandleUtility";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import TextField from "Component/TextField";
import { useMessageBox } from "Hooks/useMessageBox";
import { PublicDocument } from "Models/PublicDocument";
import { useAlertAdd } from "Common/Component/AlertList";
import { DateUtility } from "Common/Utility/DateUtility";
import { deleteButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useColumnControl } from "Hooks/useColumnControl";
import { MenuIndex } from "Component/Menu";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

export const RequestPage = () => {
  const [height, conditionRef] = useContentHeight(
    Design.componentUnitHeight + Design.margin + Design.componentUnitHeight
  );

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [viewData, setViewData] = useState<PublicDocument[]>([]);

  const [condition, setCondition] = useState({ fileName: "" });

  const [search, setSearch] = useState({ fileName: "" });

  const inputManager = useInputManager(setCondition);

  const loadingElement = useLoadingElement(
    classes.paper,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<PublicDocument[]>("/publicdocuments/registered", {
            cancelToken: signal.token,
            params: {
              filename: search.fileName,
            },
          });

          if (response.data == null) {
            return;
          }

          response.data.forEach((element) => {
            element.checked = false;
          });
          setViewData(response.data);
        },
        [search]
      ),
      false
    )
  );

  const downloadLink = useRef<HTMLAnchorElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
      const fileList: FileList = event.currentTarget.files;
      execute({
        fileList,
      });
    }
  };

  const execute = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { fileList: FileList }) => {
      const config = { headers: { "content-type": "multipart/form-data" } };

      const file = object.fileList.item(0);
      if (file) {
        const formData = new FormData();
        formData.append("File", file);
        const response = await callWebApi().post<PublicDocument>("/publicdocuments", formData, config);

        if (unmounted.value) {
          return;
        }

        setViewData((value) => {
          response.data.checked = false;
          return [...value, response.data];
        });
      }

      if (inputRef.current !== null) {
        inputRef.current.value = "";
      }
    }, [])
  );

  const executeDownload = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { downloadIdList: PublicDocument[] }) => {
      for (let i = 0; i < object.downloadIdList.length; i++) {
        if (object.downloadIdList[i].checked) {
          const response = await callWebApi().get("/publicdocuments/file", {
            params: {
              fileId: object.downloadIdList[i].fileId,
            },
            responseType: "blob",
          });

          if (unmounted.value) {
            return;
          }

          if (downloadLink.current) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            downloadLink.current.href = url;
            downloadLink.current.setAttribute("download", object.downloadIdList[i].fileName);
            downloadLink.current.click();
          }
        }
      }
    }, [])
  );

  const handleOnClickDownLoad = useCallback(() => {
    executeDownload({ downloadIdList: viewData });
  }, [viewData, executeDownload]);

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id?: string; rowIndex: number }) => {
        if (object.id == null) {
          return;
        }

        await callWebApi().delete(`/publicdocuments/${object.id}`);

        alertAdd({ type: "info", message: "ファイルを削除しました。" });

        if (unmounted.value) {
          return;
        }

        setViewData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickAdd = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleOnClickDelete = useCallback(
    async (response: PublicDocument, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "ファイルを削除します。よろしいですか？"))) {
        return;
      }

      executeDelete({ id: response.fileId, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.PublicDocumentRegister
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.PublicDocuments);

  const columns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        widthType: "fix",
        component: (data: PublicDocument, rowIndex: number) => {
          return (
            <Checkbox
              color="primary"
              checked={data.checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setViewData((value) => {
                  data.checked = checked;
                  return [...value];
                });
              }}
            />
          );
        },
      },
    ];

    if (isValidEdit) {
      columns.push(deleteButton);
    }

    columns.push(
      {
        width: 1000,
        label: "ファイル名",
        dataKey: "fileName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 400,
        label: "登録日時",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "left",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm:ss"),
        visible: getVisible(1),
      }
    );

    return columns;
  }, [getVisible, isValidEdit]);

  const checked = useMemo(() => {
    return viewData.findIndex((i) => i.checked) >= 0;
  }, [viewData]);

  return (
    <>
      <a href="#dummy" style={{ display: "none" }} ref={downloadLink}>
        DownloadLink
      </a>

      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={(genericClasses.margin, genericClasses.width300)}
                label="ファイル名"
                value={condition.fileName}
                onChange={inputManager.handleOnChange("fileName")}
              />

              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ fileName: "" })}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {loadingElement ?? (
              <VirtualizedTable
                values={viewData}
                setValues={setViewData}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} className={genericClasses.textAlignRight}>
          <Button
            className={genericClasses.margin}
            variant="contained"
            color="primary"
            onClick={handleOnClickDownLoad}
            disabled={!checked}
          >
            ダウンロード
          </Button>
        </Grid>
      </Grid>
      <input hidden ref={inputRef} type="file" onChange={onFileInputChange} />
    </>
  );
};
