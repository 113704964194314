import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Arrive, InspectionResult, ShipmentDetail } from "Models/Shipment";
import React, { useCallback, useMemo, useState } from "react";
import Label from "Component/Label";
import { editButton } from "Common/Utility/AppUtility";
import EditDialog from "./EditDialog";
import { InspectionMode, ShipmentStatus } from "Common/Utility/Constants";
import DigitsField from "Component/DigitsField";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useWhetherEdited } from "Hooks/useWhetherEdited";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 500,
  },
}));

interface Input {
  inputReturn: number | null;

  inputFailures: number | null;
}

interface Props {
  mode: InspectionMode;

  data: ShipmentDetail | Arrive;

  onClose: (data?: ShipmentDetail | Arrive) => void;

  status: number;

  isInspection?: boolean;
}

const InspectionResultDialog = (props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(true);

  const [numberOf, setNumberOf] = useState<Input>(
    props.isInspection == null
      ? { inputReturn: null, inputFailures: null }
      : props.isInspection
      ? { inputReturn: 1, inputFailures: 0 }
      : { inputReturn: 0, inputFailures: 1 }
  );

  const input = useInputManager(setNumberOf);

  // 元データを保持する為に検品状態のクローンを作成する。
  const [inspectionResults, setInspectionResults] = useState<InspectionResult[]>(
    props.data.inspectionResult == null
      ? []
      : props.data.inspectionResult.map((value) => {
          return { ...value };
        })
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (props.status === ShipmentStatus.Created) {
      ret.push(editButton);
    }

    ret.push(
      {
        width: 100,
        label: "点検項目",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 500,
        label: "出荷検品 - 特記事項",
        dataKey: "shipmentRemark",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 500,
        label: "帰庫検品 - 特記事項",
        dataKey: "arriveRemark",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 120,
        label: "チェック",
        dataKey: "checked",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: InspectionResult, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Checkbox
                  checked={data.checked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    edited();
                    setInspectionResults((value) => {
                      data.checked = checked;
                      return [...value];
                    });
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          );
        },
      }
    );
    return ret;
  }, [props.status, edited]);

  const [inspectionResult, setInspectionResult] = useState<(InspectionResult & { index: number }) | undefined>(
    undefined
  );

  const handleOnClickEdit = useCallback(
    (data: InspectionResult, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      edited();
      setInspectionResult({ ...data, index: rowIndex });
    },
    [edited]
  );

  const handleOnClose = () => {
    if (props.mode === InspectionMode.Arrive) {
      if (numberOf.inputReturn == null || numberOf.inputFailures == null) {
        return;
      }

      if (numberOf.inputReturn + numberOf.inputFailures === 0) {
        alertAdd({ type: "info", message: "返品数、故障数を合わせて1以上に設定してください。" });
        return;
      }

      const arrive = props.data as Arrive;
      if (
        arrive.numberOf <
        arrive.numberOfReturn + arrive.numberOfFailures + numberOf.inputReturn + numberOf.inputFailures
      ) {
        alertAdd({ type: "info", message: "返品数 + 故障数が貸出数を超えています。" });
        return;
      }
      arrive.inputReturn = numberOf.inputReturn;
      arrive.inputFailures = numberOf.inputFailures;
    }

    props.data.inspectionResult = inspectionResults;
    props.onClose(props.data);
  };

  const handleOnCloseEditDialog = (inspectionResult?: InspectionResult & { index: number }) => {
    if (inspectionResult != null) {
      setInspectionResults((value) => {
        const { index, ...other } = inspectionResult;
        value[index] = other;
        return [...value];
      });
    }

    setInspectionResult(undefined);
  };

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={true} fullWidth={true} maxWidth="xl">
        <DialogTitle>チェックシート</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={6}>
              <Label caption="チェックシート名" text={props.data.checkSheetName} underLine />
            </Grid>
            <Grid item xs={6}>
              <Label caption="型式" text={props.data.name} underLine />
            </Grid>
            {props.mode === InspectionMode.Arrive && props.data != null && (
              <>
                <Grid item xs={2}>
                  <Label caption="貸出数" text={(props.data as Arrive).numberOf.toString()} underLine />
                </Grid>
                <Grid item xs={2}>
                  <Label caption="返品数" text={(props.data as Arrive).numberOfReturn.toString()} underLine />
                </Grid>
                <Grid item xs={2}>
                  <Label caption="故障数" text={(props.data as Arrive).numberOfFailures.toString()} underLine />
                </Grid>
                <Grid item xs={2}>
                  <DigitsField
                    className={genericClasses.width100percent}
                    label="検品数(返品)"
                    value={numberOf.inputReturn}
                    onChange={input.handleOnChangeNumber("inputReturn")}
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <DigitsField
                    className={genericClasses.width100percent}
                    label="検品数(故障)"
                    value={numberOf.inputFailures}
                    onChange={input.handleOnChangeNumber("inputFailures")}
                    required
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <VirtualaizedTable
                  values={inspectionResults}
                  rowHeight={48}
                  columns={columns}
                  onClickEdit={handleOnClickEdit}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
          <Button
            className={genericClasses.margin}
            onClick={handleOnClose}
            color="primary"
            disabled={
              props.mode === InspectionMode.Arrive &&
              (inspectionResults.some((i) => !i.checked) ||
                numberOf.inputReturn == null ||
                numberOf.inputFailures == null)
            }
          >
            {props.mode === InspectionMode.Shipment ? "保存" : "検品"}
          </Button>
        </DialogActions>
      </Dialog>
      {inspectionResult != null && (
        <EditDialog mode={props.mode} inspectionResult={inspectionResult} onClose={handleOnCloseEditDialog} />
      )}
    </>
  );
};

export default React.memo(InspectionResultDialog);
