import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import Condition from "Component/Condition";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContentHeight } from "Hooks/useResize";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { useExecute, useExecuteEx, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useMessageBox } from "Hooks/useMessageBox";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import Period from "Component/Period";
import { Notification, NotificationLink } from "Models/Notification";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { swap } from "Common/Utility/GenericInterface";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { deleteButton, editButton } from "Common/Utility/AppUtility";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  editPaper: {
    width: "100%",
    height: 300,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
  list: {
    width: "100%",
    height: 200,
  },
  loading: {
    width: "100%",
    height: 400,
  },
  datePicker: {
    width: 150,
  },
  title: {
    width: `calc(100% - ${150 + theme.spacing(1)}px)`,
    marginLeft: theme.spacing(1),
  },
}));

interface EditDialogProps {
  open: boolean;
  notification: Notification;
  onClose: (notification?: Notification) => void;
}

const EditDialog = React.memo((props: EditDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props.open);

  const [editing, setEditing] = useState<InputState>(InputState.None);

  const [notification, setNotification] = useState<Notification>({
    notificationLinks: [],
  });

  const inputManager = useInputManager(setNotification, edited);

  const [notificationLinks, setNotificationLinks] = useState<NotificationLink[]>([]);

  const [input, setInput] = useState<NotificationLink>({} as NotificationLink);

  const inputManagerDetail = useInputManager(setInput);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          notification: Notification;
          notificationLinks: NotificationLink[];
          onClose: (motification?: Notification) => void;
        }
      ) => {
        object.notification.notificationLinks = object.notificationLinks;
        var response = await callWebApi().put<Notification>("/notifications", object.notification);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "お知らせ情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        if (response.data == null) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setNotificationLinks((details) => [...details, {} as NotificationLink]);
    setInput({} as NotificationLink);
  }, []);

  // 明細の編集
  const handleOnClickEdit = useCallback(
    (notificationLink: NotificationLink, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setInput(notificationLink);
    },
    []
  );

  // 明細の削除
  const handleOnClickDelete = useCallback(
    (notificationLink: NotificationLink, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setNotificationLinks((details) => {
        details.splice(rowIndex, 1);
        return [...details];
      });

      edited();
    },
    [edited]
  );

  // 明細の編集キャンセル
  const handleOnClickCancel = useCallback(
    (notificationLink: NotificationLink, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (editing === InputState.Adding) {
        setNotificationLinks((details) => {
          details.pop();
          return [...details];
        });
      }
    },
    [editing]
  );

  // 明細の編集確定
  const handleOnClickSave = useCallback(
    (notificationLink: NotificationLink, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setNotificationLinks((details) => {
        details[rowIndex] = input;
        return [...details];
      });

      edited();
    },
    [input, edited]
  );

  // 明細を1行上に移動
  const handleOnClickUp = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex === 0) {
        return;
      }

      setNotificationLinks((details) => [...swap(details, rowIndex, rowIndex - 1)]);

      edited();
    },
    [edited]
  );

  // 明細を1行下に移動
  const handleOnClickDown = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex >= notificationLinks.length - 1) {
        return;
      }

      setNotificationLinks((details) => [...swap(details, rowIndex, rowIndex + 1)]);

      edited();
    },
    [notificationLinks.length, edited]
  );

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.EditButton,
      },
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.DeleteButton,
      },
      {
        width: 128,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              <IconButton
                color="primary"
                aria-label="up"
                onClick={handleOnClickUp(rowIndex)}
                disabled={editing !== InputState.None}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="down"
                onClick={handleOnClickDown(rowIndex)}
                disabled={editing !== InputState.None}
              >
                <ArrowDownward />
              </IconButton>
            </>
          );
        },
      },
      {
        width: 200,
        label: "表示される文字",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={input.text}
              onChange={inputManagerDetail.handleOnChange("text")}
            />
          );
        },
      },
      {
        width: 120,
        label: "URL",
        dataKey: "url",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={input.url}
              onChange={inputManagerDetail.handleOnChange("url")}
            />
          );
        },
      },
    ];

    return ret;
  }, [
    handleOnClickUp,
    handleOnClickDown,
    inputManagerDetail,
    genericClasses.width100percent,
    editing,
    input.text,
    input.url,
  ]);

  useEffect(() => {
    if (props.open) {
      setNotification(props.notification);
      setNotificationLinks([...props.notification.notificationLinks]);
    }
  }, [props.open, props.notification]);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="md">
        <DialogTitle>お知らせ情報</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <DatePickersUtilsProvider>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  label="日付"
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  autoOk={true}
                  value={notification.notificationDate ?? null}
                  onChange={inputManager.handleOnChangeDate("notificationDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </DatePickersUtilsProvider>
              <TextField
                className={classes.title}
                label="題名"
                value={notification.title}
                onChange={inputManager.handleOnChange("title")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="詳細"
                value={notification.content}
                onChange={inputManager.handleOnChange("content")}
                multiline
                rows={10}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <span>
                {"※<LINK>と入力した箇所を「表示される文字」で置換します。置換箇所は「URL」へのリンクとなります。"}
                <br />
                {"　<LINK>が複数ある場合は一覧の上から順番に置換します。"}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.editPaper}>
                <VirtualaizedTable
                  values={notificationLinks}
                  rowHeight={48}
                  columns={columns}
                  onClickAddDirectInput={handleOnClickAdd}
                  onClickEdit={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                  onClickCancel={handleOnClickCancel}
                  onClickSave={handleOnClickSave}
                  setEditing={setEditing}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            className={genericClasses.margin}
            control={
              <Checkbox
                color="primary"
                checked={notification.sendMail}
                onChange={inputManager.handleOnChangeCheck("sendMail")}
              />
            }
            label="保存時にメール送信を行う"
          />
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            キャンセル
          </Button>
          <Button
            className={genericClasses.margin}
            onClick={() => {
              if (!notification.notificationDate) {
                alertAdd({ type: "info", message: "日付は必須項目です。" });
                return;
              }

              if (!notification.title) {
                alertAdd({ type: "info", message: "題名は必須項目です。" });
                return;
              }

              executePut({
                notification: notification,
                notificationLinks: notificationLinks,
                onClose: props.onClose,
              });
            }}
            color="primary"
            disabled={!props.open || inProcess || editing !== InputState.None}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

interface Condition {
  title?: string;

  from?: DateTime;

  to?: DateTime;
}

const initialCondition: Condition = {};

const NotificationManagement = () => {
  const [height, observer] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [notification, setNotification] = useState<Notification | null>(null);

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const loadingElement = useLoadingElement(
    classes.searchLoading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<Notification[]>("/notifications/search", {
            cancelToken: signal.token,
            params: { ...search },
          });

          if (response.data == null) {
            return;
          }

          setNotifications(response.data);
        },
        [search]
      ),
      false
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setNotification({
      notificationLinks: [],
    });
  }, []);

  const handleOnClose = useCallback((notification?: Notification) => {
    if (notification) {
      setNotifications((value) => {
        var index = value.findIndex((i) => i.id === notification.id);
        if (index >= 0) {
          value[index] = notification;
          return [...value];
        } else {
          return value;
        }
      });
    }
    setNotification(null);
  }, []);

  const handleOnClickEdit = useCallback(
    (notification: Notification, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setNotification(notification);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id?: string; rowIndex: number }) => {
        if (object.id == null) {
          return;
        }

        await callWebApi().delete(`/notifications/${object.id}`);

        alertAdd({ type: "info", message: "お知らせ情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setNotifications((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (notification: Notification, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "お知らせ情報を削除します。よろしいですか？"))) {
        return;
      }

      executeDelete({ id: notification.id, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.Notification
  );

  const columns: ColumnData[] = useMemo(() => {
    let columns: ColumnData[] = [
      editButton,
      deleteButton,
      {
        width: 150,
        label: "お知らせ日",
        dataKey: "notificationDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data),
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "題名",
        dataKey: "title",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(1),
      },
    ];

    return columns;
  }, [getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={observer}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item container direction="row" justify="flex-start" alignItems="center">
                <TextField
                  className={genericClasses.margin}
                  label="題名"
                  value={condition.title}
                  onChange={inputManager.handleOnChange("title")}
                />
                <Period
                  label="お知らせ日"
                  fromValue={condition.from}
                  toValue={condition.to}
                  onChangeFrom={inputManager.handleOnChangeDate("from")}
                  onChangeTo={inputManager.handleOnChangeDate("to")}
                  width={180}
                />
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="primary"
                  onClick={() => setSearch({ ...condition })}
                >
                  検索
                </Button>
                <Button
                  className={genericClasses.margin}
                  variant="outlined"
                  onClick={() => {
                    setCondition({ ...initialCondition });
                  }}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={notifications}
                setValues={setNotifications}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={handleOnClickAdd}
                onClickEdit={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {notification != null && <EditDialog open={true} notification={notification} onClose={handleOnClose} />}
    </>
  );
};

export default React.memo(NotificationManagement);
