import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Paper } from "@material-ui/core";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useExecuteEx } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import {
  CallOnClose,
  deleteButton,
  editButton,
  InputPending,
  onCloseWithSave,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import { LabelWithSelect } from "Component/SelectDialog";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { RepairHistory, RepairHistoryParts } from "Models/RepairHistory";
import { RepairParts } from "Models/RepairParts";
import { useCallFetchByAsset, useCallFetchByCompany, useCallFetchByUser } from "Hooks/useCallFetch";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { InputTimeSpan } from "Component/InputTimeSpan";
import { DateUtility, TimeSpan } from "Common/Utility/DateUtility";
import { EditRepairHistoryPartsDialog } from "./EditRepairHistoryPartsDialong";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    marginRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    height: 300,
  },
  person: {
    width: 200,
    marginRight: theme.spacing(1),
  },
}));

interface Props extends InputPending {
  open: boolean;

  data: RepairHistory;

  editAuth: boolean;
}

export const EditDialog = React.memo((props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState<RepairHistory>(props.data);

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [edited, confirm] = useWhetherEdited(props, isAssetMenuEditValid);

  const inputManager = useInputManager(setData, edited);

  const [asset, fetchAsset, columnsAsset, narrowDownAsset] = useCallFetchByAsset("");

  const [user, fetchUser, columnsUser, narrowDownUser] = useCallFetchByUser("");

  const [company, fetchCompany, columnsCompany, narrowDownCompany] = useCallFetchByCompany("");

  const [editData, setEditData] = useState<(RepairHistoryParts & { index?: number }) | undefined>(undefined);

  const columnsRepairPartsWithIcon = useMemo(() => {
    const columnData: ColumnData[] = [];
    if (props.editAuth) {
      columnData.push(editButton, deleteButton);
    }
    return columnData.concat(
      {
        width: 200,
        label: "メーカー",
        dataKey: "maker",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 200,
        label: "品番",
        dataKey: "productNumber",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 300,
        label: "品名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 120,
        label: "価格",
        dataKey: "amount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowData: RepairParts) => (rowData.amount == null ? "" : data.toLocaleString() + " 円"),
      },
      {
        width: 120,
        label: "個数",
        dataKey: "quantity",
        headerAlign: "center",
        bodyAlign: "right",
      }
    );
  }, [props.editAuth]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: RepairHistory; onClose: CallOnClose }) => {
        var response = await callWebApi().put<RepairHistory>("/repairHistories", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "修理履歴を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "修理履歴",
    <EditDialog open={true} data={data} onClose={props.onClose} editAuth={true} />,
    props.onClose,
    props.onHold
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    const message: Array<string> = [];
    if (data.failureDate == null) {
      message.push("故障発生日");
    }

    if (data.usedCompanyBy == null) {
      message.push("使用先企業");
    }

    if (data.foundBy == null) {
      message.push("故障発見者");
    }

    if (data.assetMasterid == null) {
      message.push("資産番号");
    }

    if (data.failureDetails == null) {
      message.push("故障内容");
    }

    if (message.length > 0) {
      alertAdd({ type: "info", message: message.join("、") + "は必須項目です。" });
      return;
    }

    if (
      !DateUtility.isValid(data.failureDate) ||
      (data.repairCompletionDate != null && !DateUtility.isValid(data.repairCompletionDate))
    ) {
      alertAdd({ type: "info", message: "日付の入力が不正です。" });
      return;
    }

    executePut({ data: data, onClose: props.onClose });
  }, [alertAdd, props, data, executePut]);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      repairPartsId: null,
      maker: "",
      productNumber: "",
      name: "",
      amount: null,
      quantity: 1,
    });
  }, []);

  const handleOnClickEdit = useCallback(
    (data: RepairHistoryParts, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...data, index: rowIndex });
    },
    []
  );

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setData((value) => {
        value.repairHistoryParts.splice(rowIndex, 1);
        return { ...value, repairHistoryParts: [...value.repairHistoryParts] };
      });
    },
    []
  );

  const handleOnClose = (data: (RepairHistoryParts & { index?: number }) | undefined) => {
    if (data) {
      setData((value) => {
        const { index, ...other } = data;
        if (index == null) {
          return { ...value, repairHistoryParts: [...value.repairHistoryParts, other] };
        } else {
          value.repairHistoryParts[index] = other;
          return { ...value };
        }
      });
    }

    setEditData(undefined);
  };

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="lg">
      <DialogTitle>修理履歴情報</DialogTitle>
      <DialogContent>
        <DatePickersUtilsProvider>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
              <KeyboardDatePicker
                className={classes.datePicker}
                label="故障発生日"
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                autoOk={true}
                value={data.failureDate}
                onChange={inputManager.handleOnChangeDate("failureDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disabled={!props.editAuth}
              />
              {fetchCompany ?? (
                <LabelWithSelect
                  className={classes.person}
                  caption="使用先企業"
                  text={data.usedCompanyByName}
                  data={company}
                  columns={columnsCompany}
                  onClickNarrowDown={narrowDownCompany}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, usedCompanyBy: result.value, usedCompanyByName: result.text };
                  })}
                  maxWidth="sm"
                  disabled={!props.editAuth}
                  underLine
                />
              )}
              {fetchUser ?? (
                <LabelWithSelect
                  className={classes.person}
                  caption="故障発見者"
                  text={data.foundByName}
                  data={user}
                  columns={columnsUser}
                  onClickNarrowDown={narrowDownUser}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, foundBy: result.value, foundByName: result.text };
                  })}
                  maxWidth="sm"
                  disabled={!props.editAuth}
                  underLine
                />
              )}
              {fetchAsset ?? (
                <LabelWithSelect
                  className={classes.datePicker}
                  caption="資産番号"
                  text={data.assetNumber}
                  data={asset}
                  columns={columnsAsset}
                  onClickNarrowDown={narrowDownAsset}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, assetMasterid: result.id, assetNumber: result.assetNumber };
                  })}
                  maxWidth="sm"
                  disabled={!props.editAuth}
                  underLine
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="故障原因"
                value={data.causeOfFailure}
                onChange={inputManager.handleOnChange("causeOfFailure")}
                multiline
                disabled={!props.editAuth}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="故障内容"
                value={data.failureDetails}
                onChange={inputManager.handleOnChange("failureDetails")}
                multiline
                disabled={!props.editAuth}
              />
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
              <KeyboardDatePicker
                className={classes.datePicker}
                label="修理完了日"
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                autoOk={true}
                value={data.repairCompletionDate}
                onChange={inputManager.handleOnChangeDate("repairCompletionDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disabled={!props.editAuth}
              />
              <InputTimeSpan
                className={genericClasses.marginRight}
                caption="修理所要時間"
                defaultValue={data.repairTime ? new TimeSpan(data.repairTime).toTimes() : {}}
                onChange={inputManager.handleOnChange("repairTime")}
                disabled={!props.editAuth}
              />
              {fetchUser ?? (
                <LabelWithSelect
                  className={classes.person}
                  caption="修理対応者"
                  text={data.repairedByName}
                  data={user}
                  columns={columnsUser}
                  onClickNarrowDown={narrowDownUser}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, repairedBy: result.value, repairedByName: result.text };
                  })}
                  maxWidth="sm"
                  disabled={!props.editAuth}
                  underLine
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="修理内容"
                value={data.repairDetails}
                onChange={inputManager.handleOnChange("repairDetails")}
                multiline
                disabled={!props.editAuth}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <VirtualizedTable
                  values={data.repairHistoryParts}
                  rowHeight={48}
                  columns={columnsRepairPartsWithIcon}
                  onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
                  onClickEdit={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <DigitsField
                className={classes.datePicker}
                label="修理使用金額"
                value={data.repairUsageAmount}
                onChange={inputManager.handleOnChangeNumber("repairUsageAmount")}
                maxLength={9}
                disabled={!props.editAuth}
              />
            </Grid>
          </Grid>
        </DatePickersUtilsProvider>
        {editData && <EditRepairHistoryPartsDialog data={editData} onClose={handleOnClose} />}
      </DialogContent>
      <DialogActions>
        {isAssetMenuEditValid ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
