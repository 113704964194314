import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useCallback } from "react";
import { EstimateDetail } from "Models/Estimate";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { ModelMaster } from "Models/ModelMaster";
import { useInputManager } from "Common/Utility/HandleUtility";
import { EstimateDetailType, EstimateDetailTypeComboItems } from "Common/Utility/Constants";
import { AssetMaster } from "Models/Asset";
import { useAlertAdd } from "Common/Component/AlertList";
import { LineupSummary } from "Models/Lineup";
import { Rental } from "./Rental";
import { Sale } from "./Sale";
import { SimpleInput } from "./SimpleInput";
import { estimateInitialize } from "./EstimateDialog";
import { DateUtility } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  type: {
    width: 200,
    margin: theme.spacing(1),
  },
}));

interface EstimateDetailDialogProps {
  editable: boolean;
  onClose: (detail: EstimateDetail | null) => void;
  models: ModelMaster[];
  assets: AssetMaster[];
  lineupSummaries: LineupSummary[];
  detail: EstimateDetail;
  closingDay: number | null;
}

export const EstimateDetailDialog = React.memo((props: EstimateDetailDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [detail, setDetail] = useState({ ...props.detail });

  const [edited, confirm] = useWhetherEdited(true);

  const inputManager = useInputManager(setDetail, edited);

  const handleOnClickSaveInAction = useCallback(() => {
    switch (detail.type) {
      case EstimateDetailType.Rental:
        if (detail.rentalDateFrom == null) {
          alertAdd({ type: "info", message: "期間 - Fromは必須項目です。" });
          return;
        }
        if (!DateUtility.isValid(detail.rentalDateFrom)) {
          alertAdd({ type: "info", message: "期間 - Fromが不正です。" });
          return;
        }
        if (detail.rentalDateTo != null && !DateUtility.isValid(detail.rentalDateTo)) {
          alertAdd({ type: "info", message: "期間 - Toが不正です。" });
          return;
        }
        if (
          detail.rentalDateFrom != null &&
          detail.rentalDateTo != null &&
          new Date(detail.rentalDateFrom) > new Date(detail.rentalDateTo)
        ) {
          alertAdd({ type: "info", message: "期間が逆転しています。" });
          return;
        }
        break;
    }
    props.onClose(detail);
  }, [alertAdd, detail, props]);

  const handleOnChangeSelectType = (event: any) => {
    setDetail({
      ...estimateInitialize,
      type: event.target.value,
      numberOfDaily: event.target.value === EstimateDetailType.Rental ? 1 : null,
    });
    edited();
  };

  const inputArea = useMemo(() => {
    switch (detail.type) {
      case EstimateDetailType.Rental:
        return (
          <Rental
            models={props.models}
            assets={props.assets}
            lineupSummaries={props.lineupSummaries}
            editable={props.editable}
            detail={detail}
            setDetail={setDetail}
            inputManager={inputManager}
            closingDay={props.closingDay}
          />
        );
      case EstimateDetailType.Sale:
        return (
          <Sale
            models={props.models}
            assets={props.assets}
            lineupSummaries={props.lineupSummaries}
            editable={props.editable}
            detail={detail}
            setDetail={setDetail}
            inputManager={inputManager}
          />
        );
      case EstimateDetailType.Fare:
        return (
          <SimpleInput
            editable={props.editable}
            detail={detail}
            setDetail={setDetail}
            inputManager={inputManager}
            cost
            taxExemption
          />
        );
      case EstimateDetailType.Discount:
        return (
          <SimpleInput editable={props.editable} detail={detail} setDetail={setDetail} inputManager={inputManager} />
        );
      case EstimateDetailType.Other:
        return (
          <SimpleInput
            editable={props.editable}
            detail={detail}
            setDetail={setDetail}
            inputManager={inputManager}
            cost
            taxExemption
          />
        );
      default:
        return <></>;
    }
  }, [detail, props.models, props.assets, props.lineupSummaries, props.editable, props.closingDay, inputManager]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={true} fullWidth={true} maxWidth="lg">
      <DialogTitle>見積明細</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
          <Grid item xs={12}>
            <FormControl className={classes.type} disabled={!props.editable}>
              <InputLabel id="estimate-detail-type-select-label">種別</InputLabel>
              <Select
                labelId="estimate-detail-type-select-label"
                id="estimate-detail-type-select"
                value={detail.type}
                onChange={handleOnChangeSelectType}
              >
                {EstimateDetailTypeComboItems.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {inputArea}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!props.editable ? (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose(null))} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={() => confirm(() => props.onClose(null))}
              color="primary"
            >
              キャンセル
            </Button>
            <Button className={genericClasses.margin} onClick={handleOnClickSaveInAction} color="primary">
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});
