import React, { useCallback, useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGenericStyles } from "Common/Utility/Styles";
import { BusinessInfo } from "Models/BusinessInfo";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useExecuteEx } from "Hooks/useFetch";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { BusinessProvider } from "./Business";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";

interface AddDialogProps {
  open: boolean;
  onClose: (result?: BusinessInfo) => void;
}

const useStyles = makeStyles((theme) => ({
  selectCustomer: {
    width: 300,
    margin: theme.spacing(1),
    borderBottom: "solid 1px #949494",
  },
}));

const AddDialog = (props: AddDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [businessInfo, setBusinessInfo] = useState<BusinessInfo>({ customerName: "" } as BusinessInfo);

  const customers = BusinessProvider.useGlobalState("customer");

  const [edited, confirm] = useWhetherEdited(props.open);

  const handleOnSelected = useCallback(
    (result: ComboItem) => {
      setBusinessInfo((value) => {
        return { ...value, customerId: result.value, customerName: result.text };
      });
      edited();
    },
    [edited]
  );

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { businessInfo: BusinessInfo; onClose: (result?: BusinessInfo) => void }
      ) => {
        if (!object.businessInfo.customerId) {
          alertAdd({ type: "info", message: "お客様名は必須項目です。" });
          return;
        }

        var response = await callWebApi().post<BusinessInfo>("/business", object.businessInfo);

        alertAdd({ type: "success", message: "受注を作成しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(() => {
    executePost({ businessInfo: businessInfo, onClose: props.onClose });
  }, [props, businessInfo, executePost]);

  useEffect(() => {
    if (props.open) {
      setBusinessInfo({ customerName: "" } as BusinessInfo);
    }
  }, [props.open]);

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "お客様名",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={false} maxWidth="sm">
      <DialogTitle>受注情報の作成</DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <LabelWithSelect
              className={classes.selectCustomer}
              caption="お客様名"
              text={businessInfo.customerName}
              data={customers}
              columns={columns}
              onClickNarrowDown={useNarrowDown(customers, "text")}
              onSelected={handleOnSelected}
              maxWidth="sm"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickSaveInAction}
          color="primary"
          disabled={!props.open || inProcess}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddDialog);
