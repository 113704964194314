import React, { useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Grid, Paper } from "@material-ui/core";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { ReportConfig } from "Models/ReportConfig";
import { deliverySlipConfig } from "./Reports/DeliverySlip";
import { invoiceConfig } from "./Reports/Invoice";
import { invoiceSummaryConfig } from "./Reports/InvoiceSummary";
import { estimateConfig } from "./Reports/Estimate";
import { shippingAdviceConfig } from "./Reports/ShippingAdvice";
import { ReturnInspectionConfig } from "./Reports/ReturnInspection";
import { CheckBox, IndeterminateCheckBox } from "@material-ui/icons";

export const simpleColumnData: ColumnData = {
  width: 300,
  label: "名称",
  dataKey: "text",
  headerAlign: "center",
  bodyAlign: "left",
  fit: true,
};

export interface Checked {
  checked: boolean;
}

export const CheckColumn = <T extends Checked>(
  data: T[],
  setData: React.Dispatch<React.SetStateAction<T[]>>
): ColumnData => {
  return {
    width: 80,
    label: "",
    headerAlign: "center",
    bodyAlign: "center",
    rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
      const numberOfChecked = data.reduce((sum, i) => sum + (i.checked ? 1 : 0), 0);

      return (
        <Checkbox
          checked={numberOfChecked > 0}
          onChange={() => {
            setData((value) => {
              const checked = numberOfChecked === 0;
              value.forEach((i) => (i.checked = checked));
              return [...value];
            });
          }}
          color="primary"
          checkedIcon={numberOfChecked === data.length ? <CheckBox /> : <IndeterminateCheckBox />}
        />
      );
    },
    rendererInCell: (data: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return (
        <Checkbox
          checked={data.checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setData((value) => {
              value[rowIndex].checked = checked;
              return [...value];
            });
          }}
          color="primary"
        />
      );
    },
  };
};

export const calcReportPages = (first: number, footer: number, next: number, length: number) => {
  const ret: number[] = [];

  let status = 0; // 0: start, 1: footer, 2: next

  let remaining = length;

  while (true) {
    switch (status) {
      case 0:
        if (remaining <= first) {
          ret.push(remaining);
          return ret;
        } else {
          ret.push(first);
          remaining -= first;
          status = 1;
        }
        break;
      case 1:
        if (remaining <= footer) {
          // 残数がフッター数以下の場合は1行分だけ次ページにして前ページに残り-1を加える
          ret[ret.length - 1] += remaining - 1;
          ret.push(1);
          return ret;
        } else {
          // 残数がフッター数を超える場合は前ページにフッター数を加算する
          ret[ret.length - 1] += footer;
          // フッター数分先に進める
          remaining -= footer;
          status = 2;
        }
        break;
      case 2:
        if (remaining <= next) {
          ret.push(remaining);
          return ret;
        } else {
          ret.push(next);
          remaining -= next;
          status = 1;
        }
        break;
    }
  }
};

const useStyles = makeStyles((theme) => ({
  search: {
    marginLeft: 17,
  },
}));

const reports: ReportConfig[] = [
  estimateConfig,
  deliverySlipConfig,
  invoiceConfig,
  invoiceSummaryConfig,
  shippingAdviceConfig,
  ReturnInspectionConfig,
];

export default React.memo(() => {
  const classes = useStyles();

  const [config, setConfig] = useState<ReportConfig>({
    text: "",
    element: <></>,
  });

  const column: ColumnData[] = useMemo(() => [{ ...simpleColumnData, label: "帳票名" }], []);

  const handleOnSelected = useCallback((result: ReportConfig) => {
    setConfig(result);
  }, []);

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <LabelWithSelect
            className={classes.search}
            caption="帳票"
            text={config.text}
            data={reports}
            columns={column}
            onClickNarrowDown={useNarrowDown(reports, "text")}
            onSelected={handleOnSelected}
          />
        </Paper>
      </Grid>
      {config.element}
    </Grid>
  );
});
