import { DateTime } from "Common/Utility/DateUtility";

export const numberOfAsset = (assetMsater: AssetMaster): number => {
  return assetMsater.stockHistories.reduce((prev, current) => prev + current.numberOf, 0);
};

export interface AssetMaster {
  id?: string;

  category: number;

  assetGroupId?: string;

  assetGroupName?: string;

  assetNumber: string | null;

  modelMasterId?: string;

  model?: string;

  name?: string;

  cost?: number;

  updatedAt?: DateTime;

  lendingPeriods: LendingPeriod[];

  stockHistories: StockHistory[];

  indexRuleId?: string;
}

export interface LendingPeriod {
  id?: string;

  shipmentId?: string;

  assetMasterId: string;

  companyId?: string;

  from: DateTime;

  to: DateTime | null;

  numberOf: number;

  shipmentStatus?: number;

  returnAssets: ReturnAsset[];
}

export interface StockHistory {
  id: string | null;

  assetMasterId: string | null;

  numberOf: number;

  memo: string;

  creatorName: string;

  createdAt: DateTime | null;
}

export interface ReturnAsset {
  lendingPeriodId: string;

  returnAt: DateTime;

  numberOfReturn: number;

  numberOfFailures: number;
}
