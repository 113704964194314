import React, { useCallback, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import clsx from "clsx";
import { useAlertAdd } from "Common/Component/AlertList";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import {
  CallOnClose,
  deleteButton,
  editButton,
  InputPending,
  OnClose,
  onCloseWithSave,
  referenceButton,
} from "Common/Utility/AppUtility";
import { Design } from "Common/Utility/Constants";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useInputManager } from "Common/Utility/HandleUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { useExecuteEx } from "Hooks/useFetch";
import { useHoldInput } from "Hooks/useHoldInput";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { WorkTaskTemplate, WorkTaskTemplateTask } from "Models/WorkTaskTemplate";
import { EditTemplateTaskDialog } from "./EditTemplateTaskDialog";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 500,
  },
  dialogPaper2: {
    height: 300,
  },
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  text: {
    width: 200,
    marginRight: Design.margin,
  },
}));

const initialTemplateTaskData: () => WorkTaskTemplateTask = () => {
  return {
    id: null,
    name: "",
    members: [],
  };
};

interface EditTemplateDialogProps extends InputPending {
  open: boolean;

  data: WorkTaskTemplate;

  editAuth: boolean;

  userMaster: ComboItem[];
}

export const EditTemplateDialog = (props: EditTemplateDialogProps) => {
  const genericClasses = useGenericStyles();

  const classes = useStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props, props.editAuth);

  const [data, setData] = useState<WorkTaskTemplate>(props.data);

  const inputManager = useInputManager(setData, edited);

  const [editTasksData, setEditTasksData] = useState<WorkTaskTemplateTask[]>(props.data.tasks);

  const [editData, setEditData] = useState<WorkTaskTemplateTask | undefined>(undefined);

  const handleOnClickAdd = useCallback(() => {
    setEditData(initialTemplateTaskData);
  }, []);

  const handleOnClickEdit = useCallback(
    (data: WorkTaskTemplateTask, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData(data);
    },
    []
  );

  const handleOnClickDelete = useCallback(
    (data: WorkTaskTemplateTask, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditTasksData((values) => {
        values.splice(rowIndex, 1);
        return [...values];
      });
      edited();
    },
    [edited]
  );

  const handleOnClickUpIndex = useCallback(
    (rowIndex: number) => () => {
      setEditTasksData((values) => {
        if (rowIndex === 0) {
          return values;
        }

        const tmp = values[rowIndex];
        values[rowIndex] = values[rowIndex - 1];
        values[rowIndex - 1] = tmp;

        return [...values];
      });
      edited();
    },
    [edited]
  );

  const handleOnClickDownIndex = useCallback(
    (rowIndex: number) => () => {
      setEditTasksData((values) => {
        if (values.length === rowIndex + 1) {
          return values;
        }

        const tmp = values[rowIndex];
        values[rowIndex] = values[rowIndex + 1];
        values[rowIndex + 1] = tmp;

        return [...values];
      });
      edited();
    },
    [edited]
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: WorkTaskTemplate; onClose: CallOnClose }) => {
        const response = await callWebApi().put<WorkTaskTemplate>("/workTaskTemplates", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "テンプレート情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = () => {
    if (!data.name) {
      alertAdd({ type: "info", message: "テンプレート名は必須項目です。" });
      return;
    }

    if (editTasksData.length === 0) {
      alertAdd({ type: "info", message: "タスクを１件以上登録してください。" });
      return;
    }

    executePut({ data: { ...data, tasks: editTasksData }, onClose: props.onClose });
  };

  const handleOnClose: CallOnClose = (args?: { onClose: OnClose; key?: string }) => {
    args?.onClose(setEditTasksData, "id");
    setEditData(undefined);
  };

  const convertNames = useCallback((data: any, rowData: WorkTaskTemplateTask): string => {
    let ret = "";

    rowData.members.forEach((value) => {
      if (ret === "") {
        ret += value.text;
      } else {
        ret += ", " + value.text;
      }
    });

    return ret;
  }, []);

  const handleOnClickHoldInAction = useHoldInput(
    "テンプレート",
    <EditTemplateDialog
      open={true}
      editAuth={true}
      data={{ ...data, tasks: [...editTasksData] }}
      userMaster={props.userMaster}
      onClose={props.onClose}
    />,
    props.onClose,
    props.onHold
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];

    if (props.editAuth) {
      ret.push(editButton, deleteButton);
      ret.push({
        width: 128,
        label: "",
        bodyAlign: "center",
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              <IconButton color="primary" onClick={handleOnClickUpIndex(rowIndex)}>
                <ArrowUpward />
              </IconButton>
              <IconButton color="primary" onClick={handleOnClickDownIndex(rowIndex)}>
                <ArrowDownward />
              </IconButton>
            </>
          );
        },
      });
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 300,
        label: "タスク名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 300,
        label: "ユーザー名",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        convert: convertNames,
      }
    );

    return ret;
  }, [props.editAuth, handleOnClickUpIndex, handleOnClickDownIndex, convertNames]);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} maxWidth="md" fullWidth={true}>
        <DialogTitle>テンプレート</DialogTitle>
        <DialogContent>
          <TextField
            label="テンプレート名"
            className={clsx(genericClasses.width100percent, genericClasses.marginBottom)}
            onChange={inputManager.handleOnChange("name")}
            value={data.name}
            disabled={!props.editAuth}
          />
          <Paper className={classes.dialogPaper}>
            <VirtualizedTable
              columns={columns}
              values={editTasksData}
              rowHeight={48}
              onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
              onClickEdit={handleOnClickEdit}
              onClickReference={handleOnClickEdit}
              onClickDelete={handleOnClickDelete}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          {props.editAuth ? (
            <>
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickHoldInAction}
                color="primary"
                disabled={!props.open || inProcess}
              >
                保留
              </Button>
              <Button className={genericClasses.margin} color="primary" onClick={() => confirm(() => props.onClose())}>
                キャンセル
              </Button>
              <Button
                className={genericClasses.margin}
                color="primary"
                disabled={!props.open || inProcess}
                onClick={handleOnClickSaveInAction}
              >
                保存
              </Button>
            </>
          ) : (
            <Button className={genericClasses.margin} color="primary" onClick={() => props.onClose()}>
              閉じる
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {editData && (
        <EditTemplateTaskDialog
          data={editData}
          editAuth={props.editAuth}
          userMaster={props.userMaster}
          edited={edited}
          onClose={handleOnClose}
        />
      )}
    </>
  );
};
