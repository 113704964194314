import { Button, Grid, IconButton, makeStyles, Paper, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { useIsValidMenuEditAuthority, OnClose } from "Common/Utility/AppUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import { useExecute } from "Hooks/useFetch";
import { Shipment, ShipmentDetail } from "Models/Shipment";
import React, { useCallback, useMemo, useState } from "react";
import { Props } from "./BusinessDetail";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateUtility } from "Common/Utility/DateUtility";
import clsx from "clsx";
import { Design, ShipmentStatus, ShipmentStatusComboItem, toComboText, VisibleColumn } from "Common/Utility/Constants";
import EditShipment, { EditShipmentMode } from "Pages/Shipment/EditShipment";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { Delete, Edit, Info } from "@material-ui/icons";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import Inspection from "Pages/Shipment/Inspection";
import { useContentHeight } from "Hooks/useResize";
import { useColumnControl } from "Hooks/useColumnControl";
import { useInputManager } from "Common/Utility/HandleUtility";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  padding: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const initialShipment: Shipment = {
  id: undefined,
  businessId: undefined,
  shipDate: undefined,
  companyId: undefined,
  companyName: "",
  status: 0,
  updatedAt: undefined,
  details: [] as ShipmentDetail[],
  sequence: null,
} as const;

interface Condition {
  isLatest: boolean;
}

const ShipmentInBusiness = (props: Props) => {
  const [height] = useContentHeight(
    Design.topButtonHeight + Design.componentUnitHeight + Design.margin + 64 + Design.margin
  );

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const [shipments, setShipments] = useState<Shipment[]>([]);

  const [shipment, setShipment] = useState<{ shipment: Shipment; editShipmentMode: EditShipmentMode } | undefined>(
    undefined
  );

  const [shipmentDirect, setShipmentDirect] = useState<Shipment | undefined>(undefined);

  const [condition, setCondition] = useState<Condition>({ isLatest: true });

  const inputManager = useInputManager(setCondition);

  const filtered = useMemo(() => {
    return shipments.filter((i) => (condition.isLatest ? i.sequence == null : true));
  }, [shipments, condition.isLatest]);

  const fetchResultLoad = useSimpleFetch(`/shipments/businessid/${businessInfo.id}`, setShipments, true, undefined, [
    businessInfo.id,
  ]);

  const handleOnClickReload = useCallback(() => fetchResultLoad.reload(), [fetchResultLoad]);

  const handleOnClickAdd = useCallback(() => {
    setShipment({
      shipment: { ...initialShipment, businessId: businessInfo.id },
      editShipmentMode: EditShipmentMode.Edit,
    });
  }, [businessInfo.id]);

  const handleOnClickEdit = useCallback(
    (data: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return () => {
        setShipment({ shipment: { ...data }, editShipmentMode: EditShipmentMode.Edit });
      };
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          id: string;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete(`/shipments/${object.id}`);

        alertAdd({ type: "success", message: "出荷情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setShipments((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    (data: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return async () => {
        if (data.id != null) {
          if (await message.confirm("削除確認", "出荷情報を削除します。よろしいですか？")) {
            executeDelete({ id: data.id, rowIndex: rowIndex });
          }
        }
      };
    },
    [executeDelete, message]
  );

  const handleOnClickReference = useCallback(
    (data: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return () => {
        setShipment({ shipment: { ...data }, editShipmentMode: EditShipmentMode.Reference });
      };
    },
    []
  );

  const handleOnClose = useOnCloseEditDialog("Shipment", setShipment, setShipments, "id", businessInfo.id);

  const handleOnCloseEditShipment = useCallback(
    (args?: { onClose: OnClose; key?: string }) => {
      handleOnClose(args);
    },
    [handleOnClose]
  );

  const handleOnClickChangePeriod = useCallback(
    (data: Shipment) => async () => {
      if (await message.confirm("期間変更確認", "貸出期間を変更しますか？")) {
        setShipment({ shipment: { ...data }, editShipmentMode: EditShipmentMode.Change });
      }
    },
    [message]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Shipment);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Shipment);

  const columns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        bodyAlign: "center",
        widthType: "fix",
        rendererInCell: (data: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return isValidEdit && data.status === ShipmentStatus.Created ? (
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={handleOnClickEdit(data, columnData, rowIndex, columnIndex)}
            >
              <Edit />
            </IconButton>
          ) : (
            <IconButton
              color="primary"
              aria-label="reference"
              onClick={handleOnClickReference(data, columnData, rowIndex, columnIndex)}
            >
              <Info />
            </IconButton>
          );
        },
      },
    ];

    if (isValidEdit) {
      columns.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        widthType: "fix",
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return data.status === ShipmentStatus.Created ? (
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={handleOnClickDelete(data, columnData, rowIndex, columnIndex)}
            >
              <Delete />
            </IconButton>
          ) : (
            <></>
          );
        },
      });
    }

    columns.push(
      {
        width: 300,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 250,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(1),
      },
      {
        width: 100,
        label: "状況",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => toComboText(ShipmentStatusComboItem, data),
        visible: getVisible(2),
      },
      {
        width: 100,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        component: (data: Shipment, rowIndex: number) =>
          data.sequence == null ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShipmentDirect(data);
              }}
            >
              検品
            </Button>
          ) : (
            <></>
          ),
      },
      {
        width: 140,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        component: (data: Shipment, rowIndex: number) =>
          isValidEdit && data.status === ShipmentStatus.Finished && data.sequence == null ? (
            <Button variant="contained" color="primary" onClick={handleOnClickChangePeriod(data)}>
              期間変更
            </Button>
          ) : (
            <></>
          ),
      }
    );

    return columns;
  }, [
    isValidEdit,
    handleOnClickEdit,
    handleOnClickReference,
    handleOnClickDelete,
    getVisible,
    handleOnClickChangePeriod,
  ]);

  return (
    <>
      <Paper>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={genericClasses.margin}>出荷情報</Typography>
            <IconButton
              className={genericClasses.margin}
              color="primary"
              aria-label="udpate"
              onClick={handleOnClickReload}
            >
              <Autorenew />
            </IconButton>
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  checked={condition.isLatest}
                  onChange={inputManager.handleOnChangeCheck("isLatest")}
                  color="primary"
                />
              }
              label="最新の出荷情報のみ表示"
            />
          </Grid>
          <div className={classes.padding}>
            {useLoadingElement(classes.paper, LoadingMode.Simple, fetchResultLoad) ?? (
              <Paper className={clsx(classes.paper)}>
                <VirtualaizedTable
                  values={filtered}
                  setValues={setShipments}
                  rowHeight={48}
                  columns={columns}
                  onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                  onCloseContextMenu={handleOnCloseContextMenu}
                  onChangeHeaderVisible={handleOnChangeHeaderVisible}
                  headerContext
                />
              </Paper>
            )}
          </div>
        </Grid>
      </Paper>
      {shipment != null && (
        <EditShipment open={true} {...shipment} onClose={handleOnCloseEditShipment} shouldInitialize />
      )}
      {shipmentDirect != null && (
        <Inspection
          shipment={shipmentDirect}
          onClose={(shipment?: Shipment) => {
            if (shipment) {
              setShipments((value) => {
                const index = value.findIndex((i) => i.id === shipment.id);
                if (index >= 0) {
                  value[index] = shipment;
                  return [...value];
                } else {
                  return value;
                }
              });
            }

            setShipmentDirect(undefined);
          }}
        />
      )}
    </>
  );
};

export default React.memo(ShipmentInBusiness);
