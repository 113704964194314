import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { AppProvider } from "App";
import React, { useState, useMemo, useCallback } from "react";
import TaskPanel from "./SchedulePanel/TaskPanel";
import UserPanel from "./SchedulePanel/UserPanel";
import { WorkTaskTemplatePanel } from "./WorkTaskTemplatePanel/WorkTaskTemplate";
import { useGenericStyles } from "Common/Utility/Styles";

const Schedule = () => {
  const genericClasses = useGenericStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const height = AppProvider.useGlobalState("height");

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <TaskPanel height={height} />
            <UserPanel height={height} />
          </Grid>
        );
      case 1:
        return <WorkTaskTemplatePanel />;
    }
  }, [tabIndex, height]);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <>
      <Paper className={genericClasses.marginBottom}>
        <Tabs value={tabIndex} onChange={handleOnChangeTabs} indicatorColor="primary" textColor="primary">
          <Tab label="日程" />
          <Tab label="テンプレート" />
        </Tabs>
      </Paper>
      {panel}
    </>
  );
};

export default React.memo(Schedule);
