import React, { useCallback, useMemo, useState } from "react";
import { Button, FormControlLabel, Grid, makeStyles, Paper, Checkbox } from "@material-ui/core";
import { ReportConfig } from "Models/ReportConfig";
import { useGenericStyles } from "Common/Utility/Styles";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callPrint, Print } from "Common/Component/Print";
import Condition from "Component/Condition";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useContentHeight } from "Hooks/useResize";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute } from "Hooks/useFetch";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import TextField from "Component/TextField";
import { ReturnInspection } from "Models/ReturnInspection";
import ReturnInspectionDesign from "./ReturnInspectionDesign";
import { useMessageBox } from "Hooks/useMessageBox";
import { useAlertAdd } from "Common/Component/AlertList";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { CheckColumn } from "../Report";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  labelWithSelect: {
    width: 300,
    margin: theme.spacing(1),
  },
  select: {
    width: 100,
    margin: theme.spacing(1),
  },
}));

interface Condition {
  unOutputed: boolean;

  model?: string;

  companyName?: string;
}

const defaultCondition: Condition = {
  unOutputed: true,
};

const Report = () => {
  const [height, ref] = useContentHeight(46);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [condition, setCondition] = useState<Condition>(defaultCondition);

  const [search, setSearch] = useState<Condition>(defaultCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<ReturnInspection[]>([]);

  const [signImage, setSignImage] = useState<string>("");

  const printData = useMemo(() => data.filter((i) => i.checked), [data]);

  const fetchdata = useSimpleFetch("/ReturnInspections", setData, false, search, [search]);

  const fetchSignImage = useSimpleFetch("/reports/kdwsign", (data: { signImage: string }) => {
    setSignImage(data.signImage);
  });

  const executePut = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: ReturnInspection[] }) => {
        const response = await callWebApi().put<ReturnInspection[]>("/ReturnInspections", object.data);

        alertAdd({ type: "success", message: "発行済みに変更しました。" });

        if (unmounted.value) {
          return;
        }

        setData((values) => {
          response.data.forEach((value) => {
            const index = values.findIndex((i) => i.id === value.id);
            if (index !== -1) {
              values[index] = value;
            }
          });

          return [...values];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickPrint = async () => {
    callPrint("printout");

    if (printData.findIndex((i) => i.outputedBy == null) >= 0) {
      if (await message.confirm("発行確認", "印刷対象を発行済みにしますか？")) {
        executePut({ data: printData });
      }
    }
  };

  const loadingElement = useLoadingElement(classes.loading, LoadingMode.Circular, fetchdata, fetchSignImage);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ReportReturnInspection
  );

  const columns: ColumnData[] = useMemo(
    () => [
      CheckColumn(data, setData),
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        width: 300,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 350,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "故障数",
        dataKey: "numberOfFailures",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(3),
      },
      {
        width: 300,
        label: "発行者",
        dataKey: "outputedName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      },
    ],
    [getVisible, data]
  );

  return (
    <>
      <Grid item xs={12}>
        <Condition observer={ref}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  color="primary"
                  checked={condition.unOutputed}
                  onChange={inputManager.handleOnChangeCheck("unOutputed")}
                />
              }
              label="未発行のみ"
            />
            <TextField
              className={genericClasses.margin}
              label="型式"
              value={condition.model}
              onChange={inputManager.handleOnChange("model")}
            />
            <TextField
              className={genericClasses.margin}
              label="出荷先企業"
              value={condition.companyName}
              onChange={inputManager.handleOnChange("companyName")}
            />
            <Button
              className={genericClasses.margin}
              variant="contained"
              color="primary"
              onClick={() => setSearch({ ...condition })}
            >
              検索
            </Button>
            <Button className={genericClasses.margin} variant="outlined" onClick={() => setCondition(defaultCondition)}>
              クリア
            </Button>
          </Grid>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Button variant="contained" color="primary" onClick={handleOnClickPrint}>
            PDF出力
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualizedTable
              values={data}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      <Print id="printout" title="帳票印刷">
        <ReturnInspectionDesign signImage={signImage} returnInspection={printData} />
      </Print>
    </>
  );
};

export const ReturnInspectionConfig: ReportConfig = {
  text: "帰庫検収書",
  element: <Report />,
};
