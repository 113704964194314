import React, { ChangeEvent, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { ComboItemCustomerEx, VoucherDetail, VoucherSummary } from "Models/Voucher";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { swap } from "Common/Utility/GenericInterface";
import Label from "Component/Label";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { AppProvider } from "App";
import { calcTax } from "Models/TaxMaster";
import { useAlertAdd } from "Common/Component/AlertList";
import { LabelWithSelect, SelectDialog, useNarrowDown, validateNarrowDownKey } from "Component/SelectDialog";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useFetch, useExecuteEx } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { useMessageBox } from "Hooks/useMessageBox";
import IntroductionFeeBillingRequestDialog from "./IntroductionFeeBillingRequestDialog";
import {
  CallOnClose,
  formatBusinessNumberFromBusinessInfo,
  formatBusinessNumberFromSummary,
  formatEstimateNumber,
  formatVoucherNumber,
  InputPending,
  onCloseWithSave,
  useIsValidVcouherEditAuthority,
} from "Common/Utility/AppUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import { VoucherEditAuth } from "Common/Utility/Role";
import { Design, EstimateDetailType, VoucherStatusComboItems } from "Common/Utility/Constants";
import { IntroductionFeeBillingRequest } from "Models/IntroductionFeeBillingRequest";
import { EstimateDetail, EstimateSummary } from "Models/Estimate";
import MultilineTextField from "Component/MultilineTextField";
import { BusinessInfo } from "Models/BusinessInfo";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { Company } from "Models/Company";

const customerColumns: ColumnData[] = [
  {
    width: 300,
    label: "得意先名",
    dataKey: "text",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

const businessColumns: ColumnData[] = [
  {
    width: 200,
    label: "受注日時",
    dataKey: "createdAt",
    headerAlign: "center",
    bodyAlign: "center",
    convert: (data: any, rowData: BusinessInfo) => DateUtility.format(data, "yyyy/MM/dd HH:mm:ss"),
  },
  {
    width: 300,
    label: "受注番号",
    dataKey: "",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (_: any, rowData: BusinessInfo) => formatBusinessNumberFromBusinessInfo(rowData),
    fit: true,
  },
];

const estimateColumns: ColumnData[] = [
  {
    width: 200,
    label: "見積番号",
    dataKey: "",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (_: any, rowData: EstimateSummary) =>
      formatEstimateNumber(rowData.issueYear, rowData.sequence, rowData.version),
  },
  {
    width: 150,
    label: "見積作成日",
    dataKey: "estimateDate",
    headerAlign: "center",
    bodyAlign: "center",
    convert: (estimateDate: DateTime) => DateUtility.format(estimateDate),
    fit: true,
  },
];

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
  selectCustomer: {
    width: 300,
    height: Design.componentUnitHeight,
    margin: theme.spacing(1),
  },
  date: {
    width: 120,
    margin: theme.spacing(1),
  },
  businessNameLoading: {
    width: 316,
    height: 64,
  },
  dateLoading: {
    width: `calc(240px + ${theme.spacing(1) * 4}px)`,
    height: 64,
  },
  detailArea: {
    width: "100%",
    height: 440,
  },
  introductionArea: {
    width: "100%",
    height: 150,
  },
  selectBusiness: {
    width: 300,
    margin: theme.spacing(1),
  },
  selectStatus: {
    width: 150,
  },
  total: {
    width: 150,
  },
  text: {
    textAlign: "right",
  },
  note: {
    width: "100%",
  },
  loading: {
    height: 544,
  },
}));

interface Notice {
  requestApproval: boolean;
  noticeApproved: boolean;
}

interface VoucherDialogProps extends InputPending {
  open: boolean;
  summary: VoucherSummary;
  details: VoucherDetail[];
  editAuth: boolean;
  notice?: Notice;
}

const VoucherDialog = (props: VoucherDialogProps) => {
  const classes = useStyles();
  const genericClasses = useGenericStyles();
  const alertAdd = useAlertAdd();
  const message = useMessageBox();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  const [customers, setCustomers] = useState<ComboItemCustomerEx[]>([]);
  const taxMaster = AppProvider.useGlobalState("taxMaster");
  const [businessList, setBusinessList] = useState<BusinessInfo[]>([]);
  const [estimates, setEstimates] = useState<EstimateSummary[]>([]);

  const [summary, setSummary] = useState<VoucherSummary>({ ...props.summary });
  const [details, setDetails] = useState<VoucherDetail[]>([...props.details]);
  const [notice, setNotice] = useState<Notice>(props.notice ?? { requestApproval: false, noticeApproved: false });

  const [editingDetail, setEditingDetail] = useState<VoucherDetail>({} as VoucherDetail);
  const [introductions, setIntroductions] = useState<IntroductionFeeBillingRequest[]>([]);
  const [introduction, setIntroduction] = useState<IntroductionFeeBillingRequest | undefined>(undefined);
  const [estimateId, setEstimateId] = useState<string | undefined>(undefined);

  const [edited, confirm] = useWhetherEdited(props);

  const inputManagerSummary = useInputManager(setSummary, edited);
  const inputManagerEditingDetail = useInputManager(setEditingDetail);

  const [estimateFilteringCustomerId, setEstimateFilteringCustomerId] = useState<string | undefined>(undefined);

  const [editing, setEditing] = useState<InputState>(InputState.None);

  const closingDate = useMemo(() => {
    if (summary.voucherDate == null || summary.customerClosingDay == null) {
      return null;
    }
    return DateUtility.closingDate(summary.customerClosingDay, summary.voucherDate);
  }, [summary.customerClosingDay, summary.voucherDate]);

  const depositDate = useMemo(() => {
    if (closingDate == null || summary.customerDepositDay == null || summary.customerDepositCycle == null) {
      return null;
    }
    return DateUtility.depositDate(summary.customerDepositDay, summary.customerDepositCycle, closingDate);
  }, [summary.customerDepositDay, summary.customerDepositCycle, closingDate]);

  const calcTotal = useMemo(() => {
    if (summary === undefined) {
      return "0";
    }

    let total = 0;
    let taxExemption = 0;
    details.forEach((value) => {
      if (value.taxExemption) {
        taxExemption += value.price ?? 0;
      } else {
        total += value.price ?? 0;
      }
    });

    return (total + taxExemption + calcTax(total, summary.voucherDate, taxMaster)).toLocaleString();
  }, [details, summary, taxMaster]);

  const [executePutVoucher, inProcessPutVoucher] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: VoucherSummary;
          details: VoucherDetail[];
          notice: { requestApproval: boolean; noticeApproved: boolean };
          onClose: CallOnClose;
        }
      ) => {
        const response = await callWebApi().put<VoucherSummary>("/voucher", {
          VoucherSummary: object.summary,
          VoucherDetails: object.details,
          ...object.notice,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "伝票を保存しました。" });

        if (object.notice.requestApproval) {
          alertAdd({ type: "success", message: "承認依頼を通知しました。" });
        }
        if (object.notice.noticeApproved) {
          alertAdd({ type: "success", message: "担当者に承認を通知しました。" });
        }

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data, response.data.businessId ?? undefined));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "伝票",
    <VoucherDialog
      open={true}
      onClose={props.onClose}
      summary={summary}
      details={details}
      editAuth={true}
      notice={notice}
    />,
    props.onClose,
    props.onHold
  );

  // 保存ボタン
  const handleOnClickSaveInAction = useCallback(() => {
    if (!summary.voucherDate) {
      alertAdd({ type: "info", message: "伝票日付は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(summary.voucherDate)) {
      alertAdd({ type: "info", message: "伝票日付が不正です。" });
      return;
    }

    if (!summary.customerId) {
      alertAdd({ type: "info", message: "得意先は必須項目です。" });
      return;
    }

    let total = 0;
    let billingAmount = 0;
    details.forEach((value) => {
      if (!value.taxExemption) {
        total += value.price ?? 0;
      }
      billingAmount += value.price ?? 0;
    });

    summary.voucherDate = DateUtility.ignoreTime(summary.voucherDate);
    summary.billingAmount = billingAmount;
    summary.tax = calcTax(total, summary.voucherDate, taxMaster);
    summary.totalAmount = summary.billingAmount + summary.tax;
    summary.closingDate = closingDate;
    summary.depositDate = depositDate;

    executePutVoucher({ summary: summary, details: details, notice: notice, onClose: props.onClose });
  }, [summary, alertAdd, props.onClose, taxMaster, details, notice, executePutVoucher, closingDate, depositDate]);

  const handleOnClickAssignStaff = useCallback(async () => {
    if (!(await message.confirm("担当設定確認", "伝票の担当者に設定しますか？"))) {
      return;
    }

    const notice = await message.confirm("伝票の承認依頼確認", "伝票の承認を依頼しますか？\n※保存後に通知します。");
    setNotice((value) => {
      return { ...value, requestApproval: notice };
    });

    setSummary((value) => {
      return {
        ...value,
        staffUserId: ralmAccount?.userGuid ?? null,
        staffUserName: ralmAccount?.userName ?? null,
        staffAssignDate: DateUtility.now(),
      };
    });

    edited();
  }, [edited, message, ralmAccount]);

  const handleOnClickApprove = useCallback(async () => {
    if (!(await message.confirm("承認確認", "伝票を承認しますか？"))) {
      return;
    }

    setSummary((value) => {
      return {
        ...value,
        approvalUserId: ralmAccount?.userGuid ?? null,
        approvalUserName: ralmAccount?.userName ?? null,
        approvalDate: DateUtility.now(),
      };
    });

    const notice = await message.confirm("承認の通知確認", "伝票の担当者に承認を通知しますか？\n※保存後に通知します。");
    setNotice((value) => {
      return { ...value, noticeApproved: notice };
    });

    edited();
  }, [edited, message, ralmAccount]);

  const handleOnClickCreateVoucherDetailsFromEstimate = async () => {
    if (summary.customerId) {
      setEstimateFilteringCustomerId(summary.customerId);
    } else {
      await message.info("見積選択", "見積を絞り込むため、得意先を指定してください。");
    }
  };

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (estimateId == null) {
          return;
        }

        const response = await callWebApi().get<EstimateDetail[]>("/estimates/details", {
          params: { estimateId: estimateId },
        });

        alertAdd({ type: "success", message: "見積の明細を取り込みました。" });

        const estimateDetails = response.data;
        const appendVoucherDetails: VoucherDetail[] = [];
        for (let i = 0; i < estimateDetails.length; i++) {
          const estimateDetail = estimateDetails[i];
          // 基本料が設定されている明細は別明細にして取り込む
          if (estimateDetail.basicPrice !== null) {
            appendVoucherDetails.push({
              name: estimateDetail.name !== null ? `${estimateDetail.name} 基本料` : null,
              price: estimateDetail.basicPrice,
              quantity: estimateDetail.count,
              unitPrice: null,
              unit: estimateDetail.unit,
              taxExemption: estimateDetail.taxExemption,
              summary: estimateDetail.summary,
            });
          }
          appendVoucherDetails.push({
            name: estimateDetail.name,
            price: (estimateDetail.price ?? 0) * (estimateDetail.type === EstimateDetailType.Discount ? -1 : 1),
            quantity: estimateDetail.count,
            unitPrice: estimateDetail.unitPrice,
            unit: estimateDetail.unit,
            taxExemption: estimateDetail.taxExemption,
            summary: estimateDetail.summary,
          });
        }
        setDetails((details) => {
          return [...details, ...appendVoucherDetails];
        });
        edited();
        setEstimateId(undefined);
      },
      [estimateId, alertAdd, edited]
    ),
    false
  );

  const handleOnCloseEstimate = useCallback((estimate: EstimateSummary | null) => {
    setEstimateFilteringCustomerId(undefined);
    if (estimate === null || !estimate.id) {
      return;
    }
    setEstimateId(estimate.id);
  }, []);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.shouldInitialize || props.summary.id == null) {
            return;
          }

          const response = await callWebApi().get<VoucherDetail[]>("/voucher/details", {
            cancelToken: signal.token,
            params: { voucherId: props.summary.id },
          });
          setDetails(response.data);
        },
        [props.summary.id, props.shouldInitialize]
      ),
      props.shouldInitialize
    ),
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (props.summary.id == null) {
            return;
          }

          const responseIntroductions = await callWebApi().get<IntroductionFeeBillingRequest[]>(
            `/IntroductionFeeBillingRequests/list/${props.summary.id}`,
            { cancelToken: signal.token }
          );
          setIntroductions(responseIntroductions.data);
        },
        [props.summary.id]
      )
    ),
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (props.summary.customerId == null || props.summary.customerClosingDay != null) {
            return;
          }

          const response = await callWebApi().get<Company>(`/companies/${props.summary.customerId}`, {
            cancelToken: signal.token,
          });
          setSummary((value) => {
            return {
              ...value,
              customerClosingDay: response.data.customerClosingDay,
              customerDepositCycle: response.data.customerDepositCycle,
              customerDepositDay: response.data.customerDepositDay,
            };
          });
        },
        [props.summary.customerId, props.summary.customerClosingDay]
      )
    )
  );

  // 得意先の読み込み
  const fetchCustomers = useLoadingElement(
    classes.selectCustomer,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItemCustomerEx[]>("/companies/customer", {
          cancelToken: signal.token,
        });
        setCustomers(response.data);
      }, [])
    )
  );

  // customerIdに対応した案件の一覧の読み込み
  const loadingBusinessList = useLoadingElement(
    classes.businessNameLoading,
    LoadingMode.Simple,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (summary.customerId == null || summary.customerId === "") {
            return;
          }

          const response = await callWebApi().get<BusinessInfo[]>(`/business/customer/${summary.customerId}`, {
            cancelToken: signal.token,
          });

          setBusinessList(response.data);
        },
        [summary.customerId]
      )
    )
  );

  // 取り込む見積の読み込み
  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get("/estimates/customer", {
          cancelToken: signal.token,
          params: {
            customerId: estimateFilteringCustomerId,
          },
        });

        if (response.data == null) {
          return;
        }

        setEstimates(response.data);
      },
      [estimateFilteringCustomerId]
    ),
    false
  );

  // 明細の追加
  const handleOnClickAdd = useCallback(() => {
    const detail: VoucherDetail = {
      name: null,
      price: null,
      quantity: null,
      unitPrice: null,
      unit: null,
      taxExemption: undefined,
      summary: null,
    };
    setDetails((details) => [...details, detail]);
    setEditingDetail(detail);
  }, []);

  // 明細の編集
  const handleOnClickEdit = useCallback(
    (detail: VoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditingDetail(detail);
    },
    []
  );

  // 明細の削除
  const handleOnClickDelete = useCallback(
    (detail: VoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((details) => {
        details.splice(rowIndex, 1);
        return [...details];
      });

      edited();
    },
    [edited]
  );

  // 明細の編集キャンセル
  const handleOnClickCancel = useCallback(
    (data: VoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (editing === InputState.Adding) {
        setDetails((details) => {
          details.pop();
          return [...details];
        });
      }
    },
    [editing]
  );

  // 明細の編集確定
  const handleOnClickSave = useCallback(
    (detail: VoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((details) => {
        details[rowIndex] = editingDetail;
        return [...details];
      });

      edited();
    },
    [editingDetail, edited]
  );

  // 明細を1行上に移動
  const handleOnClickUp = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex === 0) {
        return;
      }

      setDetails((details) => [...swap(details, rowIndex, rowIndex - 1)]);

      edited();
    },
    [edited]
  );

  // 明細を1行下に移動
  const handleOnClickDown = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex >= details.length - 1) {
        return;
      }

      setDetails((details) => [...swap(details, rowIndex, rowIndex + 1)]);

      edited();
    },
    [details.length, edited]
  );

  const handleOnClickNarrowDownCustomer = useNarrowDown(customers, "text");

  const handleOnClickNarrowDownBusiness = useCallback(
    (text: string) => {
      const keywords = validateNarrowDownKey(text);

      if (keywords.length === 0) {
        return businessList;
      }

      return businessList.filter(
        (businessInfo) =>
          keywords.findIndex((keyword) => {
            return (
              businessInfo.createdAt.indexOf(keyword) !== -1 ||
              formatBusinessNumberFromBusinessInfo(businessInfo).indexOf(keyword) !== -1
            );
          }) !== -1
      );
    },
    [businessList]
  );

  const handleOnClickNarrowDownEstimates = useCallback(
    (text: string) => {
      const keywords = validateNarrowDownKey(text);

      if (keywords.length === 0) {
        return estimates;
      }

      return estimates.filter(
        (estimate) =>
          keywords.findIndex((keyword) => {
            return (
              DateUtility.format(estimate.estimateDate).indexOf(keyword) !== -1 ||
              formatEstimateNumber(estimate.issueYear, estimate.sequence, estimate.version).indexOf(keyword) !== -1
            );
          }) !== -1
      );
    },
    [estimates]
  );

  const handleOnPreClickBusinessNo = useCallback(async () => {
    if (!summary.customerId) {
      await message.info("受注選択", "受注を絞り込むため、得意先を指定してください。");
      return false;
    }
    return true;
  }, [message, summary.customerId]);

  const handleOnChangeNumber = useCallback(
    (target: keyof VoucherDetail) => {
      return (event: ChangeEvent<HTMLAreaElement | any>) => {
        let newValue: number | null = null;
        if (event.target.value) {
          newValue = Number(event.target.value);
          if (isNaN(newValue)) {
            newValue = null;
          }
        }

        setEditingDetail((value) => {
          if (target === "quantity" && newValue != null && value.unitPrice != null) {
            return { ...value, [target]: newValue, price: newValue * value.unitPrice };
          } else if (target === "unitPrice" && newValue != null && value.quantity != null) {
            return { ...value, [target]: newValue, price: value.quantity * newValue };
          } else {
            return { ...value, [target]: newValue };
          }
        });

        edited?.();
      };
    },
    [edited]
  );

  const columns: ColumnData[] = useMemo(() => {
    let ret = [] as ColumnData[];
    if (props.editAuth) {
      ret = [
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        },
        {
          width: 128,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
            return (
              <>
                <IconButton
                  color="primary"
                  aria-label="up"
                  onClick={handleOnClickUp(rowIndex)}
                  disabled={editing !== InputState.None}
                >
                  <ArrowUpward />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="down"
                  onClick={handleOnClickDown(rowIndex)}
                  disabled={editing !== InputState.None}
                >
                  <ArrowDownward />
                </IconButton>
              </>
            );
          },
        },
      ];
    }

    return ret.concat([
      {
        width: 350,
        label: "名目",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail!.name}
              onChange={inputManagerEditingDetail.handleOnChange("name")}
            />
          );
        },
      },
      {
        width: 100,
        label: "数量",
        dataKey: "quantity",
        headerAlign: "center",
        bodyAlign: "right",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return <DigitsField value={editingDetail!.quantity} onChange={handleOnChangeNumber("quantity")} />;
        },
      },
      {
        width: 100,
        label: "単位",
        dataKey: "unit",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail!.unit}
              onChange={inputManagerEditingDetail.handleOnChange("unit")}
            />
          );
        },
      },
      {
        width: 150,
        label: "単価",
        dataKey: "unitPrice",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? `${data.toLocaleString()} 円` : ""),
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField value={editingDetail!.unitPrice} onChange={handleOnChangeNumber("unitPrice")} allowMinus />
          );
        },
      },
      {
        width: 150,
        label: "金額",
        dataKey: "price",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? `${data.toLocaleString()} 円` : ""),
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              value={editingDetail!.price}
              onChange={inputManagerEditingDetail.handleOnChangeNumber("price")}
              allowMinus
            />
          );
        },
      },
      {
        width: 110,
        label: "非課税",
        dataKey: "taxExemption",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: VoucherDetail) => {
          return (
            <Box className={genericClasses.width100percent}>
              <Checkbox color="primary" checked={data.taxExemption ?? false} />
            </Box>
          );
        },
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <Box className={genericClasses.width100percent}>
              <Checkbox
                color="primary"
                checked={editingDetail!.taxExemption ?? false}
                onChange={inputManagerEditingDetail.handleOnChangeCheck("taxExemption")}
              />
            </Box>
          );
        },
      },
      {
        width: 250,
        label: "摘要",
        dataKey: "summary",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail!.summary}
              onChange={inputManagerEditingDetail.handleOnChange("summary")}
            />
          );
        },
      },
    ]);
  }, [
    props.editAuth,
    handleOnClickUp,
    handleOnClickDown,
    inputManagerEditingDetail,
    editingDetail,
    editing,
    genericClasses.width100percent,
    handleOnChangeNumber,
  ]);

  const handleOnClickAddIntroduction = useCallback(async () => {
    if (summary.id === null) {
      await message.info("請求依頼作成", "伝票を保存してから依頼を作成してください。");
      return;
    }

    setIntroduction({
      id: null,
      voucherId: summary.id,
      vendorName: summary.customerName,
      fee: 0,
      note: "",
      status: null,
    } as IntroductionFeeBillingRequest);
  }, [message, summary.customerName, summary.id]);

  const handleOnClickReferenceIntroduction = useCallback(
    (introduction: IntroductionFeeBillingRequest, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setIntroduction(introduction);
    },
    []
  );

  const introductionColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
      },
      {
        width: 150,
        label: "依頼日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (createdAt: DateTime) => DateUtility.format(createdAt),
      },
      {
        width: 300,
        label: "依頼先",
        dataKey: "toName",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 150,
        label: "紹介料",
        dataKey: "fee",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (fee: number) => fee.toLocaleString() + " 円",
      },
      {
        width: 150,
        label: "メモ",
        dataKey: "note",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const handleOnCloseIntro = useCallback((introduction: IntroductionFeeBillingRequest | null) => {
    if (introduction) {
      setIntroductions((introductions) => {
        return [...introductions, introduction];
      });
    }

    setIntroduction(undefined);
  }, []);

  const canApproval = useIsValidVcouherEditAuthority(VoucherEditAuth.approval);
  const isAssigned = summary.staffUserId;
  const isApproved = summary.approvalUserId;
  const isAssignable = !isApproved;
  const isApprovable = isAssigned && !isApproved;

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="xl">
      <DialogTitle>伝票情報</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <Label
                className={classes.date}
                caption="伝票番号"
                text={formatVoucherNumber(summary.issueYear, summary.sequence)}
                disabled={!props.editAuth}
                underLine
              />
            </Grid>
            <Grid item>
              <DatePickersUtilsProvider>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  label="伝票日付"
                  autoOk
                  value={summary.voucherDate}
                  onChange={inputManagerSummary.handleOnChangeDate("voucherDate", (date?: string) => {})}
                  disabled={!props.editAuth}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </DatePickersUtilsProvider>
            </Grid>
            <Grid item>
              {fetchCustomers ?? (
                <LabelWithSelect
                  className={classes.selectCustomer}
                  caption="得意先"
                  text={summary.customerName}
                  data={customers}
                  columns={customerColumns}
                  onClickNarrowDown={handleOnClickNarrowDownCustomer}
                  onSelected={inputManagerSummary.handleOnChangeLabelWithSelect((value, result) => {
                    return {
                      ...value,
                      customerId: result.value,
                      customerName: result.text,
                      customerClosingDay: result.customerClosingDay,
                      customerDepositCycle: result.customerDepositCycle,
                      customerDepositDay: result.customerDepositDay,
                      businessId: null,
                      businessSequence: null,
                      businessCreatedAt: null,
                    };
                  })}
                  maxWidth="sm"
                  disabled={summary.id != null || summary.businessId != null || !props.editAuth}
                  underLine={true}
                />
              )}
            </Grid>
            <Grid item>
              {loadingBusinessList ?? (
                <LabelWithSelect
                  className={classes.selectBusiness}
                  caption="受注番号"
                  text={formatBusinessNumberFromSummary(summary)}
                  data={businessList}
                  columns={businessColumns}
                  onClickNarrowDown={handleOnClickNarrowDownBusiness}
                  onPreClick={handleOnPreClickBusinessNo}
                  onSelected={inputManagerSummary.handleOnChangeLabelWithSelect((value, result: BusinessInfo) => {
                    return {
                      ...value,
                      businessId: result.id,
                      businessSequence: result.sequence,
                      businessCreatedAt: result.createdAt,
                    };
                  })}
                  maxWidth="sm"
                  disabled={!props.editAuth}
                  underLine={true}
                />
              )}
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                <Label
                  className={classes.date}
                  caption="請求締日"
                  text={closingDate}
                  underLine={true}
                  disabled={!props.editAuth}
                />
                <Label
                  className={classes.date}
                  caption="入金予定日"
                  text={depositDate}
                  underLine={true}
                  disabled={!props.editAuth}
                />
              </Grid>
            </Grid>
            <Grid item>
              <FormControl className={genericClasses.margin} disabled={!props.editAuth}>
                <InputLabel id="voucher-status-select-label">伝票ステータス</InputLabel>
                <Select
                  labelId="voucher-status-select-label"
                  id="voucher-status-select"
                  value={summary.status}
                  onChange={inputManagerSummary.handleOnChangeSelect("status")}
                  className={classes.selectStatus}
                >
                  {VoucherStatusComboItems.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickCreateVoucherDetailsFromEstimate}
                color="primary"
                disabled={!props.editAuth || editing !== InputState.None}
              >
                見積の明細を取り込む
              </Button>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    {isAssigned && (
                      <Label className={genericClasses.margin} caption="担当者" text={summary.staffUserName ?? ""} />
                    )}
                    {isApproved && (
                      <Label className={genericClasses.margin} caption="承認者" text={summary.approvalUserName ?? ""} />
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  {isAssignable && (
                    <Button
                      className={genericClasses.margin}
                      onClick={handleOnClickAssignStaff}
                      color="primary"
                      disabled={!props.editAuth}
                    >
                      担当印
                    </Button>
                  )}
                  {isApprovable && (
                    <Button
                      className={genericClasses.margin}
                      onClick={handleOnClickApprove}
                      color="primary"
                      disabled={!props.editAuth || !canApproval}
                    >
                      承認印
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.detailArea}>
              {loadingElement ?? (
                <VirtualaizedTable
                  values={details}
                  rowHeight={48}
                  columns={columns}
                  onClickAddDirectInput={props.editAuth ? handleOnClickAdd : undefined}
                  onClickEdit={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                  onClickCancel={handleOnClickCancel}
                  onClickSave={handleOnClickSave}
                  setEditing={setEditing}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
              <Label
                className={classes.total}
                textClassName={classes.text}
                caption="総額(税込み)"
                text={calcTotal}
                suffix=" 円"
                underLine
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MultilineTextField
              id="note"
              className={classes.note}
              label="備考"
              variant="outlined"
              maxLine={2}
              value={summary.note}
              onChange={inputManagerSummary.handleOnChange("note")}
              disabled={!props.editAuth}
            />
          </Grid>
          <Grid item xs={12}>
            {summary.id != null &&
              (loadingElement ?? (
                <Paper className={classes.introductionArea}>
                  <VirtualaizedTable
                    values={introductions}
                    rowHeight={48}
                    columns={introductionColumns}
                    onClickAdd={props.editAuth ? handleOnClickAddIntroduction : undefined}
                    onClickReference={handleOnClickReferenceIntroduction}
                  />
                </Paper>
              ))}
          </Grid>
        </Grid>
        {introduction && (
          <IntroductionFeeBillingRequestDialog
            open={true}
            voucherId={summary.id ?? ""}
            introductionFeeBillingRequest={introduction}
            disabled={introduction.id !== null}
            isPrevVoucher={true}
            onClose={handleOnCloseIntro}
          />
        )}
        <SelectDialog
          open={estimateFilteringCustomerId != null}
          maxWidth={"xs"}
          title={"見積"}
          onClose={handleOnCloseEstimate}
          data={estimates}
          columns={estimateColumns}
          onClickNarrowDown={handleOnClickNarrowDownEstimates}
        />
      </DialogContent>
      <DialogActions>
        {!props.editAuth ? (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || editing !== InputState.None || inProcessPutVoucher}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || editing !== InputState.None || inProcessPutVoucher}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(VoucherDialog);
