import { DateTime } from "Common/Utility/DateUtility";
import { EstimateDetailType, RentalPayOff } from "Common/Utility/Constants";

export interface EstimateSummary {
  id: string | null;

  // 発行年
  issueYear: number | null;

  // 連番
  sequence: number | null;

  // 版数
  version: number | null;

  // 最新版数
  latestVersion: number | null;

  // 得意先ID
  customerId: string | null;

  // 得意先名
  customerName: string | null;

  // 得意先締日
  customerClosingDay: number | null;

  constructionId: string | null;
  constructionName: string | null;

  staffUserId: string | null;
  staffUserName: string | null;

  approvalUserId: string | null;
  approvalUserName: string | null;

  estimateDate: DateTime | null;

  expirationDate: DateTime | null;

  approvalDate: DateTime | null;

  note: string | null;

  updatedAt: DateTime;
}

export function isApprovedEstimate(estimate: EstimateSummary): boolean {
  return estimate.approvalUserId !== null;
}

export function isEditableEstimate(estimate: EstimateSummary): boolean {
  // 最新版かつ未承認の見積が編集可能
  const isLatestVersion = estimate.version === null || estimate.version === estimate.latestVersion;
  const isApproved = isApprovedEstimate(estimate);
  return isLatestVersion && !isApproved;
}

export interface EstimateDetail {
  /** 明細種別 */
  type: EstimateDetailType;

  /** 名称 */
  name: string | null;

  /** 摘要 */
  summary: string | null;

  /** 数量 */
  count: number | null;

  /** 単位 */
  unit: string | null;

  /** 基本料 */
  basicPrice: number | null;

  /** 単価 */
  unitPrice: number | null;

  // 日極日数
  numberOfDaily: number | null;

  /** 日割単価 */
  dailyUnitPrice: number | null;

  /** 月額単価 */
  monthlyUnitPrice: number | null;

  /** 金額 */
  price: number | null;

  /** 非課税 */
  taxExemption: boolean;

  /** 原価 */
  cost: number | null;

  /** レンタル種別(レンタルのみ) */
  rentalPayOff: RentalPayOff | null;

  /** 期間(レンタルのみ) */
  rentalDateFrom: DateTime | null;
  rentalDateTo: DateTime | null;
}
