import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { Condition } from "Component/Condition";
import { callWebApi } from "Common/Utility/Api";
import { useGenericStyles } from "Common/Utility/Styles";
import { CancelTokenSource } from "axios";
import { useFetch } from "Hooks/useFetch";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useContentHeight } from "Hooks/useResize";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import { Align, VerticalAlign } from "Common/Utility/Drawing";
import AssetSchedule from "Component/Schedule/AssetSchedule";
import { AssetMaster, numberOfAsset } from "Models/Asset";
import { Design } from "Common/Utility/Constants";
import TextField from "Component/TextField";
import { ColumnInfo } from "Component/Canvas";
import { EditAssetMasterDialog } from "./EditAssetMasterDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { modifyLendingPeriod } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: Design.scheduleMinHeight,
  },
  datePicker: {
    width: 150,
  },
}));

interface AssetCondition {
  manualId: string | null;
  model: string | null;
}

export const AssetPanel = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<AssetCondition>({
    manualId: "",
    model: "",
  } as AssetCondition);

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<AssetCondition>({
    manualId: "",
    model: "",
  } as AssetCondition);

  const [rows, setRows] = useState<AssetMaster[]>([]);

  const [asset, setAsset] = useState<AssetMaster | undefined>(undefined);

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<AssetMaster[]>("/assetmaster", {
          cancelToken: signal.token,
          params: { ...search },
        });

        modifyLendingPeriod(response.data);
        setRows(response.data);
      },
      [search]
    ),
    false
  );

  const handleOnClickAdd = useCallback(() => {
    setAsset({
      category: 0,
      assetGroupName: "未選択",
      assetNumber: null,
      lendingPeriods: [],
      stockHistories: [],
      indexRuleId: "",
    } as AssetMaster);
  }, []);

  const handleOnClickEdit = useCallback((asset: AssetMaster) => {
    if (asset == null) {
      return;
    }
    setAsset(asset);
  }, []);

  const handleOnClose = useOnCloseEditDialog("Asset", setAsset, setRows, "id");

  const columns: ColumnInfo<AssetMaster>[] = useMemo(
    () => [
      {
        width: 200,
        text: "資産番号",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: AssetMaster) => data.assetNumber,
        dataKey: "assetNumber",
      },
      {
        width: 200,
        text: "型式",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: AssetMaster) => data.model,
        dataKey: "model",
      },
      {
        width: 200,
        text: "名称",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: AssetMaster) => data.name,
        dataKey: "name",
      },
      {
        width: 80,
        text: "資産数",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.right,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: AssetMaster) => numberOfAsset(data).toString(),
      },
    ],
    []
  );

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <TextField
                className={genericClasses.margin}
                label="資産番号"
                value={condition.manualId}
                onChange={inputManager.handleOnChange("manualId")}
              />
              <TextField
                className={genericClasses.margin}
                label="型式"
                value={condition.model}
                onChange={inputManager.handleOnChange("model")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() =>
                  setCondition({
                    manualId: "",
                    model: "",
                    saleFrom: null,
                    saleTo: null,
                    discardFrom: null,
                    discardTo: null,
                    isSaledInclude: false,
                    isDiscardedInclude: false,
                  } as AssetCondition)
                }
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          <AssetSchedule
            className={classes.paper}
            rows={rows}
            setRows={setRows}
            columns={columns}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickAdd={handleOnClickAdd}
            isValidEdit={useIsValidMenuEditAuthority(MenuIndex.Asset)}
          />
        </Grid>
        {asset != null && <EditAssetMasterDialog open={true} asset={asset} onClose={handleOnClose} />}
      </Grid>
    </>
  );
});
