import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import { Shipment, ShipmentDetail } from "Models/Shipment";
import React, { useCallback, useRef, useState, useMemo } from "react";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { ComboItem } from "Common/Utility/GenericInterface";
import { AxiosResponse, CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { Design, modelColumns, simpleColumnData, toComboText } from "Common/Utility/Constants";
import { ModelMaster } from "Models/ModelMaster";
import { AssetGroup } from "Models/AssetGroup";
import ShipmentSchedule from "Component/Schedule/ShipmentSchedule";
import { ButtonForSelect } from "Component/ButtonForSelect";
import { LineupSummary } from "Models/Lineup";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useResizeObserver } from "Hooks/useResize";
import { CallOnClose, InputPending } from "Common/Utility/AppUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { DateUtility } from "Common/Utility/DateUtility";
import { modifyLendingPeriod } from "Common/Utility/DateUtility";
import { checkLendingPeriod } from "./Shipment";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
  loading: {
    height: 700,
  },
  loadCustomers: {
    width: 300,
    height: 48,
  },
  customers: {
    width: 300,
    margin: theme.spacing(1),
  },
  schedule: {
    width: "100%",
    height: (props: any) =>
      props.height - (Design.dialogTopHeight + Design.dialogBottomHeight + Design.unknownGap + 64 * 2 + 52.5),
    minHeight: Design.scheduleMinHeight,
  },
  postalCode: {
    width: 100,
    margin: theme.spacing(1),
  },
  address: {
    width: 600,
    margin: theme.spacing(1),
  },
  company: {
    width: 500,
    margin: theme.spacing(1),
  },
  department: {
    width: 400,
    margin: theme.spacing(1),
  },
}));

export const EditShipmentMode = {
  Edit: 0,
  Reference: 1,
  Change: 2,
} as const;
export type EditShipmentMode = typeof EditShipmentMode[keyof typeof EditShipmentMode];

interface Props extends InputPending {
  open: boolean;

  shipment: Shipment;

  editShipmentMode: EditShipmentMode;
}

const EditShipment = (props: Props) => {
  const [rect, resizeRef] = useResizeObserver();

  const classes = useStyles({ height: rect.height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(true);

  const customers = useRef<ComboItem[]>([]);

  const models = useRef<ModelMaster[]>([]);

  const assetGroups = useRef<AssetGroup[]>([]);

  const lineupSummaries = useRef<LineupSummary[]>([]);

  const [shipment, setShipment] = useState<Shipment>({ ...props.shipment });

  const [details, setDetails] = useState<ShipmentDetail[]>([...props.shipment.details]);

  const inputManager = useInputManager(setShipment, edited);

  const [assetGroup, setAssetGroup] = useState<AssetGroup | undefined>(undefined);

  const editAuth = useMemo(() => props.editShipmentMode === EditShipmentMode.Edit, [props.editShipmentMode]);

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (assetGroup === undefined) {
          return;
        }

        const response = await callWebApi().get<ShipmentDetail[]>(`/shipments/assetgroup/${assetGroup.id}`, {
          cancelToken: signal.token,
        });

        setDetails((value) => {
          const tmp = response.data.filter((i) => !value.some((detail) => detail.id === i.id));
          modifyLendingPeriod(tmp, shipment.id);
          return [...value, ...tmp];
        });
      },
      [assetGroup, shipment.id]
    ),
    false
  );

  const loadingCustomer = useLoadingElement(
    classes.loadCustomers,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/companies/customer", { cancelToken: signal.token });
        customers.current = response.data;
      }, [])
    )
  );

  const loadingButton = useLoadingElement(
    classes.loadCustomers,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ModelMaster[]>("/modelmaster", { cancelToken: signal.token });
        models.current = response.data;
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<LineupSummary[]>("/lineups/search", { cancelToken: signal.token });
        lineupSummaries.current = response.data;
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<AssetGroup[]>("/assetgroup", { cancelToken: signal.token });
        assetGroups.current = response.data;
      }, [])
    )
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          shipment: Shipment;
          details: ShipmentDetail[];
          onClose: CallOnClose;
          editShipmentMode: EditShipmentMode;
        }
      ) => {
        object.shipment.details = object.details;
        let response: AxiosResponse<Shipment>;
        if (object.editShipmentMode === EditShipmentMode.Edit) {
          response = await callWebApi().put<Shipment>("/shipments", object.shipment);
        } else if (object.editShipmentMode === EditShipmentMode.Change) {
          response = await callWebApi().put<Shipment>("/shipments/lendingperiods", object.shipment);
        } else {
          return;
        }

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "出荷情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose({
          onClose: <T,>(setValue: React.Dispatch<React.SetStateAction<T[]>>, key: keyof T) => {
            setValue((values: any[]) => {
              let result: any[];
              const responseData = response.data as any;

              if (response.data.sequence == null) {
                const index = values.findIndex((i) => i[key] === responseData[key]);
                if (index >= 0) {
                  values[index] = response.data;
                  result = [...values];
                } else {
                  result = [...values, response.data];
                }
              } else {
                result = [...values];

                const index = values.findIndex((i) => i[key] === responseData[key] && i.sequence == null);
                if (index >= 0) {
                  result[index].sequence = response.data.sequence;
                }

                const lastIndex = values.findLastIndex((i) => i[key] === responseData[key]);
                if (lastIndex >= 0) {
                  response.data.sequence = null;
                  result.splice(lastIndex + 1, 0, responseData);
                }
              }

              return result;
            });
          },
          key: response.data.businessId,
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "出荷",
    <EditShipment
      open={props.open}
      shipment={shipment}
      onClose={props.onClose}
      editShipmentMode={props.editShipmentMode}
    />,
    props.onClose,
    props.onHold,
    () => {
      shipment.details = [...details];
    }
  );

  const handleOnClickSaveInAction = useCallback(() => {
    if (!shipment.shipDate) {
      alertAdd({ type: "info", message: "出荷日は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(shipment.shipDate)) {
      alertAdd({ type: "info", message: "出荷日が不正です。" });
      return;
    }

    if (!shipment.companyId) {
      alertAdd({ type: "info", message: "資産管理先は必須項目です。" });
      return;
    }

    if (details.length === 0) {
      alertAdd({ type: "info", message: "出荷資産が設定されていません。" });
      return;
    }

    if (details.findIndex((i) => i.id == null) >= 0) {
      alertAdd({ type: "info", message: "資産が選択されていない行があります。" });
      return;
    }

    if (
      details.findIndex(
        (i) =>
          i.numberOfSales === 0 &&
          i.lendingPeriods.findIndex((j) => j.id == null || j.shipmentId === shipment.id) === -1
      ) >= 0
    ) {
      alertAdd({ type: "info", message: "貸出期間又は販売数のいずれかは必須項目です。" });
      return;
    }

    const shipDate = new Date(shipment.shipDate);
    if (
      details.some((i) =>
        i.lendingPeriods.some((j) => (j.id == null || j.shipmentId === shipment.id) && new Date(j.from) < shipDate)
      )
    ) {
      alertAdd({ type: "info", message: "貸出期間が出荷日よりも前に設定されている行があります。" });
      return;
    }

    for (const i of details) {
      const lendingPeriod = i.lendingPeriods.find((value) => value.shipmentId === shipment.id);
      if (lendingPeriod == null || lendingPeriod.returnAssets.length === 0 || lendingPeriod.to == null) {
        continue;
      }
      // 資産番号の有無に関わらず1個でも帰庫されている場合はNG
      const returnedAt = new Date(lendingPeriod.returnAssets[lendingPeriod.returnAssets.length - 1].returnAt);
      if (new Date(lendingPeriod.to) < returnedAt) {
        alertAdd({ type: "info", message: "貸出の終了日が帰庫日よりも前に設定されている行があります。" });
        return;
      }
    }

    if (!checkLendingPeriod(shipment.id, details)) {
      alertAdd({ type: "info", message: "指定した貸出期間が他の貸出期間（未帰庫）と重複しています。" });
      return;
    }

    executePut({
      shipment: shipment,
      details: details,
      onClose: props.onClose,
      editShipmentMode: props.editShipmentMode,
    });
  }, [executePut, shipment, details, props.onClose, alertAdd, props.editShipmentMode]);

  const handleOnClickNarrowDown = useNarrowDown(customers.current, "text");

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.shouldInitialize || shipment.id == null) {
            return;
          }

          const response = await callWebApi().get<Shipment>(`/shipments/${shipment.id}`, {
            cancelToken: signal.token,
            params: { sequence: shipment.sequence },
          });

          if (response.data == null) {
            return;
          }

          modifyLendingPeriod(response.data.details, response.data.id);
          setShipment(response.data);
          setDetails(response.data.details);
        },
        [shipment.id, shipment.sequence, props.shouldInitialize]
      ),
      props.shouldInitialize
    )
  );

  return (
    <Dialog
      ref={resizeRef}
      onClose={() => confirm(() => props.onClose())}
      open={props.open}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>出荷情報</DialogTitle>
      {loadingElement ?? (
        <>
          <DialogContent>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <DatePickersUtilsProvider>
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    label="出荷日"
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    autoOk={true}
                    value={shipment.shipDate ?? null}
                    onChange={inputManager.handleOnChangeDate("shipDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={!editAuth}
                  />
                </DatePickersUtilsProvider>
                {loadingCustomer ?? (
                  <LabelWithSelect
                    className={classes.customers}
                    caption="出荷後の資産管理先"
                    text={toComboText(customers.current, shipment.companyId)}
                    data={customers.current}
                    columns={simpleColumnData("企業名", "text")}
                    onClickNarrowDown={handleOnClickNarrowDown}
                    onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                      return { ...value, companyId: result.value };
                    })}
                    maxWidth="sm"
                    underLine={true}
                    disabled={!editAuth}
                  />
                )}
                <TextField
                  className={classes.postalCode}
                  label="郵便番号"
                  value={shipment.postalCode}
                  onChange={inputManager.handleOnChange("postalCode")}
                  disabled={!editAuth}
                />
                <TextField
                  className={classes.address}
                  label="住所"
                  value={shipment.address}
                  onChange={inputManager.handleOnChange("address")}
                  disabled={!editAuth}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <TextField
                  className={classes.company}
                  label="会社名"
                  value={shipment.toCompanyName}
                  onChange={inputManager.handleOnChange("toCompanyName")}
                  disabled={!editAuth}
                />
                <TextField
                  className={classes.department}
                  label="部署名"
                  value={shipment.departmentName}
                  onChange={inputManager.handleOnChange("departmentName")}
                  disabled={!editAuth}
                />
                <TextField
                  className={genericClasses.margin}
                  label="宛名"
                  value={shipment.contactName}
                  onChange={inputManager.handleOnChange("contactName")}
                  disabled={!editAuth}
                />
                <TextField
                  className={genericClasses.margin}
                  label="TEL"
                  value={shipment.tel}
                  onChange={inputManager.handleOnChange("tel")}
                  disabled={!editAuth}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                {loadingButton ?? (
                  <>
                    <ButtonForSelect<ModelMaster>
                      buttonCaption="型式"
                      columns={modelColumns}
                      data={models.current}
                      filters={["model", "name"]}
                      onClose={(result: ModelMaster) => {
                        setDetails((value) => {
                          return [
                            ...value,
                            {
                              category: 0,
                              assetNumber: null,
                              modelMasterId: result.id,
                              name: result.name,
                              model: result.model,
                              lendingPeriods: [],
                              stockHistories: [],
                              numberOfSales: 0,
                              checked: false,
                            },
                          ];
                        });

                        edited();
                      }}
                      title="型式"
                      disabled={!editAuth}
                    />
                    <ButtonForSelect<LineupSummary>
                      buttonCaption="商品"
                      columns={simpleColumnData("商品名", "name")}
                      data={lineupSummaries.current}
                      filters={["name"]}
                      onClose={(result: LineupSummary) => {
                        setDetails((value) => {
                          const shipmentDetails: ShipmentDetail[] = [];
                          result.details.forEach((i) => {
                            shipmentDetails.push({
                              category: 0,
                              assetNumber: null,
                              modelMasterId: i.modelMasterId,
                              name: i.name,
                              model: i.model,
                              lendingPeriods: [],
                              stockHistories: [],
                              numberOfSales: 0,
                              checked: false,
                            });
                          });
                          return [...value, ...shipmentDetails];
                        });

                        edited();
                      }}
                      title="商品"
                      disabled={!editAuth}
                    />
                    <ButtonForSelect<AssetGroup>
                      buttonCaption="資産グループ"
                      columns={simpleColumnData("グループ名", "name")}
                      data={assetGroups.current}
                      filters={["name"]}
                      onClose={(result: AssetGroup) => {
                        setAssetGroup({ ...result });
                        edited();
                      }}
                      title="資産グループ"
                      disabled={!editAuth}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ShipmentSchedule
                className={classes.schedule}
                rows={details}
                setRows={setDetails}
                shipment={shipment}
                edited={edited}
                editShipmentMode={props.editShipmentMode}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            {props.editShipmentMode !== EditShipmentMode.Reference ? (
              <>
                <Button
                  className={genericClasses.margin}
                  onClick={handleOnClickHoldInAction}
                  color="primary"
                  disabled={inProcess}
                >
                  保留
                </Button>
                <Button
                  className={genericClasses.margin}
                  onClick={() => confirm(() => props.onClose())}
                  color="primary"
                >
                  キャンセル
                </Button>
                <Button
                  className={genericClasses.margin}
                  onClick={handleOnClickSaveInAction}
                  color="primary"
                  disabled={inProcess}
                >
                  保存
                </Button>
              </>
            ) : (
              <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
                閉じる
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default React.memo(EditShipment);
