import { Button, Grid, IconButton, makeStyles, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import Condition from "Component/Condition";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import Period from "Component/Period";
import { useExecute } from "Hooks/useFetch";
import { useContentHeight } from "Hooks/useResize";
import { Shipment, ShipmentDetail } from "Models/Shipment";
import React, { useState, useCallback, useMemo } from "react";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useIsValidMenuEditAuthority, OnClose, updateArrayState } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useMessageBox } from "Hooks/useMessageBox";
import { useAlertAdd } from "Common/Component/AlertList";
import { ShipmentStatus, ShipmentStatusComboItem, toComboText } from "Common/Utility/Constants";
import EditShipment, { EditShipmentMode } from "./EditShipment";
import Inspection from "./Inspection";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { Delete, Edit, Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
}));

interface Condition {
  from: DateTime | null;

  to: DateTime | null;

  isLatest: boolean;
}

const initialShipment: Shipment = {
  id: undefined,
  businessId: undefined,
  shipDate: undefined,
  companyId: undefined,
  companyName: "",
  status: 0,
  updatedAt: undefined,
  details: [] as ShipmentDetail[],
  sequence: null,
} as const;

const initialCondition: Condition = {
  from: DateUtility.nowDate(),
  to: DateUtility.nowDate(),
  isLatest: true,
};

export const ShipmentPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const [rows, setRows] = useState<Shipment[]>([]);

  const message = useMessageBox();

  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);

  const [shipmentForEdit, setShipmentForEdit] = useState<
    { shipment: Shipment; editShipmentMode: EditShipmentMode } | undefined
  >(undefined);

  const loadingElement = useLoadingElement(
    classes.searchLoading,
    LoadingMode.Circular,
    useSimpleFetch("/shipments", setRows, false, DateUtility.InvalidToNull(search, "from", "to"), [search])
  );

  const handleOnClickAdd = useCallback(() => {
    setShipmentForEdit({
      shipment: { ...initialShipment, businessId: undefined },
      editShipmentMode: EditShipmentMode.Edit,
    });
  }, []);

  const handleOnClickEdit = useCallback(
    (shipment: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return () => {
        setShipmentForEdit({ shipment: { ...shipment }, editShipmentMode: EditShipmentMode.Edit });
      };
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          id: string;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete(`/shipments/${object.id}`);

        alertAdd({ type: "success", message: "出荷情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setRows((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    (shipment: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return async () => {
        if (shipment.id != null) {
          if (await message.confirm("削除確認", "出荷情報を削除します。よろしいですか？")) {
            executeDelete({ id: shipment.id, rowIndex: rowIndex });
          }
        }
      };
    },
    [executeDelete, message]
  );

  const handleOnClickReference = useCallback(
    (shipment: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      return () => {
        setShipmentForEdit({ shipment: { ...shipment }, editShipmentMode: EditShipmentMode.Reference });
      };
    },
    []
  );

  const handleOnClose = useOnCloseEditDialog("Shipment", setShipmentForEdit, setRows, "id");

  const handleOnCloseEditShipment = useCallback(
    (args?: { onClose: OnClose; key?: string }) => {
      handleOnClose(args);
    },
    [handleOnClose]
  );

  const handleOnClickChangePeriod = useCallback(
    (data: Shipment) => async () => {
      if (await message.confirm("期間変更確認", "貸出期間を変更しますか？")) {
        setShipmentForEdit({ shipment: { ...data }, editShipmentMode: EditShipmentMode.Change });
      }
    },
    [message]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Shipment);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Shipment);

  const columns: ColumnData[] = useMemo(() => {
    let columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        bodyAlign: "center",
        widthType: "fix",
        rendererInCell: (data: Shipment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return isValidEdit && data.status === ShipmentStatus.Created ? (
            <IconButton color="primary" onClick={handleOnClickEdit(data, columnData, rowIndex, columnIndex)}>
              <Edit />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={handleOnClickReference(data, columnData, rowIndex, columnIndex)}>
              <Info />
            </IconButton>
          );
        },
      },
    ];

    if (isValidEdit) {
      columns.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        widthType: "fix",
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return data.status === ShipmentStatus.Created ? (
            <IconButton color="primary" onClick={handleOnClickDelete(data, columnData, rowIndex, columnIndex)}>
              <Delete />
            </IconButton>
          ) : (
            <></>
          );
        },
      });
    }

    columns.push(
      {
        width: 200,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 350,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(1),
      },
      {
        width: 100,
        label: "状況",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => toComboText(ShipmentStatusComboItem, data),
        visible: getVisible(2),
      },
      {
        width: 100,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        component: (data: Shipment, rowIndex: number) =>
          data.sequence == null ? (
            <Button variant="contained" color="primary" onClick={() => setShipment(data)}>
              検品
            </Button>
          ) : (
            <></>
          ),
      },
      {
        width: 140,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        component: (data: Shipment, rowIndex: number) =>
          isValidEdit && data.status === ShipmentStatus.Finished && data.sequence == null ? (
            <Button variant="contained" color="primary" onClick={handleOnClickChangePeriod(data)}>
              期間変更
            </Button>
          ) : (
            <></>
          ),
      }
    );
    return columns;
  }, [
    isValidEdit,
    getVisible,
    handleOnClickEdit,
    handleOnClickReference,
    handleOnClickDelete,
    handleOnClickChangePeriod,
  ]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Period
                label="出荷日"
                fromValue={condition.from}
                toValue={condition.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.isLatest}
                    onChange={inputManager.handleOnChangeCheck("isLatest")}
                    color="primary"
                  />
                }
                label="最新の出荷情報のみ表示"
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ ...initialCondition })}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={rows}
                setValues={setRows}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {shipmentForEdit != null && (
        <EditShipment open={true} {...shipmentForEdit} onClose={handleOnCloseEditShipment} shouldInitialize />
      )}
      {shipment != null && (
        <Inspection
          shipment={shipment}
          onClose={(shipment?: Shipment) => {
            updateArrayState(shipment, setRows, (i) => i.id === shipment?.id);
            setShipment(undefined);
          }}
        />
      )}
    </>
  );
};
