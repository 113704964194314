import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import Condition from "Component/Condition";
import { useContentHeight } from "Hooks/useResize";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useExecute, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { AssetGroup } from "Models/AssetGroup";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { deleteButton, editButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useAlertAdd } from "Common/Component/AlertList";
import TextField from "Component/TextField";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

const AssetGroupPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<AssetGroup>({ name: "" } as AssetGroup);

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<AssetGroup>({ name: "" } as AssetGroup);

  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);

  const [editing, setEditing] = useState<InputState>(InputState.None);

  const alertAdd = useAlertAdd();

  const [instant, setInstant] = useState<AssetGroup>({} as AssetGroup);

  const handleOnClickAdd = useCallback(() => {
    setAssetGroups((value) => {
      return [...value, {} as AssetGroup];
    });

    setInstant({ id: "new" } as AssetGroup);
  }, []);

  const handleOnChange = useCallback((target: keyof AssetGroup) => {
    return (event: ChangeEvent<HTMLAreaElement | any>) => {
      const newValue = event.target.value;
      setInstant((value) => {
        return { ...value, [target]: newValue };
      });
    };
  }, []);

  const handleOnClickEdit = useCallback(
    (record: AssetGroup, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setInstant({ ...record });
    },
    []
  );

  const handleOnClickCancel = useCallback(
    (record: AssetGroup, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (instant.id === "new") {
        setAssetGroups((value) => {
          value.pop();
          return [...value];
        });
      }
    },
    [instant.id]
  );

  const executeSave = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          id: string | null;
          name: string;
          rowIndex: number;
        }
      ) => {
        try {
          var response = await callWebApi().put<AssetGroup>("/assetGroup", { id: object.id, name: object.name });

          alertAdd({ type: "success", message: "資産グループを保存しました。" });

          if (unmounted.value) {
            return;
          }

          setAssetGroups((value) => {
            value[object.rowIndex] = response.data;
            return [...value];
          });
        } catch (error) {
          if (object.id == null) {
            setAssetGroups((value) => {
              value.pop();
              return [...value];
            });
          }
          throw error;
        }
      },
      [alertAdd]
    )
  );

  const handleOnClickSave = useCallback(
    (
      assetGroup: AssetGroup,
      columnData: ColumnData,
      rowIndex: number,
      columnIndex: number,
      args: { cancel: boolean }
    ) => {
      if (!instant.name) {
        args.cancel = true;
        alertAdd({ type: "info", message: "グループ名は必須項目です。" });
        return;
      }
      executeSave({ id: assetGroup.id, name: instant.name, rowIndex: rowIndex });
    },
    [executeSave, instant.name, alertAdd]
  );

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          id: string;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete(`/assetGroup/${object.id}`);

        alertAdd({ type: "success", message: "資産グループを削除しました。" });

        if (unmounted.value) {
          return;
        }

        setAssetGroups((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    (assetGroup: AssetGroup, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (assetGroup.id != null) {
        executeDelete({ id: assetGroup.id, rowIndex: rowIndex });
      }
    },
    [executeDelete]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<AssetGroup[]>("/assetgroup", {
            cancelToken: signal.token,
            params: {
              name: search.name,
            },
          });
          setAssetGroups(response.data);
        },
        [search]
      ),
      false
    )
  );

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    }

    ret.push({
      width: 150,
      label: "グループ名",
      dataKey: "name",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
      editType: EditType.AllowEdit,
      componentWhenEditing: (rowIndex: number) => {
        return (
          <TextField
            className={genericClasses.width100percent}
            value={instant.name}
            onChange={handleOnChange("name")}
          />
        );
      },
    });

    return ret;
  }, [genericClasses.width100percent, isValidEdit, handleOnChange, instant.name]);

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item>
                <TextField
                  className={genericClasses.margin}
                  label="資産グループ"
                  value={condition.name}
                  onChange={inputManager.handleOnChange("name")}
                />
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="primary"
                  onClick={() => setSearch({ ...condition })}
                  disabled={editing !== InputState.None}
                >
                  検索
                </Button>
                <Button
                  className={genericClasses.margin}
                  variant="outlined"
                  onClick={() => setCondition({ name: "" } as AssetGroup)}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={assetGroups}
                rowHeight={48}
                columns={columns}
                onClickAddDirectInput={isAssetMenuEditValid ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onClickSave={handleOnClickSave}
                onClickCancel={handleOnClickCancel}
                setEditing={setEditing}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(AssetGroupPanel);
