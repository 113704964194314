import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Condition from "Component/Condition";
import Period from "Component/Period";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useSimpleFetch } from "Common/Utility/Api";
import { VoucherSummary } from "Models/Voucher";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import VoucherList from "./VoucherList";
import { useContentHeight } from "Hooks/useResize";
import { useGenericStyles } from "Common/Utility/Styles";
import { MenuIndex } from "Component/Menu";
import { NumberConfig, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import {
  Design,
  MakeOptionalComboItems,
  VoucherAssignStatus,
  VoucherAssignStatusComboItems,
  VoucherRequestStatus,
  VoucherRequestStatusComboItems,
  VoucherStatus,
  VoucherStatusComboItems,
} from "Common/Utility/Constants";
import { useInputManager } from "Common/Utility/HandleUtility";
import NumberField from "Component/NumberField";
import TextField from "Component/TextField";
import Select from "Component/Select";
import { AppActionTypes, AppProvider } from "App";

const voucherStatusComboItems = MakeOptionalComboItems(VoucherStatusComboItems);
const voucherRequestStatusComboItems = MakeOptionalComboItems(VoucherRequestStatusComboItems);
const voucherAssignStatusComboItems = MakeOptionalComboItems(VoucherAssignStatusComboItems);

const useStyles = makeStyles((theme) => ({
  searchLoading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  selectItem: {
    width: 150,
  },
}));

interface SearchCondition {
  id: string | null;
  voucherNo: string | null;
  voucherDateFrom: DateTime | null;
  voucherDateTo: DateTime | null;
  customerName: string | null;
  closingDateFrom: DateTime | null;
  closingDateTo: DateTime | null;
  depositDateFrom: DateTime | null;
  depositDateTo: DateTime | null;
  voucherStatus: VoucherStatus | null;
  voucherRequestStatus: VoucherRequestStatus | null;
  voucherAssignStatus: VoucherAssignStatus | null;
  staffUserName: string | null;
  approvalUserName: string | null;
  note: string | null;
}

function initialSearchCondition() {
  return {
    id: null,
    voucherNo: null,
    voucherDateFrom: DateUtility.addDate(DateUtility.now(), 0, -1, 0),
    voucherDateTo: DateUtility.format(DateUtility.now(), "yyyy/MM/dd"),
    customerName: null,
    closingDateFrom: null,
    closingDateTo: null,
    depositDateFrom: null,
    depositDateTo: null,
    voucherStatus: null,
    voucherRequestStatus: null,
    voucherAssignStatus: null,
    staffUserName: null,
    approvalUserName: null,
    note: null,
  };
}

const VoucherPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<SearchCondition>(initialSearchCondition());

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<SearchCondition>(initialSearchCondition());

  const [summaries, setSummaries] = useState<VoucherSummary[]>([]);

  const appDispatch = AppProvider.useDispatch();

  const transition = AppProvider.useGlobalState("transition");

  const fetchResultSearch = useSimpleFetch(
    "/voucher/search",
    setSummaries,
    true,
    DateUtility.InvalidToNull(
      search,
      "voucherDateFrom",
      "voucherDateTo",
      "closingDateFrom",
      "closingDateTo",
      "depositDateFrom",
      "depositDateTo"
    ),
    [search]
  );

  useEffect(() => {
    if (transition != null) {
      setSearch({
        id: transition,
      } as SearchCondition);
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: null });
    }
  }, [transition, appDispatch]);

  const isVoucherMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Voucher);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <NumberField
                className={genericClasses.margin}
                label="伝票番号"
                maxLength={NumberConfig.voucherNumberLength}
                value={condition.voucherNo ?? ""}
                onChange={inputManager.handleOnChange("voucherNo")}
              />
              <Period
                label="伝票日付"
                width={170}
                fromValue={condition.voucherDateFrom}
                toValue={condition.voucherDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("voucherDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("voucherDateTo")}
              />
              <TextField
                className={genericClasses.margin}
                label="得意先"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <Period
                label="請求締日"
                width={170}
                fromValue={condition.closingDateFrom}
                toValue={condition.closingDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("closingDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("closingDateTo")}
              />
              <Period
                label="入金予定日"
                width={170}
                fromValue={condition.depositDateFrom}
                toValue={condition.depositDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("depositDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("depositDateTo")}
              />
              <FormControl className={genericClasses.margin}>
                <InputLabel id="voucher-status-select-label">伝票ステータス</InputLabel>
                <Select
                  labelId="voucher-status-select-label"
                  id="voucher-status-select"
                  value={condition.voucherStatus}
                  onChange={inputManager.handleOnChangeSelect("voucherStatus")}
                  className={classes.selectItem}
                >
                  {voucherStatusComboItems.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={genericClasses.margin}>
                <InputLabel>依頼状態</InputLabel>
                <Select
                  value={condition.voucherRequestStatus}
                  onChange={inputManager.handleOnChangeSelect("voucherRequestStatus")}
                  className={classes.selectItem}
                >
                  {voucherRequestStatusComboItems.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={genericClasses.margin}>
                <InputLabel id="voucher-assign-status-select-label">伝票割当状態</InputLabel>
                <Select
                  labelId="voucher-assign-status-select-label"
                  id="voucher-assign-status-select"
                  value={condition.voucherAssignStatus}
                  onChange={inputManager.handleOnChangeSelect("voucherAssignStatus")}
                  className={classes.selectItem}
                >
                  {voucherAssignStatusComboItems.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className={genericClasses.margin}
                label="担当者"
                value={condition.staffUserName}
                onChange={inputManager.handleOnChange("staffUserName")}
              />
              <TextField
                className={genericClasses.margin}
                label="承認者"
                value={condition.approvalUserName}
                onChange={inputManager.handleOnChange("approvalUserName")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialSearchCondition())}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.searchLoading, LoadingMode.Circular, fetchResultSearch) ?? (
            <VoucherList
              summaries={summaries}
              setSummaries={setSummaries}
              height={height}
              editAuth={isVoucherMenuEditValid}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(VoucherPanel);
