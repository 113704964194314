import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { ContentInfo } from "Models/Content";
import Label from "Component/Label";
import {
  ArrowForward,
  Autorenew,
  Edit,
  Done,
  CancelOutlined,
  CloudUpload,
  CloudDownload,
  Delete,
} from "@material-ui/icons";
import { Props } from "./BusinessDetail";
import { useGenericStyles } from "Common/Utility/Styles";
import { useFetch, useExecute } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import { formatEstimateNumber, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import VirtualizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { EstimateSummary } from "Models/Estimate";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { SelectDialog, validateNarrowDownKey } from "Component/SelectDialog";
import { AppActionTypes, AppProvider } from "App";
import { useHistory } from "react-router-dom";
import TextField from "Component/TextField";
import { generalComparsion } from "Common/Utility/GenericInterface";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useContentHeight } from "Hooks/useResize";
import { Design } from "Common/Utility/Constants";
import { AttachmentFile } from "Models/AttachmentFile";
import { useFileUpload } from "Hooks/useFileUpload";

const useStyles = makeStyles((theme) => ({
  loding: {
    height: (props: any) => props.height + 64 + Design.margin * 2,
  },
  paper: {
    width: "100%",
  },
  note: {
    width: "100%",
    marginBottom: Design.margin,
  },
  estimate: {
    width: "100%",
    height: (props: any) => props.height - 111 - 48 - Design.margin,
    minHeight: 100,
  },
  gridRight: {
    padding: "8px 8px 8px 0px",
  },
  file: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 283 - 16,
  },
}));

function ContentInBusiness(props: Props) {
  const [height] = useContentHeight(
    Design.topButtonHeight + Design.componentUnitHeight + 64 + Design.margin * 2 + 64 + Design.margin
  );

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const [data, setData] = useState<ContentInfo>({
    customerManualId: "",
    sequence: 0,
    receptionManualId: "",
    customerName: "",
    creditLimit: 0,
    accountsReceivable: 0,
    note: "",
    updatedAt: null,
    estimates: [],
    files: [],
  });

  const [open, setOpen] = useState<boolean>(false);

  const [estimate, setEstimate] = useState<EstimateSummary[]>([]);

  const filterdEstimate = useMemo(
    () => estimate.filter((i) => data.estimates.findIndex((j) => j.id === i.id) === -1),
    [estimate, data.estimates]
  );

  const appDispatch = AppProvider.useDispatch();

  const history = useHistory();

  const [estimateCondition, setEstimateCondition] = useState<{ customerId: string }>({
    customerId: businessInfo.customerId,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [note, setNote] = useState<string>("");

  const executePut = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string; note: string; updatedAt: DateTime | null }) => {
        const response = await callWebApi().put<ContentInfo>("/content", {
          businessId: object.id,
          note: object.note,
          updatedAt: object.updatedAt,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "備考を保存しました。" });

        if (unmounted.value) {
          return;
        }

        setData(response.data);
        setIsEditing(false);
      },
      [alertAdd]
    )
  );

  const handleOnClick = () => {
    if (isEditing) {
      executePut({ id: businessInfo.id, note: note, updatedAt: data.updatedAt });
    } else {
      setIsEditing(true);
    }
  };

  const handleOnClickCancel = () => {
    setNote(data.note);
    setIsEditing(false);
  };

  const executePutUnlink = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { estimateId: string }) => {
      await callWebApi().put<EstimateSummary>("/estimates/unlink", object);

      if (unmounted.value) {
        return;
      }

      setData((value) => {
        const index = value.estimates.findIndex((i) => i.id === object.estimateId);
        if (index >= 0) {
          value.estimates.splice(index, 1);
          return { ...value, estimates: [...value.estimates] };
        }

        return value;
      });
    }, [])
  );

  const executePutEstimate = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { estimateId: string; businessId: string; customerId: string }) => {
        const response = await callWebApi().put<EstimateSummary>("/estimates/contents", object);

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          const index = value.estimates.findIndex((i) => i.id === object.estimateId);
          if (index === -1) {
            return { ...value, estimates: [...value.estimates, response.data] };
          }

          return value;
        });

        setOpen(false);
      },
      []
    )
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Business);

  const handleOnChangeNote = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target.value;
    setNote(target);
  }, []);

  const { files, setFiles, downloadFile, deleteFile, selectFiles, inputTag } = useFileUpload();

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ContentInfo>("/content", {
          cancelToken: signal.token,
          params: { businessId: businessInfo.id },
        });

        if (response.data == null) {
          return;
        }

        setData(response.data);
        setNote(response.data.note);
        response.data.files.forEach((i) => (i.checked = false));
        setFiles(response.data.files);
      },
      [businessInfo.id, setFiles]
    )
  );

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<EstimateSummary[]>("/estimates/customer", {
          cancelToken: signal.token,
          params: {
            customerId: estimateCondition.customerId,
          },
        });

        setEstimate(response.data);

        setOpen(true);
      },
      [estimateCondition]
    ),
    false
  );

  const handleOnClickReload = useCallback(() => fetchResultLoad.reload(), [fetchResultLoad]);

  const handleOnClickArrowForward = useCallback(
    (estimate: EstimateSummary) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: estimate.id });
      history.push(getMenuInfo(MenuIndex.Estimate).path);
    },
    [appDispatch, history]
  );

  const handleOnClickAdd = useCallback(() => {
    setEstimateCondition((value) => {
      return { ...value };
    });
  }, []);

  const handleOnClickDelete = useCallback(
    (data: EstimateSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (data.id != null) {
        executePutUnlink({ estimateId: data.id });
      }
    },
    [executePutUnlink]
  );

  const handleOnClickDeleteFiles = useCallback(
    (data: AttachmentFile, rowIndex: number) => {
      if (data.id != null) {
        deleteFile(data.id);
      }
    },
    [deleteFile]
  );

  const handleOnClose = useCallback(
    (selected: EstimateSummary | null) => {
      if (selected != null && selected.id != null) {
        executePutEstimate({
          estimateId: selected.id,
          businessId: businessInfo.id,
          customerId: businessInfo.customerId,
        });
      } else {
        setOpen(false);
      }
    },
    [executePutEstimate, businessInfo.id, businessInfo.customerId]
  );

  const handleOnNarrowDown = useCallback(
    (text: string) => {
      const split = validateNarrowDownKey(text);
      if (split.length === 0) {
        return filterdEstimate;
      }

      return filterdEstimate.filter((i) => {
        const estimateNumber = formatEstimateNumber(i.issueYear, i.sequence, i.version);
        const estimateDate = DateUtility.format(i.estimateDate, "yyyy/MM/dd");
        return (
          split.findIndex((find) => {
            return estimateNumber.indexOf(find) >= 0 || estimateDate.indexOf(find) >= 0;
          }) >= 0
        );
      });
    },
    [filterdEstimate]
  );

  const columnsContents: ColumnData[] = useMemo(() => {
    return [
      {
        width: 180,
        label: "見積番号",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (_: any, rowData: any) => formatEstimateNumber(rowData.issueYear, rowData.sequence, rowData.version),
      },
      {
        width: 150,
        label: "見積作成日",
        dataKey: "estimateDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        fit: true,
      },
    ];
  }, []);

  const filesColumns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        widthType: "fix",
        headerComponent: (columnData: ColumnData) => {
          if (isValidEdit) {
            return (
              <IconButton color="primary" onClick={() => selectFiles(businessInfo.id)}>
                <CloudUpload />
              </IconButton>
            );
          } else {
            return <></>;
          }
        },
        component: (data: AttachmentFile, rowIndex: number) => {
          if (isValidEdit) {
            return (
              <IconButton color="primary" onClick={() => handleOnClickDeleteFiles(data, rowIndex)}>
                <Delete />
              </IconButton>
            );
          } else {
            return <></>;
          }
        },
      },
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        widthType: "fix",
        headerComponent: (columnData: ColumnData) => {
          return (
            <IconButton color="primary" onClick={downloadFile}>
              <CloudDownload />
            </IconButton>
          );
        },
        component: (data: AttachmentFile, rowIndex: number) => {
          return (
            <Checkbox
              color="primary"
              checked={data.checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setFiles((value) => {
                  data.checked = checked;
                  return [...value];
                });
              }}
            />
          );
        },
      },
    ];

    columns.push(
      {
        width: 100,
        label: "ファイル名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 250,
        label: "登録日時",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm:ss"),
      }
    );

    return columns;
  }, [isValidEdit, selectFiles, setFiles, businessInfo.id, handleOnClickDeleteFiles, downloadFile]);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [
      {
        width: 80,
        label: "",
        bodyAlign: "center",
        component: (estimate: EstimateSummary, rowIndex: number) => {
          return (
            <IconButton color="primary" onClick={handleOnClickArrowForward(estimate)}>
              <ArrowForward />
            </IconButton>
          );
        },
      },
    ];

    if (isValidEdit) {
      ret.push({
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.DeleteButton,
      });
    }

    ret.push(
      {
        width: 180,
        label: "見積番号",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (_: any, rowData: any) => formatEstimateNumber(rowData.issueYear, rowData.sequence, rowData.version),
        sort: (asc: boolean) => {
          setData((value) => {
            value.estimates.sort(
              (a, b) =>
                generalComparsion(
                  formatEstimateNumber(a.issueYear, a.sequence, a.version),
                  formatEstimateNumber(b.issueYear, b.sequence, b.version)
                ) * (asc ? 1 : -1)
            );
            return { ...value, estimates: [...value.estimates] };
          });
        },
      },
      {
        width: 150,
        label: "見積作成日",
        dataKey: "estimateDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        fit: true,
        sort: (asc: boolean) => {
          setData((value) => {
            value.estimates.sort(
              (a, b) =>
                generalComparsion(
                  DateUtility.format(a.estimateDate, "yyyy/MM/dd"),
                  DateUtility.format(b.estimateDate, "yyyy/MM/dd")
                ) * (asc ? 1 : -1)
            );
            return { ...value, estimates: [...value.estimates] };
          });
        },
      }
    );

    return ret;
  }, [handleOnClickArrowForward, isValidEdit]);

  return (
    <>
      {useLoadingElement(classes.loding, LoadingMode.Simple, fetchResultLoad) ?? (
        <Paper>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Typography className={genericClasses.margin}>業務情報</Typography>
            <IconButton
              className={genericClasses.margin}
              color="primary"
              aria-label="udpate"
              onClick={handleOnClickReload}
              disabled={isEditing}
            >
              <Autorenew />
            </IconButton>
            <Label
              className={genericClasses.margin}
              caption="受注番号"
              text={
                "DR-" +
                DateUtility.format(businessInfo.createdAt, "yyyy") +
                "-" +
                businessInfo.sequence.toString().padStart(5, "0")
              }
            />
            <Label className={genericClasses.margin} caption="問い合わせ番号" text={data.receptionManualId} />
            <Label className={genericClasses.margin} caption="お客様番号" text={data.customerManualId} />
            <Label className={genericClasses.margin} caption="お客様名" text={data.customerName} />
            <Label
              className={genericClasses.margin}
              caption="与信額"
              text={data.creditLimit.toLocaleString() + " 円"}
            />
            <Label
              className={genericClasses.margin}
              caption="売掛金"
              text={data.accountsReceivable.toLocaleString() + " 円"}
            />
          </Grid>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={6} className={genericClasses.padding}>
              {isValidEdit && (
                <>
                  <IconButton color="primary" onClick={handleOnClick}>
                    {isEditing ? <Done /> : <Edit />}
                  </IconButton>
                  {isEditing && (
                    <IconButton color="primary" onClick={handleOnClickCancel}>
                      <CancelOutlined />
                    </IconButton>
                  )}
                </>
              )}
              <TextField
                className={classes.note}
                label="Note"
                multiline
                rowsMax={5}
                rows={5}
                value={note}
                variant="outlined"
                onChange={handleOnChangeNote}
                InputProps={{
                  readOnly: !isEditing,
                  className: genericClasses.padding,
                }}
              />
              <Paper className={classes.estimate}>
                <VirtualizedTable
                  values={data.estimates}
                  rowHeight={48}
                  columns={columns}
                  onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                  onClickDelete={handleOnClickDelete}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Paper className={classes.file}>
                <VirtualizedTable values={files} setValues={setFiles} rowHeight={48} columns={filesColumns} />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      )}
      <SelectDialog
        title="見積"
        open={open}
        columns={columnsContents}
        data={filterdEstimate}
        onClose={handleOnClose}
        onClickNarrowDown={handleOnNarrowDown}
      />
      {inputTag}
    </>
  );
}

export default React.memo(ContentInBusiness);
