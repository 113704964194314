import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { connect, IAction } from "Common/Utility/connect";
import { ScheduledDeposit } from "./ScheduledDeposit";
import { ConfirmDeposit } from "./ConfirmDepositPanel/ConfirmDeposit";

export enum ActionTypes {}

const useStyles = makeStyles((theme) => ({
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

export default React.memo(() => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => setTabIndex(index), []);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <ConfirmDeposit />;
      case 1:
        return <ScheduledDeposit />;
      default:
        return <></>;
    }
  }, [tabIndex]);

  return (
    <>
      <Paper className={classes.tabArea} square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTabs}
          aria-label="tabs"
        >
          <Tab label="入金" />
          <Tab label="入金状況" />
        </Tabs>
      </Paper>
      {panel}
    </>
  );
});

const initialState: DepositType = {};

interface DepositType {}

function reducer(state: DepositType, action: IAction): DepositType {
  switch (action.type) {
    default:
      return state;
  }
}

export const DepositProvider = connect<DepositType>(reducer, initialState);
