import { ColumnData } from "Common/Component/VirtualizedTable";
import { Month } from "Models/Sales";
import { ComboItem } from "./GenericInterface";

export class Design {
  static readonly mainFramePadding: number = 24;

  static readonly headerHeight: number = 64;

  static readonly margin: number = 8;

  static readonly rowHeight = 32;

  static readonly scheduleMinHeight = Design.rowHeight * 10;

  static readonly dialogTopHeight = 32 + 64 + Design.margin;

  static readonly dialogBottomHeight = Design.margin + 52.5 + Design.margin * 2 + 32;

  static readonly unknownGap = 8;

  static readonly componentUnitHeight = 48;

  static readonly topButtonHeight = 44.5;
}

export const BelongIndex = {
  Partner: 0,
  Vendor: 1,
  Operating: 2,
} as const;
type BelongIndex = typeof BelongIndex[keyof typeof BelongIndex];

export const belongCategory = [
  { name: "パートナー", category: 1 },
  { name: "ベンダー", category: 2 },
  { name: "KDW", category: 4 },
];

export const TenantClass = {
  UnClass: 0,
  Agency: 1,
  Sale: 2,
  EndUser: 3,
  Other: 4,
};

export const ChatStatus = {
  Inquiring: 0,
  Accepting: 1,
  Finished: 2,
};
type ChatStatus = typeof ChatStatus[keyof typeof ChatStatus];

export const StockHistoryCategory = {
  Increase: 0,
  Decrease: 1,
};
type StockHistoryCategory = typeof StockHistoryCategory[keyof typeof StockHistoryCategory];

export const InspectionMode = {
  Shipment: 0,
  Arrive: 1,
};
export type InspectionMode = typeof InspectionMode[keyof typeof InspectionMode];

export const ShipmentStatus = {
  Created: 0,
  InProgress: 1,
  Finished: 2,
};
type ShipmentStatus = typeof ShipmentStatus[keyof typeof ShipmentStatus];

export const ShipmentStatusComboItem: ComboItem[] = [
  { text: "未検品", value: 0 },
  { text: "検品中", value: 1 },
  { text: "完了", value: 2 },
];

export const ArriveStatusComboItem: ComboItem[] = [
  { text: "未帰庫", value: 0 },
  { text: "当日の帰庫", value: 1 },
  { text: "当日の未帰庫", value: 2 },
  { text: "貸出中", value: 3 },
];

export const AssetCategoryIndex = {
  InAsset: 0,
  OutAsset: 1,
} as const;
type AssetCategory = typeof AssetCategory[keyof typeof AssetCategory];

export const AssetCategory: ComboItem[] = [
  { text: "簿内資産", value: 0 },
  { text: "簿外資産", value: 1 },
];

export const Days = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;
type Days = typeof Days[keyof typeof Days];

export const SelectMonth: ComboItem[] = [
  { text: "当月", value: 0 },
  { text: "翌月", value: 1 },
  { text: "翌々月", value: 2 },
  { text: "3ヶ月", value: 3 },
  { text: "4ヶ月", value: 4 },
  { text: "5ヶ月", value: 5 },
  { text: "6ヶ月", value: 6 },
  { text: "7ヶ月", value: 7 },
  { text: "8ヶ月", value: 8 },
  { text: "9ヶ月", value: 9 },
  { text: "10ヶ月", value: 10 },
  { text: "11ヶ月", value: 11 },
  { text: "12ヶ月", value: 12 },
];

export const SelectDay: ComboItem[] = [
  { text: "1日", value: 1 },
  { text: "2日", value: 2 },
  { text: "3日", value: 3 },
  { text: "4日", value: 4 },
  { text: "5日", value: 5 },
  { text: "6日", value: 6 },
  { text: "7日", value: 7 },
  { text: "8日", value: 8 },
  { text: "9日", value: 9 },
  { text: "10日", value: 10 },
  { text: "11日", value: 11 },
  { text: "12日", value: 12 },
  { text: "13日", value: 13 },
  { text: "14日", value: 14 },
  { text: "15日", value: 15 },
  { text: "16日", value: 16 },
  { text: "17日", value: 17 },
  { text: "18日", value: 18 },
  { text: "19日", value: 19 },
  { text: "20日", value: 20 },
  { text: "21日", value: 21 },
  { text: "22日", value: 22 },
  { text: "23日", value: 23 },
  { text: "24日", value: 24 },
  { text: "25日", value: 25 },
  { text: "26日", value: 26 },
  { text: "27日", value: 27 },
  { text: "28日", value: 28 },
  { text: "末日", value: 0 },
];

/**
 * 見積明細区分
 */
export const EstimateDetailType = {
  /** レンタル */
  Rental: 1,

  /** 販売 */
  Sale: 2,

  /** 運賃 */
  Fare: 3,

  /** 値引き */
  Discount: 4,

  /** その他 */
  Other: 5,
} as const;
export type EstimateDetailType = typeof EstimateDetailType[keyof typeof EstimateDetailType];

export const EstimateDetailTypeComboItems: ComboItem[] = [
  { value: EstimateDetailType.Rental, text: "レンタル" },
  { value: EstimateDetailType.Sale, text: "販売" },
  { value: EstimateDetailType.Fare, text: "運賃" },
  { value: EstimateDetailType.Discount, text: "値引き" },
  { value: EstimateDetailType.Other, text: "その他" },
];

/**
 * レンタル精算区分
 */
export const RentalPayOff = {
  /** 日割 */
  Daily: 1,

  /** 月額 */
  Monthly: 2,

  /** 月額日割(初月、返却月が日割、継続月が月単価) */
  MonthlyDaily: 3,

  /** 定額 */
  Fixed: 4,
} as const;
export type RentalPayOff = typeof RentalPayOff[keyof typeof RentalPayOff];

export const RentalPayOffComboItems: ComboItem[] = [
  { value: RentalPayOff.Daily, text: "日割" },
  { value: RentalPayOff.Monthly, text: "月額" },
  { value: RentalPayOff.MonthlyDaily, text: "月額日割" },
  { value: RentalPayOff.Fixed, text: "定額" },
];

/**
 * 伝票ステータス
 */
export const VoucherStatus = {
  /** 未処理 */
  Initial: 0,

  /** 出庫待ち */
  WaitingForShipment: 1,

  /** 売上済み */
  Sold: 2,

  /** 請求済み */
  Billed: 3,
} as const;
export type VoucherStatus = typeof VoucherStatus[keyof typeof VoucherStatus];

export const VoucherStatusComboItems: ComboItem[] = [
  { value: VoucherStatus.Initial, text: "未処理" },
  { value: VoucherStatus.WaitingForShipment, text: "出庫待ち" },
  { value: VoucherStatus.Sold, text: "売上済み" },
  { value: VoucherStatus.Billed, text: "請求済み" },
];

/**
 * 伝票依頼ステータス
 */
export const VoucherRequestStatus = {
  /** 未依頼 */
  Unrequested: 1,

  /** 依頼済み */
  Requested: 2,
} as const;
export type VoucherRequestStatus = typeof VoucherRequestStatus[keyof typeof VoucherRequestStatus];

export const VoucherRequestStatusComboItems: ComboItem[] = [
  { value: VoucherRequestStatus.Unrequested, text: "未依頼" },
  { value: VoucherRequestStatus.Requested, text: "依頼済み" },
];

/**
 * 伝票割当状態
 */
export const VoucherAssignStatus = {
  /** 担当未設定 */
  Unassigned: 1,

  /** 未承認 */
  Unapproved: 2,

  /** 承認済み */
  Approved: 3,
} as const;
export type VoucherAssignStatus = typeof VoucherAssignStatus[keyof typeof VoucherAssignStatus];

export const VoucherAssignStatusComboItems: ComboItem[] = [
  { value: VoucherAssignStatus.Unassigned, text: "担当未設定" },
  { value: VoucherAssignStatus.Unapproved, text: "未承認" },
  { value: VoucherAssignStatus.Approved, text: "承認済み" },
];

/**
 * 精算区分
 */
export const PayOff = {
  /** 日極(日数×日単価) */
  Daily: 1,

  /** 月極(月数×月単価) */
  Monthly: 2,

  /** 月極日割(初月、返却月が日割、継続月が月単価) */
  MonthlyDaily: 3,
} as const;
export type PayOff = typeof PayOff[keyof typeof PayOff];

export const TaskStatus: ComboItem[] = [
  { value: 0, text: "未" },
  { value: 1, text: "済" },
];

export const PayOffComboItems: ComboItem[] = [
  { value: PayOff.Daily, text: "日極" },
  { value: PayOff.Monthly, text: "月極" },
  { value: PayOff.MonthlyDaily, text: "月極日割" },
];

export const BuildingCategoryName: ComboItem[] = [
  { value: 0, text: "新築" },
  { value: 1, text: "増築" },
  { value: 2, text: "改築" },
  { value: 3, text: "改修" },
];

export function toComboText(comboItems: ComboItem[] | undefined, value: any): string {
  if (comboItems == null) {
    return "";
  }
  var comboItem = comboItems.find((comboItem) => comboItem.value === value);
  return comboItem ? comboItem.text : "";
}

export function MakeOptionalComboItems(comboItems: ComboItem[], text?: string, value?: any): ComboItem[] {
  return [{ value: value ?? "", text: text ?? "未選択" }, ...comboItems];
}

export const AttachmentFileCategory = {
  Public: 0,
  Secret: 1,
} as const;
export type AttachmentFileCategory = typeof AttachmentFileCategory[keyof typeof AttachmentFileCategory];

export const AttachmentFileCategoryItems: ComboItem[] = [
  { value: AttachmentFileCategory.Public, text: "公開" },
  { value: AttachmentFileCategory.Secret, text: "機密" },
];

export const simpleColumnData = (label: string, dataKey: string) => {
  return [
    {
      width: 300,
      label: label,
      dataKey: dataKey,
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
    },
  ] as ColumnData[];
};

export const AreaComboItem: ComboItem[] = [
  { text: "未選択", value: 0 },
  { text: "北海道", value: 1 },
  { text: "青森県", value: 2 },
  { text: "岩手県", value: 3 },
  { text: "宮城県", value: 4 },
  { text: "秋田県", value: 5 },
  { text: "山形県", value: 6 },
  { text: "福島県", value: 7 },
  { text: "茨城県", value: 8 },
  { text: "栃木県", value: 9 },
  { text: "群馬県", value: 10 },
  { text: "埼玉県", value: 11 },
  { text: "千葉県", value: 12 },
  { text: "東京都", value: 13 },
  { text: "神奈川県", value: 14 },
  { text: "新潟県", value: 15 },
  { text: "富山県", value: 16 },
  { text: "石川県", value: 17 },
  { text: "福井県", value: 18 },
  { text: "山梨県", value: 19 },
  { text: "長野県", value: 20 },
  { text: "岐阜県", value: 21 },
  { text: "静岡県", value: 22 },
  { text: "愛知県", value: 23 },
  { text: "三重県", value: 24 },
  { text: "滋賀県", value: 25 },
  { text: "京都府", value: 26 },
  { text: "大阪府", value: 27 },
  { text: "兵庫県", value: 28 },
  { text: "奈良県", value: 29 },
  { text: "和歌山県", value: 30 },
  { text: "鳥取県", value: 31 },
  { text: "島根県", value: 32 },
  { text: "岡山県", value: 33 },
  { text: "広島県", value: 34 },
  { text: "山口県", value: 35 },
  { text: "徳島県", value: 36 },
  { text: "香川県", value: 37 },
  { text: "愛媛県", value: 38 },
  { text: "高知県", value: 39 },
  { text: "福岡県", value: 40 },
  { text: "佐賀県", value: 41 },
  { text: "長崎県", value: 42 },
  { text: "熊本県", value: 43 },
  { text: "大分県", value: 44 },
  { text: "宮崎県", value: 45 },
  { text: "鹿児島県", value: 46 },
  { text: "沖縄県", value: 47 },
];

export const modelColumns: ColumnData[] = [
  {
    width: 200,
    label: "型式",
    dataKey: "model",
    headerAlign: "center",
    bodyAlign: "left",
  },
  {
    width: 300,
    label: "名称",
    dataKey: "name",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

/**
 * 列の表示制御を行う一覧
 */
export const VisibleColumn = {
  /** 見積管理 */
  Estimate: 0,

  /** 工事管理 */
  Group: 1,

  /** 受注管理 */
  Business: 2,

  /** 失注管理 */
  LostOrder: 3,

  /** 出荷管理 出荷一覧 */
  Shipment: 4,

  /** 出荷管理 帰庫一覧 */
  Arrive: 5,

  /** 伝票管理 伝票一覧 */
  Voucher: 6,

  /** 伝票管理 元伝票一覧 */
  OriginalVoucher: 7,

  /** 伝票管理 紹介一覧 */
  Introduction: 8,

  /** 入金管理 入金一覧 */
  Deposit: 9,

  /** 入金管理 入金状況一覧 */
  ScheduledDeposit: 10,

  /** 資産管理 型式一覧 */
  AssetModel: 11,

  /** 資産管理 採番ルール */
  IndexRule: 12,

  /** 商品管理 商品一覧 */
  Lineup: 13,

  /** 企業管理 */
  Company: 14,

  /** 帳票出力 見積書 */
  ReportEstimate: 15,

  /** 帳票出力 納品書 */
  ReportDeliverySlip: 16,

  /** 帳票出力 請求書 */
  ReportInvoice: 17,

  /** 帳票出力 請求書(統括表) */
  ReportInvoiceSummary: 18,

  /** 帳票出力 出荷案内書 */
  ReportShippingAdvice: 19,

  /** 帳票出力 帰庫検収書 */
  ReportReturnInspection: 20,

  /** ロール管理 */
  Role: 21,

  /** ユーザー管理 ユーザー一覧 */
  User: 22,

  /** ユーザー管理 依頼一覧 */
  UserRequest: 23,

  /** お知らせ管理 */
  Notification: 24,

  /** 機能制限 */
  Restriction: 25,

  /** 公開資料 公開 */
  PublicDocumentOpen: 26,

  /** 公開資料 登録 */
  PublicDocumentRegister: 27,

  /** 受注管理 日程 */
  ScheduleInBusiness: 28,

  /** 受注管理 入金 */
  DepositInBusiness: 29,

  /** 修理履歴 */
  RepairHistory: 30,

  /** 修理部品 */
  RepairParts: 31,

  /** 統計情報 資産 */
  AssetStatistics: 32,

  /** 日程管理 タスクテンプレート */
  WorkTaskTemplate: 33,

  /** チェックシート */
  CheckSheet: 34,
} as const;
export type TableMaster = typeof VisibleColumn[keyof typeof VisibleColumn];

// 月の名称
export const months: Array<keyof Month> = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const redirectUri: string[] = ["/Estimate", "/Voucher", "/ContactTo", "/ContactFrom", "/Company"];
