import { useMemo, useEffect, useRef } from "react";

export const useUnmount = () => {
  const unmountRef = useRef(false);

  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    []
  );

  return useMemo(() => unmountRef, []);
};
