import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { useCallback } from "react";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useContentHeight } from "Hooks/useResize";
import { PublicManager } from "./PublicPanel/PublicPanel";
import { RequestPage } from "./RegistPanel/RegistPanel";

export enum ActionTypes {
  SET_ROLES = "SET_ROLES",
  SET_ACCOUNTS = "SET_ACCOUNTS",
}

const useStyles = makeStyles((theme) => ({
  loading: {
    height: (props: any) => props.height,
    width: "100%",
  },
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

export default React.memo(() => {
  const [height] = useContentHeight(56);

  const classes = useStyles({ height: height });

  const [tabIndex, setTabIndex] = useState(0);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <PublicManager />;
      case 1:
        return <RequestPage />;
    }
  }, [tabIndex]);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <>
      {useLoadingElement(classes.loading, LoadingMode.Circular) ?? (
        <>
          <Paper className={classes.tabArea} square>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleOnChangeTabs}
              aria-label="tabs"
            >
              <Tab label="公開" />
              <Tab label="登録" />
            </Tabs>
          </Paper>
          {panel}
        </>
      )}
    </>
  );
});
