import React, { useMemo, useState } from "react";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { ReportConfig } from "Models/ReportConfig";
import Period from "Component/Period";
import { useGenericStyles } from "Common/Utility/Styles";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { callPrint, Print } from "Common/Component/Print";
import Condition from "Component/Condition";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useContentHeight } from "Hooks/useResize";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useSimpleFetch } from "Common/Utility/Api";
import { ShipmentStatusComboItem, toComboText } from "Common/Utility/Constants";
import ShippingAdviceDesign from "./ShippingAdviceDesign";
import { ShippingAdvice } from "Models/ShippingAdvice";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { ComboItem } from "Common/Utility/GenericInterface";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { CheckColumn, simpleColumnData } from "Pages/Report/Report";

const useStyles = makeStyles((theme) => ({
  loadingCompany: {
    width: "100%",
    height: 48,
  },
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  labelWithSelect: {
    width: 300,
    margin: theme.spacing(1),
  },
  select: {
    width: 100,
    margin: theme.spacing(1),
  },
}));

interface Condition {
  from?: DateTime;

  to?: DateTime;

  companyName?: string;

  companyId?: string;
}

const defaultCondition: Condition = {};

const Report = () => {
  const [height, ref] = useContentHeight(46);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(defaultCondition);

  const [search, setSearch] = useState<Condition>(defaultCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<ShippingAdvice[]>([]);

  const [signImage, setSignImage] = useState<string>("");

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  const fetchdata = useSimpleFetch(
    "/ShippingAdvices",
    setData,
    false,
    DateUtility.InvalidToNull(search, "from", "to"),
    [search]
  );

  const fetchSignImage = useSimpleFetch("/reports/kdwsign", (data: { signImage: string }) => {
    setSignImage(data.signImage);
  });

  const fetchCompany = useSimpleFetch("/companies/customer", setCustomers);

  const loadingElementCompany = useLoadingElement(classes.loadingCompany, LoadingMode.Simple, fetchCompany);

  const loadingElement = useLoadingElement(classes.loading, LoadingMode.Circular, fetchdata, fetchSignImage);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ReportShippingAdvice
  );

  const columns: ColumnData[] = useMemo(
    () => [
      CheckColumn(data, setData),
      {
        width: 200,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 350,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(1),
      },
      {
        width: 100,
        label: "状況",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => toComboText(ShipmentStatusComboItem, data),
        visible: getVisible(2),
      },
    ],
    [getVisible, data]
  );

  const onClickNarrowDown = useNarrowDown(customers, "text");

  return (
    <>
      <Grid item xs={12}>
        {loadingElementCompany ?? (
          <Condition observer={ref}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Period
                label="出荷日"
                fromValue={condition.from}
                toValue={condition.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="出荷先企業"
                text={condition.companyName}
                data={customers}
                columns={[simpleColumnData]}
                onClickNarrowDown={onClickNarrowDown}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, companyId: result.value, companyName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(defaultCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="flex-start">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => callPrint("printout")}>
              PDF出力
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualizedTable
              values={data}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      <Print id="printout" title="帳票印刷">
        <ShippingAdviceDesign signImage={signImage} shippingAdvices={data} />
      </Print>
    </>
  );
};

export const shippingAdviceConfig: ReportConfig = {
  text: "出荷案内書",
  element: <Report />,
};
