import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import VirtualizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { AddBox } from "@material-ui/icons";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useFetch, useExecuteEx } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { Account } from "Models/Account";
import { Request, RequestCreateUser, RequestDeleteUser, RequestPlan, RequestUpdateUser } from "Models/Request";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import clsx from "clsx";
import { SelectDialog } from "Component/SelectDialog";
import { useInputManager, handleOnChangeSelect } from "Common/Utility/HandleUtility";
import { AppProvider } from "App";
import { MenuIndex } from "Component/Menu";
import { CreateUserDialog } from "./CreateUserDialog";
import { UpdateUserDialog } from "./UpdateUserDialog";
import { UserManagerProvider } from "../User";
import { RequestType, selectRequestType } from "./RequestPanel";

const useStyles = makeStyles((theme) => ({
  contents: {
    width: 960,
    height: 580,
  },
  dialogPaper: {
    height: 500,
  },
}));

interface RequestDialogProps {
  open: boolean;
  id: string | null;
  isOperator: boolean;
  disabled: boolean;
  onClose: (reuslt: Request | null) => void;
}

export const RequestDialog = (props: RequestDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const accounts = UserManagerProvider.useGlobalState("accounts");

  const [edited, confirm] = useWhetherEdited(props.open);

  const [request, setRequest] = useState({} as Request);

  const inputManager = useInputManager(setRequest, edited);

  const [open, setOpen] = useState(false);

  const [editIndex, setEditIndex] = useState(-1);

  const tableData = useMemo(() => {
    switch (request.type) {
      case RequestType.create:
        return request.createUsers;
      case RequestType.update:
        return request.updateUsers;
      case RequestType.delete:
        return request.deleteUsers;
      default:
        return [];
    }
  }, [request]);

  const filteredAccounts = useMemo(() => {
    switch (request.type) {
      case RequestType.update:
        return accounts?.filter(
          (account) => request.updateUsers.find((update) => update.userGuid === account.userGuid) === undefined
        );
      case RequestType.delete:
        return accounts?.filter(
          (account) => request.deleteUsers.find((deleteUser) => deleteUser.userGuid === account.userGuid) === undefined
        );
      default:
        return [] as Account[];
    }
  }, [accounts, request]);

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { request: Request; onClose: (result: Request | null) => void; isOperator: boolean }
      ) => {
        if (object.request.id == null) {
          await callWebApi().post("/requests", object.request);
        } else {
          await callWebApi().put(`/requests/${object.request.id}/${object.request.supported}`);
        }

        alertAdd({ type: "success", message: "依頼情報の保存に成功しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(object.request);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = async () => {
    if (
      !props.isOperator &&
      request.createUsers.length === 0 &&
      request.updateUsers.length === 0 &&
      request.deleteUsers.length === 0
    ) {
      alertAdd({ type: "info", message: "１件以上のデータを登録する必要があります。" });
      return;
    }

    executePost({
      request: { ...request, createdUserId: ralmAccount?.userGuid! },
      onClose: props.onClose,
      isOperator: props.isOperator,
    });
  };

  const handleOnChangeType = async (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    setOpen(false);

    if (request.createUsers.length > 0 || request.updateUsers.length > 0 || request.deleteUsers.length > 0) {
      if (await message.confirm("確認", "表にデータが存在しますが、変更しますか？")) {
        setRequest((value) => {
          return { ...value, type: event.target.value as number, createUsers: [], updateUsers: [], deleteUsers: [] };
        });

        edited();
      }
      return;
    }

    handleOnChangeSelect<Request>("type", setRequest)(event, child);
    edited();
  };

  const handleOnCloseCreateUserDialog = useCallback(
    (result: RequestCreateUser | null) => {
      setOpen(false);

      if (result == null) {
        return;
      }

      setRequest((value) => {
        if (editIndex === -1) {
          value.createUsers.push(result);
        } else {
          value.createUsers[editIndex] = result;
        }

        return { ...value, createUsers: [...value.createUsers] };
      });

      edited();
    },
    [edited, editIndex]
  );

  const handleOnCloseUpdateDialog = useCallback(
    (result: RequestUpdateUser | null) => {
      setOpen(false);

      if (result == null) {
        return;
      }

      setRequest((value) => {
        if (editIndex === -1) {
          value.updateUsers.push(result);
        } else {
          value.updateUsers[editIndex] = result;
        }

        return { ...value, updateUsers: [...value.updateUsers] };
      });

      edited();
    },
    [edited, editIndex]
  );

  const handleOnCloseDeleteUserDialog = useCallback(
    (result: Account | null) => {
      setOpen(false);

      if (result == null) {
        return;
      }

      setRequest((value) => {
        if (editIndex === -1) {
          value.deleteUsers.push({ userGuid: result.userGuid, userName: result.userName });
        } else {
          value.deleteUsers[editIndex] = { userGuid: result.userGuid, userName: result.userName };
        }

        return { ...value, deleteUsers: [...value.deleteUsers] };
      });

      edited();
    },
    [edited, editIndex]
  );

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setRequest((value) => {
        if (value.type === RequestType.create) {
          value.createUsers.splice(rowIndex, 1);
        } else if (value.type === RequestType.update) {
          value.updateUsers.splice(rowIndex, 1);
        } else if (value.type === RequestType.delete) {
          value.deleteUsers.splice(rowIndex, 1);
        }

        return {
          ...value,
          createUsers: [...value.createUsers],
          updateUsers: [...value.updateUsers],
          deleteUsers: [...value.deleteUsers],
        };
      });
    },
    []
  );

  const selectDialogColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "ユーザー",
        dataKey: "userName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const dialog = useMemo(() => {
    const emptyPlan = { plans: [] as RequestPlan[] };

    switch (request.type) {
      case 0:
        return (
          <CreateUserDialog
            open={open}
            createUserData={editIndex === -1 ? (emptyPlan as RequestCreateUser) : request.createUsers[editIndex]}
            disabled={props.disabled}
            onClose={handleOnCloseCreateUserDialog}
          />
        );
      case 1:
        return (
          <UpdateUserDialog
            open={open}
            updateUserData={editIndex === -1 ? (emptyPlan as RequestUpdateUser) : request.updateUsers[editIndex]}
            accounts={filteredAccounts!}
            disabled={props.disabled}
            onClose={handleOnCloseUpdateDialog}
          />
        );
      case 2:
        return (
          <SelectDialog
            open={open}
            title="対象のユーザー"
            columns={selectDialogColumns}
            data={filteredAccounts!}
            onClose={handleOnCloseDeleteUserDialog}
          />
        );
      default:
        return <></>;
    }
  }, [
    props.disabled,
    selectDialogColumns,
    request,
    open,
    editIndex,
    filteredAccounts,
    handleOnCloseCreateUserDialog,
    handleOnCloseUpdateDialog,
    handleOnCloseDeleteUserDialog,
  ]);

  const handleOnClickEdit = useCallback((data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setOpen(true);
    setEditIndex(rowIndex);
  }, []);

  const handleOnClickReference = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setOpen(true);
      setEditIndex(rowIndex);
    },
    []
  );

  const convertPlan = useCallback((plans: RequestPlan[]) => {
    let ret = "";

    for (let i = 0; i < plans.length - 1; i++) {
      ret += plans[i].planName + ", ";
    }

    if (plans.length > 0) {
      ret += plans[plans.length - 1].planName;
    }

    return ret;
  }, []);

  const columns = useMemo(() => {
    let ret = [] as ColumnData[];
    switch (request.type) {
      case 0:
        if (!props.disabled) {
          ret = [
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.EditButton,
              rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
                return (
                  <IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      setEditIndex(-1);
                      setOpen(true);
                    }}
                  >
                    <AddBox />
                  </IconButton>
                );
              },
            },
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.DeleteButton,
            },
          ] as ColumnData[];
        } else {
          ret = [
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.ReferenceButton,
            },
          ] as ColumnData[];
        }

        return ret.concat([
          {
            width: 150,
            label: "ユーザーID",
            dataKey: "userId",
            headerAlign: "center",
            bodyAlign: "left",
          },
          {
            width: 200,
            label: "ユーザー名",
            dataKey: "userName",
            headerAlign: "center",
            bodyAlign: "left",
          },
          {
            width: 150,
            label: "プラン",
            dataKey: "plans",
            headerAlign: "center",
            bodyAlign: "left",
            fit: true,
            convert: convertPlan,
          },
        ] as ColumnData[]);
      case 1:
        if (!props.disabled) {
          ret = [
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.EditButton,
              rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
                return (
                  <IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      setEditIndex(-1);
                      setOpen(true);
                    }}
                  >
                    <AddBox />
                  </IconButton>
                );
              },
            },
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.DeleteButton,
            },
          ] as ColumnData[];
        } else {
          ret = [
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.ReferenceButton,
            },
          ];
        }

        return ret.concat([
          {
            width: 150,
            label: "対象のユーザー",
            dataKey: "userName",
            headerAlign: "center",
            bodyAlign: "left",
          },
          {
            width: 170,
            label: "更新後のユーザー名",
            dataKey: "updatedName",
            headerAlign: "center",
            bodyAlign: "left",
          },
          {
            width: 150,
            label: "プラン",
            dataKey: "plans",
            headerAlign: "center",
            bodyAlign: "left",
            fit: true,
            convert: convertPlan,
          },
        ] as ColumnData[]);
      case 2:
        if (!props.disabled) {
          ret = [
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.EditButton,
              rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
                return (
                  <IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      setOpen(true);
                      setEditIndex(-1);
                    }}
                  >
                    <AddBox />
                  </IconButton>
                );
              },
            },
            {
              width: 80,
              label: "",
              headerAlign: "center",
              bodyAlign: "center",
              editType: EditType.DeleteButton,
            },
          ];
        }

        return ret.concat([
          {
            width: 150,
            label: "対象のユーザー",
            dataKey: "userName",
            headerAlign: "center",
            bodyAlign: "left",
            fit: true,
          },
        ] as ColumnData[]);
      default:
        return [] as ColumnData[];
    }
  }, [props.disabled, request.type, convertPlan]);

  const loadingElement = useLoadingElement(
    "",
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.open) {
            return;
          }

          if (props.id == null) {
            setRequest({
              id: null,
              type: RequestType.create,
              createUsers: [] as RequestCreateUser[],
              updateUsers: [] as RequestUpdateUser[],
              deleteUsers: [] as RequestDeleteUser[],
            } as Request);
            return;
          }

          const response = await callWebApi().get<Request>(`requests/${props.id}`, {
            cancelToken: signal.token,
          });

          if (!response.data) {
            return;
          }

          setRequest(response.data);
        },
        [props.open, props.id]
      )
    )
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.User);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>依頼情報</DialogTitle>
      <DialogContent className={classes.contents}>
        {loadingElement ?? (
          <>
            <FormControl className={clsx(genericClasses.width200, genericClasses.margin)}>
              <InputLabel>依頼の種類</InputLabel>
              <Select onChange={handleOnChangeType} value={request.type} disabled={props.disabled}>
                {selectRequestType.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.isOperator && (
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={request.supported}
                    onChange={inputManager.handleOnChangeCheck("supported")}
                    color="primary"
                    disabled={!(props.isOperator && isValidEdit)}
                  />
                }
                label="対応済み"
              />
            )}
            <Paper className={classes.dialogPaper}>
              <VirtualizedTable
                rowHeight={48}
                columns={columns}
                values={tableData}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickReference}
                onClickDelete={handleOnClickDelete}
              />
            </Paper>
            {dialog}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {props.disabled && !(props.isOperator && isValidEdit) ? (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose(null))} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={() => confirm(() => props.onClose(null))}
              color="primary"
            >
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
