import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { ChangeEvent, useCallback } from "react";
import { ComboItem } from "./GenericInterface";

export interface InputManagerResult<T> {
  handleOnChange: (target: keyof T) => (event: ChangeEvent<HTMLInputElement | any>) => void;
  handleOnChangeAutocomplete: (target: keyof T) => (event: ChangeEvent<HTMLInputElement | any>) => void;
  handleOnChangeNumber: (target: keyof T) => (event: ChangeEvent<HTMLInputElement | any>) => void;
  handleOnChangeDate: (
    target: keyof T,
    changed?: (date?: string) => void
  ) => (date: MaterialUiPickersDate | null, value?: string | null) => void;
  handleOnChangeSelect: (
    target: keyof T,
    onChangeSelect?: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void
  ) => (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  handleOnChangeLabelWithSelect: (setMethod: (value: T, result: any) => T) => (result: ComboItem) => void;
  handleOnChangeCheck: (target: keyof T) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const useInputManager = function <T>(setData: React.Dispatch<React.SetStateAction<T>>, edited?: () => void) {
  const handleOnChange = useCallback(
    (target: keyof T) => {
      return (event: ChangeEvent<HTMLInputElement | any>) => {
        const newValue = event.target.value;
        setData((value) => {
          return { ...value, [target]: newValue === "" ? undefined : newValue };
        });

        edited?.();
      };
    },
    [edited, setData]
  );

  const handleOnChangeAutocomplete = useCallback(
    (target: keyof T) => {
      return (event: ChangeEvent<HTMLInputElement | any>) => {
        const newValue = event.target.textContent;
        setData((value) => {
          return { ...value, [target]: newValue };
        });

        edited?.();
      };
    },
    [edited, setData]
  );

  const handleOnChangeNumber = useCallback(
    (target: keyof T) => {
      return (event: ChangeEvent<HTMLInputElement | any>) => {
        let newValue: number | null = null;
        if (event.target.value) {
          newValue = Number(event.target.value);
          if (isNaN(newValue)) {
            newValue = null;
          }
        }

        setData((value) => {
          return { ...value, [target]: newValue };
        });

        edited?.();
      };
    },
    [edited, setData]
  );
  const handleOnChangeDate = useCallback(
    (target: keyof T, changed?: (date?: string) => void) => {
      return (date: MaterialUiPickersDate | null, value?: string | null) => {
        if (date == null) {
          setData((value) => {
            return { ...value, [target]: null };
          });
          changed?.();
        } else {
          setData((value) => {
            return { ...value, [target]: date.toLocaleDateString() };
          });
          changed?.(date.toLocaleDateString());
        }

        edited?.();
      };
    },
    [edited, setData]
  );

  const handleOnChangeSelect = useCallback(
    (
      target: keyof T,
      onChangeSelect?: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void
    ) => {
      return (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        setData((value) => {
          if (event.target.value === "") {
            return { ...value, [target]: null };
          } else {
            return { ...value, [target]: event.target.value };
          }
        });

        edited?.();

        onChangeSelect?.(event, child);
      };
    },
    [edited, setData]
  );

  const handleOnChangeLabelWithSelect = useCallback(
    (setMethod: (value: T, result: any) => T) => {
      return (result: any) => {
        setData((value) => {
          return setMethod(value, result);
        });

        edited?.();
      };
    },
    [edited, setData]
  );

  const handleOnChangeCheck = useCallback(
    (target: keyof T) => {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setData((value) => {
          return { ...value, [target]: checked };
        });

        edited?.();
      };
    },
    [edited, setData]
  );

  return {
    handleOnChange: handleOnChange,
    handleOnChangeAutocomplete: handleOnChangeAutocomplete,
    handleOnChangeNumber: handleOnChangeNumber,
    handleOnChangeDate: handleOnChangeDate,
    handleOnChangeSelect: handleOnChangeSelect,
    handleOnChangeLabelWithSelect: handleOnChangeLabelWithSelect,
    handleOnChangeCheck: handleOnChangeCheck,
  } as InputManagerResult<T>;
};

export function handleOnChange<T, U = T>(target: keyof T, set: React.Dispatch<React.SetStateAction<U>>) {
  return (event: ChangeEvent<HTMLInputElement | any>) => {
    const newValue = event.target.value;
    set((value) => {
      return { ...value, [target]: newValue };
    });
  };
}

export function handleOnChangeNumber<T, U = T>(target: keyof T, set: React.Dispatch<React.SetStateAction<U>>) {
  return (event: ChangeEvent<HTMLInputElement | any>) => {
    var newValue: number | null = Number(event.target.value);
    if (isNaN(newValue)) {
      newValue = null;
    }
    set((value) => {
      return { ...value, [target]: newValue };
    });
  };
}

export function handleOnChangeDate<T, U = T>(target: keyof T, set: React.Dispatch<React.SetStateAction<U>>) {
  return (date: Date | null) => {
    if (date == null) {
      set((value) => {
        return { ...value, [target]: null };
      });
    } else {
      set((value) => {
        return { ...value, [target]: date.toLocaleDateString() };
      });
    }
  };
}

export function handleOnChangeSelect<T, U = T>(target: keyof T, set: React.Dispatch<React.SetStateAction<U>>) {
  return (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
    set((value) => {
      return { ...value, [target]: event.target.value };
    });
  };
}

export function handleOnChangeCheck<T, U = T>(target: keyof T, set: React.Dispatch<React.SetStateAction<U>>) {
  return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    set((value) => {
      return { ...value, [target]: checked };
    });
  };
}
