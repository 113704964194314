import { makeStyles } from "@material-ui/core";
import { DateUtility } from "Common/Utility/DateUtility";
import { DrawUtility, Align, Font, VerticalAlign } from "Common/Utility/Drawing";
import { Canvas, CanvasEvent, CanvasHandler, CanvasInfo, ColumnInfo } from "Component/Canvas";
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { AssetMaster, LendingPeriod, numberOfAsset } from "Models/Asset";
import {
  CanvasDataBase,
  DrawExcess,
  drawLegend,
  DrawLending,
  DrawPattern,
  ScheduleHandlerBase,
  ScheduleProps,
  useSortScheduleList,
} from "Component/Schedule/Schedule";
import { Design, ShipmentStatus } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  canvas: {
    height: 500,
    width: 500,
    backgroundColor: "white",
  },
  cursor: {
    cursor: "w-resize",
  },
}));

interface CanvasData extends CanvasDataBase<AssetMaster, SelectAssetScheduleProps> {
  columns: ColumnInfo<AssetMaster>[];
}

interface Callbacks {
  setData: React.Dispatch<React.SetStateAction<CanvasData>>;

  onSelected: (asset: AssetMaster) => void;

  sort: (index: number) => void;
}

class HandlerBase extends ScheduleHandlerBase<CanvasData, Callbacks, AssetMaster, SelectAssetScheduleProps> {
  protected drawRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    maxDate: Date,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const distanceDate = this.calcDistance(canvasInfo.mouseMovementDistance.x);

    // 行
    const date = new Date(
      canvasData.horizontalOffset.getFullYear(),
      canvasData.horizontalOffset.getMonth(),
      canvasData.horizontalOffset.getDate() + distanceDate
    );

    const vOffset = HandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    const drawPatterns: DrawPattern[] = [];

    for (
      let i = vOffset;
      i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < canvasData.scheduleProps.rows.length;
      ++i
    ) {
      const assetMaster = canvasData.scheduleProps.rows[i];
      assetMaster.lendingPeriods.forEach((value: LendingPeriod) => {
        let from = new Date(value.from);
        let to = value.to == null ? null : new Date(value.to);

        if (assetMaster.assetNumber != null) {
          drawPatterns.push(new DrawLending(i - vOffset, from, to, value.returnAssets));
          // 超過時の処理
          if (value.shipmentStatus === ShipmentStatus.Finished) {
            drawPatterns.push(new DrawExcess(i - vOffset, from, to, value.returnAssets));
          }
        }
      });

      this.createDrawPatterns(date, maxDate, i - vOffset, assetMaster, drawPatterns);
    }

    drawPatterns.sort((a, b) => a.zIndex - b.zIndex);
    drawPatterns.forEach((i) => i.draw(context, canvasData.columnsWidth, date, maxDate));
  }

  protected drawAsset(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const props = canvasData.scheduleProps;

    let vOffset = HandlerBase.calcVerticalOffset(
      props.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    // スケジュールのはみ出し部分の削除
    context.clearRect(0, 0, canvasData.columnsWidth, canvas.height);

    // 資産番号の出力
    context.fillStyle = "black";

    for (let i = vOffset; i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < props.rows.length; ++i) {
      var x = 0;
      for (let j = 0; j < canvasData.columns.length; ++j) {
        const columnInfo = canvasData.columns[j];
        if (columnInfo.toString) {
          DrawUtility.fillText(
            context,
            columnInfo.toString(props.rows[i]),
            {
              x: 8 + x,
              y: Design.headerHeight + (i - vOffset) * Design.rowHeight,
              w: columnInfo.width - 16,
              h: Design.rowHeight,
            },
            new Font("Meiryo", 12),
            columnInfo.bodyHorizonAlign,
            columnInfo.bodyVerticalAlign
          );
        }
        x += columnInfo.width;
      }
    }
  }

  protected drawHeader(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    // ヘッダーの境界線
    context.strokeStyle = "rgb(200,200,200)";
    context.lineWidth = 1;
    DrawUtility.line(context, 0, Design.headerHeight, canvas.width, Design.headerHeight);
    DrawUtility.line(context, 0, Design.rowHeight, canvasData.columnsWidth, Design.rowHeight);

    context.lineWidth = 0.5;
    var position = 0;
    for (let i = 0; i < canvasData.columns.length; ++i) {
      DrawUtility.fillText(
        context,
        canvasData.columns[i].text,
        {
          x: position,
          y: Design.rowHeight,
          w: canvasData.columns[i].width,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 12),
        canvasData.columns[i].headerHorizontalAlign,
        canvasData.columns[i].headerVerticalAlign
      );

      if (i < canvasData.columns.length - 1) {
        position += canvasData.columns[i].width;
        DrawUtility.line(context, position, Design.rowHeight, position, canvas.height);
      }
    }

    drawLegend(context, canvasData.columnsWidth, false);
  }

  private drawSelectedRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const selectedAsset = canvasData.scheduleProps.selectedAsset;
    if (selectedAsset == null) {
      return;
    }

    let vOffset = HandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    const index = canvasData.scheduleProps.rows.findIndex((i) => i.id === selectedAsset.id) - vOffset;

    if (index < 0) {
      return;
    }

    context.save();

    context.fillStyle = "rgba(63,81,181,0.5";
    DrawUtility.fillRect(
      context,
      {
        x: 0,
        y: Design.headerHeight + index * Design.rowHeight,
        w: canvas.width,
        h: Design.rowHeight,
      },
      0
    );

    context.restore();
  }

  onPaint(canvas: HTMLCanvasElement, canvasInfo: CanvasInfo, data: CanvasData, context: CanvasRenderingContext2D) {
    super.onPaint(canvas, canvasInfo, data, context);

    this.drawSelectedRow(canvas, context, canvasInfo, data);
  }
}

class DefaultHandler extends HandlerBase {
  onClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    if (position.x <= event.data.columnsWidth && position.y >= Design.rowHeight && position.y <= Design.headerHeight) {
      let width: number = 0;
      const index = event.data.columns.findIndex((i) => {
        if (width <= position.x && width + i.width >= position.x) {
          return true;
        }

        width += i.width;
        return false;
      });
      if (index >= 0) {
        event.callbacks.sort(index);
      }
    }

    return undefined;
  }

  onDoubleClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    let positionIndex = this.getDetailPosition(
      DrawUtility.mousePosition(canvas, event.mouseEvent),
      event.data.columnsWidth
    );

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    // 行が存在しない場合は終了
    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex >= event.data.scheduleProps.rows.length || vPositionIndex < 0) {
      return undefined;
    }

    // 該当行の資産情報
    const asset = event.data.scheduleProps.rows[vPositionIndex];

    event.callbacks.onSelected(asset);

    return undefined;
  }

  onMouseDown(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex >= event.data.scheduleProps.rows.length || vPositionIndex < 0) {
      event.data.scheduleProps.setSelectedAsset(null);
    } else {
      event.data.scheduleProps.setSelectedAsset(event.data.scheduleProps.rows[vPositionIndex]);
    }

    if (positionIndex.y < 0) {
      return undefined;
    }

    if (position.x > event.data.columnsWidth) {
      return new ScrollHandler();
    }

    return undefined;
  }

  onMouseWheel(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      this.calcDistance((event.mouseEvent as WheelEvent).deltaY)
    );

    if (event.data.verticalOffset === vOffset) {
      return undefined;
    }

    event.callbacks.setData((value) => {
      return { ...value, verticalOffset: vOffset };
    });

    event.mouseEvent.preventDefault();

    return undefined;
  }
}

class ScrollHandler extends HandlerBase {
  private resetDistance(canvas: HTMLCanvasElement, event: CanvasEvent<CanvasData, Callbacks>) {
    event.callbacks.setData((value) => {
      if (event.canvasInfo.mouseMovementDistance.x !== 0) {
        value.horizontalOffset = new Date(
          value.horizontalOffset.getFullYear(),
          value.horizontalOffset.getMonth(),
          value.horizontalOffset.getDate() + this.calcDistance(event.canvasInfo.mouseMovementDistance.x)
        );
      }

      if (event.canvasInfo.mouseMovementDistance.y !== 0) {
        value.verticalOffset = HandlerBase.calcVerticalOffset(
          value.scheduleProps.rows.length,
          canvas.height,
          value.verticalOffset,
          this.calcDistance(event.canvasInfo.mouseMovementDistance.y)
        );
      }

      return { ...value };
    });
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.refresh();
    return undefined;
  }
}

interface SelectAssetScheduleProps extends ScheduleProps<AssetMaster> {
  className: string;

  selectedAsset: AssetMaster | null;

  setSelectedAsset: React.Dispatch<React.SetStateAction<AssetMaster | null>>;

  onSelected: (asset: AssetMaster) => void;
}

function SelectAssetSchedule(props: SelectAssetScheduleProps) {
  const classes = useStyles();

  const setRows = useMemo(() => props.setRows, [props.setRows]);

  const columns: ColumnInfo<AssetMaster>[] = useMemo(
    () => [
      {
        width: 200,
        text: "型式",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        dataKey: "model",
        toString: (data: AssetMaster) => data.model,
      },
      {
        width: 200,
        text: "資産番号",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        dataKey: "assetNumber",
        toString: (data: AssetMaster) => data.assetNumber,
      },
      {
        width: 80,
        text: "資産数",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.right,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: AssetMaster) => numberOfAsset(data).toString(),
      },
    ],
    []
  );

  const sort = useSortScheduleList<AssetMaster>(setRows, columns);

  const [data, setData] = useState<CanvasData>({
    scheduleProps: props,
    columns: columns,
    columnsWidth: columns.reduce((prev, current) => prev + current.width, 0),
    verticalOffset: 0,
    horizontalOffset: new Date(DateUtility.nowDate()),
    resizeCursor: false,
  } as CanvasData);

  const callbacks = useMemo(() => {
    return {
      setData: setData,
      onSelected: props.onSelected,
      sort: sort,
    } as Callbacks;
  }, [sort, props.onSelected]);

  useEffect(() => {
    setData((value) => {
      return { ...value, scheduleProps: props };
    });
  }, [props]);

  return (
    <>
      <Canvas<CanvasData, Callbacks>
        className={clsx(props.className, classes.canvas, data.resizeCursor && classes.cursor)}
        handler={new DefaultHandler()}
        data={data}
        callbacks={callbacks}
        components={[]}
      />
    </>
  );
}

export default React.memo(SelectAssetSchedule);
