import React, { useState } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { useMessageBox } from "Hooks/useMessageBox";
import { AppActionTypes, AppProvider } from "App";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  id: string;

  name: string;

  children: React.ReactElement;
}

export const HoldDialog = (props: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const messageBox = useMessageBox();

  const dispatch = AppProvider.useDispatch();

  const handleOnHold = () => {
    setOpen(false);
  };

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleOnDelete = async () => {
    if (await messageBox.confirm("保存確認", "変更を破棄しますか？")) {
      dispatch({ type: AppActionTypes.REMOVE_HOLD, value: props.id });
    }
  };

  const handleOnClose = async (args: any) => {
    dispatch({ type: AppActionTypes.REMOVE_HOLD, value: props.id });

    props.children.props.onClose(args);
  };

  const dialog = React.cloneElement(props.children, { open: open, onHold: handleOnHold, onClose: handleOnClose });

  return (
    <>
      <Chip
        className={classes.chip}
        label={props.name}
        onClick={handleOnClick}
        onDelete={handleOnDelete}
        color="primary"
        variant="outlined"
      />
      {dialog}
    </>
  );
};
