import { MutableRefObject, useCallback, useMemo } from "react";
import { MessageBox, MessageType } from "Component/MessageBox";
import { useModalDialog } from "./useModalDialog";

interface MessageBoxController {
  info: (title: string, message: string) => Promise<boolean>;
  confirm: (title: string, message: string) => Promise<boolean>;
  warning: (title: string, message: string) => Promise<boolean>;
  error: (title: string, message: string) => Promise<boolean>;
}

export const useMessageBox = (ref?: MutableRefObject<Element | null>): MessageBoxController => {
  const showDialog = useModalDialog(ref);

  const showMessageBox = useCallback(
    (type: MessageType, title: string, message: string): Promise<boolean> => {
      return showDialog(MessageBox, {
        type: type,
        title: title,
        message: message,
      });
    },
    [showDialog]
  );

  return useMemo(() => {
    return {
      info: (title: string, message: string) => showMessageBox("info", title, message),
      confirm: (title: string, message: string) => showMessageBox("confirm", title, message),
      warning: (title: string, message: string) => showMessageBox("warning", title, message),
      error: (title: string, message: string) => showMessageBox("error", title, message),
    };
  }, [showMessageBox]);
};
