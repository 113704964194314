import { FormControl, makeStyles, MenuItem, Paper } from "@material-ui/core";
import React, { useMemo } from "react";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { useGenericStyles } from "Common/Utility/Styles";
import { Construction, ConstructionAddress, Site, Survey, User } from "Models/Group";
import { useDirectInput } from "Hooks/useDirectInput";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DateUtility } from "Common/Utility/DateUtility";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import DigitsField, { DigitsWithHyphenField } from "Component/DigitsField";
import TextField from "Component/TextField";
import Select from "Component/Select";
import { MakeOptionalComboItems, BuildingCategoryName } from "Common/Utility/Constants";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";

const BuildingCategoryNameComboItems = MakeOptionalComboItems(BuildingCategoryName);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 200,
  },
}));

interface Props<T> {
  edited: () => void;
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  setEditing: React.Dispatch<React.SetStateAction<InputState>>;
}

export const SiteComponent = React.memo((props: Props<Site>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const directInput = useDirectInput<Site>({ data: props.data, setData: props.setData, edited: props.edited });

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 150,
        label: "築年数",
        dataKey: "buildingYears",
        headerAlign: "center",
        bodyAlign: "right",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              className={genericClasses.width100percent}
              value={directInput.instant.buildingYears}
              onChange={directInput.handleOnChangeNumber("buildingYears")}
              maxLength={2}
            />
          );
        },
      },
      {
        width: 150,
        label: "住所",
        dataKey: "address",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.address}
              onChange={directInput.handleOnChange("address")}
            />
          );
        },
      },
      {
        width: 200,
        label: "TEL",
        dataKey: "tel",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsWithHyphenField
              className={genericClasses.width100percent}
              value={directInput.instant.tel}
              onChange={directInput.handleOnChange("tel")}
            />
          );
        },
      },
      {
        width: 200,
        label: "FAX",
        dataKey: "fax",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsWithHyphenField
              className={genericClasses.width100percent}
              value={directInput.instant.fax}
              onChange={directInput.handleOnChange("fax")}
            />
          );
        },
      }
    );

    return ret;
  }, [directInput, genericClasses.width100percent, isValidEdit]);

  return (
    <Paper className={classes.paper}>
      <VirtualaizedTable
        values={props.data}
        rowHeight={48}
        columns={columns}
        onClickAddDirectInput={isValidEdit ? directInput.handleOnClickAdd : undefined}
        onClickEdit={directInput.handleOnClickEdit}
        onClickDelete={directInput.handleOnClickDelete}
        onClickSave={directInput.handleOnClickSave}
        onClickCancel={directInput.handleOnClickCancel}
        setEditing={props.setEditing}
      />
    </Paper>
  );
});

export const ConstructionAddressComponent = React.memo((props: Props<ConstructionAddress>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const directInput = useDirectInput<ConstructionAddress>({
    data: props.data,
    setData: props.setData,
    edited: props.edited,
  });

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push({
      width: 150,
      label: "現場事務所住所",
      dataKey: "address",
      headerAlign: "center",
      bodyAlign: "left",
      editType: EditType.AllowEdit,
      fit: true,
      componentWhenEditing: (rowIndex: number) => {
        return (
          <TextField
            className={genericClasses.width100percent}
            value={directInput.instant.address}
            onChange={directInput.handleOnChange("address")}
          />
        );
      },
    });

    return ret;
  }, [directInput, genericClasses.width100percent, isValidEdit]);

  return (
    <Paper className={classes.paper}>
      <VirtualaizedTable
        values={props.data}
        rowHeight={48}
        columns={columns}
        onClickAddDirectInput={isValidEdit ? directInput.handleOnClickAdd : undefined}
        onClickEdit={directInput.handleOnClickEdit}
        onClickDelete={directInput.handleOnClickDelete}
        onClickSave={directInput.handleOnClickSave}
        onClickCancel={directInput.handleOnClickCancel}
        setEditing={props.setEditing}
      />
    </Paper>
  );
});

export const UsersComponent = React.memo((props: Props<User>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const directInput = useDirectInput<User>({ data: props.data, setData: props.setData, edited: props.edited });

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 150,
        label: "氏名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.name}
              onChange={directInput.handleOnChange("name")}
            />
          );
        },
      },
      {
        width: 200,
        label: "携帯番号",
        dataKey: "tel",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsWithHyphenField
              className={genericClasses.width100percent}
              value={directInput.instant.tel}
              onChange={directInput.handleOnChange("tel")}
            />
          );
        },
      },
      {
        width: 350,
        label: "メールアドレス",
        dataKey: "email",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.email}
              onChange={directInput.handleOnChange("email")}
            />
          );
        },
      }
    );
    return ret;
  }, [directInput, genericClasses.width100percent, isValidEdit]);

  return (
    <Paper className={classes.paper}>
      <VirtualaizedTable
        values={props.data}
        rowHeight={48}
        columns={columns}
        onClickAddDirectInput={isValidEdit ? directInput.handleOnClickAdd : undefined}
        onClickEdit={directInput.handleOnClickEdit}
        onClickDelete={directInput.handleOnClickDelete}
        onClickSave={directInput.handleOnClickSave}
        onClickCancel={directInput.handleOnClickCancel}
        setEditing={props.setEditing}
      />
    </Paper>
  );
});

export const SurveyComponent = React.memo((props: Props<Survey>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const directInput = useDirectInput<Survey>({
    data: props.data,
    setData: props.setData,
    edited: props.edited,
    validate: (value) => value.date !== "Invalid Date",
  });

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 175,
        label: "調査日時",
        dataKey: "date",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DatePickersUtilsProvider>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                autoOk
                value={directInput.instant.date ?? null}
                onChange={directInput.handleOnChangeDate("date")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </DatePickersUtilsProvider>
          );
        },
      },
      {
        width: 200,
        label: "調査目的",
        dataKey: "purpose",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.purpose}
              onChange={directInput.handleOnChange("purpose")}
            />
          );
        },
      }
    );

    return ret;
  }, [directInput, genericClasses.width100percent, isValidEdit]);

  return (
    <Paper className={classes.paper}>
      <VirtualaizedTable
        values={props.data}
        rowHeight={48}
        columns={columns}
        onClickAddDirectInput={isValidEdit ? directInput.handleOnClickAdd : undefined}
        onClickEdit={directInput.handleOnClickEdit}
        onClickDelete={directInput.handleOnClickDelete}
        onClickSave={directInput.handleOnClickSave}
        onClickCancel={directInput.handleOnClickCancel}
        setEditing={props.setEditing}
      />
    </Paper>
  );
});

export const ConstructionComponent = React.memo((props: Props<Construction>) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const directInput = useDirectInput<Construction>({ data: props.data, setData: props.setData, edited: props.edited });

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 200,
        label: "施主",
        dataKey: "owner",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        fit: true,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.owner}
              onChange={directInput.handleOnChange("owner")}
            />
          );
        },
      },
      {
        width: 120,
        label: "工事種別",
        dataKey: "constructionCategory",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        convert: (data: number) => (BuildingCategoryName[data] ? BuildingCategoryName[data].text : ""),
        componentWhenEditing: (rowIndex: number) => {
          return (
            <FormControl className={genericClasses.width100percent}>
              <Select
                onChange={directInput.handleOnChangeSelect("constructionCategory")}
                value={directInput.instant.constructionCategory}
              >
                {BuildingCategoryNameComboItems.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      },
      {
        width: 120,
        label: "建物種別",
        dataKey: "buildingCategory",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.buildingCategory}
              onChange={directInput.handleOnChange("buildingCategory")}
            />
          );
        },
      },
      {
        width: 120,
        label: "構造",
        dataKey: "structure",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.structure}
              onChange={directInput.handleOnChange("structure")}
            />
          );
        },
      },
      {
        width: 120,
        label: "階数",
        dataKey: "stairs",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.stairs}
              onChange={directInput.handleOnChange("stairs")}
            />
          );
        },
      },
      {
        width: 120,
        label: "延床面積(㎡)",
        dataKey: "grossFloorArea",
        headerAlign: "center",
        bodyAlign: "right",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              type="number"
              className={genericClasses.width100percent}
              value={directInput.instant.grossFloorArea}
              onChange={directInput.handleOnChangeNumber("grossFloorArea")}
            />
          );
        },
      },
      {
        width: 150,
        label: "現場代理人",
        dataKey: "siteAgent",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={directInput.instant.siteAgent}
              onChange={directInput.handleOnChange("siteAgent")}
            />
          );
        },
      }
    );
    return ret;
  }, [directInput, genericClasses.width100percent, isValidEdit]);

  return (
    <Paper className={classes.paper}>
      <VirtualaizedTable
        values={props.data}
        rowHeight={48}
        columns={columns}
        onClickAddDirectInput={isValidEdit ? directInput.handleOnClickAdd : undefined}
        onClickEdit={directInput.handleOnClickEdit}
        onClickDelete={directInput.handleOnClickDelete}
        onClickSave={directInput.handleOnClickSave}
        onClickCancel={directInput.handleOnClickCancel}
        setEditing={props.setEditing}
      />
    </Paper>
  );
});
