import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { connect, IAction } from "Common/Utility/connect";
import { generateUuid, Uuid } from "Common/Utility/GenericInterface";

export const AlertActionTypes = {
  ADD_ALERT: "ADD_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",
  REMOVED_MESSAGE_BY_TIME: "REMOVED_MESSAGE_BY_TIME",
} as const;
type AlertActionTypes = typeof AlertActionTypes[keyof typeof AlertActionTypes];

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
  },
  panel: {
    position: "fixed",
    width: "50%",
    left: "calc((100% - 50%) / 2)",
    top: 5,
    zIndex: 10000,
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export type AlertInfo = {
  uuid?: Uuid;
  type: "success" | "info" | "warning" | "error" | undefined;
  message: string;
};

export const useAlertAdd = () => {
  const alertDispatch = AlertProvider.useDispatch();

  return useCallback(
    (alertInfo: AlertInfo | null) => {
      if (alertInfo != null) {
        alertInfo.uuid = generateUuid();
        alertDispatch({ type: AlertActionTypes.ADD_ALERT, value: alertInfo });

        if (alertInfo.type === "success" || alertInfo.type === "info") {
          setTimeout(() => {
            alertDispatch({ type: AlertActionTypes.REMOVED_MESSAGE_BY_TIME, value: alertInfo });
          }, 3000);
        }
      }
    },
    [alertDispatch]
  );
};

function AlertList() {
  const classes = useStyles();

  const alertInfos = AlertProvider.useGlobalState("alertInfos");

  const alertDispatch = AlertProvider.useDispatch();

  const handleOnClose = useCallback(
    (index: number) => () => {
      alertDispatch({ type: AlertActionTypes.REMOVE_ALERT, value: index });
    },
    [alertDispatch]
  );

  return (
    <>
      <div className={classes.panel}>
        {alertInfos.map((value, index) => {
          return (
            <Alert
              key={index.toString()}
              className={classes.alert}
              severity={value.type}
              onClose={handleOnClose(index)}
            >
              {value.message}
            </Alert>
          );
        })}
      </div>
    </>
  );
}

export default React.memo(AlertList);

const initialState: AlertType = {
  alertInfos: [],
};

interface AlertType {
  alertInfos: AlertInfo[];
}

function reducer(state: AlertType, action: IAction): AlertType {
  switch (action.type) {
    case AlertActionTypes.ADD_ALERT:
      state.alertInfos.push(action.value);
      if (state.alertInfos.length > 3) {
        state.alertInfos.shift();
      }
      return { ...state, alertInfos: state.alertInfos };
    case AlertActionTypes.REMOVE_ALERT:
      if (state.alertInfos.length > 0) {
        state.alertInfos.splice(action.value, 1);
        return { ...state, alertInfos: state.alertInfos };
      } else {
        return state;
      }
    case AlertActionTypes.REMOVED_MESSAGE_BY_TIME:
      var index = state.alertInfos.findIndex((i) => i.uuid === action.value.uuid);
      if (index >= 0) {
        state.alertInfos.splice(index, 1);
        return { ...state, alertInfos: state.alertInfos };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export const AlertProvider = connect<AlertType>(reducer, initialState);
