import React, { useCallback, useMemo, useState } from "react";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import { useContentHeight } from "Hooks/useResize";
import { Design, months } from "Common/Utility/Constants";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { Sales } from "Models/Sales";
import DigitsField from "Component/DigitsField";
import { makeStyles } from "@material-ui/core/styles";
import { ValidateInteger } from "Common/Utility/AppUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { generalComparsion } from "Common/Utility/GenericInterface";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  text: {
    width: 90,
    marginRight: Design.margin,
  },
}));

interface Condition {
  year?: number;

  month?: number;
}

const initialCondition: Condition = {
  year: new Date().getFullYear() - 1,

  month: new Date().getMonth() + 1,
};

export const SalesStatistics = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>({ ...initialCondition });

  const [search, setSearch] = useState<Condition>({ ...initialCondition });

  const [startMonth, setStartMonth] = useState<number>(1);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<Sales[]>([]);

  const alert = useAlertAdd();

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!ValidateInteger(search.year, 1900, 2100)) {
            alert({ type: "info", message: "集計年が不正です。最小1900～最大2100の数字を設定してください。" });
            return;
          }

          if (search.month == null) {
            search.month = 1;
          }

          if (!ValidateInteger(search.month, 1, 12)) {
            alert({ type: "info", message: "集計開始月が不正です。最小1～最大12の数字を設定してください。" });
            return;
          }

          const response = await callWebApi().get<Sales[]>("/statistics/sales", {
            cancelToken: signal.token,
            params: search,
          });

          if (response.data == null) {
            return;
          }

          setStartMonth(search.month ?? 1);
          setData(response.data);
        },
        [search, alert]
      ),
      false
    )
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [
      { dataKey: "companyName", width: 200, label: "得意先名", headerAlign: "center", bodyAlign: "left", fit: true },
    ];

    for (let i = 0; i < 12; ++i) {
      let month = startMonth + i;
      if (month > 12) {
        month -= 12;
      }
      ret.push({
        dataKey: months[month - 1],
        width: 135,
        label: month + "月",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => data.toLocaleString() + " 円",
        sort: (asc: boolean) => {
          setData((value) => {
            value.sort((a, b) => generalComparsion(a[months[month - 1]], b[months[month - 1]]) * (asc ? 1 : -1));
            return [...value];
          });
        },
      });
    }

    return ret;
  }, [startMonth]);

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Condition observer={conditionRef}>
          <>
            <DigitsField
              label="集計年"
              className={classes.text}
              value={condition.year}
              onChange={inputManager.handleOnChangeNumber("year")}
              maxLength={4}
              inputProps={{ style: { textAlign: "right" } }}
            />
            <DigitsField
              label="集計開始月"
              className={classes.text}
              value={condition.month}
              onChange={inputManager.handleOnChangeNumber("month")}
              maxLength={2}
              inputProps={{ style: { textAlign: "right" } }}
            />
            <Button
              className={genericClasses.margin}
              variant="contained"
              color="primary"
              onClick={() => setSearch({ ...condition })}
            >
              検索
            </Button>
            <Button
              className={genericClasses.margin}
              variant="outlined"
              onClick={() => setCondition({ ...initialCondition })}
            >
              クリア
            </Button>
          </>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={data}
              setValues={setData}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
