import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Deposit } from "Models/Deposit";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import Period from "Component/Period";
import { sortWithSet } from "Common/Utility/GenericInterface";
import { useAlertAdd } from "Common/Component/AlertList";
import { useExecute } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useContentHeight } from "Hooks/useResize";
import { useGenericStyles } from "Common/Utility/Styles";
import { MenuIndex } from "Component/Menu";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useMessageBox } from "Hooks/useMessageBox";
import TextField from "Component/TextField";
import { useInputManager } from "Common/Utility/HandleUtility";
import { DepositEditDialog } from "./DepositEditDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface SearchCondition {
  customerName: string | null;
  from: DateTime | null;
  to: DateTime | null;
}

function initialSearchCondition(): SearchCondition {
  return {
    customerName: null,
    from: DateUtility.addDate(DateUtility.now(), 0, -1, 0),
    to: DateUtility.format(DateUtility.now(), "yyyy/MM/dd"),
  };
}

export const ConfirmDeposit = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alerttAdd = useAlertAdd();

  const message = useMessageBox();

  const [data, setData] = useState<Deposit[]>([]);

  const [condition, setCondition] = useState<SearchCondition>(initialSearchCondition());

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<SearchCondition>(initialSearchCondition());

  const [deposit, setDeposit] = useState<Deposit | undefined>(undefined);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useSimpleFetch("/deposit", setData, true, DateUtility.InvalidToNull(search, "from", "to"), [search])
  );

  const handleOnClickAdd = useCallback(() => {
    setDeposit({
      depositDate: null,
      category: 0,
    } as Deposit);
  }, []);

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        await callWebApi().delete(`/deposit/${object.id}`);

        alerttAdd({ type: "success", message: "入金情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alerttAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (deposit: Deposit, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "入金情報を削除します。よろしいですか？")) {
        executeDelete({ id: deposit.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClickEdit = useCallback(
    (data: Deposit, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDeposit(data);
    },
    []
  );

  const handleOnClose = useOnCloseEditDialog("Deposit", setDeposit, setData, "id");

  const isDepositMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Deposit);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Deposit);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isDepositMenuEditValid) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 350,
        label: "得意先名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        width: 175,
        label: "入金日",
        dataKey: "depositDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "入金形式",
        dataKey: "categoryName",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(2),
      },
      {
        width: 150,
        label: "入金額",
        dataKey: "amount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => `${data.toLocaleString()} 円`,
        sort: (asc: boolean) => sortWithSet(data, setData, "amount", asc),
        visible: getVisible(3),
      },
      {
        width: 300,
        label: "備考",
        dataKey: "note",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(4),
      }
    );

    return ret;
  }, [isDepositMenuEditValid, data, setData, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.margin}
                label="得意先名"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <Period
                label="入金日"
                fromValue={condition.from}
                toValue={condition.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialSearchCondition())}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={data}
                setValues={setData}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isDepositMenuEditValid ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {deposit != null && (
        <DepositEditDialog open={true} onClose={handleOnClose} deposit={deposit} editAuth={isDepositMenuEditValid} />
      )}
    </>
  );
});
