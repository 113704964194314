import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from "@material-ui/core";
import VirtualizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { AddBox } from "@material-ui/icons";
import { useAlertAdd } from "Common/Component/AlertList";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { Account } from "Models/Account";
import { RequestPlan, RequestUpdateUser } from "Models/Request";
import { ComboItem } from "Common/Utility/GenericInterface";
import { SelectDialog } from "Component/SelectDialog";
import { useInputManager } from "Common/Utility/HandleUtility";
import { AppProvider } from "App";
import { LabelWithSelect } from "Component/SelectDialog";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  table: {
    height: 200,
  },
}));

interface UpdateUserDialogProps {
  open: boolean;
  updateUserData: RequestUpdateUser;
  accounts: Account[];
  disabled: boolean;
  onClose: (data: RequestUpdateUser | null) => void;
}

export const UpdateUserDialog = (props: UpdateUserDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const licensePlans = AppProvider.useGlobalState("master").licensePlans;

  const [edited, confirm] = useWhetherEdited(props.open);

  const [updateUserData, setUpdateUserData] = useState({ plans: [] as RequestPlan[] } as RequestUpdateUser);

  const [open, setOpen] = useState(false);

  const inputManager = useInputManager(setUpdateUserData, edited);

  const [editIndex, setEditIndex] = useState(-1);

  const filteredPlan = useMemo(
    () =>
      licensePlans?.filter((value) => updateUserData.plans.find((plan) => plan.planId === value.value) === undefined),
    [licensePlans, updateUserData]
  );

  const handleOnClickSaveInAction = () => {
    if (!updateUserData.userGuid) {
      alertAdd({ type: "info", message: "対象ユーザーは必須項目です。" });
      return;
    }

    if (!updateUserData.updatedName) {
      alertAdd({ type: "info", message: "更新後のユーザー名は必須項目です。" });
      return;
    }

    props.onClose(updateUserData);
  };

  const handleOnSelectedUser = (result: Account | null) => {
    if (result == null) {
      return;
    }

    setUpdateUserData((value) => {
      value.plans = [];
      result.plans.forEach((plan) => value.plans.push({ planId: plan.value, planName: plan.text }));
      return { ...value, userGuid: result.userGuid, userName: result.userName, updatedName: "" };
    });

    edited();
  };

  const handleOnCloseSelectPlan = (result: ComboItem | null) => {
    setOpen(false);

    if (result == null) {
      return;
    }

    setUpdateUserData((value) => {
      if (editIndex === -1) {
        value.plans.push({ planId: result.value, planName: result.text });
      } else {
        value.plans[editIndex] = { planId: result.value, planName: result.text };
      }

      return { ...value, plans: [...value.plans] };
    });

    edited();
  };

  const handleOnClickEdit = useCallback((data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setOpen(true);
    setEditIndex(rowIndex);
  }, []);

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setUpdateUserData((value) => {
        value.plans.splice(rowIndex, 1);
        return { ...value, plans: [...value.plans] };
      });
    },
    []
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "ユーザー",
        dataKey: "userName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const planColumns: ColumnData[] = useMemo(() => {
    let ret = [] as ColumnData[];

    if (!props.disabled) {
      ret = [
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
          rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
            return (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={() => {
                  setOpen(true);
                  setEditIndex(-1);
                }}
              >
                <AddBox />
              </IconButton>
            );
          },
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        },
      ];
    }

    return ret.concat([
      {
        width: 150,
        label: "プラン",
        dataKey: "planName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ]);
  }, [props.disabled]);

  const planSelectColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "プラン",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (props.open) {
      setUpdateUserData({ ...props.updateUserData, plans: [...props.updateUserData.plans] });
    }
  }, [props.open, props.updateUserData]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>ユーザー更新情報</DialogTitle>
      <DialogContent>
        <LabelWithSelect
          className={classes.inEdit}
          caption="対象のユーザー"
          text={updateUserData.userName ?? ""}
          data={props.accounts!}
          columns={columns}
          onSelected={handleOnSelectedUser}
          disabled={props.disabled}
        />
        <TextField
          className={classes.inEdit}
          label="更新後のユーザー名"
          value={updateUserData.updatedName ?? ""}
          onChange={inputManager.handleOnChange("updatedName")}
          disabled={props.disabled}
        />
        <Paper className={classes.table}>
          <VirtualizedTable
            rowHeight={48}
            columns={planColumns}
            values={updateUserData.plans}
            onClickEdit={handleOnClickEdit}
            onClickDelete={handleOnClickDelete}
          />
        </Paper>
        <SelectDialog
          open={open}
          title="プラン"
          columns={planSelectColumns}
          data={filteredPlan!}
          onClose={handleOnCloseSelectPlan}
        />
      </DialogContent>
      <DialogActions>
        {props.disabled ? (
          <Button className={genericClasses.margin} onClick={() => props.onClose(null)} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={() => confirm(() => props.onClose(null))}
              color="primary"
            >
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
