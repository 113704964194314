import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { ShipmentPanel } from "./ShipmentPanel";
import ArrivePanel from "./ArrivePanel";
import { ShipmentDetail } from "Models/Shipment";
import { DrawPattern } from "Component/Schedule/Schedule";
import { ShipmentStatus } from "Common/Utility/Constants";

export function checkLendingPeriod(shipmentId: string | undefined, details: ShipmentDetail[]): boolean {
  for (const detail of details) {
    if (detail.assetNumber == null) {
      continue;
    }

    const own = detail.lendingPeriods.find((value) => value.shipmentId === shipmentId);
    if (own != null) {
      const from = new Date(own.from);
      const to = own.to == null ? null : new Date(own.to);

      const result = detail.lendingPeriods.some((i) => {
        if (own === i || i.shipmentStatus !== ShipmentStatus.Finished) {
          return false;
        }

        const period = DrawPattern.excessPeriod(new Date(i.from), i.to == null ? null : new Date(i.to), i.returnAssets);

        if (period == null) {
          return false;
        } else {
          if (to == null) {
            return from <= period.to;
          } else {
            return from <= period.to && to >= period.from;
          }
        }
      });

      if (result) {
        return false;
      }
    }
  }

  return true;
}

const useStyles = makeStyles((theme) => ({
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

const Shipment = () => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <ShipmentPanel />;
      case 1:
        return <ArrivePanel />;
    }
  }, [tabIndex]);

  return (
    <>
      <Paper className={classes.tabArea} square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event: React.ChangeEvent<{}>, index: number) => setTabIndex(index)}
          aria-label="tabs"
        >
          <Tab label="出荷" />
          <Tab label="帰庫" />
        </Tabs>
      </Paper>
      {panel}
    </>
  );
};

export default React.memo(Shipment);
