import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useFetch } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { Request } from "Models/Request";
import { useContentHeight } from "Hooks/useResize";
import { editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { handleOnChangeCheck } from "Common/Utility/HandleUtility";
import { AppProvider } from "App";
import { DateUtility } from "Common/Utility/DateUtility";
import { belongCategory, BelongIndex } from "Common/Utility/Constants";
import { MenuIndex } from "Component/Menu";
import { RequestDialog } from "./RequestDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

export const RequestType = {
  create: 0,
  update: 1,
  delete: 2,
} as const;

export const selectRequestType = [
  { value: RequestType.create, text: "追加" },
  { value: RequestType.update, text: "更新" },
  { value: RequestType.delete, text: "削除" },
];

export const RequestPage = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const [requests, setRequests] = useState([] as Request[]);

  type SearchCondition = {
    supported: boolean;
  };

  const [condition, setCondition] = useState({ supported: false } as SearchCondition);

  const [search, setSearch] = useState({ supported: false } as SearchCondition);

  const [open, setOpen] = useState(false);

  const [openId, setOpenId] = useState<string | null>(null);

  const isOperator = useMemo(
    () => ralmAccount?.goodSeriesInfo.category === belongCategory[BelongIndex.Operating].category,
    [ralmAccount]
  );

  const loadingElement = useLoadingElement(
    "",
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<Request[]>("requests", {
            cancelToken: signal.token,
            params: {
              supported: search.supported,
              isManager: isOperator,
            },
          });

          if (!response.data) {
            return;
          }

          setRequests(response.data);
        },
        [search, isOperator]
      ),
      false
    )
  );

  const handleOnClickEdit = useCallback(
    (data: Request, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setOpen(true);
      setOpenId(data.id);
    },
    []
  );

  const handleOnClickReference = useCallback(
    (data: Request, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setOpen(true);
      setOpenId(data.id);
    },
    []
  );

  const handleOnClose = (result: Request | null) => {
    setOpen(false);

    if (!result) {
      return;
    }

    setRequests((value) => {
      const index = value.findIndex((request) => request.id === result.id);

      if (index === -1) {
        return [...value];
      }

      value[index].supported = result.supported;
      return [...value];
    });
  };

  const handleOnClickAdd = useCallback(() => {
    setOpenId(null);
    setOpen(true);
  }, []);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.User);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.UserRequest
  );

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit && isOperator) {
      ret.push(editButton);
    } else {
      ret.push(referenceButton);
    }

    return ret.concat([
      {
        width: 150,
        label: "依頼日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data),
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "種類",
        dataKey: "type",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (type: number) => selectRequestType[type].text,
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "依頼者",
        dataKey: "createdUserName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 150,
        label: "状態",
        dataKey: "supported",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (supported: boolean) => {
          if (supported) {
            return "対応済み";
          } else {
            return "対応待ち";
          }
        },
        visible: getVisible(3),
      },
    ]);
  }, [isOperator, isValidEdit, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.supported}
                    onChange={handleOnChangeCheck<SearchCondition>("supported", setCondition)}
                    color="primary"
                  />
                }
                label="対応済みを含める"
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ supported: false })}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {loadingElement ?? (
              <VirtualizedTable
                values={requests}
                setValues={setRequests}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit && !isOperator ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickReference}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            )}
          </Paper>
        </Grid>
      </Grid>
      <RequestDialog
        open={open}
        id={openId}
        isOperator={isOperator}
        disabled={isOperator || openId != null || !isValidEdit}
        onClose={handleOnClose}
      />
    </>
  );
};
