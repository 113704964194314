import { LineupSummary } from "Models/Lineup";
import { makeStyles } from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { LabelWithSelect } from "Component/SelectDialog";
import React, { ChangeEvent, useMemo, useState } from "react";
import { EstimateDetail } from "Models/Estimate";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { ModelMaster } from "Models/ModelMaster";
import { AssetMaster } from "Models/Asset";
import { modelColumns } from "Common/Utility/Constants";
import { InputManagerResult } from "Common/Utility/HandleUtility";

const useStyles = makeStyles((theme) => ({
  selectItem: {
    width: 300,
    margin: theme.spacing(1),
    borderBottom: "solid 1px #949494",
  },
}));

const selectAssetDialogColumns: ColumnData[] = [
  {
    width: 200,
    label: "型式",
    dataKey: "model",
    headerAlign: "center",
    bodyAlign: "left",
  },
  {
    width: 300,
    label: "名称",
    dataKey: "name",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
  {
    width: 200,
    label: "資産番号",
    dataKey: "assetNumber",
    headerAlign: "center",
    bodyAlign: "center",
  },
];

const selectLineupDialogColumns: ColumnData[] = [
  {
    width: 200,
    label: "商品名",
    dataKey: "name",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
  {
    width: 100,
    label: "型式数",
    headerAlign: "center",
    bodyAlign: "right",
    convert: (data: any, rowData: LineupSummary) => rowData.details.length.toString(),
  },
  {
    width: 150,
    label: "総原価",
    headerAlign: "center",
    bodyAlign: "right",
    convert: (data: any, rowData: LineupSummary) =>
      rowData.details.reduce((prev, current) => prev + current.cost, 0).toLocaleString() + " 円",
  },
];

type ItemSelectType = "model" | "asset" | "lineup";

interface Props {
  models: ModelMaster[];
  assets: AssetMaster[];
  lineupSummaries: LineupSummary[];
  editable: boolean;
  detail: EstimateDetail;
  inputManager: InputManagerResult<EstimateDetail>;
}

export const SelectedItem = React.memo((props: Props): JSX.Element => {
  const classes = useStyles();

  const [itemSelectType, setItemSelectType] = useState<ItemSelectType>("model");

  const item = useMemo(() => {
    const mode: {
      caption: string;
      columns: ColumnData[];
      onClickNarrowDown: ((text: string) => any[]) | undefined;
      data: any[];
    } = { caption: "", columns: [], onClickNarrowDown: undefined, data: [] };

    switch (itemSelectType) {
      case "model":
        mode.caption = "型式";
        mode.columns = modelColumns;
        mode.onClickNarrowDown = undefined;
        mode.data = props.models;
        break;
      case "asset":
        mode.caption = "資産";
        mode.columns = selectAssetDialogColumns;
        mode.onClickNarrowDown = undefined;
        mode.data = props.assets;
        break;
      case "lineup":
        mode.caption = "商品";
        mode.columns = selectLineupDialogColumns;
        mode.onClickNarrowDown = undefined;
        mode.data = props.lineupSummaries;
        break;
    }
    return (
      <LabelWithSelect
        className={classes.selectItem}
        caption={mode.caption}
        text={props.detail.name ?? ""}
        data={mode.data}
        columns={mode.columns}
        onClickNarrowDown={mode.onClickNarrowDown}
        onSelected={props.inputManager.handleOnChangeLabelWithSelect((value, result) => {
          return {
            ...value,
            name: result.name,
          };
        })}
        maxWidth="sm"
        disabled={!props.editable}
      />
    );
  }, [
    classes.selectItem,
    itemSelectType,
    props.assets,
    props.detail.name,
    props.editable,
    props.inputManager,
    props.lineupSummaries,
    props.models,
  ]);

  const handleOnChangeItemSelectType = (event: ChangeEvent<HTMLInputElement>) => {
    setItemSelectType(event.target.value as ItemSelectType);
  };

  return (
    <>
      <FormControl component="fieldset" disabled={!props.editable}>
        <FormLabel component="legend">商品選択方式</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={itemSelectType}
          onChange={handleOnChangeItemSelectType}
        >
          <FormControlLabel value="model" control={<Radio />} label="型式" />
          <FormControlLabel value="asset" control={<Radio />} label="資産" />
          <FormControlLabel value="lineup" control={<Radio />} label="商品" />
        </RadioGroup>
      </FormControl>
      {item}
    </>
  );
});
