import React, { MutableRefObject, useCallback } from "react";
import ReactDOM from "react-dom";

// FIXME: ShowDialogのcomponentはonCloseプロパティを持つコンポーネントのみを受け取るようにしたい
export interface DialogComponentRequiredProps {
  onClose: (result?: any) => void;
}

type ShowDialog = <T>(component: any, props: any) => Promise<T>;

export const useModalDialog = (ref?: MutableRefObject<Element | null>): ShowDialog => {
  const getParentElement = useCallback(() => {
    return ref?.current ?? document.querySelector("body")!;
  }, [ref]);

  const showDialog = useCallback(
    <T,>(Component: any, props: any): Promise<T> => {
      const dialogContainer = document.createElement("div");
      const parentElement = getParentElement();
      parentElement.appendChild(dialogContainer);

      return new Promise<T>((resolve, reject) => {
        const handleClose = (result: T): void => {
          parentElement.removeChild(dialogContainer);
          ReactDOM.unmountComponentAtNode(dialogContainer);
          resolve(result);
        };
        ReactDOM.render(<Component onClose={handleClose} {...props} />, dialogContainer);
      });
    },
    [getParentElement]
  );

  return showDialog;
};
