import React, { useState, useCallback } from "react";
import { BusinessInfo } from "Models/BusinessInfo";
import { IAction, connect } from "Common/Utility/connect";
import { ComboItem } from "Common/Utility/GenericInterface";
import BusinessDetail from "./BusinessDetail";
import BusinessSummary from "./BusinessSummary";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { makeStyles } from "@material-ui/core";
import { useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import clsx from "clsx";
import { useContentHeight } from "Hooks/useResize";
import { ComboItemCustomerEx } from "Models/Voucher";

export enum ActionTypes {
  SET_CUSTOMER = "SET_CUSTOMER",
  SET_USERS = "SET_USERS",
}

const useStyles = makeStyles((theme) => ({
  searchLoading: {
    height: (props: any) => props.height,
  },
  hidden: {
    display: "none",
  },
  show: {},
}));

const Business = () => {
  const [height] = useContentHeight();

  const classes = useStyles({ height: height });

  const [summaries, setSummaries] = useState<BusinessInfo[]>([]);

  const [detail, setDetail] = useState<BusinessInfo | null>(null);

  const dispatch = BusinessProvider.useDispatch();

  const loadingCustomerElement = useLoadingElement(
    classes.searchLoading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          var response = await callWebApi().get<ComboItem[]>("/companies/customer", { cancelToken: signal.token });
          dispatch({ type: ActionTypes.SET_CUSTOMER, value: response.data });
        },
        [dispatch]
      )
    ),
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          var response = await callWebApi().get<ComboItem[]>("/users/usermaster", { cancelToken: signal.token });
          dispatch({ type: ActionTypes.SET_USERS, value: response.data });
        },
        [dispatch]
      )
    )
  );

  return (
    <>
      {loadingCustomerElement ?? (
        <>
          {detail && <BusinessDetail setSummaries={setSummaries} detail={detail} setDetail={setDetail} />}
          <div className={clsx(detail ? classes.hidden : classes.show)}>
            <BusinessSummary summaries={summaries} setSummaries={setSummaries} setDetail={setDetail} />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(Business);

const initialState: BusinessType = {
  customer: [],
  users: [],
};

interface BusinessType {
  customer: ComboItemCustomerEx[];
  users: ComboItem[];
}

function reducer(state: BusinessType, action: IAction): BusinessType {
  switch (action.type) {
    case ActionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: action.value,
      };
    case ActionTypes.SET_USERS:
      return {
        ...state,
        users: action.value,
      };
    default:
      return state;
  }
}

export const BusinessProvider = connect<BusinessType>(reducer, initialState);
