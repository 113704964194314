import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useFetch, useExecute, useExecuteEx } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { AxiosResponse, CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import { useContentHeight } from "Hooks/useResize";
import { Role, EditRoleType, BasicRoleAuth, Auth } from "Models/Role";
import { MenuIndex, menuInfos } from "Component/Menu";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { DateUtility } from "Common/Utility/DateUtility";
import { ComboItem } from "Common/Utility/GenericInterface";
import clsx from "clsx";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { getEditAuthInfos } from "Common/Utility/Role";
import TextField from "Component/TextField";
import { RestrictionModel } from "Models/Restriction";
import { validateResponse } from "Common/Utility/HttpUtility";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  editPaper: {
    width: "100%",
    height: 500,
  },
}));

interface DialogProps {
  open: boolean;
  data: Role;
  restriction: RestrictionModel;
  onClose: (role: Role | null) => void;
}

const EditDialog = (props: DialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState({} as Role);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Role);

  const [edited, confirm] = useWhetherEdited(props.open, isValidEdit);

  const menuAuthData = useMemo(() => {
    const auth = [] as BasicRoleAuth[];

    if (data.menuReferenceAuth == null || data.menuEditAuth == null) {
      return auth;
    }

    menuInfos.forEach((i) => {
      const info = i;

      if (i.administratorsOnly) {
        return;
      }

      auth.push({
        name: info.menu,
        bitIndex: info.bit,
        type: "menuEditAuth",
        isReference: Boolean(data.menuReferenceAuth & info.bit & props.restriction.menuReferenceAuth),
        isEdit: Boolean(data.menuEditAuth & info.bit & props.restriction.menuEditAuth),
        referenceOnly: i.referenceOnly,
      } as BasicRoleAuth);
    });

    return auth;
  }, [data.menuReferenceAuth, data.menuEditAuth, props.restriction]);

  const createEditAuth = useCallback(
    (type: EditRoleType, target: number) => {
      const auth = [] as BasicRoleAuth[];

      if (target == null) {
        return auth;
      }

      Object.values(getEditAuthInfos(type)).forEach((info) => {
        auth.push({
          name: info.text,
          bitIndex: info.bit,
          type: type,
          isEdit: Boolean(target & info.bit & props.restriction[type]),
        } as BasicRoleAuth);
      });

      return auth;
    },
    [props.restriction]
  );

  const estimateAuthData = useMemo(
    () => createEditAuth("estimateEditAuth", data.estimateEditAuth),
    [createEditAuth, data]
  );

  const voucherAuthData = useMemo(
    () => createEditAuth("voucherEditAuth", data.voucherEditAuth),
    [createEditAuth, data]
  );

  const companyAuthData = useMemo(
    () => createEditAuth("companyEditAuth", data.companyEditAuth),
    [createEditAuth, data]
  );

  const systemAuthData = useMemo(() => createEditAuth("systemAuth", data.systemAuth), [createEditAuth, data]);

  const handleOnClose = useCallback(() => confirm(() => props.onClose(null)), [props, confirm]);

  const handleOnClickCancelInAction = useCallback(() => confirm(() => props.onClose(null)), [props, confirm]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: Role; onClose: (role: Role | null) => void }) => {
        var response: AxiosResponse<Role>;
        if (object.data.id == null) {
          response = await callWebApi().post<Role>("/roles", object.data);
        } else {
          response = await callWebApi().put<Role>("/roles", object.data);
        }

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "ロール情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    if (!data.name) {
      alertAdd({ type: "info", message: "名前は必須項目です。" });
      return;
    }

    executePut({ data: data, onClose: props.onClose });
  }, [props.onClose, alertAdd, data, executePut]);

  const handleOnChangeName = useCallback(
    (event) => {
      const target = event.target.value;
      setData((value) => {
        return { ...value, name: target };
      });
      edited();
    },
    [edited]
  );

  const handleOnChangeAuthCategory = useCallback((event) => {
    setSelectedIndex(event.target.value);
  }, []);

  const changeAuth = useCallback(
    (key: keyof Auth, bit: number, beforeUpdate: (value: Role) => void = () => {}) => {
      setData((value) => {
        beforeUpdate(value);

        return {
          ...value,
          [key]: props.restriction[key] & (value[key] ^ bit),
        };
      });
    },
    [props.restriction]
  );

  const handleOnChangeReferenceAuth = useCallback(
    (data: BasicRoleAuth) => {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (data.type === "menuEditAuth") {
          changeAuth("menuReferenceAuth", data.bitIndex);
        }

        edited();
      };
    },
    [edited, changeAuth]
  );

  const handleOnChangeEditAuth = useCallback(
    (data: BasicRoleAuth) => {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (data.type === "menuEditAuth") {
          changeAuth("menuEditAuth", data.bitIndex, (value) => {
            if (checked) {
              value.menuReferenceAuth |= data.bitIndex;
            }
          });
        } else {
          changeAuth(data.type, data.bitIndex);
        }

        edited();
      };
    },
    [edited, changeAuth]
  );

  useEffect(() => {
    if (props.open) {
      setData(props.data);
      setSelectedIndex(0);
    }
  }, [props.open, props.data]);

  const basicColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 150,
        label: "表示権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
              <Checkbox
                checked={data.isReference}
                color="primary"
                onChange={handleOnChangeReferenceAuth(data)}
                disabled={data.isEdit || (props.restriction.menuReferenceAuth & data.bitIndex) === 0}
              />
            </Box>
          );
        },
      },
      {
        width: 150,
        label: "編集権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              {!(data.referenceOnly ?? false) && (
                <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
                  <Checkbox
                    checked={data.isEdit}
                    color="primary"
                    onChange={handleOnChangeEditAuth(data)}
                    disabled={(props.restriction.menuEditAuth & data.bitIndex) === 0}
                  />
                </Box>
              )}
            </>
          );
        },
      },
    ],
    [
      genericClasses.width100percent,
      genericClasses.textAlignCenter,
      handleOnChangeReferenceAuth,
      handleOnChangeEditAuth,
      props.restriction.menuEditAuth,
      props.restriction.menuReferenceAuth,
    ]
  );

  const authColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
      {
        width: 150,
        label: "権限",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: BasicRoleAuth, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return (
            <>
              {
                <Box className={clsx([genericClasses.width100percent, genericClasses.textAlignCenter])}>
                  <Checkbox
                    checked={data.isEdit}
                    color="primary"
                    onChange={handleOnChangeEditAuth(data)}
                    disabled={(props.restriction[data.type] & data.bitIndex) === 0}
                  />
                </Box>
              }
            </>
          );
        },
      },
    ],
    [genericClasses.width100percent, genericClasses.textAlignCenter, handleOnChangeEditAuth, props.restriction]
  );

  const select = useMemo(() => {
    return [
      { text: "メニュー", value: { data: menuAuthData, columns: basicColumns } },
      { text: "見積管理", value: { data: estimateAuthData, columns: authColumns } },
      { text: "伝票管理", value: { data: voucherAuthData, columns: authColumns } },
      { text: "企業管理", value: { data: companyAuthData, columns: authColumns } },
      { text: "システム", value: { data: systemAuthData, columns: authColumns } },
    ] as ComboItem[];
  }, [menuAuthData, basicColumns, authColumns, estimateAuthData, voucherAuthData, companyAuthData, systemAuthData]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>ロール情報</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              className={classes.inEdit}
              label="名前"
              value={data.name}
              onChange={handleOnChangeName}
              disabled={!isValidEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inEdit}>
              <InputLabel id="input-auth_category">分類</InputLabel>
              <Select id="select-auth_category" value={selectedIndex} onChange={handleOnChangeAuthCategory}>
                {select.map((value, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {value.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.editPaper}>
              {selectedIndex !== -1 && (
                <VirtualizedTable
                  values={select[selectedIndex].value.data}
                  rowHeight={48}
                  columns={select[selectedIndex].value.columns}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isValidEdit ? (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              閉じる
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const RoleManager = () => {
  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [data, setData] = useState([] as Role[]);

  const [name, setName] = useState("");

  const [search, setSearch] = useState({
    name: "",
  });

  const [open, setOpen] = useState(false);

  const [editData, setEditData] = useState({} as Role);

  const [restriction, setRestriction] = useState<RestrictionModel>({} as RestrictionModel);

  const fetchRestriction = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      const response = await callWebApi().get<RestrictionModel>("/restrictions/tenantid", {
        cancelToken: signal.token,
      });

      if (response.data == null) {
        return;
      }

      setRestriction(response.data);
    }, [])
  );

  const fetchResultSearch = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Role[]>("/roles", {
          cancelToken: signal.token,
          params: { name: search.name },
        });

        if (response.data == null) {
          return;
        }

        setData(response.data);
      },
      [search]
    ),
    false
  );

  const handleOnClickSearch = useCallback(() => {
    setSearch({ name: name });
  }, [name]);

  const handleOnClickClear = useCallback(() => {
    setName("");
  }, []);

  const hanldeOnChangeName = useCallback((event) => setName(event.target.value), []);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      id: null,
      menuReferenceAuth: 0,
      menuEditAuth: 0,
      estimateEditAuth: 0,
      voucherEditAuth: 0,
      companyEditAuth: 0,
      systemAuth: 0,
    } as Role);
    setOpen(true);
  }, []);

  const handleOnClickEdit = useCallback((role: Role, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setEditData({ ...role });
    setOpen(true);
  }, []);

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        await callWebApi().delete("/roles", {
          params: {
            id: object.id,
          },
        });

        alertAdd({ type: "success", message: "ロール情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (role: Role, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "ロール情報を削除します、よろしいですか？")) {
        executeDelete({ id: role.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose = useCallback((role: Role | null) => {
    setOpen(false);

    if (role != null && role.id != null) {
      setData((value) => {
        const index = value.findIndex((i) => i.id === role.id);

        if (index === -1) {
          return value;
        }

        value[index] = role;
        return [...value];
      });
    }
  }, []);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Role);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Role);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 250,
        label: "名前",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 200,
        label: "作成日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm"),
        visible: getVisible(1),
      },
      {
        width: 300,
        label: "作成者",
        dataKey: "createdBy",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "編集日",
        dataKey: "updatedAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd HH:mm"),
        visible: getVisible(3),
      },
      {
        width: 300,
        label: "編集者",
        dataKey: "updatedBy",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      }
    );

    return ret;
  }, [isValidEdit, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField className={genericClasses.margin} label="名前" value={name} onChange={hanldeOnChangeName} />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
              <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.loading, LoadingMode.Circular, fetchRestriction, fetchResultSearch) ?? (
            <>
              <Paper className={classes.paper}>
                <VirtualizedTable
                  values={data}
                  setValues={setData}
                  tableHeight={height}
                  rowHeight={48}
                  columns={columns}
                  onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                  onClickEdit={handleOnClickEdit}
                  onClickReference={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                  onCloseContextMenu={handleOnCloseContextMenu}
                  onChangeHeaderVisible={handleOnChangeHeaderVisible}
                  headerContext
                />
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
      <EditDialog open={open} data={editData} restriction={restriction} onClose={handleOnClose} />
    </>
  );
};

export default React.memo(RoleManager);
