import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useGenericStyles } from "Common/Utility/Styles";
import { useFetch } from "Hooks/useFetch";
import { callWebApi } from "Common/Utility/Api";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { AssetMaster } from "Models/Asset";
import SelectAssetSchedule from "./SelectAssetSchedule";
import { ShipmentDetail } from "Models/Shipment";
import { useResizeObserver } from "Hooks/useResize";
import { Design } from "Common/Utility/Constants";
import { modifyLendingPeriod } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  schedule: {
    width: "100%",
    height: (props: any) => props.height - (Design.dialogTopHeight + Design.dialogBottomHeight + Design.unknownGap),
    minHeight: Design.scheduleMinHeight,
  },
}));

interface Props {
  open: boolean;

  target?: { vPositionIndex: number; modelMasterId: string };

  onClose: (result?: { vPositionIndex: number; assetMaster: AssetMaster }) => void;

  exclusion: ShipmentDetail[];
}

const SelectAssetDialog = (props: Props) => {
  const [rect, resizeRef] = useResizeObserver();

  const classes = useStyles({ height: rect.height });

  const genericClasses = useGenericStyles();

  const [assets, setAssets] = useState<AssetMaster[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<AssetMaster | null>(null);

  const loadingElement = useLoadingElement(
    classes.schedule,
    LoadingMode.Simple,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (props.target === undefined) {
            return;
          }

          const response = await callWebApi().get<AssetMaster[]>(
            `/assetmaster/model-id/${props.target?.modelMasterId}`,
            {
              cancelToken: signal.token,
            }
          );

          setAssets((value) => {
            const ret = response.data.filter((i) => !props.exclusion.some((value) => value.id === i.id));
            modifyLendingPeriod(ret);
            return ret;
          });
        },
        [props.target, props.exclusion]
      )
    )
  );

  const handleOnSelected = useCallback(
    (asset: AssetMaster) => {
      if (props.target) {
        props.onClose({ vPositionIndex: props.target.vPositionIndex, assetMaster: asset });
      }
    },
    [props]
  );

  useEffect(() => {
    if (props.open) {
      setSelectedAsset(null);
    }
  }, [props.open]);

  return (
    <Dialog ref={resizeRef} onClose={() => props.onClose()} open={props.open} fullWidth={true} maxWidth="xl">
      <DialogTitle>貸出資産の選択</DialogTitle>
      <DialogContent>
        {loadingElement ?? (
          <SelectAssetSchedule
            className={classes.schedule}
            rows={assets}
            setRows={setAssets}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            onSelected={handleOnSelected}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={() => props.onClose()} color="primary">
          閉じる
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={() => {
            if (props.target && selectedAsset != null) {
              props.onClose({ vPositionIndex: props.target.vPositionIndex, assetMaster: selectedAsset });
            } else {
              props.onClose();
            }
          }}
          color="primary"
          disabled={selectedAsset == null}
        >
          選択
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SelectAssetDialog);
