import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  IconButton,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Typography,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {
  Autorenew as IconRefresh,
  AttachFile as IconAttachFile,
  AddBox as IconAddFile,
  Edit as IconEdit,
  Done as IconDone,
  CancelOutlined as IconCancel,
  Delete as IconDelete,
  GetApp as IconDownload,
} from "@material-ui/icons";
import { useMessageBox } from "Hooks/useMessageBox";
import NewId from "Common/Utility/NewId";
import { AttachmentFile as AttachmentFileModel } from "Models/AttachmentFile";
import { callWebApi } from "Common/Utility/Api";
import { CancelEventArgs } from "Common/Utility/GenericInterface";
import { useAlertAdd } from "Common/Component/AlertList";
import { CancelTokenSource } from "axios";
import { useFetch, useExecute } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useGenericStyles } from "Common/Utility/Styles";
import { validateResponse } from "Common/Utility/HttpUtility";
import { AttachmentFileCategoryItems, toComboText } from "Common/Utility/Constants";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  category: {
    width: "100%",
    textAlign: "center",
  },
  cell: {
    padding: "0px 16px 0px 16px",
  },
  loading: {
    height: 340,
  },
}));

interface AttachmentFIleTableRowProps {
  index: number;
  attachmentFile: AttachmentFileModel;
  isEditing: boolean;
  isDelete: boolean;
  onBeginEdit: (index: number) => void;
  onApplyEdit: (index: number, category: number) => void;
  onCancelEdit: (index: number) => void;
  onDelete: (index: number) => void;
  onDownload: (index: number) => void;
  category: boolean;
  editable: boolean;
}

function AttachmentFileTableRow(props: AttachmentFIleTableRowProps) {
  const attachmentFile = props.attachmentFile;

  const [editCategory, setEditCategory] = useState<string>("");
  const classes = useStyles();
  const genericClasses = useGenericStyles();
  const actionCellWidth = props.editable ? 200 : 140;

  function handleBeginEdit() {
    setEditCategory(String(attachmentFile.category));
    props.onBeginEdit(props.index);
  }

  function handleApplyEdit() {
    props.onApplyEdit(props.index, Number(editCategory));
  }

  function handleCancelEdit() {
    props.onCancelEdit(props.index);
  }

  function handleDelete() {
    props.onDelete(props.index);
  }

  function handleDownload() {
    props.onDownload(props.index);
  }

  if (props.isEditing) {
    return (
      <TableRow>
        <TableCell width={30} className={classes.cell}>
          <IconAttachFile />
        </TableCell>
        <TableCell className={classes.cell}>{attachmentFile.name}</TableCell>
        {props.category && (
          <TableCell className={classes.cell}>
            <TextField
              required
              select
              id="category"
              value={editCategory}
              onChange={(e) => setEditCategory(e.target.value)}
              className={classes.category}
            >
              {AttachmentFileCategoryItems.map((value, index) => (
                <MenuItem key={index} value={value.value}>
                  {value.text}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        )}
        <TableCell align="right" className={classes.cell} width={actionCellWidth}>
          <IconButton
            color="primary"
            title="確定"
            aria-label="確定"
            onClick={handleApplyEdit}
            className={genericClasses.marginRight}
          >
            <IconDone />
          </IconButton>
          <IconButton
            color="secondary"
            title="キャンセル"
            aria-label="キャンセル"
            onClick={handleCancelEdit}
            className={genericClasses.marginRight}
          >
            <IconCancel />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell width={30} className={classes.cell}>
          <IconAttachFile />
        </TableCell>
        <TableCell className={classes.cell}>{attachmentFile.name}</TableCell>
        {props.category && (
          <TableCell className={classes.cell}>
            <Typography className={classes.category}>
              {toComboText(AttachmentFileCategoryItems, attachmentFile.category)}
            </Typography>
          </TableCell>
        )}
        <TableCell align="right" className={classes.cell} width={actionCellWidth}>
          <Tooltip title="ダウンロード">
            <IconButton
              color="primary"
              aria-label="ダウンロード"
              onClick={handleDownload}
              className={genericClasses.marginRight}
            >
              <IconDownload />
            </IconButton>
          </Tooltip>
          {props.editable && (
            <Tooltip title="編集">
              <IconButton
                color="primary"
                aria-label="編集"
                onClick={handleBeginEdit}
                className={genericClasses.marginRight}
              >
                <IconEdit />
              </IconButton>
            </Tooltip>
          )}
          {props.isDelete && (
            <Tooltip title="削除">
              <IconButton color="secondary" onClick={handleDelete} className={genericClasses.marginRight}>
                <IconDelete />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

interface Props {
  attachedId: string | null;
  category: boolean;
  editable: boolean;
  destination: number;
  maxHeight?: number;
  isAdd: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  hold: boolean;
  onUploading: (args: CancelEventArgs) => void;
  onDeleting: (args: CancelEventArgs) => void;
  setOnSave?: React.Dispatch<React.SetStateAction<{ func: (attachedId: string) => void }>>;
  edited: () => void;
}

AttachmentFileList.defaultProps = {
  destination: 0,
  isAdd: true,
  isUpdate: true,
  isDelete: true,
  hold: false,
  onUploading: (args: CancelEventArgs) => {},
  onDeleting: (args: CancelEventArgs) => {},
  edited: () => {},
};

const ProcessType = {
  none: undefined,
  upload: 0,
  delete: 1,
};

export function AttachmentFileList(props: Props) {
  const [attachmentFiles, setAttachmentFiles] = useState<AttachmentFileModel[]>([]);
  const [fileInputElementId] = useState<string>(NewId("raised-button-file"));
  const fileInput = useRef<HTMLInputElement>(null);
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const [editIndex, setEditIndex] = useState<number | undefined>();

  const messageBox = useMessageBox();
  const classes = useStyles();
  const genericClasses = useGenericStyles();
  const alertAdd = useAlertAdd();

  async function handleRefresh() {
    fetchResultLoad.reload();
  }

  function handleBeginEdit(index: number) {
    setEditIndex(index);
  }

  async function handleCancelEdit(index: number) {
    setEditIndex(undefined);
  }

  const executeApplayEdit = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          attachmentFiles: AttachmentFileModel[];
          index: number;
          category: number;
          reload: () => void;
          destination: number;
        }
      ) => {
        const attachmentFile = object.attachmentFiles[object.index];

        await callWebApi(object.destination).put("/attachmentfile", {
          id: attachmentFile.id,
          category: object.category,
        });

        if (unmounted.value) {
          return;
        }

        setEditIndex(undefined);

        object.reload();
      },
      []
    )
  );

  async function handleApplyEdit(index: number, category: number) {
    executeApplayEdit({
      attachmentFiles: attachmentFiles,
      index: index,
      category: category,
      reload: fetchResultLoad.reload,
      destination: props.destination,
    });
  }

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { destination: number; id: string; reload: () => void }) => {
        const response = await callWebApi(object.destination).delete("/attachmentfile", {
          params: {
            id: object.id,
          },
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (unmounted.value) {
          return;
        }

        object.reload();
      },
      [alertAdd]
    )
  );

  async function handleDelete(index: number) {
    const attachmentFile = attachmentFiles[index];
    const message = ` ${attachmentFile.name} を削除しますか？`;
    if ((await messageBox.confirm("添付ファイル削除確認", message)) === false) {
      return;
    }

    var args: CancelEventArgs = new CancelEventArgs();
    props.onDeleting(args);

    if (args.cancel) {
      return;
    }

    if (props.hold) {
      setAttachmentFiles((value) => {
        if (value[index].fileData) {
          value[index].processType = ProcessType.none;
        } else {
          value[index].processType = ProcessType.delete;
        }
        return [...value];
      });
    } else {
      executeDelete({ destination: props.destination, id: attachmentFile.id, reload: fetchResultLoad.reload });
    }

    props.edited();
  }

  const executeDownload = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { destination: number; attachmentFile: AttachmentFileModel }) => {
        const response = await callWebApi(object.destination).get("/attachmentfile/download", {
          params: {
            id: object.attachmentFile.id,
          },
          responseType: "blob",
        });

        if (unmounted.value) {
          return;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (downloadLink.current) {
          downloadLink.current.href = url;
          downloadLink.current.setAttribute("download", object.attachmentFile.name);
          downloadLink.current.click();
        } else {
          alertAdd({ type: "warning", message: "ダウンロードに失敗しました。" });
        }
      },
      [alertAdd]
    )
  );

  async function handleDownload(index: number) {
    const attachmentFile = attachmentFiles[index];

    if (attachmentFile.fileData) {
      const url = window.URL.createObjectURL(attachmentFile.fileData);
      if (downloadLink.current) {
        downloadLink.current.href = url;
        downloadLink.current.setAttribute("download", attachmentFile.name);
        downloadLink.current.click();
      } else {
        alertAdd({ type: "warning", message: "ダウンロードに失敗しました。" });
      }
    } else {
      executeDownload({ destination: props.destination, attachmentFile: attachmentFile });
    }
  }

  const executeFilesSelect = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          destination: number;
          attachedId: string;
          fileList?: FileList | undefined;
          files?: File[] | undefined;
          reload: () => void;
        }
      ) => {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const upload = async (file: File | null) => {
          if (!file) {
            return;
          }

          const formData = new FormData();
          formData.append("attachedId", object.attachedId);
          formData.append("file", file);
          await callWebApi(object.destination).post("/attachmentfile", formData, config);
        };

        if (object.fileList) {
          for (let i = 0; i < object.fileList.length; i++) {
            await upload(object.fileList.item(i));
          }
        }

        if (object.files) {
          for (let i = 0; i < object.files.length; i++) {
            await upload(object.files[i]);
          }
        }

        if (unmounted.value) {
          return;
        }

        if (fileInput.current !== null) {
          fileInput.current.value = "";
        }

        await object.reload();
      },
      []
    )
  );

  async function handleFilesSelect(event: React.ChangeEvent<HTMLInputElement>) {
    var fileList = event.currentTarget.files;
    if (!fileList || fileList.length === 0) {
      return;
    }

    var args: CancelEventArgs = new CancelEventArgs();
    props.onUploading(args);

    if (args.cancel) {
      return;
    }

    if (props.hold) {
      setAttachmentFiles((value) => {
        if (!fileList) {
          return { ...value };
        }

        for (let i = 0; i < fileList.length; i++) {
          value.push({
            id: "",
            attachedId: props.attachedId ?? "",
            name: fileList[i].name,
            category: 0,
            physicalPath: "",
            fileData: fileList[i],
            processType: ProcessType.upload,
          });
        }

        return [...value];
      });

      if (fileInput.current !== null) {
        fileInput.current.value = "";
      }
    } else if (props.attachedId != null) {
      executeFilesSelect({
        destination: props.destination,
        attachedId: props.attachedId,
        fileList: fileList,
        reload: fetchResultLoad.reload,
      });
    }

    props.edited();
  }

  const load = useCallback(
    async (signal: CancelTokenSource) => {
      if (!props.attachedId) {
        return;
      }

      const response = await callWebApi(props.destination).get<AttachmentFileModel[]>("/attachmentfile", {
        cancelToken: signal.token,
        params: {
          attachedId: props.attachedId,
        },
      });

      setAttachmentFiles(response.data);
    },
    [props.attachedId, props.destination]
  );

  const fetchResultLoad = useFetch(load);

  useEffect(() => {
    props.setOnSave?.({
      func: (attachedId: string) => {
        const uploadList = attachmentFiles
          .filter((value) => value.processType === ProcessType.upload && value.fileData)
          .map((value) => value.fileData) as File[];

        if (uploadList.length > 0) {
          executeFilesSelect({
            destination: props.destination,
            attachedId: attachedId,
            files: uploadList,
            reload: fetchResultLoad.reload,
          });
        }

        attachmentFiles
          .filter((value) => value.processType === ProcessType.delete)
          .forEach((value) => {
            executeDelete({ destination: props.destination, id: value.id, reload: fetchResultLoad.reload });
          });
      },
    });

    return () => {
      props.setOnSave?.({ func: () => {} });
    };
  }, [props.destination, props.setOnSave, fetchResultLoad, attachmentFiles, executeFilesSelect, executeDelete]);

  return (
    <>
      {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultLoad) ?? (
        <>
          <input
            accept="*/*"
            style={{ display: "none" }}
            id={fileInputElementId}
            multiple
            type="file"
            onChange={handleFilesSelect}
            ref={fileInput}
          />

          <a href="#dummy" style={{ display: "none" }} ref={downloadLink}>
            DownloadLink
          </a>

          <TableContainer style={{ maxHeight: props.maxHeight }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} className={classes.cell}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      {props.isAdd && (
                        <label htmlFor={fileInputElementId}>
                          <Tooltip title="アップロード">
                            <IconButton
                              edge="end"
                              color="primary"
                              aria-label="添付ファイル追加"
                              component="span"
                              className={genericClasses.marginRight}
                            >
                              <IconAddFile />
                            </IconButton>
                          </Tooltip>
                        </label>
                      )}
                      {props.isUpdate && (
                        <IconButton
                          edge="end"
                          color="primary"
                          aria-label="refresh"
                          onClick={handleRefresh}
                          className={genericClasses.marginRight}
                          title="更新"
                        >
                          <IconRefresh />
                        </IconButton>
                      )}
                      <Typography className={genericClasses.marginRight}>添付ファイル</Typography>
                    </Grid>
                  </TableCell>
                  {props.category && <TableCell width={180} className={classes.cell}></TableCell>}
                  <TableCell width={150} align="right" className={classes.cell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachmentFiles
                  .filter((value) => value.processType !== ProcessType.delete)
                  .map((attachmentFile, index) => (
                    <AttachmentFileTableRow
                      key={attachmentFile.id}
                      index={index}
                      attachmentFile={attachmentFile}
                      isEditing={index === editIndex}
                      isDelete={props.isDelete}
                      onBeginEdit={handleBeginEdit}
                      onApplyEdit={handleApplyEdit}
                      onCancelEdit={handleCancelEdit}
                      onDelete={handleDelete}
                      onDownload={handleDownload}
                      category={props.category}
                      editable={props.editable}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
