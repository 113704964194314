import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputState } from "Common/Component/VirtualizedTable";
import { Construction, ConstructionAddress, Group, Site, Survey, User } from "Models/Group";
import { MenuIndex } from "Component/Menu";
import { useExecuteEx } from "Hooks/useFetch";
import { callWebApi } from "Common/Utility/Api";
import { CallOnClose, InputPending, onCloseWithSave, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import clsx from "clsx";
import {
  ConstructionAddressComponent,
  ConstructionComponent,
  SiteComponent,
  SurveyComponent,
  UsersComponent,
} from "./DirectInputComponent";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";

const useStyles = makeStyles((theme) => ({
  constructionName: {
    width: 500,
    marginLeft: theme.spacing(1),
  },
}));

interface EditDialogProps extends InputPending {
  open: boolean;
  group: Group;
}

export const EditDialog = React.memo((props: EditDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const [edited, confirm] = useWhetherEdited(props, isValidEdit);

  const [group, setGroup] = useState<Group>({ ...props.group });

  const [sites, setSites] = useState<Site[]>([...props.group.sites]);

  const [constructionAddress, setConstructionAddress] = useState<ConstructionAddress[]>([
    ...props.group.constructionAddress,
  ]);

  const [salesStaff, setSalesStaff] = useState<User[]>([...props.group.salesStaff]);

  const [operation, setOperation] = useState<User[]>([...props.group.operation]);

  const [survey, setSurvey] = useState<Survey[]>([...props.group.survey]);

  const [construction, setConstruction] = useState<Construction[]>([...props.group.constructions]);

  const [editingSite, setEditingSite] = useState<InputState>(InputState.None);

  const [editingConstructionAddress, setEditingConstructionAddress] = useState<InputState>(InputState.None);

  const [editingUsers, setEditingUsers] = useState<InputState>(InputState.None);

  const [editingUsersOperation, setEditingUsersOperation] = useState<InputState>(InputState.None);

  const [editingSurvey, setEditingSurvey] = useState<InputState>(InputState.None);

  const [editingConstruction, setEditingConstruction] = useState<InputState>(InputState.None);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { group: Group; onClose: CallOnClose }) => {
        var response = await callWebApi().put("/group", object.group);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "工事情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(object.group));
      },
      [alertAdd]
    )
  );

  const handleOnChange = (target: "constructionNumber" | "name" | "note") => {
    return (event: ChangeEvent<HTMLAreaElement | any>) => {
      const newValue = event.target.value;
      setGroup((value) => {
        return { ...value, [target]: newValue };
      });
      edited();
    };
  };

  const handleOnClickHoldInAction = useHoldInput(
    "工事",
    <EditDialog open={true} group={group} onClose={props.onClose} />,
    props.onClose,
    props.onHold,
    () => {
      group.sites = [...sites];
      group.constructionAddress = [...constructionAddress];
      group.salesStaff = [...salesStaff];
      group.operation = [...operation];
      group.survey = [...survey];
      group.constructions = [...construction];
    }
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={false} maxWidth="lg">
      <DialogTitle>工事情報</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="工事番号"
              value={group.constructionNumber}
              onChange={handleOnChange("constructionNumber")}
              disabled={!isValidEdit}
            />
            <TextField
              className={classes.constructionName}
              label="工事名"
              value={group.name}
              onChange={handleOnChange("name")}
              disabled={!isValidEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(genericClasses.width100percent)}
              label="備考"
              multiline
              value={group.note}
              variant="outlined"
              onChange={handleOnChange("note")}
              disabled={!isValidEdit}
            />
          </Grid>
          <Grid item xs={12}>
            現場情報
          </Grid>
          <Grid item xs={12}>
            <SiteComponent edited={edited} data={sites} setData={setSites} setEditing={setEditingSite} />
          </Grid>
          <Grid item xs={12}>
            施工住所
          </Grid>
          <Grid item xs={12}>
            <ConstructionAddressComponent
              edited={edited}
              data={constructionAddress}
              setData={setConstructionAddress}
              setEditing={setEditingConstructionAddress}
            />
          </Grid>
          <Grid item xs={12}>
            現場担当者(営業)
          </Grid>
          <Grid item xs={12}>
            <UsersComponent edited={edited} data={salesStaff} setData={setSalesStaff} setEditing={setEditingUsers} />
          </Grid>
          <Grid item xs={12}>
            現場担当者(オペレーション)
          </Grid>
          <Grid item xs={12}>
            <UsersComponent
              edited={edited}
              data={operation}
              setData={setOperation}
              setEditing={setEditingUsersOperation}
            />
          </Grid>
          <Grid item xs={12}>
            調査情報
          </Grid>
          <Grid item xs={12}>
            <SurveyComponent edited={edited} data={survey} setData={setSurvey} setEditing={setEditingSurvey} />
          </Grid>
          <Grid item xs={12}>
            施工情報
          </Grid>
          <Grid item xs={12}>
            <ConstructionComponent
              edited={edited}
              data={construction}
              setData={setConstruction}
              setEditing={setEditingConstruction}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isValidEdit ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={
                !props.open ||
                editingSite !== InputState.None ||
                editingConstructionAddress !== InputState.None ||
                editingUsers !== InputState.None ||
                editingUsersOperation !== InputState.None ||
                editingSurvey !== InputState.None ||
                editingConstruction !== InputState.None ||
                inProcess
              }
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={() => {
                group.sites = sites;
                group.constructionAddress = constructionAddress;
                group.salesStaff = salesStaff;
                group.operation = operation;
                group.survey = survey;
                group.constructions = construction;
                executePut({ group: group, onClose: props.onClose });
              }}
              color="primary"
              disabled={
                !props.open ||
                editingSite !== InputState.None ||
                editingConstructionAddress !== InputState.None ||
                editingUsers !== InputState.None ||
                editingUsersOperation !== InputState.None ||
                editingSurvey !== InputState.None ||
                editingConstruction !== InputState.None ||
                inProcess
              }
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
