import React, { useCallback, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useAlertAdd } from "Common/Component/AlertList";
import { Deposit } from "Models/Deposit";
import { IconButton, Paper, Grid, Typography } from "@material-ui/core";
import { DateUtility } from "Common/Utility/DateUtility";
import { callWebApi } from "Common/Utility/Api";
import { useMessageBox } from "Hooks/useMessageBox";
import { Props } from "./BusinessDetail";
import { CancelTokenSource } from "axios";
import { useFetch, useExecute } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { Autorenew } from "@material-ui/icons";
import { sortWithSet } from "Common/Utility/GenericInterface";
import { DepositEditDialog } from "Pages/Deposit/ConfirmDepositPanel/DepositEditDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { useContentHeight } from "Hooks/useResize";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
  caption: {
    width: 125,
  },
  contents: {
    width: "calc(100% - 125px)",
  },
  select: {
    width: "100%",
  },
  note: {
    width: `calc(100% - (48px + ${theme.spacing(1)}px))`,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  padding: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const DepositInBusiness = (props: Props) => {
  const [height] = useContentHeight(
    Design.topButtonHeight + Design.componentUnitHeight + Design.margin + 64 + Design.margin
  );

  const classes = useStyles({ height: height });

  const alerttAdd = useAlertAdd();

  const genericClasses = useGenericStyles();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const [deposits, setDeposits] = useState<Deposit[]>([]);

  const [deposit, setDeposit] = useState<Deposit | undefined>(undefined);

  const message = useMessageBox();

  // 新規行の追加
  const handleOnClickAdd = useCallback(() => {
    setDeposit({
      customerId: businessInfo.customerId,
      customerManualId: businessInfo.customerManualId,
      customerName: businessInfo.customerName,
      businessId: businessInfo.id,
      depositDate: null,
      category: 0,
    } as Deposit);
  }, [businessInfo]);

  const handleOnClickEdit = useCallback(
    (deposit: Deposit, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDeposit(deposit);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        await callWebApi().delete("/deposit", {
          params: {
            id: object.id,
          },
        });

        alerttAdd({ type: "success", message: "入金情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setDeposits((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alerttAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (deposit: Deposit, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "入金情報を削除します。よろしいですか？")) {
        executeDelete({ id: deposit.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog("Deposit", setDeposit, setDeposits, "id", businessInfo.id);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Deposit);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.DepositInBusiness
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 175,
        label: "入金日",
        dataKey: "depositDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 200,
        label: "入金形式",
        dataKey: "categoryName",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "金額",
        dataKey: "amount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => `${data.toLocaleString()} 円`,
        sort: (asc: boolean) => sortWithSet(deposits, setDeposits, "amount", asc),
        visible: getVisible(2),
      },
      {
        width: 500,
        label: "備考",
        dataKey: "note",
        headerAlign: "center",
        fit: true,
        visible: getVisible(3),
      }
    );

    return ret;
  }, [isValidEdit, deposits, setDeposits, getVisible]);

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get("/deposit", {
          cancelToken: signal.token,
          params: { businessId: businessInfo.id },
        });
        setDeposits(response.data);
      },
      [businessInfo.id]
    )
  );

  const handleOnClickReload = useCallback(() => fetchResultLoad.reload(), [fetchResultLoad]);

  return (
    <>
      <Paper>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography className={genericClasses.margin}>入金</Typography>
              <IconButton
                className={genericClasses.margin}
                color="primary"
                aria-label="udpate"
                onClick={handleOnClickReload}
              >
                <Autorenew />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.padding}>
            {useLoadingElement(classes.paper, LoadingMode.Simple, fetchResultLoad) ?? (
              <Paper className={classes.paper}>
                <VirtualaizedTable
                  values={deposits}
                  setValues={setDeposits}
                  rowHeight={48}
                  columns={columns}
                  onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                  onClickEdit={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                  onClickReference={handleOnClickEdit}
                  onCloseContextMenu={handleOnCloseContextMenu}
                  onChangeHeaderVisible={handleOnChangeHeaderVisible}
                  headerContext
                />
              </Paper>
            )}
          </div>
        </Grid>
      </Paper>
      {deposit != null && (
        <DepositEditDialog open={true} onClose={handleOnClose} deposit={deposit} editAuth={isValidEdit} />
      )}
    </>
  );
};

export default React.memo(DepositInBusiness);
