import { EditRoleType } from "Models/Role";

export const EstimateEditAuth = {
  approval: 0,
} as const;
export type EstimateEditAuth = typeof EstimateEditAuth[keyof typeof EstimateEditAuth];

export const VoucherEditAuth = {
  approval: 0,
} as const;
export type VoucherEditAuth = typeof VoucherEditAuth[keyof typeof VoucherEditAuth];

export const CompanyEditAuth = {
  creditLimit: 0,
} as const;
export type CompanyEditAuth = typeof CompanyEditAuth[keyof typeof CompanyEditAuth];

export const SystemAuth = {
  login: 0,
} as const;
export type SystemAuth = typeof SystemAuth[keyof typeof SystemAuth];

export interface EditAuthInfo {
  text: string;

  bit: number;
}

const estimateInfos: EditAuthInfo[] = [
  {
    text: "承認",
    bit: 1 << 0,
  },
];

const voucherInfos: EditAuthInfo[] = [
  {
    text: "承認",
    bit: 1 << 0,
  },
];

const companyInfos: EditAuthInfo[] = [
  {
    text: "与信額",
    bit: 1 << 0,
  },
];

const systemInfos: EditAuthInfo[] = [
  {
    text: "ログイン",
    bit: 1 << 0,
  },
];

export const getEditAuthInfo = (
  type: EditRoleType,
  index: EstimateEditAuth | VoucherEditAuth | CompanyEditAuth | SystemAuth
) => {
  switch (type) {
    case "estimateEditAuth":
      return estimateInfos[index];
    case "voucherEditAuth":
      return voucherInfos[index];
    case "companyEditAuth":
      return companyInfos[index];
    case "systemAuth":
      return systemInfos[index];
    default:
      return {} as EditAuthInfo;
  }
};

export const getEditAuthInfos = (type: EditRoleType) => {
  switch (type) {
    case "estimateEditAuth":
      return estimateInfos;
    case "voucherEditAuth":
      return voucherInfos;
    case "companyEditAuth":
      return companyInfos;
    case "systemAuth":
      return systemInfos;
    default:
      return [] as EditAuthInfo[];
  }
};
