import { makeStyles } from "@material-ui/core";
import { DateEx, DateUtility } from "Common/Utility/DateUtility";
import { DrawUtility, Align, Font } from "Common/Utility/Drawing";
import { Canvas, CanvasEvent, CanvasHandler, CanvasInfo, ColumnInfo } from "Component/Canvas";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { callWebApi } from "Common/Utility/Api";
import { useExecute } from "Hooks/useFetch";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { WorkTask } from "Models/WorkTask";
import { AddComponent, CanvasDataBase, ScheduleHandlerBase, ScheduleProps, useSortScheduleList } from "./Schedule";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  canvas: {
    backgroundColor: "white",
  },
  cursor: {
    cursor: "w-resize",
  },
}));

interface CanvasData extends CanvasDataBase<WorkTask, TaskScheduleProps> {}

interface Callbacks {
  setData: React.Dispatch<React.SetStateAction<CanvasData>>;

  executePut: (objcet?: { workTask: WorkTask; source: { from: Date; to: Date } }) => void;

  sort: (index: number) => void;
}

class HandlerBase extends ScheduleHandlerBase<CanvasData, Callbacks, WorkTask, TaskScheduleProps> {
  protected drawRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    maxDate: Date,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    let distanceDate = this.calcDistance(canvasInfo.mouseMovementDistance.x);

    // 行
    let date = new Date(
      canvasData.horizontalOffset.getFullYear(),
      canvasData.horizontalOffset.getMonth(),
      canvasData.horizontalOffset.getDate() + distanceDate
    );

    let vOffset = HandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    for (
      let i = vOffset;
      i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < canvasData.scheduleProps.rows.length;
      ++i
    ) {
      const value = canvasData.scheduleProps.rows[i];
      if (value.from == null || value.to == null) {
        return;
      }

      let from = new Date(value.from);
      let to = new Date(value.to);
      if (from <= maxDate && to >= date) {
        let start = DateUtility.termDate(date, from);
        let term = DateUtility.termDate(from, to);
        let rect = {
          x: 2 + canvasData.columnsWidth + start * Design.rowHeight,
          y: 2 + Design.headerHeight + (i - vOffset) * Design.rowHeight,
          w: Design.rowHeight * (term + 1) - 4,
          h: Design.rowHeight - 4,
        };
        context.fillStyle = "rgba(134,219,193,0.75)";
        DrawUtility.fillRect(context, rect, 5);
      }
    }
  }

  protected drawAsset(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const props = canvasData.scheduleProps;

    let vOffset = HandlerBase.calcVerticalOffset(
      props.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    // スケジュールのはみ出し部分の削除
    context.clearRect(0, 0, canvasData.columnsWidth, canvas.height);

    // 資産番号の出力
    context.fillStyle = "black";

    for (let i = vOffset; i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < props.rows.length; ++i) {
      var x = 0;
      for (let j = 0; j < props.columns.length; ++j) {
        const columnInfo = props.columns[j];
        if (columnInfo.toString) {
          DrawUtility.fillText(
            context,
            columnInfo.toString(props.rows[i]),
            {
              x: 8 + x,
              y: Design.headerHeight + (i - vOffset) * Design.rowHeight,
              w: columnInfo.width - 16,
              h: Design.rowHeight,
            },
            new Font("Meiryo", 12),
            columnInfo.bodyHorizonAlign,
            columnInfo.bodyVerticalAlign
          );
        }
        x += columnInfo.width;
      }
    }
  }

  protected drawHeader(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    // ヘッダーの境界線
    context.strokeStyle = "rgb(200,200,200)";
    context.lineWidth = 1;
    DrawUtility.line(context, 0, Design.headerHeight, canvas.width, Design.headerHeight);
    DrawUtility.line(context, 0, Design.rowHeight, canvasData.columnsWidth, Design.rowHeight);

    context.lineWidth = 0.5;
    var position = 0;
    for (let i = 0; i < canvasData.scheduleProps.columns.length; ++i) {
      DrawUtility.fillText(
        context,
        canvasData.scheduleProps.columns[i].text,
        {
          x: position,
          y: Design.rowHeight,
          w: canvasData.scheduleProps.columns[i].width,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 12),
        canvasData.scheduleProps.columns[i].headerHorizontalAlign,
        canvasData.scheduleProps.columns[i].headerVerticalAlign
      );

      if (i < canvasData.scheduleProps.columns.length - 1) {
        position += canvasData.scheduleProps.columns[i].width;
        DrawUtility.line(context, position, Design.rowHeight, position, canvas.height);
      }
    }
  }
}

class DefaultHandler extends HandlerBase {
  onClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    if (position.x <= event.data.columnsWidth && position.y >= Design.rowHeight && position.y <= Design.headerHeight) {
      let width: number = 0;
      const index = event.data.scheduleProps.columns.findIndex((i) => {
        if (width <= position.x && width + i.width >= position.x) {
          return true;
        }

        width += i.width;
        return false;
      });
      if (index >= 0) {
        event.callbacks.sort(index);
      }
    }

    return undefined;
  }

  onDoubleClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    let positionIndex = this.getDetailPosition(
      DrawUtility.mousePosition(canvas, event.mouseEvent),
      event.data.columnsWidth
    );

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    // 行が存在しない場合は終了
    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex >= event.data.scheduleProps.rows.length || vPositionIndex < 0) {
      return undefined;
    }

    event.data.scheduleProps.onClickEdit?.(event.data.scheduleProps.rows[vOffset + positionIndex.y]);

    return undefined;
  }

  onMouseDown(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    if (positionIndex.y < 0) {
      return undefined;
    }

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex < event.data.scheduleProps.rows.length) {
      const workTask = event.data.scheduleProps.rows[vPositionIndex];
      if (workTask.from == null || workTask.to == null) {
        return;
      }

      const date = new DateEx(event.data.horizontalOffset).newInstance(0, 0, positionIndex.x);

      if (
        DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), workTask.from) &&
        positionIndex.align === Align.left
      ) {
        return new ResizeHandler(Align.left, workTask);
      } else if (
        DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), workTask.to) &&
        positionIndex.align === Align.right
      ) {
        return new ResizeHandler(Align.right, workTask);
      }
    }

    if (position.x > event.data.columnsWidth) {
      return new ScrollHandler();
    }

    return undefined;
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    var resizeCursor: boolean = false;

    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    const vPositionIndex = positionIndex.y + vOffset;
    if (
      position.x >= event.data.columnsWidth &&
      position.y >= Design.headerHeight &&
      vPositionIndex < event.data.scheduleProps.rows.length
    ) {
      const workTask = event.data.scheduleProps.rows[vPositionIndex];
      const date = new DateEx(event.data.horizontalOffset).newInstance(0, 0, positionIndex.x);

      if (
        DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), workTask.from) &&
        positionIndex.align === Align.left
      ) {
        resizeCursor = true;
      } else if (
        DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), workTask.to) &&
        positionIndex.align === Align.right
      ) {
        resizeCursor = true;
      }
    }

    event.callbacks.setData((value) => {
      if (value.resizeCursor !== resizeCursor) {
        return { ...value, resizeCursor: resizeCursor };
      } else {
        return value;
      }
    });

    return undefined;
  }

  onMouseWheel(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      this.calcDistance((event.mouseEvent as WheelEvent).deltaY)
    );

    if (event.data.verticalOffset === vOffset) {
      return undefined;
    }

    event.callbacks.setData((value) => {
      return { ...value, verticalOffset: vOffset };
    });

    event.mouseEvent.preventDefault();

    return undefined;
  }
}

class ResizeHandler extends HandlerBase {
  workTask: WorkTask;

  startDate: Date;

  align: Align;

  source: { from: Date; to: Date };

  constructor(align: Align, workTask: WorkTask) {
    super();

    this.workTask = workTask;
    this.align = align;
    const from = new Date(workTask.from!);
    const to = new Date(workTask.to!);
    if (this.align === Align.left) {
      this.startDate = from;
    } else {
      this.startDate = to;
    }
    this.source = { from: from, to: to };
  }

  private resized(event: CanvasEvent<CanvasData, Callbacks>) {
    if (
      !DateUtility.equals("yyyMMdd", this.workTask.from, this.source.from) ||
      !DateUtility.equals("yyyMMdd", this.workTask.to, this.source.to)
    ) {
      event.callbacks.executePut({
        workTask: this.workTask,
        source: this.source,
      });
    }
    return new DefaultHandler();
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    return this.resized(event);
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    return this.resized(event);
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.mouseMovementDistance = { x: 0, y: 0 };

    if (this.startDate === null) {
      return undefined;
    }

    let position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    if (event.canvasInfo.mouseDowned && event.canvasInfo.mouseDownPosition) {
      var amountOfChange = Math.floor((position.x - event.canvasInfo.mouseDownPosition.x) / Design.rowHeight);
      const newDate = new DateEx(this.startDate).newInstance(0, 0, amountOfChange);

      var newRange: { from: Date; to: Date };

      if (this.workTask.from == null || this.workTask.to == null) {
        return undefined;
      }

      if (this.align === Align.left) {
        newRange = { from: newDate, to: new Date(this.workTask.to) };
      } else {
        newRange = { from: new Date(this.workTask.from), to: newDate };
      }

      if (newRange.from > newRange.to) {
        return undefined;
      }

      event.callbacks.setData((value) => {
        if (this.align === Align.left) {
          this.workTask.from = newDate.toLocaleDateString();
        } else {
          this.workTask.to = newDate.toLocaleDateString();
        }
        return { ...value };
      });
    }
    return undefined;
  }
}

class ScrollHandler extends HandlerBase {
  private resetDistance(canvas: HTMLCanvasElement, event: CanvasEvent<CanvasData, Callbacks>) {
    event.callbacks.setData((value) => {
      if (event.canvasInfo.mouseMovementDistance.x !== 0) {
        value.horizontalOffset = new Date(
          value.horizontalOffset.getFullYear(),
          value.horizontalOffset.getMonth(),
          value.horizontalOffset.getDate() + this.calcDistance(event.canvasInfo.mouseMovementDistance.x)
        );
      }

      if (event.canvasInfo.mouseMovementDistance.y !== 0) {
        value.verticalOffset = HandlerBase.calcVerticalOffset(
          value.scheduleProps.rows.length,
          canvas.height,
          value.verticalOffset,
          this.calcDistance(event.canvasInfo.mouseMovementDistance.y)
        );
      }

      return { ...value };
    });
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.refresh();
    return undefined;
  }
}

interface TaskScheduleProps extends ScheduleProps<WorkTask> {
  className: string;

  columns: ColumnInfo<WorkTask>[];

  onClickEdit?: (data: any) => void;

  onClickAdd?: () => void;

  isValidEdit: boolean;
}

function TaskSchedule(props: TaskScheduleProps) {
  const classes = useStyles();

  const setRows = useMemo(() => props.setRows, [props.setRows]);

  const sort = useSortScheduleList<WorkTask>(setRows, props.columns);

  const alertAdd = useAlertAdd();

  const executePut = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          workTask: WorkTask;
          source: { from: Date; to: Date };
        }
      ) => {
        var response = await callWebApi().put<WorkTask>("/schedule", object.workTask);

        if (!validateResponse(alertAdd, response)) {
          if (object.source !== undefined) {
            const from = object.source.from;
            const to = object.source.to;
            setRows((value) => {
              const newValue = value.find((i) => i.id === object.workTask.id);
              if (newValue != null) {
                newValue.from = from.toLocaleDateString();
                newValue.to = to.toLocaleDateString();
                return [...value];
              } else {
                return value;
              }
            });
          }
          return;
        }

        if (unmounted.value) {
          return;
        }

        setRows((value) => {
          const index = value.findIndex((i) => i.id === object.workTask.id);
          if (index >= 0) {
            value[index] = response.data;
            return [...value];
          } else {
            return value;
          }
        });
      },
      [setRows, alertAdd]
    )
  );

  const [data, setData] = useState<CanvasData>({
    scheduleProps: props,
    columnsWidth: props.columns.reduce((prev, current) => prev + current.width, 0),
    verticalOffset: 0,
    horizontalOffset: new Date(DateUtility.nowDate()),
    resizeCursor: false,
  } as CanvasData);

  const handleOnClickAdd = useMemo(() => props.onClickAdd, [props.onClickAdd]);

  const components = useMemo(() => {
    if (props.isValidEdit && handleOnClickAdd) {
      const component = new AddComponent<CanvasData, Callbacks>(8, 8);
      component.click.push(handleOnClickAdd);
      return [component];
    } else {
      return [];
    }
  }, [props.isValidEdit, handleOnClickAdd]);

  const callbacks = useMemo(() => {
    return {
      setData: setData,
      executePut: executePut,
      sort: sort,
    } as Callbacks;
  }, [executePut, sort]);

  useEffect(() => {
    setData((value) => {
      return { ...value, scheduleProps: props };
    });
  }, [props]);

  return (
    <>
      <div>
        <Canvas<CanvasData, Callbacks>
          className={clsx(props.className, classes.canvas, data.resizeCursor && classes.cursor)}
          handler={new DefaultHandler()}
          data={data}
          callbacks={callbacks}
          components={components}
        />
      </div>
    </>
  );
}

export default React.memo(TaskSchedule);
