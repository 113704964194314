import { makeStyles } from "@material-ui/core";
import React from "react";
import { InputManagerResult } from "Common/Utility/HandleUtility";
import { EstimateDetail } from "Models/Estimate";
import { ModelMaster } from "Models/ModelMaster";
import { AssetMaster } from "Models/Asset";
import { LineupSummary } from "Models/Lineup";
import { SelectedItem } from "./SelectedItem";
import TextField from "Component/TextField";
import { InputMoney } from "./InputMoney";

const useStyles = makeStyles((theme) => ({
  note: {
    width: 300,
    margin: theme.spacing(1),
  },
}));

interface Props {
  models: ModelMaster[];
  assets: AssetMaster[];
  lineupSummaries: LineupSummary[];
  editable: boolean;
  detail: EstimateDetail;
  setDetail: React.Dispatch<React.SetStateAction<EstimateDetail>>;
  inputManager: InputManagerResult<EstimateDetail>;
}

export const Sale = React.memo((props: Props) => {
  const classes = useStyles();

  const { detail, setDetail, editable, inputManager, ...other } = { ...props };

  return (
    <>
      <SelectedItem {...other} detail={detail} inputManager={inputManager} editable={editable} />
      <TextField
        label="摘要"
        className={classes.note}
        type="text"
        value={detail.summary}
        onChange={inputManager.handleOnChange("summary")}
        disabled={!editable}
      />
      <InputMoney
        editable={editable}
        detail={detail}
        setDetail={setDetail}
        inputManager={inputManager}
        cost
        taxExemption
      />
    </>
  );
});
