import { Days, Design } from "Common/Utility/Constants";
import { DateEx, DateUtility } from "Common/Utility/DateUtility";
import { Align, DrawUtility, Font, Point, VerticalAlign, Rect } from "Common/Utility/Drawing";
import { generalComparsion } from "Common/Utility/GenericInterface";
import { CanvasComponent, CanvasEvent, CanvasHandler, CanvasInfo, ColumnInfo } from "Component/Canvas";
import { AssetMaster, ReturnAsset } from "Models/Asset";
import { useCallback, useState } from "react";
import { ShipmentStatus } from "Common/Utility/Constants";

export function drawLegend(context: CanvasRenderingContext2D, columnsWidth: number, drawCurrent: boolean) {
  const titleWidth = context.measureText("凡例").width;
  const colorWidth = context.measureText("貸出期間").width + 16;

  let x = titleWidth + colorWidth * 3 + Design.margin * 7;
  if (drawCurrent) {
    x += colorWidth * 1 + Design.margin * 2;
  }
  x = (columnsWidth - context.measureText("yyyy/mm").width - x) / 2;

  DrawUtility.fillText(
    context,
    "凡例",
    { x: x, y: 0, w: titleWidth, h: Design.rowHeight },
    new Font("Meiryo", 12),
    Align.left,
    VerticalAlign.middle
  );

  x += titleWidth + Design.margin * 2;
  context.fillStyle = "rgba(134,219,193,0.75)";
  DrawUtility.fillRect(context, { x: x, y: 2, w: colorWidth, h: Design.rowHeight - 4 }, 5);
  context.fillStyle = "rgba(0,0,0,1)";
  DrawUtility.fillText(
    context,
    "貸出期間",
    { x: x, y: 0, w: colorWidth, h: Design.rowHeight },
    new Font("Meiryo", 12),
    Align.center,
    VerticalAlign.middle
  );

  x += colorWidth + Design.margin * 2;
  context.fillStyle = "rgba(201,158,158,0.75)";
  DrawUtility.fillRect(
    context,
    { x: x, y: 2 + (Design.rowHeight - 4) / 2, w: colorWidth, h: (Design.rowHeight - 4) / 2 },
    5
  );
  context.fillStyle = "rgba(0,0,0,1)";
  DrawUtility.fillText(
    context,
    "未帰庫",
    { x: x, y: 0, w: colorWidth, h: Design.rowHeight },
    new Font("Meiryo", 12),
    Align.center,
    VerticalAlign.middle
  );

  x += colorWidth + Design.margin * 2;
  context.fillStyle = "rgba(255,200,200,0.60)";
  DrawUtility.fillRect(
    context,
    { x: x, y: 2 + (Design.rowHeight - 4) / 2, w: colorWidth, h: (Design.rowHeight - 4) / 2 },
    5
  );
  context.fillStyle = "rgba(0,0,0,1)";
  DrawUtility.fillText(
    context,
    "帰庫済",
    { x: x, y: 0, w: colorWidth, h: Design.rowHeight },
    new Font("Meiryo", 12),
    Align.center,
    VerticalAlign.middle
  );

  if (drawCurrent) {
    x += colorWidth + Design.margin * 2;
    context.fillStyle = "rgba(153,200,244,0.75)";
    DrawUtility.fillRect(context, { x: x, y: 2, w: colorWidth, h: Design.rowHeight - 4 }, 5);
    context.fillStyle = "rgba(0,0,0,1)";
    DrawUtility.fillText(
      context,
      "設定期間",
      { x: x, y: 0, w: colorWidth, h: Design.rowHeight },
      new Font("Meiryo", 12),
      Align.center,
      VerticalAlign.middle
    );
  }
}

export const useSortScheduleList = <T,>(
  setRows: React.Dispatch<React.SetStateAction<T[]>>,
  columns: ColumnInfo<T>[]
) => {
  const [, setSortDirection] = useState<number>(-1);

  return useCallback(
    (index: number) => {
      setSortDirection((value) => {
        const asc = value !== index;

        setRows((value) => {
          const dataKey = columns[index].dataKey;
          if (dataKey) {
            value.sort((a, b) => {
              return generalComparsion(a[dataKey], b[dataKey]) * (asc ? 1 : -1);
            });
            return [...value];
          }
          return value;
        });

        if (value === index) {
          return -1;
        } else {
          return index;
        }
      });
    },
    [setRows, columns]
  );
};

export class AddComponent<T, U> extends CanvasComponent<T, U> {
  click: ((canvas: HTMLCanvasElement, event: CanvasEvent<T, U>) => void)[] = [];

  constructor(x: number, y: number) {
    super({ x: x, y: y, w: 16, h: 16 });
  }

  onPaint(canvas: HTMLCanvasElement, canvasInfo: CanvasInfo, data: T, context: CanvasRenderingContext2D) {
    context.fillStyle = "rgb(63,81,181)";
    DrawUtility.fillRect(context, this.rect, 3);

    context.strokeStyle = "rgb(255,255,255)";
    context.lineWidth = 2;
    DrawUtility.line(context, this.rect.x + 2, this.rect.y + 8, this.rect.x + this.rect.w - 2, this.rect.y + 8);
    DrawUtility.line(context, this.rect.x + 8, this.rect.y + 2, this.rect.x + 8, this.rect.y + this.rect.h - 2);
  }

  onClick(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>) {
    this.click.forEach((i) => i(canvas, event));
  }
}

export interface ScheduleProps<T> {
  rows: T[];

  setRows: React.Dispatch<React.SetStateAction<T[]>>;
}

export interface CanvasDataBase<T, U extends ScheduleProps<T>> {
  scheduleProps: U;

  columnsWidth: number;

  verticalOffset: number;

  horizontalOffset: Date;

  resizeCursor: boolean;
}

export abstract class DrawPattern {
  yIndex: number;
  from: Date;
  to: Date | null;
  zIndex: number;

  margin: number = 2;

  constructor(yIndex: number, from: Date, to: Date | null, zIndex: number) {
    this.yIndex = yIndex;
    this.from = from;
    this.to = to;
    this.zIndex = zIndex;
  }

  draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    // 描画範囲外を対象外とする
    if (this.from > endDate || (this.to != null && this.to < startDate)) {
      return;
    }

    this._draw(context, columnsWidth, startDate, endDate);
  }

  protected abstract _draw(
    context: CanvasRenderingContext2D,
    columnsWidth: number,
    startDate: Date,
    endDate: Date
  ): void;

  calcRect(columnsWidth: number, startDate: Date, endDate: Date): Rect | null {
    let start = 0;
    let term = 0;

    if (this.to == null) {
      start = DateUtility.termDate(startDate, this.from);
      if (this.from < startDate) {
        start = -1;
      }

      term = DateUtility.termDate(startDate, endDate) + 2;
    } else if (this.from <= endDate && this.to >= startDate) {
      start = DateUtility.termDate(startDate, this.from);
      term = DateUtility.termDate(this.from, this.to);
    } else {
      return null;
    }

    const rect: Rect = {
      x: columnsWidth + start * Design.rowHeight + this.margin,
      y: Design.headerHeight + this.yIndex * Design.rowHeight + this.margin,
      w: Design.rowHeight * (term + 1) - this.margin * 2,
      h: 0,
    };
    return rect;
  }

  static excessPeriod(from: Date, to: Date | null, returnAssets: ReturnAsset[]): { from: Date; to: Date } | null {
    if (to == null) {
      return null;
    } else {
      let newTo = new Date(DateUtility.nowDate());
      if (returnAssets.length > 0) {
        newTo = new Date(returnAssets[0].returnAt);
      }

      if (to < newTo) {
        return { from: new DateEx(to).newInstance(0, 0, 1), to: newTo };
      } else {
        return null;
      }
    }
  }
}

export class DrawLending extends DrawPattern {
  constructor(yIndex: number, from: Date, to: Date | null, returnAssets: ReturnAsset[]) {
    super(yIndex, from, to, 0);

    if (returnAssets.length > 0) {
      const returnedAt = new Date(returnAssets[0].returnAt);
      if (to == null || to > returnedAt) {
        this.to = returnedAt;
      }
    }
  }

  _draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    const rect = this.calcRect(columnsWidth, startDate, endDate);
    if (rect == null) {
      return;
    }
    rect.h = Design.rowHeight - 4;

    context.fillStyle = "rgba(134,219,193,0.75)";
    DrawUtility.fillRect(context, rect, 5);
  }
}

export class DrawLendingCurrent extends DrawPattern {
  constructor(yIndex: number, from: Date, to: Date | null) {
    super(yIndex, from, to, 1);
  }

  _draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    const rect = this.calcRect(columnsWidth, startDate, endDate);
    if (rect == null) {
      return;
    }
    rect.h = Design.rowHeight - 4;

    context.fillStyle = "rgba(153,200,244,1)";
    DrawUtility.fillRect(context, rect, 5);
  }
}

export class DrawExcess extends DrawPattern {
  doDraw: boolean = true;

  arrived: boolean = false;

  constructor(yIndex: number, from: Date, to: Date | null, returnAssets: ReturnAsset[]) {
    super(yIndex, from, to, 2);

    this.arrived = returnAssets.length > 0;
    const period = DrawPattern.excessPeriod(from, to, returnAssets);
    if (period == null) {
      this.doDraw = false;
    } else {
      this.from = period.from;
      this.to = period.to;
    }
  }

  _draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    if (!this.doDraw) {
      return;
    }

    const rect = this.calcRect(columnsWidth, startDate, endDate);
    if (rect == null) {
      return;
    }
    rect.h = (Design.rowHeight - 4) / 2;
    rect.y += rect.h;

    if (this.arrived) {
      context.fillStyle = "rgba(255,200,200,0.60)";
    } else {
      context.fillStyle = "rgba(201,158,158,0.75)";
    }
    DrawUtility.fillRect(context, rect, 4);
  }
}

export class DrawLendingStroke extends DrawPattern {
  constructor(yIndex: number, from: Date, to: Date | null) {
    super(yIndex, from, to, 2);
  }

  _draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    const rect = this.calcRect(columnsWidth, startDate, endDate);
    if (rect == null) {
      return;
    }
    rect.h = Design.rowHeight - 4;

    context.lineWidth = 2;
    context.strokeStyle = "rgba(153,200,244,1)";
    DrawUtility.strokeRect(context, rect, 5);
  }
}

export class DrawText extends DrawPattern {
  text: string;

  constructor(yIndex: number, from: Date, text: string) {
    super(yIndex, from, from, 2);

    this.text = text;
  }

  _draw(context: CanvasRenderingContext2D, columnsWidth: number, startDate: Date, endDate: Date): void {
    const rect = this.calcRect(columnsWidth, startDate, endDate);
    if (rect == null) {
      return;
    }
    rect.h = Design.rowHeight - 4;

    context.fillStyle = "black";
    DrawUtility.fillText(context, this.text, rect, new Font("Meiryo", 9), Align.center, VerticalAlign.middle);
  }
}

export abstract class ScheduleHandlerBase<
  T extends CanvasDataBase<V, W>,
  U,
  V,
  W extends ScheduleProps<V>
> extends CanvasHandler<T, U> {
  public clickedHeader(canvas: HTMLCanvasElement, event: CanvasEvent<T, U>) {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    return position.y >= Design.rowHeight && position.y <= Design.headerHeight;
  }

  public calcDistance(distance: number) {
    if (distance > 0) {
      return Math.floor(distance / Design.rowHeight);
    } else if (distance < 0) {
      return Math.ceil(distance / Design.rowHeight);
    } else {
      return 0;
    }
  }

  public static maxRowCount(height: number) {
    let h = height - Design.headerHeight;
    if (h < 0) {
      return 0;
    }
    return Math.floor(h / Design.rowHeight);
  }

  public static calcVerticalOffset(dataLength: number, height: number, offset: number, difference: number) {
    let heightMaxCount = dataLength - this.maxRowCount(height);
    if (heightMaxCount < 0 || offset + difference < 0) {
      return 0;
    } else if (offset + difference > heightMaxCount) {
      return heightMaxCount;
    }

    return offset + difference;
  }

  getRowIndex(y: number) {
    let h = y - Design.headerHeight;
    return Math.floor(h / Design.rowHeight);
  }

  getPosition(point: Point, xOffset: number): Point {
    return {
      x: Math.floor((point.x - xOffset) / Design.rowHeight),
      y: Math.floor((point.y - Design.headerHeight) / Design.rowHeight),
    };
  }

  getDetailPosition(point: Point, xOffset: number): { x: number; y: number; align: Align } {
    const detail = Math.floor((point.x - xOffset) / (Design.rowHeight / 3)) % 3;
    return {
      x: Math.floor((point.x - xOffset) / Design.rowHeight),
      y: Math.floor((point.y - Design.headerHeight) / Design.rowHeight),
      align: detail === 0 ? Align.left : detail === 1 ? Align.center : Align.right,
    };
  }

  protected drawDate(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasDataBase<V, W>
  ) {
    const props = canvasData;

    let toDay: number | null = null;

    // 日付
    let maxDate: Date = props.horizontalOffset;
    let distanceDate = this.calcDistance(canvasInfo.mouseMovementDistance.x);
    for (let i = 0; props.columnsWidth + i * Design.rowHeight < canvas.width; ++i) {
      let date = new Date(
        props.horizontalOffset.getFullYear(),
        props.horizontalOffset.getMonth(),
        props.horizontalOffset.getDate() + i + distanceDate
      );

      if (date.getDay() === Days.Sunday) {
        context.fillStyle = "rgb(255,240,240)";
        context.fillRect(props.columnsWidth + i * Design.rowHeight + 1, 0, Design.rowHeight - 2, canvas.height);
        context.fillStyle = "red";
      } else if (date.getDay() === Days.Saturday) {
        context.fillStyle = "rgb(240,240,255)";
        context.fillRect(props.columnsWidth + i * Design.rowHeight + 1, 0, Design.rowHeight - 2, canvas.height);
        context.fillStyle = "blue";
      } else {
        context.fillStyle = "black";
      }

      // 当日
      if (DateUtility.equals("yyyyMMdd", date.toLocaleString(), DateUtility.now())) {
        context.fillStyle = "rgba(255,0,0,0.75)";
        DrawUtility.fillRect(
          context,
          {
            x: props.columnsWidth + i * Design.rowHeight + 3,
            y: 0 + 3,
            w: Design.rowHeight - 6,
            h: Design.rowHeight * 2 - 6,
          },
          5
        );
        toDay = props.columnsWidth + i * Design.rowHeight + Design.rowHeight / 2;
        context.fillStyle = "white";
      }

      // 日付
      DrawUtility.fillText(
        context,
        date.getDate().toString(),
        {
          x: props.columnsWidth + i * Design.rowHeight,
          y: 0,
          w: Design.rowHeight,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 9),
        Align.center,
        VerticalAlign.middle
      );

      // 曜日
      DrawUtility.fillText(
        context,
        DateUtility.dayJpStrings[date.getDay()],
        {
          x: props.columnsWidth + i * Design.rowHeight,
          y: Design.rowHeight,
          w: Design.rowHeight,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 9),
        Align.center,
        VerticalAlign.middle
      );

      maxDate = date;
    }

    return { maxDate: maxDate, toDay: toDay };
  }

  protected drawVerticalLine(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: T
  ) {
    const props = canvasData;

    context.beginPath();

    for (let i = 0; props.columnsWidth + i * Design.rowHeight < canvas.width; ++i) {
      context.moveTo(props.columnsWidth + i * Design.rowHeight, 0);
      context.lineTo(props.columnsWidth + i * Design.rowHeight, canvas.height);
    }

    context.strokeStyle = "rgb(200,200,200)";
    context.lineWidth = 0.5;
    context.stroke();
  }

  protected abstract drawRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    maxDate: Date,
    canvasInfo: CanvasInfo,
    canvasData: T
  ): void;

  protected createDrawPatterns(
    date: Date,
    endDate: Date,
    offset: number,
    assetMaster: AssetMaster,
    drawPatterns: DrawPattern[],
    shipmentId?: string
  ) {
    if (assetMaster.assetNumber != null) {
      return;
    }

    const count: { [key: string]: number } = {};

    for (let j = new Date(date.valueOf()); j <= endDate; j.setDate(j.getDate() + 1)) {
      count[j.toLocaleDateString()] = 0;
    }

    // 過去分の貸出差分
    const numberOf: { from: Date; to: Date | null; numberOf: number; status?: number }[] = [];
    const numberOfReturn: { returnedAt: Date; to: Date | null; numberOf: number }[] = [];
    const self: { from: Date; to: Date | null; numberOf: number } = {
      from: new Date(),
      to: new Date(),
      numberOf: 0,
    };

    const toDay = new Date(DateUtility.nowDate());

    assetMaster.lendingPeriods.forEach((value) => {
      if (value.shipmentId === shipmentId) {
        self.from = new Date(value.from);
        self.to = value.to == null ? null : new Date(value.to);
        self.numberOf = value.numberOf;
        return;
      }

      const from = new Date(value.from);
      const to = value.to == null ? null : new Date(value.to);
      numberOf.push({ from: from, to: to, numberOf: value.numberOf, status: value.shipmentStatus });

      value.returnAssets.forEach((value) => {
        numberOfReturn.push({
          returnedAt: new Date(value.returnAt),
          to: to,
          numberOf: value.numberOfReturn + value.numberOfFailures,
        });
      });
    });
    numberOf.sort((a, b) => (a.from < b.from ? -1 : 1));
    numberOfReturn.sort((a, b) => (a.returnedAt < b.returnedAt ? -1 : 1));

    for (let i = new Date(date.valueOf()); i <= new DateEx(endDate).newInstance(0, 0, 5); i.setDate(i.getDate() + 1)) {
      if (i <= toDay) {
        // 当日までは実績値
        count[i.toLocaleDateString()] += numberOf
          .filter((value) => {
            // 当日までに貸出済み分を加算する。
            return value.from <= i && (value.to == null || i <= value.to || value.status === ShipmentStatus.Finished);
          })
          .reduce((prev, value) => prev + value.numberOf, 0);
        count[i.toLocaleDateString()] -= numberOfReturn
          .filter((value) => {
            // 当日まで帰庫された分を減算する。
            return value.returnedAt <= i;
          })
          .reduce((prev, value) => prev + value.numberOf, 0);
      } else {
        // 未来は予測値
        count[i.toLocaleDateString()] += numberOf
          .filter((value) => {
            // 予定分のみを加算する。
            return value.from <= i && (value.to == null || value.to >= i);
          })
          .reduce((prev, value) => prev + value.numberOf, 0);
        count[i.toLocaleDateString()] -= numberOfReturn
          .filter((value) => {
            // 帰庫日～終了日の分を減算する。
            return value.returnedAt <= i && (value.to == null || i <= value.to);
          })
          .reduce((prev, value) => prev + value.numberOf, 0);
      }
      if (self.from <= i && (self.to == null || self.to >= i)) {
        count[i.toLocaleDateString()] += self.numberOf;
      }
    }

    // 資産数が設定されている場合の数字
    if (assetMaster.assetNumber == null) {
      for (let key in count) {
        if (count[key] <= 0) {
          continue;
        }

        drawPatterns.push(new DrawText(offset, new Date(key), count[key].toString()));
      }
    }
  }

  protected abstract drawAsset(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: T
  ): void;

  protected abstract drawHeader(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: T
  ): void;

  protected drawScroll(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: T
  ): void {
    const height = canvas.height - Design.headerHeight;
    const numberOfRowCount = Math.floor(height / Design.rowHeight);
    const numberOfOver = canvasData.scheduleProps.rows.length - numberOfRowCount;
    if (numberOfOver <= 0) {
      return;
    }

    const scrollBarLength = height - (height * 0.9 * numberOfOver) / canvasData.scheduleProps.rows.length;

    const vOffset = ScheduleHandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    const y = Design.headerHeight + (height - scrollBarLength) * (vOffset / numberOfOver);

    context.beginPath();

    context.moveTo(canvas.width - 5, y);
    context.lineTo(canvas.width - 5, y + scrollBarLength);

    context.strokeStyle = "rgba(0,0,0,0.75)";
    context.lineWidth = 1;
    context.stroke();
  }

  onPaint(canvas: HTMLCanvasElement, canvasInfo: CanvasInfo, data: T, context: CanvasRenderingContext2D) {
    context.save();

    context.shadowBlur = 0;

    this.drawVerticalLine(canvas, context, canvasInfo, data);

    const date = this.drawDate(canvas, context, canvasInfo, data);

    this.drawRow(canvas, context, date.maxDate, canvasInfo, data);

    this.drawAsset(canvas, context, canvasInfo, data);

    // 年月の出力
    context.fillStyle = "black";
    DrawUtility.fillText(
      context,
      DateUtility.format(
        new Date(
          data.horizontalOffset.getFullYear(),
          data.horizontalOffset.getMonth(),
          data.horizontalOffset.getDate() + this.calcDistance(canvasInfo.mouseMovementDistance.x)
        ).toLocaleString(),
        "yyyy/MM"
      ),
      { x: 8, y: 0, w: data.columnsWidth - 16, h: Design.headerHeight / 2 },
      new Font("Meiryo", 12),
      Align.right,
      VerticalAlign.middle
    );

    // ヘッダー
    this.drawHeader(canvas, context, canvasInfo, data);

    // 当日の線
    if (date.toDay) {
      context.strokeStyle = "red";
      context.lineWidth = 1;
      DrawUtility.line(context, date.toDay, Design.headerHeight - 3, date.toDay, canvas.height + 3);
    }

    // スクロール
    this.drawScroll(canvas, context, canvasInfo, data);

    context.restore();
  }
}
