import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  ListItem,
  Box,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  FormControl,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { ChatForRaLM } from "Component/Chat";
import { AppActionTypes, AppProvider } from "App";
import { useContentHeight } from "Hooks/useResize";
import { callWebApi } from "Common/Utility/Api";
import { useFetch, useExecute, useExecuteEx } from "Hooks/useFetch";
import { useMessageBox } from "Hooks/useMessageBox";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { Contact } from "Models/Contact";
import { NotificationSettings, SendMailTime } from "Models/NotificationSettings";
import { BusinessInfo } from "Models/BusinessInfo";
import { DateUtility } from "Common/Utility/DateUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import clsx from "clsx";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { ComboItem } from "Common/Utility/GenericInterface";
import { NotificationCalledFuncs } from "Component/Contents";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useAlertAdd } from "Common/Component/AlertList";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useHistory } from "react-router-dom";
import { History } from "history";
import TextField from "Component/TextField";
import { belongCategory, BelongIndex } from "Common/Utility/Constants";
import { validateResponse } from "Common/Utility/HttpUtility";
import AddRoomDialog from "./AddRoomDialog";
import RoomName from "./RoomName";
import { Design } from "Common/Utility/Constants";
import { IdName } from "Models/IdName";

export enum ActionTypes {
  SET_CUSTOMER = "SET_CUSTOMER",
}

export const StatusIndex = {
  contact: 0,
  ordered: 1,
  closed: 2,
  business: 3,
} as const;
type StatusIndex = typeof StatusIndex[keyof typeof StatusIndex];

const StatusFromText = ["受付中", "受注済み", "終了", "営業中"];
const StatusToText = ["問い合わせ中", "発注済み", "終了", "営業通知"];

const useStyles = makeStyles((theme) => ({
  box: {
    height: (props: any) => props.height,
  },
  select: {
    overflowY: "scroll",
    display: "inline-block",
    verticalAlign: "top",
  },
  listArea: (props: any) => ({
    width: 450,
    height: props.height,
  }),
  tenant: {
    marginTop: 0,
    marginBottom: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  selected: {
    backgroundColor: "#CCE8FF",
  },
  status: {
    margin: `${theme.spacing(1) * 2}px 0px 0px ${theme.spacing(1)}px`,
  },
  chat: {
    width: `calc(100% - 450px - ${theme.spacing(1)}px)`,
    marginLeft: theme.spacing(1),
    display: "inline-block",
    verticalAlign: "top",
  },
  paper: {
    padding: theme.spacing(1),
  },
  notificationPaper: {
    width: 48,
  },
  contactInfoBar: {
    height: 48,
    width: `calc(100% - ${48 + theme.spacing(1)}px)`,
    marginBottom: theme.spacing(1),
    display: "inline-block",
    verticalAlign: "top",
  },
  contactUser: {
    minWidth: 350,
    padding: "0px 8px 0px 8px",
  },
  unread: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
    backgroundColor: "#3F51B5",
    color: "white",
  },
  settingLoadingElement: {
    width: "100%",
    height: 340,
  },
  border: {
    position: "relative",
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  unreadNotification: {
    position: "absolute",
    top: 0,
    left: 3,
    backgroundColor: "white",
    transform: "translateY(-50%) translateX(1em)",
  },
  checkbox: {
    paddingLeft: 0,
  },
  sendMailTimesTable: {
    height: 200,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  colon: {
    width: 15,
    marginTop: theme.spacing(1),
    display: "inline-block",
    verticalAlign: "top",
  },
  inEdit: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  isManager: {
    visibility: "hidden",
  },
  manualId: {
    width: 75,
  },
  roomName: {
    width: 250,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  loadingCutomer: {
    width: "100%",
    height: Design.topButtonHeight,
    marginBottom: Design.margin,
  },
  loadingContact: {
    width: 350,
    height: Design.componentUnitHeight,
  },
}));

interface LostOrderProps {
  open: boolean;
  roomId: string;
  manualId: string;
  contactUserId: string | null;
  onClose: (isLostOrder: boolean) => void;
}

const LostOrderDialog = (props: LostOrderProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [reason, setReason] = useState("");

  const [edited, confirm] = useWhetherEdited(props.open);

  const handleOnClose = async () => {
    confirm(() => props.onClose(false));
  };

  const handleOnClickCancelInAction = async () => {
    confirm(() => props.onClose(false));
  };

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          roomId: string;
          manualId: string;
          contactUserId: string | null;
          reason: string;
          onClose: (isLostOrder: boolean) => void;
        }
      ) => {
        var response = await callWebApi().post("/lostorder/lostorder", {
          receptionId: object.roomId,
          contactUserId: object.contactUserId,
          reason: object.reason,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: `${object.manualId} を失注にしました。` });

        if (unmounted.value) {
          return;
        }

        object.onClose(true);
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = async () => {
    if (!reason) {
      alertAdd({ type: "info", message: "理由は必須項目です。" });
      return;
    }

    if (await message.confirm("失注確認", `${props.manualId} を失注にしますか？`)) {
      executePost({
        roomId: props.roomId,
        manualId: props.manualId,
        contactUserId: props.contactUserId,
        reason: reason,
        onClose: props.onClose,
      });
    }
  };

  const handleOnChangeReason = (event: any) => {
    setReason(event.target.value);
    edited();
  };

  useEffect(() => {
    if (props.open) {
      setReason("");
    }
  }, [props.open]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="xs">
      <DialogTitle>失注情報</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.inEdit}
          multiline
          rows={10}
          label="理由"
          value={reason}
          onChange={handleOnChangeReason}
        />
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickSaveInAction}
          color="primary"
          disabled={!props.open || inProcess}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NotificationMailSendType = {
  noReceive: 0,
  everyTime: 1,
  regular: 2,
} as const;

const initNotification = () => {
  return {
    toUnread: false,
    toDesktop: false,
    category: 0,
    sendMailTimes: [{ sendAt: "09:00:00" }, { sendAt: "12:00:00" }, { sendAt: "15:00:00" }],
  } as NotificationSettings;
};

interface NotificationSettingProps {
  open: boolean;
  id: string | null;
  roomId: string;
  customerId: string;
  contactUserId: string | null;
  onClose: () => void;
}

const NotificationSettingDialog = React.memo((props: NotificationSettingProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const [notification, setNotification] = useState(initNotification());

  const [hour, setHour] = useState("00");

  const [minutes, setMinutes] = useState("00");

  const [edited, confirm] = useWhetherEdited(props.open);

  const [inputState, setInputState] = useState<InputState>(InputState.None);

  const isContactUser = useMemo(() => {
    return props.contactUserId === ralmAccount?.userGuid;
  }, [props.contactUserId, ralmAccount]);

  const editingTable = useMemo(() => inputState !== InputState.None, [inputState]);

  const handleOnClose = useCallback(() => {
    confirm(() => props.onClose());
  }, [props, confirm]);

  const handleOnClickCancelInAction = useCallback(() => {
    confirm(() => props.onClose());
  }, [props, confirm]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          tenantId?: string;
          notification: NotificationSettings;
          roomId: string;
          userGuid?: string;
          customerId: string;
          onClose: () => void;
        }
      ) => {
        if (object.notification.id) {
          await callWebApi().put("/NotificationSettings", {
            ...object.notification,
            tenantId: object.tenantId,
            roomId: object.roomId,
            userGuid: object.userGuid,
          });
        } else {
          await callWebApi().post("/NotificationSettings", {
            ...object.notification,
            tenantId: object.tenantId,
            roomId: object.roomId,
            userGuid: object.userGuid,
            customerId: object.customerId,
          });
        }

        alertAdd({ type: "success", message: "通知設定を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose();
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(() => {
    const data = { ...notification };
    executePut({
      onClose: props.onClose,
      tenantId: ralmAccount?.tenantId,
      notification: data,
      roomId: props.roomId,
      userGuid: ralmAccount?.userGuid,
      customerId: props.customerId,
    });
  }, [ralmAccount, props.customerId, props.onClose, props.roomId, executePut, notification]);

  const handleOnChangeToUnread = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setNotification((value) => {
        if (!checked) {
          value.category = 0;
          value.toDesktop = false;
        }

        return { ...value, toUnread: checked };
      });

      edited();
    },
    [edited]
  );

  const handleOnChangeToDesktop = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setNotification((value) => {
        return { ...value, toDesktop: checked };
      });

      edited();
    },
    [edited]
  );

  const handleOnChangeSelected = useCallback(
    (event: any) => {
      setNotification((value) => {
        return { ...value, category: event.target.value };
      });

      edited();
    },
    [edited]
  );

  const handleOnUnmounted = useCallback((inputState: InputState) => {
    if (inputState === InputState.Adding) {
      setNotification((value) => {
        value.sendMailTimes.pop();
        return { ...value };
      });
    }
  }, []);

  const handleOnClickAdd = useCallback(() => {
    setNotification((value) => {
      value.sendMailTimes = [...value.sendMailTimes, { sendAt: "00:00" }];
      return { ...value };
    });

    setHour("00");
    setMinutes("00");
  }, []);

  const handleOnClickEdit = useCallback(
    (data: SendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setHour(data.sendAt.substr(0, 2));
      setMinutes(data.sendAt.substr(3, 2));
    },
    []
  );

  const handleOnChangeHour = useCallback((event: any) => {
    setHour(event.target.value);
  }, []);

  const handleOnChangeMinutes = useCallback((event: any) => {
    setMinutes(event.target.value);
  }, []);

  const handleOnClickSave = useCallback(
    (data: SendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setNotification((value) => {
        value.sendMailTimes[rowIndex].sendAt = `${hour}:${minutes}:00`;
        return { ...value };
      });

      edited();
    },
    [edited, hour, minutes]
  );

  const handleOnClickCancel = useCallback(() => {
    if (inputState === InputState.Adding) {
      setNotification((value) => {
        value.sendMailTimes.pop();
        return { ...value };
      });
    }
  }, [inputState]);

  const handleOnClickDelete = useCallback(
    async (data: SendMailTime, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setNotification((value) => {
        value.sendMailTimes.splice(rowIndex, 1);
        return { ...value };
      });

      edited();
    },
    [edited]
  );

  const handleOnClickDefault = () => {
    setNotification((value) => {
      if (!ralmAccount) {
        return { ...value };
      }

      value.toUnread = true;
      value.category = ralmAccount.defaultNotification.category;
      value.toDesktop = ralmAccount.defaultNotification.toDesktop;
      value.sendMailTimes = [];
      ralmAccount.defaultNotification.sendMailTimes.forEach((time) => {
        value.sendMailTimes.push({ sendAt: time.sendAt });
      });

      return { ...value };
    });

    edited();
  };

  const loadingElement = useLoadingElement(
    classes.settingLoadingElement,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.open) {
            return;
          }

          if (props.id == null) {
            setNotification(initNotification());
            return;
          }

          const response = await callWebApi().get<NotificationSettings>(`notificationsettings/${props.id}`, {
            cancelToken: signal.token,
          });

          if (!response.data) {
            return;
          }

          setNotification(response.data);
        },
        [props.open, props.id]
      )
    )
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: notification.category === NotificationMailSendType.regular ? EditType.EditButton : undefined,
      },
      {
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: notification.category === NotificationMailSendType.regular ? EditType.DeleteButton : undefined,
      },
      {
        width: 100,
        label: "通知時間",
        dataKey: "sendAt",
        headerAlign: "center",
        bodyAlign: "center",
        fit: true,
        editType: EditType.AllowEdit,
        component: (data: SendMailTime, rowIndex: number) => {
          return (
            <Box className={clsx(genericClasses.width100percent, genericClasses.textAlignCenter)}>
              {DateUtility.hhmm(data.sendAt)}
            </Box>
          );
        },
        componentWhenEditing: (rowIndex: number) => {
          return (
            <>
              <FormControl>
                <Select labelId="" id="" onChange={handleOnChangeHour} value={hour}>
                  {[...Array(24)].map((_, i) => {
                    const h = ("00" + String(i)).slice(-2);

                    return (
                      <MenuItem key={i} value={h}>
                        {h}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box className={classes.colon}>：</Box>
              <FormControl>
                <Select labelId="" id="" onChange={handleOnChangeMinutes} value={minutes}>
                  {["00", "30"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          );
        },
      },
    ],
    [
      classes.colon,
      genericClasses.width100percent,
      genericClasses.textAlignCenter,
      notification,
      handleOnChangeHour,
      hour,
      handleOnChangeMinutes,
      minutes,
    ]
  );

  return (
    <Dialog open={props.open} onClose={handleOnClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>未読時の通知設定</DialogTitle>
      <DialogContent>
        {loadingElement ?? (
          <Box className={classes.border}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
              <Grid item>
                <FormControlLabel
                  className={classes.unreadNotification}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={notification.toUnread}
                      onChange={handleOnChangeToUnread}
                      disabled={isContactUser || editingTable}
                    />
                  }
                  label="未読通知"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  className={genericClasses.width100percent}
                  control={
                    <Checkbox
                      color="primary"
                      checked={notification.toDesktop}
                      onChange={handleOnChangeToDesktop}
                      disabled={!notification.toUnread || editingTable}
                    />
                  }
                  label="デスクトップ通知"
                />
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel id="select-customer">メール通知方法</InputLabel>
                  <Select
                    className={genericClasses.width200}
                    id="select-category"
                    value={notification.category}
                    onChange={handleOnChangeSelected}
                    disabled={!notification.toUnread || editingTable}
                  >
                    <MenuItem key="0" value={0}>
                      通知を受けない
                    </MenuItem>
                    <MenuItem key="1" value={1}>
                      受信毎に知らせる
                    </MenuItem>
                    <MenuItem key="2" value={2}>
                      定時に知らせる
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Paper className={classes.sendMailTimesTable}>
                  <VirtualaizedTable
                    values={notification.sendMailTimes}
                    rowHeight={48}
                    columns={columns}
                    onClickAddDirectInput={
                      notification.category === NotificationMailSendType.regular ? handleOnClickAdd : undefined
                    }
                    onClickDelete={handleOnClickDelete}
                    onClickEdit={handleOnClickEdit}
                    onClickSave={handleOnClickSave}
                    onClickCancel={handleOnClickCancel}
                    setEditing={setInputState}
                    onUnmounted={handleOnUnmounted}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.spaceBetween}>
        <Box>
          <Button
            className={genericClasses.margin}
            color="primary"
            onClick={handleOnClickDefault}
            disabled={editingTable}
          >
            デフォルト
          </Button>
        </Box>
        <Box>
          <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
            キャンセル
          </Button>
          <Button
            className={genericClasses.margin}
            onClick={handleOnClickSaveInAction}
            color="primary"
            disabled={!props.open || editingTable || inProcess}
          >
            保存
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

interface ContactProps {
  type: "from" | "to";
}

export default React.memo((props: ContactProps) => {
  const [height] = useContentHeight(props.type === "from" ? Design.topButtonHeight : 0);

  const isContactFromMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.ContactFrom);

  const isContactToMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.ContactTo);

  const isValidEdit = useMemo(
    () => (props.type === "from" ? isContactFromMenuEditValid : isContactToMenuEditValid),
    [props.type, isContactFromMenuEditValid, isContactToMenuEditValid]
  );

  const [open, setOpen] = useState<boolean>(false);

  const [partners, setPartners] = useState<IdName[]>([]);

  const [selected, setSelected] = useState<Contact | null>(null);

  const showReceptionButton = useMemo(() => {
    return (
      props.type === "from" &&
      isValidEdit &&
      (selected?.status === StatusIndex.contact || selected?.status === StatusIndex.business)
    );
  }, [props.type, isValidEdit, selected]);

  const classes = useStyles({ height: height, isContactFrom: showReceptionButton });

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const transition = AppProvider.useGlobalState("transition");

  const notificationHub = AppProvider.useGlobalState("notificationHub");

  const watchingRoomIds = AppProvider.useGlobalState("watchingRoomIds");

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const appDispatch = AppProvider.useDispatch();

  const history = useHistory();

  const [users, setUsers] = useState([] as ComboItem[]);

  const [contacts, setContacts] = useState<Contact[]>([] as Contact[]);

  const [selectedFetchParam, setSelectedFetchParam] = useState<{
    roomId: string;
    notificationId: string | null;
  }>({
    roomId: "",
    notificationId: null,
  });

  const [roomIdOfReceivedMessage, setRoomIdOfReceivedmessage] = useState<{ id: string }>({ id: "" });

  const lastSelectedId = useRef("");

  const [notificationSettingOpen, setNotificationSettingOpen] = useState(false);

  const [lostOrderDialogOpen, setLostOrderDialogOpen] = useState(false);

  const [showData, showDataLength] = useMemo(() => {
    const ret: {
      [key: string]: {
        name: string;
        data: { [key: number]: Contact[] };
      };
    } = {};

    contacts.forEach((value: Contact) => {
      const id = props.type === "from" ? value.customerId : value.providerId;

      if (ret[id] == null) {
        ret[id] = { name: props.type === "from" ? value.customerName : value.providerName, data: {} };

        ret[id].data[StatusIndex.business] = [];
        ret[id].data[StatusIndex.contact] = [];
        ret[id].data[StatusIndex.ordered] = [];
        ret[id].data[StatusIndex.closed] = [];
      }

      ret[id].data[value.status].push(value);
    });

    return [ret, Object.values(ret).length];
  }, [contacts, props.type]);

  const filteredUsers = useMemo(
    () => users.filter((value) => value.value !== selected?.contactUserId),
    [users, selected]
  );

  const fetchResult = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Contact[]>(`/contacts/${props.type}`, {
          cancelToken: signal.token,
        });

        if (response.data == null) {
          return;
        }

        setContacts(response.data);
        setSelected((value) => {
          const target = response.data.find((data) => data.id === value?.id);
          return target ?? null;
        });
      },
      [props.type]
    )
  );

  const handleOnClick = useCallback(
    (data: Contact) => async () => {
      setSelectedFetchParam({ roomId: data.id, notificationId: data.notificationSettingsId });

      let ids = [...watchingRoomIds, data.id];
      if (selected != null) {
        const index = ids.findIndex((value) => value === selected.id);

        if (index !== -1) {
          ids.splice(index, 1);
        }
      }

      appDispatch({ type: AppActionTypes.SET_WATCHING_ROOM_IDS, value: ids });
      await notificationHub?.send("UpdateWatchingRoomIdsToAll", undefined, ids);

      lastSelectedId.current = data.id;
    },
    [appDispatch, notificationHub, watchingRoomIds, selected]
  );

  useEffect(() => {
    return () => {
      if (lastSelectedId.current !== "") {
        notificationHub?.send("RemoveWatchingRoomIdsToAll", undefined, lastSelectedId.current);
      }
    };
  }, [notificationHub]);

  const send = useCallback(async () => {
    if (selected) {
      await notificationHub?.send(
        "SendUnreadNotification",
        () => {},
        NotificationCalledFuncs.ContactReceiveMessage,
        selected.id,
        props.type === "to"
      );
    }
  }, [props.type, notificationHub, selected]);

  const handleOnSuccessedSendMessage = useCallback(async () => {
    await send();
  }, [send]);

  const handleOnSendedFile = useCallback(async () => {
    await send();
  }, [send]);

  const executePut = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { notificationId: string }) => {
      await callWebApi().put(`/NotificationSettings/already_read/${object.notificationId}`);
    }, [])
  );

  const handleOnReceivedMessage = useCallback(
    async (roomId: string, toDesktop: boolean, senderTenantId: string, isSentFromContactTo: boolean) => {
      if (selected?.id === roomId) {
        if (selected.notificationSettingsId != null) {
          executePut({ notificationId: selected.notificationSettingsId });
        }

        await notificationHub?.send("ClearIssueToDesktopProcess");
      } else {
        if (contacts.find((value) => value.id === roomId)) {
          setRoomIdOfReceivedmessage({ id: roomId });
        }

        if (toDesktop && watchingRoomIds.find((value) => value === roomId) === undefined) {
          await notificationHub?.notificationToDesktop(roomId, senderTenantId);
        } else {
          await notificationHub?.send("ClearIssueToDesktopProcess");
        }
      }
    },
    [notificationHub, watchingRoomIds, contacts, selected, executePut]
  );

  const handleOnClickNarrowDown = useNarrowDown(filteredUsers, "text");

  const executePost = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { type: string; id: string; customerId: string; result: ComboItem }
      ) => {
        await callWebApi().put(`/contacts/${object.type}/contactuser`, {
          id: object.id,
          contactUserId: object.result.value,
          customerId: object.customerId,
        });

        alertAdd({ type: "success", message: "担当者を保存しました。" });

        if (unmounted.value) {
          return;
        }

        setSelectedFetchParam((value) => {
          return { ...value };
        });
      },
      [alertAdd]
    )
  );

  const handleOnSelected = useCallback(
    (result: ComboItem) => {
      if (selected != null) {
        executePost({ type: props.type, id: selected.id, customerId: selected.customerId, result: result });
      }
    },
    [props.type, selected, executePost]
  );

  const executePostOrdersReceived = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { roomId: string; customerId: string; customerName: string; history: History<History.UnknownFacade> }
      ) => {
        const response = await callWebApi().post<BusinessInfo>(`/business/reception/${object.roomId}`, {
          customerId: object.customerId,
          customerName: object.customerName,
          name: "",
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (unmounted.value || response.data == null) {
          return;
        }

        appDispatch({ type: AppActionTypes.SET_TRANSITION, value: response.data.id });
        object.history.push(getMenuInfo(MenuIndex.Business).path);
      },
      [appDispatch, alertAdd]
    )
  );

  const handleOnClickOrdersReceived = async () => {
    if (selected && (await message.confirm("受注確認", `受注しますか？`))) {
      executePostOrdersReceived({
        roomId: selected.id,
        customerId: selected.customerId,
        customerName: selected.customerName,
        history: history,
      });
    }
  };

  const handleOnClickLostOrder = () => {
    setLostOrderDialogOpen(true);
  };

  useEffect(() => {
    appDispatch({
      type: AppActionTypes.ADD_NOTIFCATION_CALLED_FUNC,
      value: { key: NotificationCalledFuncs.ContactReceiveMessage, func: handleOnReceivedMessage },
    });

    return () => {
      appDispatch({
        type: AppActionTypes.REMOVE_NOTIFCATION_CALLED_FUNC,
        value: { key: NotificationCalledFuncs.ContactReceiveMessage, func: handleOnReceivedMessage },
      });
    };
  }, [appDispatch, handleOnReceivedMessage]);

  const handleOnClickNotificationSetting = useCallback(() => {
    setNotificationSettingOpen(true);
  }, []);

  const handleOnCloseNotificationSetting = useCallback(() => {
    setNotificationSettingOpen(false);

    if (selected?.notificationSettingsId == null) {
      setSelectedFetchParam((value) => {
        return { ...value };
      });
    }
  }, [selected]);

  const handleOnCloseLostOrder = (isLostOrder: boolean) => {
    setLostOrderDialogOpen(false);

    if (isLostOrder) {
      fetchResult.reload();
    }
  };

  const handleOnClickMakeRoom = useCallback(() => setOpen(true), []);
  const handleOnClose = useCallback((result?: Contact) => {
    if (result) {
      setSelected(result);
      setSelectedFetchParam({ roomId: result.id, notificationId: null });
      setContacts((value) => {
        return [...value, result];
      });
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    if (transition != null && typeof transition === "object") {
      setSelected(transition);
      setSelectedFetchParam({ roomId: transition.id, notificationId: null });
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: null });
    }
  }, [transition, appDispatch]);

  const loadingElement = useLoadingElement(clsx(classes.listArea, classes.select), LoadingMode.Circular, fetchResult);

  const loadingCustomer = useLoadingElement(
    classes.loadingCutomer,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        var response = await callWebApi().get<IdName[]>("/tenants/partners", { cancelToken: signal.token });
        if (response.data == null) {
          return;
        }
        setPartners(response.data);
      }, [])
    )
  );

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Contact>(`contacts/${props.type}/${roomIdOfReceivedMessage.id}`, {
          cancelToken: signal.token,
        });

        if (response.data == null) {
          return;
        }

        setContacts((values) => {
          const index = values.findIndex((value) => value.id === roomIdOfReceivedMessage.id);

          if (index === -1) {
            return values;
          }

          values[index] = response.data;
          return [...values];
        });
      },
      [props.type, roomIdOfReceivedMessage]
    ),
    false
  );

  const loadingContactUserElement = useLoadingElement(
    classes.loadingContact,
    LoadingMode.Simple,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (selectedFetchParam.notificationId != null) {
            await callWebApi().put(`/NotificationSettings/already_read/${selectedFetchParam.notificationId}`);
          }

          const response = await callWebApi().get<Contact>(`contacts/${props.type}/${selectedFetchParam.roomId}`, {
            cancelToken: signal.token,
          });

          if (response.data == null) {
            return;
          }

          setContacts((values) => {
            const index = values.findIndex((value) => value.id === selectedFetchParam.roomId);

            if (index === -1) {
              return values;
            }

            values[index] = response.data;
            return [...values];
          });
          setSelected(response.data);
        },
        [props.type, selectedFetchParam]
      ),
      false
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("users/usermaster", {
          cancelToken: signal.token,
        });

        if (response.data == null) {
          return;
        }

        setUsers(response.data);
      }, [])
    )
  );

  const createList = useCallback(
    (data: { [key: number]: Contact[] }, index: StatusIndex) => {
      return (
        <>
          {data[index].length > 0 && (
            <>
              <h4 className={classes.status} key={data[index][0].id}>
                {props.type === "from" ? StatusFromText[index] : StatusToText[index]}
              </h4>
              <List key={DateUtility.now() + index}>
                {data[index].map((value) => {
                  return (
                    <ListItem
                      className={selectedFetchParam?.roomId === value.id ? classes.selected : ""}
                      key={value.id}
                      onClick={handleOnClick(value)}
                      button
                    >
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <div className={classes.manualId}>{value.manualId}</div>
                        <div className={classes.roomName}>{value.roomName}</div>
                        {value.unreadCount > 0 && (
                          <Box className={classes.unread} borderRadius="borderRadius">
                            <b>{value.unreadCount}</b>
                          </Box>
                        )}
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </>
      );
    },
    [props.type, classes, handleOnClick, selectedFetchParam]
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "ユーザー",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <Box className={classes.box}>
      {props.type === "from" && (
        <>
          {loadingCustomer ?? (
            <Box>
              <Button
                className={clsx(genericClasses.marginLeft, genericClasses.marginBottom)}
                variant="contained"
                color="primary"
                onClick={handleOnClickMakeRoom}
                disabled={!isValidEdit}
              >
                ルーム作成
              </Button>
            </Box>
          )}
        </>
      )}
      <>
        {loadingElement ?? (
          <Box className={clsx(classes.listArea, classes.select)}>
            {Object.values(showData).map((value, index) => {
              return (
                <Paper
                  key={value.name}
                  className={
                    index === showDataLength - 1
                      ? clsx(genericClasses.marginRight, classes.paper)
                      : clsx(genericClasses.marginRight, classes.paper, classes.marginBottom)
                  }
                >
                  <h3 className={classes.tenant} key={value.name}>
                    {value.name}
                  </h3>
                  <Divider />
                  {createList(value.data, StatusIndex.business)}
                  {createList(value.data, StatusIndex.contact)}
                  {createList(value.data, StatusIndex.ordered)}
                  {createList(value.data, StatusIndex.closed)}
                </Paper>
              );
            })}
          </Box>
        )}
        {selected && (
          <Box className={classes.chat}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Paper className={classes.notificationPaper}>
                    <IconButton color="primary" onClick={handleOnClickNotificationSetting}>
                      <NotificationsIcon />
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid item>
                  <RoomName type={props.type} contact={selected} setContacts={setContacts} disabled={!isValidEdit} />
                </Grid>
                {ralmAccount?.goodSeriesInfo.category !== belongCategory[BelongIndex.Operating].category && (
                  <>
                    {loadingContactUserElement ?? (
                      <>
                        <Grid item>
                          <Paper className={classes.contactUser}>
                            <LabelWithSelect
                              caption="担当者"
                              text={selected.contactUserName}
                              placeHolder="ここをクリックすると担当者を設定できます。"
                              data={filteredUsers}
                              columns={columns}
                              onClickNarrowDown={handleOnClickNarrowDown}
                              onSelected={handleOnSelected}
                              maxWidth="sm"
                              disabled={!isValidEdit}
                            />
                          </Paper>
                        </Grid>
                        {showReceptionButton && (
                          <>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={handleOnClickOrdersReceived}
                                disabled={
                                  (selected?.status !== StatusIndex.contact &&
                                    selected?.status !== StatusIndex.business) ||
                                  !isValidEdit
                                }
                              >
                                受注
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={handleOnClickLostOrder}
                                disabled={
                                  (selected?.status !== StatusIndex.contact &&
                                    selected?.status !== StatusIndex.business) ||
                                  !isValidEdit
                                }
                              >
                                失注
                              </Button>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <ChatForRaLM
                    id={selectedFetchParam?.roomId ?? selected.id}
                    height={height - 16 - 48 - 8}
                    disabled={!isValidEdit}
                    onSuccessedSendMessage={handleOnSuccessedSendMessage}
                    onSendedFile={handleOnSendedFile}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
        <AddRoomDialog open={open} onClose={handleOnClose} partners={partners} />
      </>
      {selected && (
        <>
          <NotificationSettingDialog
            open={notificationSettingOpen}
            id={selected.notificationSettingsId}
            roomId={selected.id}
            customerId={selected.customerId}
            contactUserId={selected.contactUserId}
            onClose={handleOnCloseNotificationSetting}
          />
          <LostOrderDialog
            open={lostOrderDialogOpen}
            manualId={selected.manualId}
            roomId={selected.id}
            contactUserId={selected.contactUserId}
            onClose={handleOnCloseLostOrder}
          />
        </>
      )}
    </Box>
  );
});
