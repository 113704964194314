import { useCallback, useState } from "react";

export const useOpens = (
  numberOf: number
): [(index: number) => boolean, (index: number) => void, (index?: number) => void] => {
  const [opens, setOpens] = useState<boolean[]>([...Array(numberOf)].fill(false));

  const status = useCallback(
    (index: number) => {
      return opens[index];
    },
    [opens]
  );

  const open = useCallback((index: number) => {
    setOpens((value) => {
      value[index] = true;
      return [...value];
    });
  }, []);

  const close = useCallback((index?: number) => {
    setOpens((value) => {
      if (index === undefined) {
        value = Array(value.length).fill(false);
        return [...value];
      } else {
        value[index] = false;
        return [...value];
      }
    });
  }, []);

  return [status, open, close];
};
