import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSimpleFetch } from "Common/Utility/Api";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { Design, MakeOptionalComboItems, simpleColumnData, TaskStatus, toComboText } from "Common/Utility/Constants";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { Align, VerticalAlign } from "Common/Utility/Drawing";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { ColumnInfo } from "Component/Canvas";
import DigitsField from "Component/DigitsField";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import Period from "Component/Period";
import TaskSchedule from "Component/Schedule/TaskSchedule";
import Select from "Component/Select";
import TextField from "Component/TextField";
import { WorkTask } from "Models/WorkTask";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import EditTaskDialog from "./EditTaskDialog";
import clsx from "clsx";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { useResizeObserver } from "Hooks/useResize";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";

const taskStatusComboItems = MakeOptionalComboItems(TaskStatus);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: Design.scheduleMinHeight,
  },
  list: {
    width: "100%",
    height: 200,
  },
  status: {
    width: 60,
  },
  workNumber: {
    width: 150,
  },
  user: {
    width: 150,
  },
  numberOfPepole: {
    width: 50,
  },
}));

interface Condition {
  workNumber?: string;

  workName?: string;

  taskName?: string;

  userId: string | null;

  userName: string;

  numberOf?: number;

  status: number | null;

  isTask: boolean;

  from?: DateTime;

  to?: DateTime;
}

const initialCondition: Condition = {
  status: null,
  isTask: true,
  userId: null,
  userName: "未選択",
};

interface Props {
  height: number;
}

const TaskPanel = (props: Props) => {
  const [height, setHeight] = useState<number>(0);

  const [rect, resizeRef] = useResizeObserver();

  useEffect(() => {
    setHeight(props.height / 2 - rect.height - Design.margin);
  }, [rect, props.height]);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [conditionUsers, setConditionUsers] = useState<ComboItem[]>([]);

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>({} as Condition);

  const [workTasks, setWorkTasks] = useState<WorkTask[]>([]);

  const [workTask, setWorkTask] = useState<WorkTask | undefined>(undefined);

  const inputManager = useInputManager(setCondition);

  const loadingElement = useLoadingElement(
    classes.paper,
    LoadingMode.Circular,
    useSimpleFetch("/schedule", setWorkTasks, false, DateUtility.InvalidToNull(search, "from", "to"), [search]),
    useSimpleFetch("/users/usermaster", (data: ComboItem[]) => {
      data.unshift({ value: null, text: "未選択" } as ComboItem);
      setConditionUsers(data);
    })
  );

  const handleOnClickAdd = useCallback(() => {
    setWorkTask({
      name: "",
      status: 0,
      taskMembers: [],
    } as WorkTask);
  }, []);

  const handleOnClickEdit = useCallback((workTask: WorkTask) => {
    if (workTask == null) {
      return;
    }
    setWorkTask(workTask);
  }, []);

  const handleOnClose = useOnCloseEditDialog("Task", setWorkTask, setWorkTasks, "id");

  const columns: ColumnInfo<WorkTask>[] = useMemo(
    () => [
      {
        width: 300,
        text: "タスク名",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: WorkTask) => data.name,
        dataKey: "name",
      },
      {
        width: 50,
        text: "人数",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.right,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: WorkTask) => data.taskMembers.length.toString(),
        dataKey: "taskMembers",
      },
      {
        width: 50,
        text: "状態",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.center,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: WorkTask) => toComboText(TaskStatus, data.status),
        dataKey: "status",
      },
    ],
    []
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Schedule);

  return (
    <>
      {workTask != null && (
        <EditTaskDialog open={true} onClose={handleOnClose} workTask={workTask} editAuth={isValidEdit} />
      )}
      <Grid ref={resizeRef} item xs={12}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <TextField
            className={clsx(genericClasses.margin, classes.workNumber)}
            label="工事番号"
            value={condition.workNumber}
            onChange={inputManager.handleOnChange("workNumber")}
          />
          <TextField
            className={genericClasses.margin}
            label="工事名"
            value={condition.workName}
            onChange={inputManager.handleOnChange("workName")}
          />
          <TextField
            className={genericClasses.margin}
            label="タスク名"
            value={condition.taskName}
            onChange={inputManager.handleOnChange("taskName")}
          />
          <LabelWithSelect
            className={clsx(genericClasses.margin, classes.user)}
            caption="名前"
            text={condition.userName}
            data={conditionUsers}
            columns={simpleColumnData("名前", "text")}
            onClickNarrowDown={useNarrowDown(conditionUsers, "text")}
            onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
              return { ...value, userId: result.value, userName: result.text };
            })}
            maxWidth="sm"
            underLine
          />
          <DigitsField
            className={clsx(genericClasses.margin, classes.numberOfPepole)}
            label="人数"
            value={condition.numberOf}
            onChange={inputManager.handleOnChangeNumber("numberOf")}
          />
          <FormControl className={clsx(classes.status, genericClasses.margin)}>
            <InputLabel id="select-status">状態</InputLabel>
            <Select id="select-status" onChange={inputManager.handleOnChangeSelect("status")} value={condition.status}>
              {taskStatusComboItems.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.value}>
                    {value.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            className={genericClasses.margin}
            control={
              <Checkbox
                color="primary"
                checked={condition.isTask}
                onChange={inputManager.handleOnChangeCheck("isTask")}
              />
            }
            label="期間内にタスク有り"
          />
          <Period
            label="期間"
            fromValue={condition.from}
            toValue={condition.to}
            onChangeFrom={inputManager.handleOnChangeDate("from")}
            onChangeTo={inputManager.handleOnChangeDate("to")}
          />
          <Button
            className={genericClasses.margin}
            variant="contained"
            color="primary"
            onClick={() => setSearch({ ...condition })}
          >
            検索
          </Button>
          <Button className={genericClasses.margin} variant="outlined" onClick={() => setCondition(initialCondition)}>
            クリア
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <TaskSchedule
            className={classes.paper}
            rows={workTasks}
            setRows={setWorkTasks}
            columns={columns}
            onClickEdit={handleOnClickEdit}
            onClickAdd={handleOnClickAdd}
            isValidEdit={isValidEdit}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(TaskPanel);
