import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useMessageBox } from "Hooks/useMessageBox";
import { useGenericStyles } from "Common/Utility/Styles";
import { ModelMaster } from "Models/ModelMaster";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute, useFetch } from "Hooks/useFetch";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useContentHeight } from "Hooks/useResize";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { EditModelDialog } from "./EditModelDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface ModelCondition {
  model: string;

  name: string;

  indexRuleName: string;
}

export const ModelPanel = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const [condition, setCondition] = useState<ModelCondition>({ model: "", name: "", indexRuleName: "" });

  const [data, setData] = useState<ModelMaster[]>([]);

  const [editData, setEditData] = useState<ModelMaster | undefined>(undefined);

  const [search, setSearch] = useState<ModelCondition>({
    model: "",
    name: "",
    indexRuleName: "",
  });

  const inputManager = useInputManager(setCondition);

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get("/modelmaster", {
          cancelToken: signal.token,
          params: { model: search.model, name: search.name, indexRuleName: search.indexRuleName },
        });

        if (response.data == null) {
          return;
        }

        setData(response.data);
      },
      [search]
    ),
    false
  );

  const handleOnClickSearch = useCallback(async () => setSearch({ ...condition }), [condition]);

  const handleOnClickClear = useCallback(() => setCondition({ model: "", name: "", indexRuleName: "" }), []);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      model: "",
      name: "",
      supplierId: null,
      supplierName: "未選択",
      cost: null,
      indexRuleId: null,
      indexRuleName: "未選択",
      checkSheetId: null,
      checkSheetName: "未選択",
    });
  }, []);

  const handleOnClickEdit = useCallback(
    (modelMaster: ModelMaster, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...modelMaster });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { id: string; rowIndex: number }) => {
      await callWebApi().delete(`/modelmaster/${object.id}`);

      if (unmounted.value) {
        return;
      }

      setData((value) => {
        value.splice(object.rowIndex, 1);
        return [...value];
      });
    }, [])
  );

  const handleOnClickDelete = useCallback(
    async (modelMaster: ModelMaster, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "型式情報を削除します。よろしいですか？"))) {
        return;
      }

      if (modelMaster.id) {
        executeDelete({ id: modelMaster.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog("Model", setEditData, setData, "id");

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.AssetModel
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isAssetMenuEditValid) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 300,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        width: 500,
        label: "名称",
        dataKey: "name",
        fit: true,
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 300,
        label: "採番ルール名称",
        dataKey: "indexRuleName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      }
    );

    return ret;
  }, [isAssetMenuEditValid, getVisible]);

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Condition observer={conditionRef}>
          <>
            <TextField
              className={genericClasses.margin}
              label="型式"
              value={condition.model}
              onChange={inputManager.handleOnChange("model")}
            />
            <TextField
              className={genericClasses.margin}
              label="名称"
              value={condition.name}
              onChange={inputManager.handleOnChange("name")}
            />
            <TextField
              className={genericClasses.margin}
              label="採番ルール名称"
              value={condition.indexRuleName}
              onChange={inputManager.handleOnChange("indexRuleName")}
            />
            <Button className={genericClasses.margin} variant="contained" color="primary" onClick={handleOnClickSearch}>
              検索
            </Button>
            <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
              クリア
            </Button>
          </>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultLoad) ?? (
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={data}
              setValues={setData}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onClickAdd={isAssetMenuEditValid ? handleOnClickAdd : undefined}
              onClickEdit={handleOnClickEdit}
              onClickReference={handleOnClickEdit}
              onClickDelete={handleOnClickDelete}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      {editData != null && <EditModelDialog open={true} data={editData} onClose={handleOnClose} />}
    </Grid>
  );
});
