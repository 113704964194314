export class Point {
  x: number = 0;

  y: number = 0;
}

export class Rect {
  public static get zero(): Rect {
    return { x: 0, y: 0, w: 0, h: 0 } as Rect;
  }

  x: number = 0;

  y: number = 0;

  w: number = 0;

  h: number = 0;
}

export class Size {
  width: number = 0;

  height: number = 0;
}

export class Font {
  name: string = "Meiryo";

  size: number = 20;

  constructor(name: string, size: number) {
    this.name = name;
    this.size = size;
  }

  toFont(): string {
    return `${this.size.toString()}pt ${this.name}`;
  }
}

export class Color {
  r: number = 0;

  g: number = 0;

  b: number = 0;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  toRGB(): string {
    return `rgb(${this.r.toString()},${this.g.toString()},${this.b.toString()})`;
  }
}

export const Align = {
  left: "left",
  center: "center",
  right: "right",
} as const;
export type Align = typeof Align[keyof typeof Align];

export const VerticalAlign = {
  top: "top",
  middle: "middle",
  bottom: "bottom",
} as const;
export type VerticalAlign = typeof VerticalAlign[keyof typeof VerticalAlign];

export const numberTo4Arr = (value: number | [number, number, number, number]): [number, number, number, number] => {
  if (Array.isArray(value)) {
    return value;
  }

  return [value, value, value, value];
};

export class DrawUtility {
  static inRect(rect: Rect, point: Point): boolean {
    return point.x >= rect.x && point.x <= rect.x + rect.w && point.y >= rect.y && point.y <= rect.y + rect.h;
  }

  static mousePosition(canvas: HTMLCanvasElement, event: MouseEvent) {
    var rect = canvas.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    } as Point;
  }

  static measureText(context: CanvasRenderingContext2D, text: string): TextMetrics {
    return context.measureText(text);
  }

  static fillText(
    context: CanvasRenderingContext2D,
    text: string | null | undefined,
    rect: Rect,
    font: Font,
    align: Align,
    vAlign: VerticalAlign
  ) {
    if (text == null) {
      return;
    }

    context.font = font.toFont();

    var textMetrics = context.measureText(text);

    // 描画範囲よりも文字幅が大きい場合は末尾を...に変更する。
    if (textMetrics.width > rect.w) {
      var newText = "...";
      var size = context.measureText(newText);
      if (size.width > rect.w) {
        // 描画無し
        return;
      } else {
        var str = "";
        for (let i = 0; i < text.length; ++i) {
          size = context.measureText(str + text.charAt(i) + "...");
          if (size.width > rect.w) {
            text = str + "...";
            textMetrics = context.measureText(text);
            break;
          } else {
            str += text.charAt(i);
          }
        }
      }
    }

    let x = rect.x;
    if (align === Align.center) {
      x += (rect.w - textMetrics.width) / 2;
    } else if (align === Align.right) {
      x += rect.w - textMetrics.width;
    }

    let y = rect.y;
    if (vAlign === VerticalAlign.middle) {
      y += (rect.h - font.size) / 2;
    } else if (vAlign === VerticalAlign.bottom) {
      y += rect.h - font.size;
    }

    context.fillText(text, x, y + font.size);
  }

  static fillRect(
    context: CanvasRenderingContext2D,
    rect: Rect,
    radius: number | [number, number, number, number] | undefined
  ) {
    if (radius == null || radius === 0) {
      context.fillRect(rect.x, rect.y, rect.w, rect.h);
    } else {
      let tmpRadius = numberTo4Arr(radius);
      context.beginPath();
      context.moveTo(rect.x, rect.y + rect.h - tmpRadius[0]);
      context.arcTo(rect.x, rect.y, rect.x + rect.w, rect.y, tmpRadius[0]);
      context.arcTo(rect.x + rect.w, rect.y, rect.x + rect.w, rect.y + rect.h, tmpRadius[1]);
      context.arcTo(rect.x + rect.w, rect.y + rect.h, rect.x, rect.y + rect.h, tmpRadius[2]);
      context.arcTo(rect.x, rect.y + rect.h, rect.x, rect.y, tmpRadius[3]);
      context.closePath();
      context.fill();
    }
  }

  static fillRectEx(
    context: CanvasRenderingContext2D,
    rect: Rect,
    clip: Path2D,
    radiusTopLeft: number,
    radiusTopRight: number,
    radiusBottomRight: number,
    radiusBottomLeft: number
  ) {
    context.save();
    context.beginPath();
    context.moveTo(rect.x, rect.y + rect.h - radiusBottomLeft);
    context.arcTo(rect.x, rect.y, rect.x + rect.w, rect.y, radiusTopLeft);
    context.arcTo(rect.x + rect.w, rect.y, rect.x + rect.w, rect.y + rect.h, radiusTopRight);
    context.arcTo(rect.x + rect.w, rect.y + rect.h, rect.x, rect.y + rect.h, radiusBottomRight);
    context.arcTo(rect.x, rect.y + rect.h, rect.x, rect.y, radiusBottomLeft);
    context.closePath();
    context.clip(clip);
    context.fill();
    context.restore();
  }

  static strokeRect(
    context: CanvasRenderingContext2D,
    rect: Rect,
    radius: number | [number, number, number, number] | undefined
  ) {
    if (radius == null || radius === 0) {
      context.strokeRect(rect.x, rect.y, rect.w, rect.h);
    } else {
      let tmpRadius = numberTo4Arr(radius);
      context.beginPath();
      context.moveTo(rect.x, rect.y + rect.h - tmpRadius[0]);
      context.arcTo(rect.x, rect.y, rect.x + rect.w, rect.y, tmpRadius[0]);
      context.arcTo(rect.x + rect.w, rect.y, rect.x + rect.w, rect.y + rect.h, tmpRadius[1]);
      context.arcTo(rect.x + rect.w, rect.y + rect.h, rect.x, rect.y + rect.h, tmpRadius[2]);
      context.arcTo(rect.x, rect.y + rect.h, rect.x, rect.y, tmpRadius[3]);
      context.closePath();
      context.stroke();
    }
  }

  static strokeRectEx(
    context: CanvasRenderingContext2D,
    rect: Rect,
    clip: Path2D,
    radiusTopLeft: number,
    radiusTopRight: number,
    radiusBottomRight: number,
    radiusBottomLeft: number
  ) {
    context.save();
    context.beginPath();
    context.moveTo(rect.x, rect.y + rect.h - radiusBottomLeft);
    context.arcTo(rect.x, rect.y, rect.x + rect.w, rect.y, radiusTopLeft);
    context.arcTo(rect.x + rect.w, rect.y, rect.x + rect.w, rect.y + rect.h, radiusTopRight);
    context.arcTo(rect.x + rect.w, rect.y + rect.h, rect.x, rect.y + rect.h, radiusBottomRight);
    context.arcTo(rect.x, rect.y + rect.h, rect.x, rect.y, radiusBottomLeft);
    context.closePath();
    context.clip(clip);
    context.stroke();
    context.restore();
  }

  static line(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number): void {
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
  }

  static polyline(context: CanvasRenderingContext2D, ...points: Point[]): void {
    if (points.length < 2) {
      return;
    }

    context.beginPath();
    context.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; ++i) {
      context.lineTo(points[i].x, points[i].y);
    }
    context.stroke();
  }

  static fill(context: CanvasRenderingContext2D, offset: Point, ...points: Point[]): void {
    if (points.length < 2) {
      return;
    }

    context.beginPath();
    context.moveTo(offset.x + points[0].x, offset.y + points[0].y);
    for (let i = 1; i < points.length; ++i) {
      context.lineTo(offset.x + points[i].x, offset.y + points[i].y);
    }
    context.closePath();
    context.fill();
  }

  static flowLine(
    context: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    radius: number
  ): void {
    context.beginPath();
    context.moveTo(x1, y1);
    const half = x1 + Math.abs(x2 - x1) / 2;
    context.arcTo(half, y1, half, y2, radius);
    context.arcTo(half, y2, x2, y2, radius);
    context.lineTo(x2, y2);
    context.stroke();
  }

  static checkBox(context: CanvasRenderingContext2D, rect: Rect, checked: boolean, style: string = "rgb(63,81,181)") {
    const r = { x: rect.x + rect.w / 2 - 9, y: rect.y + rect.h / 2 - 9, w: 18, h: 18 };
    if (checked) {
      context.fillStyle = "rgb(117,117,117)";
      DrawUtility.fillRect(context, r, 3);
      context.strokeStyle = "white";
      context.lineWidth = 2;
      DrawUtility.polyline(
        context,
        { x: r.x + 3, y: r.y + 8 },
        { x: r.x + 7, y: r.y + 13 },
        { x: r.x + 15, y: r.y + 5 }
      );
    } else {
      context.strokeStyle = "rgb(117,117,117)";
      context.lineWidth = 2;
      DrawUtility.strokeRect(context, r, 3);
    }
  }

  static editIcon(context: CanvasRenderingContext2D, rect: Rect, fillStyle: string = "rgb(63,81,181)") {
    context.save();

    const position = { x: rect.x + rect.w / 2 - 9, y: rect.y + rect.h / 2 - 9 };
    context.fillStyle = fillStyle;
    DrawUtility.fill(
      context,
      position,
      { x: 0, y: 14 },
      { x: 0, y: 18 },
      { x: 4, y: 18 },
      { x: 15, y: 7 },
      { x: 11, y: 3 }
    );
    DrawUtility.fill(context, position, { x: 12, y: 2 }, { x: 16, y: 6 }, { x: 18, y: 4 }, { x: 14, y: 0 });

    context.restore();
  }

  static deleteIcon(context: CanvasRenderingContext2D, rect: Rect, fillStyle: string = "rgb(63,81,181)") {
    context.save();

    const position = { x: rect.x + rect.w / 2 - 7, y: rect.y + rect.h / 2 - 9 };
    context.fillStyle = fillStyle;
    DrawUtility.fill(
      context,
      position,
      { x: 0, y: 1 },
      { x: 0, y: 3 },
      { x: 14, y: 3 },
      { x: 14, y: 1 },
      { x: 10, y: 1 },
      { x: 10, y: 0 },
      { x: 4, y: 0 },
      { x: 4, y: 1 }
    );
    DrawUtility.fill(
      context,
      position,
      { x: 1, y: 4 },
      { x: 1, y: 17 },
      { x: 3, y: 18 },
      { x: 11, y: 18 },
      { x: 13, y: 17 },
      { x: 13, y: 4 }
    );

    context.restore();
  }
}
