import React, { useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { DateUtility } from "Common/Utility/DateUtility";
import { createPortal } from "react-dom";

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  invisibleFrame: {
    position: "fixed",
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
    border: 0,
  },
}));

export const callPrint = (id: string) => {
  const iframe = document.getElementById(id) as HTMLIFrameElement;
  if (iframe) {
    iframe.contentWindow?.print();
  }
};

interface Props {
  id: string;
  title: string;
}

export const Print: React.FC<Props> = (props) => {
  const classes = useStyles();
  const iframeElement = useRef<HTMLIFrameElement>(null);
  const mountNode = iframeElement.current?.contentWindow?.document?.body;

  return (
    <iframe id={props.id} title={props.title} ref={iframeElement} className={classes.invisibleFrame}>
      {mountNode && createPortal(props.children, mountNode)}
    </iframe>
  );
};

interface TableProps {
  data: any[];
  createHeader: () => JSX.Element;
  createRow: (data: any, index: number) => JSX.Element;
  createFooter: (data: any[]) => JSX.Element;
  maxRowInFristPage: number;
  maxRowInPage: number;
}

export const Table = React.memo((props: TableProps) => {
  const group = useMemo(() => {
    const group: any[][] = [];
    let oneSet: any[] = [];
    for (let i = 0; i < props.data.length; ++i) {
      oneSet.push(props.data[i]);
      if (i + 1 === props.maxRowInFristPage || (i + 1 - props.maxRowInFristPage) % props.maxRowInPage === 0) {
        group.push(oneSet);
        oneSet = [];
      }
    }
    if (oneSet.length !== 0) {
      group.push(oneSet);
    }

    return group;
  }, [props.data, props.maxRowInFristPage, props.maxRowInPage]);

  const element = useMemo(() => {
    let count = 0;
    return (
      <>
        {group.map((value, index) => {
          return (
            <table key={DateUtility.now() + index.toString()} className="break-page">
              <thead>{props.createHeader()}</thead>
              <tbody>
                {value.map((data, index) => {
                  return props.createRow(data, count++);
                })}
                {index === group.length - 1 ? props.createFooter(props.data) : <></>}
              </tbody>
            </table>
          );
        })}
      </>
    );
  }, [group, props]);

  return element;
});
