import { DateUtility } from "Common/Utility/DateUtility";
import React, { useMemo } from "react";
import clsx from "clsx";
import { StringUtility } from "Common/Utility/StringUtility";
import { ShippingAdvice, ShippingAdviceDetail } from "Models/ShippingAdvice";
import { calcReportPages } from "../Report";

interface ShippingAdviceDetailDesignProps {
  details: ShippingAdviceDetail[];
}

const ShippingAdviceDetailDesign = (props: ShippingAdviceDetailDesignProps) => {
  const data = useMemo(() => {
    var ret: ShippingAdviceDetail[][] = [];
    const details = [...props.details];

    const pages = calcReportPages(18, 4, 28, details.length);

    pages.forEach((page) => {
      ret.push(details.splice(0, page));
    });

    return ret;
  }, [props.details]);

  return (
    <>
      {data.map((details, detailsIndex) => {
        return (
          <div
            key={detailsIndex.toString()}
            className={clsx("detail", detailsIndex !== data.length - 1 ? "break-page" : "")}
          >
            <table>
              <thead>
                <tr>
                  <th className="leftCell">品　　　名</th>
                  <th>購入数量</th>
                  <th>レンタル数量</th>
                  <th>貸出期限</th>
                  <th className="rightCell">摘　　要</th>
                </tr>
              </thead>
              <tbody>
                {details.map((detail, detailIndex) => {
                  return (
                    <tr key={detailsIndex.toString() + "-" + detailIndex.toString()}>
                      <td className="leftCell nameCell">{`${detail.model} ${detail.name}`}</td>
                      <td className="amountCell">{detail.numberOfSales ?? ""}</td>
                      <td className="amountCell">{detail.numberOf ?? ""}</td>
                      <td className="dateCell">{detail.to == null ? "" : `～ ${DateUtility.format(detail.to)}`}</td>
                      <td className="rightCell descriptionCell"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};

interface ShippingAdviceSummaryDesignProps {
  signImage: string;

  shippingAdvice: ShippingAdvice;
}

const ShippingAdviceSummaryDesign = React.memo((props: ShippingAdviceSummaryDesignProps) => {
  let companyName: JSX.Element = <>{props.shippingAdvice.companyName}</>;
  const isNull1 = StringUtility.isNullOrUndefeinedOrEmpty(props.shippingAdvice.companyName1);
  const isNull2 = StringUtility.isNullOrUndefeinedOrEmpty(props.shippingAdvice.companyName2);
  if (!isNull1 && !isNull2) {
    companyName = (
      <div className="customerNameHalf">
        {props.shippingAdvice.companyName1}
        <br />
        {props.shippingAdvice.companyName2}
      </div>
    );
  } else if (!isNull1) {
    companyName = (
      <div className="customerNameHalf">
        {props.shippingAdvice.companyName1}
        <br />
      </div>
    );
  } else if (!isNull2) {
    companyName = (
      <div className="customerNameHalf">
        <br />
        {props.shippingAdvice.companyName2}
      </div>
    );
  }

  return (
    <div>
      <div className="r-container justify-center">
        <div className="title">
          <span>出荷案内書</span>
        </div>
      </div>
      <div className="r-container justify-start margin20">
        <div className="leftBlock">
          <div className="customerBox">
            <div className="r-container justify-space-between">
              <div className="customerName">{companyName}</div>
              <div>
                <span>御中</span>
              </div>
            </div>
          </div>
          <div className="r-container justify-center margin20">
            <div className="deliveryString">
              <span>下記の通り出荷致しましたからご案内申し上げます。</span>
              <br />
            </div>
          </div>
          <div className="deliveryDate">
            <span>{DateUtility.format(props.shippingAdvice.shipDate, "yyyy 年 M 月 d 日")}</span>
          </div>
        </div>
        <div className="rightBlock">
          <div className="company">
            <span style={{ fontSize: "24px" }}>株式会社 弘栄ドリームワークス</span>
            <br />
            <span>〒 990-2221 山形県山形市大字風間字地蔵山下2068</span>
            <br />
            <span>TEL : 023-616-5735 FAX : 023-616-5652</span>
            <div className="companySign">
              <img src={"data:image/png;base64," + props.signImage} alt="company sign" style={{ width: 110 }} />
            </div>
          </div>
        </div>
      </div>
      <div className="margin20">
        <ShippingAdviceDetailDesign details={props.shippingAdvice.details} />
      </div>
      <div className="r-container margin10">
        <div className="remark">
          <span>備考</span>
        </div>
      </div>
      <div className="r-container margin10 break-page">
        <div className="remarkValue">
          <br />
        </div>
      </div>
    </div>
  );
});

interface Props {
  signImage: string;

  shippingAdvices: ShippingAdvice[];
}

const ShippingAdviceDesign = (props: Props) => {
  return (
    <>
      <style type="text/css">
        {`@page {
            margin: 12.7mm 9.7mm;
            size: A4 landscape;
          }
          .break-page {
            break-after: page;
          }
          .main {
            font-family: Meiryo;
            font-size: 12px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          .f-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .r-container {
            display: flex;
            flex-direction: row;            
            align-items: flex-start;
            width: 100%
          }
          .justify-start {
            justify-content: flex-start;
          }
          .justify-center {
            justify-content: center;
          }
          .justify-end {
            justify-content: flex-end;
          }
          .justify-space-between {
            justify-content: space-between;
          }
          .margin10 {
            margin-top: 10px;
          }
          .margin20 {
            margin-top: 20px;
          }
          .margin40 {
            margin-top: 40px;
          }
          .voucherNumber {
            border-bottom: solid 1px black;
          }
          .title {
            width: 350px;
            font-size: 28px;
            border-bottom: solid 1px black;
            text-align: center;
            font-weight: bold;
            letter-spacing: 0.25em;
          }
          .leftBlock {
            width: 450px;
          }
          .rightBlock {
            width: calc(100% - 450px);
          }
          .company {
            height: 100px;
            text-align: right;
            position: relative;
            padding-top: 20px;
          }
          .companySign {
            position: absolute;
            top: 0px;
            right: 0px;
          }
          .signTable {
            width: 180px;
            border: solid 1px black;
            margin-top: 15px;
          }
          .signBody {
            height: 90px;
          }
          .signCell {
            width: 90px;
            height: 90px;
            text-align: center;
          }
          .customerBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            font-size: 24px;
          }
          .customerName {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .customerNameHalf {
            font-size: 12px;
          }
          .constructionBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .deliveryString {
            width: 300px;
          }
          .deliveryDate {
            width: 100%;
            text-align: center;
          }
          .amountUnit {
            font-size: 24px;
            width: 50px;
            border-bottom: double 4px black;
            padding-left: 25px;
          }
          .amount {
            font-size: 24px;
            width: 250px;
            border-bottom: double 4px black;
          }
          .detail {
            width: 100%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;
          }
          th, td {
            border: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .leftCell {
            border-left: none;
          }
          .rightCell {
            border-right: none;
          }
          .nameCell {
            width: 400px;
            max-width: 400px;
            padding-left: 10px;
          }
          .amountCell {
            width: 100px;
            max-width: 100px;
            text-align: right;
            padding-right: 10px;
          }
          .dateCell {
            width: 100px;
            max-width: 100px;
            text-align: center;
          }
          .descriptionCell {
            padding-left: 10px;
          }
          .remark {
            padding-left: 10px;
          }
          .remarkValue {
            width: 100%;
            padding-left: 10px;
            border-bottom: solid 1px black;
          }
            `}
      </style>
      <div className="main">
        {props.shippingAdvices
          .filter((i) => i.checked)
          .map((value, index) => {
            return (
              <div key={value.id + index.toString()}>
                <ShippingAdviceSummaryDesign signImage={props.signImage} shippingAdvice={value} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default React.memo(ShippingAdviceDesign);
