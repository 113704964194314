import React, { useMemo, useState } from "react";
import { Button, Checkbox, Grid, makeStyles, Paper } from "@material-ui/core";
import { ReportConfig } from "Models/ReportConfig";
import Period from "Component/Period";
import { useGenericStyles } from "Common/Utility/Styles";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { callPrint, Print } from "Common/Component/Print";
import Condition from "Component/Condition";
import { CheckColumn, Checked, simpleColumnData } from "../Report";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useContentHeight } from "Hooks/useResize";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useSimpleFetch } from "Common/Utility/Api";
import { ComboItem } from "Common/Utility/GenericInterface";
import { Group } from "Models/Group";
import { FormControlLabel } from "@material-ui/core";
import EstimateDesign from "./EstimateDesign";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 100,
  },
  labelWithSelect: {
    width: 300,
    margin: theme.spacing(1),
  },
  version: {
    width: 50,
    margin: theme.spacing(1),
  },
}));

export interface EstimateSummary extends Checked {
  id: string;

  estimateNumber: string;

  companyName: string;

  companyName1: string;

  companyName2: string;

  estimateDate: DateTime;

  expirationDate: DateTime;

  staffStamp: string;

  approvalStamp: string;

  note: string;

  details: EstimateDetail[];

  amount: number;

  version: number;

  constructionName: string;

  staffName: string;

  approvalName: string;

  approvalDate: string;

  customerClosingDay: number;
}

export interface EstimateDetail {
  numberOfItem: number;

  className: string;

  name: string;

  basicPrice: number | null;

  unitPrice: number | null;

  numberOf: number | null;

  unit: string;

  fromDate: DateTime | null;

  toDate: DateTime | null;

  period: string;

  payOff: number | null;

  price: number | null;

  summary: string;

  taxExemption: boolean;
}

interface Condition {
  estimateNumber: string;

  newest: boolean;

  versionNumber?: number;

  unApproved: boolean;

  approved: boolean;

  companyId: string;

  companyName: string;

  constructionId: string;

  constructionName: string;

  staffId: string;

  staffName: string;

  approverId: string;

  approverName: string;

  estimateDateFrom: DateTime | null;

  estimateDateTo: DateTime | null;

  approvedDateFrom: DateTime | null;

  approvedDateTo: DateTime | null;
}

const defaultCondition: Condition = {
  estimateNumber: "",
  newest: true,
  versionNumber: undefined,
  unApproved: true,
  approved: true,
  companyId: "",
  companyName: "",
  constructionId: "",
  constructionName: "",
  staffId: "",
  staffName: "",
  approverId: "",
  approverName: "",
  estimateDateFrom: null,
  estimateDateTo: null,
  approvedDateFrom: null,
  approvedDateTo: null,
};

const Report = () => {
  const [height, ref] = useContentHeight(55 + 44.5);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(defaultCondition);

  const [search, setSearch] = useState<Condition>(defaultCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<EstimateSummary[]>([]);

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  const [constructions, setConstructions] = useState<Group[]>([]);

  const [staff, setStaff] = useState<ComboItem[]>([]);

  const [approver, setApprover] = useState<ComboItem[]>([]);

  const [signImage, setSignImage] = useState<string>("");

  const [logoImage, setLogoImage] = useState<string>("");

  const fetchdata = useSimpleFetch(
    "/estimate",
    setData,
    false,
    DateUtility.InvalidToNull(search, "estimateDateFrom", "estimateDateTo", "approvedDateFrom", "approvedDateTo"),
    [search]
  );

  const fetchCompany = useSimpleFetch("/companies/customer", setCustomers);

  const fetchConstruction = useSimpleFetch("/group", setConstructions);

  const fetchStaff = useSimpleFetch("/users/usermaster", setStaff);

  const fetchApprover = useSimpleFetch("/users/approver", setApprover);

  const fetchLogoImage = useSimpleFetch("/reports/kdwlogo", (data: { logoImage: string }) => {
    setLogoImage(data.logoImage);
  });

  const fetchSignImage = useSimpleFetch("/reports/kdwsign", (data: { signImage: string }) => {
    setSignImage(data.signImage);
  });

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    fetchdata,
    fetchCompany,
    fetchConstruction,
    fetchStaff,
    fetchApprover,
    fetchLogoImage,
    fetchSignImage
  );

  const constructionColumns: ColumnData[] = useMemo(() => [{ ...simpleColumnData, dataKey: "name" }], []);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ReportEstimate
  );

  const columns: ColumnData[] = useMemo(
    () => [
      CheckColumn(data, setData),
      {
        width: 180,
        label: "見積番号",
        dataKey: "estimateNumber",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(0),
      },
      {
        width: 300,
        label: "得意先",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 200,
        label: "工事名",
        dataKey: "constructionName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "担当者",
        dataKey: "staffName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(3),
      },
      {
        width: 200,
        label: "承認者",
        dataKey: "approvalName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "見積作成日",
        dataKey: "estimateDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd", "-"),
        visible: getVisible(5),
      },
      {
        width: 150,
        label: "承認日",
        dataKey: "approvalDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd", "-"),
        visible: getVisible(6),
      },
    ],
    [getVisible, data]
  );

  return (
    <>
      <Grid item xs={12}>
        <Condition observer={ref}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <TextField
                className={genericClasses.margin}
                label="見積番号"
                value={condition.estimateNumber}
                onChange={inputManager.handleOnChange("estimateNumber")}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.newest}
                    onChange={inputManager.handleOnChangeCheck("newest")}
                    color="primary"
                  />
                }
                label="最新版のみ"
              />
              <DigitsField
                className={classes.version}
                label="版数"
                value={condition.versionNumber}
                onChange={inputManager.handleOnChangeNumber("versionNumber")}
                disabled={condition.newest}
                maxLength={2}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.unApproved}
                    onChange={inputManager.handleOnChangeCheck("unApproved")}
                    color="primary"
                  />
                }
                label="未承認"
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.approved}
                    onChange={inputManager.handleOnChangeCheck("approved")}
                    color="primary"
                  />
                }
                label="承認済み"
              />
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="得意先"
                text={condition.companyName}
                data={customers}
                columns={[simpleColumnData]}
                onClickNarrowDown={useNarrowDown(customers, "text")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, companyId: result.value, companyName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="工事情報"
                text={condition.constructionName}
                data={constructions}
                columns={constructionColumns}
                onClickNarrowDown={useNarrowDown(constructions, "name")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, constructionId: result.id, constructionName: result.name };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="担当者"
                text={condition.staffName}
                data={staff}
                columns={[simpleColumnData]}
                onClickNarrowDown={useNarrowDown(staff, "text")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, staffId: result.value, staffName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="承認者"
                text={condition.approverName}
                data={approver}
                columns={[simpleColumnData]}
                onClickNarrowDown={useNarrowDown(approver, "text")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, approverId: result.value, approverName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Period
                label="見積日"
                fromValue={condition.estimateDateFrom}
                toValue={condition.estimateDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("estimateDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("estimateDateTo")}
                width={160}
              />
              <Period
                label="承認日"
                fromValue={condition.approvedDateFrom}
                toValue={condition.approvedDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("approvedDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("approvedDateTo")}
                width={160}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(defaultCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="flex-start">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => callPrint("printout")}>
              PDF出力
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualizedTable
              values={data}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      <Print id="printout" title="帳票印刷">
        <EstimateDesign logoImage={logoImage} signImage={signImage} summary={data} />
      </Print>
    </>
  );
};

export const estimateConfig: ReportConfig = {
  text: "見積書",
  element: <Report />,
};
