import { MenuIndex, getMenuInfo } from "Component/Menu";
import { AppProvider } from "App";
import { useMemo } from "react";
import { getEditAuthInfo, EstimateEditAuth, VoucherEditAuth, CompanyEditAuth, SystemAuth } from "Common/Utility/Role";
import { BusinessInfo } from "Models/BusinessInfo";
import { OriginalVoucherSummary } from "Models/OriginalVoucher";
import { VoucherSummary } from "Models/Voucher";
import { DateTime, DateUtility } from "./DateUtility";
import { ColumnData, EditType } from "Common/Component/VirtualizedTable";

export function createBusinessNumber(createdAt?: DateTime | null, sequence?: number | null): string {
  if (createdAt == null || sequence == null) {
    return "";
  }

  return `DR-${DateUtility.format(createdAt, "yyyy")}-${sequence.toString().padStart(5, "0")}`;
}

export function depositStatusToString(depositStatus: number | null): string {
  switch (depositStatus) {
    case 0:
      return "入金不足";
    case 1:
      return "満額入金";
    case 2:
      return "過剰入金";
    case 255:
      return "";
    default:
      return "未入金";
  }
}

export const NumberConfig = {
  estimateNumberPrefix: "DE",
  businessNumberPrefix: "DR",
  estimateNumberLength: 13, // Prefix-を除く長さ
  businessNumberLength: 10, // Prefix-を除く長さ
  voucherNumberLength: 10,
  issueYearLength: 4,
  sequenceLength: 5,
  versionLength: 2,
  separator: "-",
} as const;

function zeroPadding(value: number, length: number): string {
  return ("0".repeat(length) + value).slice(-length);
}

function formatNumber(
  prefix: string | undefined,
  issueYear: number | null,
  sequence: number | null,
  version?: number | null
): string {
  if (issueYear === null || sequence === null || version === null) {
    return "";
  }

  const parts = [];
  if (prefix !== undefined) {
    parts.push(prefix);
  }
  parts.push(zeroPadding(issueYear, NumberConfig.issueYearLength));
  parts.push(zeroPadding(sequence, NumberConfig.sequenceLength));
  if (version !== undefined) {
    parts.push(zeroPadding(version, NumberConfig.versionLength));
  }

  return parts.join(NumberConfig.separator);
}

export function formatEstimateNumber(issueYear: number | null, sequence: number | null, version: number | null) {
  return formatNumber(NumberConfig.estimateNumberPrefix, issueYear, sequence, version);
}

export function formatBusinessNumber(issueYear: number | null, sequence: number | null) {
  return formatNumber(NumberConfig.businessNumberPrefix, issueYear, sequence);
}

export function formatBusinessNumberFromBusinessInfo(businessInfo: BusinessInfo) {
  const issueDate = new Date(businessInfo.createdAt);
  return formatBusinessNumber(issueDate.getFullYear(), businessInfo.sequence);
}

export function formatBusinessNumberFromSummary(summary: VoucherSummary | OriginalVoucherSummary) {
  if (summary.businessId === null || summary.businessSequence === null || summary.businessCreatedAt === null) {
    return "";
  }
  const issueDate = new Date(summary.businessCreatedAt!);
  return formatBusinessNumber(issueDate.getFullYear(), summary.businessSequence);
}

export function formatVoucherNumber(issueYear: number | null, sequence: number | null) {
  return formatNumber(undefined, issueYear, sequence);
}

// nullかもしれないnumberの掛け算。nullが含まれていた場合はnullを返す。
export function safeMultiply(...values: (number | null)[]): number | null {
  let result = BigInt(1);
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (value === null) {
      return null;
    }
    result *= BigInt(value);
  }

  if (result > 999999999) {
    return null;
  }
  return Number(result);
}

export const useIsValidMenuEditAuthority = (menuIndex: MenuIndex) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.menuEditAuth & getMenuInfo(menuIndex).bit) > 0 : false),
    [ralmAccount, menuIndex]
  );
};

export const useIsValidMenuReferenceAuthority = (menuIndex: MenuIndex) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.menuReferenceAuth & getMenuInfo(menuIndex).bit) > 0 : false),
    [ralmAccount, menuIndex]
  );
};

export const useIsValidEstimateEditAuthority = (index: EstimateEditAuth) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.estimateEditAuth & getEditAuthInfo("estimateEditAuth", index).bit) > 0 : false),
    [ralmAccount, index]
  );
};

export const useIsValidVcouherEditAuthority = (index: VoucherEditAuth) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.voucherEditAuth & getEditAuthInfo("voucherEditAuth", index).bit) > 0 : false),
    [ralmAccount, index]
  );
};

export const useIsValidCompanyEditAuthority = (index: CompanyEditAuth) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.companyEditAuth & getEditAuthInfo("companyEditAuth", index).bit) > 0 : false),
    [ralmAccount, index]
  );
};

export const useIsValidSystemAuthority = (index: SystemAuth) => {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");
  return useMemo(
    () => (ralmAccount ? (ralmAccount.systemAuth & getEditAuthInfo("systemAuth", index).bit) > 0 : false),
    [ralmAccount, index]
  );
};

export const HttpExceptionMessage = {
  TheUserDoesNotExist: "The user does not exist.",
  ThatUserHasAlreadyBeenUsed: "That user has already been used.",
  TheResourceDoesNotExist: "The resource does not exist.",
  TheResourceHasBeenDeleted: "The resource has been deleted.",
  TheTeamDoesNotExist: "The team does not exist.",
  TheDateRangesOverlap: "The date ranges overlap.",
  TimeOutByLock: "Time out by lock.",
  NotFoundUser: "Not found user.",
  NotFoundTenant: "Not found tenant.",
  NotFoundUserInAad: "Not found user in aad.",
  TheUpdateTargetDoesNotExist: "The update target does not exist.",
  TheUpdateTargetHasBeenRemoved: "The update target has been removed.",
  TheUpdateDateHasBeenUpdated: "The update date has been updated.",
  ThisIsNotAManagerAccount: "This is not a manager account.",
  NotFoundFile: "Not found file.",
  ThisIsAnExternallyLinkedUser: "This is an externally linked user.",
  TheTargetDoesNotExist: "The target does not exist.",
  TheServerIsNotFullyConfigured: "The server is not fully configured.",
} as const;
export type HttpExceptionMessage = typeof HttpExceptionMessage[keyof typeof HttpExceptionMessage];

export type OnClose = <T>(setValue: React.Dispatch<React.SetStateAction<T[]>>, key: keyof T) => void;
export type CallOnClose = (args?: { onClose: OnClose; key?: string }) => void;

export const onCloseWithExclusion = (target: any, key?: string) => {
  return {
    onClose: <T,>(setValue: React.Dispatch<React.SetStateAction<T[]>>, key: keyof T) => {
      setValue((value) => value.filter((i) => i[key] !== target));
    },
    key: key,
  };
};

export const onCloseWithSave = (target: any, key?: string) => {
  return {
    onClose: <T,>(setValue: React.Dispatch<React.SetStateAction<T[]>>, key: keyof T) => {
      setValue((value) => {
        const index = value.findIndex((i) => i[key] === target[key]);
        if (index >= 0) {
          value[index] = target;
          return [...value];
        }
        return [...value, target];
      });
    },
    key: key,
  };
};

export interface InputPending {
  onClose: CallOnClose;

  onHold?: () => void;

  shouldInitialize?: boolean;
}

export const editButton: ColumnData = {
  width: 80,
  label: "",
  bodyAlign: "center",
  editType: EditType.EditButton,
  widthType: "fix",
};

export const deleteButton: ColumnData = {
  width: 80,
  label: "",
  headerAlign: "center",
  bodyAlign: "center",
  editType: EditType.DeleteButton,
  widthType: "fix",
};

export const referenceButton: ColumnData = {
  width: 80,
  label: "",
  bodyAlign: "center",
  editType: EditType.ReferenceButton,
  widthType: "fix",
};

export const ValidateInteger = (value: any, min: number, max: number): boolean => {
  if (value == null) {
    return false;
  }

  if (!Number.isInteger(value)) {
    return false;
  }

  const num = Number.parseInt(value);

  return num >= min && num <= max;
};

export const removeArrayState = <T,>(
  setState: React.Dispatch<React.SetStateAction<T[]>>,
  compare: ((value: T) => boolean) | number
) => {
  if (typeof compare === "function") {
    setState((value) => {
      const index = value.findIndex((i) => compare(i));
      if (index >= 0) {
        value.splice(index, 1);
        return [...value];
      } else {
        return value;
      }
    });
  } else if (typeof compare === "number") {
    setState((value) => {
      value.splice(compare, 1);
      return [...value];
    });
  }
};

export const updateArrayState = <T,>(
  newValue: T | undefined,
  setState: React.Dispatch<React.SetStateAction<T[]>>,
  compare: (value: T) => boolean
) => {
  if (newValue == null) {
    return;
  }

  setState((value) => {
    const index = value.findIndex((i) => compare(i));
    if (index >= 0) {
      value[index] = newValue;
      return [...value];
    } else {
      return value;
    }
  });
};
