import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { InputManagerResult } from "Common/Utility/HandleUtility";
import { EstimateDetail } from "Models/Estimate";
import { ModelMaster } from "Models/ModelMaster";
import { AssetMaster } from "Models/Asset";
import { LineupSummary } from "Models/Lineup";
import { SelectedItem } from "./SelectedItem";
import TextField from "Component/TextField";
import DigitsField from "Component/DigitsField";
import Period from "Component/Period";
import { RentalPayOff, RentalPayOffComboItems } from "Common/Utility/Constants";
import Label from "Component/Label";
import { DateUtility } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  note: {
    width: 300,
    margin: theme.spacing(1),
  },
  basicPrice: {
    width: 200,
    margin: theme.spacing(1),
  },
  rentalType: {
    width: 150,
    margin: theme.spacing(1),
  },
  day: {
    width: 100,
    margin: theme.spacing(1),
  },
  count: {
    width: 80,
    margin: theme.spacing(1),
  },
  unit: {
    width: 60,
    margin: theme.spacing(1),
  },
  price: {
    width: 140,
    margin: theme.spacing(1),
  },
  summary: {
    width: 160,
    margin: theme.spacing(1),
  },
  summaryText: {
    textAlign: "right",
  },
  cost: {
    width: 150,
    margin: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  models: ModelMaster[];
  assets: AssetMaster[];
  lineupSummaries: LineupSummary[];
  editable: boolean;
  detail: EstimateDetail;
  setDetail: React.Dispatch<React.SetStateAction<EstimateDetail>>;
  inputManager: InputManagerResult<EstimateDetail>;
  closingDay: number | null;
}

export const Rental = React.memo((props: Props) => {
  const classes = useStyles();

  const { detail, setDetail, editable, inputManager, closingDay, ...other } = { ...props };

  const rentalTermDate = useMemo(() => {
    switch (detail.rentalPayOff) {
      case RentalPayOff.Daily:
      case RentalPayOff.Fixed:
        const rentalDays = DateUtility.calcRentalDays(detail.rentalDateFrom, detail.rentalDateTo);
        if (rentalDays) {
          return `${rentalDays}日`;
        }
        break;
      case RentalPayOff.Monthly:
        const rentalMonths = DateUtility.calcRentalMonths(detail.rentalDateFrom, detail.rentalDateTo);
        if (rentalMonths) {
          return `${rentalMonths}ヵ月`;
        }
        break;
      case RentalPayOff.MonthlyDaily:
        const rentalMonthsAndDays = DateUtility.calcRentalMonthsAndDays(
          detail.rentalDateFrom,
          detail.rentalDateTo,
          closingDay ?? 1
        );
        if (rentalMonthsAndDays) {
          return `${rentalMonthsAndDays.months}ヵ月${rentalMonthsAndDays.days}日`;
        }
        break;
    }
    return "";
  }, [closingDay, detail.rentalDateFrom, detail.rentalDateTo, detail.rentalPayOff]);

  useEffect(() => {
    if (detail.count == null) {
      return;
    }

    switch (detail.rentalPayOff) {
      case RentalPayOff.Daily:
        if (
          detail.dailyUnitPrice == null ||
          detail.numberOfDaily == null ||
          detail.numberOfDaily <= 0 ||
          detail.rentalDateFrom == null ||
          detail.rentalDateTo == null
        ) {
          return;
        }
        break;
      case RentalPayOff.Monthly:
        if (detail.monthlyUnitPrice == null || detail.rentalDateFrom == null || detail.rentalDateTo == null) {
          return;
        }
        break;
      case RentalPayOff.MonthlyDaily:
        if (
          detail.dailyUnitPrice == null ||
          detail.monthlyUnitPrice == null ||
          detail.rentalDateFrom == null ||
          detail.rentalDateTo == null
        ) {
          return;
        }
        break;
      case RentalPayOff.Fixed:
        if (detail.dailyUnitPrice == null) {
          return;
        }
        break;
    }

    switch (detail.rentalPayOff) {
      case RentalPayOff.Daily:
        setDetail((value) => {
          const days = DateUtility.calcRentalDays(value.rentalDateFrom, value.rentalDateTo);
          if (days == null || value.count == null || value.dailyUnitPrice == null) {
            return value;
          }

          return {
            ...value,
            price: Math.ceil(days / (detail.numberOfDaily ?? 1)) * value.count * value.dailyUnitPrice,
          };
        });
        break;
      case RentalPayOff.Monthly:
        setDetail((value) => {
          const months = DateUtility.calcRentalMonths(detail.rentalDateFrom, detail.rentalDateTo);
          if (months == null || value.count == null || value.monthlyUnitPrice == null) {
            return value;
          }
          return { ...value, price: months * value.count * value.monthlyUnitPrice };
        });
        break;
      case RentalPayOff.MonthlyDaily:
        setDetail((value) => {
          const rentalMonthsAndDays = DateUtility.calcRentalMonthsAndDays(
            detail.rentalDateFrom,
            detail.rentalDateTo,
            props.closingDay ?? 1
          );
          if (
            rentalMonthsAndDays == null ||
            value.count == null ||
            value.dailyUnitPrice == null ||
            value.monthlyUnitPrice == null
          ) {
            return value;
          }
          return {
            ...value,
            price:
              (rentalMonthsAndDays.days * value.dailyUnitPrice + rentalMonthsAndDays.months * value.monthlyUnitPrice) *
              value.count,
          };
        });
        break;
      case RentalPayOff.Fixed:
        setDetail((value) => {
          if (value.count == null || value.dailyUnitPrice == null) {
            return value;
          }
          return {
            ...value,
            price: value.dailyUnitPrice * value.count,
          };
        });
        break;
    }
  }, [
    detail.count,
    detail.dailyUnitPrice,
    detail.monthlyUnitPrice,
    detail.numberOfDaily,
    detail.rentalDateFrom,
    detail.rentalDateTo,
    detail.rentalPayOff,
    props.closingDay,
    setDetail,
  ]);

  return (
    <>
      <SelectedItem {...other} detail={detail} inputManager={inputManager} editable={editable} />
      <TextField
        label="摘要"
        className={classes.note}
        type="text"
        value={detail.summary}
        onChange={inputManager.handleOnChange("summary")}
        disabled={!editable}
      />
      <Grid item container direction="row" justify="flex-start" alignItems="flex-end">
        <DigitsField
          className={classes.basicPrice}
          label="基本料"
          value={detail.basicPrice}
          onChange={inputManager.handleOnChangeNumber("basicPrice")}
          disabled={!editable}
        />
        <FormControl className={classes.rentalType} disabled={!editable}>
          <InputLabel id="rental-payoff-select-label">精算区分</InputLabel>
          <Select
            labelId="rental-payoff-select-label"
            id="rental-payoff-select"
            value={detail.rentalPayOff}
            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
              inputManager.handleOnChangeSelect("rentalPayOff")(event, child);
              setDetail((value) => {
                if (event.target.value === RentalPayOff.Daily) {
                  return { ...value, numberOfDaily: 1 };
                } else {
                  return { ...value, numberOfDaily: null };
                }
              });
            }}
          >
            {RentalPayOffComboItems.map((value, index) => {
              return (
                <MenuItem key={index} value={value.value}>
                  {value.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <DigitsField
          className={classes.count}
          label="日極日数"
          value={detail.numberOfDaily}
          onChange={inputManager.handleOnChangeNumber("numberOfDaily")}
          disabled={!(editable && detail.rentalPayOff === RentalPayOff.Daily)}
        />
        <Period
          label="期間"
          fromValue={detail.rentalDateFrom}
          toValue={detail.rentalDateTo}
          onChangeFrom={inputManager.handleOnChangeDate("rentalDateFrom")}
          onChangeTo={inputManager.handleOnChangeDate("rentalDateTo")}
          disabled={!editable}
        />
        <Label className={classes.day} caption="日数" text={rentalTermDate} disabled={!editable} underLine />
      </Grid>
      <Grid item container direction="row" justify="flex-start" alignItems="center">
        <DigitsField
          className={classes.count}
          label="数量"
          value={detail.count}
          onChange={inputManager.handleOnChangeNumber("count")}
          disabled={!editable}
        />
        <TextField
          className={classes.unit}
          label="単位"
          value={detail.unit}
          onChange={inputManager.handleOnChange("unit")}
          disabled={!editable}
        />
        <DigitsField
          className={classes.price}
          label="日額単価又は単価"
          value={detail.dailyUnitPrice}
          onChange={inputManager.handleOnChangeNumber("dailyUnitPrice")}
          disabled={!(editable && detail.rentalPayOff !== RentalPayOff.Monthly)}
        />
        <DigitsField
          className={classes.price}
          label="月額単価"
          value={detail.monthlyUnitPrice}
          onChange={inputManager.handleOnChangeNumber("monthlyUnitPrice")}
          disabled={
            !(
              editable &&
              (detail.rentalPayOff === RentalPayOff.Monthly || detail.rentalPayOff === RentalPayOff.MonthlyDaily)
            )
          }
        />
        <DigitsField
          className={classes.price}
          label="金額"
          value={detail.price}
          onChange={inputManager.handleOnChangeNumber("price")}
          disabled={!editable}
        />
        <Label
          className={classes.summary}
          textClassName={classes.summaryText}
          caption="合計"
          text={`${((detail.basicPrice ?? 0) + (detail.price ?? 0)).toLocaleString()} 円`}
          underLine
        />
        <DigitsField
          className={classes.cost}
          label="原価"
          value={detail.cost}
          onChange={inputManager.handleOnChangeNumber("cost")}
          disabled={!editable}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={detail.taxExemption}
              onChange={inputManager.handleOnChangeCheck("taxExemption")}
              color="primary"
              disabled={!editable}
            />
          }
          label="非課税"
        />
      </Grid>
    </>
  );
});
