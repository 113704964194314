import React, { useCallback, useState, useEffect } from "react";
import { Grid, Button, DialogActions, Dialog, DialogTitle, DialogContent, makeStyles } from "@material-ui/core";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useExecute } from "Hooks/useFetch";
import { useGenericStyles } from "Common/Utility/Styles";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
}));

interface VoucherIssueDialogProps {
  open: boolean;
  onClose: () => void;
  originalVoucherIdList: string[];
}

const VoucherIssueDialog = (props: VoucherIssueDialogProps) => {
  const classes = useStyles();
  const genericClasses = useGenericStyles();
  const alertAdd = useAlertAdd();

  const [voucherDate, setVoucherDate] = useState<DateTime>();
  const [billingYearMonth, setBillingYearMonth] = useState<DateTime>();

  useEffect(() => {
    if (!props.open) {
      return;
    }

    var today = DateUtility.nowDate();
    setVoucherDate(today);
    setBillingYearMonth(today);
  }, [props.open]);

  const executePost = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          originalVoucherIdList: string[];
          voucherDate: DateTime | undefined;
          billingYearMonth: DateTime | undefined;
          onClose: () => void;
        }
      ) => {
        var response = await callWebApi().post("/originalvoucher/issue", {
          idList: object.originalVoucherIdList,
          voucherDate: object.voucherDate,
          billingYearMonth: DateUtility.format(object.billingYearMonth!, "yyyy/MM"),
        });

        if (response.data?.message) {
          alertAdd({ type: "info", message: response.data.message });
        } else {
          alertAdd({ type: "success", message: "伝票を発行しました。" });

          object.onClose();
        }
      },
      [alertAdd]
    )
  );

  const handleOnIssue = useCallback(() => {
    executePost({
      originalVoucherIdList: props.originalVoucherIdList,
      voucherDate: voucherDate,
      billingYearMonth: billingYearMonth,
      onClose: props.onClose,
    });
  }, [props.originalVoucherIdList, voucherDate, billingYearMonth, executePost, props.onClose]);

  const handleOnClose = useCallback(() => {
    props.onClose();
  }, [props]);

  const handleOnChangeVoucherDate = useCallback((date: Date | null) => {
    const voucherDate = date == null ? undefined : date.toLocaleDateString();
    setVoucherDate(voucherDate);
  }, []);

  const handleOnChangeBillingYearMonth = useCallback((date: Date | null) => {
    const billingYearMonth = date == null ? undefined : date.toLocaleDateString();
    setBillingYearMonth(billingYearMonth);
  }, []);

  return (
    <>
      <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="sm">
        <DialogTitle>伝票発行</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                <DatePickersUtilsProvider>
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label="伝票日付"
                    autoOk={true}
                    invalidLabel=""
                    invalidDateMessage=""
                    value={voucherDate}
                    onChange={handleOnChangeVoucherDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM"
                    views={["year", "month"]}
                    margin="normal"
                    label="請求年月"
                    autoOk={true}
                    invalidLabel=""
                    invalidDateMessage=""
                    value={billingYearMonth}
                    onChange={handleOnChangeBillingYearMonth}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </DatePickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.marginRight} onClick={handleOnClose} color="primary">
            閉じる
          </Button>
          <Button className={genericClasses.marginRight} onClick={handleOnIssue} color="primary">
            発行
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(VoucherIssueDialog);
