import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { useCallback } from "react";
import { connect, IAction } from "Common/Utility/connect";
import VoucherPanel from "./VoucherPanel";
import OriginalVoucherPanel from "./OriginalVoucherPanel";
import IntroductionPanel from "./IntroductionPanel";
import { AppProvider } from "App";

export enum ActionTypes {}

export const VoucherTabIndex = {
  Voucher: 0,
  OriginalVoucher: 1,
  Introduction: 2,
} as const;
export type VoucherTabIndex = typeof VoucherTabIndex[keyof typeof VoucherTabIndex];

const useStyles = makeStyles((theme) => ({
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

export default React.memo(() => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => setTabIndex(index), []);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <VoucherPanel />;
      case 1:
        return <OriginalVoucherPanel />;
      case 2:
        return <IntroductionPanel />;
      default:
        return <></>;
    }
  }, [tabIndex]);

  const transition = AppProvider.useGlobalState("transition");

  useEffect(() => {
    if (transition != null) {
      setTabIndex(0);
    }
  }, [transition]);

  return (
    <>
      <Paper className={classes.tabArea} square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTabs}
          aria-label="tabs"
        >
          <Tab label="伝票" />
          <Tab label="元伝票" />
          <Tab label="紹介" />
        </Tabs>
      </Paper>
      {panel}
    </>
  );
});

const initialState: VoucherType = {};

interface VoucherType {}

function reducer(state: VoucherType, action: IAction): VoucherType {
  switch (action.type) {
    default:
      return state;
  }
}

export const VoucherProvider = connect<VoucherType>(reducer, initialState);
