import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { VoucherSummary } from "Models/Voucher";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import VoucherDialog from "./VoucherDialog";
import { useExecute } from "Hooks/useFetch";
import { deleteButton, editButton, formatVoucherNumber, referenceButton } from "Common/Utility/AppUtility";
import { toComboText, VoucherStatus, VoucherStatusComboItems } from "Common/Utility/Constants";
import { sortWithSet } from "Common/Utility/GenericInterface";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface VoucherListProps {
  summaries: VoucherSummary[];
  setSummaries: React.Dispatch<React.SetStateAction<VoucherSummary[]>>;
  customerId?: string;
  customerName?: string;
  businessId?: string;
  businessSequence?: number;
  businessCreatedAt?: DateTime;
  height: number;
  editAuth: boolean;
}

const VoucherList = (props: VoucherListProps) => {
  const classes = useStyles({ height: props.height, minHeight: props.customerId != null ? 0 : 300 });

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [editSummary, setEditSummary] = useState<VoucherSummary | undefined>(undefined);

  const handleOnClickAdd = useCallback(() => {
    setEditSummary({
      id: null,
      issueYear: null,
      sequence: null,
      voucherDate: null,
      customerId: props.customerId,
      customerName: props.customerName,
      billingAmount: 0,
      tax: 0,
      totalAmount: 0,
      closingDate: null,
      depositDate: null,
      businessId: props.businessId ?? null,
      businessName: "",
      businessSequence: props.businessSequence ?? null,
      businessCreatedAt: props.businessCreatedAt ?? null,
      note: null,
      staffUserId: null,
      staffUserName: null,
      staffAssignDate: null,
      approvalUserId: null,
      approvalUserName: null,
      approvalDate: null,
      status: VoucherStatus.Initial,
      requestCount: 0,
      updatedAt: undefined,
      customerClosingDay: undefined,
      customerDepositCycle: undefined,
      customerDepositDay: undefined,
    });
  }, [props.customerId, props.customerName, props.businessId, props.businessSequence, props.businessCreatedAt]);

  const handleOnClickEdit = useCallback(
    async (summary: VoucherSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditSummary({ ...summary });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: VoucherSummary;
          setSummaries: React.Dispatch<React.SetStateAction<VoucherSummary[]>>;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete("/voucher", { params: { id: object.summary.id } });

        alertAdd({ type: "success", message: "伝票を削除しました。" });

        if (unmounted.value) {
          return;
        }

        object.setSummaries((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (summary: VoucherSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "伝票情報を削除します、よろしいですか？"))) {
        return;
      }

      if (summary.status !== VoucherStatus.Initial) {
        if (!(await message.confirm("重要 削除確認", "既に処理済みの伝票ですが、本当に削除しますか？"))) {
          return;
        }
      }

      executeDelete({
        summary: summary,
        setSummaries: props.setSummaries,
        rowIndex: rowIndex,
      });
    },
    [props, message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog("Voucher", setEditSummary, props.setSummaries, "id", props.businessId);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Voucher);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (props.editAuth) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 120,
        label: "伝票番号",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (_: any, rowData: any) => formatVoucherNumber(rowData.issueYear, rowData.sequence),
        visible: getVisible(0),
      },
      {
        width: 120,
        label: "伝票日付",
        dataKey: "voucherDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "得意先名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 120,
        label: "請求締日",
        dataKey: "closingDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(3),
      },
      {
        width: 120,
        label: "入金予定日",
        dataKey: "depositDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "総額",
        dataKey: "totalAmount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => `${(data ?? 0).toLocaleString()} 円`,
        sort: (asc: boolean) => sortWithSet(props.summaries, props.setSummaries, "totalAmount", asc),
        visible: getVisible(5),
      },
      {
        width: 100,
        label: "状態",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (status: VoucherStatus) => toComboText(VoucherStatusComboItems, status),
        visible: getVisible(6),
      },
      {
        width: 100,
        label: "依頼状態",
        dataKey: "requestCount",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (requestCount: number) => (requestCount > 0 ? "請求済み" : ""),
        visible: getVisible(7),
      },
      {
        width: 150,
        label: "担当者",
        dataKey: "staffUserName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(8),
      },
      {
        width: 150,
        label: "承認者",
        dataKey: "approvalUserName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(9),
      }
    );

    return ret;
  }, [props.editAuth, props.summaries, props.setSummaries, getVisible]);

  return (
    <>
      <Paper className={classes.paper}>
        <VirtualaizedTable
          values={props.summaries}
          setValues={props.setSummaries}
          tableHeight={props.height}
          rowHeight={48}
          columns={columns}
          onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
          onClickEdit={handleOnClickEdit}
          onClickReference={handleOnClickEdit}
          onClickDelete={handleOnClickDelete}
          onCloseContextMenu={handleOnCloseContextMenu}
          onChangeHeaderVisible={handleOnChangeHeaderVisible}
          headerContext
        />
      </Paper>
      {editSummary != null && (
        <VoucherDialog
          open={true}
          onClose={handleOnClose}
          summary={editSummary}
          details={[]}
          editAuth={props.editAuth}
          shouldInitialize
        />
      )}
    </>
  );
};

export default React.memo(VoucherList);
