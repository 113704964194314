import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { CancelTokenSource } from "axios";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { AreaComboItem, TenantClass, toComboText } from "Common/Utility/Constants";
import { DateUtility } from "Common/Utility/DateUtility";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useFetch } from "Hooks/useFetch";
import { useContentHeight } from "Hooks/useResize";
import { Tenant } from "Models/Tenant";
import React, { useCallback, useMemo, useState } from "react";
import Logo from "../../images/pflogo.png";
import { Notification } from "Models/Notification";
import { useGenericStyles } from "Common/Utility/Styles";
import { useAlertAdd } from "Common/Component/AlertList";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 200,
    margin: theme.spacing(1),
  },
  box: {
    width: `calc(100% - (200px + ${theme.spacing(1) * 2}px))`,
    height: (props: any) => props.height,
  },
  paperButton: {
    width: 300,
    height: 36,
    backgroundColor: "#007451",
    margin: theme.spacing(1),
  },
  text: {
    fontSize: 16,
    color: "white",
  },
  textGrid: {
    height: "100%",
  },
  notificationList: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    height: 320,
    margin: theme.spacing(1),
  },
  loading: {
    height: (props: any) => props.height,
  },
  partner: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    height: (props: any) => props.heightBottom,
    margin: theme.spacing(1),
    minHeight: 300,
  },
  vendor: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    height: (props: any) => props.heightBottom,
    margin: theme.spacing(1),
    minHeight: 300,
  },
  aroundPartner: {
    margin: theme.spacing(1),
    backgroundColor: "rgb(0,134,209)",
  },
  aroundVendor: {
    margin: theme.spacing(1),
    backgroundColor: "rgb(246,171,0)",
  },
  content: {
    whiteSpace: "pre-wrap",
    fontSize: 18,
  },
}));

interface NofiticationDetailProps {
  open: boolean;

  onClose: () => void;

  notification: Notification;
}

const NofiticationDetail = (props: NofiticationDetailProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const link = useCallback(
    (index: number) => {
      if (index < props.notification.notificationLinks.length) {
        return (
          <a href={props.notification.notificationLinks[index].url} rel="noopener noreferrer" target="_blank">
            {props.notification.notificationLinks[index].text}
          </a>
        );
      } else {
        return <></>;
      }
    },
    [props.notification.notificationLinks]
  );

  const text = useMemo(() => {
    if (props.notification.content == null) {
      return <></>;
    }

    const split = props.notification.content.split("<LINK>");
    return split.map((value, index) => {
      return (
        <>
          {value}
          {link(index)}
        </>
      );
    });
  }, [props.notification.content, link]);

  return (
    <Dialog onClose={props.onClose} open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>お知らせ詳細</DialogTitle>
      <DialogContent className={classes.content}>{text}</DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={props.onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const notificationColumns: ColumnData[] = [
  {
    width: 150,
    label: "日付",
    dataKey: "notificationDate",
    headerAlign: "center",
    bodyAlign: "center",
    convert: (data: any) => DateUtility.format(data),
  },
  {
    width: 80,
    label: "題名",
    dataKey: "title",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

const partnerColumns: ColumnData[] = [
  {
    width: 150,
    label: "パートナー",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (data: any, rowData: Tenant) =>
      `${rowData.name} ${rowData.area === 0 ? "" : `(${toComboText(AreaComboItem, rowData.area)})`}`,
    fit: true,
  },
];

const vendorColumns: ColumnData[] = [
  {
    width: 150,
    label: "ベンダー",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (data: any, rowData: Tenant) =>
      `${rowData.name} ${rowData.area === 0 ? "" : `(${toComboText(AreaComboItem, rowData.area)})`}`,
    fit: true,
  },
];

export default React.memo(() => {
  const [height] = useContentHeight();

  const [heightBottom] = useContentHeight(52 + 52 + 336 + 8 * 2);

  const classes = useStyles({ height: height, heightBottom: heightBottom });

  const alertAdd = useAlertAdd();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [partners, setPartners] = useState<Tenant[]>([]);

  const [vendors, setVendors] = useState<Tenant[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [notification, setNotification] = useState<Notification>({
    notificationLinks: [],
  });

  const loading = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        let response = await callWebApi().get<Notification[]>("/notifications", { cancelToken: signal.token });
        setNotifications(response.data);
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        let response = await callWebApi().get<Tenant[]>("/Tenants", { cancelToken: signal.token });

        setPartners(
          response.data
            .filter((i) => i.category === 1 && i.tenantClass === TenantClass.UnClass)
            .sort((a, b) => a.name.localeCompare(b.name))
        );
        setVendors(
          response.data
            .filter((i) => i.category === 2)
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      }, [])
    )
  );

  const handleOnClickCell = useCallback(
    (notification: Notification, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (notification.content == null || notification.content === "") {
        alertAdd({ type: "info", message: "お知らせ詳細はありません。" });
        return;
      }

      setNotification(notification);
      setOpen(true);
    },
    [alertAdd]
  );

  const handleOnClickCellPartner = useCallback(
    (tenant: Tenant, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (tenant.url != null) {
        window.open(tenant.url);
      }
    },
    []
  );

  const handleOnClickCellVendor = useCallback(
    (tenant: Tenant, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (tenant.url != null) {
        window.open(tenant.url);
      }
    },
    []
  );

  return (
    <>
      {loading ?? (
        <>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <img className={classes.image} alt="logo" src={Logo} />
            <Box className={classes.box}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                  <Paper className={classes.paperButton}>
                    <Grid className={classes.textGrid} container direction="row" justify="center" alignItems="center">
                      <Typography className={classes.text} variant="caption">
                        お知らせ
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.notificationList}>
                    <VirtualizedTable
                      headerHeight={0}
                      values={notifications}
                      rowHeight={48}
                      columns={notificationColumns}
                      onClickCell={handleOnClickCell}
                      hover
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paperButton}>
                    <Grid className={classes.textGrid} container direction="row" justify="center" alignItems="center">
                      <Typography className={classes.text} variant="caption">
                        プラットフォーム会員リスト
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.aroundPartner}>
                    <Paper className={classes.partner}>
                      <VirtualizedTable
                        headerHeight={48}
                        values={partners}
                        rowHeight={48}
                        columns={partnerColumns}
                        onClickCell={handleOnClickCellPartner}
                        hover
                      />
                    </Paper>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.aroundVendor}>
                    <Paper className={classes.vendor}>
                      <VirtualizedTable
                        headerHeight={48}
                        values={vendors}
                        rowHeight={48}
                        columns={vendorColumns}
                        onClickCell={handleOnClickCellVendor}
                        hover
                      />
                    </Paper>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <NofiticationDetail open={open} onClose={() => setOpen(false)} notification={notification} />
        </>
      )}
    </>
  );
});
