import { Button, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import Condition from "Component/Condition";
import Period from "Component/Period";
import { useContentHeight } from "Hooks/useResize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VirtualaizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { useExecuteEx } from "Hooks/useFetch";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { LostOrderDetail, LostOrderSummary } from "Models/LostOrder";
import { AddBox } from "@material-ui/icons";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { AppProvider } from "App";
import { editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import TextField from "Component/TextField";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  selectCustomer: {
    width: 200,
    borderBottom: "solid 1px #949494",
  },
  editPaper: {
    width: "100%",
    height: 300,
  },
}));

interface ReasonEditDialogProps {
  open: boolean;

  editing: boolean;

  reason: string;

  onClose: (reason: string | null) => void;

  inProcess: boolean;
}

const ReasonEditDialog = (props: ReasonEditDialogProps) => {
  const genericClasses = useGenericStyles();

  const [reason, setReason] = useState<{ value: string }>({ value: "" });

  const [edited, confirm] = useWhetherEdited(props.open);

  const inputManager = useInputManager(setReason, edited);

  useEffect(() => {
    if (props.open) {
      setReason({ value: props.reason });
    }
  }, [props.open, props.reason]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>理由</DialogTitle>
      <DialogContent>
        <TextField
          className={genericClasses.width100percent}
          label="理由"
          multiline
          rows={10}
          variant="outlined"
          value={reason.value}
          onChange={inputManager.handleOnChange("value")}
          InputProps={{
            readOnly: !props.editing,
          }}
        />
      </DialogContent>
      <DialogActions>
        {props.editing ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={() => confirm(() => props.onClose(null))}
              color="primary"
            >
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={() => props.onClose(reason.value)}
              color="primary"
              disabled={!props.open || reason.value === "" || props.inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose(null))} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface EditDialogProps {
  open: boolean;

  lostOrderSummary: LostOrderSummary;

  setLostOrderSummary: React.Dispatch<React.SetStateAction<LostOrderSummary>>;

  onClose: () => void;
}

const EditDialog = (props: EditDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const [open, setOpen] = useState<boolean>(false);

  const [editing, setEditing] = useState<boolean>(false);

  const [reason, setReason] = useState<string>("");

  const handleOnClickReference = useCallback(
    (data: LostOrderDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditing(false);
      setReason(data.reason);
      setOpen(true);
    },
    []
  );

  const setLostOrderSummary = useMemo(() => props.setLostOrderSummary, [props.setLostOrderSummary]);

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { receptionId: string; contactUserId: string; reason: string }) => {
        var response = await callWebApi().post<LostOrderDetail>("/lostorder", object);

        if (unmounted.value) {
          return;
        }

        if (response.data != null) {
          setLostOrderSummary((value) => {
            return { ...value, lostOrderDetail: [...value.lostOrderDetail, response.data] };
          });
        }

        setOpen(false);
      },
      [setLostOrderSummary]
    )
  );

  const handleOnClose = useCallback(
    (reason: string | null) => {
      if (reason == null) {
        setOpen(false);
      } else if (ralmAccount) {
        executePost({
          receptionId: props.lostOrderSummary.receptionId,
          contactUserId: ralmAccount.userGuid,
          reason: reason,
        });
      }
    },
    [executePost, props.lostOrderSummary.receptionId, ralmAccount]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.LostOrder);

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
        rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
          return (
            <IconButton
              color="primary"
              onClick={() => {
                setEditing(true);
                setReason("");
                setOpen(true);
              }}
            >
              <AddBox />
            </IconButton>
          );
        },
      });
    } else {
      ret.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
      });
    }

    ret.push(
      {
        width: 150,
        label: "記載日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (createdAt: DateTime) => DateUtility.format(createdAt, "yyyy/MM/dd"),
      },
      {
        width: 200,
        label: "記載者",
        dataKey: "contactUser",
        headerAlign: "center",
        bodyAlign: "center",
      },
      {
        width: 175,
        label: "理由",
        dataKey: "reason",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      }
    );

    return ret;
  }, [isValidEdit]);

  return (
    <>
      <Dialog onClose={props.onClose} open={props.open} fullWidth={true} maxWidth="md">
        <DialogTitle>失注理由</DialogTitle>
        <DialogContent>
          <Paper className={classes.editPaper}>
            <VirtualaizedTable
              values={props.lostOrderSummary.lostOrderDetail}
              rowHeight={48}
              columns={columns}
              onClickReference={handleOnClickReference}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={props.onClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <ReasonEditDialog open={open} onClose={handleOnClose} reason={reason} editing={editing} inProcess={inProcess} />
    </>
  );
};

interface Condition {
  receptionNumber: string;

  lostDateFrom: DateTime | null;

  lostDateTo: DateTime | null;

  contactUser: string;

  reason: string;
}

const LostOrder = () => {
  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [open, setOpen] = useState<boolean>(false);

  const [lostOrderSummaries, setLostOrderSummaries] = useState<LostOrderSummary[]>([]);

  const [lostOrderSummary, setLostOrderSummary] = useState<LostOrderSummary>({
    receptionId: "",
    receptionNumber: "",
    lostOrderDetail: [],
  } as LostOrderSummary);

  const [condition, setCondition] = useState<Condition>({
    receptionNumber: "",
    lostDateFrom: null,
    lostDateTo: null,
    contactUser: "",
    reason: "",
  } as Condition);

  const [search, setSearch] = useState<Condition>({
    receptionNumber: "",
    lostDateFrom: null,
    lostDateTo: null,
    contactUser: "",
    reason: "",
  } as Condition);

  const inputManager = useInputManager(setCondition);

  const handleOnClickEdit = useCallback(
    (data: LostOrderSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setLostOrderSummary(data);
      setOpen(true);
    },
    []
  );

  const fetchResult = useSimpleFetch(
    "/lostorder",
    setLostOrderSummaries,
    false,
    DateUtility.InvalidToNull(search, "lostDateFrom", "lostDateTo"),
    [search]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.LostOrder);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.LostOrder);

  const columns: ColumnData[] = useMemo(
    () => [
      isValidEdit ? editButton : referenceButton,
      {
        width: 150,
        label: "受付番号",
        dataKey: "receptionNumber",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "失注日",
        dataKey: "lostOrderDetail",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (lostOrderDetail: LostOrderDetail[]) => DateUtility.format(lostOrderDetail[0].createdAt, "yyyy/MM/dd"),
        visible: getVisible(1),
      },
      {
        width: 200,
        label: "担当者",
        dataKey: "lostOrderDetail",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (lostOrderDetail: LostOrderDetail[]) => lostOrderDetail[0].contactUser,
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "理由",
        dataKey: "lostOrderDetail",
        headerAlign: "center",
        bodyAlign: "left",
        convert: (lostOrderDetail: LostOrderDetail[]) => lostOrderDetail[0].reason,
        fit: true,
        visible: getVisible(3),
      },
    ],
    [isValidEdit, getVisible]
  );

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.margin}
                label="受付番号"
                value={condition.receptionNumber}
                onChange={inputManager.handleOnChange("receptionNumber")}
              />
              <Period
                label="失注日"
                fromValue={condition.lostDateFrom}
                toValue={condition.lostDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("lostDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("lostDateTo")}
              />
              <TextField
                className={genericClasses.margin}
                label="担当者/閲覧者"
                value={condition.contactUser}
                onChange={inputManager.handleOnChange("contactUser")}
              />
              <TextField
                className={genericClasses.margin}
                label="理由"
                value={condition.reason}
                onChange={inputManager.handleOnChange("reason")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => {
                  setCondition({
                    receptionNumber: "",
                    lostDateFrom: null,
                    lostDateTo: null,
                    contactUserId: "",
                    contactUser: "",
                    reason: "",
                  } as Condition);
                }}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResult) ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={lostOrderSummaries}
                setValues={setLostOrderSummaries}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <EditDialog
        open={open}
        lostOrderSummary={lostOrderSummary}
        setLostOrderSummary={setLostOrderSummary}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default React.memo(LostOrder);
