import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useExecuteEx } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { useInputManager } from "Common/Utility/HandleUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { CallOnClose, deleteButton, editButton, InputPending, onCloseWithSave } from "Common/Utility/AppUtility";
import { CheckSheet, CheckSheetItem } from "Models/CheckSheet";
import { useDirectInput } from "Hooks/useDirectInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 600,
  },
}));

interface Props extends InputPending {
  open: boolean;
  checkSheet: CheckSheet;
  editAuth: boolean;
}

export const EditDialog = (props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const [checkSheet, setCheckSheet] = useState<CheckSheet>(props.checkSheet);

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props);

  const inputManager = useInputManager(setCheckSheet, edited);

  const [items, setItems] = useState<CheckSheetItem[]>(props.checkSheet.items);

  const directInput = useDirectInput<CheckSheetItem>({
    data: items,
    setData: setItems,
    edited: edited,
    validate: (value: CheckSheetItem) => {
      const validate = value.name != null && value.name !== "";
      if (!validate) {
        alertAdd({ type: "info", message: "項目名は必須項目です。" });
      }
      return validate;
    },
  });

  const [editing, setEditing] = useState<InputState>(InputState.None);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { checkSheet: CheckSheet; onClose: CallOnClose }) => {
        const response = await callWebApi().put<CheckSheet>("/checksheets", object.checkSheet);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "チェックシートを保存しました。" });

        if (unmounted.value) {
          return;
        }

        if (response.data == null) {
          return;
        }

        object.onClose(onCloseWithSave(response.data, response.data.id));
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    if (!checkSheet.name) {
      alertAdd({ type: "info", message: "名称は必須項目です。" });
      return;
    }

    if (items.length === 0) {
      alertAdd({ type: "info", message: "点検項目が0件です。" });
      return;
    }

    executePut({ checkSheet: { ...checkSheet, items: items }, onClose: props.onClose });
  }, [props, alertAdd, checkSheet, items, executePut]);

  const handleOnClickHoldInAction = useHoldInput(
    "チェックシート",
    <EditDialog open={true} checkSheet={checkSheet} onClose={props.onClose} editAuth={true} />,
    props.onClose,
    props.onHold,
    () => {
      checkSheet.items = [...items];
    }
  );

  const columns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (props.editAuth) {
      ret.push(editButton, deleteButton);
    }

    ret.push({
      width: 150,
      label: "点検項目",
      dataKey: "name",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
      editType: EditType.AllowEdit,
      componentWhenEditing: (rowIndex: number) => {
        return (
          <TextField
            className={genericClasses.width100percent}
            value={directInput.instant.name}
            onChange={directInput.handleOnChange("name")}
          />
        );
      },
    });

    return ret;
  }, [props.editAuth, genericClasses.width100percent, directInput]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>チェックシート情報</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
          <Grid item xs={12}>
            <TextField
              className={genericClasses.width100percent}
              label="名称"
              value={checkSheet.name}
              onChange={inputManager.handleOnChange("name")}
              disabled={!props.editAuth}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={items}
                rowHeight={48}
                columns={columns}
                onClickAddDirectInput={props.editAuth ? directInput.handleOnClickAdd : undefined}
                onClickEdit={directInput.handleOnClickEdit}
                onClickDelete={directInput.handleOnClickDelete}
                onClickSave={directInput.handleOnClickSave}
                onClickCancel={directInput.handleOnClickCancel}
                setEditing={setEditing}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {props.editAuth && (
          <Button
            className={genericClasses.margin}
            onClick={handleOnClickHoldInAction}
            color="primary"
            disabled={!props.open || editing !== InputState.None || inProcess}
          >
            保留
          </Button>
        )}
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
          {props.editAuth ? "キャンセル" : "閉じる"}
        </Button>
        {props.editAuth && (
          <Button
            className={genericClasses.margin}
            onClick={handleOnClickSaveInAction}
            color="primary"
            disabled={!props.open || editing !== InputState.None || inProcess}
          >
            保存
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
