import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DepositPlan } from "Models/Deposit";
import { useSimpleFetch } from "Common/Utility/Api";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import Period from "Component/Period";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useContentHeight } from "Hooks/useResize";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { useInputManager } from "Common/Utility/HandleUtility";
import { sortWithSet } from "Common/Utility/GenericInterface";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface ScheduledDepositSearchCondition {
  customerName: string | null;
  from: DateTime | null;
  to: DateTime | null;
}

const initialScheduledDepositSearchCondition: ScheduledDepositSearchCondition = {
  customerName: null,
  from: DateUtility.firstDate(DateUtility.nowDate()),
  to: DateUtility.endDate(DateUtility.nowDate()),
};

export const ScheduledDeposit = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [depositPlans, setDepositPlans] = useState<DepositPlan[]>([]);

  const [condition, setCondition] = useState<ScheduledDepositSearchCondition>(initialScheduledDepositSearchCondition);

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<ScheduledDepositSearchCondition>({} as ScheduledDepositSearchCondition);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useSimpleFetch("/deposit/depositplans", setDepositPlans, false, DateUtility.InvalidToNull(search, "from", "to"), [
      search,
    ])
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ScheduledDeposit
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "得意先名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 175,
        label: "請求締日",
        dataKey: "closingDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(1),
      },
      {
        width: 175,
        label: "入金予定日",
        dataKey: "depositDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "請求額",
        dataKey: "claimAmount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => `${data.toLocaleString()} 円`,
        sort: (asc: boolean) => sortWithSet(depositPlans, setDepositPlans, "claimAmount", asc),
        visible: getVisible(3),
      },
      {
        width: 200,
        label: "入金額",
        dataKey: "depositAmount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number) => `${data.toLocaleString()} 円`,
        sort: (asc: boolean) => sortWithSet(depositPlans, setDepositPlans, "depositAmount", asc),
        visible: getVisible(4),
      },
    ],
    [depositPlans, setDepositPlans, getVisible]
  );

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Condition observer={conditionRef}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <TextField
                className={genericClasses.margin}
                label="得意先名"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <Period
                label="入金予定日"
                fromValue={condition.from}
                toValue={condition.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
                width={170}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialScheduledDepositSearchCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={depositPlans}
              setValues={setDepositPlans}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
});
