import React, { useMemo, useState, useCallback, useEffect, useRef, ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import VirtualizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import {
  Company,
  CompanyHeadOfficeBank,
  CompanyContactUser,
  CompanyManagementReviewComment,
  CompanyCreditLimit,
} from "Models/Company";
import { ModelMaster } from "Models/ModelMaster";
import { AddBox } from "@material-ui/icons";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { SelectMonth, SelectDay, modelColumns } from "Common/Utility/Constants";
import { useFetch, useExecute, useExecuteEx } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useInputManager, handleOnChange } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import {
  CallOnClose,
  InputPending,
  onCloseWithSave,
  useIsValidCompanyEditAuthority,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectDialog, LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import DigitField, { DigitsWithHyphenField } from "Component/DigitsField";
import Label from "Component/Label";
import { AppProvider } from "App";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { CompanyEditAuth } from "Common/Utility/Role";
import { MenuIndex } from "Component/Menu";
import Select from "Component/Select";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";

const useStyles = makeStyles((theme) => ({
  selectCustomer: {
    width: 300,
    margin: theme.spacing(1),
  },
  selectDate: {
    margin: theme.spacing(1),
    width: 150,
  },
  tab: {
    marginTop: theme.spacing(1) * 3,
    marginBottom: -12,
  },
  content: {
    height: 707,
  },
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  inEditHalf: {
    margin: theme.spacing(1),
    width: `calc((100% - ${theme.spacing(1) * 4}px) / 2)`,
  },
  needCreditLimit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px - ${64 + theme.spacing(1)}px)`,
    display: "inline-block",
    verticalAlign: "top",
  },
  confirmCreditLimit: {
    marginTop: 18,
  },
  typography: {
    marginTop: 30,
  },
  typographyCheckbox: {
    marginTop: 18,
  },
  tablePaper: {
    height: 150,
  },
  topNoMargin: {
    marginTop: 12,
  },
  leftAndRight0: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

interface EditTabProps {
  edited: () => void;
  company: Company;
  setCompany: React.Dispatch<React.SetStateAction<Company>>;
  inputState: InputState;
  userMaster: ComboItem[];
  modelMaster: ModelMaster[];
  setInputState: React.Dispatch<React.SetStateAction<InputState>>;
  setNewManagementReviewComment: React.Dispatch<React.SetStateAction<CompanyManagementReviewComment[]>>;
  setNewCreditLimit: React.Dispatch<React.SetStateAction<CompanyCreditLimit[]>>;
}

const SupplierInfoTab = (props: EditTabProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const inputManager = useInputManager(props.setCompany, props.edited);

  const [itemHandledOpen, setItemHandledOpen] = useState(false);

  const [editItemHandledIndex, setEditItemHandledIndex] = useState(-1);

  const setCompany = useMemo(() => props.setCompany, [props.setCompany]);

  const edited = useMemo(() => props.edited, [props.edited]);

  const filteredUser = useMemo(() => {
    return props.userMaster.filter((value) => value.value !== props.company.registrantGuidOfSupplierInfo);
  }, [props.userMaster, props.company]);

  const filteredModel = useMemo(() => {
    return props.modelMaster.filter(
      (value) => props.company.itemHandleds.find((item) => item.modelMasterId === value.id) === undefined
    );
  }, [props.modelMaster, props.company]);

  const handleOnClickEdit = useCallback((data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setItemHandledOpen(true);
    setEditItemHandledIndex(rowIndex);
  }, []);

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCompany((value) => {
        value.itemHandleds.splice(rowIndex, 1);
        return { ...value, itemHandleds: [...value.itemHandleds] };
      });

      edited();
    },
    [setCompany, edited]
  );

  const handleOnCloseItemHandled = (result: ModelMaster | null) => {
    setItemHandledOpen(false);

    if (result == null) {
      return;
    }

    props.setCompany((value) => {
      if (result.id == null) {
        return value;
      }

      if (editItemHandledIndex === -1) {
        value.itemHandleds.push({ ...result, modelMasterId: result.id });
      } else {
        value.itemHandleds[editItemHandledIndex] = { ...result, modelMasterId: result.id };
      }
      return { ...value, itemHandleds: [...value.itemHandleds] };
    });

    props.edited();
  };

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Company);

  const itemHandledListColumns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
          rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
            return (
              <IconButton
                color="primary"
                onClick={() => {
                  setItemHandledOpen(true);
                  setEditItemHandledIndex(-1);
                }}
              >
                <AddBox />
              </IconButton>
            );
          },
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 200,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      }
    );

    return ret;
  }, [isValidEdit]);

  const selectRegistrantColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "ユーザー",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>請求書条件</Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl className={classes.inEdit}>
            <InputLabel>請求書条件</InputLabel>
            <Select
              value={props.company.invoiceTerms}
              onChange={inputManager.handleOnChangeSelect("invoiceTerms")}
              disabled={!isValidEdit}
            >
              <MenuItem key={0} value={0}>
                本社一括
              </MenuItem>
              <MenuItem key={1} value={1}>
                支店毎
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>支払条件</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>請求締日</InputLabel>
                <Select
                  disabled={!isValidEdit}
                  value={props.company.supplierClosingDay}
                  onChange={inputManager.handleOnChangeSelect("supplierClosingDay")}
                >
                  {SelectDay.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>入金サイクル</InputLabel>
                <Select
                  disabled={!isValidEdit}
                  value={props.company.supplierDepositCycle}
                  onChange={inputManager.handleOnChangeSelect("supplierDepositCycle")}
                >
                  {SelectMonth.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>入金日</InputLabel>
                <Select
                  disabled={!isValidEdit}
                  value={props.company.supplierDepositDay}
                  onChange={inputManager.handleOnChangeSelect("supplierDepositDay")}
                >
                  {SelectDay.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                className={classes.inEdit}
                freeSolo
                options={["振込", "手形郵送", "現金払い"]}
                getOptionLabel={(option) => option}
                disabled={!isValidEdit}
                value={props.company.supplierPaymentMethod}
                onChange={(event: React.ChangeEvent<{}>, value: string | null) => {
                  if (value != null) {
                    props.setCompany((company) => {
                      return { ...company, supplierPaymentMethod: value };
                    });
                    props.edited();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="支払方法"
                    onChange={inputManager.handleOnChange("supplierPaymentMethod")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>手形支払い</InputLabel>
                <Select
                  value={props.company.billPayment}
                  onChange={inputManager.handleOnChangeSelect("billPayment")}
                  disabled={!isValidEdit}
                >
                  <MenuItem key={1} value={1}>
                    可
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    不可
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <TextField
                className={classes.inEdit}
                label="振込先口座"
                value={props.company.supplierAccountToBeCredited ?? ""}
                onChange={inputManager.handleOnChange("supplierAccountToBeCredited")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography>取扱い品目</Typography>
        </Grid>
        <Grid item xs={10}>
          <Paper className={classes.tablePaper}>
            <VirtualizedTable
              rowHeight={48}
              values={props.company.itemHandleds}
              columns={itemHandledListColumns}
              onClickEdit={handleOnClickEdit}
              onClickDelete={handleOnClickDelete}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>仕入先情報の登録者</Typography>
        </Grid>
        <Grid item xs={10}>
          <Paper className={clsx(genericClasses.marginTop, classes.topNoMargin, classes.leftAndRight0)}>
            <LabelWithSelect
              className={clsx(genericClasses.width100percent, genericClasses.marginLeft)}
              caption="登録者"
              text={props.company.registrantNameOfSupplierInfo}
              data={filteredUser}
              columns={selectRegistrantColumns}
              onClickNarrowDown={useNarrowDown(filteredUser, "text")}
              onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                return {
                  ...value,
                  registrantGuidOfSupplierInfo: result.value,
                  registrantNameOfSupplierInfo: result.text,
                };
              })}
              maxWidth="sm"
              disabled={!isValidEdit}
            />
          </Paper>
        </Grid>
      </Grid>
      <SelectDialog
        title="取扱い品目"
        open={itemHandledOpen}
        columns={modelColumns}
        data={filteredModel}
        onClose={handleOnCloseItemHandled}
        onClickNarrowDown={useNarrowDown(filteredModel, "name", "model")}
      />
    </>
  );
};

interface CommentProps {
  open: boolean;
  data: CompanyManagementReviewComment | null;
  disabled: boolean;
  onClose: (result: CompanyManagementReviewComment | null) => void;
}

const CommentDialog = (props: CommentProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props.open);

  type Comment = { comment: string };

  const [comment, setComment] = useState({ comment: "" } as Comment);

  const inputManager = useInputManager(setComment, edited);

  const handleOnClose = async () => {
    confirm(() => props.onClose(null));
  };

  const handleOnClickCancelInAction = async () => {
    confirm(() => props.onClose(null));
  };

  const handleOnClickSaveInAction = () => {
    if (!comment.comment) {
      alertAdd({ type: "info", message: "コメントは必須項目です。" });
      return;
    }

    props.onClose({
      comment: comment.comment,
      userGuid: ralmAccount?.userGuid,
      userName: ralmAccount?.userName,
    } as CompanyManagementReviewComment);
  };

  useEffect(() => {
    if (props.open) {
      if (props.data) {
        setComment(props.data);
      } else {
        setComment({ comment: "" });
      }
    }
  }, [props.open, props.data]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="xs">
      <DialogTitle>コメント</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.inEdit}
          multiline
          rows={5}
          value={comment.comment}
          onChange={inputManager.handleOnChange("comment")}
          disabled={props.disabled}
        />
      </DialogContent>
      <DialogActions>
        {props.disabled ? (
          <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface CreditLimitProps {
  open: boolean;
  data: CompanyCreditLimit | null;
  disabled: boolean;
  onClose: (result: CompanyCreditLimit | null) => void;
}

const CreditLimitDialog = (props: CreditLimitProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props.open);

  type CreditLimit = { creditLimit: number | null; reason: string };

  const [creditLimit, setCreditLimit] = useState({ creditLimit: null, reason: "" } as CreditLimit);

  const inputManager = useInputManager(setCreditLimit, edited);

  const handleOnClose = async () => {
    confirm(() => props.onClose(null));
  };

  const handleOnClickCancelInAction = async () => {
    confirm(() => props.onClose(null));
  };

  const handleOnClickSaveInAction = async () => {
    if (creditLimit.creditLimit == null) {
      alertAdd({ type: "info", message: "与信額は必須項目です。" });
      return;
    }

    if (!creditLimit.reason) {
      alertAdd({ type: "info", message: "理由は必須項目です。" });
      return;
    }

    props.onClose({
      creditLimit: creditLimit.creditLimit,
      reason: creditLimit.reason,
      userGuid: ralmAccount?.userGuid,
      userName: ralmAccount?.userName,
    } as CompanyCreditLimit);
  };

  useEffect(() => {
    if (props.open) {
      if (props.data) {
        setCreditLimit(props.data);
      } else {
        setCreditLimit({ creditLimit: null, reason: "" });
      }
    }
  }, [props.open, props.data]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth="xs">
      <DialogTitle>与信変更情報</DialogTitle>
      <DialogContent>
        <DigitField
          className={classes.inEdit}
          label="与信額"
          value={props.data ? creditLimit.creditLimit?.toLocaleString() + " 円" : creditLimit.creditLimit ?? ""}
          onChange={inputManager.handleOnChangeNumber("creditLimit")}
          disabled={props.disabled}
        />
        <TextField
          className={classes.inEdit}
          multiline
          label="理由"
          rows="5"
          value={creditLimit.reason}
          onChange={inputManager.handleOnChange("reason")}
          disabled={props.disabled}
        />
      </DialogContent>
      <DialogActions>
        {props.disabled ? (
          <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CustomerInfoTab = (props: EditTabProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const isEditCreditLimit = useIsValidCompanyEditAuthority(CompanyEditAuth.creditLimit);

  const inputManager = useInputManager(props.setCompany, props.edited);

  const [commentOpen, setCommentOpen] = useState(false);

  const [creditLimitOpen, setCreditLimitOpen] = useState(false);

  const [editCommentData, setEditCommentData] = useState<CompanyManagementReviewComment | null>(null);

  const [editCreditLimitData, setEditCreditLimitData] = useState<CompanyCreditLimit | null>(null);

  const handleOnClickReferenceComments = useCallback(
    (data: CompanyManagementReviewComment, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCommentOpen(true);
      setEditCommentData(data);
    },
    []
  );

  const handleOnClickReferenceCreditLimits = useCallback(
    (data: CompanyCreditLimit, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCreditLimitOpen(true);
      setEditCreditLimitData(data);
    },
    []
  );

  const handleOnCloseComment = (result: CompanyManagementReviewComment | null) => {
    setCommentOpen(false);

    if (result == null) {
      return;
    }

    props.setNewManagementReviewComment((comments) => {
      return [...comments, result];
    });

    props.setCompany((value) => {
      value.managementReviewComments = [...value.managementReviewComments, result];
      return { ...value };
    });

    props.edited();
  };

  const handleOnCloseCreditLimit = (result: CompanyCreditLimit | null) => {
    setCreditLimitOpen(false);

    if (result == null) {
      return;
    }

    props.setNewCreditLimit((value) => {
      return [...value, result];
    });

    props.setCompany((value) => {
      value.companyCreditLimits = [...value.companyCreditLimits, result];
      value.creditLimit = result.creditLimit;
      return { ...value };
    });

    props.edited();
  };

  const executePutConfirmedCreditLimt = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { id: string; updatedAt: DateTime; setCompany: React.Dispatch<React.SetStateAction<Company>> }
      ) => {
        var response = await callWebApi().put("/companies/confirmed", {
          id: object.id,
          updatedAt: object.updatedAt,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "与信額の確認を行いました。" });

        if (unmounted.value) {
          return;
        }

        object.setCompany((value) => {
          return { ...value, needConfirmCreditLimit: false };
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickConfirmedCreditLimit = async () => {
    if ((await message.confirm("与信額確認", "与信額の確認を行いましたか？")) && props.company.id) {
      executePutConfirmedCreditLimt({
        id: props.company.id,
        updatedAt: props.company.updatedAt,
        setCompany: props.setCompany,
      });
    }
  };

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Company);

  const commentColumns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
        rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
          return (
            <IconButton
              color="primary"
              onClick={() => {
                setCommentOpen(true);
                setEditCommentData(null);
              }}
            >
              <AddBox />
            </IconButton>
          );
        },
      });
    } else {
      ret.push({
        width: 80,
        label: "",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
      });
    }

    ret.push(
      {
        width: 125,
        label: "作成日",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "createdAt",
        convert: (value: DateTime) => (value ? DateUtility.format(value) : ""),
      },
      {
        width: 150,
        label: "作成者",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "userName",
      },
      {
        width: 150,
        label: "コメント",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "comment",
        fit: true,
      }
    );

    return ret;
  }, [isValidEdit]);

  const creditLimitColumns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit && isEditCreditLimit) {
      ret.push({
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
        rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
          return (
            <IconButton
              color="primary"
              onClick={() => {
                setCreditLimitOpen(true);
                setEditCreditLimitData(null);
              }}
            >
              <AddBox />
            </IconButton>
          );
        },
      });
    } else {
      ret.push({
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.ReferenceButton,
      });
    }

    ret.push(
      {
        width: 125,
        label: "変更日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (value: DateTime) => (value ? DateUtility.format(value) : ""),
      },
      {
        width: 150,
        label: "変更者",
        dataKey: "userName",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 150,
        label: "与信額",
        dataKey: "creditLimit",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (value: number) => value.toLocaleString() + " 円",
      },
      {
        width: 10,
        label: "理由",
        dataKey: "reason",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      }
    );

    return ret;
  }, [isValidEdit, isEditCreditLimit]);

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>支払条件</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>請求締日</InputLabel>
                <Select
                  value={props.company.customerClosingDay}
                  onChange={inputManager.handleOnChange("customerClosingDay")}
                  disabled={!isValidEdit}
                >
                  {SelectDay.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>入金サイクル</InputLabel>
                <Select
                  value={props.company.customerDepositCycle}
                  onChange={inputManager.handleOnChange("customerDepositCycle")}
                  disabled={!isValidEdit}
                >
                  {SelectMonth.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>入金日</InputLabel>
                <Select
                  value={props.company.customerDepositDay}
                  onChange={inputManager.handleOnChange("customerDepositDay")}
                  disabled={!isValidEdit}
                >
                  {SelectDay.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inEdit}>
                <InputLabel>決算月</InputLabel>
                <Select
                  value={props.company.settlementMonth}
                  onChange={inputManager.handleOnChange("settlementMonth")}
                  disabled={!isValidEdit}
                >
                  {[...Array(12)].map((_, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1 + "月"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={4}>
              <Autocomplete
                className={classes.inEdit}
                freeSolo
                options={["振込", "手形郵送"]}
                getOptionLabel={(option) => option}
                value={props.company.customerPaymentMethod}
                disabled={!isValidEdit}
                onChange={(event: React.ChangeEvent<{}>, value: string | null) => {
                  if (value != null) {
                    props.setCompany((company) => {
                      return { ...company, customerPaymentMethod: value };
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="支払方法"
                    onChange={inputManager.handleOnChange("customerPaymentMethod")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitField
                className={classes.inEdit}
                label="手形条件(万円以上)"
                value={props.company.billTerms ?? ""}
                onChange={inputManager.handleOnChangeNumber("billTerms")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitField
                className={classes.inEdit}
                label="振出日起算サイト(日)"
                value={props.company.drawingDate ?? ""}
                onChange={inputManager.handleOnChangeNumber("drawingDate")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
          <TextField
            className={classes.inEdit}
            label="その他"
            value={props.company.otherTerms ?? ""}
            onChange={inputManager.handleOnChange("otherTerms")}
            disabled={!isValidEdit}
          />
          <Grid container direction="row">
            <Grid item xs={2}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="郵便番号"
                value={props.company.customerPostalCode ?? ""}
                onChange={inputManager.handleOnChange("customerPostalCode")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                className={classes.inEdit}
                label="請求書送付先"
                value={props.company.billingAddress ?? ""}
                onChange={inputManager.handleOnChange("billingAddress")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={4}>
              <TextField
                className={classes.inEdit}
                label="担当者"
                value={props.company.customerContactUser ?? ""}
                onChange={inputManager.handleOnChange("customerContactUser")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="TEL"
                value={props.company.customerTel ?? ""}
                onChange={inputManager.handleOnChange("customerTel")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="FAX"
                value={props.company.customerFax ?? ""}
                onChange={inputManager.handleOnChange("customerFax")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
          <FormControl className={classes.inEditHalf}>
            <InputLabel>貴社御指定伝票(請求書等)</InputLabel>
            <Select
              value={props.company.yourDesignatedInvoice}
              onChange={inputManager.handleOnChangeSelect("yourDesignatedInvoice")}
              disabled={!isValidEdit}
            >
              <MenuItem key={-1} value="">
                未選択
              </MenuItem>
              <MenuItem key={1} value={1}>
                あり
              </MenuItem>
              <MenuItem key={0} value={0}>
                なし
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.inEditHalf}>
            <InputLabel>有りの場合弊社様式納品書</InputLabel>
            <Select
              disabled={props.company.yourDesignatedInvoice !== 1 || !isValidEdit}
              value={props.company.ourStyleDeliveryNote ?? ""}
              onChange={inputManager.handleOnChange("ourStyleDeliveryNote")}
            >
              <MenuItem key={-1} value="">
                未選択
              </MenuItem>
              <MenuItem key={1} value={1}>
                要
              </MenuItem>
              <MenuItem key={0} value={0}>
                不要
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.inEdit}
            label="特記事項"
            value={props.company.specialNote ?? ""}
            onChange={inputManager.handleOnChange("specialNote")}
            disabled={!isValidEdit}
          />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography className={classes.typography}>
              ファクタリング
              <br />
              情報
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl className={classes.inEdit}>
              <InputLabel>ファクタリング制度</InputLabel>
              <Select
                value={props.company.factoringSystem ?? ""}
                onChange={inputManager.handleOnChange("factoringSystem")}
                disabled={!isValidEdit}
              >
                <MenuItem key={-1} value="">
                  未選択
                </MenuItem>
                <MenuItem key={1} value={1}>
                  あり
                </MenuItem>
                <MenuItem key={0} value={0}>
                  なし
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.inEditHalf}
              label="企業名"
              disabled={props.company.factoringSystem !== 1 || !isValidEdit}
              value={props.company.customerFactoringCompanyName ?? ""}
              onChange={inputManager.handleOnChange("customerFactoringCompanyName")}
            />
            <TextField
              className={classes.inEditHalf}
              label="担当者"
              disabled={props.company.factoringSystem !== 1 || !isValidEdit}
              value={props.company.customerFactoringContactUser ?? ""}
              onChange={inputManager.handleOnChange("customerFactoringContactUser")}
            />
            <Grid container direction="row">
              <Grid item xs={2}>
                <DigitsWithHyphenField
                  className={classes.inEdit}
                  label="郵便番号"
                  disabled={props.company.factoringSystem !== 1 || !isValidEdit}
                  value={props.company.customerFactoringPostalCode ?? ""}
                  onChange={inputManager.handleOnChange("customerFactoringPostalCode")}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  className={classes.inEdit}
                  label="住所"
                  disabled={props.company.factoringSystem !== 1 || !isValidEdit}
                  value={props.company.customerFactoringAddress ?? ""}
                  onChange={inputManager.handleOnChange("customerFactoringAddress")}
                />
              </Grid>
            </Grid>
            <DigitsWithHyphenField
              className={classes.inEditHalf}
              label="TEL"
              disabled={props.company.factoringSystem !== 1 || !isValidEdit}
              value={props.company.customerFactoringTel ?? ""}
              onChange={inputManager.handleOnChange("customerFactoringTel")}
            />
            <DigitsWithHyphenField
              className={classes.inEditHalf}
              label="FAX"
              disabled={props.company.factoringSystem !== 1 || !isValidEdit}
              value={props.company.customerFactoringFax ?? ""}
              onChange={inputManager.handleOnChange("customerFactoringFax")}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography className={classes.typographyCheckbox}>契約情報</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  color="primary"
                  checked={props.company.conclusionOfTradeContract}
                  onChange={inputManager.handleOnChangeCheck("conclusionOfTradeContract")}
                  disabled={!isValidEdit}
                />
              }
              label="基本取引契約書締結"
            />
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  color="primary"
                  checked={props.company.conclusionOfBasicTranssactionAgreement}
                  onChange={inputManager.handleOnChangeCheck("conclusionOfBasicTranssactionAgreement")}
                  disabled={!isValidEdit}
                />
              }
              label="売買契約書締結"
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography className={classes.typography}>振込先情報</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              className={classes.inEdit}
              label="振り込まれ先口座"
              value={props.company.customerAccountToBeCredited ?? ""}
              onChange={inputManager.handleOnChange("customerAccountToBeCredited")}
              disabled={!isValidEdit}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography className={classes.typography}>経営審査情報</Typography>
          </Grid>
          <Grid item xs={10}>
            <DatePickersUtilsProvider>
              <KeyboardDatePicker
                className={classes.inEditHalf}
                label="調査年月日"
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                autoOk={true}
                value={props.company.surveyDate}
                onChange={inputManager.handleOnChangeDate("surveyDate")}
                invalidLabel=""
                invalidDateMessage=""
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disabled={!isValidEdit}
              />
            </DatePickersUtilsProvider>
            <DigitField
              className={classes.inEditHalf}
              label="TDB評価点数"
              value={props.company.tdbEvaluationScore ?? ""}
              onChange={inputManager.handleOnChangeNumber("tdbEvaluationScore")}
              disabled={!isValidEdit}
            />
            <Paper className={classes.tablePaper}>
              <VirtualizedTable
                rowHeight={48}
                values={props.company.managementReviewComments}
                columns={commentColumns}
                onClickReference={handleOnClickReferenceComments}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Typography className={classes.typography}>与信情報</Typography>
          </Grid>
          <Grid item xs={10}>
            {isEditCreditLimit && props.company.needConfirmCreditLimit ? (
              <>
                <Button
                  className={clsx(classes.confirmCreditLimit, genericClasses.marginRight)}
                  variant="contained"
                  color="secondary"
                  onClick={handleOnClickConfirmedCreditLimit}
                  disabled={!isValidEdit}
                >
                  確認
                </Button>
                <Label
                  className={classes.needCreditLimit}
                  caption="与信額"
                  text={props.company.creditLimit ? props.company.creditLimit.toLocaleString() + " 円" : ""}
                />
              </>
            ) : (
              <Label
                className={classes.inEdit}
                caption="与信額"
                text={props.company.creditLimit ? props.company.creditLimit.toLocaleString() + " 円" : ""}
              />
            )}
            <Paper className={classes.tablePaper}>
              <VirtualizedTable
                rowHeight={48}
                values={props.company.companyCreditLimits}
                columns={creditLimitColumns}
                onClickReference={handleOnClickReferenceCreditLimits}
              />
            </Paper>
            <Grid container direction="row">
              <Grid item xs={6}>
                <FormControl className={classes.inEdit}>
                  <InputLabel>再調査期間</InputLabel>
                  <Select
                    value={props.company.resurveyPeriod}
                    onChange={inputManager.handleOnChange("resurveyPeriod")}
                    disabled={!isValidEdit}
                  >
                    <MenuItem key={1} value={1}>
                      1ヶ月
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      2ヶ月
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      3ヶ月
                    </MenuItem>
                    <MenuItem key={4} value={4}>
                      4ヶ月
                    </MenuItem>
                    <MenuItem key={6} value={6}>
                      6ヶ月
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <DatePickersUtilsProvider>
                  <KeyboardDatePicker
                    className={classes.inEdit}
                    label="与信額確認日"
                    disableToolbar
                    variant="inline"
                    format="MM/dd"
                    autoOk={true}
                    value={props.company.dateConfirmationOfCreditLimit}
                    onChange={inputManager.handleOnChangeDate("dateConfirmationOfCreditLimit")}
                    invalidLabel=""
                    invalidDateMessage=""
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={!isValidEdit}
                  />
                </DatePickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommentDialog
        open={commentOpen}
        data={editCommentData}
        disabled={!!editCommentData || !isValidEdit}
        onClose={handleOnCloseComment}
      />
      <CreditLimitDialog
        open={creditLimitOpen}
        data={editCreditLimitData}
        disabled={!!editCreditLimitData || !isValidEdit}
        onClose={handleOnCloseCreditLimit}
      />
    </>
  );
};

const relationToMe = {
  customer: 1 << 0,
  supplier: 1 << 1,
} as const;

const BasicInfoTab = (props: EditTabProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const inputManager = useInputManager(props.setCompany, props.edited);

  const [selectUserOpen, setSelectUserOpen] = useState(false);

  const [editContactUser, setEditContactUser] = useState({} as CompanyContactUser);

  const [editIndexContactUserWithinCompany, setEditIndexContactUserWithinCompany] = useState(-1);

  const isHeadOffice = useMemo(() => props.company.parentCompanyId == null, [props.company.parentCompanyId]);

  const setCompany = useMemo(() => props.setCompany, [props.setCompany]);

  const setInputState = useMemo(() => props.setInputState, [props.setInputState]);

  const edited = useMemo(() => props.edited, [props.edited]);

  const filteredUser = useMemo(() => {
    return props.userMaster.filter(
      (value) => props.company.contactUsersWithinCompany.find((user) => user.userGuid === value.value) === undefined
    );
  }, [props.userMaster, props.company]);

  const handleOnChangeRelationToMe =
    (target: keyof typeof relationToMe) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      props.setCompany((value) => {
        value.relationToMe = checked
          ? value.relationToMe | relationToMe[target]
          : value.relationToMe & ~relationToMe[target];
        return { ...value };
      });

      props.edited();
    };

  const handleOnChangeBank =
    (target: keyof CompanyHeadOfficeBank, index: number) => (event: ChangeEvent<HTMLAreaElement | any>) => {
      const newValue = event.target.value;
      props.setCompany((value) => {
        value.headOfficeBanks[index][target] = newValue;
        value.headOfficeBanks = [...value.headOfficeBanks];
        return { ...value };
      });
      props.edited();
    };

  const handleOnClickAddContactUser = useCallback(() => {
    props.setCompany((value) => {
      value.contactUsers = [...value.contactUsers, { name: "", tel: "", email: "" }];
      return { ...value };
    });
    setEditContactUser({ name: "", tel: "", email: "" });
  }, [props]);

  const hanldeOnClickEditContactUser = useCallback(
    (data: CompanyContactUser, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditContactUser(data);
    },
    []
  );

  const hanldeOnClickCancelContactUser = useCallback(
    (data: CompanyContactUser, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (props.inputState === InputState.Adding) {
        setCompany((value) => {
          value.contactUsers.pop();
          return { ...value };
        });
      }
    },
    [props.inputState, setCompany]
  );

  const hanldeOnClickSaveContactUser = useCallback(
    (
      data: CompanyContactUser,
      columnData: ColumnData,
      rowIndex: number,
      columnIndex: number,
      args: { cancel: boolean }
    ) => {
      if (!editContactUser.name) {
        alertAdd({ type: "info", message: "担当者は必須項目です。" });
        args.cancel = true;
        return;
      }

      setCompany((value) => {
        value.contactUsers[rowIndex] = editContactUser;
        return { ...value };
      });

      edited();
    },
    [alertAdd, setCompany, edited, editContactUser]
  );

  const hanldeOnClickDeleteContactUser = useCallback(
    (data: CompanyContactUser, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCompany((value) => {
        value.contactUsers.splice(rowIndex, 1);
        return { ...value };
      });

      edited();
    },
    [setCompany, edited]
  );

  const handleOnUnmounted = useCallback(
    (inputState: InputState) => {
      if (inputState === InputState.Adding) {
        setCompany((value) => {
          value.contactUsers.pop();
          return { ...value };
        });
      }
    },
    [setCompany]
  );

  const handleOnClickEditContactUserWithinCompany = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setSelectUserOpen(true);
      setEditIndexContactUserWithinCompany(rowIndex);
    },
    []
  );

  const handleOnClickDeleteContactUserWithinCompany = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCompany((value) => {
        value.contactUsersWithinCompany.splice(rowIndex, 1);
        return { ...value, contactUsersWithinCompany: [...value.contactUsersWithinCompany] };
      });

      edited();
    },
    [setCompany, edited]
  );

  const handleOnCloseSelectUser = (result: ComboItem | null) => {
    setSelectUserOpen(false);

    if (result == null) {
      return;
    }

    props.setCompany((value) => {
      if (editIndexContactUserWithinCompany === -1) {
        value.contactUsersWithinCompany.push({ userGuid: result.value, userName: result.text });
      } else {
        value.contactUsersWithinCompany[editIndexContactUserWithinCompany] = {
          userGuid: result.value,
          userName: result.text,
        };
      }
      return { ...value, contactUsersWithinCompany: [...value.contactUsersWithinCompany] };
    });

    props.edited();
  };

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Company);

  const contactUserColumns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push(
      {
        width: 150,
        label: "担当者",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editContactUser.name}
              onChange={handleOnChange<CompanyContactUser>("name", setEditContactUser)}
            />
          );
        },
      },
      {
        width: 150,
        label: "TEL",
        dataKey: "tel",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsWithHyphenField
              className={genericClasses.width100percent}
              value={editContactUser.tel}
              onChange={handleOnChange<CompanyContactUser>("tel", setEditContactUser)}
            />
          );
        },
      },
      {
        width: 135,
        label: "MAIL",
        dataKey: "email",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editContactUser.email}
              onChange={handleOnChange<CompanyContactUser>("email", setEditContactUser)}
            />
          );
        },
      }
    );

    return ret;
  }, [isValidEdit, genericClasses.width100percent, editContactUser]);

  const contactUserWithinCompanyColumns: ColumnData[] = useMemo(() => {
    let ret: ColumnData[] = [];

    if (isValidEdit) {
      ret.push(
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
          rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
            return (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={() => {
                  setSelectUserOpen(true);
                  setEditIndexContactUserWithinCompany(-1);
                }}
              >
                <AddBox />
              </IconButton>
            );
          },
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        }
      );
    }

    ret.push({
      width: 150,
      label: "担当者",
      dataKey: "userName",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
    });

    return ret;
  }, [isValidEdit]);

  const selectUserColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "ユーザー",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>自社との関係</Typography>
        </Grid>
        <Grid item xs={10}>
          <Box className={classes.topNoMargin}>
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  color="primary"
                  checked={(props.company.relationToMe & relationToMe.customer) > 0}
                  onChange={handleOnChangeRelationToMe("customer")}
                  disabled={!isValidEdit}
                />
              }
              label="得意先"
            />
            <FormControlLabel
              className={genericClasses.margin}
              control={
                <Checkbox
                  color="primary"
                  checked={(props.company.relationToMe & relationToMe.supplier) > 0}
                  onChange={handleOnChangeRelationToMe("supplier")}
                  disabled={!isValidEdit}
                />
              }
              label="仕入先"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>本社情報</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="郵便番号"
                value={props.company.basicHeadOfficePostalCode ?? ""}
                onChange={inputManager.handleOnChange("basicHeadOfficePostalCode")}
                disabled={!isHeadOffice || !isValidEdit}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                className={classes.inEdit}
                label="住所"
                value={props.company.basicHeadOfficeAddress ?? ""}
                onChange={inputManager.handleOnChange("basicHeadOfficeAddress")}
                disabled={!isHeadOffice || !isValidEdit}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={4}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="TEL"
                value={props.company.basicHeadOfficeTel ?? ""}
                onChange={inputManager.handleOnChange("basicHeadOfficeTel")}
                disabled={!isHeadOffice || !isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="FAX"
                value={props.company.basicHeadOfficeFax ?? ""}
                onChange={inputManager.handleOnChange("basicHeadOfficeFax")}
                disabled={!isHeadOffice || !isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.inEdit}
                label="代表者名"
                value={props.company.basicHeadOfficeRepresentativeName ?? ""}
                onChange={inputManager.handleOnChange("basicHeadOfficeRepresentativeName")}
                disabled={!isHeadOffice || !isValidEdit}
              />
            </Grid>
          </Grid>
          <TextField
            className={classes.inEditHalf}
            label="事業形態"
            value={props.company.businessType ?? ""}
            onChange={inputManager.handleOnChange("businessType")}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="主な業種"
            value={props.company.mainBusinessType ?? ""}
            onChange={inputManager.handleOnChange("mainBusinessType")}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <DigitField
            className={classes.inEditHalf}
            label="資本金(万円)"
            value={props.company.capital ?? ""}
            onChange={inputManager.handleOnChangeNumber("capital")}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <DigitField
            className={classes.inEditHalf}
            label="従業員数"
            value={props.company.numberOfEmployees ?? ""}
            onChange={inputManager.handleOnChangeNumber("numberOfEmployees")}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="取引銀行(銀行・信用金庫)"
            value={props.company.headOfficeBanks[0].correspondentBank ?? ""}
            onChange={handleOnChangeBank("correspondentBank", 0)}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="支店"
            value={props.company.headOfficeBanks[0].bankBranch ?? ""}
            onChange={handleOnChangeBank("bankBranch", 0)}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="取引銀行(銀行・信用金庫)"
            value={props.company.headOfficeBanks[1].correspondentBank ?? ""}
            onChange={handleOnChangeBank("correspondentBank", 1)}
            disabled={!isHeadOffice || !isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="支店"
            value={props.company.headOfficeBanks[1].bankBranch ?? ""}
            onChange={handleOnChangeBank("bankBranch", 1)}
            disabled={!isHeadOffice || !isValidEdit}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>企業情報</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={6}>
              <Label
                className={classes.inEdit}
                caption="企業名(親)"
                text={props.company.parentCompanyName ?? ""}
                underLine={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Label className={classes.inEdit} caption="企業ID" text={props.company.manualId ?? ""} underLine={true} />
            </Grid>
          </Grid>
          <TextField
            className={classes.inEdit}
            label="企業名(フリガナ)"
            value={props.company.companyNameKana ?? ""}
            onChange={inputManager.handleOnChange("companyNameKana")}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEdit}
            label="企業名(事業者・支店・営業所)"
            value={props.company.basicCompanyCompanyName ?? ""}
            onChange={inputManager.handleOnChange("basicCompanyCompanyName")}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEdit}
            label="帳票出力用企業名１段目"
            value={props.company.companyName1CaseReport ?? ""}
            onChange={inputManager.handleOnChange("companyName1CaseReport")}
            inputProps={{ maxLength: 30 }}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEdit}
            label="帳票出力用企業名２段目"
            value={props.company.companyName2CaseReport ?? ""}
            onChange={inputManager.handleOnChange("companyName2CaseReport")}
            inputProps={{ maxLength: 30 }}
            disabled={!isValidEdit}
          />
          <Grid container direction="row">
            <Grid item xs={2}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="郵便番号"
                value={props.company.basicCompanyPostalCode ?? ""}
                onChange={inputManager.handleOnChange("basicCompanyPostalCode")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                className={classes.inEdit}
                label="住所"
                value={props.company.basicCompanyAddress ?? ""}
                onChange={inputManager.handleOnChange("basicCompanyAddress")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
          <DigitsWithHyphenField
            className={classes.inEditHalf}
            label="TEL"
            value={props.company.basicCompanyTel ?? ""}
            onChange={inputManager.handleOnChange("basicCompanyTel")}
            disabled={!isValidEdit}
          />
          <DigitsWithHyphenField
            className={classes.inEditHalf}
            label="FAX"
            value={props.company.basicCompanyFax ?? ""}
            onChange={inputManager.handleOnChange("basicCompanyFax")}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="記入者 - 部署名"
            value={props.company.scribeOfDepartment ?? ""}
            onChange={inputManager.handleOnChange("scribeOfDepartment")}
            disabled={!isValidEdit}
          />
          <TextField
            className={classes.inEditHalf}
            label="記入者 - お名前"
            value={props.company.scribeOfName ?? ""}
            onChange={inputManager.handleOnChange("scribeOfName")}
            disabled={!isValidEdit}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography className={classes.typography}>担当者</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row">
            <Grid item xs={4}>
              <TextField
                className={classes.inEdit}
                label="代表者 - 名前"
                value={props.company.representativeName ?? ""}
                onChange={inputManager.handleOnChange("representativeName")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DigitsWithHyphenField
                className={classes.inEdit}
                label="代表者 - 携帯電話"
                value={props.company.representativeTel ?? ""}
                onChange={inputManager.handleOnChange("representativeTel")}
                disabled={!isValidEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.inEdit}
                label="代表者 - メールアドレス"
                value={props.company.representativeEmail ?? ""}
                onChange={inputManager.handleOnChange("representativeEmail")}
                disabled={!isValidEdit}
              />
            </Grid>
          </Grid>
          <Paper className={clsx(classes.tablePaper, genericClasses.marginBottom)}>
            <VirtualizedTable
              rowHeight={48}
              values={props.company.contactUsers}
              columns={contactUserColumns}
              onClickAddDirectInput={isValidEdit ? handleOnClickAddContactUser : undefined}
              onClickEdit={hanldeOnClickEditContactUser}
              onClickCancel={hanldeOnClickCancelContactUser}
              onClickSave={hanldeOnClickSaveContactUser}
              onClickDelete={hanldeOnClickDeleteContactUser}
              setEditing={setInputState}
              onUnmounted={handleOnUnmounted}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Typography>社内担当者</Typography>
        </Grid>
        <Grid item xs={10}>
          <Paper className={clsx(classes.tablePaper, genericClasses.marginBottom)}>
            <VirtualizedTable
              rowHeight={48}
              values={props.company.contactUsersWithinCompany}
              columns={contactUserWithinCompanyColumns}
              onClickEdit={handleOnClickEditContactUserWithinCompany}
              onClickDelete={handleOnClickDeleteContactUserWithinCompany}
            />
          </Paper>
        </Grid>
      </Grid>
      <SelectDialog
        title="社内担当者"
        onClickNarrowDown={useNarrowDown(filteredUser, "text")}
        data={filteredUser}
        columns={selectUserColumns}
        onClose={handleOnCloseSelectUser}
        open={selectUserOpen}
      />
    </>
  );
};

interface EditDialogProps extends InputPending {
  open: boolean;
  data: Company;
}

export const EditDialog = React.memo((props: EditDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [edited, confirm] = useWhetherEdited(props);

  const [tabIndex, setTabIndex] = useState(0);

  const [company, setCompany] = useState<Company>({ ...props.data });

  const [newManagementReviewComment, setNewManagementReviewComment] = useState<CompanyManagementReviewComment[]>([
    ...props.data.managementReviewComments,
  ]);

  const [newCreditLimit, setNewCreditLimit] = useState<CompanyCreditLimit[]>([...props.data.companyCreditLimits]);

  const scrollPosition = useRef<HTMLDivElement>(null);

  const [inputState, setInputState] = useState<InputState>(InputState.None);

  const [userMaster, setUserMaster] = useState<ComboItem[]>([]);

  const [modelMaster, setModelMaster] = useState<ModelMaster[]>([]);

  const tab = useMemo(() => {
    const state = {
      edited: edited,
      company: company,
      setCompany: setCompany,
      inputState: inputState,
      userMaster: userMaster,
      modelMaster: modelMaster,
      setInputState: setInputState,
      setNewManagementReviewComment: setNewManagementReviewComment,
      setNewCreditLimit: setNewCreditLimit,
    };

    switch (tabIndex) {
      case 0:
        return <BasicInfoTab {...state} />;
      case 1:
        return <CustomerInfoTab {...state} />;
      case 2:
        return <SupplierInfoTab {...state} />;
      default:
        return <></>;
    }
  }, [tabIndex, edited, company, inputState, userMaster, modelMaster]);

  const handleOnChangeTabs = (event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);

    if (scrollPosition.current != null) {
      scrollPosition.current.scrollTop = 0;
    }
  };

  const handleOnClickCancelInAction = async () => {
    confirm(() => props.onClose());
  };

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          company: Company;
          newManagementReviewComment: CompanyManagementReviewComment[];
          newCreditLimit: CompanyCreditLimit[];
          onClose: CallOnClose;
        }
      ) => {
        const company = {
          ...object.company,
          managementReviewComments: object.newManagementReviewComment,
          companyCreditLimits: object.newCreditLimit,
        } as Company;

        var response;
        if (company.id == null) {
          response = await callWebApi().post("/companies", company);
        } else {
          response = await callWebApi().put("/companies", company);
        }

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "企業情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "企業",
    <EditDialog open={true} data={company} onClose={props.onClose} />,
    props.onClose,
    props.onHold,
    () => {
      company.managementReviewComments = [...newManagementReviewComment];
      company.companyCreditLimits = [...newCreditLimit];
    }
  );

  const handleOnClickSaveInAction = async () => {
    if (!company.basicCompanyCompanyName) {
      alertAdd({ type: "info", message: "企業名(事業者・支店・営業所)は必須項目です。" });
      return;
    }

    if (company.surveyDate != null && !DateUtility.isValid(company.surveyDate)) {
      alertAdd({ type: "info", message: "調査年月日が不正です。" });
      return;
    }

    if (company.dateConfirmationOfCreditLimit != null && !DateUtility.isValid(company.dateConfirmationOfCreditLimit)) {
      alertAdd({ type: "info", message: "与信額確認日が不正です。" });
      return;
    }

    executePut({
      company: company,
      newManagementReviewComment: newManagementReviewComment,
      newCreditLimit: newCreditLimit,
      onClose: props.onClose,
    });
  };

  const handleOnClickCopy = async () => {
    if (await message.confirm("確認", "顧客情報をコピーしますか？")) {
      alertAdd({ type: "success", message: `${company.manualId} をコピーしました。` });

      setCompany((value) => {
        value.parentCompanyId = value.parentCompanyId ?? value.id;
        value.id = null;
        value.manualId = "";
        value.managementReviewComments = [];
        value.companyCreditLimits = [];
        value.creditLimit = null;

        return { ...value };
      });

      edited();
    }
  };

  const onClose = useMemo(() => props.onClose, [props.onClose]);

  const loadingElement = useLoadingElement(
    "",
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.shouldInitialize || company.id == null) {
            return;
          }

          const response = await callWebApi().get<Company>(`/companies/${company.id}`, {
            cancelToken: signal.token,
          });

          if (!response.data) {
            alertAdd({ type: "info", message: "存在しない企業情報です。既に削除済みの可能性があります。" });
            onClose();
            return;
          }

          setCompany(response.data);
        },
        [alertAdd, onClose, company.id, props.shouldInitialize]
      ),
      props.shouldInitialize
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/users/usermaster", { cancelToken: signal.token });
        setUserMaster(response.data);
      }, [])
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ModelMaster[]>("/modelmaster", { cancelToken: signal.token });
        setModelMaster(response.data);
      }, [])
    )
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Company);

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>
        <Grid container justify="space-between">
          <Grid item>企業情報</Grid>
          <Grid item>
            {company.id != null && isValidEdit && (
              <Button color="primary" onClick={handleOnClickCopy}>
                コピーして新規顧客情報を作成
              </Button>
            )}
          </Grid>
        </Grid>
        <Paper className={classes.tab} square>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleOnChangeTabs}
            aria-label="tabs"
          >
            <Tab label="基本情報" />
            <Tab label="得意先情報" />
            <Tab label="仕入先情報" />
          </Tabs>
        </Paper>
      </DialogTitle>
      <DialogContent className={classes.content} ref={scrollPosition}>
        {loadingElement ?? tab}
      </DialogContent>
      <DialogActions>
        {isValidEdit ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inputState !== InputState.None || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inputState !== InputState.None || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={handleOnClickCancelInAction} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
