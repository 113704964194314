import { useContentHeight } from "Hooks/useResize";
import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useGenericStyles } from "Common/Utility/Styles";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useInputManager } from "Common/Utility/HandleUtility";
import { CheckSheet } from "Models/CheckSheet";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import TextField from "Component/TextField";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute } from "Hooks/useFetch";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import {
  deleteButton,
  editButton,
  referenceButton,
  removeArrayState,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useColumnControl } from "Hooks/useColumnControl";
import { format } from "date-fns";
import { EditDialog } from "./EditDialog";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 100,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 100,
  },
}));

interface Condition {
  name?: string;
}

const initialCondition: Condition = {};

export const CheckSheetPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const alerttAdd = useAlertAdd();

  const message = useMessageBox();

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const [data, setData] = useState<CheckSheet[]>([]);

  const [checkSheet, setCheckSheet] = useState<CheckSheet | undefined>(undefined);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useSimpleFetch("/checksheets", setData, false, search, [search])
  );

  const handleOnClickAdd = useCallback(() => {
    setCheckSheet({ items: [] });
  }, []);

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        await callWebApi().delete(`/checksheets/${object.id}`);

        alerttAdd({ type: "success", message: "チェックシートを削除しました。" });

        if (unmounted.value) {
          return;
        }

        removeArrayState(setData, object.rowIndex);
      },
      [alerttAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (checkSheet: CheckSheet, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (checkSheet.id == null) {
        return;
      }

      if (await message.confirm("削除確認", "チェックシートを削除します。よろしいですか？")) {
        executeDelete({ id: checkSheet.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClickEdit = useCallback(
    (data: CheckSheet, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCheckSheet({ ...data });
    },
    []
  );

  const handleOnClose = useOnCloseEditDialog("CheckSheet", setCheckSheet, setData, "id");

  const isMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.CheckSheet
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isMenuEditValid) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 350,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
        fit: true,
      },
      {
        width: 200,
        label: "点検項目数",
        dataKey: "numberOf",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowData: CheckSheet) => rowData.items.length.toString(),
        visible: getVisible(1),
      },
      {
        width: 200,
        label: "更新日時",
        dataKey: "updatedAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: CheckSheet) =>
          rowData.updatedAt == null ? "" : format(new Date(rowData.updatedAt), "yyyy/MM/dd HH:mm:ss"),
        visible: getVisible(2),
      }
    );

    return ret;
  }, [isMenuEditValid, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.margin}
                label="名称"
                value={condition.name}
                onChange={inputManager.handleOnChange("name")}
              />

              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialCondition)}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={data}
                setValues={setData}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isMenuEditValid ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {checkSheet != null && (
        <EditDialog open={true} onClose={handleOnClose} checkSheet={checkSheet} editAuth={isMenuEditValid} />
      )}
    </>
  );
};
