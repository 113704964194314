import { InputAdornment, TextFieldProps } from "@material-ui/core";
import React from "react";
import TextField from "Component/TextField";

const NumberField = (props: TextFieldProps & { prefix?: string; maxLength: number }) => {
  const { prefix, maxLength, ...other } = props;

  const makeInputProps = () => {
    return prefix
      ? {
          ...props.InputProps,
          startAdornment: <InputAdornment position="start">{prefix + "-"}</InputAdornment>,
        }
      : props.InputProps;
  };

  return (
    <TextField
      {...other}
      InputProps={makeInputProps()}
      inputProps={{
        ...props.inputProps,
        maxLength: maxLength,
      }}
    />
  );
};

export default React.memo(NumberField);
