import { DateUtility } from "Common/Utility/DateUtility";
import React, { useMemo } from "react";
import { EstimateSummary, EstimateDetail } from "./Estimate";
import clsx from "clsx";
import { replaceCRLF } from "Common/Utility/GenericInterface";
import { StringUtility } from "Common/Utility/StringUtility";
import { RentalPayOff, RentalPayOffComboItems, toComboText } from "Common/Utility/Constants";

interface EstimateDetailProps {
  summary: EstimateSummary;
}

const calcRentalDays = (detail: EstimateDetail) => {
  const days = DateUtility.calcRentalDays(detail.fromDate, detail.toDate);
  return days !== null ? `${days}日` : "";
};

const calcRentalMonths = (detail: EstimateDetail) => {
  const months = DateUtility.calcRentalMonths(detail.fromDate, detail.toDate);
  return months !== null ? `${months}ヵ月` : "";
};

const calcRentalMonthsAndDays = (detail: EstimateDetail, customerClosingDay: number) => {
  const monthsAndDays = DateUtility.calcRentalMonthsAndDays(detail.fromDate, detail.toDate, customerClosingDay);
  return monthsAndDays !== null ? `${monthsAndDays.months}ヵ月${monthsAndDays.days}日` : "";
};

const EstimateDetailDesign = (props: EstimateDetailProps) => {
  const data = useMemo(() => {
    const details: EstimateDetail[][] = [];
    let detail: EstimateDetail[] = [];

    const propDetails: EstimateDetail[] = [];
    props.summary.details.forEach((detail) => {
      if (detail.payOff === null) {
        propDetails.push(detail);
        return;
      }

      if (detail.payOff === RentalPayOff.Daily) {
        propDetails.push({ ...detail, period: calcRentalDays(detail) });
      } else if (detail.payOff === RentalPayOff.Monthly) {
        propDetails.push({ ...detail, period: calcRentalMonths(detail) });
      } else if (detail.payOff === RentalPayOff.MonthlyDaily) {
        propDetails.push({ ...detail, period: calcRentalMonthsAndDays(detail, props.summary.customerClosingDay) });
      } else if (detail.payOff === RentalPayOff.Fixed) {
        propDetails.push({ ...detail, period: calcRentalDays(detail) });
      }
    });

    propDetails.forEach((value, index) => {
      if (detail.length < (details.length === 0 ? 9 : 20)) {
        detail.push(value);
      } else {
        details.push(detail);
        detail = [];
        detail.push(value);
      }

      if (index >= propDetails.length - 1) {
        details.push(detail);
      }
    });

    return details;
  }, [props.summary.customerClosingDay, props.summary.details]);

  return (
    <>
      {data.map((details, detailsIndex) => {
        return (
          <div
            key={detailsIndex.toString()}
            className={clsx(
              "detail",
              detailsIndex !== data.length - 1 || details.length > (data.length === 1 ? 7 : 18) ? "break-page" : ""
            )}
          >
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className="leftCell">
                    項目数
                  </th>
                  <th rowSpan={2} className="leftCell">
                    商品名
                  </th>
                  <th rowSpan={2}>基本料</th>
                  <th rowSpan={2}>単価</th>
                  <th rowSpan={2}>数量</th>
                  <th rowSpan={2}>単位</th>
                  <th>開始日</th>
                  <th rowSpan={2}>期間</th>
                  <th rowSpan={2}>極</th>
                  <th rowSpan={2}>金額</th>
                  <th rowSpan={2} className="rightCell">
                    摘　　要
                  </th>
                </tr>
                <tr>
                  <th>終了日</th>
                </tr>
              </thead>
              <tbody>
                {details.map((detail, detailIndex) => {
                  return (
                    <React.Fragment key={detailIndex.toString()}>
                      <tr>
                        <td className="leftCell indexCell" rowSpan={2}>
                          {detail.numberOfItem}
                        </td>
                        <td className={detail.className} rowSpan={2}>
                          {detail.name}
                        </td>
                        <td className="basicPriceCell" rowSpan={2}>
                          {detail.basicPrice == null ? <></> : <>&yen; {detail.basicPrice.toLocaleString()}</>}
                        </td>
                        <td className="unitPriceCell" rowSpan={2}>
                          {detail.unitPrice == null ? <></> : <>&yen; {detail.unitPrice.toLocaleString()}</>}
                        </td>
                        <td className="numberOfCell" rowSpan={2}>
                          {detail.numberOf}
                        </td>
                        <td className="unitCell" rowSpan={2}>
                          {detail.unit}
                        </td>
                        <td className="fromDateCell">{DateUtility.format(detail.fromDate, "yyyy/MM/dd")}</td>
                        <td className="periodCell" rowSpan={2}>
                          {detail.period}
                        </td>
                        <td className="payOffCell" rowSpan={2}>
                          {toComboText(RentalPayOffComboItems, detail.payOff)}
                        </td>
                        <td className="priceCell" rowSpan={2}>
                          {detail.price == null ? <></> : <>&yen; {detail.price.toLocaleString()}</>}
                        </td>
                        <td className="rightCell summaryCell" rowSpan={2}>
                          {detail.taxExemption ? "非課税 " : ""}
                          {detail.summary}
                        </td>
                      </tr>
                      <tr>
                        <td className="toDateCell">{DateUtility.format(detail.toDate, "yyyy/MM/dd")}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};

interface EstimateSummaryDesignProps {
  logoImage: string;

  signImage: string;

  summary: EstimateSummary;
}

const EstimateSummaryDesign = React.memo((props: EstimateSummaryDesignProps) => {
  let companyName: JSX.Element = <>{props.summary.companyName}</>;
  const isNull1 = StringUtility.isNullOrUndefeinedOrEmpty(props.summary.companyName1);
  const isNull2 = StringUtility.isNullOrUndefeinedOrEmpty(props.summary.companyName2);
  if (!isNull1 && !isNull2) {
    companyName = (
      <div className="customerNameHalf">
        {props.summary.companyName1}
        <br />
        {props.summary.companyName2}
      </div>
    );
  } else if (!isNull1) {
    companyName = (
      <div className="customerNameHalf">
        {props.summary.companyName1}
        <br />
      </div>
    );
  } else if (!isNull2) {
    companyName = (
      <div className="customerNameHalf">
        <br />
        {props.summary.companyName2}
      </div>
    );
  }

  return (
    <div>
      <div className="r-container justify-end">
        <div className="voucherNumber">
          <span>No. {props.summary.estimateNumber}</span>
        </div>
      </div>
      <div className="r-container justify-center">
        <div className="title">
          <span>御　見　積　書</span>
        </div>
      </div>
      <div className="r-container justify-start margin20">
        <div className="leftBlock">
          <br />
          <br />
          <br />
          <div className="customerBox">
            <div className="r-container justify-space-between">
              <div className="customerName">{companyName}</div>
              <div>
                <span>御中</span>
              </div>
            </div>
          </div>
          <div className="r-container justify-center">
            <div className="deliveryString">
              <span>下記の通り見積り申し上げます。</span>
              <br />
            </div>
          </div>
          <div className="deliveryDate">
            <span>{DateUtility.format(props.summary.estimateDate, "yyyy 年 M 月 d 日")}</span>
          </div>
          <div className="r-container justify-center margin20">
            <div className="amountUnit">
              <span>&yen;</span>
            </div>
            <div className="amount">
              <span>{props.summary.amount.toLocaleString()}</span>
            </div>
          </div>
          <div className="r-container justify-center">
            <div className="deliveryString">
              <span>この見積金額には消費税は含まれておりません。</span>
              <br />
              <span>{DateUtility.format(props.summary.expirationDate, "見積有効期限：yyyy 年 M 月 d 日")}</span>
            </div>
          </div>
        </div>
        <div className="rightBlock">
          <div className="logo">
            <img src={"data:image/png;base64," + props.logoImage} alt="company logo" style={{ height: 70 }} />
          </div>
          <div className="company">
            <span>株式会社 弘栄ドリームワークス</span>
            <br />
            <span>〒 990-2221 山形市大字風間字地蔵山下2068</span>
            <br />
            <span>TEL : 023-616-5735 FAX : 023-616-5652</span>
            <div className="companySign">
              <img src={"data:image/png;base64," + props.signImage} alt="company sign" style={{ width: 80 }} />
            </div>
          </div>
          <div className="r-container justify-end">
            <table className="signTable">
              <thead>
                <tr>
                  <th>承 認</th>
                  <th>担 当</th>
                </tr>
              </thead>
              <tbody className="signBody">
                <tr>
                  <td className="signCell">
                    {props.summary.approvalStamp && (
                      <img
                        className="sign"
                        src={"data:image/png;base64," + props.summary.approvalStamp}
                        alt="approval sign"
                      />
                    )}
                  </td>
                  <td className="signCell">
                    {props.summary.staffStamp && (
                      <img
                        className="sign"
                        src={"data:image/png;base64," + props.summary.staffStamp}
                        alt="staff sign"
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="margin20">
        <EstimateDetailDesign summary={props.summary} />
      </div>
      <div className="r-container margin10">
        <div className="remark">
          <span>備考</span>
        </div>
      </div>
      <div className="r-container margin10 break-page">
        <div className="remarkValue">{replaceCRLF(props.summary.note)}</div>
      </div>
    </div>
  );
});

interface Props {
  logoImage: string;

  signImage: string;

  summary: EstimateSummary[];
}

const EstimateDesign = (props: Props) => {
  return (
    <>
      <style type="text/css">
        {`@page {
            margin: 7.3mm 4.8mm;
            size: A4 landscape;
          }
          .break-page {
            break-after: page;
          }
          .main {
            font-family: Meiryo;
            font-size: 12px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          .f-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .r-container {
            display: flex;
            flex-direction: row;            
            align-items: flex-start;
            width: 100%
          }
          .justify-start {
            justify-content: flex-start;
          }
          .justify-center {
            justify-content: center;
          }
          .justify-end {
            justify-content: flex-end;
          }
          .justify-space-between {
            justify-content: space-between;
          }
          .margin10 {
            margin-top: 10px;
          }
          .margin20 {
            margin-top: 20px;
          }
          .margin40 {
            margin-top: 40px;
          }
          .voucherNumber {
            border-bottom: solid 1px black;
          }
          .title {
            width: 300px;
            font-size: 28px;
            border-bottom: solid 1px black;
            text-align: center;
            font-weight: bold;
          }
          .leftBlock {
            width: 450px;
          }
          .rightBlock {
            width: calc(100% - 450px);
          }
          .logo {
            text-align: right;
          }
          .company {
            text-align: right;
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .companySign {
            position: absolute;
            top: 0px;
            right: 0px;
          }
          .signTable {
            width: 150px;
            border: solid 1px black;
            table-layout: fixed;
          }
          .signBody {
            height: 75px;
          }
          .signCell {
            width: 75px;
            height: 75px;
            text-align: center;
            vertical-align: middle;
          }
          .sign {
            max-width: 80%;
            max-height: 80%;
          }
          .customerBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            font-size: 24px;
          }
          .customerName {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .customerNameHalf {
            font-size: 12px;
          }
          .constructionBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .deliveryString {
            width: 300px;
          }
          .deliveryDate {
            width: 100%;
            text-align: center;
          }
          .amountUnit {
            font-size: 24px;
            width: 50px;
            border-bottom: double 4px black;
            padding-left: 25px;
          }
          .amount {
            font-size: 24px;
            width: 250px;
            border-bottom: double 4px black;
          }
          .detail {
            width: 100%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 10px;
          }
          th, td {
            border: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .leftCell {
            height: 34px;
            border-left: none;
          }
          .rightCell {
            border-right: none;
          }
          .indexCell {
            width: 50px;
            max-width: 50px;
            text-align: center;
          }
          .nameCell {
            width: 200px;
            max-width: 200px;
            padding-left: 10px;
          }
          .basicPriceCell {
            width: 110px;
            max-width: 110px;
            text-align: center;
          }
          .unitPriceCell {
            width: 90px;
            max-width: 90px;
            text-align: center;
          }
          .numberOfCell {
            width: 50px;
            max-width: 50px;
            text-align: center;
          }
          .unitCell {
            width: 50px;
            max-width: 50px;
            text-align: center;
          }
          .fromDateCell {
            height: 17px;
            width: 80px;
            max-width: 80px;
            text-align: center;
          }
          .toDateCell {
            height: 17px;
            width: 80px;
            max-width: 80px;
            text-align: center;
          }
          .periodCell {
            width: 80px;
            max-width: 80px;
            text-align: center;
          }
          .payOffCell {
            width: 60px;
            max-width: 60px;
            text-align: center;
          }
          .priceCell {
            width: 110px;
            max-width: 110px;
            text-align: center;
          }
          .summaryCell {
            width: 200px;
            max-width: 200px;
            padding-left: 10px;
          }
          .remark {
            padding-left: 10px;
          }
          .remarkValue {
            width: 100%;
            padding-left: 10px;
            border-bottom: solid 1px black;
          }
          .leftName {
            width: 200px;
            max-width: 200px;
            padding-left: 10px;
          }
          .centerName {
            width: 200px;
            max-width: 200px;
            text-align: center;
          }
          .rightName {
            width: 200px;
            max-width: 200px;
            text-align: right;
            padding-right: 10px;
          }
            `}
      </style>
      <div className="main">
        {props.summary
          .filter((i) => i.checked)
          .map((summary, index) => {
            return (
              <div key={"estimate" + index}>
                <EstimateSummaryDesign logoImage={props.logoImage} signImage={props.signImage} summary={summary} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default React.memo(EstimateDesign);
