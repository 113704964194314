import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSimpleFetch } from "Common/Utility/Api";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { Align, VerticalAlign } from "Common/Utility/Drawing";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { ColumnInfo } from "Component/Canvas";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import Period from "Component/Period";
import TextField from "Component/TextField";
import UserSchedule from "Component/Schedule/UserSchedule";
import { UserTask } from "Models/UserTask";
import { WorkTask } from "Models/WorkTask";
import React, { useEffect, useMemo, useState } from "react";
import { useResizeObserver } from "Hooks/useResize";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: Design.scheduleMinHeight,
  },
  list: {
    width: "100%",
    height: 200,
  },
}));

interface Condition {
  name?: string;

  isTask: boolean;

  from?: DateTime;

  to?: DateTime;
}

const initialCondition: Condition = {
  isTask: true,
};

interface Props {
  height: number;
}

const UserPanel = (props: Props) => {
  const [height, setHeight] = useState<number>(0);

  const [rect, resizeRef] = useResizeObserver();

  useEffect(() => {
    setHeight(props.height / 2 - rect.height - Design.margin);
  }, [rect, props.height]);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>({} as Condition);

  const [userSchedule, setUserSchedule] = useState<UserTask[]>([]);

  const inputManager = useInputManager(setCondition);

  const loadingElement = useLoadingElement(
    classes.paper,
    LoadingMode.Circular,
    useSimpleFetch("/schedule/user", setUserSchedule, false, DateUtility.InvalidToNull(search, "from", "to"), [search])
  );

  const columns: ColumnInfo<UserTask>[] = useMemo(
    () => [
      {
        width: 400,
        text: "ユーザー名",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: WorkTask) => data.name,
        dataKey: "name",
      },
    ],
    []
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Schedule);

  return (
    <>
      <Grid ref={resizeRef} item xs={12}>
        <TextField
          className={genericClasses.margin}
          label="名前"
          value={condition.name}
          onChange={inputManager.handleOnChange("name")}
        />
        <FormControlLabel
          className={genericClasses.margin}
          control={
            <Checkbox
              color="primary"
              checked={condition.isTask}
              onChange={inputManager.handleOnChangeCheck("isTask")}
            />
          }
          label="期間内にタスク有り"
        />
        <Period
          label="期間"
          fromValue={condition.from}
          toValue={condition.to}
          onChangeFrom={inputManager.handleOnChangeDate("from")}
          onChangeTo={inputManager.handleOnChangeDate("to")}
        />
        <Button
          className={genericClasses.margin}
          variant="contained"
          color="primary"
          onClick={() => setSearch({ ...condition })}
        >
          検索
        </Button>
        <Button className={genericClasses.margin} variant="outlined" onClick={() => setCondition(initialCondition)}>
          クリア
        </Button>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <UserSchedule
            className={classes.paper}
            rows={userSchedule}
            setRows={setUserSchedule}
            columns={columns}
            isValidEdit={isValidEdit}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(UserPanel);
