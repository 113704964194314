import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "Common/Utility/DateUtility";
import DatePickersUtilsProvider from "./DatePickersUtilsProvider";

const useStyles = makeStyles((theme) => ({
  datePicker: (param: { width?: number }) => {
    return {
      width: param.width ?? 150,
      margin: theme.spacing(1),
    };
  },
}));

interface Props {
  label: string;
  fromValue?: DateTime | null;
  toValue?: DateTime | null;
  onChangeFrom: (date: MaterialUiPickersDate | null, value?: string | null) => void;
  onChangeTo: (date: MaterialUiPickersDate | null, value?: string | null) => void;
  disabled?: boolean;
  width?: number;
}

const Period = (props: Props) => {
  const classes = useStyles({ width: props.width });

  return (
    <DatePickersUtilsProvider>
      <KeyboardDatePicker
        className={classes.datePicker}
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        label={`${props.label} - From`}
        value={props.fromValue ?? null}
        autoOk
        onChange={props.onChangeFrom}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={props.disabled ?? false}
      />
      <KeyboardDatePicker
        className={classes.datePicker}
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        label={`${props.label} - To`}
        value={props.toValue ?? null}
        autoOk
        onChange={props.onChangeTo}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={props.disabled ?? false}
      />
    </DatePickersUtilsProvider>
  );
};

export default React.memo(Period);
