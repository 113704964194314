import { Checkbox, FormControlLabel, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { InputManagerResult } from "Common/Utility/HandleUtility";
import { EstimateDetail } from "Models/Estimate";
import TextField from "Component/TextField";
import DigitsField from "Component/DigitsField";

const useStyles = makeStyles((theme) => ({
  count: {
    width: 80,
    margin: theme.spacing(1),
  },
  unit: {
    width: 60,
    margin: theme.spacing(1),
  },
  price: {
    width: 140,
    margin: theme.spacing(1),
  },
  cost: {
    width: 150,
    margin: theme.spacing(1),
  },
}));

interface Props {
  editable: boolean;
  detail: EstimateDetail;
  setDetail: React.Dispatch<React.SetStateAction<EstimateDetail>>;
  inputManager: InputManagerResult<EstimateDetail>;
  cost?: boolean;
  taxExemption?: boolean;
}

export const InputMoney = React.memo((props: Props) => {
  const classes = useStyles();

  const { detail, setDetail, editable, inputManager, cost, taxExemption } = { ...props };

  useEffect(() => {
    if (detail.count == null || detail.unitPrice == null) {
      return;
    }

    setDetail((value) => {
      if (detail.count == null || detail.unitPrice == null) {
        return value;
      }

      return {
        ...value,
        price: detail.count * detail.unitPrice,
      };
    });
  }, [detail.count, detail.unitPrice, setDetail]);

  return (
    <Grid item container direction="row" justify="flex-start" alignItems="flex-end">
      <DigitsField
        className={classes.count}
        label="数量"
        value={detail.count}
        onChange={inputManager.handleOnChangeNumber("count")}
        disabled={!editable}
      />
      <TextField
        className={classes.unit}
        label="単位"
        value={detail.unit}
        onChange={inputManager.handleOnChange("unit")}
        disabled={!editable}
      />
      <DigitsField
        className={classes.price}
        label="単価"
        value={detail.unitPrice}
        onChange={inputManager.handleOnChangeNumber("unitPrice")}
        disabled={!editable}
      />
      <DigitsField
        className={classes.price}
        label="金額"
        value={detail.price}
        onChange={inputManager.handleOnChangeNumber("price")}
        disabled={!editable}
      />
      {cost && (
        <DigitsField
          className={classes.cost}
          label="原価"
          value={detail.cost}
          onChange={inputManager.handleOnChangeNumber("cost")}
          disabled={!editable}
        />
      )}
      {taxExemption && (
        <FormControlLabel
          control={
            <Checkbox
              checked={detail.taxExemption}
              onChange={inputManager.handleOnChangeCheck("taxExemption")}
              color="primary"
              disabled={!editable}
            />
          }
          label="非課税"
        />
      )}
    </Grid>
  );
});
