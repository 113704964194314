import React, { useCallback, useEffect } from "react";
import Contents from "./Component/Contents";
import AlertList, { AlertProvider, useAlertAdd } from "./Common/Component/AlertList";
import { callWebApi } from "./Common/Utility/Api";
import { connect, IAction } from "Common/Utility/connect";
import { ComboItem } from "Common/Utility/GenericInterface";
import { RaLMAccount } from "Models/RaLMAccount";
import { TaxMaster } from "Models/TaxMaster";
import { ServiceByVendor } from "Models/ServiceByVendor";
import { abortMessage, useFetch } from "Hooks/useFetch";
import { InvokeResult } from "Hooks/useHubConnection";
import { BrowserRouter } from "react-router-dom";
import { HttpExceptionMessage, OnClose } from "Common/Utility/AppUtility";
import { CancelTokenSource } from "axios";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msal } from "Common/Utility/AuthUtility";
import { getEditAuthInfo, SystemAuth } from "Common/Utility/Role";
import { redirectUri } from "Common/Utility/Constants";

export const Destination = {
  RaLM: 0,
} as const;
type Destination = typeof Destination[keyof typeof Destination];

export const AppActionTypes = {
  DISCONNECTED: "DISCONNECTED",
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_RALMACCOUNT: "SET_RALMACCOUNT",
  SET_DEPOSIT_CATEGORY_MASTER: "SET_DEPOSIT_CATEGORY_MASTER",
  SET_TAX_MASTER: "SET_TAX_MASTER",
  SET_AGENCY: "SET_AGENCY",
  SET_SERVICE: "SET_SERVICE",
  SET_LICENSE_PLAN: "SET_LICENSE_PLAN",
  SET_TRANSITION: "SET_TRANSITION",
  SET_NOTIFICATION_HUB: "SET_NOTIFICATION_HUB",
  ADD_NOTIFCATION_CALLED_FUNC: "ADD_NOTIFCATION_CALLED_FUNC",
  REMOVE_NOTIFCATION_CALLED_FUNC: "REMOVE_NOTIFCATION_CALLED_FUNC",
  SET_WATCHING_ROOM_IDS: "SET_WATCHING_ROOM_IDS",
  SET_HEIGHT: "SET_HEIGHT",
  ADD_HOLD: "ADD_HOLD",
  REMOVE_HOLD: "REMOVE_HOLD",
  SAVED_PENDING_DATA: "SAVED_PENDING_DATA",
} as const;
type AppActionTypes = typeof AppActionTypes[keyof typeof AppActionTypes];

function Login() {
  const dispatch = AppProvider.useDispatch();

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<RaLMAccount>("/account", {
          cancelToken: signal.token,
        });

        if ((response.data.systemAuth & getEditAuthInfo("systemAuth", SystemAuth.login).bit) > 0) {
          dispatch({ type: AppActionTypes.SET_ACCOUNT, value: response.data });
        } else {
          alert("ログイン権限がありません。");

          dispatch({ type: AppActionTypes.DISCONNECTED, value: {} });

          msal.logoutRedirect();
        }
      },
      [dispatch]
    )
  );

  return <></>;
}

function App() {
  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const dispatch = AppProvider.useDispatch();

  const alertAdd = useAlertAdd();

  const onError = useCallback(
    async (error: any) => {
      if (error.response !== undefined && error.response.status === 401 && error.response.data != null) {
        switch (error.response.data.message) {
          case HttpExceptionMessage.NotFoundUser:
          case HttpExceptionMessage.NotFoundUserInAad:
            alert("ユーザー情報の取得に失敗しました。");
            dispatch({ type: AppActionTypes.DISCONNECTED, value: {} });
            msal.logoutRedirect();
            return;
          case HttpExceptionMessage.NotFoundTenant:
            alert("テナント情報の取得に失敗しました。");
            dispatch({ type: AppActionTypes.DISCONNECTED, value: {} });
            msal.logoutRedirect();
            return;
        }
      } else if (error.response !== undefined && error.response.status === 500 && error.response.data != null) {
        switch (error.response.data.message) {
          case HttpExceptionMessage.TheServerIsNotFullyConfigured:
            alert("サーバーが正しく稼働していません。");
            dispatch({ type: AppActionTypes.DISCONNECTED, value: {} });
            msal.logoutRedirect();
            return;
        }
      }

      if (error.message !== abortMessage) {
        alertAdd({ type: "error", message: error.message });
      }
    },
    [alertAdd, dispatch]
  );

  useEffect(() => {
    const onFulfilled = (response: any) => {
      try {
        return response;
      } catch (error) {
        onError(error);
      }
    };

    const onRejected = async (error: any) => {
      onError(error);
      throw error;
    };

    callWebApi().axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  }, [onError]);

  useEffect(() => {
    if (window.Notification.permission === "denied") {
      alertAdd({
        type: "warning",
        message: "デスクトップ通知が無効に設定されています。ブラウザの設定から通知を有効にしてください。",
      });
      return;
    }

    window.Notification.requestPermission().then((permission) => {
      if (permission === "default") {
        alertAdd({ type: "warning", message: "デスクトップ通知を有効してください。" });
      } else if (permission === "denied") {
        alertAdd({
          type: "warning",
          message: "デスクトップ通知を無効に設定しました。アプリからの通知を受け取れなくなります。",
        });
      }
    });
  }, [alertAdd]);

  return (
    <>
      <AlertList />
      {ralmAccount ? (
        <BrowserRouter>
          <Contents />
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </>
  );
}

function AppInContext() {
  const uri = redirectUri.some((i) => window.location.href === process.env.REACT_APP_REDIRECT_URL + i)
    ? window.location.href
    : process.env.REACT_APP_REDIRECT_URL;

  return (
    <AlertProvider.Provider>
      <AppProvider.Provider>
        <MsalProvider instance={msal}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ redirectUri: uri }}
          >
            <App />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </AppProvider.Provider>
    </AlertProvider.Provider>
  );
}

export default React.memo(AppInContext);

const initialState: AppType = {
  ralmAccount: null,
  category: {
    depositCategory: undefined,
  },
  taxMaster: [],
  master: {
    services: undefined,
    agencies: undefined,
    licensePlans: undefined,
  },
  transition: null,
  notificationHub: undefined,
  notificationCalledFuncs: {},
  watchingRoomIds: [],
  height: 0,
  holdDialogs: [],
  savedPendingData: undefined,
};

interface AppType {
  ralmAccount: RaLMAccount | null;

  // SE作業のみで変更される設定値
  category: {
    depositCategory: ComboItem[] | undefined;
  };

  // 消費税一覧
  taxMaster: TaxMaster[];

  master: {
    services: ServiceByVendor[] | undefined;

    agencies: ComboItem[] | undefined;

    licensePlans: ComboItem[] | undefined;
  };

  transition: any;

  notificationHub:
    | {
        start: () => Promise<void>;
        on: (methodName: string, newMethod: (...args: any[]) => void) => void;
        off: (methodName: string) => void;
        onreconnecting: (func: (error?: Error | undefined) => void) => void;
        onreconnected: (func: (connectionId?: string | undefined) => void) => void;
        invoke: (methodName: string, failed?: (error: any) => void, ...args: any[]) => void;
        send: (methodName: string, failed?: (error: any) => void, ...args: any[]) => Promise<InvokeResult>;
        notificationToDesktop: (
          roomId: string,
          senderTenantId: string,
          failed?: (error: any) => void
        ) => Promise<InvokeResult>;
      }
    | undefined;

  notificationCalledFuncs: {
    [key: string]: ((...data: any[]) => void)[];
  };

  watchingRoomIds: string[];

  height: number;

  holdDialogs: { key: string; component: React.ReactElement }[];

  savedPendingData?: {
    category: string;
    key: string;
    value: OnClose;
  };
}

function reducer(state: AppType, action: IAction): AppType {
  switch (action.type) {
    case AppActionTypes.SET_ACCOUNT:
      return {
        ...state,
        ralmAccount: action.value,
      };
    case AppActionTypes.SET_RALMACCOUNT:
      return { ...state, ralmAccount: action.value };
    case AppActionTypes.DISCONNECTED:
      return {
        ...state,
        ralmAccount: null,
      };
    case AppActionTypes.SET_DEPOSIT_CATEGORY_MASTER:
      return {
        ...state,
        category: {
          ...state.category,
          depositCategory: action.value,
        },
      };
    case AppActionTypes.SET_TAX_MASTER:
      return { ...state, taxMaster: action.value };
    case AppActionTypes.SET_AGENCY:
      return { ...state, master: { ...state.master, agencies: action.value } };
    case AppActionTypes.SET_SERVICE:
      return { ...state, master: { ...state.master, services: action.value } };
    case AppActionTypes.SET_LICENSE_PLAN:
      return { ...state, master: { ...state.master, licensePlans: action.value } };
    case AppActionTypes.SET_TRANSITION:
      return { ...state, transition: action.value };
    case AppActionTypes.SET_NOTIFICATION_HUB:
      return { ...state, notificationHub: action.value };
    case AppActionTypes.ADD_NOTIFCATION_CALLED_FUNC:
      if (!state.notificationCalledFuncs[action.value.key]) {
        state.notificationCalledFuncs[action.value.key] = [];
      }

      state.notificationCalledFuncs[action.value.key].push(action.value.func);
      return { ...state, notificationCalledFuncs: { ...state.notificationCalledFuncs } };
    case AppActionTypes.REMOVE_NOTIFCATION_CALLED_FUNC:
      if (state.notificationCalledFuncs[action.value.key]) {
        const index = state.notificationCalledFuncs[action.value.key].findIndex((func) => func === action.value.func);

        if (index === -1) {
          return state;
        }

        state.notificationCalledFuncs[action.value.key].splice(index, 1);
        return { ...state };
      }

      return state;
    case AppActionTypes.SET_WATCHING_ROOM_IDS:
      return { ...state, watchingRoomIds: [...action.value] };
    case AppActionTypes.SET_HEIGHT:
      return { ...state, height: action.value };
    case AppActionTypes.ADD_HOLD:
      return { ...state, holdDialogs: [...state.holdDialogs, action.value] };
    case AppActionTypes.REMOVE_HOLD:
      var index = state.holdDialogs.findIndex((i) => i.key === action.value);
      state.holdDialogs.splice(index, 1);
      return { ...state };
    case AppActionTypes.SAVED_PENDING_DATA:
      return { ...state, savedPendingData: action.value };
    default:
      return { ...state };
  }
}

export const AppProvider = connect<AppType>(reducer, initialState);
