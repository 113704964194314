import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { Deposit } from "Models/Deposit";
import { callWebApi } from "Common/Utility/Api";
import { ComboItem } from "Common/Utility/GenericInterface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useAlertAdd } from "Common/Component/AlertList";
import { AppProvider } from "App";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import { useInputManager } from "Common/Utility/HandleUtility";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { Design } from "Common/Utility/Constants";
import { useHoldInput } from "Hooks/useHoldInput";
import { CallOnClose, InputPending, onCloseWithSave } from "Common/Utility/AppUtility";
import { DateUtility } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
  selectCustomer: {
    width: 300,
    margin: theme.spacing(1),
    borderBottom: "solid 1px #949494",
  },
  loadSelectCustomer: {
    width: 300,
    height: Design.componentUnitHeight,
    margin: theme.spacing(1),
  },
  selectCategory: {
    width: 200,
    margin: theme.spacing(1),
  },
  amount: {
    width: 150,
    margin: theme.spacing(1),
  },
  note: {
    width: "100%",
    margin: theme.spacing(1),
  },
  salesStaff: {
    width: 300,
    margin: theme.spacing(1),
  },
  approve: {
    width: 300,
    margin: theme.spacing(1),
  },
  loadUsers: {
    width: 600 + theme.spacing(1),
    margin: theme.spacing(1),
    height: Design.componentUnitHeight,
  },
}));

interface DialogProps extends InputPending {
  open: boolean;
  deposit: Deposit;
  editAuth: boolean;
}

export const DepositEditDialog = React.memo((props: DialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const [deposit, setDeposit] = useState<Deposit>(props.deposit);

  const category = AppProvider.useGlobalState("category");

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props);

  const inputManager = useInputManager(setDeposit, edited);

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  const [users, setUsers] = useState<ComboItem[]>([]);

  const fetchCustomer = useLoadingElement(
    classes.loadSelectCustomer,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        var response = await callWebApi().get<ComboItem[]>("/companies/customer", { cancelToken: signal.token });
        setCustomers(response.data);
      }, [])
    )
  );

  const fetchUsers = useLoadingElement(
    classes.loadUsers,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        var response = await callWebApi().get<ComboItem[]>("/users/usermaster", { cancelToken: signal.token });
        setUsers(response.data);
      }, [])
    )
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { deposit: Deposit; onClose: CallOnClose }) => {
        const response = await callWebApi().put<Deposit>("/deposit", object.deposit);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "入金情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        if (response.data == null) {
          return;
        }

        object.onClose(onCloseWithSave(response.data, response.data.businessId ?? undefined));
      },
      [alertAdd]
    )
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    if (!deposit.depositDate) {
      alertAdd({ type: "info", message: "入金日は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(deposit.depositDate)) {
      alertAdd({ type: "info", message: "入金日が不正です。" });
      return;
    }

    if (!deposit.customerId) {
      alertAdd({ type: "info", message: "得意先名は必須項目です。" });
      return;
    }

    if (!deposit.amount) {
      alertAdd({ type: "info", message: "入金額は必須項目です。" });
      return;
    }

    executePut({ deposit: deposit, onClose: props.onClose });
  }, [props, alertAdd, deposit, executePut]);

  const handleOnClickNarrowDownCustomer = useNarrowDown(customers, "text");

  const handleOnClickNarrowDownUser = useNarrowDown(users, "text");

  const handleOnClickHoldInAction = useHoldInput(
    "入金",
    <DepositEditDialog open={true} deposit={deposit} onClose={props.onClose} editAuth={true} />,
    props.onClose,
    props.onHold
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "得意先名",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const columnsUser: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "ユーザー",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="md">
      <DialogTitle>入金情報</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-end">
              <DatePickersUtilsProvider>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  label="入金日"
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  autoOk={true}
                  value={deposit.depositDate}
                  onChange={inputManager.handleOnChangeDate("depositDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={!props.editAuth}
                />
              </DatePickersUtilsProvider>
              {fetchCustomer ?? (
                <LabelWithSelect
                  className={classes.selectCustomer}
                  caption="得意先名"
                  text={deposit.customerName}
                  data={customers}
                  columns={columns}
                  onClickNarrowDown={handleOnClickNarrowDownCustomer}
                  onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                    return { ...value, customerId: result.value, customerName: result.text };
                  })}
                  maxWidth="sm"
                  disabled={props.deposit.customerId != null || !props.editAuth}
                />
              )}
              <FormControl className={classes.selectCategory} disabled={!props.editAuth}>
                <InputLabel id="select-customer">入金形式</InputLabel>
                <Select
                  id="select-category"
                  onChange={inputManager.handleOnChangeSelect("category")}
                  value={deposit.category}
                >
                  {category?.depositCategory?.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.value}>
                        {value.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <DigitsField
                id="deposit-amount"
                className={classes.amount}
                label="入金額"
                value={deposit.amount}
                onChange={inputManager.handleOnChangeNumber("amount")}
                disabled={!props.editAuth}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-end">
              {fetchUsers ?? (
                <>
                  <LabelWithSelect
                    className={classes.salesStaff}
                    caption="営業担当"
                    text={deposit.salesStaffName}
                    data={users}
                    columns={columnsUser}
                    onClickNarrowDown={handleOnClickNarrowDownUser}
                    onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                      return { ...value, salesStaff: result.value, salesStaffName: result.text };
                    })}
                    maxWidth="sm"
                    underLine={true}
                    disabled={!props.editAuth}
                  />
                  <LabelWithSelect
                    className={classes.approve}
                    caption="承認者"
                    text={deposit.approveName}
                    data={users}
                    columns={columnsUser}
                    onClickNarrowDown={handleOnClickNarrowDownUser}
                    onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                      return { ...value, approve: result.value, approveName: result.text };
                    })}
                    maxWidth="sm"
                    underLine={true}
                    disabled={!props.editAuth}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="note"
              className={classes.note}
              label="備考"
              multiline
              value={deposit.note}
              onChange={inputManager.handleOnChange("note")}
              disabled={!props.editAuth}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {props.editAuth && (
          <Button
            className={genericClasses.margin}
            onClick={handleOnClickHoldInAction}
            color="primary"
            disabled={!props.open || inProcess}
          >
            保留
          </Button>
        )}
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
          {props.editAuth ? "キャンセル" : "閉じる"}
        </Button>
        {props.editAuth && (
          <Button
            className={genericClasses.margin}
            onClick={handleOnClickSaveInAction}
            color="primary"
            disabled={!props.open || inProcess}
          >
            保存
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
