import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import Condition from "Component/Condition";
import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContentHeight } from "Hooks/useResize";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useSimpleFetch } from "Common/Utility/Api";
import { createBusinessNumber } from "Common/Utility/AppUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { Arrive } from "Models/Shipment";
import { useInputManager } from "Common/Utility/HandleUtility";
import { DateUtility } from "Common/Utility/DateUtility";
import { ArriveStatusComboItem } from "Common/Utility/Constants";
import { ArriveInspection } from "./ArriveInspection";
import { Design } from "Common/Utility/Constants";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
  list: {
    width: "100%",
    height: 200,
  },
  select: {
    width: 150,
  },
}));

interface Condition {
  arriveStatus: number;

  companyName?: string;
}

const initialCondition: Condition = {
  arriveStatus: 0,
  companyName: undefined,
};

export default React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin + 60.5);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const [rows, setRows] = useState<Arrive[]>([]);

  const inputManager = useInputManager(setCondition);

  const [open, setOpen] = useState<boolean>(false);

  useSimpleFetch("/shipments/arrive/search", setRows, false, search, [search]);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Arrive);

  const columns: ColumnData[] = useMemo(() => {
    return [
      {
        width: 150,
        label: "出荷日",
        dataKey: "shipDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 200,
        label: "貸出期間",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) =>
          `${DateUtility.format(rowData.from)} - ${rowData.to ? DateUtility.format(rowData.to) : ""}`,
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "受注番号",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: Arrive) => createBusinessNumber(rowData.createdAt, rowData.sequence),
        visible: getVisible(2),
      },
      {
        width: 250,
        label: "工事名",
        dataKey: "constructionName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(3),
      },
      {
        width: 150,
        label: "出荷先企業",
        dataKey: "companyName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(4),
      },
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(5),
      },
      {
        width: 175,
        label: "資産番号",
        dataKey: "assetNumber",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(6),
      },
      {
        width: 100,
        label: "貸出",
        dataKey: "numberOf",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(7),
      },
      {
        width: 100,
        label: "返品",
        dataKey: "numberOfReturn",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(8),
      },
      {
        width: 100,
        label: "故障",
        dataKey: "numberOfFailures",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(9),
      },
    ];
  }, [getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid item container direction="row" justify="flex-start" alignItems="center">
              <TextField
                className={genericClasses.margin}
                label="企業名"
                value={condition.companyName}
                onChange={inputManager.handleOnChange("companyName")}
              />
              <FormControl className={classes.select}>
                <InputLabel>状態</InputLabel>
                <Select value={condition.arriveStatus} onChange={inputManager.handleOnChangeSelect("arriveStatus")}>
                  {ArriveStatusComboItem.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ ...initialCondition })}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          <Button className={genericClasses.margin} variant="contained" color="primary" onClick={() => setOpen(true)}>
            帰庫検品
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={rows}
              setValues={setRows}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        </Grid>
      </Grid>
      {open && <ArriveInspection onClose={() => setOpen(false)} />}
    </>
  );
});
