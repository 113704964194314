import { makeStyles } from "@material-ui/core";
import { DateUtility } from "Common/Utility/DateUtility";
import { DrawUtility, Font } from "Common/Utility/Drawing";
import { Canvas, CanvasEvent, CanvasHandler, CanvasInfo, ColumnInfo } from "Component/Canvas";
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { UserTask } from "Models/UserTask";
import { CanvasDataBase, ScheduleHandlerBase, ScheduleProps, useSortScheduleList } from "./Schedule";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  canvas: {
    backgroundColor: "white",
  },
  cursor: {
    cursor: "w-resize",
  },
}));

interface CanvasData extends CanvasDataBase<UserTask, UserScheduleProps> {
  scheduleProps: UserScheduleProps;
}

interface Callbacks {
  setData: React.Dispatch<React.SetStateAction<CanvasData>>;

  sort: (index: number) => void;
}

class HandlerBase extends ScheduleHandlerBase<CanvasData, Callbacks, UserTask, UserScheduleProps> {
  protected drawRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    maxDate: Date,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    let distanceDate = this.calcDistance(canvasInfo.mouseMovementDistance.x);

    // 行
    let date = new Date(
      canvasData.horizontalOffset.getFullYear(),
      canvasData.horizontalOffset.getMonth(),
      canvasData.horizontalOffset.getDate() + distanceDate
    );

    let vOffset = HandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    for (
      let i = vOffset;
      i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < canvasData.scheduleProps.rows.length;
      ++i
    ) {
      const value = canvasData.scheduleProps.rows[i];
      value.workTasks.forEach((value) => {
        if (value.from == null || value.to == null) {
          return;
        }

        let from = new Date(value.from);
        let to = new Date(value.to);
        if (from <= maxDate && to >= date) {
          let start = DateUtility.termDate(date, from);
          let term = DateUtility.termDate(from, to);
          let rect = {
            x: 2 + canvasData.columnsWidth + start * Design.rowHeight,
            y: 2 + Design.headerHeight + (i - vOffset) * Design.rowHeight,
            w: Design.rowHeight * (term + 1) - 4,
            h: Design.rowHeight - 4,
          };
          context.fillStyle = "rgba(134,219,193,0.5)";
          DrawUtility.fillRect(context, rect, 5);
        }
      });
    }
  }

  protected drawAsset(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const props = canvasData.scheduleProps;

    let vOffset = HandlerBase.calcVerticalOffset(
      props.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    // スケジュールのはみ出し部分の削除
    context.clearRect(0, 0, canvasData.columnsWidth, canvas.height);

    // 資産番号の出力
    context.fillStyle = "black";

    for (let i = vOffset; i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < props.rows.length; ++i) {
      var x = 0;
      for (let j = 0; j < props.columns.length; ++j) {
        const columnInfo = props.columns[j];
        if (columnInfo.toString) {
          DrawUtility.fillText(
            context,
            columnInfo.toString(props.rows[i]),
            {
              x: 8 + x,
              y: Design.headerHeight + (i - vOffset) * Design.rowHeight,
              w: columnInfo.width - 16,
              h: Design.rowHeight,
            },
            new Font("Meiryo", 12),
            columnInfo.bodyHorizonAlign,
            columnInfo.bodyVerticalAlign
          );
        }
        x += columnInfo.width;
      }
    }
  }

  protected drawHeader(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    // ヘッダーの境界線
    context.strokeStyle = "rgb(200,200,200)";
    context.lineWidth = 1;
    DrawUtility.line(context, 0, Design.headerHeight, canvas.width, Design.headerHeight);
    DrawUtility.line(context, 0, Design.rowHeight, canvasData.columnsWidth, Design.rowHeight);

    context.lineWidth = 0.5;
    var position = 0;
    for (let i = 0; i < canvasData.scheduleProps.columns.length; ++i) {
      DrawUtility.fillText(
        context,
        canvasData.scheduleProps.columns[i].text,
        {
          x: position,
          y: Design.rowHeight,
          w: canvasData.scheduleProps.columns[i].width,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 12),
        canvasData.scheduleProps.columns[i].headerHorizontalAlign,
        canvasData.scheduleProps.columns[i].headerVerticalAlign
      );

      if (i < canvasData.scheduleProps.columns.length - 1) {
        position += canvasData.scheduleProps.columns[i].width;
        DrawUtility.line(context, position, Design.rowHeight, position, canvas.height);
      }
    }
  }
}

class DefaultHandler extends HandlerBase {
  onClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    if (position.x <= event.data.columnsWidth && position.y >= Design.rowHeight && position.y <= Design.headerHeight) {
      let width: number = 0;
      const index = event.data.scheduleProps.columns.findIndex((i) => {
        if (width <= position.x && width + i.width >= position.x) {
          return true;
        }

        width += i.width;
        return false;
      });
      if (index >= 0) {
        event.callbacks.sort(index);
      }
    }

    return undefined;
  }

  onMouseDown(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    if (positionIndex.y < 0) {
      return undefined;
    }

    if (position.x > event.data.columnsWidth) {
      return new ScrollHandler();
    }

    return undefined;
  }

  onMouseWheel(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      this.calcDistance((event.mouseEvent as WheelEvent).deltaY)
    );

    if (event.data.verticalOffset === vOffset) {
      return undefined;
    }

    event.callbacks.setData((value) => {
      return { ...value, verticalOffset: vOffset };
    });

    event.mouseEvent.preventDefault();

    return undefined;
  }
}

class ScrollHandler extends HandlerBase {
  private resetDistance(canvas: HTMLCanvasElement, event: CanvasEvent<CanvasData, Callbacks>) {
    event.callbacks.setData((value) => {
      if (event.canvasInfo.mouseMovementDistance.x !== 0) {
        value.horizontalOffset = new Date(
          value.horizontalOffset.getFullYear(),
          value.horizontalOffset.getMonth(),
          value.horizontalOffset.getDate() + this.calcDistance(event.canvasInfo.mouseMovementDistance.x)
        );
      }

      if (event.canvasInfo.mouseMovementDistance.y !== 0) {
        value.verticalOffset = HandlerBase.calcVerticalOffset(
          value.scheduleProps.rows.length,
          canvas.height,
          value.verticalOffset,
          this.calcDistance(event.canvasInfo.mouseMovementDistance.y)
        );
      }

      return { ...value };
    });
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.refresh();
    return undefined;
  }
}

interface UserScheduleProps extends ScheduleProps<UserTask> {
  className: string;

  columns: ColumnInfo<UserTask>[];

  isValidEdit: boolean;
}

function UserSchedule(props: UserScheduleProps) {
  const classes = useStyles();

  const setRows = useMemo(() => props.setRows, [props.setRows]);

  const sort = useSortScheduleList<UserTask>(setRows, props.columns);

  const [data, setData] = useState<CanvasData>({
    scheduleProps: props,
    columnsWidth: props.columns.reduce((prev, current) => prev + current.width, 0),
    verticalOffset: 0,
    horizontalOffset: new Date(DateUtility.nowDate()),
    resizeCursor: false,
  } as CanvasData);

  const callbacks = useMemo(() => {
    return { setData: setData, sort: sort };
  }, [sort]);

  useEffect(() => {
    setData((value) => {
      return { ...value, scheduleProps: props };
    });
  }, [props]);

  const components = useMemo(() => [], []);

  return (
    <>
      <div>
        <Canvas<CanvasData, Callbacks>
          className={clsx(props.className, classes.canvas, data.resizeCursor && classes.cursor)}
          handler={new DefaultHandler()}
          data={data}
          callbacks={callbacks}
          components={components}
        />
      </div>
    </>
  );
}

export default React.memo(UserSchedule);
