import React, { useCallback, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, IconButton, Grid, Typography, Box } from "@material-ui/core";
import { Autorenew, GroupAdd } from "@material-ui/icons";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateUtility } from "Common/Utility/DateUtility";
import { callWebApi } from "Common/Utility/Api";
import { MenuIndex } from "Component/Menu";
import { useExecute, useFetch } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { Props } from "./BusinessDetail";
import { CancelTokenSource } from "axios";
import { useGenericStyles } from "Common/Utility/Styles";
import clsx from "clsx";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { Design, simpleColumnData, TaskStatus, toComboText, VisibleColumn } from "Common/Utility/Constants";
import { WorkTask } from "Models/WorkTask";
import EditTaskDialog from "Pages/Schedule/SchedulePanel/EditTaskDialog";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { useContentHeight } from "Hooks/useResize";
import { useColumnControl } from "Hooks/useColumnControl";
import { ComboItem } from "Common/Utility/GenericInterface";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  padding: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

export function ScheduleInBusiness(props: Props) {
  const [height] = useContentHeight(
    Design.topButtonHeight + Design.componentUnitHeight + Design.margin + 64 + Design.margin
  );

  const classes = useStyles({ height: height });

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const genericClasses = useGenericStyles();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const [data, setData] = useState([] as WorkTask[]);

  const [workTask, setWorkTask] = useState<WorkTask | undefined>(undefined);

  const [taskTemplate, setTaskTemplate] = useState<ComboItem[]>([]);

  const [open, setOpen] = useState(false);

  const handleOnClickAdd = useCallback(() => {
    setWorkTask({
      businessId: businessInfo.id,
      name: "",
      status: 0,
      taskMembers: [],
    } as WorkTask);
  }, [businessInfo]);

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<WorkTask[]>(`/schedule/business/${businessInfo.id}`, {
          cancelToken: signal.token,
        });

        if (!response.data) {
          return;
        }

        setData(response.data);
      },
      [businessInfo]
    )
  );

  const fetchResultTemplateMaster = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      const response = await callWebApi().get<ComboItem[]>("workTaskTemplates/master", { cancelToken: signal.token });

      if (!response.data) {
        return;
      }

      setTaskTemplate(response.data);
    }, [])
  );

  const handleOnClickReload = useCallback(() => {
    fetchResultLoad.reload();
    fetchResultTemplateMaster.reload();
  }, [fetchResultLoad, fetchResultTemplateMaster]);

  const handleOnClickEdit = useCallback(
    (workTask: WorkTask, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setWorkTask(workTask);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        await callWebApi().delete(`/schedule/${object.id}`);

        alertAdd({ type: "success", message: "タスクを削除しました。" });

        if (unmounted.value) {
          return;
        }

        setData((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (workTask: WorkTask, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (workTask.id !== undefined) {
        if (await message.confirm("削除確認", "タスクを削除します。よろしいですか？")) {
          executeDelete({ id: workTask.id, rowIndex: rowIndex });
        }
      }
    },
    [message, executeDelete]
  );

  const handleOnClickReference = useCallback(
    (workTask: WorkTask, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setWorkTask(workTask);
    },
    []
  );

  const handleOnClose = useOnCloseEditDialog("Task", setWorkTask, setData, "id", businessInfo.id);

  const handleOnClickNarrowDown = useNarrowDown(taskTemplate, "text");

  const executePostTemplate = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: any; businessId: string }) => {
        const response = await callWebApi().post<WorkTask[]>(`/schedule/template/${object.id}/${object.businessId}`);

        if (!response.data) {
          return;
        }

        alertAdd({ type: "success", message: "テンプレートのタスクを追加しました。" });

        if (unmounted.value) {
          return;
        }

        setData((values) => {
          return [...values, ...response.data];
        });
      },
      [alertAdd]
    )
  );

  const handleOnCloseSelectTemplate = (template: ComboItem | null) => {
    if (template) {
      executePostTemplate({ id: template.value, businessId: props.detail.id });
    }

    setOpen(false);
  };

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Schedule);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ScheduleInBusiness
  );

  const columns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [];
    if (isValidEdit) {
      columns.push(editButton, {
        ...deleteButton,
        rendererInHeader: (label, columnData, columnIndex) => {
          return (
            <Box className={genericClasses.width100percent}>
              <IconButton color="primary" aria-label="cancel" onClick={() => setOpen(true)}>
                <GroupAdd />
              </IconButton>
            </Box>
          );
        },
      });
    } else {
      columns.push(referenceButton);
    }

    columns.push(
      {
        width: 300,
        label: "タスク名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        width: 250,
        label: "期間",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any, rowData: WorkTask) =>
          `${DateUtility.format(rowData.from, "yyyy/MM/dd")} - ${DateUtility.format(rowData.to, "yyyy/MM/dd")}`,
        visible: getVisible(1),
      },
      {
        width: 80,
        label: "状態",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => toComboText(TaskStatus, data),
        visible: getVisible(2),
      },
      {
        width: 400,
        label: "メンバー",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "left",
        convert: (data: any, rowData: WorkTask) => {
          return rowData.taskMembers.map((row) => [row["text"]]).join(", ");
        },
        fit: true,
        visible: getVisible(3),
      }
    );

    return columns;
  }, [genericClasses, isValidEdit, getVisible]);

  return (
    <>
      <Paper>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography className={genericClasses.margin}>日程情報</Typography>
              <IconButton
                className={genericClasses.margin}
                color="primary"
                aria-label="udpate"
                onClick={handleOnClickReload}
              >
                <Autorenew />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.padding}>
            {useLoadingElement(classes.paper, LoadingMode.Simple, fetchResultLoad, fetchResultTemplateMaster) ?? (
              <Paper className={clsx(classes.paper)}>
                <VirtualaizedTable
                  values={data}
                  setValues={setData}
                  rowHeight={48}
                  columns={columns}
                  onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                  onClickEdit={handleOnClickEdit}
                  onClickDelete={handleOnClickDelete}
                  onClickReference={handleOnClickReference}
                  onCloseContextMenu={handleOnCloseContextMenu}
                  onChangeHeaderVisible={handleOnChangeHeaderVisible}
                  headerContext
                />
              </Paper>
            )}
          </div>
        </Grid>
      </Paper>
      {workTask != null && (
        <EditTaskDialog open={true} onClose={handleOnClose} workTask={workTask} editAuth={isValidEdit} />
      )}
      <SelectDialog
        title="テンプレート"
        columns={simpleColumnData("テンプレート名", "text")}
        data={taskTemplate}
        onClose={handleOnCloseSelectTemplate}
        open={open}
        onClickNarrowDown={handleOnClickNarrowDown}
      />
    </>
  );
}

export default React.memo(ScheduleInBusiness);
