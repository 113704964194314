import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Paper } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Design } from "Common/Utility/Constants";
import { Edit, Done, CancelOutlined } from "@material-ui/icons";
import Label from "Component/Label";
import TextField from "Component/TextField";
import { useExecuteEx } from "Hooks/useFetch";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { validateResponse } from "Common/Utility/HttpUtility";
import { Contact } from "Models/Contact";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    height: Design.componentUnitHeight,
  },
  label: {
    marginLeft: theme.spacing(1),
    width: `calc(100% - ${48 + theme.spacing(1) * 2}px)`,
  },
  text: {
    marginLeft: theme.spacing(1),
    width: `calc(100% - ${48 * 2 + theme.spacing(1) * 2}px)`,
  },
}));

interface Props {
  type: "from" | "to";

  contact: Contact;

  setContacts: React.Dispatch<React.SetStateAction<Contact[]>>;

  disabled?: boolean;
}

const RoomName = (props: Props) => {
  const classes = useStyles();

  const alertAdd = useAlertAdd();

  const [roomName, setRoomName] = useState<string | undefined>();

  useEffect(() => {
    setRoomName(props.contact.roomName);
    setEditing(false);
  }, [props.contact]);

  const [text, setText] = useState<string | undefined>();

  const [editing, setEditing] = useState<boolean>(false);

  const setContacts = useMemo(() => props.setContacts, [props.setContacts]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { text?: string; contact: Contact }) => {
        const response = await callWebApi().put<Contact>(`/contacts/roomName/${props.type}`, {
          ...object.contact,
          roomName: object.text,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "ルーム名を保存しました。" });

        if (unmounted.value) {
          return;
        }

        setContacts((value) => {
          var contact = value.find((i) => i.id === object.contact.id);
          if (contact) {
            contact.roomName = response.data.roomName;
            contact.updatedAt = response.data.updatedAt;
            return [...value];
          }
          return value;
        });

        setEditing(false);

        setRoomName(response.data.roomName);
      },
      [alertAdd, setContacts, props.type]
    )
  );

  const handleOnClickDone = () => {
    executePut({ text: text, contact: props.contact });
  };

  const handleOnChange = (event: ChangeEvent<HTMLAreaElement | any>) => {
    const value = event.target.value;
    setText(value);
  };

  return (
    <Paper className={classes.paper}>
      {editing ? (
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <IconButton color="primary" onClick={handleOnClickDone} disabled={inProcess}>
            <Done />
          </IconButton>
          <IconButton color="primary" onClick={() => setEditing(false)} disabled={inProcess}>
            <CancelOutlined />
          </IconButton>
          <TextField className={classes.text} value={text} onChange={handleOnChange} />
        </Grid>
      ) : (
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <IconButton
            color="primary"
            onClick={() => {
              setText(roomName);
              setEditing(true);
            }}
            disabled={props.disabled}
          >
            <Edit />
          </IconButton>
          <Label className={classes.label} caption="ルーム名" text={roomName} />
        </Grid>
      )}
    </Paper>
  );
};

export default React.memo(RoomName);
