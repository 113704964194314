import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CancelTokenSource } from "axios";
import { useAlertAdd } from "Common/Component/AlertList";
import VirtualaizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { CallOnClose, InputPending, onCloseWithExclusion, onCloseWithSave } from "Common/Utility/AppUtility";
import { simpleColumnData, TaskStatus } from "Common/Utility/Constants";
import { DateUtility } from "Common/Utility/DateUtility";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useInputManager } from "Common/Utility/HandleUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import Period from "Component/Period";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";
import TextField from "Component/TextField";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { useHoldInput } from "Hooks/useHoldInput";
import { useMessageBox } from "Hooks/useMessageBox";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { WorkTask } from "Models/WorkTask";
import React, { useCallback, useMemo, useState } from "react";

const useStyles = makeStyles((theme) => ({
  name: {
    width: "100%",
  },
  status: {
    width: "100%",
  },
  paper: {
    height: 250,
  },
  loading: {
    width: "100%",
    height: `calc(48px + ${theme.spacing(1)}px)`,
  },
}));

interface Props extends InputPending {
  open: boolean;
  workTask: WorkTask;
  editAuth: boolean;
}

const EditTaskDialog = (props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const [open, setOpen] = useState<boolean>(false);

  const [workTask, setWorkTask] = useState<WorkTask>({ ...props.workTask });

  const [taskMembers, setTaskMembers] = useState<ComboItem[]>([...props.workTask.taskMembers]);

  const [edited, confirm] = useWhetherEdited(props);

  const inputManager = useInputManager(setWorkTask, edited);

  const [allUsers, setAllUsers] = useState<ComboItem[]>([]);

  const fetchUsers = useLoadingElement(
    classes.paper,
    LoadingMode.Circular,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        var response = await callWebApi().get<ComboItem[]>("/users/usermaster", { cancelToken: signal.token });

        if (response.data == null) {
          return;
        }

        setAllUsers(response.data);
      }, [])
    )
  );

  const users = useMemo(
    () => allUsers.filter((i) => taskMembers.find((j) => j.value === i.value) === undefined),
    [allUsers, taskMembers]
  );

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { workTask: WorkTask; taskMembers: ComboItem[]; onClose: CallOnClose }
      ) => {
        object.workTask.taskMembers = object.taskMembers;
        const response = await callWebApi().put<WorkTask>("/schedule", object.workTask);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "タスクを保存しました。" });

        if (unmounted.value) {
          return;
        }

        if (response.data == null) {
          return;
        }

        object.onClose(onCloseWithSave(response.data, response.data.businessId));
      },
      [alertAdd]
    )
  );

  const [executeDelete, inProcessDelete] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string; onClose: CallOnClose }) => {
        await callWebApi().delete(`/schedule/${object.id}`);

        alertAdd({ type: "success", message: "タスクを削除しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithExclusion(object.id));
      },
      [alertAdd]
    )
  );

  const handleOnClickDeleteInAction = useCallback(async () => {
    if (workTask.id != null) {
      if (await message.confirm("削除確認", "タスクを削除します。よろしいですか？")) {
        executeDelete({ id: workTask.id, onClose: props.onClose });
      }
    }
  }, [executeDelete, message, props.onClose, workTask.id]);

  const handleOnClickSaveInAction = useCallback(() => {
    if (!workTask.name) {
      alertAdd({ type: "info", message: "タスク名は必須項目です。" });
      return;
    }

    if (!workTask.from || !workTask.to) {
      alertAdd({ type: "info", message: "期間は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(workTask.from) || !DateUtility.isValid(workTask.to)) {
      alertAdd({ type: "info", message: "期間が不正です。" });
      return;
    }

    if (new Date(workTask.from) > new Date(workTask.to)) {
      alertAdd({ type: "info", message: "期間が逆転しています。正しい値を設定してください。" });
      return;
    }
    executePut({ workTask: workTask, taskMembers: taskMembers, onClose: props.onClose });
  }, [props, alertAdd, workTask, taskMembers, executePut]);

  const handleOnClickNarrowDown = useNarrowDown(users, "text");

  const handleOnClose = (user: ComboItem | null) => {
    if (user) {
      setTaskMembers((value) => {
        return [...value, user];
      });

      edited();
    }

    setOpen(false);
  };

  const [candidate, setCandidate] = useState<{ value: string }[]>([]);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<{ value: string }[]>("/schedule/candidate", {
          cancelToken: signal.token,
        });

        if (!response.data) {
          return;
        }

        setCandidate(response.data);
      }, [])
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnClickDelete = useCallback(
    (user: ComboItem, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setTaskMembers((value) => {
        value.splice(rowIndex, 1);
        return [...value];
      });

      edited();
    },
    [edited]
  );

  const handleOnClickHoldInAction = useHoldInput(
    "日程",
    <EditTaskDialog open={true} onClose={props.onClose} workTask={workTask} editAuth={true} />,
    props.onClose,
    props.onHold,
    () => {
      workTask.taskMembers = [...taskMembers];
    }
  );

  const columns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [];
    if (props.editAuth) {
      columns.push({
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.DeleteButton,
      });
    }

    columns.push({
      width: 300,
      label: "名前",
      dataKey: "text",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
    });

    return columns;
  }, [props.editAuth]);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="xs">
        <DialogTitle>タスク</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              {loadingElement ?? (
                <Autocomplete
                  id="combo-box-taskname"
                  freeSolo
                  options={candidate.map((option) => option.value)}
                  onChange={inputManager.handleOnChangeAutocomplete("name")}
                  value={workTask.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.name}
                      label="タスク名"
                      value={workTask.name}
                      onChange={inputManager.handleOnChange("name")}
                      disabled={!props.editAuth}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Period
                label="期間"
                width={180}
                fromValue={workTask.from}
                toValue={workTask.to}
                onChangeFrom={inputManager.handleOnChangeDate("from")}
                onChangeTo={inputManager.handleOnChangeDate("to")}
                disabled={!props.editAuth}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.status} disabled={!props.editAuth}>
                <InputLabel id="select-customer">状態</InputLabel>
                <Select
                  id="select-category"
                  onChange={inputManager.handleOnChangeSelect("status")}
                  value={workTask.status}
                >
                  {TaskStatus.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.value}>
                        {value.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {fetchUsers ?? (
                <Paper className={classes.paper}>
                  <VirtualaizedTable
                    values={taskMembers}
                    setValues={setTaskMembers}
                    rowHeight={48}
                    columns={columns}
                    onClickAdd={props.editAuth ? handleOnClickAdd : undefined}
                    onClickDelete={handleOnClickDelete}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {props.editAuth && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
          )}
          {props.workTask.businessId == null && props.workTask.id != null && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickDeleteInAction}
              color="secondary"
              disabled={!props.open || inProcess || inProcessDelete}
            >
              削除
            </Button>
          )}
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            {props.editAuth ? "キャンセル" : "閉じる"}
          </Button>
          {props.editAuth && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess || inProcessDelete}
            >
              保存
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SelectDialog
        columns={simpleColumnData("名前", "text")}
        data={users}
        onClose={handleOnClose}
        open={open}
        title="作業者"
        onClickNarrowDown={handleOnClickNarrowDown}
      />
    </>
  );
};

export default React.memo(EditTaskDialog);
