import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Condition from "Component/Condition";
import Period from "Component/Period";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { useSimpleFetch } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { OriginalVoucherSummary } from "Models/OriginalVoucher";
import OriginalVoucherList from "./OriginalVoucherList";
import { useContentHeight } from "Hooks/useResize";
import { useGenericStyles } from "Common/Utility/Styles";
import { MenuIndex } from "Component/Menu";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { Design } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  searchLoading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
}));

interface SearchCondition {
  customerName: string | null;
  startDateFrom: DateTime | null;
  startDateTo: DateTime | null;
  endDateFrom: DateTime | null;
  endDateTo: DateTime | null;
}

const initialSearchCondition: SearchCondition = {
  customerName: null,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
};

const OriginalVoucherPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });
  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<SearchCondition>(initialSearchCondition);

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<SearchCondition>({} as SearchCondition);

  const [summaries, setSummaries] = useState<OriginalVoucherSummary[]>([]);

  const fetchResultSearch = useSimpleFetch(
    "/originalvoucher/search",
    setSummaries,
    false,
    DateUtility.InvalidToNull(search, "startDateFrom", "startDateTo", "endDateFrom", "endDateTo"),
    [search]
  );

  const isVoucherMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Voucher);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <TextField
                className={genericClasses.margin}
                label="得意先"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <Period
                label="請求開始日"
                width={170}
                fromValue={condition.startDateFrom}
                toValue={condition.startDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("startDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("startDateTo")}
              />
              <Period
                label="請求終了日"
                width={170}
                fromValue={condition.endDateFrom}
                toValue={condition.endDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("endDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("endDateTo")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialSearchCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.searchLoading, LoadingMode.Circular, fetchResultSearch) ?? (
            <OriginalVoucherList
              summaries={summaries}
              setSummaries={setSummaries}
              height={height}
              editAuth={isVoucherMenuEditValid}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(OriginalVoucherPanel);
