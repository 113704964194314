import { Button } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useGenericStyles } from "Common/Utility/Styles";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";
import { ColumnData } from "Common/Component/VirtualizedTable";

interface ButtonForSelectProps<T> {
  buttonCaption: string;

  title: string;

  data: T[];

  filters: (keyof T)[];

  columns: ColumnData[];

  onClose: (result: T) => void;

  disabled?: boolean;
}

export const ButtonForSelect = <T,>(props: ButtonForSelectProps<T>) => {
  const genericClasses = useGenericStyles();

  const [open, setOpen] = useState<boolean>(false);

  const handleOnClickNarrowDown = useNarrowDown(props.data, ...props.filters);

  const onClose = useMemo(() => props.onClose, [props.onClose]);

  const handleOnClose = useCallback(
    (result: T) => {
      if (result != null) {
        onClose(result);
      }

      setOpen(false);
    },
    [onClose]
  );

  return (
    <>
      <Button
        className={genericClasses.margin}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={props.disabled ?? false}
      >
        {props.buttonCaption}
      </Button>
      <SelectDialog
        title={props.title}
        open={open}
        columns={props.columns}
        data={props.data}
        onClose={handleOnClose}
        onClickNarrowDown={handleOnClickNarrowDown}
      />
    </>
  );
};
