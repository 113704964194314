import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from "@material-ui/core";
import VirtualizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { useCallback } from "react";
import { AddBox } from "@material-ui/icons";
import { useAlertAdd } from "Common/Component/AlertList";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { RequestCreateUser, RequestPlan } from "Models/Request";
import { ComboItem } from "Common/Utility/GenericInterface";
import { SelectDialog } from "Component/SelectDialog";
import { useInputManager } from "Common/Utility/HandleUtility";
import { AppProvider } from "App";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  inEdit: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
  },
  table: {
    height: 200,
  },
}));

interface CreateUserProps {
  open: boolean;
  createUserData: RequestCreateUser;
  disabled: boolean;
  onClose: (result: RequestCreateUser | null) => void;
}

export const CreateUserDialog = (props: CreateUserProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const licensePlans = AppProvider.useGlobalState("master").licensePlans;

  const [edited, confirm] = useWhetherEdited(props.open);

  const [createUserData, setCreateUserData] = useState({
    plans: [] as RequestPlan[],
  } as RequestCreateUser);

  const [open, setOpen] = useState(false);

  const inputManager = useInputManager(setCreateUserData, edited);

  const [editIndex, setEditIndex] = useState(-1);

  const filteredPlan = useMemo(
    () =>
      licensePlans?.filter((value) => createUserData.plans.find((plan) => plan.planId === value.value) === undefined),
    [licensePlans, createUserData]
  );

  const handleOnClickSaveInAction = () => {
    if (!createUserData.userId) {
      alertAdd({ type: "info", message: "ユーザーIDは必須項目です。" });
      return;
    }

    if (!createUserData.userName) {
      alertAdd({ type: "info", message: "ユーザー名は必須項目です。" });
      return;
    }

    props.onClose(createUserData);
  };

  const handleOnCloseSelect = (result: ComboItem | null) => {
    setOpen(false);

    if (result == null) {
      return;
    }

    setCreateUserData((value) => {
      if (editIndex === -1) {
        value.plans.push({ planId: result.value, planName: result.text });
      } else {
        value.plans[editIndex] = { planId: result.value, planName: result.text };
      }

      return { ...value, plans: [...value.plans] };
    });

    edited();
  };

  const handleOnClickEdit = useCallback((data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setOpen(true);
    setEditIndex(rowIndex);
  }, []);

  const handleOnClickDelete = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setCreateUserData((value) => {
        value.plans.splice(rowIndex, 1);
        return { ...value, plans: [...value.plans] };
      });
    },
    []
  );

  const planColumns: ColumnData[] = useMemo(() => {
    let ret = [] as ColumnData[];

    if (!props.disabled) {
      ret = [
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
          rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
            return (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={() => {
                  setOpen(true);
                  setEditIndex(-1);
                }}
              >
                <AddBox />
              </IconButton>
            );
          },
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        },
      ];
    }

    return ret.concat([
      {
        width: 150,
        label: "プラン",
        dataKey: "planName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ]);
  }, [props.disabled]);

  const planSelectColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "プラン",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (props.open) {
      setCreateUserData((value) => {
        return { ...props.createUserData, plans: [...props.createUserData.plans] };
      });
    }
  }, [props.open, props.createUserData]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>ユーザー追加情報</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.inEdit}
          label="ユーザーID"
          value={createUserData.userId ?? ""}
          onChange={inputManager.handleOnChange("userId")}
          disabled={props.disabled}
        />
        <TextField
          className={classes.inEdit}
          label="ユーザー名"
          value={createUserData.userName ?? ""}
          onChange={inputManager.handleOnChange("userName")}
          disabled={props.disabled}
        />
        <Paper className={classes.table}>
          <VirtualizedTable
            rowHeight={48}
            columns={planColumns}
            values={createUserData.plans}
            onClickEdit={handleOnClickEdit}
            onClickDelete={handleOnClickDelete}
          />
        </Paper>
        <SelectDialog
          open={open}
          title="プラン"
          columns={planSelectColumns}
          data={filteredPlan!}
          onClose={handleOnCloseSelect}
        />
      </DialogContent>
      <DialogActions>
        {props.disabled ? (
          <Button className={genericClasses.margin} onClick={() => props.onClose(null)} color="primary">
            閉じる
          </Button>
        ) : (
          <>
            <Button
              className={genericClasses.margin}
              onClick={() => confirm(() => props.onClose(null))}
              color="primary"
            >
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open}
            >
              保存
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
