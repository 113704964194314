import { AppProvider } from "App";
import { Design } from "Common/Utility/Constants";
import React, { useEffect, useState, useMemo, useCallback } from "react";

export const useResizeObserver = (): [
  DOMRect,
  (instance: HTMLElement | null) => void,
  React.Dispatch<React.SetStateAction<HTMLElement | null>>
] => {
  const [observer, setObserver] = useState<HTMLElement | null>(null);

  const [rect, setRect] = useState({ y: 0, height: 0 } as DOMRect);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setRect(observer?.getBoundingClientRect() ?? entries[0].contentRect);
    });

    observer && resizeObserver.observe(observer);

    return () => {
      resizeObserver.disconnect();
    };
  }, [observer]);

  return [rect, (instance: HTMLElement | null) => setObserver(instance), setObserver];
};

export const useContentHeight = (offset: number = 0): [number, (instance: HTMLElement | null) => void] => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const [rect, resizeRef] = useResizeObserver();

  const chipHeight = AppProvider.useGlobalState("height");

  const height = useMemo(() => {
    return (
      innerHeight -
      Design.headerHeight -
      Design.mainFramePadding * 2 -
      chipHeight -
      rect.height -
      Design.margin -
      offset
    );
  }, [innerHeight, rect, chipHeight, offset]);

  const handleOnResize = useCallback(() => {
    setInnerHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleOnResize);

    return () => {
      window.removeEventListener("resize", handleOnResize);
    };
  }, [handleOnResize]);

  return [height, resizeRef];
};
