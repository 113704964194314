import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import { Edit, Delete, Info } from "@material-ui/icons";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { useGenericStyles } from "Common/Utility/Styles";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute, useFetch } from "Hooks/useFetch";
import { NumberConfig, useIsValidMenuEditAuthority, formatEstimateNumber } from "Common/Utility/AppUtility";
import { useContentHeight } from "Hooks/useResize";
import { MenuIndex } from "Component/Menu";
import { EstimateDialog } from "./EstimateDialog";
import { EstimateSummary, isEditableEstimate } from "Models/Estimate";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import Period from "Component/Period";
import { AppActionTypes, AppProvider } from "App";
import { useInputManager } from "Common/Utility/HandleUtility";
import NumberField from "Component/NumberField";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { connect, IAction } from "Common/Utility/connect";
import { useColumnControl } from "Hooks/useColumnControl";
import { VisibleColumn } from "Common/Utility/Constants";

export enum ActionTypes {}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  version: {
    width: 50,
    margin: theme.spacing(1),
  },
  searchLoading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
}));

function initialSearchCondition(): SearchCondition {
  return {
    estimateId: null,
    estimateNo: null,
    onlyLatestVersion: true,
    version: null,
    targetUnapproved: true,
    targetApproved: true,
    customerName: null,
    constructionName: null,
    staffUserName: null,
    approvalUserName: null,
    estimateDateFrom: DateUtility.addDate(DateUtility.now(), 0, -1, 0),
    estimateDateTo: DateUtility.format(DateUtility.now(), "yyyy/MM/dd"),
    approvalDateFrom: null,
    approvalDateTo: null,
  };
}

interface SearchCondition {
  estimateId: string | null;
  estimateNo: string | null;
  onlyLatestVersion: boolean;
  version: number | null;
  targetUnapproved: boolean;
  targetApproved: boolean;
  customerName: string | null;
  constructionName: string | null;
  staffUserName: string | null;
  approvalUserName: string | null;
  estimateDateFrom: DateTime | null;
  estimateDateTo: DateTime | null;
  approvalDateFrom: DateTime | null;
  approvalDateTo: DateTime | null;
}

export const Estimate = React.memo(() => {
  const [height, conditionRef] = useContentHeight();

  const classes = useStyles({ height: height });
  const genericClasses = useGenericStyles();

  const transition = AppProvider.useGlobalState("transition");
  const appDispatch = AppProvider.useDispatch();

  const message = useMessageBox();
  const alertAdd = useAlertAdd();

  const [condition, setCondition] = useState<SearchCondition>(initialSearchCondition());

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<SearchCondition>(initialSearchCondition());

  const [estimates, setEstimates] = useState<Array<EstimateSummary>>([]);

  const [editEstimateSummary, setEditEstimateSummary] = useState<EstimateSummary | undefined>(undefined);

  useEffect(() => {
    if (transition != null) {
      const estimateId = transition;
      setSearch({
        ...initialSearchCondition(),
        estimateDateFrom: null,
        estimateDateTo: null,
        estimateId: estimateId,
      });
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: null });
    }
  }, [transition, appDispatch]);

  const handleOnChangeOnlyLatestVersion = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCondition((condition) => {
      return {
        ...condition,
        onlyLatestVersion: checked,
        version: null,
      };
    });
  };

  const fetchResultSearch = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (search.estimateId) {
          const response = await callWebApi().get<EstimateSummary[]>(`/estimates/${search.estimateId}`, {
            cancelToken: signal.token,
          });
          setEstimates(response.data);
        } else {
          const params = DateUtility.InvalidToNull(
            search,
            "estimateDateFrom",
            "estimateDateTo",
            "approvalDateFrom",
            "approvalDateTo"
          );
          const response = await callWebApi().get<EstimateSummary[]>("/estimates/search", {
            cancelToken: signal.token,
            params: {
              estimateNo: params.estimateNo,
              onlyLatestVersion: params.onlyLatestVersion,
              estimateVersion: params.version,
              targetUnapproved: params.targetUnapproved,
              targetApproved: params.targetApproved,
              customerName: params.customerName,
              constructionName: params.constructionName,
              staffUserName: params.staffUserName,
              approvalUserName: params.approvalUserName,
              estimateDateFrom: params.estimateDateFrom,
              estimateDateTo: params.estimateDateTo,
              approvalDateFrom: params.approvalDateFrom,
              approvalDateTo: params.approvalDateTo,
            },
          });
          setEstimates(response.data);
        }
      },
      [search]
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setEditEstimateSummary({
      id: null,
      issueYear: null,
      sequence: null,
      version: null,
      customerId: null,
      customerName: null,
      constructionId: null,
      constructionName: null,
      staffUserId: null,
      staffUserName: null,
      approvalUserId: null,
      approvalUserName: null,
      estimateDate: null,
      expirationDate: null,
      approvalDate: null,
    } as EstimateSummary);
  }, []);

  const handleOnClickEdit = useCallback((summary: EstimateSummary, readonly: boolean) => {
    setEditEstimateSummary({ ...summary });
  }, []);

  const handleOnClose = useOnCloseEditDialog("Estimate", setEditEstimateSummary, setEstimates, "id");

  const executeDelete = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: EstimateSummary;
          rowIndex: number;
        }
      ) => {
        await callWebApi().delete("/estimates", { params: { id: object.summary.id } });

        alertAdd({ type: "success", message: "見積を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setEstimates((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (summary: EstimateSummary, rowIndex: number) => {
      if (await message.confirm("削除確認", "見積情報を削除します。よろしいですか？")) {
        executeDelete({ summary: summary, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const onClickEditIcon = useCallback(
    (summary: EstimateSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleOnClickEdit(summary, false);
      },
    [handleOnClickEdit]
  );

  const onClickReferenceIcon = useCallback(
    (summary: EstimateSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleOnClickEdit(summary, true);
      },
    [handleOnClickEdit]
  );

  const onClickDeleteIcon = useCallback(
    (summary: EstimateSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleOnClickDelete(summary, rowIndex);
      },
    [handleOnClickDelete]
  );

  const isMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Estimate);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Estimate);

  const columns: ColumnData[] = useMemo(() => {
    let ret = [
      {
        width: 80,
        label: "",
        bodyAlign: "center",
        widthType: "fix",
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return isMenuEditValid && isEditableEstimate(data) ? (
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={onClickEditIcon(data, columnData, rowIndex, columnIndex)}
            >
              <Edit />
            </IconButton>
          ) : (
            <IconButton
              color="primary"
              aria-label="reference"
              onClick={onClickReferenceIcon(data, columnData, rowIndex, columnIndex)}
            >
              <Info />
            </IconButton>
          );
        },
      },
    ] as ColumnData[];

    if (isMenuEditValid) {
      ret = ret.concat([
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          widthType: "fix",
          rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
            return (
              <IconButton
                color="primary"
                aria-label="delete"
                disabled={!isEditableEstimate(data)}
                onClick={onClickDeleteIcon(data, columnData, rowIndex, columnIndex)}
              >
                <Delete />
              </IconButton>
            );
          },
        },
      ]);
    }

    return ret.concat([
      {
        width: 200,
        label: "見積番号",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (_: any, rowData: EstimateSummary) =>
          formatEstimateNumber(rowData.issueYear, rowData.sequence, rowData.version),
        visible: getVisible(0),
      },
      {
        width: 500,
        label: "得意先名",
        dataKey: "customerName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(1),
      },
      {
        width: 200,
        label: "工事名",
        dataKey: "constructionName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "担当者",
        dataKey: "staffUserName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(3),
      },
      {
        width: 200,
        label: "承認者",
        dataKey: "approvalUserName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "見積作成日",
        dataKey: "estimateDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd", "-"),
        visible: getVisible(5),
      },
      {
        width: 150,
        label: "承認日",
        dataKey: "approvalDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd", "-"),
        visible: getVisible(6),
      },
    ]);
  }, [isMenuEditValid, onClickEditIcon, onClickReferenceIcon, onClickDeleteIcon, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <Grid item container direction="row" justify="flex-start" alignItems="center">
              <NumberField
                className={genericClasses.margin}
                label="見積番号"
                prefix={NumberConfig.estimateNumberPrefix}
                maxLength={NumberConfig.estimateNumberLength}
                value={condition.estimateNo}
                onChange={inputManager.handleOnChange("estimateNo")}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.onlyLatestVersion}
                    onChange={handleOnChangeOnlyLatestVersion}
                    color="primary"
                  />
                }
                label="最新版のみ"
              />
              <DigitsField
                className={classes.version}
                label="版数"
                value={condition.version !== null ? condition.version : ""}
                onChange={inputManager.handleOnChangeNumber("version")}
                disabled={condition.onlyLatestVersion}
                maxLength={2}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.targetUnapproved}
                    onChange={inputManager.handleOnChangeCheck("targetUnapproved")}
                    color="primary"
                  />
                }
                label="未承認"
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.targetApproved}
                    onChange={inputManager.handleOnChangeCheck("targetApproved")}
                    color="primary"
                  />
                }
                label="承認済み"
              />
              <TextField
                className={genericClasses.margin}
                label="得意先"
                value={condition.customerName}
                onChange={inputManager.handleOnChange("customerName")}
              />
              <TextField
                className={genericClasses.margin}
                label="工事名"
                value={condition.constructionName}
                onChange={inputManager.handleOnChange("constructionName")}
              />
              <TextField
                className={genericClasses.margin}
                label="担当者"
                value={condition.staffUserName}
                onChange={inputManager.handleOnChange("staffUserName")}
              />
              <TextField
                className={genericClasses.margin}
                label="承認者"
                value={condition.approvalUserName}
                onChange={inputManager.handleOnChange("approvalUserName")}
              />
              <Period
                label="見積日"
                fromValue={condition.estimateDateFrom}
                toValue={condition.estimateDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("estimateDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("estimateDateTo")}
              />
              <Period
                label="承認日"
                fromValue={condition.approvalDateFrom}
                toValue={condition.approvalDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("approvalDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("approvalDateTo")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition, estimateId: null })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(initialSearchCondition())}
              >
                クリア
              </Button>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.searchLoading, LoadingMode.Circular, fetchResultSearch) ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                onClickAdd={isMenuEditValid ? handleOnClickAdd : undefined}
                values={estimates}
                setValues={setEstimates}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
        {editEstimateSummary != null && (
          <EstimateDialog
            open={true}
            summary={editEstimateSummary}
            onClose={handleOnClose}
            editAuth={isMenuEditValid}
          />
        )}
      </Grid>
    </>
  );
});

const initialState: EstimateType = {};

interface EstimateType {}

function reducer(state: EstimateType, action: IAction): EstimateType {
  switch (action.type) {
    default:
      return state;
  }
}

export const EstimateProvider = connect<EstimateType>(reducer, initialState);
