import React, { useMemo, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Paper,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  Box,
  Checkbox,
} from "@material-ui/core";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import VirtualaizedTable, { ColumnData, EditType, InputState } from "Common/Component/VirtualizedTable";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { OriginalVoucherDetail, OriginalVoucherSummary } from "Models/OriginalVoucher";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { EstimateDetailType, PayOff, PayOffComboItems, RentalPayOff, toComboText } from "Common/Utility/Constants";
import { LabelWithSelect, SelectDialog, useNarrowDown, validateNarrowDownKey } from "Component/SelectDialog";
import { ComboItem, swap } from "Common/Utility/GenericInterface";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useFetch, useExecuteEx } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useGenericStyles } from "Common/Utility/Styles";
import { useMessageBox } from "Hooks/useMessageBox";
import { useInputManager } from "Common/Utility/HandleUtility";
import { EstimateDetail, EstimateSummary } from "Models/Estimate";
import {
  CallOnClose,
  formatBusinessNumberFromBusinessInfo,
  formatBusinessNumberFromSummary,
  formatEstimateNumber,
  InputPending,
  onCloseWithSave,
} from "Common/Utility/AppUtility";
import { BusinessInfo } from "Models/BusinessInfo";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import MultilineTextField from "Component/MultilineTextField";

const customerColumns: ColumnData[] = [
  {
    width: 300,
    label: "得意先名",
    dataKey: "text",
    headerAlign: "center",
    bodyAlign: "left",
    fit: true,
  },
];

const businessColumns: ColumnData[] = [
  {
    width: 200,
    label: "受注日時",
    dataKey: "createdAt",
    headerAlign: "center",
    bodyAlign: "center",
  },
  {
    width: 300,
    label: "受注番号",
    dataKey: "",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (_: any, rowData: BusinessInfo) => formatBusinessNumberFromBusinessInfo(rowData),
    fit: true,
  },
];

const estimateColumns: ColumnData[] = [
  {
    width: 200,
    label: "見積番号",
    dataKey: "",
    headerAlign: "center",
    bodyAlign: "left",
    convert: (_: any, rowData: EstimateSummary) =>
      formatEstimateNumber(rowData.issueYear, rowData.sequence, rowData.version),
  },
  {
    width: 150,
    label: "見積作成日",
    dataKey: "estimateDate",
    headerAlign: "center",
    bodyAlign: "center",
    convert: (estimateDate: DateTime) => DateUtility.format(estimateDate),
    fit: true,
  },
];

const useStyles = makeStyles((theme) => ({
  popupGrid: {
    marginBottom: theme.spacing(1),
    width: "100%",
    height: 500,
  },
  selectPayOff: {
    width: 100,
    margin: theme.spacing(1),
  },
  datePicker: {
    width: 150,
    margin: theme.spacing(1),
  },
  selectCustomer: {
    width: 300,
    margin: theme.spacing(1),
  },
  selectBusiness: {
    width: 300,
    margin: theme.spacing(1),
  },
  businessNameLoading: {
    width: 300,
    height: 48,
  },
  loading: {
    height: 600,
  },
}));

const toPayOff = (rentalPayOff: RentalPayOff): PayOff => {
  switch (rentalPayOff) {
    default: // 下記以外は取り込み対象外だけど念のため
    case RentalPayOff.Daily:
      return PayOff.Daily;
    case RentalPayOff.Monthly:
      return PayOff.Monthly;
    case RentalPayOff.MonthlyDaily:
      return PayOff.MonthlyDaily;
  }
};

interface OriginalVoucherDialogProps extends InputPending {
  open: boolean;
  summary: OriginalVoucherSummary;
  details: OriginalVoucherDetail[];
  editAuth: boolean;
}

const OriginalVoucherDialog = (props: OriginalVoucherDialogProps) => {
  const classes = useStyles();
  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();
  const message = useMessageBox();

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  // customerIdに対応した案件の一覧
  const [businessList, setBusinessList] = useState<BusinessInfo[]>([]);
  const [estimates, setEstimates] = useState<EstimateSummary[]>([]);

  const [summary, setSummary] = useState<OriginalVoucherSummary>(props.summary);
  const [details, setDetails] = useState<OriginalVoucherDetail[]>([...props.details]);
  const [editingDetail, setEditingDetail] = useState<OriginalVoucherDetail>({} as OriginalVoucherDetail);
  const [estimateId, setEstimateId] = useState<string | undefined>(undefined);

  const [edited, confirm] = useWhetherEdited(props);
  const inputManagerSummary = useInputManager(setSummary, edited);
  const inputManagerEditingDetail = useInputManager(setEditingDetail);

  const [estimateFilteringCustomerId, setEstimateFilteringCustomerId] = useState<string | undefined>(undefined);

  const [editing, setEditing] = useState<InputState>(InputState.None);

  // 得意先の読み込み
  const fetchCustomers = useLoadingElement(
    classes.selectCustomer,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/companies/customer", {
          cancelToken: signal.token,
        });
        setCustomers(response.data);
      }, [])
    )
  );

  // 伝票の明細読み込み
  const loadingDetails = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.shouldInitialize || !props.summary.id) {
            return;
          }

          const response = await callWebApi().get<OriginalVoucherDetail[]>("/originalvoucher/details", {
            cancelToken: signal.token,
            params: { voucherId: props.summary.id },
          });
          setDetails(response.data);
        },
        [props.summary, props.shouldInitialize]
      ),
      props.shouldInitialize
    )
  );

  // customerIdに対応した案件の一覧の読み込み
  const loadingBusinessList = useLoadingElement(
    classes.businessNameLoading,
    LoadingMode.Simple,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          if (!props.open) {
            return;
          }

          if (summary.customerId == null || summary.customerId === "") {
            setBusinessList([]);
            return;
          }

          const response = await callWebApi().get<BusinessInfo[]>(`/business/customer/${summary.customerId}`, {
            cancelToken: signal.token,
          });

          setBusinessList(response.data);
        },
        [props.open, summary.customerId]
      )
    )
  );

  const [executePutOriginalVoucher, inProcessPutOriginalVoucher] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          summary: OriginalVoucherSummary;
          details: OriginalVoucherDetail[];
          onClose: CallOnClose;
        }
      ) => {
        const response = await callWebApi().put<OriginalVoucherSummary>("/originalvoucher", {
          summary: object.summary,
          details: object.details,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "元伝票を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data, response.data.businessId ?? undefined));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "元伝",
    <OriginalVoucherDialog open={true} onClose={props.onClose} summary={summary} details={details} editAuth={true} />,
    props.onClose,
    props.onHold
  );

  const handleOnClickSaveInAction = useCallback(() => {
    if (!summary.customerId) {
      alertAdd({ type: "info", message: "得意先名は必須項目です。" });
      return;
    }

    if (!summary.startDate) {
      alertAdd({ type: "info", message: "請求開始日は必須項目です。" });
      return;
    }

    if (!DateUtility.isValid(summary.startDate)) {
      alertAdd({ type: "info", message: "請求開始日が不正です。" });
      return;
    }

    if (summary.endDate != null && !DateUtility.isValid(summary.endDate)) {
      alertAdd({ type: "info", message: "請求終了日が不正です。" });
      return;
    }

    if (
      summary.startDate != null &&
      summary.endDate != null &&
      new Date(summary.startDate) > new Date(summary.endDate)
    ) {
      alertAdd({ type: "info", message: "請求期間が逆転しています。" });
      return;
    }

    executePutOriginalVoucher({ summary: summary, details: details, onClose: props.onClose });
  }, [summary, executePutOriginalVoucher, details, props.onClose, alertAdd]);

  // 明細の追加
  const handleOnClickAdd = useCallback(() => {
    const detail: OriginalVoucherDetail = {
      name: null,
      payOff: PayOff.Daily,
      dailyPrice: null,
      monthlyPrice: null,
      quantity: null,
      unit: null,
      numberOfDaily: 1,
      summary: null,
    };
    setDetails((details) => [...details, detail]);
    setEditingDetail(detail);
  }, []);

  // 明細の編集
  const handleOnClickEdit = useCallback(
    (detail: OriginalVoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditingDetail(detail);
    },
    []
  );

  // 明細の削除
  const handleOnClickDelete = useCallback(
    (detail: OriginalVoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((details) => {
        details.splice(rowIndex, 1);
        return [...details];
      });

      edited();
    },
    [edited]
  );

  // 明細の編集キャンセル
  const handleOnClickCancel = useCallback(
    (data: OriginalVoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (editing === InputState.Adding) {
        setDetails((details) => {
          details.pop();
          return [...details];
        });
      }
    },
    [editing]
  );

  // 明細の編集確定
  const handleOnClickSave = useCallback(
    (detail: OriginalVoucherDetail, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((details) => {
        details[rowIndex] = editingDetail;
        return [...details];
      });

      edited();
    },
    [editingDetail, edited]
  );

  // 明細を1行上に移動
  const handleOnClickUp = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex === 0) {
        return;
      }

      setDetails((details) => [...swap(details, rowIndex, rowIndex - 1)]);

      edited();
    },
    [edited]
  );

  // 明細を1行下に移動
  const handleOnClickDown = useCallback(
    (rowIndex: number) => () => {
      if (rowIndex >= details.length - 1) {
        return;
      }

      setDetails((details) => [...swap(details, rowIndex, rowIndex + 1)]);

      edited();
    },
    [details.length, edited]
  );

  const handleOnClickNarrowDownCustomer = useNarrowDown(customers, "text");

  const handleOnClickNarrowDownBusiness = useCallback(
    (text: string) => {
      const keywords = validateNarrowDownKey(text);

      if (keywords.length === 0) {
        return businessList;
      }

      return businessList.filter(
        (businessInfo) =>
          keywords.findIndex((keyword) => {
            return (
              businessInfo.createdAt.indexOf(keyword) !== -1 ||
              formatBusinessNumberFromBusinessInfo(businessInfo).indexOf(keyword) !== -1
            );
          }) !== -1
      );
    },
    [businessList]
  );

  const handleOnClickNarrowDownEstimates = useCallback(
    (text: string) => {
      const keywords = validateNarrowDownKey(text);

      if (keywords.length === 0) {
        return estimates;
      }

      return estimates.filter(
        (estimate) =>
          keywords.findIndex((keyword) => {
            return (
              DateUtility.format(estimate.estimateDate).indexOf(keyword) !== -1 ||
              formatEstimateNumber(estimate.issueYear, estimate.sequence, estimate.version).indexOf(keyword) !== -1
            );
          }) !== -1
      );
    },
    [estimates]
  );

  const handleOnPreClickBusinessNo = useCallback(async () => {
    if (!summary.customerId) {
      await message.info("受注選択", "受注を絞り込むため、得意先を指定してください。");
      return false;
    }
    return true;
  }, [message, summary.customerId]);

  const columns: ColumnData[] = useMemo(() => {
    let ret = [] as ColumnData[];
    if (props.editAuth) {
      ret = [
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.EditButton,
        },
        {
          width: 80,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          editType: EditType.DeleteButton,
        },
        {
          width: 128,
          label: "",
          headerAlign: "center",
          bodyAlign: "center",
          rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
            return (
              <>
                <IconButton
                  color="primary"
                  aria-label="up"
                  onClick={handleOnClickUp(rowIndex)}
                  disabled={editing !== InputState.None}
                >
                  <ArrowUpward />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="down"
                  onClick={handleOnClickDown(rowIndex)}
                  disabled={editing !== InputState.None}
                >
                  <ArrowDownward />
                </IconButton>
              </>
            );
          },
        },
      ];
    }

    return ret.concat([
      {
        width: 400,
        label: "名目",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail.name}
              onChange={inputManagerEditingDetail.handleOnChange("name")}
            />
          );
        },
      },
      {
        width: 150,
        label: "精算区分",
        dataKey: "payOff",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.AllowEdit,
        convert: (value: any) => toComboText(PayOffComboItems, value),
        componentWhenEditing: (rowIndex: number) => {
          return (
            <FormControl className={genericClasses.width100percent}>
              <Select
                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
                  inputManagerEditingDetail.handleOnChangeSelect("payOff")(event, child);
                  setEditingDetail((value) => {
                    if (event.target.value === PayOff.Daily) {
                      return { ...value, numberOfDaily: 1 };
                    } else {
                      return { ...value, numberOfDaily: null };
                    }
                  });
                }}
                value={editingDetail.payOff}
              >
                {PayOffComboItems.map((value, index) => (
                  <MenuItem key={index} value={value.value}>
                    {value.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      },
      {
        width: 100,
        label: "日極日数",
        dataKey: "numberOfDaily",
        headerAlign: "center",
        bodyAlign: "right",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              value={editingDetail.numberOfDaily}
              onChange={inputManagerEditingDetail.handleOnChangeNumber("numberOfDaily")}
              maxLength={2}
              disabled={editingDetail.payOff !== PayOff.Daily}
            />
          );
        },
      },
      {
        width: 100,
        label: "数量",
        dataKey: "quantity",
        headerAlign: "center",
        bodyAlign: "right",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              value={editingDetail.quantity}
              onChange={inputManagerEditingDetail.handleOnChangeNumber("quantity")}
            />
          );
        },
      },
      {
        width: 100,
        label: "単位",
        dataKey: "unit",
        headerAlign: "center",
        bodyAlign: "left",
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail.unit}
              onChange={inputManagerEditingDetail.handleOnChange("unit")}
            />
          );
        },
      },
      {
        width: 120,
        label: "日額(単価)",
        dataKey: "dailyPrice",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? data.toLocaleString() : ""),
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              className={genericClasses.width100percent}
              value={editingDetail.dailyPrice}
              onChange={inputManagerEditingDetail.handleOnChangeNumber("dailyPrice")}
              allowMinus
            />
          );
        },
      },
      {
        width: 120,
        label: "月額",
        dataKey: "monthlyPrice",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: number | null) => (data !== null ? data.toLocaleString() : ""),
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <DigitsField
              className={genericClasses.width100percent}
              value={editingDetail.monthlyPrice}
              onChange={inputManagerEditingDetail.handleOnChangeNumber("monthlyPrice")}
              allowMinus
            />
          );
        },
      },
      {
        width: 110,
        label: "非課税",
        dataKey: "taxExemption",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInCell: (data: OriginalVoucherDetail) => {
          return (
            <Box className={genericClasses.width100percent}>
              <Checkbox color="primary" checked={data.taxExemption ?? false} />
            </Box>
          );
        },
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <Box className={genericClasses.width100percent}>
              <Checkbox
                color="primary"
                checked={editingDetail!.taxExemption ?? false}
                onChange={inputManagerEditingDetail.handleOnChangeCheck("taxExemption")}
              />
            </Box>
          );
        },
      },
      {
        width: 200,
        label: "摘要",
        dataKey: "summary",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        editType: EditType.AllowEdit,
        componentWhenEditing: (rowIndex: number) => {
          return (
            <TextField
              className={genericClasses.width100percent}
              value={editingDetail.summary}
              onChange={inputManagerEditingDetail.handleOnChange("summary")}
            />
          );
        },
      },
    ]);
  }, [
    props.editAuth,
    handleOnClickUp,
    handleOnClickDown,
    inputManagerEditingDetail,
    editingDetail,
    genericClasses.width100percent,
    editing,
  ]);

  const handleOnClickCreateVoucherDetailsFromEstimate = async () => {
    if (summary.customerId) {
      setEstimateFilteringCustomerId(summary.customerId);
    } else {
      await message.info("見積選択", "見積を絞り込むため、得意先を指定してください。");
    }
  };

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (estimateId == null) {
          return;
        }

        const response = await callWebApi().get<EstimateDetail[]>("/estimates/details", {
          params: { estimateId: estimateId },
        });

        alertAdd({ type: "success", message: "見積の明細を取り込みました。" });

        const estimateDetails = response.data;
        // 毎月支払いが発生する明細のみを対象とする
        const targetEstimateDetails = estimateDetails.filter(
          (estimateDetail) =>
            estimateDetail.type === EstimateDetailType.Rental && estimateDetail.rentalPayOff !== RentalPayOff.Fixed
        );
        const appendVoucherDetails: OriginalVoucherDetail[] = targetEstimateDetails.map((estimateDetail) => {
          // 本伝票への見積明細取り込みと異なり、毎月支払いが発生する明細のみを取り込むため、基本料を別明細にして取り込む必要はない
          const payOff = toPayOff(estimateDetail.rentalPayOff!);
          return {
            name: estimateDetail.name,
            payOff: payOff,
            dailyPrice: estimateDetail.dailyUnitPrice,
            monthlyPrice: estimateDetail.monthlyUnitPrice,
            quantity: estimateDetail.count,
            unit: estimateDetail.unit,
            numberOfDaily: payOff === PayOff.Daily ? estimateDetail.numberOfDaily : null,
            summary: estimateDetail.summary,
            taxExemption: estimateDetail.taxExemption,
          };
        });
        setDetails((details) => [...details, ...appendVoucherDetails]);
        edited();
        setEstimateId(undefined);
      },
      [estimateId, alertAdd, edited]
    ),
    false
  );

  const handleOnCloseEstimate = useCallback((estimate: EstimateSummary | null) => {
    setEstimateFilteringCustomerId(undefined);
    if (estimate === null || !estimate.id) {
      return;
    }
    setEstimateId(estimate.id);
  }, []);

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (estimateFilteringCustomerId === null) {
          return;
        }

        const response = await callWebApi().get("/estimates/customer", {
          cancelToken: signal.token,
          params: {
            customerId: estimateFilteringCustomerId,
          },
        });

        if (response.data == null) {
          return;
        }

        setEstimates(response.data);
      },
      [estimateFilteringCustomerId]
    ),
    false
  );

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="xl">
        <DialogTitle>元伝票情報</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-end">
              <Grid item>
                {fetchCustomers ?? (
                  <LabelWithSelect
                    className={classes.selectCustomer}
                    caption="得意先名"
                    text={summary.customerName}
                    data={customers}
                    columns={customerColumns}
                    onClickNarrowDown={handleOnClickNarrowDownCustomer}
                    onSelected={inputManagerSummary.handleOnChangeLabelWithSelect((value, result) => {
                      return {
                        ...value,
                        customerId: result.value,
                        customerName: result.text,
                        businessId: null,
                        businessSequence: null,
                        businessCreatedAt: null,
                      };
                    })}
                    maxWidth="sm"
                    disabled={props.summary.customerId !== null || !props.editAuth}
                    underLine={true}
                  />
                )}
              </Grid>
              <Grid item>
                {loadingBusinessList ?? (
                  <LabelWithSelect
                    className={classes.selectBusiness}
                    caption="受注番号"
                    text={formatBusinessNumberFromSummary(summary)}
                    data={businessList}
                    columns={businessColumns}
                    onClickNarrowDown={handleOnClickNarrowDownBusiness}
                    onPreClick={handleOnPreClickBusinessNo}
                    onSelected={inputManagerSummary.handleOnChangeLabelWithSelect((value, result: BusinessInfo) => {
                      return {
                        ...value,
                        businessId: result.id,
                        businessSequence: result.sequence,
                        businessCreatedAt: result.createdAt,
                      };
                    })}
                    maxWidth="sm"
                    disabled={!props.editAuth}
                    underLine={true}
                  />
                )}
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                  <DatePickersUtilsProvider>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      label="請求開始日"
                      autoOk
                      value={summary.startDate}
                      onChange={inputManagerSummary.handleOnChangeDate("startDate")}
                      disabled={!props.editAuth}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      label="請求終了日"
                      autoOk
                      value={summary.endDate}
                      onChange={inputManagerSummary.handleOnChangeDate("endDate")}
                      disabled={!props.editAuth}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </DatePickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickCreateVoucherDetailsFromEstimate}
                color="primary"
                disabled={!props.editAuth || editing !== InputState.None}
              >
                見積の明細を取り込む
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.popupGrid}>
                {loadingDetails ?? (
                  <VirtualaizedTable
                    values={details}
                    rowHeight={48}
                    columns={columns}
                    onClickAddDirectInput={props.editAuth ? handleOnClickAdd : undefined}
                    onClickEdit={handleOnClickEdit}
                    onClickDelete={handleOnClickDelete}
                    onClickCancel={handleOnClickCancel}
                    onClickSave={handleOnClickSave}
                    setEditing={setEditing}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <MultilineTextField
                id="note"
                className={genericClasses.width100percent}
                label="備考"
                variant="outlined"
                maxLine={2}
                value={summary.note}
                onChange={inputManagerSummary.handleOnChange("note")}
                disabled={!props.editAuth}
              />
            </Grid>
          </Grid>
          <SelectDialog
            open={estimateFilteringCustomerId != null}
            maxWidth={"xs"}
            title={"見積"}
            onClose={handleOnCloseEstimate}
            data={estimates}
            columns={estimateColumns}
            onClickNarrowDown={handleOnClickNarrowDownEstimates}
          />
        </DialogContent>
        <DialogActions>
          {!props.editAuth ? (
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              閉じる
            </Button>
          ) : (
            <>
              <Button
                className={genericClasses.margin}
                onClick={handleOnClickHoldInAction}
                color="primary"
                disabled={!props.open || editing !== InputState.None || inProcessPutOriginalVoucher}
              >
                保留
              </Button>
              <Button
                className={genericClasses.marginRight}
                onClick={() => confirm(() => props.onClose())}
                color="primary"
              >
                キャンセル
              </Button>
              <Button
                className={genericClasses.marginRight}
                onClick={handleOnClickSaveInAction}
                color="primary"
                disabled={!props.open || editing !== InputState.None || inProcessPutOriginalVoucher}
              >
                保存
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(OriginalVoucherDialog);
