import React, { useMemo, useState } from "react";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import { useContentHeight } from "Hooks/useResize";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useSimpleFetch } from "Common/Utility/Api";
import { useColumnControl } from "Hooks/useColumnControl";
import { makeStyles } from "@material-ui/core/styles";
import { AssetsStatisticsModel } from "Models/AssetsStatistics";
import TextField from "Component/TextField";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  text: {
    width: 100,
  },
}));

interface Condition {
  assetNumber?: string;
  model?: string;
  name?: string;
}

const initialCondition: Condition = {};

export const AssetsStatistics = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<AssetsStatisticsModel[]>([]);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useSimpleFetch("/statistics/assets", setData, false, search, [search])
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.AssetStatistics
  );

  const columns: ColumnData[] = useMemo(
    () => [
      {
        dataKey: "assetNumber",
        width: 175,
        label: "資産番号",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        dataKey: "model",
        width: 200,
        label: "型式",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        dataKey: "name",
        width: 200,
        label: "名称",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
        fit: true,
      },
      {
        dataKey: "numberOfShipments",
        width: 120,
        label: "出荷回数",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(3),
      },
      {
        dataKey: "lendingPeriod",
        width: 120,
        label: "貸出期間",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(4),
      },
      {
        dataKey: "numberOfRepairs",
        width: 120,
        label: "修理回数",
        headerAlign: "center",
        bodyAlign: "right",
        visible: getVisible(5),
      },
    ],
    [getVisible]
  );

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Condition observer={conditionRef}>
          <>
            <TextField
              className={genericClasses.margin}
              label="資産番号"
              value={condition.assetNumber}
              onChange={inputManager.handleOnChange("assetNumber")}
            />
            <TextField
              className={genericClasses.margin}
              label="型式"
              value={condition.model}
              onChange={inputManager.handleOnChange("model")}
            />
            <TextField
              className={genericClasses.margin}
              label="名称"
              value={condition.name}
              onChange={inputManager.handleOnChange("name")}
            />
            <Button
              className={genericClasses.margin}
              variant="contained"
              color="primary"
              onClick={() => setSearch({ ...condition })}
            >
              検索
            </Button>
            <Button className={genericClasses.margin} variant="outlined" onClick={() => setCondition(initialCondition)}>
              クリア
            </Button>
          </>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={data}
              setValues={setData}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
