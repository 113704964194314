import { AssetMaster } from "Models/Asset";

export type DateTime = string;

type MonthsAndDays = { months: number; days: number };

export class Period {
  from?: Date;

  to?: Date;
}

export class DateEx extends Date {
  public newInstance(addYear: number = 0, addMonth: number = 0, addDate: number = 0) {
    return new Date(this.getFullYear() + addYear, this.getMonth() + addMonth, this.getDate() + addDate);
  }
}

export class StringEx extends String {
  public toDate(): Date {
    return new Date(this.toString());
  }
}

export class DateUtility {
  public static minDate: DateTime = "1753/01/01";

  public static maxDate: DateTime = "9999/12/31";

  public static isValid(value?: DateTime | null) {
    return value != null && value !== "Invalid Date";
  }

  public static InvalidToNull<T>(value: T, ...key: (keyof T)[]) {
    key.forEach((i) => {
      value[i] = (this.isValid(value[i] as unknown as DateTime | null) ? value[i] : null) as T[keyof T];
    });

    return value;
  }

  public static hhmm(value: string): string {
    return value.slice(0, -3);
  }

  public static format(value?: DateTime | null, format: string = "yyyy/MM/dd", error: string = "") {
    try {
      if (value == null || value === "Invalid Date") {
        return error;
      }

      const date = new Date(value);
      if (this.isInvalidDate(date)) {
        return error;
      }

      return format
        .replace("yyyy", date.getFullYear().toString())
        .replace("MM", DateUtility.padding(date.getMonth() + 1))
        .replace("M", (date.getMonth() + 1).toString())
        .replace("dd", DateUtility.padding(date.getDate()))
        .replace("d", date.getDate().toString())
        .replace("HH", DateUtility.padding(date.getHours()))
        .replace("mm", DateUtility.padding(date.getMinutes()))
        .replace("ss", DateUtility.padding(date.getSeconds()));
    } catch {
      return error;
    }
  }

  private static padding(num: number) {
    return ("00" + num).slice(-2);
  }

  public static firstDate(date: DateTime) {
    let inst = new Date(date);
    inst.setDate(1);
    return inst.toLocaleDateString();
  }

  public static endDate(date: DateTime) {
    let inst = new Date(date);
    inst.setDate(1);
    inst.setMonth(inst.getMonth() + 1);
    inst.setDate(0);
    return inst.toLocaleDateString();
  }

  public static ignoreTime(value: DateTime) {
    return new Date(value).toLocaleDateString();
  }

  public static now(): DateTime {
    return new Date().toLocaleString();
  }

  public static nowDate(): DateTime {
    return new Date().toLocaleDateString();
  }

  public static equals(format: string, date1?: Date | DateTime | null, date2?: Date | DateTime | null): boolean {
    if (date1 == null && date2 == null) {
      return true;
    } else if (date1 == null && date2 != null) {
      return false;
    } else if (date1 != null && date2 == null) {
      return false;
    }

    var dateTime1 = typeof date1 === "string" ? date1 : date1!.toLocaleDateString();
    var dateTime2 = typeof date2 === "string" ? date2 : date2!.toLocaleDateString();
    return DateUtility.format(dateTime1, format) === DateUtility.format(dateTime2, format);
  }

  public static addDate(dateTime: DateTime, year: number, month: number, date: number) {
    let calcdate = new Date(dateTime);
    if (year !== 0) {
      calcdate.setFullYear(calcdate.getFullYear() + year);
    }
    if (month !== 0) {
      calcdate.setMonth(calcdate.getMonth() + month);
    }
    if (date !== 0) {
      calcdate.setDate(calcdate.getDate() + date);
    }
    return calcdate.toLocaleDateString();
  }

  public static overlap(period1: Period, period2: Period, boundary: boolean = true): boolean {
    if (period1.from) {
      if (period1.to) {
        if (period2.from && period2.to) {
          if (boundary) {
            return period1.from <= period2.to && period2.from <= period1.to;
          } else {
            return period1.from < period2.to && period2.from < period1.to;
          }
        } else {
          return this.overlap(period2, period1, boundary);
        }
      } else {
        if (period2.to) {
          return boundary ? period1.from <= period2.to : period1.from < period2.to;
        } else {
          return true;
        }
      }
    } else {
      if (period1.to) {
        if (period2.from) {
          return boundary ? period1.to >= period2.from : period1.to > period2.from;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  public static inRange(
    target: Date | DateTime,
    from: Date | DateTime,
    to: Date | DateTime,
    boundary: boolean = true
  ): boolean {
    const targetDate: Date = typeof target === "string" ? new Date(target) : target;
    const fromDate: Date = typeof from === "string" ? new Date(from) : from;
    const toDate: Date = typeof to === "string" ? new Date(to) : to;

    if (boundary) {
      return targetDate.getTime() >= fromDate.getTime() && targetDate.getTime() <= toDate.getTime();
    } else {
      return targetDate.getTime() > fromDate.getTime() && targetDate.getTime() < toDate.getTime();
    }
  }

  public static termDate(from: Date, to: Date): number {
    return (to.getTime() - from.getTime()) / 86400000;
  }

  public static termMonths(from: Date, to: Date): number {
    const fromMonth = from.getFullYear() * 12 + from.getMonth();
    const toMonth = to.getFullYear() * 12 + to.getMonth();
    return toMonth - fromMonth;
  }

  public static isInvalidDate(date: Date): boolean {
    return Number.isNaN(date.getDate());
  }

  public static dayJpStrings = ["日", "月", "火", "水", "木", "金", "土"];

  public static calcRentalDays = (rentalFrom: DateTime | null, rentalTo: DateTime | null): number | null => {
    if (rentalFrom && rentalTo) {
      const from = new Date(rentalFrom);
      const to = new Date(rentalTo);
      if (from > to) {
        return null;
      }
      return DateUtility.termDate(from, to) + 1;
    }
    return null;
  };

  public static calcRentalMonths = (rentalFrom: DateTime | null, rentalTo: DateTime | null): number | null => {
    if (rentalFrom && rentalTo) {
      const from = new Date(rentalFrom);
      const to = new Date(rentalTo);
      if (from > to) {
        return null;
      }
      return DateUtility.termMonths(from, to) + 1;
    }
    return null;
  };

  public static calcRentalMonthsAndDays = (
    rentalFrom: DateTime | null,
    rentalTo: DateTime | null,
    closingDay: number | null
  ): MonthsAndDays | null => {
    if (rentalFrom == null || rentalTo == null || closingDay == null) {
      return null;
    }

    const from = new Date(rentalFrom);
    rentalFrom = DateUtility.format(from.toDateString(), "yyyy/MM/dd");
    const to = new Date(rentalTo);
    rentalTo = DateUtility.format(to.toDateString(), "yyyy/MM/dd");

    if (from > to) {
      return null;
    }

    // 日数の計算

    // 開始日から考えた請求締日を求める
    const fromClosing = DateUtility.closingDate(closingDay, rentalFrom);

    // 終了日から考えた請求締日を求める
    let toClosing = DateUtility.closingDate(closingDay, rentalTo);

    const fromClosingStartDate = DateUtility.closingStartDate(closingDay, rentalFrom);

    // 開始日と終了日の請求締日が同じ場合は端数日数のみとなる
    if (fromClosing === toClosing) {
      if (fromClosingStartDate.getTime() === from.getTime() && new Date(toClosing).getTime() === to.getTime()) {
        return { days: 0, months: 1 };
      } else {
        return { days: DateUtility.termDate(from, new Date(DateUtility.addDate(rentalTo, 0, 0, 1))), months: 0 };
      }
    }

    // 開始日と終了日の請求締日が違う場合は開始月と終了月で端数の日数を求めた合算値となる
    // 開始月の端数
    const fromDays =
      from.getTime() === fromClosingStartDate.getTime()
        ? 0
        : DateUtility.termDate(from, new Date(DateUtility.addDate(fromClosing, 0, 0, 1)));

    // 終了月の端数
    if (toClosing !== rentalTo) {
      // 終了日から考えた請求締日を1ヵ月戻す
      if (closingDay === 0) {
        // 末日の場合は日付がずれるので末日にする
        toClosing = DateUtility.endDate(DateUtility.addDate(DateUtility.firstDate(toClosing), 0, -1, 0));
      } else {
        toClosing = DateUtility.addDate(toClosing, 0, -1, 0);
      }
    }

    // 終了月の端数
    const toDays = DateUtility.termDate(
      new Date(DateUtility.addDate(toClosing, 0, 0, 1)),
      new Date(DateUtility.addDate(rentalTo, 0, 0, 1))
    );

    // 月数の計算
    const fromClosingDate = new Date(fromClosing);
    const toClosingDate = new Date(DateUtility.closingDate(closingDay, rentalTo));
    let year = toClosingDate.getFullYear() - fromClosingDate.getFullYear();
    let month = toClosingDate.getMonth() - fromClosingDate.getMonth();
    if (month < 0) {
      year -= 1;
      month = 12 + month;
    }
    month -= 1;

    if (DateUtility.closingStartDate(closingDay, rentalFrom).getTime() === from.getTime()) {
      ++month;
    }

    if (toClosingDate.getTime() === to.getTime()) {
      ++month;
    }

    return { days: fromDays + toDays, months: year * 12 + month };
  };

  // 締日を計算
  public static closingDate(closingDay: number, targetDate: DateTime) {
    if (closingDay > 28) {
      closingDay = 0;
    }

    let date = new Date(targetDate);
    date = new Date(date.getFullYear(), date.getMonth(), closingDay);
    if (closingDay === 0) {
      return this.format(this.endDate(targetDate));
    } else if (closingDay < new Date(targetDate).getDate()) {
      return this.format(this.addDate(date.toDateString(), 0, 1, 0));
    } else {
      return this.format(date.toDateString());
    }
  }

  // 締日に対応する開始日
  public static closingStartDate(closingDay: number, targetDate: DateTime) {
    let prev = new Date(
      DateUtility.addDate(DateUtility.firstDate(DateUtility.closingDate(closingDay, targetDate)), 0, -1, 0)
    );
    if (closingDay === 0) {
      return new Date(DateUtility.addDate(DateUtility.endDate(prev.toLocaleDateString()), 0, 0, 1));
    } else {
      prev.setDate(closingDay);
      return new Date(DateUtility.addDate(prev.toLocaleDateString(), 0, 0, 1));
    }
  }

  // 入金予定日を計算
  public static depositDate(depositDay: number, depositCycle: number, closingDate: DateTime) {
    if (depositDay > 28) {
      depositDay = 0;
    }

    if (depositDay === 0) {
      return this.format(this.endDate(this.addDate(this.firstDate(closingDate), 0, depositCycle, 0)));
    } else {
      let date = new Date(closingDate);
      return this.format(
        this.addDate(new Date(date.getFullYear(), date.getMonth(), depositDay).toString(), 0, depositCycle, 0)
      );
    }
  }
}

export class TimeSpan {
  value: number = 0;

  maxValue: number = 864000000; // 10万日 = 144,000,000分

  constructor(value: number) {
    this.value = value;
  }

  toTimes(): { days: number; hours: number; minutes: number } {
    const days = Math.floor(this.value / 60 / 24);
    const hours = Math.floor((this.value - days * 60 * 24) / 60);
    const minutes = this.value - days * 60 * 24 - hours * 60;
    return { days, hours, minutes };
  }

  toString(): string {
    const { days, hours, minutes } = this.toTimes();
    if (days > 0) {
      return `${days}日${hours}時間${minutes}分`;
    } else if (hours > 0) {
      return `${hours}時間${minutes}分`;
    } else {
      return `${minutes}分`;
    }
  }

  set(days: string, hours: string, minute: string) {
    this.value = Number.parseInt(days) * 24 * 60 + Number.parseInt(hours) * 60 + Number.parseInt(minute);
    const times = this.toTimes();
    if (times.days > 999) {
      this.value = 999 * 24 * 60 + times.hours * 60 + times.minutes;
    }
  }

  validate(days: string, hours: string, minute: string): boolean {
    try {
      return Number.parseInt(days) * 24 * 60 + Number.parseInt(hours) * 60 + Number.parseInt(minute) <= this.maxValue;
    } catch {
      return false;
    }
  }
}

// 帰庫データから描画用のデータ作成し更新する
// 貸出期間終了日を上書きします。
export const modifyLendingPeriod = <T extends AssetMaster>(assets: T[], ignoreShipmentId: string | null = "") => {
  for (const asset of assets) {
    if (asset.assetNumber == null) {
      continue;
    }

    asset.lendingPeriods = asset.lendingPeriods.filter((i) => {
      if (i.shipmentId === ignoreShipmentId || i.returnAssets.length === 0) {
        return true;
      }
      return new Date(i.from).getTime() !== new Date(i.returnAssets[0].returnAt).getTime();
    });

    for (const lendingPeriod of asset.lendingPeriods) {
      if (
        lendingPeriod.shipmentId === ignoreShipmentId ||
        new Date(DateUtility.nowDate()) <= new Date(lendingPeriod.from) ||
        lendingPeriod.returnAssets.length === 0
      ) {
        continue;
      }

      const to = lendingPeriod.to == null ? null : new Date(lendingPeriod.to);

      const newTo = new DateEx(lendingPeriod.returnAssets[0].returnAt).newInstance(0, 0, -1);
      lendingPeriod.returnAssets[0].returnAt = newTo.toLocaleDateString();

      if (to == null || to > newTo) {
        lendingPeriod.to = newTo.toLocaleDateString();
      }
    }
  }
};
