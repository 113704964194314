import { useEffect, useCallback, useMemo, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";

const createConnection = (url: string) => {
  return new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_WEBAPI_URL! + url, {
      accessTokenFactory: async () => {
        return await callWebApi().getAccessToken();
      },
    })
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();
};

export interface InvokeResult {
  success: boolean;

  result: any;
}

export const useHubConnection = (url: string, onStarted: (hubConnection: signalR.HubConnection) => Promise<void>) => {
  const [, setMethods] = useState<string[]>([]);

  const alertAdd = useAlertAdd();

  const connection = useRef<signalR.HubConnection | null>(null);

  const start = useCallback(async () => {
    if (connection.current && connection.current.state === signalR.HubConnectionState.Disconnected) {
      for (let i = 0; i < 5; ++i) {
        try {
          await connection.current.start();

          await onStarted(connection.current);

          return;
        } catch (error) {
          if (i === 4) {
            alertAdd({
              type: "warning",
              message: "一部サーバーとの接続に失敗しました。再接続を行うまで一部機能が制限されます。",
            });
          }
        }
      }
    }
  }, [connection, alertAdd, onStarted]);

  useEffect(() => {
    connection.current = createConnection(url);

    start();

    return () => {
      if (connection.current) {
        if (connection.current.state === signalR.HubConnectionState.Connected) {
          connection.current.stop();
          connection.current = null;
        }
      }
    };
  }, [url, start]);

  const on = useCallback((methodName: string, newMethod: (...args: any[]) => void, overwrite: boolean = true) => {
    setMethods((value) => {
      const index = value.findIndex((i) => i === methodName);
      if (index >= 0) {
        if (overwrite) {
          connection.current?.off(methodName);
        }
        connection.current?.on(methodName, newMethod);
        return value;
      } else {
        connection.current?.on(methodName, newMethod);
        return [...value, methodName];
      }
    });
  }, []);

  const off = useCallback((methodName?: string) => {
    if (methodName === undefined) {
      setMethods((value) => {
        value.forEach((i) => connection.current?.off(i));
        return [];
      });
    } else {
      connection.current?.off(methodName);
      setMethods((value) => {
        const index = value.findIndex((i) => i === methodName);
        if (index >= 0) {
          value.splice(index, 1);
          return [...value];
        } else {
          return value;
        }
      });
    }
  }, []);

  const onreconnecting = useCallback((func: (error?: Error | undefined) => void) => {
    connection.current?.onreconnecting(func);
  }, []);

  const onreconnected = useCallback((func: (connectionId?: string | undefined) => void) => {
    connection.current?.onreconnected(func);
  }, []);

  const invoke = useCallback(
    async (methodName: string, failed?: (error: any) => void, ...args: any[]): Promise<InvokeResult> => {
      try {
        if (connection.current === null) {
          return { success: true, result: null };
        }

        if (connection.current?.state === signalR.HubConnectionState.Disconnected) {
          await start();
        }

        return { success: true, result: await connection.current?.invoke(methodName, ...args) };
      } catch (error) {
        failed?.(error);
        return { success: false, result: error };
      }
    },
    [start]
  );

  const send = useCallback(
    async (methodName: string, failed?: (error: any) => void, ...args: any[]): Promise<InvokeResult> => {
      try {
        if (connection.current === null) {
          return { success: true, result: null };
        }

        if (connection.current?.state === signalR.HubConnectionState.Disconnected) {
          await start();
        }

        return { success: true, result: await connection.current?.send(methodName, ...args) };
      } catch (error) {
        failed?.(error);
        return { success: false, result: error };
      }
    },
    [start]
  );

  return useMemo(() => {
    return {
      start,
      on,
      off,
      onreconnecting,
      onreconnected,
      invoke,
      send,
    };
  }, [start, on, off, onreconnecting, onreconnected, invoke, send]);
};
