import { DateUtility } from "Common/Utility/DateUtility";
import React, { useMemo } from "react";
import { DeliverySlipSummary, DeliverySlipDetail } from "./DeliverySlip";
import clsx from "clsx";
import { replaceCRLF } from "Common/Utility/GenericInterface";
import { StringUtility } from "Common/Utility/StringUtility";

interface DeliverySlipDetailProps {
  summary: DeliverySlipSummary;
}

const DeliverySlipDetailDesign = (props: DeliverySlipDetailProps) => {
  const data = useMemo(() => {
    var details: DeliverySlipDetail[][] = [];
    var detail: DeliverySlipDetail[] = [];

    props.summary.details.forEach((value, index) => {
      if (detail.length < (details.length === 0 ? 14 : 30)) {
        detail.push(value);
      } else {
        details.push(detail);
        detail = [];
        detail.push(value);
      }

      if (index >= props.summary.details.length - 1) {
        details.push(detail);
      }
    });

    return details;
  }, [props.summary.details]);

  return (
    <>
      {data.map((details, detailsIndex) => {
        return (
          <div
            key={detailsIndex.toString()}
            className={clsx(
              "detail",
              detailsIndex !== data.length - 1 || details.length > (data.length === 1 ? 10 : 26) ? "break-page" : ""
            )}
          >
            <table>
              <thead>
                <tr>
                  <th className="leftCell">名　　　称</th>
                  <th>数量</th>
                  <th>単位</th>
                  <th>金　　額</th>
                  <th className="rightCell">摘　　要</th>
                </tr>
              </thead>
              <tbody>
                {details.map((detail, detailIndex) => {
                  return (
                    <tr key={detailsIndex.toString() + detailIndex.toString()}>
                      <td className="leftCell nameCell">{detail.name}</td>
                      <td className="numberOfCell">{detail.numberOf}</td>
                      <td className="unitCell">{detail.unit}</td>
                      <td className="amountCell">
                        {detail.amount == null ? <></> : <>&yen; {detail.amount.toLocaleString()}</>}
                      </td>
                      <td className="rightCell descriptionCell">
                        {detail.taxExemption ? "非課税 " : ""}
                        {detail.summary}
                      </td>
                    </tr>
                  );
                })}
                {detailsIndex === data.length - 1 && (
                  <>
                    <tr>
                      <td className="leftCell nameCell">消費税</td>
                      <td></td>
                      <td></td>
                      <td className="amountCell">&yen; {props.summary.tax.toLocaleString()}</td>
                      <td className="rightCell"></td>
                    </tr>
                    <tr>
                      <th className="leftCell nameCell">合　　計</th>
                      <td></td>
                      <td></td>
                      <td className="amountCell">
                        &yen; {(props.summary.billingAmount + props.summary.tax).toLocaleString()}
                      </td>
                      <td className="rightCell"></td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};

interface DeliverySlipSummaryDesignProps {
  signImage: string;

  summary: DeliverySlipSummary;
}

const DeliverySlipSummaryDesign = React.memo((props: DeliverySlipSummaryDesignProps) => {
  let companyName: JSX.Element = <>{props.summary.companyName}</>;
  const isNull1 = StringUtility.isNullOrUndefeinedOrEmpty(props.summary.companyName1);
  const isNull2 = StringUtility.isNullOrUndefeinedOrEmpty(props.summary.companyName2);
  if (!isNull1 && !isNull2) {
    companyName = (
      <div className="customerNameHalf">
        {props.summary.companyName1}
        <br />
        {props.summary.companyName2}
      </div>
    );
  } else if (!isNull1) {
    companyName = (
      <div className="customerNameHalf">
        {props.summary.companyName1}
        <br />
      </div>
    );
  } else if (!isNull2) {
    companyName = (
      <div className="customerNameHalf">
        <br />
        {props.summary.companyName2}
      </div>
    );
  }

  return (
    <div>
      <div className="r-container justify-end">
        <div className="voucherNumber">
          <span>No. {props.summary.voucherNumber}</span>
        </div>
      </div>
      <div className="r-container justify-center">
        <div className="title">
          <span>納　品　書</span>
        </div>
      </div>
      <div className="r-container justify-start margin20">
        <div className="leftBlock">
          <div className="customerBox">
            <div className="r-container justify-space-between">
              <div className="customerName">{companyName}</div>
              <div>
                <span>御中</span>
              </div>
            </div>
          </div>
          <div className="constructionBox margin20">
            <span>工事名：{props.summary.constructionName}</span>
          </div>
          <div className="r-container justify-center margin20">
            <div className="deliveryString">
              <span>平素は格別のご高配を賜り厚く御礼申し上げます。</span>
              <br />
              <span>下記の通り納品申し上げます。</span>
              <br />
            </div>
          </div>
          <div className="deliveryDate">
            <span>{DateUtility.format(DateUtility.nowDate(), "yyyy 年 M 月 d 日")}</span>
          </div>
          <div className="r-container justify-center margin20">
            <div className="amountUnit">
              <span>&yen;</span>
            </div>
            <div className="amount">
              <span>{(props.summary.billingAmount + props.summary.tax).toLocaleString()}</span>
            </div>
          </div>
          <div className="r-container justify-center">
            <div className="deliveryString">
              <span>この金額には消費税が含まれております。</span>
            </div>
          </div>
        </div>
        <div className="rightBlock">
          <div className="company">
            <span style={{ fontSize: "24px" }}>株式会社 弘栄ドリームワークス</span>
            <br />
            <span>〒 990-2221 山形県山形市大字風間字地蔵山下2068</span>
            <br />
            <span>TEL : 023-616-5735 FAX : 023-616-5652</span>
            <div className="companySign">
              <img src={"data:image/png;base64," + props.signImage} alt="company sign" style={{ width: 110 }} />
            </div>
          </div>
          <div className="r-container justify-end">
            <table className="signTable">
              <thead>
                <tr>
                  <th>承 認</th>
                  <th>担 当</th>
                </tr>
              </thead>
              <tbody className="signBody">
                <tr>
                  <td className="signCell">
                    {props.summary.approval && (
                      <img
                        className="sign"
                        src={"data:image/png;base64," + props.summary.approval}
                        alt="approval sign"
                      />
                    )}
                  </td>
                  <td className="signCell">
                    {props.summary.staff && (
                      <img className="sign" src={"data:image/png;base64," + props.summary.staff} alt="staff sign" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="margin20">
        <DeliverySlipDetailDesign summary={props.summary} />
      </div>
      <div className="r-container margin10">
        <div className="remark">
          <span>備考</span>
        </div>
      </div>
      <div className="r-container margin10 break-page">
        <div className="remarkValue">{replaceCRLF(props.summary.note)}</div>
      </div>
    </div>
  );
});

interface Props {
  signImage: string;

  summary: DeliverySlipSummary[];
}

const DeliverySlipDesign = (props: Props) => {
  return (
    <>
      <style type="text/css">
        {`@page {
            margin: 12.7mm 9.7mm;
            size: A4 landscape;
          }
          .break-page {
            break-after: page;
          }
          .main {
            font-family: Meiryo;
            font-size: 12px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          .f-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .r-container {
            display: flex;
            flex-direction: row;            
            align-items: flex-start;
            width: 100%
          }
          .justify-start {
            justify-content: flex-start;
          }
          .justify-center {
            justify-content: center;
          }
          .justify-end {
            justify-content: flex-end;
          }
          .justify-space-between {
            justify-content: space-between;
          }
          .margin10 {
            margin-top: 10px;
          }
          .margin20 {
            margin-top: 20px;
          }
          .margin40 {
            margin-top: 40px;
          }
          .voucherNumber {
            border-bottom: solid 1px black;
          }
          .title {
            width: 300px;
            font-size: 28px;
            border-bottom: solid 1px black;
            text-align: center;
            font-weight: bold;
          }
          .leftBlock {
            width: 450px;
          }
          .rightBlock {
            width: calc(100% - 450px);
          }
          .company {
            height: 100px;
            text-align: right;
            position: relative;
            padding-top: 20px;
          }
          .companySign {
            position: absolute;
            top: 0px;
            right: 0px;
          }
          .signTable {
            width: 160px;
            border: solid 1px black;
            margin-top: 15px;
          }
          .signBody {
            height: 75px;
          }
          .signCell {
            width: 75px;
            height: 75px;
            text-align: center;
            vertical-align: middle;
          }
          .sign {
            max-width: 80%;
            max-height: 80%;
          }
          .customerBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            font-size: 24px;
          }
          .customerName {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .customerNameHalf {
            font-size: 12px;
          }
          .constructionBox {
            width: 100%;
            max-width: 450px;
            border-bottom: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .deliveryString {
            width: 300px;
          }
          .deliveryDate {
            width: 100%;
            text-align: center;
          }
          .amountUnit {
            font-size: 24px;
            width: 50px;
            border-bottom: double 4px black;
            padding-left: 25px;
          }
          .amount {
            font-size: 24px;
            width: 250px;
            border-bottom: double 4px black;
          }
          .detail {
            width: 100%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;
          }
          th, td {
            border: solid 1px black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .leftCell {
            border-left: none;
          }
          .rightCell {
            border-right: none;
          }
          .nameCell {
            width: 300px;
            max-width: 300px;
            padding-left: 10px;
          }
          .numberOfCell {
            width: 70px;
            max-width: 70px;
            text-align: right;
            padding-right: 10px;
          }
          .unitCell {
            width: 70px;
            max-width: 70px;
            text-align: right;
            padding-right: 10px;
          }
          .amountCell {
            width: 100px;
            max-width: 100px;
            text-align: right;
            padding-right: 10px;
          }
          .descriptionCell {
            padding-left: 10px;
          }
          .remark {
            padding-left: 10px;
          }
          .remarkValue {
            width: 100%;
            padding-left: 10px;
            border-bottom: solid 1px black;
          }
            `}
      </style>
      <div className="main">
        {props.summary
          .filter((i) => i.checked)
          .map((summary, index) => {
            return (
              <div key={summary.id}>
                <DeliverySlipSummaryDesign signImage={props.signImage} summary={summary} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default React.memo(DeliverySlipDesign);
