import { SilentRequest } from "@azure/msal-browser";
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { useFetch } from "Hooks/useFetch";
import { DependencyList, useCallback } from "react";
import { msal } from "./AuthUtility";

export const scopes = [process.env.REACT_APP_SCOPE!];

class CallWebApi {
  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_WEBAPI_URL!,
    headers: { "Content-Type": "application/json" },
    responseType: "json",
  });

  async getAccessToken() {
    const activeAccount = msal.getActiveAccount();
    const accounts = msal.getAllAccounts();

    var request: SilentRequest = {
      scopes: scopes,
      account: activeAccount || accounts[0],
    };

    try {
      var response = await msal.acquireTokenSilent(request);
      return response.accessToken;
    } catch {
      msal.acquireTokenRedirect(request);
      return "";
    }
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return await this.axiosInstance.get<T>(url, config);
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return await this.axiosInstance.delete<T>(url, config);
  }

  async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return this.axiosInstance.post<T>(url, data, config);
  }

  async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return this.axiosInstance.put<T>(url, data, config);
  }
}

const webApi: CallWebApi = new CallWebApi();

export const callWebApi = (index: number = 0) => {
  return webApi;
};

export const cancelToken = () => axios.CancelToken.source();

export const simpleGet = <T,>(
  url: string,
  setState: React.Dispatch<React.SetStateAction<T>> | ((data: T) => void),
  params?: object
) => {
  return async (signal: CancelTokenSource) => {
    const response = await callWebApi().get<T>(url, {
      cancelToken: signal.token,
      params: params,
    });

    if (response.data == null) {
      return;
    }

    setState(response.data);
  };
};

export const useSimpleFetch = <T,>(
  url: string,
  setState: React.Dispatch<React.SetStateAction<T>> | ((data: T) => void),
  firstLoad: boolean = true,
  params?: object,
  deps?: DependencyList
) => {
  return useFetch(useCallback(simpleGet(url, setState, params), deps ?? []), firstLoad);
};
