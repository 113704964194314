import { makeStyles } from "@material-ui/core";

export const useGenericStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  hw100percent: {
    height: "100%",
    width: "100%",
  },
  height100percent: {
    height: "100%",
  },
  width100percent: {
    width: "100%",
  },
  width100: {
    width: 100,
  },
  width150: {
    width: 150,
  },
  width200: {
    width: 200,
  },
  width300: {
    width: 300,
  },
  width400: {
    width: 400,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignRight: {
    textAlign: "right",
  },
  leftMarginNextToButton: {
    marginLeft: 12,
  },
}));
