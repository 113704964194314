import React, { useCallback } from "react";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

interface Props {
  children: JSX.Element[];
  to: string;
  value: any;
  onClick: (value: any) => void;
}

export default function ListItemEx(props: Props) {
  const handleClick = useCallback(() => {
    props.onClick(props.value);
  }, [props]);

  return (
    <ListItem button component={Link} to={props.to} onClick={handleClick}>
      {props.children}
    </ListItem>
  );
}
