import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import { DateEx, DateTime, DateUtility, StringEx } from "Common/Utility/DateUtility";
import { DrawUtility, Align, Font, VerticalAlign, Rect } from "Common/Utility/Drawing";
import { Canvas, CanvasEvent, CanvasHandler, CanvasInfo, ColumnInfo } from "Component/Canvas";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useAlertAdd } from "Common/Component/AlertList";
import {
  CanvasDataBase,
  drawLegend,
  ScheduleHandlerBase,
  ScheduleProps,
  useSortScheduleList,
  DrawLendingStroke,
  DrawLending,
  DrawLendingCurrent,
  DrawExcess,
  DrawPattern,
} from "./Schedule";
import { AssetMaster, LendingPeriod, numberOfAsset } from "Models/Asset";
import { useGenericStyles } from "Common/Utility/Styles";
import { useMessageBox } from "Hooks/useMessageBox";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import DigitsField from "Component/DigitsField";
import SelectAssetDialog from "./SelectAssetDialog";
import { v4 } from "uuid";
import { Shipment, ShipmentDetail } from "Models/Shipment";
import { useOpens } from "Hooks/useOpens";
import { Design, ShipmentStatus } from "Common/Utility/Constants";
import { EditShipmentMode } from "Pages/Shipment/EditShipment";

const useStyles = makeStyles((theme) => ({
  canvas: {
    backgroundColor: "white",
  },
  cursor: {
    cursor: "w-resize",
  },
}));

interface NumberOfInput {
  numberOf: number | null;
}

interface NumberOfSalesDialogProps {
  open: boolean;

  shipmentDetail: ShipmentDetail;

  onClose: (numberOf: number | null) => void;
}

const NumberOfSalesDialog = (props: NumberOfSalesDialogProps) => {
  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props.open);

  const [value, setValue] = useState<NumberOfInput>({ numberOf: 0 });

  const inputManager = useInputManager(setValue, edited);

  useEffect(() => {
    if (props.open) {
      setValue((value) => {
        value.numberOf = props.shipmentDetail.numberOfSales;
        return { ...value };
      });
    }
  }, [props.open, props.shipmentDetail]);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="xs">
        <DialogTitle>販売数</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <DigitsField
                className={genericClasses.width100percent}
                label="販売数"
                value={value.numberOf == null ? "" : value.numberOf.toString()}
                onChange={inputManager.handleOnChangeNumber("numberOf")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose(null))} color="primary">
            キャンセル
          </Button>
          <Button
            className={genericClasses.margin}
            onClick={() => {
              if (value.numberOf == null) {
                alertAdd({ type: "info", message: "販売数は必須項目です。" });
                return;
              }

              props.onClose(value.numberOf);
            }}
            color="primary"
            disabled={!props.open}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface NumberOfLentOutEditDailogProps {
  open: boolean;

  target: { detail: ShipmentDetail; lendingPeriod: LendingPeriod };

  onClose: () => void;

  onSave: (target: { detail: ShipmentDetail; lendingPeriod: LendingPeriod }, numberOfLentOUt: number) => void;

  onDelete: (target: { detail: ShipmentDetail; lendingPeriod: LendingPeriod }) => void;

  editAuth: boolean;
}

interface NumberOfLentOutEditDailogInput {
  numberOfLentOut: number | null;
}

const NumberOfLentOutEditDailog = (props: NumberOfLentOutEditDailogProps) => {
  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [input, setInput] = useState<NumberOfLentOutEditDailogInput>({
    numberOfLentOut: null,
  } as NumberOfLentOutEditDailogInput);

  const [edited, confirm] = useWhetherEdited(props.open);

  const inputManager = useInputManager(setInput, edited);

  const handleOnClickDeleteInAction = useCallback(async () => {
    if (await message.confirm("削除確認", "貸出予定を削除します、よろしいですか？")) {
      props.onDelete(props.target);
    }
  }, [message, props]);

  useEffect(() => {
    if (props.open) {
      setInput({
        numberOfLentOut: props.target.lendingPeriod.numberOf,
      } as NumberOfLentOutEditDailogInput);
    }
  }, [props.open, props.target]);

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Shipment);

  return (
    <>
      <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="xs">
        <DialogTitle>貸出数</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <DigitsField
                className={genericClasses.width100percent}
                label="貸出数"
                value={input.numberOfLentOut ?? ""}
                onChange={inputManager.handleOnChangeNumber("numberOfLentOut")}
                disabled={!(props.editAuth && isValidEdit)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {props.editAuth && isValidEdit && props.target.lendingPeriod.numberOf > 0 && (
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickDeleteInAction}
              color="secondary"
              disabled={!props.open}
            >
              削除
            </Button>
          )}
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            {props.editAuth && isValidEdit ? "キャンセル" : "閉じる"}
          </Button>
          {props.editAuth && isValidEdit && (
            <Button
              className={genericClasses.margin}
              onClick={() => {
                if (input.numberOfLentOut == null) {
                  alertAdd({ type: "info", message: "貸出数は必須項目です。" });
                  return;
                }

                if (input.numberOfLentOut === 0) {
                  alertAdd({ type: "info", message: "貸出数は1以上を設定してください。" });
                  return;
                }

                props.onSave(props.target, input.numberOfLentOut);
              }}
              color="primary"
              disabled={!props.open}
            >
              保存
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

interface CanvasData extends CanvasDataBase<ShipmentDetail, ShipmentScheduleProps> {
  columns: ColumnInfo<ShipmentDetail>[];
}

interface Callbacks {
  setData: React.Dispatch<React.SetStateAction<CanvasData>>;

  openEditDialog: (detail: ShipmentDetail, lendingPeriod: LendingPeriod) => void;

  openSelectDialog: (vPositionIndex: number, modelMasterId: string) => void;

  openNumberOfSalesDialog: (detail: ShipmentDetail) => void;

  sort: (index: number) => void;
}

class HandlerBase extends ScheduleHandlerBase<CanvasData, Callbacks, ShipmentDetail, ShipmentScheduleProps> {
  protected drawRow(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    maxDate: Date,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    const distanceDate = this.calcDistance(canvasInfo.mouseMovementDistance.x);

    // 行
    const date = new Date(
      canvasData.horizontalOffset.getFullYear(),
      canvasData.horizontalOffset.getMonth(),
      canvasData.horizontalOffset.getDate() + distanceDate
    );

    const vOffset = HandlerBase.calcVerticalOffset(
      canvasData.scheduleProps.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    const drawPatterns: DrawPattern[] = [];

    for (
      let i = vOffset;
      i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < canvasData.scheduleProps.rows.length;
      ++i
    ) {
      const detail = canvasData.scheduleProps.rows[i];
      detail.lendingPeriods.forEach((value: LendingPeriod) => {
        let from = new Date(value.from);
        let to = value.to == null ? null : new Date(value.to);

        if (detail.assetNumber == null) {
          if (value.shipmentId === canvasData.scheduleProps.shipment.id) {
            drawPatterns.push(new DrawLendingStroke(i - vOffset, from, to));
          }
        } else {
          if (value.shipmentId === canvasData.scheduleProps.shipment.id) {
            drawPatterns.push(new DrawLendingCurrent(i - vOffset, from, to));
          } else {
            drawPatterns.push(new DrawLending(i - vOffset, from, to, value.returnAssets));
          }
          // 超過時の処理
          if (value.shipmentStatus === ShipmentStatus.Finished) {
            drawPatterns.push(new DrawExcess(i - vOffset, from, to, value.returnAssets));
          }
        }
      });

      this.createDrawPatterns(date, maxDate, i - vOffset, detail, drawPatterns, canvasData.scheduleProps.shipment.id);
    }

    drawPatterns.sort((a, b) => a.zIndex - b.zIndex);
    drawPatterns.forEach((i) => i.draw(context, canvasData.columnsWidth, date, maxDate));
  }

  protected drawAsset(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    context.save();

    const props = canvasData.scheduleProps;

    let vOffset = HandlerBase.calcVerticalOffset(
      props.rows.length,
      canvas.height,
      canvasData.verticalOffset,
      this.calcDistance(canvasInfo.mouseMovementDistance.y)
    );

    // スケジュールのはみ出し部分の削除
    context.clearRect(0, 0, canvasData.columnsWidth, canvas.height);

    // 資産番号の出力
    for (let i = vOffset; i < HandlerBase.maxRowCount(canvas.height) + vOffset + 1 && i < props.rows.length; ++i) {
      var x = 0;
      for (let j = 0; j < canvasData.columns.length; ++j) {
        const columnInfo = canvasData.columns[j];

        const rect: Rect = {
          x: 8 + x,
          y: Design.headerHeight + (i - vOffset) * Design.rowHeight,
          w: columnInfo.width - 16,
          h: Design.rowHeight,
        };

        if (columnInfo.draw) {
          columnInfo.draw(context, rect, props.rows[i]);
        } else {
          context.fillStyle = "black";
          if (columnInfo.toString != null) {
            DrawUtility.fillText(
              context,
              columnInfo.toString(props.rows[i]),
              rect,
              new Font("Meiryo", 12),
              columnInfo.bodyHorizonAlign,
              columnInfo.bodyVerticalAlign
            );
          }
        }

        x += canvasData.columns[j].width;
      }
    }

    context.restore();
  }

  protected drawHeader(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    canvasInfo: CanvasInfo,
    canvasData: CanvasData
  ) {
    // ヘッダーの境界線
    context.strokeStyle = "rgb(200,200,200)";
    context.lineWidth = 1;
    DrawUtility.line(context, 0, Design.headerHeight, canvas.width, Design.headerHeight);
    DrawUtility.line(context, 0, Design.rowHeight, canvasData.columnsWidth, Design.rowHeight);

    context.fillStyle = "black";

    context.lineWidth = 0.5;
    var position = 0;
    for (let i = 0; i < canvasData.columns.length; ++i) {
      DrawUtility.fillText(
        context,
        canvasData.columns[i].text,
        {
          x: position,
          y: Design.rowHeight,
          w: canvasData.columns[i].width,
          h: Design.rowHeight,
        },
        new Font("Meiryo", 12),
        canvasData.columns[i].headerHorizontalAlign,
        canvasData.columns[i].headerVerticalAlign
      );

      if (i < canvasData.columns.length - 1) {
        position += canvasData.columns[i].width;
        DrawUtility.line(context, position, Design.rowHeight, position, canvas.height);
      }
    }

    drawLegend(context, canvasData.columnsWidth, true);
  }
}

class DefaultHandler extends HandlerBase {
  private clicked(canvas: HTMLCanvasElement, event: CanvasEvent<CanvasData, Callbacks>) {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    let width: number = 0;
    return event.data.columns.findIndex((i) => {
      if (width <= position.x && width + i.width >= position.x) {
        return true;
      }

      width += i.width;
      return false;
    });
  }

  private clickedSales(event: CanvasEvent<CanvasData, Callbacks>, vPositionIndex: number) {
    if (event.data.scheduleProps.editShipmentMode === EditShipmentMode.Edit) {
      const detail = event.data.scheduleProps.rows[vPositionIndex];
      if (detail.assetNumber == null) {
        if (detail.id != null) {
          event.callbacks.openNumberOfSalesDialog(detail);
        }
      } else {
        event.data.scheduleProps.setRows((value) => {
          detail.numberOfSales = Math.abs((detail.numberOfSales ?? 0) - 1);
          if (detail.numberOfSales === 1) {
            const index = detail.lendingPeriods.findIndex(
              (i) => i.id == null || i.shipmentId === event.data.scheduleProps.shipment.id
            );
            if (index >= 0) {
              detail.lendingPeriods.splice(index, 1);
            }
          }
          return [...value];
        });

        event.data.scheduleProps.edited();
      }
    }
  }

  private clickedDelete(event: CanvasEvent<CanvasData, Callbacks>, vPositionIndex: number) {
    if (event.data.scheduleProps.editShipmentMode === EditShipmentMode.Edit) {
      event.data.scheduleProps.setRows((value) => {
        value.splice(vPositionIndex, 1);
        return [...value];
      });

      event.data.scheduleProps.edited();
    }
  }

  private clickedAssetNumber(event: CanvasEvent<CanvasData, Callbacks>, vPositionIndex: number) {
    if (event.data.scheduleProps.editShipmentMode === EditShipmentMode.Edit) {
      const detail = event.data.scheduleProps.rows[vPositionIndex];

      // 型式不明な場合は終了
      if (detail.modelMasterId == null) {
        return undefined;
      }

      event.callbacks.openSelectDialog(vPositionIndex, detail.modelMasterId);
    }
  }

  onClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    const columnIndex = this.clicked(canvas, event);
    const isHeader = this.clickedHeader(canvas, event);

    // ヘッダ部分ならソート
    if (isHeader && columnIndex >= 1 && columnIndex <= 3) {
      event.callbacks.sort(columnIndex);
      return;
    }

    // ボディ部分ならそれぞれの処理
    if (position.y >= Design.headerHeight) {
      let positionIndex = this.getDetailPosition(
        DrawUtility.mousePosition(canvas, event.mouseEvent),
        event.data.columnsWidth
      );

      let vOffset = HandlerBase.calcVerticalOffset(
        event.data.scheduleProps.rows.length,
        canvas.height,
        event.data.verticalOffset,
        0
      );

      // 行が存在しない場合は終了
      const vPositionIndex = positionIndex.y + vOffset;
      if (vPositionIndex >= event.data.scheduleProps.rows.length || vPositionIndex < 0) {
        return undefined;
      }

      switch (columnIndex) {
        case 0:
          this.clickedDelete(event, vPositionIndex);
          break;
        case 2:
          this.clickedAssetNumber(event, vPositionIndex);
          break;
        case 4:
          this.clickedSales(event, vPositionIndex);
          break;
        default:
          break;
      }
    }

    return undefined;
  }

  onDoubleClick(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    let positionIndex = this.getDetailPosition(
      DrawUtility.mousePosition(canvas, event.mouseEvent),
      event.data.columnsWidth
    );

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    // 行が存在しない場合は終了
    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex >= event.data.scheduleProps.rows.length || vPositionIndex < 0) {
      return undefined;
    }

    // 左側の場合は終了
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    if (position.x <= event.data.columnsWidth) {
      return undefined;
    }

    // 該当行の資産情報
    const detail = event.data.scheduleProps.rows[vPositionIndex];

    // 具体的な資産が選択されていない場合は終了
    if (detail.id == null) {
      return undefined;
    }

    // クリックした箇所の日付
    const date = new Date(
      event.data.horizontalOffset.getFullYear(),
      event.data.horizontalOffset.getMonth(),
      event.data.horizontalOffset.getDate() + positionIndex.x
    );

    // クリック箇所の貸出情報index
    const index = detail.lendingPeriods.findIndex((i) => {
      if (detail.assetNumber == null) {
        if (i.to == null) {
          return i.shipmentId === event.data.scheduleProps.shipment.id && new Date(i.from) <= date;
        } else {
          return (
            i.shipmentId === event.data.scheduleProps.shipment.id &&
            DateUtility.inRange(date, new Date(i.from), new Date(i.to))
          );
        }
      } else {
        if (i.to == null) {
          return new Date(i.from) <= date;
        } else {
          return DateUtility.inRange(date, new Date(i.from), new Date(i.to));
        }
      }
    });

    if (index === -1) {
      // 編集不可の場合は処理終了
      if (event.data.scheduleProps.editShipmentMode !== EditShipmentMode.Edit) {
        return undefined;
      }

      const lendingPeriod: LendingPeriod = {
        id: undefined,
        shipmentId: event.data.scheduleProps.shipment.id,
        assetMasterId: detail.id,
        from: date.toLocaleDateString(),
        to: date.toLocaleDateString(),
        numberOf: 0,
        returnAssets: [],
      };

      if (detail.assetNumber == null) {
        event.callbacks.openEditDialog(detail, lendingPeriod);
      } else {
        lendingPeriod.numberOf = 1;
        event.data.scheduleProps.setRows((value) => {
          // 1出荷情報1貸出期間とするので既に貸出期間が存在する場合は削除
          const index = value[vPositionIndex].lendingPeriods.findIndex(
            (i) => i.id == null || i.shipmentId === event.data.scheduleProps.shipment.id
          );
          if (index >= 0) {
            value[vPositionIndex].lendingPeriods.splice(index, 1);
          }

          // 新規分の追加
          value[vPositionIndex].numberOfSales = 0;
          value[vPositionIndex].lendingPeriods.push(lendingPeriod);
          return [...value];
        });
        event.data.scheduleProps.edited();
      }
    } else {
      // 受注IDが関連する受注IDと同じ場合のみ編集可能
      const lendingPeriod = detail.lendingPeriods[index];

      if (lendingPeriod.shipmentId === event.data.scheduleProps.shipment.id) {
        // 対象が期限無し貸出の状態で右端をダブルクリックした場合、1日に変更
        if (lendingPeriod.to == null && position.x >= canvas.width - 5) {
          // 編集不可の場合は処理終了
          if (event.data.scheduleProps.editShipmentMode === EditShipmentMode.Reference) {
            return undefined;
          }

          event.data.scheduleProps.setRows((value) => {
            value[vPositionIndex].lendingPeriods[index].to = value[vPositionIndex].lendingPeriods[index].from;
            return [...value];
          });
          event.data.scheduleProps.edited();
          return undefined;
        }

        // 対象が期限有り貸出の状態で貸出期間の右端をダブルクリックした場合、かつ未来に他の貸出期間が無い場合、期限無し貸出に変更
        if (
          lendingPeriod.to !== null &&
          DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), lendingPeriod.to) &&
          positionIndex.align === Align.right &&
          (detail.assetNumber == null ||
            !detail.lendingPeriods.some((i) => new Date(lendingPeriod.from) < new Date(i.from)))
        ) {
          // 編集不可の場合は処理終了
          if (event.data.scheduleProps.editShipmentMode === EditShipmentMode.Reference) {
            return undefined;
          }

          event.data.scheduleProps.setRows((value) => {
            value[vPositionIndex].lendingPeriods[index].to = null;
            return [...value];
          });
          event.data.scheduleProps.edited();
          return undefined;
        }

        // 対象を削除
        if (detail.assetNumber == null) {
          event.callbacks.openEditDialog(detail, lendingPeriod);
        } else {
          // 編集不可の場合は処理終了
          if (event.data.scheduleProps.editShipmentMode !== EditShipmentMode.Edit) {
            return undefined;
          }

          event.data.scheduleProps.setRows((value) => {
            value[vPositionIndex].lendingPeriods.splice(index, 1);
            return [...value];
          });

          event.data.scheduleProps.edited();
        }
      }
    }

    return undefined;
  }

  onMouseDown(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    if (positionIndex.y < 0) {
      return undefined;
    }

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    const vPositionIndex = positionIndex.y + vOffset;
    if (vPositionIndex < event.data.scheduleProps.rows.length) {
      const detail = event.data.scheduleProps.rows[vPositionIndex];
      const date = new DateEx(event.data.horizontalOffset).newInstance(0, 0, positionIndex.x);

      for (let i = 0; i < detail.lendingPeriods.length; ++i) {
        if (detail.lendingPeriods[i].shipmentId === event.data.scheduleProps.shipment.id) {
          if (
            DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), detail.lendingPeriods[i].from) &&
            positionIndex.align === Align.left &&
            event.data.scheduleProps.editShipmentMode === EditShipmentMode.Edit
          ) {
            return new ResizeHandler(Align.left, detail, detail.lendingPeriods[i]);
          } else if (
            detail.lendingPeriods[i].to !== null &&
            DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), detail.lendingPeriods[i].to!) &&
            positionIndex.align === Align.right &&
            event.data.scheduleProps.editShipmentMode !== EditShipmentMode.Reference
          ) {
            return new ResizeHandler(Align.right, detail, detail.lendingPeriods[i]);
          }
        }
      }
    }

    if (position.x > event.data.columnsWidth) {
      return new ScrollHandler();
    }

    return undefined;
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    var resizeCursor: boolean = false;

    const position = DrawUtility.mousePosition(canvas, event.mouseEvent);
    const positionIndex = this.getDetailPosition(position, event.data.columnsWidth);

    let vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      0
    );

    const vPositionIndex = positionIndex.y + vOffset;
    if (
      position.x >= event.data.columnsWidth &&
      position.y >= Design.headerHeight &&
      vPositionIndex < event.data.scheduleProps.rows.length
    ) {
      const detail = event.data.scheduleProps.rows[vPositionIndex];
      const date = new DateEx(event.data.horizontalOffset).newInstance(0, 0, positionIndex.x);

      for (let i = 0; i < detail.lendingPeriods.length; ++i) {
        if (detail.lendingPeriods[i].shipmentId === event.data.scheduleProps.shipment.id) {
          if (
            DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), detail.lendingPeriods[i].from) &&
            positionIndex.align === Align.left &&
            event.data.scheduleProps.editShipmentMode === EditShipmentMode.Edit
          ) {
            resizeCursor = true;
          } else if (
            detail.lendingPeriods[i].to !== null &&
            DateUtility.equals("yyyyMMdd", date.toLocaleDateString(), detail.lendingPeriods[i].to!) &&
            positionIndex.align === Align.right &&
            event.data.scheduleProps.editShipmentMode !== EditShipmentMode.Reference
          ) {
            resizeCursor = true;
          }
        }
      }
    }

    if (event.data.resizeCursor !== resizeCursor) {
      event.callbacks.setData((value) => {
        if (value.resizeCursor !== resizeCursor) {
          return { ...value, resizeCursor: resizeCursor };
        } else {
          return value;
        }
      });
    }

    return undefined;
  }

  onMouseWheel(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    const vOffset = HandlerBase.calcVerticalOffset(
      event.data.scheduleProps.rows.length,
      canvas.height,
      event.data.verticalOffset,
      this.calcDistance((event.mouseEvent as WheelEvent).deltaY)
    );

    if (event.data.verticalOffset === vOffset) {
      return undefined;
    }

    event.callbacks.setData((value) => {
      return { ...value, verticalOffset: vOffset };
    });

    event.mouseEvent.preventDefault();

    return undefined;
  }
}

class ResizeHandler extends HandlerBase {
  detail: ShipmentDetail;

  lendingPeriod: LendingPeriod;

  startDate: Date | null;

  align: Align;

  sourceFrom: DateTime;

  sourceTo: DateTime | null;

  constructor(align: Align, detail: ShipmentDetail, lendingPeriod: LendingPeriod) {
    super();

    this.detail = detail;
    this.align = align;
    this.lendingPeriod = lendingPeriod;
    if (this.align === Align.left) {
      this.startDate = new Date(this.lendingPeriod.from);
    } else {
      if (this.lendingPeriod.to == null) {
        this.startDate = null;
      } else {
        this.startDate = new Date(this.lendingPeriod.to);
      }
    }
    this.sourceFrom = lendingPeriod.from;
    this.sourceTo = lendingPeriod.to;
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    return new DefaultHandler();
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    return new DefaultHandler();
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.mouseMovementDistance = { x: 0, y: 0 };

    if (this.startDate === null) {
      return undefined;
    }

    let position = DrawUtility.mousePosition(canvas, event.mouseEvent);

    if (event.canvasInfo.mouseDowned && event.canvasInfo.mouseDownPosition) {
      var amountOfChange = Math.floor((position.x - event.canvasInfo.mouseDownPosition.x) / Design.rowHeight);
      const newDate = new DateEx(this.startDate).newInstance(0, 0, amountOfChange).toLocaleDateString();

      var newRange: { from: StringEx; to: StringEx | null };

      if (this.align === Align.left) {
        if (this.lendingPeriod.to == null) {
          newRange = { from: new StringEx(newDate), to: null };
        } else {
          newRange = { from: new StringEx(newDate), to: new StringEx(this.lendingPeriod.to) };
        }
      } else {
        newRange = { from: new StringEx(this.lendingPeriod.from), to: new StringEx(newDate) };
      }

      if (newRange.to !== null) {
        if (
          newRange.from.toDate() > newRange.to.toDate() ||
          (this.detail.assetNumber != null &&
            this.detail.lendingPeriods.findIndex(
              (i) =>
                i !== this.lendingPeriod &&
                new StringEx(i.from).toDate() <= newRange.to!.toDate() &&
                new StringEx(i.to).toDate() >= newRange.from.toDate()
            ) >= 0)
        ) {
          return undefined;
        }
      }

      event.callbacks.setData((value) => {
        if (this.align === Align.left) {
          this.lendingPeriod.from = newDate;
        } else {
          this.lendingPeriod.to = newDate;
        }
        return { ...value };
      });

      event.data.scheduleProps.edited();
    }
    return undefined;
  }
}

class ScrollHandler extends HandlerBase {
  private resetDistance(canvas: HTMLCanvasElement, event: CanvasEvent<CanvasData, Callbacks>) {
    event.callbacks.setData((value) => {
      if (event.canvasInfo.mouseMovementDistance.x !== 0) {
        value.horizontalOffset = new Date(
          value.horizontalOffset.getFullYear(),
          value.horizontalOffset.getMonth(),
          value.horizontalOffset.getDate() + this.calcDistance(event.canvasInfo.mouseMovementDistance.x)
        );
      }

      if (event.canvasInfo.mouseMovementDistance.y !== 0) {
        value.verticalOffset = HandlerBase.calcVerticalOffset(
          value.scheduleProps.rows.length,
          canvas.height,
          value.verticalOffset,
          this.calcDistance(event.canvasInfo.mouseMovementDistance.y)
        );
      }

      return { ...value };
    });
  }

  onMouseUp(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseOut(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    this.resetDistance(canvas, event);
    return new DefaultHandler();
  }

  onMouseMove(
    canvas: HTMLCanvasElement,
    event: CanvasEvent<CanvasData, Callbacks>
  ): CanvasHandler<CanvasData, Callbacks> | undefined {
    event.canvasInfo.refresh();
    return undefined;
  }
}

interface ShipmentScheduleProps extends ScheduleProps<ShipmentDetail> {
  className: string;

  shipment: Shipment;

  edited: () => void;

  editShipmentMode: EditShipmentMode;
}

const OpenIndex = {
  NumberOfLentOutEditDailog: 0,
  SelectAssetDialog: 1,
  NumberOfSalesDialog: 2,
  Max: 3,
};
type OpenIndex = typeof OpenIndex[keyof typeof OpenIndex];

function ShipmentSchedule(props: ShipmentScheduleProps) {
  const classes = useStyles();

  const setRows = useMemo(() => props.setRows, [props.setRows]);

  const edited = useMemo(() => props.edited, [props.edited]);

  const [status, open, close] = useOpens(OpenIndex.Max);

  const [target, setTarget] = useState<{ detail: ShipmentDetail; lendingPeriod: LendingPeriod }>({
    detail: {} as ShipmentDetail,
    lendingPeriod: {} as LendingPeriod,
  });

  const [detail, setDetail] = useState<ShipmentDetail>({
    category: 0,
    assetNumber: null,
    lendingPeriods: [],
    stockHistories: [],
    numberOfSales: 0,
    indexRuleId: "",
    checked: false,
  });

  const [selectAssetDialog, setSelectAssetDialog] = useState<
    { vPositionIndex: number; modelMasterId: string } | undefined
  >(undefined);

  const handleOnSave = useCallback(
    (target: { detail: ShipmentDetail; lendingPeriod: LendingPeriod }, numberOfLentout: number) => {
      setRows((value) => {
        const detail = value.find((i) => i.id === target.detail.id);
        if (detail) {
          let lendingPeriod = detail.lendingPeriods.find((i) => i.id === target.lendingPeriod.id);
          if (lendingPeriod == null) {
            // 1出荷情報1貸出期間とするので既に貸出期間が存在する場合は削除
            const index = detail.lendingPeriods.findIndex((i) => i.id == null || i.shipmentId === props.shipment.id);
            if (index >= 0) {
              detail.lendingPeriods.splice(index, 1);
            }

            detail.lendingPeriods.push(target.lendingPeriod);
            lendingPeriod = target.lendingPeriod;
            lendingPeriod.id = v4();
          }
          lendingPeriod.numberOf = numberOfLentout;
          return [...value];
        } else {
          return value;
        }
      });

      edited();

      close(OpenIndex.NumberOfLentOutEditDailog);
    },
    [setRows, close, props.shipment.id, edited]
  );

  const handleOnDelete = useCallback(
    (target: { detail: ShipmentDetail; lendingPeriod: LendingPeriod }) => {
      setRows((value) => {
        const detail = value.find((i) => i.id === target.detail.id);
        if (detail == null) {
          return value;
        }
        const index = detail.lendingPeriods.findIndex((i) => i.id === target.lendingPeriod.id);
        if (index < 0) {
          return value;
        } else {
          detail?.lendingPeriods.splice(index, 1);
          edited();
          return [...value];
        }
      });

      close(OpenIndex.NumberOfLentOutEditDailog);
    },
    [setRows, close, edited]
  );

  const handleOnClose = useCallback(
    (result?: { vPositionIndex: number; assetMaster: AssetMaster }) => {
      if (result) {
        setRows((value) => {
          value[result.vPositionIndex] = { ...value[result.vPositionIndex], ...result.assetMaster };
          return [...value];
        });

        edited();
      }
      close(OpenIndex.SelectAssetDialog);
    },
    [close, setRows, edited]
  );

  const columns: ColumnInfo<ShipmentDetail>[] = useMemo(
    () => [
      {
        width: 50,
        text: "",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.center,
        bodyVerticalAlign: VerticalAlign.middle,
        draw: (context: CanvasRenderingContext2D, rect: Rect, data: ShipmentDetail) => {
          DrawUtility.deleteIcon(
            context,
            rect,
            props.editShipmentMode === EditShipmentMode.Edit ? "rgb(63,81,181)" : "rgb(117,117,117)"
          );
        },
      },
      {
        width: 200,
        text: "型式",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        dataKey: "model",
        toString: (data: ShipmentDetail) => data.model,
      },
      {
        width: 200,
        text: "資産番号",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.left,
        bodyVerticalAlign: VerticalAlign.middle,
        dataKey: "assetNumber",
        toString: (data: ShipmentDetail) => data.assetNumber,
        draw: (context: CanvasRenderingContext2D, rect: Rect, data: ShipmentDetail) => {
          DrawUtility.editIcon(
            context,
            { x: rect.x, y: rect.y, w: 30, h: rect.h },
            props.editShipmentMode === EditShipmentMode.Edit ? "rgb(63,81,181)" : "rgb(117,117,117)"
          );
          if (data.assetNumber == null) {
            if (data.id == null) {
              context.fillStyle = "rgba(0,0,0,0.54)";
              DrawUtility.fillText(
                context,
                "未選択",
                {
                  x: rect.x + 30,
                  y: rect.y,
                  w: rect.w - 30,
                  h: rect.h,
                },
                new Font("Meiryo", 12),
                Align.center,
                VerticalAlign.middle
              );
            }
          } else {
            context.fillStyle = "black";
            DrawUtility.fillText(
              context,
              data.assetNumber,
              {
                x: rect.x + 30,
                y: rect.y,
                w: rect.w - 30,
                h: rect.h,
              },
              new Font("Meiryo", 12),
              Align.left,
              VerticalAlign.middle
            );
          }
        },
      },
      {
        width: 80,
        text: "資産数",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.right,
        bodyVerticalAlign: VerticalAlign.middle,
        toString: (data: ShipmentDetail) => {
          if (data.id == null) {
            return "";
          } else {
            return numberOfAsset(data).toString();
          }
        },
      },
      {
        width: 100,
        text: "販売数",
        headerHorizontalAlign: Align.center,
        headerVerticalAlign: VerticalAlign.middle,
        bodyHorizonAlign: Align.center,
        bodyVerticalAlign: VerticalAlign.middle,
        draw: (context: CanvasRenderingContext2D, rect: Rect, data: ShipmentDetail) => {
          if (data.id != null) {
            context.fillStyle =
              props.editShipmentMode === EditShipmentMode.Edit ? "rgb(63,81,181)" : "rgb(117,117,117)";

            DrawUtility.editIcon(context, { x: rect.x, y: rect.y, w: 30, h: rect.h }, context.fillStyle);

            DrawUtility.fillText(
              context,
              data.numberOfSales == null ? "0" : data.numberOfSales.toString(),
              {
                x: rect.x + 30,
                y: rect.y,
                w: rect.w - 30,
                h: rect.h,
              },
              new Font("Meiryo", 12),
              Align.right,
              VerticalAlign.middle
            );
          }
        },
      },
    ],
    [props.editShipmentMode]
  );

  const sort = useSortScheduleList<ShipmentDetail>(setRows, columns);

  const [data, setData] = useState<CanvasData>({
    scheduleProps: props,
    columns: columns,
    columnsWidth: columns.reduce((prev, current) => prev + current.width, 0),
    verticalOffset: 0,
    horizontalOffset: new Date(DateUtility.nowDate()),
    resizeCursor: false,
  } as CanvasData);

  const callbacks = useMemo(() => {
    return {
      setData: setData,
      openEditDialog: (detail: ShipmentDetail, lendingPeriod: LendingPeriod) => {
        setTarget({ detail: detail, lendingPeriod: lendingPeriod });
        open(OpenIndex.NumberOfLentOutEditDailog);
      },
      openSelectDialog: (vPositionIndex: number, modelMasterId: string) => {
        setSelectAssetDialog({ vPositionIndex: vPositionIndex, modelMasterId: modelMasterId });
        open(OpenIndex.SelectAssetDialog);
      },
      openNumberOfSalesDialog: (detail: ShipmentDetail) => {
        setDetail(detail);
        open(OpenIndex.NumberOfSalesDialog);
      },
      sort: sort,
    } as Callbacks;
  }, [open, sort]);

  useEffect(() => {
    setData((value) => {
      return { ...value, columns: columns };
    });
  }, [columns]);

  useEffect(() => {
    setData((value) => {
      return { ...value, scheduleProps: { ...props } };
    });
  }, [props]);

  return (
    <>
      <div>
        <Canvas<CanvasData, Callbacks>
          className={clsx(props.className, classes.canvas, data.resizeCursor && classes.cursor)}
          handler={new DefaultHandler()}
          data={data}
          callbacks={callbacks}
          components={[]}
        />
      </div>
      <NumberOfLentOutEditDailog
        open={status(OpenIndex.NumberOfLentOutEditDailog)}
        target={target}
        onSave={handleOnSave}
        onClose={() => close(OpenIndex.NumberOfLentOutEditDailog)}
        onDelete={handleOnDelete}
        editAuth={props.editShipmentMode === EditShipmentMode.Edit}
      />
      <SelectAssetDialog
        open={status(OpenIndex.SelectAssetDialog)}
        target={selectAssetDialog}
        onClose={handleOnClose}
        exclusion={props.rows}
      />
      <NumberOfSalesDialog
        open={status(OpenIndex.NumberOfSalesDialog)}
        shipmentDetail={detail}
        onClose={(numberOf: number | null) => {
          if (numberOf != null) {
            setRows((value) => {
              const assetMaster = value.find((i) => i.id === detail.id);
              if (assetMaster == null) {
                return value;
              } else {
                assetMaster.numberOfSales = numberOf;
                return [...value];
              }
            });

            edited();
          }
          close(OpenIndex.NumberOfSalesDialog);
        }}
      />
    </>
  );
}

export default React.memo(ShipmentSchedule);
