import { Box, Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Design } from "Common/Utility/Constants";
import DigitsField from "./DigitsField";
import { useGenericStyles } from "Common/Utility/Styles";
import clsx from "clsx";
import { TimeSpan } from "Common/Utility/DateUtility";

const useStyles = makeStyles((theme) => ({
  box: {
    height: Design.componentUnitHeight,
    marginBottom: Design.margin,
  },
  caption: {
    transform: "translate(0px, -1px) scale(0.75)",
    transformOrigin: "top left",
    color: (props: any) => (props.disabled ? theme.palette.text.disabled : "rgba(0,0,0,0.54)"),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  text: {
    marginTop: Design.margin * -1,
    marginRight: Design.margin,
    width: 35,
  },
}));

interface Props {
  className?: string;
  caption: string;
  disabled?: boolean;
  defaultValue: { days?: number; hours?: number; minutes?: number };
  onChange: (event: React.ChangeEvent<any>) => void;
}

export const InputTimeSpan = (props: Props) => {
  const classes = useStyles({ disabled: props.disabled });

  const genericClasses = useGenericStyles();

  const [days, setDays] = useState<number | undefined>(props.defaultValue.days);

  const [hours, setHours] = useState<number | undefined>(props.defaultValue.hours);

  const [minutes, setMinutes] = useState<number | undefined>(props.defaultValue.minutes);

  const handleOnChange = (target: string, set: React.Dispatch<React.SetStateAction<number | undefined>>) => {
    return (event: ChangeEvent<HTMLInputElement | any>) => {
      let newValue: number | undefined = undefined;
      if (event.target.value) {
        newValue = Number(event.target.value);
        if (isNaN(newValue)) {
          newValue = undefined;
        }
      }

      set(newValue);

      let result: number;
      const timeSpan = new TimeSpan(0);
      try {
        if (target === "days") {
          timeSpan.set((newValue ?? 0).toString(), (hours ?? 0).toString(), (minutes ?? 0).toString());
        } else if (target === "hours") {
          timeSpan.set((days ?? 0).toString(), (newValue ?? 0).toString(), (minutes ?? 0).toString());
        } else {
          timeSpan.set((days ?? 0).toString(), (hours ?? 0).toString(), (newValue ?? 0).toString());
        }
        result = timeSpan.value;
      } catch {
        result = NaN;
      }
      props.onChange({ target: { value: result } } as ChangeEvent<HTMLInputElement | any>);
    };
  };

  return (
    <Box className={clsx(classes.box, props.className)}>
      <Typography className={classes.caption}>{props.caption}</Typography>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <DigitsField
          className={classes.text}
          value={days}
          onChange={handleOnChange("days", setDays)}
          maxLength={3}
          inputProps={{ style: { textAlign: "right" } }}
          disabled={props.disabled}
        />
        <Typography className={genericClasses.marginRight}>日</Typography>
        <DigitsField
          className={classes.text}
          value={hours}
          onChange={handleOnChange("hours", setHours)}
          maxLength={3}
          inputProps={{ style: { textAlign: "right" } }}
          disabled={props.disabled}
        />
        <Typography className={genericClasses.marginRight}>時間</Typography>
        <DigitsField
          className={classes.text}
          value={minutes}
          onChange={handleOnChange("minutes", setMinutes)}
          maxLength={3}
          inputProps={{ style: { textAlign: "right" } }}
          disabled={props.disabled}
        />
        <Typography>分</Typography>
      </Grid>
    </Box>
  );
};
