import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { useMessageBox } from "Hooks/useMessageBox";
import { useGenericStyles } from "Common/Utility/Styles";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute } from "Hooks/useFetch";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useContentHeight } from "Hooks/useResize";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { DateTime, DateUtility, TimeSpan } from "Common/Utility/DateUtility";
import Period from "Component/Period";
import { EditDialog } from "./EditDialog";
import { RepairHistory, RepairHistoryParts } from "Models/RepairHistory";
import { generalComparsion } from "Common/Utility/GenericInterface";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
}));

interface Condition {
  // 資産番号
  assetNumber: string;

  // 故障発生日From
  failureDateFrom: DateTime | null;

  // 故障発生日To
  failureDateTo: DateTime | null;

  // 故障発見者
  foundByName: string;

  // 修理完了日From
  repairCompletionDateFrom: DateTime | null;

  // 修理完了日To
  repairCompletionDateTo: DateTime | null;

  // 修理対応者
  repairedByName: string;
}

const initialSearchCondition: Condition = {
  assetNumber: "",
  failureDateFrom: null,
  failureDateTo: null,
  foundByName: "",
  repairCompletionDateFrom: null,
  repairCompletionDateTo: null,
  repairedByName: "",
};

export const RepairHistoryPanel = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const [condition, setCondition] = useState<Condition>({ ...initialSearchCondition });

  const [search, setSearch] = useState<Condition>({ ...initialSearchCondition });

  const [data, setData] = useState<RepairHistory[]>([]);

  const [editData, setEditData] = useState<RepairHistory | undefined>(undefined);

  const inputManager = useInputManager(setCondition);

  const fetchResultLoad = useSimpleFetch(
    "/repairHistories",
    setData,
    false,
    DateUtility.InvalidToNull(
      search,
      "failureDateFrom",
      "failureDateTo",
      "repairCompletionDateFrom",
      "repairCompletionDateTo"
    ),
    [search]
  );
  const handleOnClickSearch = useCallback(() => setSearch({ ...condition }), [condition]);

  const handleOnClickClear = useCallback(() => setCondition({ ...initialSearchCondition }), []);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      id: null,
      failureDate: null,
      usedCompanyBy: null,
      usedCompanyByName: null,
      foundBy: null,
      foundByName: "",
      assetMasterid: null,
      assetNumber: "",
      causeOfFailure: null,
      failureDetails: null,
      repairCompletionDate: null,
      repairTime: null,
      repairedBy: null,
      repairedByName: "",
      repairDetails: null,
      repairHistoryParts: [],
      repairUsageAmount: null,
    });
  }, []);

  const handleOnClickEdit = useCallback(
    (repairHistory: RepairHistory, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...repairHistory });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { id: string; rowIndex: number }) => {
      await callWebApi().delete(`/repairHistories/${object.id}`);

      if (unmounted.value) {
        return;
      }

      setData((value) => {
        value.splice(object.rowIndex, 1);
        return [...value];
      });
    }, [])
  );

  const handleOnClickDelete = useCallback(
    async (repairHistory: RepairHistory, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "修理履歴を削除します。よろしいですか？"))) {
        return;
      }

      if (repairHistory.id) {
        executeDelete({ id: repairHistory.id, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose = useOnCloseEditDialog("RepairHistory", setEditData, setData, "id");

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.RepairHistory
  );

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isAssetMenuEditValid) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 150,
        label: "故障発生日",
        dataKey: "failureDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(0),
      },
      {
        width: 200,
        label: "使用先企業",
        dataKey: "usedCompanyByName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "故障発見者",
        dataKey: "foundByName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      },
      {
        width: 175,
        label: "資産番号",
        dataKey: "assetNumber",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(3),
      },
      {
        width: 200,
        label: "故障原因",
        dataKey: "causeOfFailure",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(4),
      },
      {
        width: 200,
        label: "故障内容",
        dataKey: "failureDetails",
        fit: true,
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(5),
      },
      {
        width: 150,
        label: "修理完了日",
        dataKey: "repairCompletionDate",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy/MM/dd"),
        visible: getVisible(6),
      },
      {
        width: 130,
        label: "修理所要時間",
        dataKey: "repairTime",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any) => (data == null ? "" : new TimeSpan(data).toString()),
        visible: getVisible(7),
      },
      {
        width: 150,
        label: "修理対応者",
        dataKey: "repairedByName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(8),
      },
      {
        width: 200,
        label: "修理内容",
        dataKey: "repairDetails",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(9),
      },
      {
        width: 200,
        label: "修理使用部品名",
        dataKey: "repairHistoryParts",
        headerAlign: "center",
        bodyAlign: "left",
        convert: (data: RepairHistoryParts[]) => (data.length === 0 ? "" : data[0].name),
        visible: getVisible(10),
      },
      {
        width: 130,
        label: "修理使用金額",
        dataKey: "repairUsageAmount",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any) => (data == null ? "" : data.toLocaleString() + " 円"),
        visible: getVisible(11),
        sort: (asc: boolean) => {
          setData((value) => {
            value.sort((a, b) => generalComparsion(a.repairUsageAmount, b.repairUsageAmount) * (asc ? 1 : -1));
            return [...value];
          });
        },
      }
    );

    return ret;
  }, [isAssetMenuEditValid, getVisible]);

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <Condition observer={conditionRef}>
          <>
            <TextField
              className={genericClasses.margin}
              label="資産番号"
              value={condition.assetNumber}
              onChange={inputManager.handleOnChange("assetNumber")}
            />
            <Period
              label="故障発生日"
              fromValue={condition.failureDateFrom}
              toValue={condition.failureDateTo}
              onChangeFrom={inputManager.handleOnChangeDate("failureDateFrom")}
              onChangeTo={inputManager.handleOnChangeDate("failureDateTo")}
              width={170}
            />
            <TextField
              className={genericClasses.margin}
              label="故障発見者"
              value={condition.foundByName}
              onChange={inputManager.handleOnChange("foundByName")}
            />
            <Period
              label="修理完了日"
              fromValue={condition.repairCompletionDateFrom}
              toValue={condition.repairCompletionDateTo}
              onChangeFrom={inputManager.handleOnChangeDate("repairCompletionDateFrom")}
              onChangeTo={inputManager.handleOnChangeDate("repairCompletionDateTo")}
              width={170}
            />
            <TextField
              className={genericClasses.margin}
              label="修理対応者"
              value={condition.repairedByName}
              onChange={inputManager.handleOnChange("repairedByName")}
            />
            <Button className={genericClasses.margin} variant="contained" color="primary" onClick={handleOnClickSearch}>
              検索
            </Button>
            <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
              クリア
            </Button>
          </>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultLoad) ?? (
          <Paper className={classes.paper}>
            <VirtualaizedTable
              values={data}
              setValues={setData}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onClickAdd={isAssetMenuEditValid ? handleOnClickAdd : undefined}
              onClickEdit={handleOnClickEdit}
              onClickReference={handleOnClickEdit}
              onClickDelete={handleOnClickDelete}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      {editData != null && (
        <EditDialog open={true} data={editData} onClose={handleOnClose} editAuth={isAssetMenuEditValid} />
      )}
    </Grid>
  );
});
