import React, { useCallback, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import { Condition } from "Component/Condition";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { useGenericStyles } from "Common/Utility/Styles";
import { useContentHeight } from "Hooks/useResize";
import Period from "Component/Period";
import { DateUtility } from "Common/Utility/DateUtility";
import IntroductionFeeBillingRequestDialog from "./IntroductionFeeBillingRequestDialog";
import { IntroductionFeeBillingRequest } from "Models/IntroductionFeeBillingRequest";
import { DateTime } from "Common/Utility/DateUtility";
import { useSimpleFetch } from "Common/Utility/Api";
import { useInputManager } from "Common/Utility/HandleUtility";
import { Design, VoucherStatus, VisibleColumn } from "Common/Utility/Constants";
import { sortWithSet } from "Common/Utility/GenericInterface";
import { useColumnControl } from "Hooks/useColumnControl";
import { referenceButton } from "Common/Utility/AppUtility";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  inEdit: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    margin: theme.spacing(1),
  },
  file: {
    height: 150,
  },
}));

const IntroductionPanel = () => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [introductionFeeBillingRequest, seTintroductionFeeBillingRequest] = useState(
    [] as IntroductionFeeBillingRequest[]
  );

  interface Condition {
    requestedFromDate: DateTime;
    requestedToDate: DateTime;
    billed: boolean;
  }

  const [search, setSearch] = useState({} as Condition);

  const [condition, setCondition] = useState({
    requestedFromDate: DateUtility.firstDate(DateUtility.nowDate()),
    requestedToDate: DateUtility.endDate(DateUtility.nowDate()),
    billed: false,
  } as Condition);

  const inputManager = useInputManager(setCondition);

  const [open, setOpen] = useState(false);

  const [referenceIndex, setReferenceIndex] = useState(0);

  const handleOnClose = useCallback((result: IntroductionFeeBillingRequest | null) => {
    setOpen(false);

    if (!result) {
      return;
    }

    seTintroductionFeeBillingRequest((value) => {
      const index = value.findIndex((value) => value.id === result.id);

      if (index === -1) {
        return { ...value };
      }

      if (result.disabled) {
        value.splice(index, 1);
      } else {
        value[index] = result;
      }

      return [...value];
    });
  }, []);

  const handleOnClickReference = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setOpen(true);
      setReferenceIndex(rowIndex);
    },
    []
  );

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.Introduction
  );

  const columns: ColumnData[] = useMemo(
    () => [
      referenceButton,
      {
        width: 150,
        label: "依頼日付",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (createdAt: DateTime) => DateUtility.format(createdAt),
        visible: getVisible(0),
      },
      {
        width: 350,
        label: "ベンダー",
        dataKey: "vendorName",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "工事番号",
        dataKey: "constructionNumber",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(2),
      },
      {
        width: 400,
        label: "工事名",
        dataKey: "constructionName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(3),
      },
      {
        width: 150,
        label: "手数料",
        dataKey: "fee",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (fee: number) => fee.toLocaleString() + " 円",
        sort: (asc: boolean) =>
          sortWithSet(introductionFeeBillingRequest, seTintroductionFeeBillingRequest, "fee", asc),
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "状態",
        dataKey: "status",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (status: number | null) => {
          if (status == null) {
            return "伝票未作成";
          }

          if (status === VoucherStatus.Billed) {
            return "請求済み";
          } else {
            return "伝票作成済み";
          }
        },
        visible: getVisible(5),
      },
    ],
    [introductionFeeBillingRequest, seTintroductionFeeBillingRequest, getVisible]
  );

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useSimpleFetch(
      "/IntroductionFeeBillingRequests",
      seTintroductionFeeBillingRequest,
      false,
      DateUtility.InvalidToNull(search, "requestedFromDate", "requestedToDate"),
      [search]
    )
  );

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <Period
                label="依頼日付"
                fromValue={condition.requestedFromDate}
                toValue={condition.requestedToDate}
                onChangeFrom={inputManager.handleOnChangeDate("requestedFromDate")}
                onChangeTo={inputManager.handleOnChangeDate("requestedToDate")}
              />
              <FormControlLabel
                className={genericClasses.margin}
                control={
                  <Checkbox
                    checked={condition.billed}
                    onChange={inputManager.handleOnChangeCheck("billed")}
                    color="primary"
                  />
                }
                label="請求済みを含む"
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() =>
                  setCondition({
                    requestedFromDate: DateUtility.firstDate(DateUtility.nowDate()),
                    requestedToDate: DateUtility.endDate(DateUtility.nowDate()),
                    billed: false,
                  })
                }
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualizedTable
                values={introductionFeeBillingRequest}
                setValues={seTintroductionFeeBillingRequest}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickReference={handleOnClickReference}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <IntroductionFeeBillingRequestDialog
        open={open}
        voucherId={""}
        introductionFeeBillingRequest={
          referenceIndex < introductionFeeBillingRequest.length
            ? introductionFeeBillingRequest[referenceIndex]
            : ({} as IntroductionFeeBillingRequest)
        }
        disabled={true}
        isPrevVoucher={false}
        onClose={handleOnClose}
      />
    </>
  );
};

export default React.memo(IntroductionPanel);
