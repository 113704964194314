import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Grid } from "@material-ui/core";
import { Info as IconInfo, Help as IconConfirm, Warning as IconWarning, Error as IconError } from "@material-ui/icons";

export enum ButtonType {
  OK = 1,
  Cancel = 2,
  Yes = 4,
  No = 8,
  OKCancel = OK | Cancel,
  YesNo = Yes | No,
}

interface MessageTypeDef {
  icon: JSX.Element;
  buttonType: ButtonType;
}

interface MessageTypes {
  [index: string]: MessageTypeDef;
}

const MessageTypes: MessageTypes = {
  info: {
    icon: <IconInfo color="primary" fontSize="large" />,
    buttonType: ButtonType.OK,
  },
  confirm: {
    icon: <IconConfirm color="primary" fontSize="large" />,
    buttonType: ButtonType.YesNo,
  },
  warning: {
    icon: <IconWarning color="secondary" fontSize="large" />,
    buttonType: ButtonType.OK,
  },
  error: {
    icon: <IconError color="secondary" fontSize="large" />,
    buttonType: ButtonType.OK,
  },
};
//type MessageType = keyof typeof MessageTypes;
export type MessageType = "info" | "confirm" | "warning" | "error";

export type Props = {
  type: MessageType;
  buttonType?: ButtonType;
  title?: string;
  message: string;
  onClose: (result?: boolean) => void;
};

export const MessageBox: React.FC<Props> = (props) => {
  const messageTypeDef = MessageTypes[props.type];
  const buttonType = props.buttonType ?? messageTypeDef.buttonType;

  const isVisible = (checkButtonType: ButtonType): boolean => {
    return (buttonType & checkButtonType) === checkButtonType;
  };

  const handleClose = (result: boolean): (() => void) => {
    return () => props.onClose(result);
  };

  return (
    <Dialog open={true} maxWidth="xs">
      {props.title && <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>}
      <DialogContent style={{ minWidth: 444 }}>
        <Grid container>
          <Grid item style={{ width: 45 }}>
            <Grid container direction="row" justify="flex-start" alignItems="center" style={{ height: "100%" }}>
              {messageTypeDef.icon}
            </Grid>
          </Grid>
          <Grid item style={{ width: "calc(100% - 45px)" }}>
            <Grid container direction="row" justify="flex-start" alignItems="center" style={{ height: "100%" }}>
              <Typography style={{ whiteSpace: "pre-wrap" }}>{props.message}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isVisible(ButtonType.OK) && (
          <Button color="primary" variant="contained" onClick={handleClose(true)}>
            OK
          </Button>
        )}
        {isVisible(ButtonType.Cancel) && (
          <Button variant="contained" onClick={handleClose(false)}>
            キャンセル
          </Button>
        )}
        {isVisible(ButtonType.Yes) && (
          <Button color="primary" variant="contained" onClick={handleClose(true)}>
            はい
          </Button>
        )}
        {isVisible(ButtonType.No) && (
          <Button variant="contained" onClick={handleClose(false)}>
            いいえ
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
