import React, { useCallback } from "react";
import { makeStyles, Box, Grid, Paper, CircularProgress } from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import { FetchResult, LoadStatus } from "Hooks/useFetch";

export const LoadingMode = {
  Circular: 0,
  Simple: 1,
} as const;
export type LoadingMode = typeof LoadingMode[keyof typeof LoadingMode];

const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(199, 199, 199, 0.25)",
    borderRadius: "4px 4px 4px 4px /4px 4px 4px 4px",
  },
  paper: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 100,
    height: 100,
    backgroundColor: "rgba(175,255,255,0.75)",
    borderRadius: "25px 25px 25px 25px /25px 25px 25px 25px",
  },
  circular: {
    marginTop: 20,
  },
  loading: {
    marginTop: 8,
    marginLeft: 4,
    color: "blue",
  },
  faild: {
    color: "white",
    fontSize: 24,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "nowrap",
  },
}));

interface Props {
  loading: boolean;
  onClickReload?: () => void;
  mode: LoadingMode;
}

const Loading = (props: Props) => {
  const classes = useStyles();

  if (props.loading) {
    if (props.mode === LoadingMode.Circular) {
      return (
        <Box className={classes.box}>
          <Paper className={classes.paper} elevation={3}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="center" alignItems="center">
                <CircularProgress className={classes.circular} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="center" alignItems="center">
                <span className={classes.loading}>Loading...</span>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      );
    } else if (props.mode === LoadingMode.Simple) {
      return (
        <Box className={classes.box}>
          <span className={classes.faild}>Now loading...</span>
        </Box>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <Box className={classes.box} onClick={props.onClickReload}>
        <span className={classes.faild}>Click here to reload !</span>
      </Box>
    );
  }
};

Loading.defaultProps = {
  mode: LoadingMode.Circular,
};

export default React.memo(Loading);

export const useLoadingElement = (
  style: string,
  mode: LoadingMode = LoadingMode.Circular,
  ...state: FetchResult[]
): JSX.Element | null => {
  const genericClasses = useGenericStyles();

  if (style === "") {
    style = genericClasses.hw100percent;
  }

  const handleOnClick = useCallback(() => {
    for (let i = 0; i < state.length; ++i) {
      if (state[i].status === LoadStatus.Error) {
        state[i].reload();
      }
    }
  }, [state]);

  for (let i = 0; i < state.length; ++i) {
    switch (state[i].status) {
      case LoadStatus.Init:
        return <></>;
      case LoadStatus.Begined:
        return (
          <Box className={style}>
            <Loading loading={true} mode={mode} />
          </Box>
        );
      case LoadStatus.Error:
        return (
          <Box className={style}>
            <Loading loading={false} onClickReload={handleOnClick} mode={mode} />
          </Box>
        );
      default:
        break;
    }
  }

  return null;
};
