import React, { useMemo, useState } from "react";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { ReportConfig } from "Models/ReportConfig";
import Period from "Component/Period";
import { useGenericStyles } from "Common/Utility/Styles";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import { callPrint, Print } from "Common/Component/Print";
import Condition from "Component/Condition";
import { CheckColumn, Checked, simpleColumnData } from "../Report";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useContentHeight } from "Hooks/useResize";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useSimpleFetch } from "Common/Utility/Api";
import { ComboItem } from "Common/Utility/GenericInterface";
import { Group } from "Models/Group";
import DeliverySlipDesign from "./DeliverySlipDesign";
import TextField from "Component/TextField";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  labelWithSelect: {
    width: 300,
    margin: theme.spacing(1),
  },
}));

export interface DeliverySlipSummary extends Checked {
  id: string;

  voucherNumber: string;

  companyName: string;

  companyName1: string;

  companyName2: string;

  constructionName: string;

  billingAmount: number;

  tax: number;

  staff: string;

  approval: string;

  details: DeliverySlipDetail[];

  note: string;

  voucherDate: DateTime;

  closingDate: DateTime;

  depositDate: DateTime;
}

export interface DeliverySlipDetail {
  name: string;

  numberOf: string;

  unit: string;

  amount: number | null;

  taxExemption: boolean;

  summary: string;
}

interface Condition {
  voucherNumber: string;

  companyId: string;

  companyName: string;

  constructionId: string;

  constructionName: string;

  voucherDateFrom: DateTime | null;

  voucherDateTo: DateTime | null;

  closingDateFrom: DateTime | null;

  closingDateTo: DateTime | null;

  depositDateFrom: DateTime | null;

  depositDateTo: DateTime | null;
}

const defaultCondition: Condition = {
  voucherNumber: "",
  companyId: "",
  companyName: "",
  constructionId: "",
  constructionName: "",
  voucherDateFrom: null,
  voucherDateTo: null,
  closingDateFrom: null,
  closingDateTo: null,
  depositDateFrom: null,
  depositDateTo: null,
};

const Report = () => {
  const [height, ref] = useContentHeight(46);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const [condition, setCondition] = useState<Condition>(defaultCondition);

  const [search, setSearch] = useState<Condition>(defaultCondition);

  const inputManager = useInputManager(setCondition);

  const [data, setData] = useState<DeliverySlipSummary[]>([]);

  const [customers, setCustomers] = useState<ComboItem[]>([]);

  const [constructions, setConstructions] = useState<Group[]>([]);

  const [signImage, setSignImage] = useState<string>("");

  const fetchdata = useSimpleFetch(
    "/deliverySlip",
    setData,
    false,
    DateUtility.InvalidToNull(
      search,
      "voucherDateFrom",
      "voucherDateTo",
      "closingDateFrom",
      "closingDateTo",
      "depositDateFrom",
      "depositDateTo"
    ),
    [search]
  );

  const fetchCompany = useSimpleFetch("/companies/customer", setCustomers);

  const fetchConstruction = useSimpleFetch("/group", setConstructions);

  const fetchSignImage = useSimpleFetch("/reports/kdwsign", (data: { signImage: string }) => {
    setSignImage(data.signImage);
  });

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    fetchdata,
    fetchCompany,
    fetchConstruction,
    fetchSignImage
  );

  const constructionColumns: ColumnData[] = useMemo(() => [{ ...simpleColumnData, dataKey: "name" }], []);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.ReportDeliverySlip
  );

  const columns: ColumnData[] = useMemo(
    () => [
      CheckColumn(data, setData),
      {
        width: 150,
        label: "伝票番号",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "voucherNumber",
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "伝票日付",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "voucherDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(1),
      },
      {
        width: 300,
        label: "得意先名",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "companyName",
        visible: getVisible(2),
      },
      {
        width: 200,
        label: "工事名",
        headerAlign: "center",
        bodyAlign: "left",
        dataKey: "constructionName",
        fit: true,
        visible: getVisible(3),
      },
      {
        width: 150,
        label: "請求締日",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "closingDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(4),
      },
      {
        width: 150,
        label: "入金予定日",
        headerAlign: "center",
        bodyAlign: "center",
        dataKey: "depositDate",
        convert: (date: any) => DateUtility.format(date, "yyyy/MM/dd", "-"),
        visible: getVisible(5),
      },
    ],
    [getVisible, data]
  );

  return (
    <>
      <Grid item xs={12}>
        <Condition observer={ref}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <TextField
                className={genericClasses.margin}
                label="伝票番号"
                value={condition.voucherNumber}
                onChange={inputManager.handleOnChange("voucherNumber")}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="得意先"
                text={condition.companyName}
                data={customers}
                columns={[simpleColumnData]}
                onClickNarrowDown={useNarrowDown(customers, "text")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, companyId: result.value, companyName: result.text };
                })}
                maxWidth="sm"
                underLine={true}
              />
              <LabelWithSelect
                className={classes.labelWithSelect}
                caption="工事"
                text={condition.constructionName}
                data={constructions}
                columns={constructionColumns}
                onClickNarrowDown={useNarrowDown(constructions, "name")}
                onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                  return { ...value, constructionId: result.id, constructionName: result.name };
                })}
                maxWidth="sm"
                underLine={true}
              />
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Period
                label="伝票日付"
                fromValue={condition.voucherDateFrom}
                toValue={condition.voucherDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("voucherDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("voucherDateTo")}
                width={160}
              />
              <Period
                label="請求締日"
                fromValue={condition.closingDateFrom}
                toValue={condition.closingDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("closingDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("closingDateTo")}
                width={160}
              />
              <Period
                label="入金予定日"
                fromValue={condition.depositDateFrom}
                toValue={condition.depositDateTo}
                onChangeFrom={inputManager.handleOnChangeDate("depositDateFrom")}
                onChangeTo={inputManager.handleOnChangeDate("depositDateTo")}
                width={170}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition(defaultCondition)}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Condition>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="flex-start">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => callPrint("printout")}>
              PDF出力
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingElement ?? (
          <Paper className={classes.paper}>
            <VirtualizedTable
              values={data}
              tableHeight={height}
              rowHeight={48}
              columns={columns}
              onCloseContextMenu={handleOnCloseContextMenu}
              onChangeHeaderVisible={handleOnChangeHeaderVisible}
              headerContext
            />
          </Paper>
        )}
      </Grid>
      <Print id="printout" title="帳票印刷">
        <DeliverySlipDesign signImage={signImage} summary={data} />
      </Print>
    </>
  );
};

export const deliverySlipConfig: ReportConfig = {
  text: "納品書",
  element: <Report />,
};
