import { CancelTokenSource } from "axios";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { ComboItem } from "Common/Utility/GenericInterface";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useNarrowDown } from "Component/SelectDialog";
import { AssetMaster } from "Models/Asset";
import { useCallback, useMemo, useState } from "react";
import { useFetch } from "./useFetch";

export const useCallFetch = <T,>(style: string, url: string): [T[], JSX.Element | null] => {
  const [data, setData] = useState<T[]>([]);

  const fetch = useLoadingElement(
    style,
    LoadingMode.Simple,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<T[]>(url, { cancelToken: signal.token });
          setData(response.data);
        },
        [url]
      )
    )
  );

  return [data, fetch];
};

export const useCallFetchByCompany = (
  style: string
): [ComboItem[], JSX.Element | null, ColumnData[], (text: string) => ComboItem[]] => {
  const [data, fetch] = useCallFetch<ComboItem>(style, "/companies/customer");

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "得意先名",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const narrowDown = useNarrowDown(data, "text");

  return [data, fetch, columns, narrowDown];
};

export const useCallFetchByUser = (
  style: string
): [ComboItem[], JSX.Element | null, ColumnData[], (text: string) => ComboItem[]] => {
  const [data, fetch] = useCallFetch<ComboItem>(style, "/users/usermaster");

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "ユーザー",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const narrowDown = useNarrowDown(data, "text");

  return [data, fetch, columns, narrowDown];
};

export const useCallFetchByAsset = (
  style: string
): [AssetMaster[], JSX.Element | null, ColumnData[], (text: string) => AssetMaster[]] => {
  const [data, fetch] = useCallFetch<AssetMaster>(style, "/assetmaster");

  const columns: ColumnData[] = [
    {
      width: 200,
      label: "型式",
      dataKey: "model",
      headerAlign: "center",
      bodyAlign: "left",
    },
    {
      width: 300,
      label: "名称",
      dataKey: "name",
      headerAlign: "center",
      bodyAlign: "left",
      fit: true,
    },
    {
      width: 200,
      label: "資産番号",
      dataKey: "assetNumber",
      headerAlign: "center",
      bodyAlign: "center",
    },
  ];

  const narrowDown = useNarrowDown(data, "model", "name", "assetNumber");

  return [data, fetch, columns, narrowDown];
};
