import { useCallback, useEffect, useState } from "react";
import { useMessageBox } from "./useMessageBox";

export const useWhetherEdited = (
  open: boolean | { open: boolean; onHold?: () => void },
  isEditAuth?: boolean
): [() => void, (proc: () => void) => void] => {
  const message = useMessageBox();

  const [edited, setEdited] = useState(false);

  const confirm = useCallback(
    async (proc: () => void) => {
      if (
        (isEditAuth == null || isEditAuth) &&
        edited &&
        !(await message.confirm("保存確認", "変更を破棄しますか？"))
      ) {
        return;
      }

      proc();
    },
    [edited, message, isEditAuth]
  );

  useEffect(() => {
    if (open) {
      setEdited(false);
    }
  }, [open]);

  useEffect(() => {
    if (typeof open === "boolean") {
      if (open) {
        setEdited(false);
      }
    } else {
      if (open.onHold) {
        setEdited(true);
      } else if (open.open) {
        setEdited(false);
      }
    }
  }, [open]);

  return [useCallback(() => setEdited(true), []), confirm];
};
