import React, { ChangeEvent, useCallback, useState } from "react";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export interface IWithId {
  id: string | null;
}

interface DirectInput<T> {
  instant: T;
  disabled: boolean;
  handleOnClickAdd: () => void;
  handleOnChange: (target: keyof T) => (event: ChangeEvent<HTMLInputElement | any>) => void;
  handleOnChangeNumber: (target: keyof T) => (event: ChangeEvent<HTMLInputElement | any>) => void;
  handleOnChangeDate: (target: keyof T) => (date: Date | null) => void;
  handleOnChangeSelect: (
    target: keyof T
  ) => (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  handleOnClickEdit: (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  handleOnClickDelete: (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  handleOnClickSave: (
    record: T,
    columnData: ColumnData,
    rowIndex: number,
    columnIndex: number,
    args: { cancel: boolean }
  ) => void;
  handleOnClickCancel: (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
}

export interface DirectInputProps<T> {
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  edited?: () => void;
  validate?: (value: T) => boolean;
}

export const useDirectInput = function <T extends IWithId>(props: DirectInputProps<T>) {
  const [instant, setInstant] = useState<T>({} as T);

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleOnClickAdd = useCallback(() => {
    setDisabled(true);

    props.setData((value) => {
      return [...value, {} as T];
    });

    setInstant({ id: "new" } as T);
  }, [props]);

  const handleOnChange = useCallback((target: keyof T) => {
    return (event: ChangeEvent<HTMLInputElement | any>) => {
      const newValue = event.target.value;
      setInstant((value) => {
        return { ...value, [target]: newValue };
      });
    };
  }, []);

  const handleOnChangeNumber = useCallback((target: keyof T) => {
    return (event: ChangeEvent<HTMLInputElement | any>) => {
      var newValue: number | null = Number(event.target.value);
      if (isNaN(newValue)) {
        newValue = null;
      }
      setInstant((value) => {
        return { ...value, [target]: newValue };
      });
    };
  }, []);

  const handleOnChangeDate = useCallback((target: keyof T) => {
    return (date: MaterialUiPickersDate | null) => {
      if (date == null) {
        setInstant((value) => {
          return { ...value, [target]: null };
        });
      } else {
        setInstant((value) => {
          return { ...value, [target]: date.toLocaleDateString() };
        });
      }
    };
  }, []);

  const handleOnChangeSelect = useCallback((target: keyof T) => {
    return (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
      setInstant((value) => {
        if (event.target.value === "") {
          return { ...value, [target]: null };
        } else {
          return { ...value, [target]: event.target.value };
        }
      });
    };
  }, []);

  const handleOnClickEdit = useCallback((record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setDisabled(true);

    setInstant({ ...record });
  }, []);

  const handleOnClickDelete = useCallback(
    async (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      props.setData((value) => {
        value.splice(rowIndex, 1);
        return [...value];
      });
      props.edited?.();
    },
    [props]
  );

  const handleOnClickSave = useCallback(
    async (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number, args: { cancel: boolean }) => {
      if (props.validate && !props.validate(instant)) {
        args.cancel = true;
        return;
      }

      props.setData((value) => {
        if (instant.id === "new") {
          instant.id = null;
        }
        value[rowIndex] = instant;
        return [...value];
      });

      setDisabled(false);
      props.edited?.();
    },
    [instant, props]
  );

  const handleOnClickCancel = useCallback(
    (record: T, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (instant.id === "new") {
        props.setData((value) => {
          value.pop();
          return [...value];
        });
      }

      setDisabled(false);
    },
    [instant.id, props]
  );

  return {
    instant: instant,
    disabled: disabled,
    handleOnClickAdd: handleOnClickAdd,
    handleOnChange: handleOnChange,
    handleOnChangeNumber: handleOnChangeNumber,
    handleOnChangeDate: handleOnChangeDate,
    handleOnChangeSelect: handleOnChangeSelect,
    handleOnClickEdit: handleOnClickEdit,
    handleOnClickDelete: handleOnClickDelete,
    handleOnClickSave: handleOnClickSave,
    handleOnClickCancel: handleOnClickCancel,
  } as DirectInput<T>;
};
