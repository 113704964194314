import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContentHeight } from "Hooks/useResize";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { MenuIndex } from "Component/Menu";
import { useExecute, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useMessageBox } from "Hooks/useMessageBox";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { LineupSummary } from "Models/Lineup";
import { useInputManager } from "Common/Utility/HandleUtility";
import { EditDialog } from "./EditDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { useColumnControl } from "Hooks/useColumnControl";
import { VisibleColumn } from "Common/Utility/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
}));

interface Condition {
  name?: string;
}

const initialCondition: Condition = {};

export const Lineup = React.memo(() => {
  const [height, observer] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [lineupSummaries, setLineupSummaries] = useState<LineupSummary[]>([]);

  const [lineupSummary, setLineupSummary] = useState<LineupSummary | undefined>(undefined);

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const loadingElement = useLoadingElement(
    classes.searchLoading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<LineupSummary[]>("/lineups/search", {
            cancelToken: signal.token,
            params: { ...search },
          });

          if (response.data == null) {
            return;
          }

          setLineupSummaries(response.data);
        },
        [search]
      ),
      false
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setLineupSummary({
      details: [],
    });
    setIsEdit(true);
  }, []);

  const handleOnClose = useOnCloseEditDialog("Lineup", setLineupSummary, setLineupSummaries, "id");

  const handleOnClickEdit = useCallback(
    (lineupSummary: LineupSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setLineupSummary(lineupSummary);
      setIsEdit(true);
    },
    []
  );

  const handleOnClickReference = useCallback(
    (lineupSummary: LineupSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setLineupSummary(lineupSummary);
      setIsEdit(false);
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id?: string; rowIndex: number }) => {
        if (object.id == null) {
          return;
        }

        await callWebApi().delete(`/lineups/${object.id}`);

        alertAdd({ type: "info", message: "商品情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setLineupSummaries((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (lineupSummary: LineupSummary, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "商品情報を削除します。よろしいですか？"))) {
        return;
      }

      executeDelete({ id: lineupSummary.id, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Lineup);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Lineup);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 200,
        label: "商品名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 150,
        label: "型式数",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowData: LineupSummary) => rowData.details.length.toString(),
        visible: getVisible(1),
      },
      {
        width: 150,
        label: "総原価",
        headerAlign: "center",
        bodyAlign: "right",
        convert: (data: any, rowData: LineupSummary) =>
          rowData.details.reduce((prev, current) => prev + current.cost, 0).toLocaleString() + " 円",
        visible: getVisible(2),
      }
    );

    return ret;
  }, [isValidEdit, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={observer}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item container direction="row" justify="flex-start" alignItems="center">
                <TextField
                  className={genericClasses.margin}
                  label="商品名"
                  value={condition.name}
                  onChange={inputManager.handleOnChange("name")}
                />
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="primary"
                  onClick={() => setSearch({ ...condition })}
                >
                  検索
                </Button>
                <Button
                  className={genericClasses.margin}
                  variant="outlined"
                  onClick={() => {
                    setCondition({ ...initialCondition });
                  }}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={lineupSummaries}
                setValues={setLineupSummaries}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickReference}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {lineupSummary != null && (
        <EditDialog open={true} lineupSummary={lineupSummary} onClose={handleOnClose} isEdit={isValidEdit && isEdit} />
      )}
    </>
  );
});
