import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useExecuteEx } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { CallOnClose, InputPending, onCloseWithSave, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { RepairHistory } from "Models/RepairHistory";
import { RepairParts } from "Models/RepairParts";
import DigitsField from "Component/DigitsField";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 150,
    marginRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    height: 300,
  },
  person: {
    width: 200,
    marginRight: theme.spacing(1),
  },
}));

interface Props extends InputPending {
  open: boolean;

  data: RepairParts;

  editAuth: boolean;
}

export const EditDialog = React.memo((props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState<RepairParts>(props.data);

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [edited, confirm] = useWhetherEdited(props, isAssetMenuEditValid);

  const inputManager = useInputManager(setData, edited);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: RepairParts; onClose: CallOnClose }) => {
        var response = await callWebApi().put<RepairHistory>("/repairParts", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "修理部品を保存しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "修理部品",
    <EditDialog open={true} data={data} onClose={props.onClose} editAuth={true} />,
    props.onClose,
    props.onHold
  );

  const handleOnClickSaveInAction = useCallback(async () => {
    executePut({ data: data, onClose: props.onClose });
  }, [props, data, executePut]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>修理部品情報</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <TextField
              className={genericClasses.width100percent}
              label="メーカー"
              value={data.maker}
              onChange={inputManager.handleOnChange("maker")}
              disabled={!isAssetMenuEditValid}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={genericClasses.width100percent}
              label="品番"
              value={data.productNumber}
              onChange={inputManager.handleOnChange("productNumber")}
              disabled={!isAssetMenuEditValid}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={genericClasses.width100percent}
              label="品名"
              value={data.name}
              onChange={inputManager.handleOnChange("name")}
              disabled={!isAssetMenuEditValid}
            />
          </Grid>
          <Grid item xs={12}>
            <DigitsField
              className={classes.datePicker}
              label="価格"
              value={data.amount}
              onChange={inputManager.handleOnChangeNumber("amount")}
              maxLength={9}
              disabled={!isAssetMenuEditValid}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isAssetMenuEditValid ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
