import { AppActionTypes, AppProvider } from "App";
import { HoldDialog } from "Component/HoldDialog";
import React from "react";
import { v4 } from "uuid";

export const useHoldInput = (
  name: string,
  component: React.ReactElement,
  onClose: (result: any) => void,
  onHold: (() => void) | undefined,
  before?: () => void
) => {
  const dispatch = AppProvider.useDispatch();

  return () => {
    if (onHold) {
      onHold();
    } else {
      const uuid = v4();
      before?.();
      dispatch({
        type: AppActionTypes.ADD_HOLD,
        value: {
          key: uuid,
          component: (
            <HoldDialog id={uuid} name={name}>
              {component}
            </HoldDialog>
          ),
        },
      });
      onClose(null);
    }
  };
};
