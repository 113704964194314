export const toBlob = (base64: string | null) => {
  if (!base64) {
    return false;
  }

  const bin = atob(base64);
  const buffer = new Uint8Array(bin.length);

  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  try {
    var blob = new Blob([buffer.buffer], { type: "image/png" });
  } catch (e) {
    return false;
  }

  return blob;
};

export const removeImagePrefix = (base64: string) => {
  return base64.substring(22);
};
