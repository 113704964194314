import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Accordion, AccordionSummary, AccordionDetails, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

interface Props {
  children: JSX.Element;

  observer?: (instance: HTMLElement) => void;
}

export const Condition = (props: Props) => {
  const classes = useStyles();

  const [open, setopen] = useState<boolean>(true);

  return (
    <Accordion ref={props.observer} expanded={open} onChange={() => setopen((value) => !value)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>検索条件</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className={classes.root}>{props.children}</Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(Condition);
