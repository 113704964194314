import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VirtualaizedTable, { ColumnData, EditType } from "Common/Component/VirtualizedTable";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";
import { useExecuteEx, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { LineupDetail, LineupSummary } from "Models/Lineup";
import { useInputManager } from "Common/Utility/HandleUtility";
import { ModelMaster } from "Models/ModelMaster";
import { modelColumns } from "Common/Utility/Constants";
import { useHoldInput } from "Hooks/useHoldInput";
import { CallOnClose, InputPending, onCloseWithSave } from "Common/Utility/AppUtility";

const useStyles = makeStyles((theme) => ({
  editPaper: {
    width: "100%",
    height: 400,
  },
  loading: {
    width: "100%",
    height: 400,
  },
}));

interface EditDialogProps extends InputPending {
  open: boolean;
  lineupSummary: LineupSummary;
  isEdit: boolean;
}

export const EditDialog = React.memo((props: EditDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [edited, confirm] = useWhetherEdited(props, props.isEdit);

  const [lineupSummary, setLineupSummary] = useState<LineupSummary>({ ...props.lineupSummary });

  const [details, setDetails] = useState<LineupDetail[]>([...props.lineupSummary.details]);

  const inputManager = useInputManager(setLineupSummary, edited);

  const [open, setOpen] = useState<boolean>(false);

  const [modelMaster, setModelMaster] = useState<ModelMaster[]>([]);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { lineup: LineupSummary; details: LineupDetail[]; onClose: CallOnClose }
      ) => {
        object.lineup.details = object.details;
        var response = await callWebApi().put<LineupSummary>("/lineups", object.lineup);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({ type: "success", message: "商品情報を保存しました。" });

        if (unmounted.value) {
          return;
        }

        if (response.data == null) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickAdd = useCallback(() => setOpen(true), []);

  const handleOnClickDelete = useCallback(
    async (modelMaster: ModelMaster, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setDetails((value) => {
        value.splice(rowIndex, 1);
        return [...value];
      });
    },
    []
  );

  const loading = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        let response = await callWebApi().get<ModelMaster[]>("/modelmaster", { cancelToken: signal.token });
        setModelMaster(response.data);
      }, [])
    )
  );

  const handleOnClickNarrowDown = useNarrowDown(modelMaster, "model", "name");

  const handleOnClickHoldInAction = useHoldInput(
    "商品",
    <EditDialog open={true} lineupSummary={lineupSummary} onClose={props.onClose} isEdit={true} />,
    props.onClose,
    props.onHold,
    () => {
      lineupSummary.details = [...details];
    }
  );

  const columns = useMemo(() => {
    const columns: ColumnData[] = [];

    if (props.isEdit) {
      columns.push({
        width: 80,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        editType: EditType.DeleteButton,
      });
    }

    columns.push(
      {
        width: 200,
        label: "型式",
        dataKey: "model",
        headerAlign: "center",
        bodyAlign: "left",
      },
      {
        width: 300,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      }
    );

    return columns;
  }, [props.isEdit]);

  return (
    <>
      {loading ?? (
        <>
          <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={true} maxWidth="sm">
            <DialogTitle>商品情報</DialogTitle>
            <DialogContent>
              <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    className={genericClasses.width100percent}
                    label="商品名"
                    value={lineupSummary.name}
                    onChange={inputManager.handleOnChange("name")}
                    disabled={!props.isEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.editPaper}>
                    <VirtualaizedTable
                      values={details}
                      setValues={setDetails}
                      rowHeight={48}
                      columns={columns}
                      onClickAdd={props.isEdit ? handleOnClickAdd : undefined}
                      onClickDelete={handleOnClickDelete}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {props.isEdit && (
                <Button
                  className={genericClasses.margin}
                  onClick={handleOnClickHoldInAction}
                  color="primary"
                  disabled={!props.open || inProcess}
                >
                  保留
                </Button>
              )}
              <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
                {props.isEdit ? "キャンセル" : "閉じる"}
              </Button>
              {props.isEdit && (
                <Button
                  className={genericClasses.margin}
                  onClick={() => {
                    if (!lineupSummary.name) {
                      alertAdd({ type: "info", message: "商品名は必須項目です。" });
                      return;
                    }
                    executePut({ lineup: lineupSummary, details: details, onClose: props.onClose });
                  }}
                  color="primary"
                  disabled={!props.open || inProcess}
                >
                  保存
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <SelectDialog
            title="型式"
            open={open}
            columns={modelColumns}
            data={modelMaster}
            onClose={(modelMaster?: ModelMaster) => {
              if (modelMaster) {
                setDetails((value) => {
                  if (modelMaster.id == null) {
                    return value;
                  }
                  value.push({
                    modelMasterId: modelMaster.id,
                    model: modelMaster.model,
                    name: modelMaster.name,
                    cost: modelMaster.cost ?? 0,
                  });
                  return [...value];
                });
              }
              setOpen(false);
            }}
            onClickNarrowDown={handleOnClickNarrowDown}
          />
        </>
      )}
    </>
  );
});
