import React, { useCallback, useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { useExecuteEx } from "Hooks/useFetch";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { Contact } from "Models/Contact";
import { IdName } from "Models/IdName";

const useStyles = makeStyles((theme) => ({
  selectCustomer: {
    width: 300,
    margin: theme.spacing(1),
    borderBottom: "solid 1px #949494",
  },
  roomName: {
    width: 300,
    margin: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface AddRoomInfo {
  customerId: string;

  customerName: string;

  roomName?: string;
}

interface AddRoomDialogProps {
  open: boolean;

  onClose: (result?: Contact) => void;

  partners: IdName[];
}

const AddRoomDialog = (props: AddRoomDialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [addRoomInfo, setAddRoomInfo] = useState<AddRoomInfo>({
    customerId: "",
    roomName: "",
    customerName: "",
  });

  const [edited, confirm] = useWhetherEdited(props.open);

  const inputManager = useInputManager(setAddRoomInfo, edited);

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { addRoomInfo: AddRoomInfo; onClose: (result?: Contact) => void }
      ) => {
        if (!object.addRoomInfo.customerId) {
          alertAdd({ type: "info", message: "お客様名は必須項目です。" });
          return;
        }

        const response = await callWebApi().post<Contact>("/contacts/from", {
          customerId: object.addRoomInfo.customerId,
          roomName: object.addRoomInfo.roomName,
        });

        alertAdd({ type: "success", message: "チャットルームを作成しました。" });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickCreateRoom = useCallback(() => {
    executePost({ addRoomInfo: addRoomInfo, onClose: props.onClose });
  }, [props, addRoomInfo, executePost]);

  useEffect(() => {
    if (props.open) {
      setAddRoomInfo({
        customerId: "",
        roomName: "",
        customerName: "",
      });
    }
  }, [props.open]);

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "お客様名",
        dataKey: "tenantName",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={false} maxWidth="sm">
      <DialogTitle>チャットルームの作成</DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid>
            <LabelWithSelect
              className={classes.selectCustomer}
              caption="お客様名"
              text={addRoomInfo.customerName}
              data={props.partners}
              columns={columns}
              onClickNarrowDown={useNarrowDown(props.partners, "tenantName")}
              onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                return { ...value, customerId: result.tenantId, customerName: result.tenantName };
              })}
              maxWidth="sm"
            />
            <TextField
              className={classes.roomName}
              label="ルーム名"
              value={addRoomInfo.roomName}
              onChange={inputManager.handleOnChange("roomName")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
          キャンセル
        </Button>
        <Button
          className={genericClasses.margin}
          onClick={handleOnClickCreateRoom}
          color="primary"
          disabled={!props.open || inProcess || addRoomInfo.customerId == null || addRoomInfo.customerId === ""}
        >
          作成
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddRoomDialog);
