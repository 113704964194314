import { useAlertAdd } from "Common/Component/AlertList";
import { callWebApi } from "Common/Utility/Api";
import { AttachmentFile } from "Models/AttachmentFile";
import React, { useCallback, useRef, useState } from "react";
import { useExecute } from "./useFetch";
import { useMessageBox } from "./useMessageBox";

export const useFileUpload = () => {
  const alertAdd = useAlertAdd();

  const [files, setFiles] = useState<AttachmentFile[]>([]);

  const refAttachedId = useRef<{ value: string | null }>({ value: null });

  const inputRef = useRef<HTMLInputElement>(null);

  const downloadLink = useRef<HTMLAnchorElement>(null);

  const selectFiles = useCallback((attachedId: string) => {
    refAttachedId.current.value = attachedId;
    inputRef.current?.click();
  }, []);

  const uploadFile = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { attachedId: string; fileList: FileList }) => {
      refAttachedId.current.value = null;

      const config = { headers: { "content-type": "multipart/form-data" } };

      const file = object.fileList.item(0);
      if (file) {
        const formData = new FormData();
        formData.append("attachedId", object.attachedId);
        formData.append("file", file);
        const response = await callWebApi().post<AttachmentFile>("/attachmentfile", formData, config);

        if (unmounted.value) {
          return;
        }

        setFiles((value) => {
          response.data.checked = false;
          return [...value, response.data];
        });

        if (inputRef.current !== null) {
          inputRef.current.value = "";
        }
      }
    }, [])
  );

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files?.length > 0 && refAttachedId.current.value != null) {
      const fileList: FileList = event.currentTarget.files;
      uploadFile({ attachedId: refAttachedId.current.value, fileList: fileList });
    }
  };

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string }) => {
        await callWebApi().delete("/attachmentfile", {
          params: {
            id: object.id,
          },
        });

        if (unmounted.value) {
          return;
        }

        setFiles((value) => {
          return value.filter((i) => i.id !== object.id);
        });
      },
      [setFiles]
    )
  );

  const message = useMessageBox();

  const deleteFile = useCallback(
    async (attachedId: string) => {
      if (await message.confirm("削除確認", "ファイルを削除します。よろしいですか？")) {
        executeDelete({ id: attachedId });
      }
    },
    [message, executeDelete]
  );

  const executeDownload = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { downloadIdList: AttachmentFile[] }) => {
        for (let i = 0; i < object.downloadIdList.length; i++) {
          if (object.downloadIdList[i].checked) {
            const response = await callWebApi().get("/attachmentfile/download", {
              params: {
                id: object.downloadIdList[i].id,
              },
              responseType: "blob",
            });

            if (unmounted.value) {
              return;
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            if (downloadLink.current) {
              downloadLink.current.href = url;
              downloadLink.current.setAttribute("download", object.downloadIdList[i].name);
              downloadLink.current.click();
            } else {
              alertAdd({ type: "warning", message: "ダウンロードに失敗しました。" });
            }
          }
        }
      },
      [alertAdd]
    )
  );

  const downloadFile = useCallback(() => {
    executeDownload({ downloadIdList: files });
  }, [executeDownload, files]);

  return {
    files,
    setFiles,
    downloadFile,
    deleteFile,
    selectFiles,
    inputTag: (
      <>
        <input hidden ref={inputRef} type="file" onChange={onFileInputChange} />
        <a href="#dummy" style={{ display: "none" }} ref={downloadLink}>
          DownloadLink
        </a>
      </>
    ),
  };
};
