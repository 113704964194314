import { TextFieldProps } from "@material-ui/core";
import React, { useCallback } from "react";
import TextField from "Component/TextField";

interface ExtendProps {
  maxLine: number;
}

const MultilineTextField = (props: TextFieldProps & ExtendProps) => {
  const { onChange, maxLine, ...other } = props;

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      const lines = event.target.value.split("\n");
      event.target.value = lines.slice(0, maxLine).join("\n");
      onChange?.(event);
    },
    [maxLine, onChange]
  );

  return <TextField multiline rows={maxLine} rowsMax={maxLine} {...other} onChange={handleOnChange} />;
};

export default React.memo(MultilineTextField);
