import React, { useCallback, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import Condition from "Component/Condition";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useMessageBox } from "Hooks/useMessageBox";
import { useGenericStyles } from "Common/Utility/Styles";
import { CancelTokenSource } from "axios";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useExecute, useFetch } from "Hooks/useFetch";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { useContentHeight } from "Hooks/useResize";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import TextField from "Component/TextField";
import { IndexRulelDialog } from "./IndexRuleDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { CloudDownload, CloudUpload, Delete } from "@material-ui/icons";
import { IndexRule, RuleFileInfo } from "Models/IndexRule";
import { DateUtility } from "Common/Utility/DateUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useColumnControl } from "Hooks/useColumnControl";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  document: {
    width: "100%",
    height: Design.componentUnitHeight,
    paddingLeft: Design.margin,
  },
}));

interface IndexRuleCondition {
  name: string;
}

export const IndexRulePanel = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin + 44);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const [condition, setCondition] = useState<IndexRuleCondition>({ name: "" });

  const [data, setData] = useState<IndexRule[]>([]);

  const [ruleFileInfo, setRuleFileInfo] = useState<RuleFileInfo | undefined>(undefined);

  const [editData, setEditData] = useState<IndexRule | undefined>(undefined);

  const [search, setSearch] = useState<IndexRuleCondition>({ name: "" });

  const inputManager = useInputManager(setCondition);

  const inputRef = useRef<HTMLInputElement>(null);

  const downloadLink = useRef<HTMLAnchorElement>(null);

  const alertAdd = useAlertAdd();

  const fetchResultLoad = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get("/indexrules", {
          cancelToken: signal.token,
          params: { name: search.name },
        });

        if (response.data == null) {
          return;
        }

        setData(response.data);
      },
      [search]
    ),
    false
  );

  const fetchRuleFileData = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      const response = await callWebApi().get("/indexrules/fileinfo", {
        cancelToken: signal.token,
      });

      if (response.data == null) {
        setRuleFileInfo(undefined);
        return;
      }

      setRuleFileInfo(response.data);
    }, []),
    true
  );

  const handleOnClickSearch = useCallback(async () => setSearch({ ...condition }), [condition]);

  const handleOnClickClear = useCallback(() => setCondition({ name: "" }), []);

  const handleOnClickAdd = useCallback(() => {
    setEditData({
      id: undefined,
      name: "",
      frontStatic: "",
      numberCount: 1,
      backStatic: "",
      viewIndexRule: "",
      currentIndex: 0,
      fillZero: true,
    });
  }, []);

  const handleOnClickEdit = useCallback(
    (modelMaster: IndexRule, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData({ ...modelMaster });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { indexRule: IndexRule; rowIndex: number }) => {
      await callWebApi().delete(`/indexrules/${object.indexRule.id}`, {});

      if (unmounted.value) {
        return;
      }

      setData((value) => {
        value.splice(object.rowIndex, 1);
        return [...value];
      });
    }, [])
  );

  const handleOnClickDelete = useCallback(
    async (indexRule: IndexRule, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (
        !(await message.confirm(
          "削除確認",
          "採番ルールを削除します。\n型式と紐づく場合は紐づきも解除されます。\nよろしいですか？"
        ))
      ) {
        return;
      }

      executeDelete({ indexRule: indexRule, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
      const fileList: FileList = event.currentTarget.files;
      execute({ fileList: fileList });
    }
  };

  const execute = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { fileList: FileList }) => {
      const config = { headers: { "content-type": "multipart/form-data" } };

      const file = object.fileList.item(0);
      if (file) {
        const formData = new FormData();
        formData.append("File", file);
        const response = await callWebApi().post("/indexrules", formData, config);

        if (unmounted.value) {
          return;
        }

        setRuleFileInfo(response.data);
      }

      if (inputRef.current !== null) {
        inputRef.current.value = "";
      }
    }, [])
  );

  const handleOnClickUpload = useCallback(async () => {
    if (ruleFileInfo != null) {
      if (
        !(await message.confirm(
          "削除確認",
          "既にファイルが登録されています。\n元のファイルを削除し、新たに登録しますか？"
        ))
      ) {
        return;
      }
    }
    inputRef.current?.click();
  }, [message, ruleFileInfo]);

  const executeDownload = useExecute(
    useCallback(async (unmounted: { value: boolean }, object: { id: string; name: string }) => {
      const response = await callWebApi().get("/indexrules/file", {
        params: { fileId: object.id },
        responseType: "blob",
      });

      if (unmounted.value) {
        return;
      }

      if (downloadLink.current) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadLink.current.href = url;
        downloadLink.current.setAttribute("download", object.name);
        downloadLink.current.click();
      }
    }, [])
  );

  const handleOnClickDownLoad = useCallback(() => {
    if (ruleFileInfo) {
      executeDownload({ id: ruleFileInfo.id, name: ruleFileInfo.name });
    }
  }, [ruleFileInfo, executeDownload]);

  const executeFileDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string }) => {
        await callWebApi().delete(`/indexrules/file/${object.id}`);

        alertAdd({ type: "info", message: "ファイルを削除しました。" });

        if (unmounted.value) {
          return;
        }

        setRuleFileInfo(undefined);
      },
      [alertAdd]
    )
  );

  const handleOnClickFileDelete = useCallback(async () => {
    if (ruleFileInfo == null) {
      return;
    }

    if (!(await message.confirm("削除確認", "ファイルを削除します。よろしいですか？"))) {
      return;
    }

    executeFileDelete({ id: ruleFileInfo.id });
  }, [ruleFileInfo, message, executeFileDelete]);

  const handleOnClose = useOnCloseEditDialog("Model", setEditData, setData, "id");

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.IndexRule);

  const columns: ColumnData[] = useMemo(() => {
    const columnData: ColumnData[] = [];
    if (isAssetMenuEditValid) {
      columnData.push(editButton, deleteButton);
    } else {
      columnData.push(referenceButton);
    }

    columnData.push(
      {
        width: 350,
        label: "名称",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(0),
      },
      {
        width: 500,
        label: "採番ルール",
        dataKey: "viewIndexRule",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 200,
        label: "現在のインデックス",
        dataKey: "currentIndex",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(2),
      }
    );

    return columnData;
  }, [isAssetMenuEditValid, getVisible]);

  return (
    <>
      <a href="#dummy" style={{ display: "none" }} ref={downloadLink}>
        DownloadLink
      </a>

      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                className={genericClasses.margin}
                label="名称"
                value={condition.name}
                onChange={inputManager.handleOnChange("name")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
              <Button className={genericClasses.margin} variant="outlined" onClick={handleOnClickClear}>
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.document, LoadingMode.Simple, fetchRuleFileData) ?? (
            <Paper className={classes.document}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Typography className={genericClasses.marginRight}>採番ルール参考資料</Typography>
                {isAssetMenuEditValid && (
                  <IconButton color="primary" aria-label="upload" onClick={handleOnClickUpload}>
                    <CloudUpload />
                  </IconButton>
                )}
                {ruleFileInfo && (
                  <>
                    <IconButton color="primary" aria-label="download" onClick={handleOnClickDownLoad}>
                      <CloudDownload />
                    </IconButton>
                    {isAssetMenuEditValid && (
                      <IconButton color="primary" aria-label="delete" onClick={handleOnClickFileDelete}>
                        <Delete />
                      </IconButton>
                    )}
                    <Typography className={genericClasses.marginLeft}>ファイル名：{ruleFileInfo.name}</Typography>
                    <Typography className={genericClasses.marginLeft}>
                      アップロード日：{DateUtility.format(ruleFileInfo.createdAt, "yyyy/MM/dd HH:mm:ss")}
                    </Typography>
                  </>
                )}
              </Grid>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12}>
          {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultLoad) ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={data}
                setValues={setData}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isAssetMenuEditValid ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
        {editData != null && <IndexRulelDialog open={true} data={editData} onClose={handleOnClose} />}
      </Grid>
      <input hidden ref={inputRef} type="file" onChange={onFileInputChange} />
    </>
  );
});
