import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from "@material-ui/core";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import { useExecuteEx } from "Hooks/useFetch";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { CallOnClose, InputPending, onCloseWithSave, useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { MenuIndex } from "Component/Menu";
import { useInputManager } from "Common/Utility/HandleUtility";
import DigitsField from "Component/DigitsField";
import TextField from "Component/TextField";
import DatePickersUtilsProvider from "Component/DatePickersUtilsProvider";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useHoldInput } from "Hooks/useHoldInput";
import { IndexRule } from "Models/IndexRule";

interface EditModelProps extends InputPending {
  open: boolean;
  data: IndexRule;
}

export const IndexRulelDialog = React.memo((props: EditModelProps) => {
  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const [data, setData] = useState(props.data);

  const isAssetMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Asset);

  const [edited, confirm] = useWhetherEdited(props, isAssetMenuEditValid);

  const inputManager = useInputManager(setData, edited);

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: IndexRule; onClose: CallOnClose }) => {
        var response = await callWebApi().put<IndexRule>("/indexrules", object.data);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (object.data.id === undefined) {
          alertAdd({ type: "success", message: "新規採番ルールを追加しました。" });
        } else {
          alertAdd({ type: "success", message: "採番ルールを変更しました。" });
        }

        if (unmounted.value) {
          return;
        }

        object.onClose(onCloseWithSave(response.data));
      },
      [alertAdd]
    )
  );

  const handleOnClickHoldInAction = useHoldInput(
    "採番ルール",
    <IndexRulelDialog open={true} data={data} onClose={props.onClose} />,
    props.onClose,
    props.onHold
  );

  const allowSave = useMemo(() => {
    return data.name && data.numberCount != null && data.numberCount > 0 && data.currentIndex != null;
  }, [data]);

  const handleOnClickSaveInAction = useCallback(async () => {
    executePut({ data: data, onClose: props.onClose });
  }, [props, data, executePut]);

  return (
    <Dialog onClose={() => confirm(() => props.onClose())} open={props.open} fullWidth={false} maxWidth="sm">
      <DialogTitle>採番ルール</DialogTitle>
      <DialogContent>
        <DatePickersUtilsProvider>
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={genericClasses.width100percent}
                label="名称"
                value={data.name}
                onChange={inputManager.handleOnChange("name")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                className={genericClasses.width100percent}
                label="採番ルール 固定部 前方"
                value={data.frontStatic}
                onChange={inputManager.handleOnChange("frontStatic")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={2}>
              <DigitsField
                className={genericClasses.width100percent}
                label="連番部桁数"
                value={data.numberCount}
                onChange={inputManager.handleOnChangeNumber("numberCount")}
                maxLength={1}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                className={genericClasses.width100percent}
                label="採番ルール 固定部 後方"
                value={data.backStatic}
                onChange={inputManager.handleOnChange("backStatic")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={5}>
              <DigitsField
                className={genericClasses.width100percent}
                label="現在のインデックス"
                value={data.currentIndex}
                onChange={inputManager.handleOnChangeNumber("currentIndex")}
                disabled={!isAssetMenuEditValid}
              />
            </Grid>
            <Grid item xs={7}>
              <Checkbox
                color="primary"
                checked={data.fillZero}
                onChange={inputManager.handleOnChangeCheck("fillZero")}
                disabled={!isAssetMenuEditValid}
              />
              連番の0埋め
            </Grid>
          </Grid>
        </DatePickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        {isAssetMenuEditValid ? (
          <>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickHoldInAction}
              color="primary"
              disabled={!props.open || inProcess}
            >
              保留
            </Button>
            <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
              キャンセル
            </Button>
            <Button
              className={genericClasses.margin}
              onClick={handleOnClickSaveInAction}
              color="primary"
              disabled={!props.open || inProcess || !allowSave}
            >
              保存
            </Button>
          </>
        ) : (
          <Button className={genericClasses.margin} onClick={() => confirm(() => props.onClose())} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
