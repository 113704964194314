import { AlertInfo } from "Common/Component/AlertList";
import { HttpExceptionMessage } from "./AppUtility";

export const HttpStatus = {
  Forbidden: 403,
  Conflict: 409,
} as const;
export type HttpStatus = typeof HttpStatus[keyof typeof HttpStatus];

export const validateResponse = (alertAdd: (alertInfo: AlertInfo | null) => void, response: any): boolean => {
  if (response == null || response.data == null || response.data.message == null) {
    return true;
  }

  switch (response.data.message) {
    case HttpExceptionMessage.TheTargetDoesNotExist:
    case HttpExceptionMessage.TheResourceDoesNotExist:
      alertAdd({ type: "info", message: "対象が存在しません。" });
      break;
    case HttpExceptionMessage.TheResourceHasBeenDeleted:
      alertAdd({ type: "info", message: "対象は削除されています。" });
      break;
    case HttpExceptionMessage.TheUpdateTargetDoesNotExist:
      alertAdd({ type: "warning", message: "更新対象が存在しない為、更新処理に失敗しました。" });
      break;
    case HttpExceptionMessage.TheUpdateTargetHasBeenRemoved:
      alertAdd({ type: "info", message: "更新対象は削除されています。" });
      break;
    case HttpExceptionMessage.TheUpdateDateHasBeenUpdated:
      alertAdd({
        type: "info",
        message: "更新対象は他者によって更新されています。最新の情報を読み込み再度更新してください。",
      });
      break;
    case HttpExceptionMessage.NotFoundFile:
      alertAdd({ type: "info", message: "ダウンロードファイルが存在しませんでした。" });
      break;
    case HttpExceptionMessage.TheTeamDoesNotExist:
      alertAdd({ type: "info", message: "チームが存在しませんでした。" });
      break;
    case HttpExceptionMessage.TheDateRangesOverlap:
      alertAdd({ type: "info", message: "貸出期間が重なっている為、保存ができませんでした。" });
      break;
    case HttpExceptionMessage.TimeOutByLock:
      alertAdd({ type: "info", message: "貸出期間の保存処理に失敗しました。" });
      break;
    case HttpExceptionMessage.ThisIsAnExternallyLinkedUser:
      alertAdd({ type: "info", message: "外部連携されたユーザーの為、削除できません。" });
      break;
    case HttpExceptionMessage.ThisIsNotAManagerAccount:
      alertAdd({ type: "error", message: "不正なアクセスです。" });
      break;
    default:
      alertAdd({ type: "info", message: response.data.message });
      break;
  }

  return false;
};
