import React, { useCallback, useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Grid } from "@material-ui/core";
import { useGenericStyles } from "Common/Utility/Styles";
import { useWhetherEdited } from "Hooks/useWhetherEdited";
import { useMessageBox } from "Hooks/useMessageBox";
import Label from "Component/Label";
import { AttachmentFileList } from "Component/AttachmentFile";
import { IntroductionFeeBillingRequest } from "Models/IntroductionFeeBillingRequest";
import { callWebApi } from "Common/Utility/Api";
import { useExecute, useExecuteEx, useFetch } from "Hooks/useFetch";
import { useInputManager } from "Common/Utility/HandleUtility";
import DigitsField from "Component/DigitsField";
import { useAlertAdd } from "Common/Component/AlertList";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { LabelWithSelect, useNarrowDown } from "Component/SelectDialog";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { ComboItem } from "Common/Utility/GenericInterface";
import { toComboText } from "Common/Utility/Constants";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { AppActionTypes, AppProvider } from "App";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  inEdit: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    margin: theme.spacing(1),
  },
  file: {
    height: 150,
  },
  withSelect: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    margin: theme.spacing(1),
    borderBottom: "solid 1px #949494",
  },
  loading: {
    height: 50,
  },
}));

interface DialogProps {
  open: boolean;
  voucherId: string;
  introductionFeeBillingRequest: IntroductionFeeBillingRequest;
  disabled: boolean;
  isPrevVoucher: boolean;
  onClose: (data: IntroductionFeeBillingRequest | null) => void;
}

const IntroductionFeeBillingRequestDialog = (props: DialogProps) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const alertAdd = useAlertAdd();

  const message = useMessageBox();

  const history = useHistory();

  const dispatch = AppProvider.useDispatch();

  const [edited, confirm] = useWhetherEdited(props.open);

  const [introductionFeeBillingRequest, setIntroductionFeeBillingRequest] = useState({
    fee: 0,
  } as IntroductionFeeBillingRequest);

  const [handleOnSaveAttachmentFiles, setHandleOnSaveAttachmentFiles] = useState({ func: (attachedId: string) => {} });

  const inputManager = useInputManager(setIntroductionFeeBillingRequest, edited);

  const executeVoucherPost = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          request: IntroductionFeeBillingRequest;
          onClose: (data: IntroductionFeeBillingRequest | null) => void;
        }
      ) => {
        const response = await callWebApi().post<IntroductionFeeBillingRequest>(
          "/voucher/introductionfeebillingrequest",
          object.request
        );

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        alertAdd({
          type: "success",
          message: `${object.request.vendorName}の紹介料請求依頼から伝票を作成しました。`,
        });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnClickTransitionOrCreate = () => {
    if (introductionFeeBillingRequest.voucherId) {
      dispatch({ type: AppActionTypes.SET_TRANSITION, value: introductionFeeBillingRequest.voucherId });
      history.push(getMenuInfo(MenuIndex.Voucher).path);
    } else {
      executeVoucherPost({
        request: introductionFeeBillingRequest,
        onClose: props.onClose,
      });
    }
  };

  const [executePost, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          voucherId: string;
          data: IntroductionFeeBillingRequest;
          handleOnSaveAttachmentFiles: (attachedId: string) => void;
          onClose: (data: IntroductionFeeBillingRequest | null) => void;
        }
      ) => {
        const response = await callWebApi().post<IntroductionFeeBillingRequest>("/introductionfeebillingrequests", {
          ...object.data,
          voucherId: object.voucherId,
        });

        if (!response.data) {
          return;
        }

        object.handleOnSaveAttachmentFiles(response.data.id as string);

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      []
    )
  );

  const handleOnClickSaveInAction = () => {
    if (!introductionFeeBillingRequest.toTenantId) {
      alertAdd({ type: "info", message: "紹介料請求依頼先は必須項目です。" });
      return;
    }

    if (introductionFeeBillingRequest.fee == null) {
      alertAdd({ type: "info", message: "紹介料は必須項目です。" });
      return;
    }

    executePost({
      voucherId: props.voucherId,
      data: introductionFeeBillingRequest,
      handleOnSaveAttachmentFiles: handleOnSaveAttachmentFiles.func,
      onClose: props.onClose,
    });
  };

  const executePut = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: { id: string; name: string; onClose: (data: IntroductionFeeBillingRequest | null) => void }
      ) => {
        const response = await callWebApi().put<IntroductionFeeBillingRequest>(
          "/introductionfeebillingrequests/disabledrequest",
          { id: object.id }
        );

        alertAdd({ type: "success", message: `${object.name}の紹介料請求依頼を無効にしました。` });

        if (unmounted.value) {
          return;
        }

        object.onClose(response.data);
      },
      [alertAdd]
    )
  );

  const handleOnRemove = async () => {
    if (await message.confirm("無効確認", "依頼を無効にしますか？")) {
      executePut({
        id: introductionFeeBillingRequest.id as string,
        name: introductionFeeBillingRequest.vendorName,
        onClose: props.onClose,
      });
    }
  };

  useEffect(() => {
    if (props.open) {
      setIntroductionFeeBillingRequest(props.introductionFeeBillingRequest);
    }
  }, [props.introductionFeeBillingRequest, props.open]);

  const isVoucherMenuEditValid = useIsValidMenuEditAuthority(MenuIndex.Voucher);

  const [toTenants, setToTenants] = useState<ComboItem[]>([]);

  const handleOnClickNarrowDown = useNarrowDown(toTenants, "text");

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "企業名",
        dataKey: "text",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Simple,
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/IntroductionFeeBillingRequests/to", {
          cancelToken: signal.token,
        });
        setToTenants(response.data);
      }, [])
    )
  );

  return (
    <Dialog onClose={() => confirm(() => props.onClose(null))} open={props.open} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Grid container justify="space-between">
          <Grid item>紹介料請求依頼</Grid>
          <Grid item>
            {!props.isPrevVoucher && (
              <Button
                color="primary"
                onClick={handleOnClickTransitionOrCreate}
                disabled={!introductionFeeBillingRequest.voucherId && !isVoucherMenuEditValid}
              >
                {introductionFeeBillingRequest.voucherId ? "伝票情報に遷移" : "伝票情報を作成"}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {props.isPrevVoucher &&
          (loadingElement ?? (
            <LabelWithSelect
              className={classes.withSelect}
              caption="紹介料請求依頼先"
              text={toComboText(toTenants, introductionFeeBillingRequest.toTenantId)}
              data={toTenants}
              columns={columns}
              onClickNarrowDown={handleOnClickNarrowDown}
              onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                return { ...value, toTenantId: result.value };
              })}
              maxWidth="sm"
              disabled={props.disabled}
            />
          ))}
        {!props.isPrevVoucher && (
          <Label className={classes.inEdit} caption="ベンダー" text={introductionFeeBillingRequest.vendorName} />
        )}
        <DigitsField
          className={classes.inEdit}
          label={props.isPrevVoucher ? "金額" : "紹介料"}
          value={
            props.disabled
              ? introductionFeeBillingRequest.fee.toLocaleString() + " 円"
              : introductionFeeBillingRequest.fee ?? ""
          }
          onChange={inputManager.handleOnChangeNumber("fee")}
          disabled={props.disabled}
        />
        <TextField
          className={classes.inEdit}
          label="メモ"
          multiline
          rows={5}
          value={introductionFeeBillingRequest.note ?? ""}
          onChange={inputManager.handleOnChange("note")}
          disabled={props.disabled}
        />
        <Paper className={classes.file}>
          <AttachmentFileList
            maxHeight={150}
            attachedId={introductionFeeBillingRequest.id ?? ""}
            category={false}
            editable={false}
            isAdd={!props.disabled}
            isUpdate={false}
            isDelete={!props.disabled}
            hold={true}
            setOnSave={setHandleOnSaveAttachmentFiles}
            edited={edited}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            {!props.isPrevVoucher && (
              <Button
                className={genericClasses.margin}
                onClick={handleOnRemove}
                color="primary"
                disabled={!isVoucherMenuEditValid}
              >
                依頼を無効
              </Button>
            )}
          </Grid>
          <Grid item>
            {props.disabled ? (
              <Button
                className={genericClasses.margin}
                onClick={() => confirm(() => props.onClose(null))}
                color="primary"
              >
                閉じる
              </Button>
            ) : (
              <>
                <Button
                  className={genericClasses.margin}
                  onClick={() => confirm(() => props.onClose(null))}
                  color="primary"
                >
                  キャンセル
                </Button>
                <Button
                  className={genericClasses.margin}
                  onClick={handleOnClickSaveInAction}
                  color="primary"
                  disabled={!props.open || inProcess}
                >
                  保存
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(IntroductionFeeBillingRequestDialog);
