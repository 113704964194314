import React, { useCallback, useState } from "react";
import { Button, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { BusinessInfo } from "Models/BusinessInfo";
import GroupInBusiness from "./GroupInBusiness";
import ContentInBusiness from "./ContentInBusiness";
import ScheduleInBusiness from "./ScheduleInBusiness";
import OriginalVoucherInBusiness from "./OriginalVoucherInBusiness";
import VoucherInBusiness from "./VoucherInBusiness";
import DepositInBusiness from "./DepositInBusiness";
import { useGenericStyles } from "Common/Utility/Styles";
import { MenuIndex } from "Component/Menu";
import { useIsValidMenuReferenceAuthority } from "Common/Utility/AppUtility";
import ShipmentInBusiness from "./ShipmentInBusiness";
import { useExecuteEx } from "Hooks/useFetch";
import { callWebApi } from "Common/Utility/Api";
import { useAlertAdd } from "Common/Component/AlertList";
import { useMessageBox } from "Hooks/useMessageBox";
import { ChatStatus } from "Common/Utility/Constants";

export interface Props {
  setSummaries: React.Dispatch<React.SetStateAction<BusinessInfo[]>>;

  detail: BusinessInfo;

  setDetail: React.Dispatch<React.SetStateAction<BusinessInfo | null>>;
}

export const BusinessDetailTabIndex = {
  Business: 0, //業務
  Shipment: 1, //出荷
  Schedule: 2, //日程
  OriginalVoucher: 3, //元伝票
  Voucher: 4, //伝票
  Deposit: 5, //入金
} as const;
export type VoucherTabIndex = typeof BusinessDetailTabIndex[keyof typeof BusinessDetailTabIndex];

const BusinessDetail = (props: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const genericClasses = useGenericStyles();

  const isValidReferenceGroup = useIsValidMenuReferenceAuthority(MenuIndex.Group);

  const isValidReferenceShipment = useIsValidMenuReferenceAuthority(MenuIndex.Shipment);

  const isValidReferenceSchedule = useIsValidMenuReferenceAuthority(MenuIndex.Schedule);

  const isValidReferenceVoucher = useIsValidMenuReferenceAuthority(MenuIndex.Voucher);

  const isValidReferenceDeposit = useIsValidMenuReferenceAuthority(MenuIndex.Deposit);

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const handleOnChangeTabs = (event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  };

  const [executePut, inProcess] = useExecuteEx(
    useCallback(
      async (
        unmounted: { value: boolean },
        object: {
          businessInfo: BusinessInfo;
          setDetail: React.Dispatch<React.SetStateAction<BusinessInfo | null>>;
          setSummaries: React.Dispatch<React.SetStateAction<BusinessInfo[]>>;
        }
      ) => {
        await callWebApi().put("/Contacts/finish", object.businessInfo);

        alertAdd({ type: "success", message: "受付を終了状態にしました。" });

        if (unmounted.value) {
          return;
        }

        object.setDetail((value) => {
          if (value) {
            value.chatStatus = ChatStatus.Finished;
            return { ...value };
          } else {
            return value;
          }
        });

        object.setSummaries((value) => {
          var index = value.findIndex((i) => i.id === object.businessInfo.id);
          if (index < 0) {
            return value;
          } else {
            value[index].chatStatus = ChatStatus.Finished;
            return [...value];
          }
        });
      },
      [alertAdd]
    )
  );

  const handleOnClick = async () => {
    if (await message.confirm("受付終了", "受付を終了状態にします。よろしいですか？")) {
      executePut({ businessInfo: props.detail, setDetail: props.setDetail, setSummaries: props.setSummaries });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            className={genericClasses.marginLeft}
            variant="contained"
            color="primary"
            onClick={() => props.setDetail(null)}
            disabled={inProcess}
          >
            一覧へ
          </Button>
          {props.detail.chatStatus != null && (
            <Button
              className={genericClasses.marginLeft}
              variant="contained"
              color="primary"
              onClick={handleOnClick}
              disabled={inProcess || props.detail.chatStatus === ChatStatus.Finished}
            >
              受付終了
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper square>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleOnChangeTabs}
              aria-label="tabs"
            >
              <Tab label="工事・業務" disabled={!isValidReferenceGroup} />
              <Tab label="出荷" disabled={!isValidReferenceShipment} />
              <Tab label="日程" disabled={!isValidReferenceSchedule} />
              <Tab label="元伝票" disabled={!isValidReferenceVoucher} />
              <Tab label="伝票" disabled={!isValidReferenceVoucher} />
              <Tab label="入金" disabled={!isValidReferenceDeposit} />
            </Tabs>
          </Paper>
        </Grid>
        {isValidReferenceGroup && tabIndex === BusinessDetailTabIndex.Business && (
          <>
            <Grid item xs={12}>
              <GroupInBusiness {...props} />
            </Grid>
            <Grid item xs={12}>
              <ContentInBusiness {...props} />
            </Grid>
          </>
        )}
        {isValidReferenceShipment && tabIndex === BusinessDetailTabIndex.Shipment && (
          <Grid item xs={12}>
            <ShipmentInBusiness {...props} />
          </Grid>
        )}
        {isValidReferenceSchedule && tabIndex === BusinessDetailTabIndex.Schedule && (
          <Grid item xs={12}>
            <ScheduleInBusiness {...props} />
          </Grid>
        )}
        {isValidReferenceVoucher && tabIndex === BusinessDetailTabIndex.OriginalVoucher && (
          <Grid item xs={12}>
            <OriginalVoucherInBusiness {...props} />
          </Grid>
        )}
        {isValidReferenceVoucher && tabIndex === BusinessDetailTabIndex.Voucher && (
          <Grid item xs={12}>
            <VoucherInBusiness {...props} />
          </Grid>
        )}
        {isValidReferenceDeposit && tabIndex === BusinessDetailTabIndex.Deposit && (
          <Grid item xs={12}>
            <DepositInBusiness {...props} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(BusinessDetail);
