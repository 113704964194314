import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CancelTokenSource } from "axios";
import { useAlertAdd } from "Common/Component/AlertList";
import VirtualizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import {
  CallOnClose,
  deleteButton,
  editButton,
  OnClose,
  referenceButton,
  useIsValidMenuEditAuthority,
} from "Common/Utility/AppUtility";
import { Design, VisibleColumn } from "Common/Utility/Constants";
import { ComboItem } from "Common/Utility/GenericInterface";
import { useInputManager } from "Common/Utility/HandleUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import Condition from "Component/Condition";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { MenuIndex } from "Component/Menu";
import TextField from "Component/TextField";
import { useColumnControl } from "Hooks/useColumnControl";
import { useExecute, useFetch } from "Hooks/useFetch";
import { useMessageBox } from "Hooks/useMessageBox";
import { useContentHeight } from "Hooks/useResize";
import { WorkTaskTemplate } from "Models/WorkTaskTemplate";
import { EditTemplateDialog } from "Pages/Schedule/WorkTaskTemplatePanel/EditWorkTaskTemplateDialog";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 500,
  },
  dialogPaper2: {
    height: 300,
  },
  loading: {
    width: "100%",
    height: (props: any) => props.height,
    minHeight: 300,
  },
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  text: {
    width: 200,
    marginRight: Design.margin,
  },
}));

interface Condition {
  name: string;
}

const initialCondition: Condition = {
  name: "",
};

const initialTemplateListData: () => WorkTaskTemplate = () => {
  return {
    id: null,
    name: "",
    tasks: [],
    updatedAt: null,
  };
};

export const WorkTaskTemplatePanel = React.memo(() => {
  const [height, conditionRef] = useContentHeight(Design.componentUnitHeight + Design.margin);

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Schedule);

  const [userMaster, setUserMaster] = useState<ComboItem[]>([]);

  const [rows, setRows] = useState<WorkTaskTemplate[]>([]);

  const [editData, setEditData] = useState<WorkTaskTemplate | undefined>(undefined);

  const [search, setSearch] = useState<Condition>(initialCondition);

  const [condition, setCondition] = useState<Condition>(initialCondition);

  const inputManager = useInputManager(setCondition);

  const loadingElement = useLoadingElement(
    classes.loading,
    LoadingMode.Circular,
    useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<WorkTaskTemplate[]>("/workTaskTemplates", {
            cancelToken: signal.token,
            params: search,
          });

          if (response.data == null) {
            return;
          }

          setRows(response.data);
        },
        [search]
      ),
      false
    ),
    useFetch(
      useCallback(async (signal: CancelTokenSource) => {
        var response = await callWebApi().get<ComboItem[]>("/users/usermaster", { cancelToken: signal.token });

        if (response.data == null) {
          return;
        }

        setUserMaster(response.data);
      }, [])
    )
  );

  const handleOnClickAdd = useCallback(() => {
    setEditData(initialTemplateListData);
  }, []);

  const handleOnClickEdit = useCallback(
    (data: WorkTaskTemplate, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setEditData((value) => {
        data.tasks.forEach((value) => {
          value.members = [...value.members];
        });
        return { ...data, tasks: [...data.tasks] };
      });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { data: WorkTaskTemplate; rowIndex: number }) => {
        await callWebApi().delete("/workTaskTemplates", { params: { id: object.data.id } });

        if (unmounted.value) {
          return;
        }

        alertAdd({ type: "success", message: "テンプレート情報を削除しました。" });

        setRows((values) => {
          values.splice(object.rowIndex, 1);
          return [...values];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (data: WorkTaskTemplate, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (await message.confirm("削除確認", "テンプレート情報を削除します、よろしいですか？")) {
        executeDelete({ data: data, rowIndex: rowIndex });
      }
    },
    [message, executeDelete]
  );

  const handleOnClose: CallOnClose = (args?: { onClose: OnClose; key?: string }) => {
    args?.onClose(setRows, "id");
    setEditData(undefined);
  };

  const convertTasks = useCallback((data: any, rowData: WorkTaskTemplate) => {
    let ret = "";

    rowData.tasks.forEach((value) => {
      if (ret === "") {
        ret += value.name;
      } else {
        ret += ", " + value.name;
      }
    });

    return ret;
  }, []);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(
    VisibleColumn.WorkTaskTemplate
  );

  const columns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [];

    if (isValidEdit) {
      columns.push(editButton, deleteButton);
    } else {
      columns.push(referenceButton);
    }

    columns.push(
      {
        width: 300,
        label: "テンプレート名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(0),
      },
      {
        width: 300,
        label: "タスク名",
        dataKey: "",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        convert: convertTasks,
        visible: getVisible(1),
      }
    );

    return columns;
  }, [isValidEdit, convertTasks, getVisible]);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={conditionRef}>
            <>
              <TextField
                label="テンプレート名"
                className={classes.text}
                value={condition.name}
                onChange={inputManager.handleOnChange("name")}
              />
              <Button
                className={genericClasses.margin}
                variant="contained"
                color="primary"
                onClick={() => setSearch({ ...condition })}
              >
                検索
              </Button>
              <Button
                className={genericClasses.margin}
                variant="outlined"
                onClick={() => setCondition({ ...initialCondition })}
              >
                クリア
              </Button>
            </>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualizedTable
                values={rows}
                setValues={setRows}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      {editData && (
        <EditTemplateDialog
          open={true}
          editAuth={isValidEdit}
          data={editData}
          userMaster={userMaster}
          onClose={handleOnClose}
        />
      )}
    </>
  );
});
