import { Button, Grid, Paper } from "@material-ui/core";
import Condition from "Component/Condition";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContentHeight } from "Hooks/useResize";
import VirtualaizedTable, { ColumnData } from "Common/Component/VirtualizedTable";
import { Construction, ConstructionAddress, Group, Site, Survey, User } from "Models/Group";
import { SelectDialog, validateNarrowDownKey } from "Component/SelectDialog";
import { BusinessInfo } from "Models/BusinessInfo";
import { AppActionTypes, AppProvider } from "App";
import { useHistory } from "react-router-dom";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import { useExecute, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi, useSimpleFetch } from "Common/Utility/Api";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { useMessageBox } from "Hooks/useMessageBox";
import { deleteButton, editButton, referenceButton, useIsValidMenuEditAuthority, formatBusinessNumberFromBusinessInfo } from "Common/Utility/AppUtility";
import { useAlertAdd } from "Common/Component/AlertList";
import { useGenericStyles } from "Common/Utility/Styles";
import TextField from "Component/TextField";
import { validateResponse } from "Common/Utility/HttpUtility";
import { EditDialog } from "./EditDialog";
import { useOnCloseEditDialog } from "Hooks/useOnCloseEditDialog";
import { VisibleColumn } from "Common/Utility/Constants";
import { useColumnControl } from "Hooks/useColumnControl";
import { DateTime, DateUtility } from "Common/Utility/DateUtility";
import Period from "Component/Period";
import { useInputManager } from "Common/Utility/HandleUtility";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: (props: any) => props.height,
  },
  searchLoading: {
    height: (props: any) => props.height,
  },
}));

interface Condition {
  id?: string;
  constructionNumber?: string;
  name?: string;
  note?: string;
  from?: DateTime;
  to?: DateTime;
}

function initialCondition(): Condition {
  return {
    from: DateUtility.addDate(DateUtility.now(), 0, -1, 0),
    to: DateUtility.format(DateUtility.now(), "yyyy/MM/dd"),
  };
}

export default React.memo(() => {
  const [height, observer] = useContentHeight();

  const classes = useStyles({ height: height });

  const genericClasses = useGenericStyles();

  const appDispatch = AppProvider.useDispatch();

  const history = useHistory();

  const message = useMessageBox();

  const alertAdd = useAlertAdd();

  const [groups, setGroups] = useState<Group[]>([]);

  const transition = AppProvider.useGlobalState("transition");

  const [condition, setCondition] = useState<Condition>(initialCondition());

  const inputManager = useInputManager(setCondition);

  const [search, setSearch] = useState<Condition>(initialCondition());

  const [group, setGroup] = useState<Group | undefined>(undefined);

  const [groupId, setGroupId] = useState<{ id: string | null }>({ id: null });

  const [openRecept, setOpenRecept] = useState<boolean>(false);

  const [recepts, setRecepts] = useState<BusinessInfo[]>([]);

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  const loadingElement = useLoadingElement(
    classes.searchLoading,
    LoadingMode.Circular,
    useSimpleFetch("/group", setGroups, true, DateUtility.InvalidToNull(search, "from", "to"), [search])
  );

  const handleOnClickAdd = useCallback(() => {
    setGroup({
      id: null,
      constructionNumber: "",
      name: "",
      note: "",
      sites: [] as Site[],
      constructionAddress: [] as ConstructionAddress[],
      salesStaff: [] as User[],
      operation: [] as User[],
      survey: [] as Survey[],
      constructions: [] as Construction[],
    } as Group);
  }, []);

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (selectedGroup == null) {
          return;
        }

        const response = await callWebApi().get<BusinessInfo[]>(`/business/group/${selectedGroup.id}`, {
          cancelToken: signal.token,
        });

        if (response.data == null) {
          return;
        }

        setRecepts(response.data);

        setOpenRecept(true);
      },
      [selectedGroup]
    ),
    false
  );

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        if (groupId.id == null) {
          return;
        }

        const response = await callWebApi().get<Group>(`/group/${groupId.id}`, {
          cancelToken: signal.token,
        });

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (response.data == null) {
          return;
        }

        setGroup(response.data);
      },
      [groupId, alertAdd]
    ),
    false
  );

  const handleOnClickRecept = useCallback((data: Group) => {
    return () => {
      setSelectedGroup(data);
    };
  }, []);

  const handleOnClose = useOnCloseEditDialog("Group", setGroup, setGroups, "id");

  const handleOnClickEdit = useCallback(
    (group: Group, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      setGroupId({ id: group.id });
    },
    []
  );

  const executeDelete = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { id: string | null; rowIndex: number }) => {
        if (object.id == null) {
          return;
        }

        await callWebApi().delete(`/group/${object.id}`);

        alertAdd({ type: "info", message: "工事情報を削除しました。" });

        if (unmounted.value) {
          return;
        }

        setGroups((value) => {
          value.splice(object.rowIndex, 1);
          return [...value];
        });
      },
      [alertAdd]
    )
  );

  const handleOnClickDelete = useCallback(
    async (group: Group, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (!(await message.confirm("削除確認", "工事情報を削除します。よろしいですか？"))) {
        return;
      }

      executeDelete({ id: group.id, rowIndex: rowIndex });
    },
    [message, executeDelete]
  );

  const handleOnCloseRecept = useCallback(
    (result: BusinessInfo | null) => {
      if (result) {
        appDispatch({ type: AppActionTypes.SET_TRANSITION, value: result.id });
        history.push(getMenuInfo(MenuIndex.Business).path);
      }
      setSelectedGroup(null);

      setOpenRecept(false);
    },
    [appDispatch, history]
  );

  useEffect(() => {
    if (transition != null) {
      setSearch({
        id: transition,
      });
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: null });
    }
  }, [transition, appDispatch]);

  const handleOnClickNarrowDown = useCallback(
    (text: string) => {
      const split = validateNarrowDownKey(text);
      if (split.length === 0) {
        return recepts;
      }

      return recepts.filter(
        (i) =>
          split.findIndex((find) => {
            return i.customerName.indexOf(find) !== -1 ||
                   formatBusinessNumberFromBusinessInfo(i).indexOf(find) !== -1 ||
                   DateUtility.format(i.createdAt, "yyyy年 MM月dd日 HH時 mm分").indexOf(find) !== -1;
          }) !== -1
      );
    },
    [recepts]
  );

  const isValidEdit = useIsValidMenuEditAuthority(MenuIndex.Group);

  const [getVisible, handleOnCloseContextMenu, handleOnChangeHeaderVisible] = useColumnControl(VisibleColumn.Group);

  const columns: ColumnData[] = useMemo(() => {
    const ret: ColumnData[] = [];
    if (isValidEdit) {
      ret.push(editButton, deleteButton);
    } else {
      ret.push(referenceButton);
    }

    ret.push(
      {
        width: 200,
        label: "工事番号",
        dataKey: "constructionNumber",
        headerAlign: "center",
        bodyAlign: "center",
        visible: getVisible(0),
      },
      {
        width: 350,
        label: "工事名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        visible: getVisible(1),
      },
      {
        width: 350,
        label: "備考",
        dataKey: "note",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
        visible: getVisible(2),
      },
      {
        width: 350,
        label: "作成日",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy年 MM月dd日 HH時 mm分"),
        visible: getVisible(3),
      },
      {
        width: 100,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        component: (data: Group, rowIndex: number) => (
          <Button variant="contained" color="primary" onClick={handleOnClickRecept(data)}>
            受注
          </Button>
        ),
      }
    );
    return ret;
  }, [handleOnClickRecept, isValidEdit, getVisible]);

  const receptColumns: ColumnData[] = useMemo(
    () => [
      {
        width: 200,
        label: "お客様名",
        dataKey: "customerName",
        headerAlign: "center",
        fit: true
      },
      {
        width: 300,
        label: "受注日時",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (data: any) => DateUtility.format(data, "yyyy年 MM月dd日 HH時 mm分"),
      },
      {
        width: 200,
        label: "受注番号",
        dataKey: "createdAt",
        headerAlign: "center",
        bodyAlign: "center",
        convert: (createdAt: Date, businessInfo: BusinessInfo) => formatBusinessNumberFromBusinessInfo(businessInfo),
      }
    ],
    []
  );

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Condition observer={observer}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item container direction="row" justify="flex-start" alignItems="center">
                <TextField
                  className={genericClasses.margin}
                  label="工事番号"
                  value={condition.constructionNumber}
                  onChange={inputManager.handleOnChange("constructionNumber")}
                />
                <TextField
                  className={genericClasses.margin}
                  label="工事名"
                  value={condition.name}
                  onChange={inputManager.handleOnChange("name")}
                />
                <TextField
                  className={genericClasses.margin}
                  label="備考"
                  value={condition.note}
                  onChange={inputManager.handleOnChange("note")}
                />
                <Period
                  label="作成日"
                  fromValue={condition.from}
                  toValue={condition.to}
                  onChangeFrom={inputManager.handleOnChangeDate("from")}
                  onChangeTo={inputManager.handleOnChangeDate("to")}
                />
                <Button
                  className={genericClasses.margin}
                  variant="contained"
                  color="primary"
                  onClick={() => setSearch({ ...condition })}
                >
                  検索
                </Button>
                <Button
                  className={genericClasses.margin}
                  variant="outlined"
                  onClick={() => setCondition(initialCondition())}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </Condition>
        </Grid>
        <Grid item xs={12}>
          {loadingElement ?? (
            <Paper className={classes.paper}>
              <VirtualaizedTable
                values={groups}
                setValues={setGroups}
                tableHeight={height}
                rowHeight={48}
                columns={columns}
                onClickAdd={isValidEdit ? handleOnClickAdd : undefined}
                onClickEdit={handleOnClickEdit}
                onClickReference={handleOnClickEdit}
                onClickDelete={handleOnClickDelete}
                onCloseContextMenu={handleOnCloseContextMenu}
                onChangeHeaderVisible={handleOnChangeHeaderVisible}
                headerContext
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <SelectDialog
        open={openRecept}
        maxWidth={"md"}
        title={"受注"}
        onClose={handleOnCloseRecept}
        data={recepts}
        columns={receptColumns}
        onClickNarrowDown={handleOnClickNarrowDown}
      />
      {group != null && <EditDialog open={true} group={group} onClose={handleOnClose} />}
    </>
  );
});
