import { Grid, IconButton, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { ArrowForward, Autorenew, Link, LinkOff } from "@material-ui/icons";
import { AppActionTypes, AppProvider } from "App";
import { CancelTokenSource } from "axios";
import { useAlertAdd } from "Common/Component/AlertList";
import { ColumnData } from "Common/Component/VirtualizedTable";
import { callWebApi } from "Common/Utility/Api";
import { useIsValidMenuEditAuthority } from "Common/Utility/AppUtility";
import { validateResponse } from "Common/Utility/HttpUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import Label from "Component/Label";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { getMenuInfo, MenuIndex } from "Component/Menu";
import { SelectDialog, useNarrowDown } from "Component/SelectDialog";
import { useExecute, useFetch } from "Hooks/useFetch";
import { BusinessInfo } from "Models/BusinessInfo";
import { Group } from "Models/Group";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Props } from "./BusinessDetail";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 64,
  },
}));

const GroupInBusiness = (props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const businessInfo = useMemo(() => props.detail, [props.detail]);

  const alertAdd = useAlertAdd();

  const [groups, setGroups] = useState<Group[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const appDispatch = AppProvider.useDispatch();

  const history = useHistory();

  const handleOnClick = useCallback(() => {
    setOpen(true);
  }, []);

  const setSummaries = useMemo(() => props.setSummaries, [props.setSummaries]);

  const setDetail = useMemo(() => props.setDetail, [props.setDetail]);

  const handleOnClickDetail = useCallback(() => {
    if (businessInfo.groupId) {
      appDispatch({ type: AppActionTypes.SET_TRANSITION, value: businessInfo.groupId });
      history.push(getMenuInfo(MenuIndex.Group).path);
    }
  }, [appDispatch, history, businessInfo.groupId]);

  const execute = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, object: { business: BusinessInfo; group: Group | null }) => {
        var business = { ...object.business };
        business.groupId = object.group ? object.group.id : null;

        var response = await callWebApi().put<BusinessInfo>("/business", business);

        if (!validateResponse(alertAdd, response)) {
          return;
        }

        if (object.group) {
          alertAdd({ type: "success", message: "工事情報と連携しました。" });
        } else {
          alertAdd({ type: "success", message: "工事情報を解除しました。" });
        }

        if (unmounted.value) {
          return;
        }

        setDetail(response.data);
        setSummaries((value) => {
          var summary = value.findIndex((i) => i.id === response.data.id);
          if (summary >= 0) {
            value[summary] = response.data;
            return [...value];
          } else {
            return value;
          }
        });

        setOpen(false);
      },
      [alertAdd, setDetail, setSummaries]
    )
  );

  const handleOnClickUnlink = useCallback(() => {
    execute({ business: businessInfo, group: null });
  }, [execute, businessInfo]);

  const handleOnClose = useCallback(
    (group: Group | null) => {
      if (group) {
        execute({ business: businessInfo, group: group });
      } else {
        setOpen(false);
      }
    },
    [execute, businessInfo]
  );

  const fetchResultLoad = useFetch(
    useCallback(async (signal: CancelTokenSource) => {
      const response = await callWebApi().get<Group[]>("/group", {
        cancelToken: signal.token,
      });

      if (response.data == null) {
        return;
      }

      setGroups(response.data);
    }, [])
  );

  const fetchGroup = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<BusinessInfo>(`/business/${businessInfo.id}`, {
          cancelToken: signal.token,
        });

        if (response.data == null) {
          return;
        }

        setDetail(response.data);
      },
      [businessInfo.id, setDetail]
    )
  );

  const handleOnClickReload = useCallback(() => fetchGroup.reload(), [fetchGroup]);

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 150,
        label: "工事番号",
        dataKey: "constructionNumber",
        headerAlign: "center",
        bodyAlign: "center",
      },
      {
        width: 300,
        label: "工事名",
        dataKey: "name",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  // 工事情報の編集ではなく受注に対してどの工事を割り当てるかなので受注の編集権限を参照する
  const isValidEditBusiness = useIsValidMenuEditAuthority(MenuIndex.Business);

  return (
    <>
      {useLoadingElement(classes.paper, LoadingMode.Simple, fetchResultLoad) ?? (
        <Paper>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Typography className={genericClasses.margin}>工事情報</Typography>
            <IconButton
              className={genericClasses.margin}
              color="primary"
              aria-label="udpate"
              onClick={handleOnClickReload}
            >
              <Autorenew />
            </IconButton>
            {props.detail.groupId ? (
              <>
                {isValidEditBusiness && (
                  <>
                    {props.detail.groupId ? (
                      <Tooltip title="アンリンク">
                        <IconButton className={genericClasses.margin} color="primary" onClick={handleOnClickUnlink}>
                          <LinkOff />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="リンク">
                        <IconButton className={genericClasses.margin} color="primary" onClick={handleOnClick}>
                          <Link />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
                <Tooltip title="工事情報詳細へ">
                  <IconButton className={genericClasses.margin} color="primary" onClick={handleOnClickDetail}>
                    <ArrowForward />
                  </IconButton>
                </Tooltip>
                <Label
                  className={genericClasses.margin}
                  caption="お客様の工事番号"
                  text={props.detail.constructionNumber}
                ></Label>
                <Label className={genericClasses.margin} caption="工事名" text={props.detail.constructionName}></Label>
              </>
            ) : (
              isValidEditBusiness && (
                <Tooltip title="リンク">
                  <IconButton className={genericClasses.margin} color="primary" onClick={handleOnClick}>
                    <Link />
                  </IconButton>
                </Tooltip>
              )
            )}
          </Grid>
        </Paper>
      )}
      <SelectDialog
        open={open}
        onClose={handleOnClose}
        onClickNarrowDown={useNarrowDown(groups, "constructionNumber", "name")}
        title="工事"
        data={groups}
        columns={columns}
        maxWidth="xs"
      />
    </>
  );
};

export default React.memo(GroupInBusiness);
