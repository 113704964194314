import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { useCallback } from "react";
import { callWebApi } from "Common/Utility/Api";
import { connect, IAction } from "Common/Utility/connect";
import { useFetch } from "Hooks/useFetch";
import { LoadingMode, useLoadingElement } from "Component/Loading";
import { CancelTokenSource } from "axios";
import { Account } from "Models/Account";
import { useContentHeight } from "Hooks/useResize";
import { ComboItem } from "Common/Utility/GenericInterface";
import { AppProvider } from "App";
import { UserManager } from "./UserPanel/UserPanel";
import { RequestPage } from "./RequestPanel/RequestPanel";

export enum ActionTypes {
  SET_ROLES = "SET_ROLES",
  SET_ACCOUNTS = "SET_ACCOUNTS",
}

const useStyles = makeStyles((theme) => ({
  loading: {
    height: (props: any) => props.height,
    width: "100%",
  },
  tabArea: {
    marginBottom: theme.spacing(1),
  },
}));

export default React.memo(() => {
  const [height] = useContentHeight(56);

  const classes = useStyles({ height: height });

  const dispatch = UserManagerProvider.useDispatch();

  const ralmAccount = AppProvider.useGlobalState("ralmAccount");

  const [tabIndex, setTabIndex] = useState(0);

  const panel = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <UserManager />;
      case 1:
        return <RequestPage />;
    }
  }, [tabIndex]);

  const handleOnChangeTabs = useCallback((event: React.ChangeEvent<{}>, index: number) => {
    setTabIndex(index);
  }, []);

  const fetchResultRole = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<ComboItem[]>("/roles/combo", { cancelToken: signal.token });
        dispatch({ type: ActionTypes.SET_ROLES, value: response.data });
      },
      [dispatch]
    )
  );

  const fetchResultAccounts = useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const response = await callWebApi().get<Account[]>(`/account/core/summary/${ralmAccount?.tenantId}`, {
          cancelToken: signal.token,
        });
        dispatch({ type: ActionTypes.SET_ACCOUNTS, value: response.data });
      },
      [dispatch, ralmAccount]
    )
  );

  return (
    <>
      {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResultRole, fetchResultAccounts) ?? (
        <>
          <Paper className={classes.tabArea} square>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleOnChangeTabs}
              aria-label="tabs"
            >
              <Tab label="一覧" />
              <Tab label="依頼" />
            </Tabs>
          </Paper>
          {panel}
        </>
      )}
    </>
  );
});

interface UserManagerType {
  roles: ComboItem[] | undefined;
  accounts: Account[] | undefined;
}

const initialState: UserManagerType = {
  roles: undefined,
  accounts: undefined,
};

function reducer(state: UserManagerType, action: IAction): UserManagerType {
  switch (action.type) {
    case ActionTypes.SET_ROLES:
      return { ...state, roles: [...action.value] };
    case ActionTypes.SET_ACCOUNTS:
      return { ...state, accounts: [...action.value] };
    default:
      return state;
  }
}

export const UserManagerProvider = connect<UserManagerType>(reducer, initialState);
