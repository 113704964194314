import { Box, Grid, IconButton, makeStyles, Paper, Tooltip, Button, Typography } from "@material-ui/core";
import { ArrowUpward, AttachFile } from "@material-ui/icons";
import { DateUtility } from "Common/Utility/DateUtility";
import { useGenericStyles } from "Common/Utility/Styles";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import clsx from "clsx";
import { AppProvider } from "App";
import { useExecute, useFetch } from "Hooks/useFetch";
import { CancelTokenSource } from "axios";
import { callWebApi } from "Common/Utility/Api";
import { Message } from "Models/Message";
import NewId from "Common/Utility/NewId";
import { LoadingMode, useLoadingElement } from "./Loading";
import { useMessageBox } from "Hooks/useMessageBox";
import { useHubConnection } from "Hooks/useHubConnection";
import { useAlertAdd } from "Common/Component/AlertList";
import { SelectDialog, validateNarrowDownKey } from "Component/SelectDialog";
import { ColumnData } from "Common/Component/VirtualizedTable";
import TextField from "Component/TextField";

let counter = 0;

const useStyles = makeStyles((theme) => ({
  messageMargin: {
    margin: 4,
  },
  iconMargin: {
    margin: 4,
  },
  inputArea: {
    width: "100%",
  },
  dragDrop: {
    "&[data-drag='true']": {
      backgroundColor: "rgba(127,127,127, 0.5)",
      zIndex: 1000,
    },
  },
  dragDropArea: {
    position: "relative",
  },
  dragDropAreaImage: {
    width: "100%",
    height: (props: any) => props.height,
    backgroundColor: "rgba(127,127,127, 0.5)",
    position: "absolute",
    top: 0,
    display: "table",
    "&[data-drag='false']": {
      visibility: "hidden",
    },
  },
  dragDropAreaMessage: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  messageArea: {
    width: "100%",
    height: (props: any) => props.height - 129 - 52,
    overflowX: "hidden",
    marginBottom: `${theme.spacing(1) * 2}px`,
  },
  message: {
    padding: theme.spacing(1),
    overflowWrap: "anywhere",
    maxWidth: `calc(100% - ${theme.spacing(1) * 4}px)`,
  },
  messageName: {
    fontSize: 12,
  },
  messageContent: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  textField: {
    width: `calc(100% - ${4 * 2 + 48}px)`,
  },
  icon: {
    width: `${4 * 2 + 48}px`,
  },
  download: {
    color: "blue",
    textDecoration: "underline",
  },
  newLine: {
    whiteSpace: "pre-wrap",
  },
  loading: {
    height: (props: any) => props.height,
  },
  marginZero: {
    margin: 0,
  },
}));

interface Props {
  height: number;

  messages: Message[];

  userId: string;

  disabled: boolean;

  onClickFile?: (fileList: FileList, onSended: () => void) => void;

  onClickUp?: (message: string) => Promise<boolean>;

  onClickDownload?: (message: Message[]) => void;
}

const Chat = React.memo((props: Props) => {
  const classes = useStyles({ height: props.height });

  const genericClasses = useGenericStyles();

  const [fileInputElementId] = useState<string>(NewId("raised-button-file"));

  const fileInput = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState<string>("");

  const messageBox = useMessageBox();

  const [disabled, setDisabled] = useState(false);

  const [openDownloadsDialog, setOpenDownloadsDloag] = useState(false);

  const [isDragging, setIsDragging] = useState(false);

  const handleOnClickUp = useCallback(async () => {
    if (message === "") {
      return;
    }

    setDisabled(true);

    if (await props.onClickUp?.(message)) {
      setMessage("");
    }

    setDisabled(false);
  }, [props, message]);

  const handleOnClickDownload = useCallback(
    (message: Message) => {
      return async () => {
        if (message.isFile) {
          if (await messageBox.confirm("確認メッセージ", `${message.message}\nをダウンロードしますか？`)) {
            props.onClickDownload?.([message]);
          }
        }
      };
    },
    [props.onClickDownload, messageBox]
  );

  const messageComponent = useMemo(() => {
    return (
      <>
        {props.messages.map((value) => {
          return (
            <Grid key={value.id} item xs={12} className={classes.messageMargin}>
              <Grid
                container
                direction="row"
                justify={props.userId === value.userId ? "flex-end" : "flex-start"}
                alignItems="center"
              >
                <Paper className={classes.message} onClick={handleOnClickDownload(value)}>
                  <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.messageName}>
                      {`${DateUtility.format(value.createdAt, "M / d HH:mm")}　${value.tenantName}　${value.userName}`}
                    </Grid>
                    <Grid
                      item
                      className={clsx(classes.messageContent, value.isFile && classes.download, classes.newLine)}
                    >
                      {value.message}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }, [
    props.messages,
    props.userId,
    classes.download,
    classes.message,
    classes.messageContent,
    classes.messageMargin,
    classes.messageName,
    classes.newLine,
    handleOnClickDownload,
  ]);

  const handleOnChange = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  const handleOnChangeSelectedFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.currentTarget.files;
      if (!fileList || fileList.length === 0) {
        return;
      }

      props.onClickFile?.(fileList, () => {
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      });
    },
    [props]
  );

  const handleOnDropFile = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (props.disabled) {
        return;
      }

      event.preventDefault();
      setIsDragging(false);
      counter = 0;

      const fileList = event.dataTransfer.files;
      if (!fileList || fileList.length === 0) {
        return;
      }

      props.onClickFile?.(fileList, () => {});
    },
    [props]
  );

  const handleOnChangeDragState = useCallback((event: React.DragEvent<HTMLDivElement>, isDragging) => {
    setIsDragging(isDragging);
  }, []);

  const handleOnClickDownloads = useCallback(() => {
    setOpenDownloadsDloag(true);
  }, []);

  const handleOnClickNarrowDown = useCallback(
    (text: string) => {
      const split = validateNarrowDownKey(text);
      const files = props.messages.filter((value) => value.isFile);

      if (split.length === 0) {
        return files;
      }

      return files.filter((file) => split.findIndex((find) => file.message.indexOf(find) !== -1) !== -1);
    },
    [props.messages]
  );

  const handleOnSelectedAttachmentFile = useCallback(
    async (result: Message[] | null) => {
      setOpenDownloadsDloag(false);

      if (!result || result.length === 0) {
        return;
      }

      props.onClickDownload?.(result);
    },
    [props.onClickDownload]
  );

  useEffect(() => {
    const messageBox = document.getElementById("messageBox");
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  }, [props.messages]);

  const columns: ColumnData[] = useMemo(
    () => [
      {
        width: 300,
        label: "添付ファイル",
        dataKey: "message",
        headerAlign: "center",
        bodyAlign: "left",
        fit: true,
      },
    ],
    []
  );

  return (
    <>
      <input
        accept="*/*"
        style={{ display: "none" }}
        id={fileInputElementId}
        multiple
        type="file"
        onChange={handleOnChangeSelectedFile}
        ref={fileInput}
        disabled={props.disabled}
      />

      <div
        className={classes.dragDropArea}
        data-drag={isDragging}
        onDragEnter={(event) => {
          if (props.disabled) {
            return;
          }

          counter++;
          handleOnChangeDragState(event, true);
          event.preventDefault();
        }}
        onDragLeave={(event) => {
          if (props.disabled) {
            return;
          }

          counter--;
          if (counter === 0) {
            handleOnChangeDragState(event, false);
          }
        }}
        onDragOver={(event) => {
          if (props.disabled) {
            return;
          }

          handleOnChangeDragState(event, true);
          event.preventDefault();
        }}
        onDrop={handleOnDropFile}
      >
        <Box data-drag={isDragging} className={classes.dragDropAreaImage}>
          <Typography className={classes.dragDropAreaMessage}>このエリアにドロップするとアップロードします</Typography>
        </Box>
        <Box id="messageBox" className={classes.messageArea}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            {messageComponent}
          </Grid>
        </Box>
        <Box>
          <Button
            className={genericClasses.margin}
            variant="contained"
            color="primary"
            onClick={handleOnClickDownloads}
          >
            添付ファイル一覧
          </Button>
          <SelectDialog
            open={openDownloadsDialog}
            title="添付ファイル"
            data={props.messages.filter((value) => value.isFile)}
            columns={columns}
            selectedData={[]}
            onClickNarrowDown={handleOnClickNarrowDown}
            onClose={handleOnSelectedAttachmentFile}
            maxWidth="sm"
            okButtonText="ダウンロード"
            cancelButtonText="キャンセル"
            disabledIfNotSelected={true}
            changeToUnselectedIfNotHit={true}
          />
        </Box>
        <Box className={classes.inputArea}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item className={classes.textField}>
              <TextField
                className={genericClasses.width100percent}
                label="メッセージ"
                multiline
                rows={4}
                variant="outlined"
                value={message}
                onChange={handleOnChange}
                disabled={disabled || props.disabled}
              />
            </Grid>
            <Grid item className={classes.icon}>
              <label htmlFor={fileInputElementId}>
                <Tooltip title="添付">
                  <span>
                    <IconButton
                      className={classes.iconMargin}
                      color="primary"
                      component="span"
                      disabled={props.disabled}
                    >
                      <AttachFile />
                    </IconButton>
                  </span>
                </Tooltip>
              </label>
              <Tooltip title="メッセージ">
                <span>
                  <IconButton
                    className={classes.iconMargin}
                    color="primary"
                    onClick={handleOnClickUp}
                    disabled={disabled || props.disabled}
                  >
                    <ArrowUpward />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
});

export default Chat;

export const ChatForRaLM = React.memo(
  (props: {
    id: string;
    height: number;
    onSuccessedSendMessage?: () => void;
    onSendedFile?: () => void;
    disabled: boolean;
  }) => {
    const classes = useStyles({ height: props.height });

    const alertAdd = useAlertAdd();

    const ralmAccount = AppProvider.useGlobalState("ralmAccount");

    const hubConnection = useHubConnection(
      "/chathub",
      useCallback(async () => setConnected(true), [])
    );

    const downloadLink = useRef<HTMLAnchorElement>(null);

    const [messages, setMessages] = useState<Message[] | null>(null);

    const [connected, setConnected] = useState<boolean>(false);

    useEffect(() => {
      hubConnection.on("ReceiveMessage", (message: Message) => {
        setMessages((value) => {
          return value ? [...value, message] : null;
        });
      });
    }, [hubConnection]);

    useEffect(() => {
      hubConnection.onreconnected(async () => {
        await hubConnection.invoke("AddGroup", undefined, props.id);
      });
    }, [hubConnection, props.id]);

    useEffect(() => {
      if (connected) {
        hubConnection.invoke("AddGroup", undefined, props.id);
      }

      return () => {
        hubConnection.invoke("RemoveGroup", undefined, props.id);
      };
    }, [hubConnection, connected, props.id]);

    const handleOnClickUp = useCallback(
      async (message: string) => {
        if (ralmAccount) {
          var result = await hubConnection.send(
            "SendMessageToGroup",
            (error: any) => {
              alertAdd({ type: "warning", message: "通信エラーの為、メッセージの送信に失敗しました。" });
            },
            ralmAccount.tenantId,
            ralmAccount.tenantName,
            props.id,
            ralmAccount.userGuid,
            ralmAccount.userName,
            message
          );

          if (result.success) {
            props.onSuccessedSendMessage?.();
          }

          return result.success;
        }

        return false;
      },
      [props.id, props.onSuccessedSendMessage, ralmAccount, hubConnection, alertAdd]
    );

    const execute = useExecute(
      useCallback(
        async (
          unmounted: { value: boolean },
          object: {
            tenantId: string;
            tenantName: string;
            roomId: string;
            userGuid: string;
            userName: string;
            fileList: FileList;
            onSendedFile?: () => void;
            onSended: () => void;
          }
        ) => {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          for (let i = 0; i < object.fileList.length; ++i) {
            const file = object.fileList.item(i);
            if (file) {
              const formData = new FormData();
              formData.append("tenantId", object.tenantId);
              formData.append("tenantName", object.tenantName);
              formData.append("roomId", object.roomId);
              formData.append("userGuid", object.userGuid);
              formData.append("userName", object.userName);
              formData.append("file", file);
              await callWebApi().post("/message/file", formData, config);
            }

            if (unmounted.value) {
              return;
            }

            object.onSendedFile?.();
            object.onSended();
          }
        },
        []
      )
    );

    const message = useMessageBox();

    const handleOnClickFile = useCallback(
      async (fileList: FileList, onSended: () => void) => {
        if (ralmAccount) {
          if (await message.confirm("アップロード確認", "ファイルをアップロードしますか？")) {
            execute({
              tenantId: ralmAccount.tenantId,
              tenantName: ralmAccount.tenantName,
              roomId: props.id,
              userGuid: ralmAccount.userGuid,
              userName: ralmAccount.userName,
              fileList: fileList,
              onSendedFile: props.onSendedFile,
              onSended: onSended,
            });
          }
        }
      },
      [execute, props.id, props.onSendedFile, ralmAccount, message]
    );

    const executeDownload = useExecute(
      useCallback(async (unmounted: { value: boolean }, object: { messages: Message[] }) => {
        for (let i = 0; i < object.messages.length; i++) {
          const response = await callWebApi().get("/message/file", {
            params: {
              id: object.messages[i].id,
            },
            responseType: "blob",
          });

          if (unmounted.value) {
            return;
          }

          if (downloadLink.current) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            downloadLink.current.href = url;
            downloadLink.current.setAttribute("download", object.messages[i].message);
            downloadLink.current.click();
          }
        }
      }, [])
    );

    const handleOnClickDownLoad = useCallback(
      (messages: Message[]) => {
        executeDownload({ messages: messages });
      },
      [executeDownload]
    );

    const fetchResult = useFetch(
      useCallback(
        async (signal: CancelTokenSource) => {
          const response = await callWebApi().get<Message[]>("/message", {
            cancelToken: signal.token,
            params: { chatId: props.id },
          });
          setMessages(response.data);
        },
        [props.id]
      )
    );

    return (
      <>
        <a href="#dummy" style={{ display: "none" }} ref={downloadLink}>
          DownloadLink
        </a>

        {useLoadingElement(classes.loading, LoadingMode.Circular, fetchResult) ??
          (ralmAccount && messages && (
            <Chat
              height={props.height}
              messages={messages}
              userId={ralmAccount?.userGuid}
              disabled={props.disabled}
              onClickUp={handleOnClickUp}
              onClickFile={handleOnClickFile}
              onClickDownload={handleOnClickDownLoad}
            />
          ))}
      </>
    );
  }
);
